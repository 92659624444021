import React, { useEffect, useMemo, useState } from 'react'
import Tooltip from '../Tooltip'
import SettingButton from '../SettingButton'
import SettingInput from '../SettingInput'
import { CROSS_CHAIN_GAS_RECEIVING_AMOUNT, chainInfos } from '../../constants/web3/chains'
import { useSwapInputContext } from '../../context/SwapInputContext'
import Image from 'next/image'
import { useSwapContext } from '../../context/SwapContext'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN, TOKENS } from '../../constants/contract/token'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { strToWad } from '@hailstonelabs/big-number-utils'
import { useUserPreference } from '../../context/userPreferenceContext'
import { SWAP_GAS_ON_DEST_CHAIN_TOOLTIP_MSG } from '../../constants/infoData'

const CrossChainSwapSettingBox = () => {
  const { targetChainId } = useSwapContext()
  const {
    userPreference: { gasOnDestinationChain },
  } = useUserPreference()
  const receiverValue = useMemo(
    () => gasOnDestinationChain[targetChainId],
    [gasOnDestinationChain, targetChainId]
  )
  const { handleReceiverValueOnChange, handleReceiverMaxOnClick } = useSwapInputContext()
  const defaultReceiverValue = CROSS_CHAIN_GAS_RECEIVING_AMOUNT[targetChainId].defaultAmount
  const [isCustomChecked, setIsCustomChecked] = useState(false)

  useEffect(() => {
    /** check if user is customizing gas  */
    if (receiverValue !== defaultReceiverValue && receiverValue !== '0') {
      setIsCustomChecked(true)
    }
  }, [defaultReceiverValue, receiverValue])

  /** display waarning msg when receiverValue input > max value */
  const warningMsg = useMemo(() => {
    const maxReceiverValue = CROSS_CHAIN_GAS_RECEIVING_AMOUNT[targetChainId].maxAmount
    if (strToWad(receiverValue).gt(strToWad(maxReceiverValue))) {
      return `Request gas token exceeds the max: ${maxReceiverValue} ${
        TOKENS[targetChainId][NATIVE_WRAPPED_TOKEN_IN_CHAIN[targetChainId]]?.displaySymbol
      }`
    }
    return ''
  }, [receiverValue, targetChainId])

  return (
    <div>
      <div className="mb-2 flex">
        <div className="mr-2 text-[14px] font-normal text-wombatPurple1">
          Gas on the Destination Chain
        </div>
        <Tooltip width={330}>{SWAP_GAS_ON_DEST_CHAIN_TOOLTIP_MSG}</Tooltip>
      </div>
      <form>
        <div className="flex-col text-xs text-wombatBrown1">
          <div className="flex gap-20">
            {/* Default */}
            <SettingButton
              onClick={() => {
                handleReceiverValueOnChange(defaultReceiverValue)
                setIsCustomChecked(false)
              }}
              checked={receiverValue === defaultReceiverValue && !isCustomChecked}
            >
              Default
            </SettingButton>
            {/* None */}
            <SettingButton
              onClick={() => {
                handleReceiverValueOnChange('0')
                setIsCustomChecked(false)
              }}
              checked={receiverValue == '0' && !isCustomChecked}
            >
              None
            </SettingButton>
          </div>
          <div>
            {/* Custom */}
            <SettingInput
              onClick={() => {
                setIsCustomChecked(true)
              }}
              placeHolder="0.00"
              checked={isCustomChecked}
              inputForDisplay={receiverValue}
              onAmountChanged={(amount) => {
                handleReceiverValueOnChange(amount)
              }}
              leftIcon={
                <div className="mr-2">
                  <Image src={chainInfos[targetChainId].icon} width={22} height={22} />
                </div>
              }
              onMaxOnClick={() => handleReceiverMaxOnClick()}
              inputTokenSymbol={
                TOKENS[targetChainId][NATIVE_WRAPPED_TOKEN_IN_CHAIN[targetChainId]]
                  ?.displaySymbol as TokenSymbol
              }
              inputWidth="w-[200px]"
              warningMsg={warningMsg}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default CrossChainSwapSettingBox

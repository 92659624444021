import React, { useEffect, useRef, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, ChartData, ChartComponentLike } from 'chart.js'
import 'react-circular-progressbar/dist/styles.css'
import _ from 'lodash'

type TooltipLabel = { component: React.ReactNode }
type TooltipData = {
  left: string
  top: string
  opacity: string
  borderColor: string
  display: string
} & TooltipLabel

// Custom plugin
const HoverShadow: ChartComponentLike = {
  id: 'shadow',
  // make sure to draw above the existing arcs
  afterDraw: (chart) => {
    const { ctx } = chart
    ctx.save()
    if (chart.getActiveElements().length > 0) {
      const ele = chart.getActiveElements()[0].element
      const { x, y, innerRadius, outerRadius, startAngle, endAngle } = ele as unknown as {
        x: number
        y: number
        innerRadius: number
        outerRadius: number
        startAngle: number
        endAngle: number
      }

      ctx.beginPath()
      ctx.arc(x, y, outerRadius - (outerRadius - innerRadius) / 2, startAngle, endAngle)
      ctx.shadowBlur = 5
      ctx.shadowColor = 'rgba(0, 0, 0, 0.3)'
      ctx.lineWidth = innerRadius * 1.2
      ctx.strokeStyle = ele.options['backgroundColor'] as string
      ctx.stroke()

      ctx.restore()
    }
  },
}
ChartJS.register(ArcElement, Tooltip, HoverShadow)

export type DoughnutChartData = {
  dataset: number[]
  backgroundColors: string[]
  labels: React.ReactNode[]
}
type Props = {
  children?: React.ReactNode
  size?: string
  maxWidth?: string
  margin?: string
  data: DoughnutChartData
  onArcHover?: (dataIndex: number | null) => void
  disableTooltip?: boolean
}
function DoughnutChart({
  children,
  data,
  size = '100%',
  maxWidth,
  margin,
  onArcHover,
  disableTooltip,
}: Props) {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const customTooltipRef = useRef<HTMLDivElement>(null)
  const [tooltipData, setTooltipData] = useState<TooltipData>({
    left: '0',
    top: '0',
    opacity: '0',
    display: 'none',
    component: <></>,
    borderColor: '',
  })
  const chartData: ChartData<'doughnut', number[], React.ReactNode> = {
    labels: data.labels,
    datasets: [
      {
        data: data.dataset,
        backgroundColor: data.backgroundColors,
        borderWidth: 0,
      },
    ],
  }

  // update tooltip's position and visibility
  useEffect(() => {
    if (customTooltipRef.current) {
      const style = customTooltipRef.current.style
      style.left = tooltipData.left
      style.top = tooltipData.top
      style.opacity = tooltipData.opacity
      style.borderColor = tooltipData.borderColor
      style.display = tooltipData.display
    }
  }, [tooltipData])

  return (
    <div
      ref={chartContainerRef}
      className="relative"
      style={{
        width: size,
        height: size,
        maxWidth,
        margin,
      }}
    >
      {/* custom tooltip */}
      <div
        className="pointer-events-none absolute z-[1] rounded-[8px] border-1 bg-white p-[8px] font-Work text-sm"
        ref={customTooltipRef}
      >
        {tooltipData.component}
      </div>
      {/* center its content */}
      <div className="absolute top-[50%] left-[50%] z-0 h-[50%] w-[50%] translate-x-[-50%] translate-y-[-50%] rounded-full bg-white font-Jamjuree">
        {children}
      </div>
      <Doughnut
        data={chartData}
        options={{
          animation: false,
          plugins: {
            tooltip: {
              enabled: false,
              external: (ctx) => {
                const tooltipModal = ctx.tooltip
                // on mouse leave
                if (tooltipModal.opacity === 0) {
                  onArcHover && onArcHover(null)
                  if (tooltipData.opacity !== '0') {
                    setTooltipData((prev) => ({ ...prev, opacity: '0', display: 'none' }))
                  }
                } else {
                  // on hover
                  // position
                  // make sure the tooltip does not block the content at the center of the chart
                  const getLeftPosition = () => {
                    if (chartContainerRef.current) {
                      const rect = chartContainerRef.current.getBoundingClientRect()
                      const rectLeft = rect.left
                      let tooltipLeftPosition = 0
                      let isInViewport = false
                      switch (tooltipModal.xAlign) {
                        // make sure the tooltip is placed in the viewport
                        case 'left':
                          tooltipLeftPosition = tooltipModal.x - tooltipModal.width
                          isInViewport = tooltipLeftPosition + rectLeft > 0
                          return isInViewport ? tooltipLeftPosition : tooltipModal.x
                        case 'right':
                          tooltipLeftPosition = tooltipModal.x + tooltipModal.width
                          isInViewport =
                            tooltipLeftPosition + rectLeft + tooltipModal.width < window.innerWidth
                          return isInViewport ? tooltipLeftPosition : tooltipModal.x
                        default:
                          return 0
                      }
                    }
                    return 0
                  }

                  const left = `${getLeftPosition()}px`
                  const top = `${tooltipModal.y}px`
                  // default color
                  let borderColor = '#EB9B3D'
                  // label data
                  let targetLabelComponent: React.ReactNode = <></>
                  const dataPoint = tooltipModal.dataPoints[0]
                  if (dataPoint.label) {
                    targetLabelComponent = dataPoint.label
                  }
                  const backgroundColors = dataPoint.dataset.backgroundColor as string[]
                  if (dataPoint.dataset.backgroundColor) {
                    borderColor = backgroundColors[dataPoint.dataIndex]
                  }

                  // new state
                  const newTooltipData: TooltipData = {
                    component: targetLabelComponent,
                    left,
                    top,
                    opacity: '1',
                    borderColor,
                    display: 'block',
                  }
                  // prevent from changes on every render.
                  if (!_.isEqual(newTooltipData, tooltipData)) {
                    !disableTooltip && setTooltipData(newTooltipData)
                    onArcHover && onArcHover(dataPoint.dataIndex)
                  }
                }
              },
            },
          },
          layout: { padding: 10 },
        }}
      />
    </div>
  )
}

export default DoughnutChart

import { PoolFilterGroup } from '../../constants/contract/pool/poolFilterGroup'
import clsx from 'clsx'
import { useWeb3 } from '../../context/Web3Context'
import { chainInfos } from '../../constants/web3/chains'
import { usePoolPage } from '../../context/PoolPageContext'

interface IPoolFilterSelection {
  onPoolFilterSelectionChange: (filterGroup: PoolFilterGroup) => void
  selectedPoolFilterGroup: PoolFilterGroup
}

export const PoolFilterSelection: React.FC<IPoolFilterSelection> = ({
  onPoolFilterSelectionChange,
  selectedPoolFilterGroup,
}) => {
  const { chainId } = useWeb3()
  const { updateSelectedPoolFilterGroup } = usePoolPage()
  const avaliablePoolFilterGroups = chainInfos[chainId].displayPoolFilterGroups

  return (
    <div className="flex w-full rounded-md border-1 border-wombatBrown1">
      {Object.values(PoolFilterGroup)
        .filter((filterGroup) => {
          return avaliablePoolFilterGroups.includes(filterGroup)
        })
        .map((filterGroup) => {
          const selected = filterGroup === selectedPoolFilterGroup
          return (
            <div
              key={filterGroup}
              className={clsx(
                'flex flex-1 items-center justify-center border-wombatBrown1 text-[14px] [&:not(:last-child)]:border-r-1',
                selected
                  ? 'cursor-default bg-wombatPurple3 font-bold'
                  : 'cursor-pointer text-wombatBrown3'
              )}
              onClick={() => {
                updateSelectedPoolFilterGroup(filterGroup)
                onPoolFilterSelectionChange(filterGroup)
              }}
            >
              {filterGroup}
            </div>
          )
        })}
    </div>
  )
}

import { ChainId as WormholeChainId } from '@certusone/wormhole-sdk'
import { BUILD_TYPE } from '../common'

/** @todo unhide second class  */
export enum SupportedChainId {
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  ARBITRUM_ONE = 42161,
  FUJI = 43113,
  ETHEREUM_MAINNET = 1,
  SCROLL_SEPOLIA_TESTNET = 534351,
  SCROLL_MAINNET = 534352,
  SKALE_TESTNET = 1351057110,
  OP_MAINNET = 10,
  AVAX = 43114,
  POLYGON_MAINNET = 137,
  BASE = 8453,
  // ZKSYNC_MAINNET = 324,
  // ZKSYNC_TESTNET = 300,
}

export const supportedChainIdToWormholeChainIdMap: { [key in SupportedChainId]?: WormholeChainId } =
  {
    [SupportedChainId.ARBITRUM_ONE]: 23,
    [SupportedChainId.BSC_MAINNET]: 4,
    [SupportedChainId.BSC_TESTNET]: 4,
    [SupportedChainId.FUJI]: 6,
    [SupportedChainId.ETHEREUM_MAINNET]: 2,
    [SupportedChainId.OP_MAINNET]: 24,
    [SupportedChainId.AVAX]: 6,
    [SupportedChainId.POLYGON_MAINNET]: 5,
    [SupportedChainId.BASE]: 30,
    [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: 34,
    [SupportedChainId.SCROLL_MAINNET]: 34,
  }

export const wormholeChainIdToSupportedChainIdMap = Object.entries(
  supportedChainIdToWormholeChainIdMap
).reduce((output, [supportedChainId, wormholeChainId]) => {
  output[wormholeChainId] = supportedChainId as unknown as SupportedChainId
  return output
}, {} as { [key in WormholeChainId]?: SupportedChainId })

export const MAINNET_CHAIN_IDS = [
  SupportedChainId.BSC_MAINNET,
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ETHEREUM_MAINNET,
  SupportedChainId.SCROLL_MAINNET,
  SupportedChainId.AVAX,
  SupportedChainId.BASE,
  SupportedChainId.OP_MAINNET,
]

export const TESTNET_CHAIN_IDS = [
  SupportedChainId.BSC_TESTNET,
  SupportedChainId.FUJI,
  SupportedChainId.SCROLL_SEPOLIA_TESTNET,
  // SupportedChainId.ZKSYNC_TESTNET,
  SupportedChainId.POLYGON_MAINNET,
  /** @todo uncomment it when needed */
  // SupportedChainId.ZKSYNC_MAINNET,
  SupportedChainId.SKALE_TESTNET,
]

// Change SUPPORTED_CHAINS for interface
export const SUPPORTED_CHAIN_IDS =
  process.env.NEXT_PUBLIC_BUILD_TYPE === BUILD_TYPE.PROD
    ? MAINNET_CHAIN_IDS
    : [...MAINNET_CHAIN_IDS, ...TESTNET_CHAIN_IDS]

export const DEFAULT_CHAIN_ID =
  process.env.NEXT_PUBLIC_BUILD_TYPE === BUILD_TYPE.PROD
    ? SupportedChainId.BSC_MAINNET
    : SupportedChainId.BSC_TESTNET

import { ethers } from 'ethers'
import { MulticallProvider } from './multicallProvider'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SIDfunctions = require('@siddomains/sidjs')
const SID = SIDfunctions.default

export async function getSIDName(
  account: string,
  multicallProvider: MulticallProvider
): Promise<string> {
  try {
    if (!account) {
      return ''
    }
    const provider = new ethers.providers.JsonRpcProvider(multicallProvider.getRpcUrl())
    const sid = new SID({
      provider,
      sidAddress: SIDfunctions.getSidAddress(String(multicallProvider.getChainId())),
    })
    const response = await sid.getName(account)
    return response.name
  } catch (error) {
    return ''
  }
}

import * as multicall from 'ethcall'
import { ethers, utils } from 'ethers'
import { BUILD_TYPE } from '../constants/common'
import { chainInfos } from '../constants/web3/chains'
import { SupportedChainId } from '../constants/web3/supportedChainId'

const RETRY_LIMIT = 3
/**
 * Wrapper class for the ethcall.multicall class to allow for multiple RPC URLs.
 */
export class MulticallProvider {
  chainId: SupportedChainId
  apiList: string[]
  rpcUrl: string
  retryCount = 0
  multicallProvider?: multicall.Provider | null
  readOnlyProvider?: ethers.providers.StaticJsonRpcProvider | null
  constructor(chainId: SupportedChainId) {
    this.chainId = chainId
    this.apiList = chainInfos[chainId].rpcUrls.default.http as string[]
    this.rpcUrl = this.apiList[0]
  }

  public getRpcUrl() {
    return this.rpcUrl
  }

  public getChainId() {
    return this.chainId
  }

  public getReadOnlyProvider() {
    if (!this.readOnlyProvider) {
      const providerObject: utils.ConnectionInfo = {
        url: this.rpcUrl,
      }
      if (process.env.NEXT_PUBLIC_BUILD_TYPE !== BUILD_TYPE.PROD) {
        providerObject.headers = {
          'Access-Control-Allow-Origin': this.rpcUrl + ', http://localhost:3000',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
          'Access-Control-Allow-Headers':
            'Origin, X-Requested-With, Content-Type, Accept, Authorization',
        }
      }
      this.readOnlyProvider = new ethers.providers.StaticJsonRpcProvider(
        providerObject,
        this.chainId
      )
    }
    return this.readOnlyProvider
  }

  private nextProvider() {
    console.warn(`Failed RPC URL: ${this.rpcUrl}`)
    this.multicallProvider = null
    this.readOnlyProvider = null
    this.retryCount++
    if (this.retryCount > this.apiList.length - 1) {
      this.retryCount = 0
    }
    this.rpcUrl = this.apiList[this.retryCount]
  }

  private async callWithRetry(
    calls: multicall.Call[],
    block?: multicall.BlockTag | undefined
  ): Promise<unknown[]> {
    let response = null
    try {
      if (!this.multicallProvider) {
        this.multicallProvider = new multicall.Provider()
        await this.multicallProvider.init(this.getReadOnlyProvider())
      }
      response = await this.multicallProvider.tryAll(calls, block)
    } catch (e) {
      // Roll over the to the next URL in the list
      this.nextProvider()
      if (this.retryCount > RETRY_LIMIT) {
        console.warn('Attempts exhausted. Please check rpc settings.', e)
        this.retryCount = 0
        this.rpcUrl = this.apiList[this.retryCount]
        throw e
      }
      return this.callWithRetry(calls, block)
    }
    return response
  }
  getEthBalance(address: string) {
    return this.multicallProvider?.getEthBalance(address)
  }
  public async tryAll(calls: multicall.Call[], block?: multicall.BlockTag) {
    if (calls.length === 0) return []
    return this.callWithRetry(calls, block)
  }
}

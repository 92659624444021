import { safeWdiv, strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { CROSS_CHAIN_SWAP_GAS_LIMIT, MARKET_FROM_AMOUNT } from '../constants/common'
import { TOKENS } from '../constants/contract'
import { DEFAULT_SWAP_TOKEN_IN_CHAIN } from '../constants/contract/token'
import { Token } from '../constants/contract/token/Token'
import { TokenSymbol, TokenSymbols } from '../constants/contract/token/TokenSymbols'
import { chainInfos } from '../constants/web3/chains'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import useCovRatioBetter from '../hooks/swap/useCovRatioBetter'
import { ISwapPathData } from '../interfaces/swap'
import { useTokenPrices } from '../store/Prices/hooks'
import {
  estimateDeliveryFeeWad,
  getCrossChainSwapFeeAndTokenReceive,
} from '../utils/crossChainSwap'
import { getMinimumCreditReceived, getMinimumReceived } from '../utils/pool'
import {
  IQuote,
  TokenPathInfo,
  getAllSwapPossibleTokenInfoPaths,
  getBestSwapTokenInfoPaths,
  getFilteredTokenMaps,
} from '../utils/router'
import {
  getFirstCrossChainToken,
  getHaircutSumInToTokenWad,
  getNewToToken,
  getPriceImpactWad,
  getSwapPairSymbolAndChainIdByQuery,
} from '../utils/swap'
import { useWeb3 } from './Web3Context'
import { useUserPreference } from './userPreferenceContext'

interface ContextType {
  sourceChainId: SupportedChainId
  targetChainId: SupportedChainId
  isCrossChainSwap: boolean
  defaultFromTokenSymbol: TokenSymbol
  defaultToTokenSymbol: TokenSymbol
  fromTokenSymbol: TokenSymbol
  toTokenSymbol: TokenSymbol
  fromTokenAmount: string
  toTokenAmount: string
  swapPathData: ISwapPathData | null
  fee: string
  priceImpact: string
  minimumReceive: string
  isQuoteRequestFailed: boolean
  ratio: string | null
  estimatedDeliveryFee: string | null
  minimumCreditReceive: string | null
  receiverValue: string
  isQuotingSwapInfo: boolean
  handleSourceChainIdChange: (chainId: SupportedChainId) => void
  handleTargetChainIdChange: (chainId: SupportedChainId) => void
  updateToTokenSymbol: (symbol: TokenSymbol | null) => Promise<void>
  updateFromTokenSymbol: (symbol: TokenSymbol | null) => Promise<void>
  updateFromTokenAmount: (amount: string) => Promise<void>
  updateToTokenAmount: (amount: string) => Promise<void>
  switchTokensDirection: () => Promise<void>
  resetAllAmount: () => void
  setReceiverValue: React.Dispatch<React.SetStateAction<string>>
  covRatioBetter: boolean
}

const initialState: ContextType = {
  sourceChainId: SupportedChainId.BSC_MAINNET,
  targetChainId: SupportedChainId.BSC_MAINNET,
  isCrossChainSwap: false,
  defaultFromTokenSymbol: TokenSymbols.USDT,
  defaultToTokenSymbol: TokenSymbols.USDC,
  fromTokenAmount: '',
  toTokenAmount: '',
  fromTokenSymbol: TokenSymbols.USDT,
  toTokenSymbol: TokenSymbols.USDC,
  swapPathData: null,
  fee: '',
  priceImpact: '',
  minimumReceive: '',
  isQuoteRequestFailed: false,
  ratio: null,
  estimatedDeliveryFee: null,
  minimumCreditReceive: null,
  receiverValue: '',
  isQuotingSwapInfo: false,
  handleSourceChainIdChange: () => undefined,
  handleTargetChainIdChange: () => undefined,
  updateToTokenSymbol: () => {
    return new Promise<void>((resolve) => resolve())
  },
  updateFromTokenSymbol: () => {
    return new Promise<void>((resolve) => resolve())
  },
  updateFromTokenAmount: () => {
    return new Promise<void>((resolve) => resolve())
  },
  updateToTokenAmount: () => {
    return new Promise<void>((resolve) => resolve())
  },
  switchTokensDirection: () => {
    return new Promise<void>((resolve) => resolve())
  },
  resetAllAmount: () => undefined,
  setReceiverValue: () => undefined,
  covRatioBetter: false,
}

const SwapContext = createContext<ContextType>(initialState)
SwapContext.displayName = 'SwapContext'
type IQuoteDirection = 'in' | 'out'

interface Props {
  children: React.ReactNode
}

export const useSwapContext = (): ContextType => {
  return useContext(SwapContext)
}

function SwapProvider({ children }: Props): ReactElement {
  const [fromTokenAmount, setFromTokenAmount] = useState<string>('')
  const [toTokenAmount, setToTokenAmount] = useState<string>('')
  const { chainId, switchNetwork } = useWeb3()
  const {
    userPreference: { slippage, gasOnDestinationChain },
    actions: { updateGasOnDestinationChain },
  } = useUserPreference()
  const [receiverValue, setReceiverValue] = useState<string>('')

  const [searchParams, setSearchParams] = useSearchParams()

  const sourceTokenMaps: { [id in TokenSymbol]?: Token } = useMemo(
    () => getFilteredTokenMaps(chainId, true),
    [chainId]
  )
  const [selectedSwapPossibleTokenInfoPaths, setSelectedSwapPossibleTokenInfoPaths] = useState<
    null | TokenPathInfo[][]
  >(null)

  const defaultFromTokenSymbol = useMemo(() => {
    return DEFAULT_SWAP_TOKEN_IN_CHAIN[chainId].fromTokenSymbol
  }, [chainId])

  const [sourceChainId, setSourceChainId] = useState(initialState.sourceChainId)
  const [targetChainId, setTargetChainId] = useState(initialState.targetChainId)
  const isCrossChainSwap = sourceChainId !== targetChainId

  const targetTokenMaps: { [id in TokenSymbol]?: Token } = useMemo(
    () => getFilteredTokenMaps(targetChainId, true),
    [targetChainId]
  )
  const defaultToTokenSymbol = useMemo(() => {
    return DEFAULT_SWAP_TOKEN_IN_CHAIN[targetChainId].toTokenSymbol
  }, [targetChainId])
  const [toTokenSymbol, setToTokenSymbol] = useState<TokenSymbol>(defaultToTokenSymbol)
  const [fromTokenSymbol, setFromTokenSymbol] = useState<TokenSymbol>(defaultFromTokenSymbol)
  const [swapPathData, setSwapPathData] = useState<ISwapPathData | null>(null)
  const tokenPrices = useTokenPrices()
  const [fee, setFee] = useState<string>('')
  const [priceImpact, setPriceImpact] = useState<string>('')
  const [lastQuoteDirection, setLastQuoteDirection] = useState<IQuoteDirection | null>(null)
  const [isQuoteRequestFailed, setIsQuoteRequestFailed] = useState<boolean>(false)
  const [isQuotingSwapInfo, setIsQuotingSwapInfo] = useState(false)
  const [ratio, setRatio] = useState<null | string>(null)
  const [minimumReceive, setMinimumReceive] = useState<string>('')
  // for crosschain swap
  const [estimatedDeliveryFee, setEstimatedDeliveryFee] = useState<string | null>(null)
  const [minimumCreditReceive, setMinimumCreditReceive] = useState<string | null>(null)
  const isFirstTimeRender = useRef(true)

  const resetAllAmount = useCallback((quoteDirection?: IQuoteDirection) => {
    if (quoteDirection !== 'out') {
      setFromTokenAmount('')
    }
    setToTokenAmount('')
    setPriceImpact('')
    setFee('')
    setRatio(null)
    setMinimumReceive('')
    setEstimatedDeliveryFee(null)
    setMinimumCreditReceive(null)
  }, [])

  const setSameChainSwapQuotation = useCallback(
    (
      newAmount: string,
      newQoute: IQuote,
      newSwapPathData: ISwapPathData,
      quoteDirection: 'in' | 'out'
    ) => {
      setIsQuoteRequestFailed(false)
      const { potentialOutcome, haircutBNs, priceImpactWad } = newQoute
      const haircutWad = getHaircutSumInToTokenWad(
        haircutBNs,
        newSwapPathData.tokenSymbolPath,
        tokenPrices,
        chainId
      )
      setFee(utils.formatEther(haircutWad))
      setPriceImpact(utils.formatEther(priceImpactWad))
      if (quoteDirection === 'out') {
        setToTokenAmount(potentialOutcome)
        setRatio(utils.formatEther(safeWdiv(strToWad(newAmount), strToWad(potentialOutcome))))
      } else {
        setFromTokenAmount(potentialOutcome)
        setRatio(utils.formatEther(safeWdiv(strToWad(potentialOutcome), strToWad(newAmount))))
      }
      setMinimumReceive(utils.formatEther(getMinimumReceived(strToWad(potentialOutcome), slippage)))
      setIsQuotingSwapInfo(false)
    },
    /**
     * should depend on from and to token's prices instead of tokenPrices object
     * Otherwise, it will keep rerendering
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chainId, slippage, tokenPrices?.[fromTokenSymbol], tokenPrices?.[toTokenSymbol]]
  )

  const setCrossChainSwapQuotation = useCallback(
    async (newAmount: string) => {
      try {
        setIsQuotingSwapInfo(true)
        const fromChainId = sourceChainId
        const toChainId = targetChainId
        const fromTokenAmount = newAmount
        const targetQuote = await getCrossChainSwapFeeAndTokenReceive({
          fromTokenSymbol,
          fromChainId,
          fromTokenAmount,
          toTokenSymbol,
          toChainId,
          tokenPrices,
        })
        const marketQuote = await getCrossChainSwapFeeAndTokenReceive({
          fromTokenSymbol,
          fromChainId,
          fromTokenAmount: MARKET_FROM_AMOUNT,
          toTokenSymbol,
          toChainId,
          tokenPrices,
        })
        const priceImpact = utils.formatEther(
          getPriceImpactWad(
            fromTokenAmount,
            targetQuote.toTokenAmount,
            MARKET_FROM_AMOUNT,
            marketQuote.toTokenAmount
          )
        )

        const quotedToTokenAmount = targetQuote.toTokenAmount
        setToTokenAmount(quotedToTokenAmount)
        setPriceImpact(priceImpact)
        setFee(targetQuote.totalFeeInFromToken)
        setRatio(
          utils.formatEther(safeWdiv(strToWad(fromTokenAmount), strToWad(quotedToTokenAmount)))
        )
        setMinimumReceive(
          utils.formatEther(getMinimumReceived(strToWad(quotedToTokenAmount), slippage))
        )
        setMinimumCreditReceive(
          utils.formatEther(getMinimumCreditReceived(strToWad(targetQuote.creditAmount), slippage))
        )
        const deliveryFeeWad = await estimateDeliveryFeeWad({
          fromChainId,
          toChainId,
          // receiver value is native tokens on destination chain
          receiverValue,
          // gas limit
          gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
        })
        setEstimatedDeliveryFee(utils.formatEther(deliveryFeeWad))
      } catch (error) {
        console.log(error)
        resetAllAmount('out')
      } finally {
        setIsQuotingSwapInfo(false)
      }
    },
    /**
     * should depend on from and to token's prices instead of tokenPrices object
     * Otherwise, it will keep rerendering
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fromTokenSymbol,
      receiverValue,
      slippage,
      sourceChainId,
      targetChainId,
      toTokenSymbol,
      tokenPrices?.[fromTokenSymbol],
      tokenPrices?.[toTokenSymbol],
    ]
  )

  // re-evaluate quotation after selected new token
  const handleSameChainSwapAmountChange = useCallback(
    async (
      amount: string,
      lastQuoteDirection: IQuoteDirection,
      toTokenSymbol: TokenSymbol,
      fromTokenSymbol: TokenSymbol,
      selectedSwapPossibleTokenInfoPaths: TokenPathInfo[][]
    ) => {
      const toToken = TOKENS[chainId][toTokenSymbol]
      const fromToken = TOKENS[chainId][fromTokenSymbol]
      if (!amount || !(toToken && fromToken) || !selectedSwapPossibleTokenInfoPaths) return
      setIsQuotingSwapInfo(true)
      const result = await getBestSwapTokenInfoPaths(
        chainId,
        fromToken,
        toToken,
        selectedSwapPossibleTokenInfoPaths,
        utils.parseUnits(amount, fromToken.decimals),
        lastQuoteDirection
      )
      if (result) {
        setSwapPathData(result.swapTokenInfoPath)
        const { swapTokenInfoPath, qoute } = result
        setSameChainSwapQuotation(amount, qoute, swapTokenInfoPath, lastQuoteDirection)
      } else {
        resetAllAmount(lastQuoteDirection)
      }
    },
    [chainId, resetAllAmount, setSameChainSwapQuotation]
  )

  const handleCrossChainSwapAmountChange = useCallback(
    (amount: string) => {
      if (!amount) return
      setCrossChainSwapQuotation(amount)
    },
    [setCrossChainSwapQuotation]
  )

  const updateFromTokenAmount = useCallback(
    async (amount: string) => {
      // quote toTokenAmount
      setFromTokenAmount(amount)
      if (isCrossChainSwap) {
        // For crosschain swap
        handleCrossChainSwapAmountChange(amount)
      } else {
        // For same-chain swap
        setLastQuoteDirection('out')
        if (selectedSwapPossibleTokenInfoPaths) {
          await handleSameChainSwapAmountChange(
            amount,
            'out',
            toTokenSymbol,
            fromTokenSymbol,
            selectedSwapPossibleTokenInfoPaths
          )
        }
      }
    },
    [
      fromTokenSymbol,
      handleCrossChainSwapAmountChange,
      handleSameChainSwapAmountChange,
      isCrossChainSwap,
      selectedSwapPossibleTokenInfoPaths,
      toTokenSymbol,
    ]
  )

  const updateToTokenAmount = useCallback(
    async (amount: string) => {
      // quote fromTokenAmount
      setToTokenAmount(amount)
      setLastQuoteDirection('in')
      if (selectedSwapPossibleTokenInfoPaths) {
        await handleSameChainSwapAmountChange(
          amount,
          'in',
          toTokenSymbol,
          fromTokenSymbol,
          selectedSwapPossibleTokenInfoPaths
        )
      }
    },
    [
      fromTokenSymbol,
      handleSameChainSwapAmountChange,
      selectedSwapPossibleTokenInfoPaths,
      toTokenSymbol,
    ]
  )

  const updateToTokenSymbol = useCallback(
    async (newToTokenSymbol: TokenSymbol | null) => {
      // Update fromTokenSymnol if symbol changed
      if (newToTokenSymbol === toTokenSymbol) return
      if (!newToTokenSymbol) {
        setToTokenSymbol(defaultToTokenSymbol)
        resetAllAmount()
        return
      }
      setToTokenSymbol(newToTokenSymbol)
    },
    [defaultToTokenSymbol, resetAllAmount, toTokenSymbol]
  )

  const updateFromTokenSymbol = useCallback(
    async (newFromTokenSymbol: TokenSymbol | null) => {
      // Update fromTokenSymnol if symbol changed
      if (newFromTokenSymbol === fromTokenSymbol) return
      if (!newFromTokenSymbol) {
        setFromTokenSymbol(defaultFromTokenSymbol)
        resetAllAmount()
        return
      }

      const newFromToken = TOKENS[chainId][newFromTokenSymbol]
      const toToken = TOKENS[targetChainId][toTokenSymbol]
      if (!newFromToken || !toToken) return

      // displaying the first token from the swapGroupSymbol group
      const newToToken = getNewToToken(targetChainId, newFromToken, toToken)
      setFromTokenSymbol(newFromTokenSymbol)
      setToTokenSymbol(newToToken.symbol)
    },
    [chainId, targetChainId, defaultFromTokenSymbol, fromTokenSymbol, resetAllAmount, toTokenSymbol]
  )

  const switchTokensDirection = useCallback(async () => {
    const switchedFromTokenSymbol = toTokenSymbol
    const switchedToTokenSymbol = fromTokenSymbol
    const switchedLastQuoteDirection = lastQuoteDirection === 'out' ? 'in' : 'out'
    const switchedFromTokenAmount = toTokenAmount
    const switchedToTokenAmount = fromTokenAmount
    setLastQuoteDirection(switchedLastQuoteDirection)
    setFromTokenSymbol(switchedFromTokenSymbol)
    setToTokenSymbol(switchedToTokenSymbol)
    const toToken = TOKENS[chainId][toTokenSymbol]
    const fromToken = TOKENS[chainId][fromTokenSymbol]
    let selectedSwapPossibleTokenInfoPaths: TokenPathInfo[][] = []
    if (strToWad(switchedFromTokenAmount).gt('0') || strToWad(switchedToTokenAmount).gt('0')) {
      selectedSwapPossibleTokenInfoPaths = getAllSwapPossibleTokenInfoPaths(
        chainId,
        switchedFromTokenSymbol,
        switchedToTokenSymbol
      )
      setSelectedSwapPossibleTokenInfoPaths(selectedSwapPossibleTokenInfoPaths)
    }

    if (strToWad(switchedFromTokenAmount).gt('0') && switchedLastQuoteDirection === 'out') {
      setFromTokenAmount(switchedFromTokenAmount)
      if (switchedFromTokenAmount) {
        await handleSameChainSwapAmountChange(
          switchedFromTokenAmount,
          switchedLastQuoteDirection,
          switchedToTokenSymbol,
          switchedFromTokenSymbol,
          selectedSwapPossibleTokenInfoPaths
        )
      }
    }

    if (strToWad(switchedToTokenAmount).gt('0') && switchedLastQuoteDirection === 'in') {
      setToTokenAmount(switchedToTokenAmount)
      if (switchedToTokenAmount) {
        await handleSameChainSwapAmountChange(
          switchedToTokenAmount,
          switchedLastQuoteDirection,
          switchedToTokenSymbol,
          switchedFromTokenSymbol,
          selectedSwapPossibleTokenInfoPaths
        )
      }
    }
    if (!(toToken && fromToken)) return
  }, [
    toTokenSymbol,
    fromTokenSymbol,
    lastQuoteDirection,
    toTokenAmount,
    fromTokenAmount,
    chainId,
    handleSameChainSwapAmountChange,
  ])

  const updateTokenSymbolsAfterChainIdChange = useCallback(
    (newSourceChainId: SupportedChainId, newTargetChainId: SupportedChainId) => {
      if (newSourceChainId !== newTargetChainId) {
        /** set updated cross chain tokens */
        const firstCrossChainTokenOfTargetChain = getFirstCrossChainToken(newTargetChainId)
        const firstCrossChainTokenOfSourceChain = getFirstCrossChainToken(newSourceChainId)
        if (!firstCrossChainTokenOfTargetChain || !firstCrossChainTokenOfSourceChain) return
        updateFromTokenSymbol(firstCrossChainTokenOfSourceChain.symbol)
        updateToTokenSymbol(firstCrossChainTokenOfTargetChain.symbol)
      } else {
        /** set updated default tokens */
        updateFromTokenSymbol(defaultFromTokenSymbol)
        updateToTokenSymbol(defaultToTokenSymbol)
      }
    },
    [defaultFromTokenSymbol, defaultToTokenSymbol, updateFromTokenSymbol, updateToTokenSymbol]
  )

  const handleSourceChainIdChange = useCallback(
    async (chainId: SupportedChainId) => {
      if (sourceChainId !== chainId) {
        const result = await switchNetwork(chainId)
        if (result) {
          setSourceChainId(chainId)
        }
      }
      /** reset target chain network to source chain network */
      setTargetChainId(chainId)
      /** update Token Symbol */
      updateTokenSymbolsAfterChainIdChange(chainId, targetChainId)
    },
    [sourceChainId, switchNetwork, targetChainId, updateTokenSymbolsAfterChainIdChange]
  )

  const handleTargetChainIdChange = useCallback(
    (chainId: SupportedChainId) => {
      setTargetChainId(chainId)
      /** update Token Symbol */
      updateTokenSymbolsAfterChainIdChange(sourceChainId, chainId)
    },
    [sourceChainId, updateTokenSymbolsAfterChainIdChange]
  )
  /**
   * handle same-chain swap only
   * when user change token symbol, get possible swap paths
   */
  useEffect(() => {
    if (isCrossChainSwap) return
    const initSwapPossibleTokenInfoPaths = getAllSwapPossibleTokenInfoPaths(
      chainId,
      fromTokenSymbol,
      toTokenSymbol
    )
    if (lastQuoteDirection) {
      handleSameChainSwapAmountChange(
        lastQuoteDirection === 'in' ? toTokenAmount : fromTokenAmount,
        lastQuoteDirection,
        toTokenSymbol,
        fromTokenSymbol,
        initSwapPossibleTokenInfoPaths
      )
    }

    setSelectedSwapPossibleTokenInfoPaths(initSwapPossibleTokenInfoPaths)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, fromTokenSymbol, handleSameChainSwapAmountChange, toTokenSymbol, isCrossChainSwap])

  /**
   * handle crosschain swap only
   * When users update from or to tokensymbol, update the quotation
   */
  useEffect(() => {
    if (!isCrossChainSwap) return
    handleCrossChainSwapAmountChange(fromTokenAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, fromTokenSymbol, handleCrossChainSwapAmountChange, isCrossChainSwap, toTokenSymbol])
  useEffect(() => {
    /** default sourceChainId & targetChainId = chainId */
    setSourceChainId(chainId)
    setTargetChainId(chainId)
  }, [chainId])

  useEffect(() => {
    return () => {
      // reset all swap amount after unmount
      resetAllAmount()
    }
  }, [resetAllAmount])

  useEffect(() => {
    /** Check swap pair query string in first render */
    const queryFromTokenInfo = searchParams.get('from')
    const queryToTokenInfo = searchParams.get('to')

    if (!queryFromTokenInfo || !queryToTokenInfo) return

    const swapPairSymbolAndChainId = getSwapPairSymbolAndChainIdByQuery(
      queryFromTokenInfo,
      queryToTokenInfo
    )

    if (!swapPairSymbolAndChainId) return

    const { queryFromTokenSymbol, queryToTokenSymbol } = swapPairSymbolAndChainId

    /** Initizate query string */
    setFromTokenSymbol(queryFromTokenSymbol)
    setToTokenSymbol(queryToTokenSymbol)
  }, [
    sourceTokenMaps,
    defaultFromTokenSymbol,
    defaultToTokenSymbol,
    searchParams,
    setSearchParams,
    switchNetwork,
    chainId,
  ])

  useEffect(() => {
    // prevent from overwriting the query string when users enter a url on the browser
    if (isFirstTimeRender.current)
      return () => {
        isFirstTimeRender.current = false
      }
    const isContainInCurrentChain = sourceTokenMaps[fromTokenSymbol] !== undefined
    const isContainInTargetChain = targetTokenMaps[toTokenSymbol] !== undefined

    const targetFromTokenSymbol = isContainInCurrentChain ? fromTokenSymbol : defaultFromTokenSymbol
    const targetToTokenSymbol = isContainInTargetChain ? toTokenSymbol : defaultToTokenSymbol

    const sourceChainNetwork = chainInfos[sourceChainId].network
    const targetChainNetwork = chainInfos[targetChainId].network

    setSearchParams((prev) => {
      prev.set('from', `${targetFromTokenSymbol},${sourceChainNetwork}`)
      prev.set('to', `${targetToTokenSymbol},${targetChainNetwork}`)
      return prev
    })

    setFromTokenSymbol(targetFromTokenSymbol)
    setToTokenSymbol(targetToTokenSymbol)
  }, [
    sourceTokenMaps,
    targetTokenMaps,
    defaultFromTokenSymbol,
    defaultToTokenSymbol,
    fromTokenSymbol,
    setSearchParams,
    sourceChainId,
    targetChainId,
    toTokenSymbol,
  ])

  useEffect(() => {
    updateGasOnDestinationChain(receiverValue, targetChainId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiverValue, targetChainId])
  useEffect(() => {
    /** default receiver value */
    setReceiverValue(gasOnDestinationChain[targetChainId])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetChainId])

  const covRatioBetter = useCovRatioBetter({
    path: swapPathData,
    fromTokenAmount: fromTokenAmount,
    minimumReceive: minimumReceive,
  })

  return (
    <SwapContext.Provider
      value={{
        sourceChainId,
        targetChainId,
        isCrossChainSwap,
        defaultFromTokenSymbol,
        defaultToTokenSymbol,
        fromTokenAmount,
        toTokenAmount,
        fromTokenSymbol,
        toTokenSymbol,
        swapPathData,
        fee,
        priceImpact,
        minimumReceive,
        isQuoteRequestFailed,
        ratio,
        estimatedDeliveryFee,
        minimumCreditReceive,
        receiverValue,
        isQuotingSwapInfo,
        handleSourceChainIdChange,
        handleTargetChainIdChange,
        updateToTokenSymbol,
        updateFromTokenSymbol,
        updateFromTokenAmount,
        updateToTokenAmount,
        switchTokensDirection,
        resetAllAmount,
        setReceiverValue,
        covRatioBetter,
      }}
    >
      {children}
    </SwapContext.Provider>
  )
}

export default SwapProvider

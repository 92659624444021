import React from 'react'
import CrossChainStatusRecord from './CrossChainStatusRecord'
import { CrossChainCase } from '../../../constants/crossChain'
import { HexString } from '../../../interfaces/contract'
import { useCrossChainSwapTxStore } from '../../../hooks/crossChainSwap/useCrossChainSwapTx'
import { useWeb3 } from '../../../context/Web3Context'

function CrossChainPendingSwap() {
  const { account, chainId } = useWeb3()
  const { getPendingTxnList: getPendingTxList } = useCrossChainSwapTxStore()

  const pendingTxnList = account ? getPendingTxList(chainId, account) : []
  return (
    <div>
      {pendingTxnList.length > 0 ? (
        pendingTxnList.map(
          ({
            case: status,
            fromChainId,
            fromTxhash,
            toChainId,
            toTokenAmount,
            toTokenSymbol,
            sequence,
            receiverValue,
            ...restData
          }) => {
            // only render records for NEED_REDELIVERY and PENDING in this component
            if (!(status === CrossChainCase.NEED_REDELIVERY || status === CrossChainCase.PENDING))
              return
            return (
              <CrossChainStatusRecord
                key={fromChainId + sequence}
                source={{
                  chainId: fromChainId,
                  tokenSymbol: restData.isCreditToToken ? null : restData.fromTokenSymbol,
                  amount: restData.isCreditToToken
                    ? restData.fromCreditAmount
                    : restData.fromTokenAmount,
                  txnHash: fromTxhash as HexString,
                }}
                target={{
                  chainId: toChainId,
                  tokenSymbol: toTokenSymbol,
                  amount: toTokenAmount,
                }}
                status={status}
                sequence={sequence}
                receiverValue={receiverValue}
                isCreditToToken={restData.isCreditToToken}
              />
            )
          }
        )
      ) : (
        <p className="py-4 text-center text-base text-wombatBrown1">No pending transaction</p>
      )}
    </div>
  )
}

export default CrossChainPendingSwap

import {
  getDpFormat,
  getPercentageFromTwoWAD,
  strToWad,
  safeWdiv,
  wmul,
} from '@hailstonelabs/big-number-utils'
import { constants, utils } from 'ethers'
import React, { createContext, ReactElement, useContext, useMemo } from 'react'
import { PoolLabels, poolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import {
  PoolLabelTokenSymbolStringType,
  PoolLabelsTokenSymbolsGenericType,
} from '../interfaces/common'
import {
  EarnedBribeRewardType,
  getAprOfEachBribe,
  GetAprOfEachBribeReturnType,
  getBribeIncentivesPerUserVotesPerNDays,
  getEarnedBribeRewards,
  getTotalBribeEfficiencyWad,
} from '../utils/voting'
import { BribeInfoOfEachPool } from './MulticallDataContext/helpers/fetchVotingDataHelper'
import { useWeb3 } from './Web3Context'
import {
  useBribeInfoData,
  useVewomData,
  useVotingData as useStoreVotingData,
} from '../store/MulticallData/hooks'
import { useTokenPrices } from '../store/Prices/hooks'

export interface ContextType {
  nextEpochStartTime: number | null
  total: {
    currentVote: string
    votedPercentage: string
    voteWeightOfEachAsset: {
      vewom: PoolLabelTokenSymbolStringType
      percentage: PoolLabelTokenSymbolStringType
    }
    bribeTokenBalanceInfoOfEachAsset: PoolLabelsTokenSymbolsGenericType<
      { amount: string; tokenSymbol: string }[]
    >
    whiteListOfEachAsset: PoolLabelsTokenSymbolsGenericType<boolean>
  }
  bribeAprData: {
    user: {
      max: GetAprOfEachBribeReturnType
      actual: GetAprOfEachBribeReturnType
    }
    average: GetAprOfEachBribeReturnType
  }
  bribeEfficiencyData: {
    total: string
  }
  user: {
    remainingVote: {
      vewom: string
      percentage: string
    }
    usedVote: {
      vewom: string
      percentage: string
    }
    voteWeightOfEachAsset: {
      vewom: PoolLabelTokenSymbolStringType
      percentage: PoolLabelTokenSymbolStringType
    }
    earnedBribeOfEachAsset: BribeInfoOfEachPool
    earnedBribeRewards: EarnedBribeRewardType[]
    hasEarnedBribeRewards: boolean
  }
}

export const initialVotingDataContext = {
  nextEpochStartTime: null,
  total: {
    currentVote: '0.0',
    votedPercentage: '0.0',
    voteWeightOfEachAsset: {
      vewom: {},
      percentage: {},
    },
    bribeTokenBalanceInfoOfEachAsset: {},
    whiteListOfEachAsset: {},
  },
  bribeAprData: {
    user: { max: {}, actual: {} },
    average: {},
  },
  bribeEfficiencyData: {
    total: '0.0',
  },
  user: {
    remainingVote: {
      vewom: '0.0',
      percentage: '0.0',
    },
    usedVote: {
      vewom: '0.0',
      percentage: '0.0',
    },
    voteWeightOfEachAsset: {
      vewom: {},
      percentage: {},
    },
    earnedBribeOfEachAsset: {},
    earnedBribeRewards: [],
    hasEarnedBribeRewards: false,
  },
} as ContextType

export const VotingDataContext = createContext<ContextType>(initialVotingDataContext)
VotingDataContext.displayName = 'VotingDataContext'

export const useVotingData = (): ContextType => {
  return useContext(VotingDataContext)
}

interface Props {
  children: React.ReactNode
}

export function VotingDataProvider({ children }: Props): ReactElement {
  const { withoutAccount, withAccount } = useStoreVotingData()
  const vewomData = useVewomData()
  const bribeInfoData = useBribeInfoData()
  const { chainId } = useWeb3()
  const { vewomBalanceWad, vewomTotalSupplyWad } = useMemo(() => {
    return {
      vewomBalanceWad: vewomData.withAccount?.vewomBalanceWad || null,
      vewomTotalSupplyWad: vewomData.withoutAccount?.vewomTotalSupplyWad || null,
    }
  }, [vewomData.withAccount?.vewomBalanceWad, vewomData.withoutAccount?.vewomTotalSupplyWad])
  const tokenPrices = useTokenPrices()

  const { newTotalData, newUserData, newBribeEfficiencyData, newBribeAprData, nextEpochStartTime } =
    useMemo(() => {
      let newTotalData = initialVotingDataContext.total
      let newUserData = initialVotingDataContext.user
      const newBribeEfficiencyData = initialVotingDataContext.bribeEfficiencyData
      const newBribeAprData = initialVotingDataContext.bribeAprData
      let nextEpochStartTime = null

      if (withoutAccount) {
        nextEpochStartTime = withoutAccount.nextEpochStartTimes
          ? Math.max(...withoutAccount.nextEpochStartTimes)
          : null
        const totalVoteWeightOfEachAssetInVewom: PoolLabelTokenSymbolStringType = {}
        const totalVoteWeightOfEachAssetInPercentage: PoolLabelTokenSymbolStringType = {}
        const whiteListOfEachAsset: PoolLabelsTokenSymbolsGenericType<boolean> = {}
        for (let i = 0; i < poolLabels.length; i++) {
          const poolSymbol = poolLabels[i]
          const voteInAssets = withoutAccount.weightOfEachAsset[poolSymbol] || {}
          const assetTokenSymbols = Object.keys(voteInAssets)
          for (let j = 0; j < assetTokenSymbols.length; j++) {
            const assetTokenSymbol = assetTokenSymbols[j] as TokenSymbol
            const targetTotalWeight =
              withoutAccount.weightOfEachAsset[poolSymbol]?.[assetTokenSymbol]
            const targetWhiteList =
              withoutAccount.whiteListOfEachAsset[poolSymbol]?.[assetTokenSymbol]
            if (!targetTotalWeight) continue
            totalVoteWeightOfEachAssetInVewom[poolSymbol] = {
              ...(totalVoteWeightOfEachAssetInVewom[poolSymbol] || {}),
              [assetTokenSymbol]: targetTotalWeight,
            }
            totalVoteWeightOfEachAssetInPercentage[poolSymbol] = {
              ...(totalVoteWeightOfEachAssetInPercentage[poolSymbol] || {}),
              [assetTokenSymbol]: getPercentageFromTwoWAD(
                strToWad(targetTotalWeight),
                strToWad(withoutAccount.totalWeight)
              ),
            }
            whiteListOfEachAsset[poolSymbol] = {
              ...(whiteListOfEachAsset[poolSymbol] || {}),
              [assetTokenSymbol]: targetWhiteList,
            }
          }
        }

        newTotalData = {
          currentVote: withoutAccount.totalWeight,
          votedPercentage: getPercentageFromTwoWAD(
            strToWad(withoutAccount.totalWeight),
            vewomTotalSupplyWad || constants.Zero
          ),
          voteWeightOfEachAsset: {
            vewom: totalVoteWeightOfEachAssetInVewom,
            percentage: totalVoteWeightOfEachAssetInPercentage,
          },
          bribeTokenBalanceInfoOfEachAsset: withoutAccount.bribeTokenBalanceInfoOfEachAsset,
          whiteListOfEachAsset: withoutAccount.whiteListOfEachAsset,
        }
      }
      if (withAccount) {
        const userVoteWeightOfEachAssetInVewom: PoolLabelTokenSymbolStringType = {}
        const userVoteWeightOfEachAssetInPercentage: PoolLabelTokenSymbolStringType = {}
        for (let i = 0; i < poolLabels.length; i++) {
          const poolLabel = poolLabels[i]
          const voteInAssets = withAccount.voteOfEachAsset[poolLabel] || {}
          const assetTokenSymbols = Object.keys(voteInAssets)
          for (let j = 0; j < assetTokenSymbols.length; j++) {
            const assetTokenSymbol = assetTokenSymbols[j] as TokenSymbol
            const targetUserWeight = withAccount.voteOfEachAsset[poolLabel]?.[assetTokenSymbol]
            if (!targetUserWeight) continue
            userVoteWeightOfEachAssetInVewom[poolLabel] = {
              ...(userVoteWeightOfEachAssetInVewom[poolLabel] || {}),
              [assetTokenSymbol]: targetUserWeight,
            }
            const userVoteWeightInPercentage = vewomBalanceWad
              ? getPercentageFromTwoWAD(strToWad(targetUserWeight), vewomBalanceWad)
              : '0'
            // if user votes less than 0.01% then set it as 0.00
            userVoteWeightOfEachAssetInPercentage[poolLabel] = {
              ...(userVoteWeightOfEachAssetInPercentage[poolLabel] || {}),
              [assetTokenSymbol]: strToWad(userVoteWeightInPercentage).lt(strToWad('0.01'))
                ? '0.00'
                : getDpFormat(userVoteWeightInPercentage, 2, 'off'),
            }
          }
          /** @todo should check whether bribe rewarders have enough balances for the user's rewards. */
          const earnedBribeRewards = getEarnedBribeRewards(
            withAccount.userEarnedBribeOfEachAsset,
            chainId
          )
          const usedVoteWad = strToWad(withAccount.usedVote)
          newUserData = {
            remainingVote: {
              vewom: utils.formatEther((vewomBalanceWad || constants.Zero).sub(usedVoteWad)),
              percentage: getPercentageFromTwoWAD(
                (vewomBalanceWad || constants.Zero).sub(usedVoteWad),
                vewomBalanceWad || constants.Zero
              ),
            },
            usedVote: {
              vewom: utils.formatEther(usedVoteWad),
              percentage: getPercentageFromTwoWAD(usedVoteWad, vewomBalanceWad || constants.Zero),
            },
            voteWeightOfEachAsset: {
              vewom: userVoteWeightOfEachAssetInVewom,
              percentage: userVoteWeightOfEachAssetInPercentage,
            },
            earnedBribeOfEachAsset: withAccount.userEarnedBribeOfEachAsset,
            earnedBribeRewards,
            hasEarnedBribeRewards: earnedBribeRewards.length > 0,
          }
        }
      } else {
        newUserData = initialVotingDataContext.user
      }

      /**
       * Bribe Rewards, APRs and Efficiency
       */
      if (withoutAccount?.weightOfEachAsset && withoutAccount?.bribeTokenPerSecondOfEachAsset) {
        const annualBribeRewardsPerTotalPoolWeight = getBribeIncentivesPerUserVotesPerNDays({
          chainId,
          poolWeightOfEachAsset: withoutAccount.weightOfEachAsset,
          bribeTokenPerSecondOfEachAsset: withoutAccount.bribeTokenPerSecondOfEachAsset,
          tokenPrices,
          userVotes: 'TOTAL_POOL_WEIGHT',
          numberOfDays: 365,
          isEmissionActiveOfEachAsset: bribeInfoData.withoutAccount?.isEmissionActive,
        })

        if (vewomTotalSupplyWad && !vewomTotalSupplyWad.isZero()) {
          const vewomToWomRatioWad = safeWdiv(
            strToWad(withoutAccount.totalWomStaked),
            vewomTotalSupplyWad
          )
          const voteWeightOfEachAssetInUsd = {} as PoolLabelTokenSymbolStringType
          for (const [poolLablelStr, weightOfEachAsset] of Object.entries(
            withoutAccount.weightOfEachAsset
          )) {
            const poolLablel = poolLablelStr as PoolLabels
            for (const [assetSymbolStr, weightOfAsset] of Object.entries(weightOfEachAsset)) {
              const assetSymbol = assetSymbolStr as TokenSymbol
              voteWeightOfEachAssetInUsd[poolLablel] = {
                ...voteWeightOfEachAssetInUsd[poolLablel],
                [assetSymbol]: utils.formatEther(
                  wmul(wmul(strToWad(weightOfAsset), vewomToWomRatioWad), strToWad(tokenPrices.WOM))
                ),
              }
            }
          }
          newBribeAprData.average = getAprOfEachBribe(
            annualBribeRewardsPerTotalPoolWeight,
            undefined,
            undefined,
            voteWeightOfEachAssetInUsd
          )
        }

        // calculate bribe effciency
        const totalBribeEfficiencyWad = getTotalBribeEfficiencyWad(
          withoutAccount.womPerSec,
          withoutAccount.voteAllocation,
          tokenPrices,
          withoutAccount.bribeTokenPerSecondOfEachAsset
        )

        newBribeEfficiencyData.total = utils.formatEther(totalBribeEfficiencyWad)

        /**
         * with accounts
         */
        if (withAccount?.voteOfEachAsset && vewomBalanceWad) {
          // calculate user bribe apr
          const annualActualBribeRewards = getBribeIncentivesPerUserVotesPerNDays({
            chainId,
            poolWeightOfEachAsset: withoutAccount.weightOfEachAsset,
            bribeTokenPerSecondOfEachAsset: withoutAccount.bribeTokenPerSecondOfEachAsset,
            tokenPrices,
            userVotes: 'ACTUAL',
            numberOfDays: 365,
            userVoteOfEachAsset: withAccount.voteOfEachAsset,
            vewomBalanceWad,
            isEmissionActiveOfEachAsset: bribeInfoData.withoutAccount?.isEmissionActive,
          })

          const annualMaxBribeRewards = getBribeIncentivesPerUserVotesPerNDays({
            chainId,
            poolWeightOfEachAsset: withoutAccount.weightOfEachAsset,
            bribeTokenPerSecondOfEachAsset: withoutAccount.bribeTokenPerSecondOfEachAsset,
            tokenPrices,
            userVotes: 'MAX',
            numberOfDays: 365,
            userVoteOfEachAsset: withAccount.voteOfEachAsset,
            vewomBalanceWad,
            isEmissionActiveOfEachAsset: bribeInfoData.withoutAccount?.isEmissionActive,
          })
          const userStakedWomInUsd = utils.formatEther(
            wmul(strToWad(withAccount.womStaked), strToWad(tokenPrices.WOM))
          )

          newBribeAprData.user.max = getAprOfEachBribe(annualMaxBribeRewards, userStakedWomInUsd)
          newBribeAprData.user.actual = getAprOfEachBribe(
            annualActualBribeRewards,
            userStakedWomInUsd,
            newUserData.voteWeightOfEachAsset.percentage
          )
        } else {
          newBribeAprData.user = { max: {}, actual: {} }
        }
      }
      return {
        newTotalData,
        newUserData,
        newBribeEfficiencyData,
        newBribeAprData,
        nextEpochStartTime,
      }
    }, [
      bribeInfoData.withoutAccount?.isEmissionActive,
      chainId,
      tokenPrices,
      vewomBalanceWad,
      vewomTotalSupplyWad,
      withAccount,
      withoutAccount,
    ])

  return (
    <VotingDataContext.Provider
      value={{
        nextEpochStartTime,
        bribeAprData: newBribeAprData,
        bribeEfficiencyData: newBribeEfficiencyData,
        total: newTotalData,
        user: newUserData,
      }}
    >
      {children}
    </VotingDataContext.Provider>
  )
}

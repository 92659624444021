import { BigNumberish, utils } from 'ethers'
import Image from 'next/image'
import { useState } from 'react'
import { Token } from '../../constants/contract/token/Token'
import SelectToken from '../../public/assets/icons/select-a-token.svg'
import ToggleDown from '../../public/assets/icons/toggle-down.svg'
import SelectTokenModal from '../SelectTokenModal'
import TokenImage from '../TokenImage'
import { TokenSymbolGenericType } from '../../interfaces/common'

export type InputProps = {
  tokens: Token[] | null
  selectedToken: Token | null
  amount: BigNumberish | null
  onAmountChanged: ((value: BigNumberish | null) => void) | null
  onSelectedChange: (token: Token) => void
  balances: TokenSymbolGenericType<string>
}
export default function InputSelect({
  tokens,
  selectedToken,
  amount,
  onAmountChanged,
  onSelectedChange,
  balances,
}: InputProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div className="relative w-full">
        <input
          disabled
          inputMode="numeric"
          className="w-full rounded bg-white px-2 py-1 font-Helvetica text-base"
          type="number"
          autoCorrect="false"
          autoCapitalize="false"
          value={
            amount
              ? parseFloat(
                  utils.formatUnits(amount, selectedToken ? selectedToken.decimals : 18)
                ).toFixed(2)
              : ''
          }
          onChange={(e) => {
            if (e.target.value.length == 0 && onAmountChanged) {
              onAmountChanged(null)
            } else {
              try {
                if (onAmountChanged)
                  onAmountChanged(
                    utils.parseUnits(e.target.value, selectedToken ? selectedToken.decimals : 18)
                  )
                // eslint-disable-next-line no-empty
              } catch (error) {}
            }
          }}
          placeholder="0.00"
        />
        <button
          className={`button-hover-opacity absolute right-2 top-2 flex items-center`}
          onClick={() => setIsOpen(true)}
        >
          {selectedToken ? (
            <TokenImage tokenSymbol={selectedToken?.symbol} width="14" height="14" />
          ) : (
            <>
              <Image src={SelectToken} width={14} height={14} />
            </>
          )}
          <div className="ml-2">
            <Image src={ToggleDown} width={10} height={5} />
          </div>
        </button>
      </div>
      {tokens && (
        <SelectTokenModal
          title="Withdraw in"
          {...{
            isOpen,
            setIsOpen,
            tokens,
            onTokenChange: onSelectedChange,
            balances,
            selectedToken,
            disableTokens: null,
          }}
        />
      )}
    </>
  )
}

import Image from 'next/image'
import { useMemo } from 'react'
import WombatToTheMoonFlyUp from '../../public/assets/animations/ToTheMoon2.gif'
import WombatToTheMoonNoText from '../../public/assets/animations/ToTheMoon_noText.gif'
import arrowRight from '../../public/assets/icons/arrow-right-with-tail.svg'
import Modal from '../../components/Modal'
import { useSwapContext } from '../../context/SwapContext'
import { TOKENS } from '../../constants/contract'
import { useWeb3 } from '../../context/Web3Context'
import TokenDisplay from '../display/TokenDisplay'
import { chainInfos } from '../../constants/web3/chains'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { Token } from '../../constants/contract/token/Token'
import { formatNumberUSLocale } from '../../utils/numberFormat'

type Props = {
  isOpen: boolean
  onClose: () => void | undefined
  isDisplayGifFlyToTheMoon: boolean | undefined
}

export default function SwapConfirmationModal({
  isOpen,
  onClose,
  isDisplayGifFlyToTheMoon,
}: Props) {
  const {
    fromTokenAmount,
    fromTokenSymbol,
    toTokenAmount,
    toTokenSymbol,
    sourceChainId,
    targetChainId,
  } = useSwapContext()
  const { chainId } = useWeb3()

  const renderGif = useMemo(() => {
    if (isDisplayGifFlyToTheMoon) {
      return (
        <div className="fixed top-24 bottom-0 md:absolute md:top-[-106px]">
          <Image src={WombatToTheMoonFlyUp} layout="fixed" width={100} height={450} unoptimized />
        </div>
      )
    }
    return <Image src={WombatToTheMoonNoText} layout="fixed" width={80} height={80} unoptimized />
  }, [isDisplayGifFlyToTheMoon])

  const fromToken = useMemo(() => {
    return TOKENS[chainId][fromTokenSymbol]
  }, [chainId, fromTokenSymbol])

  const toToken = useMemo(() => {
    return TOKENS[chainId][toTokenSymbol]
  }, [chainId, toTokenSymbol])

  const renderSwapInfoBox = (
    chainId: SupportedChainId,
    token: Token,
    amount: string,
    direction: 'From' | 'To'
  ) => {
    const chainInfo = chainInfos[chainId]
    return (
      <div className="rounded-[4px] border-1 border-wombatPurple1 p-[8px_12px] font-semibold text-black">
        <p className="mb-1 flex items-center text-xs">
          <span className="mr-2 font-normal">{direction}</span>
          <Image src={chainInfo.icon} width={22} height={22} />
          &nbsp;
          {chainInfo.label}
        </p>
        <p className="flex items-center justify-between [&_*]:text-base [&_*]:font-semibold">
          <TokenDisplay label={token.displaySymbol} tokenSymbol={token.symbol} />
          <span>{formatNumberUSLocale(amount, 4)}</span>
        </p>
      </div>
    )
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          <p className="text-xl text-wombatPurple1">Waiting for Confirmation</p>
          <p className="text-center text-sm text-[#171003]">Please confirm on your wallet</p>
        </>
      }
      isCenterTitle
      isOverflowHidden
    >
      <div className="relative">
        <div className="flex flex-col  text-wombatCream md:px-14">
          {fromToken && renderSwapInfoBox(sourceChainId, fromToken, fromTokenAmount, 'From')}
          <div className="flex items-center justify-center py-3">
            <Image src={arrowRight} height={24} width={24} style={{ transform: 'rotate(90deg)' }} />
          </div>
          {toToken && renderSwapInfoBox(targetChainId, toToken, toTokenAmount, 'To')}
        </div>

        <div
          className={`flex flex-col items-center justify-center ${
            isDisplayGifFlyToTheMoon ? 'md:pb-20' : ''
          }`}
        >
          {renderGif}
        </div>
      </div>
    </Modal>
  )
}

import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { POOL_STATE } from '../components/PoolPage'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { useSearchParams } from 'react-router-dom'
import { PoolFilterGroup } from '../constants/contract/pool/poolFilterGroup'

interface ContextType {
  poolState: POOL_STATE | null
  selectedPoolSymbol: PoolLabels | null
  selectedAssetTokenSymbol: TokenSymbol | null
  updateSelectedPoolInfo: (
    selectedPoolSymbol: PoolLabels | null,
    selectedAssetTokenSymbol: TokenSymbol | null
  ) => void
  resetPoolPageStates: () => void
  setPoolState: React.Dispatch<React.SetStateAction<POOL_STATE | null>>
  selectedPoolFilterGroup: PoolFilterGroup
  updateSelectedPoolFilterGroup: (selectedPoolFilterGroup: PoolFilterGroup) => void
}

const initialState: ContextType = {
  poolState: null,
  selectedAssetTokenSymbol: null,
  selectedPoolSymbol: null,
  updateSelectedPoolInfo: () => {
    //
  },
  resetPoolPageStates: () => {
    //
  },
  setPoolState: () => {
    //
  },
  selectedPoolFilterGroup: PoolFilterGroup.ALL,
  updateSelectedPoolFilterGroup: () => {
    //
  },
}

const MyContext = createContext<ContextType>(initialState)

interface Props {
  children: React.ReactNode
}

export const usePoolPage = (): ContextType => {
  return useContext(MyContext)
}

function PoolPageProvider({ children }: Props): ReactElement {
  const [poolState, setPoolState] = useState<POOL_STATE | null>(null)
  const [selectedPoolSymbol, setSelectedPoolSymbol] = useState<PoolLabels | null>(null)
  const [selectedAssetTokenSymbol, setSelectedAssetTokenSymbol] = useState<TokenSymbol | null>(null)
  const [selectedPoolFilterGroup, setSelectedPoolFilterGroup] = useState<PoolFilterGroup>(
    PoolFilterGroup.ALL
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const updateSelectedPoolInfo = useCallback(
    (selectedPoolSymbol: PoolLabels | null, selectedAssetTokenSymbol: TokenSymbol | null) => {
      setSelectedPoolSymbol(selectedPoolSymbol)
      setSelectedAssetTokenSymbol(selectedAssetTokenSymbol)
    },
    []
  )

  const resetPoolPageStates = useCallback(() => {
    setPoolState(null)
    setSelectedPoolSymbol(null)
    setSelectedAssetTokenSymbol(null)
  }, [])

  const updateSelectedPoolFilterGroup = useCallback((selectedPoolFilterGroup: PoolFilterGroup) => {
    setSelectedPoolFilterGroup(selectedPoolFilterGroup)
  }, [])

  useEffect(() => {
    const queryPool = searchParams.get('pool') as PoolLabels
    const queryToken = searchParams.get('token') as TokenSymbol
    const queryAction = searchParams.get('action')?.toLowerCase() as POOL_STATE
    if (
      poolState != null &&
      selectedPoolSymbol &&
      selectedAssetTokenSymbol &&
      (queryPool !== selectedPoolSymbol ||
        queryToken !== selectedAssetTokenSymbol ||
        queryAction !== poolState.toLowerCase())
    ) {
      const newQuery: Record<string, string> = {}
      newQuery['pool'] = selectedPoolSymbol
      newQuery['token'] = selectedAssetTokenSymbol
      newQuery['action'] = (poolState ?? POOL_STATE.DEPOSIT).toUpperCase()
      setSearchParams(newQuery)
    }
    // do not put searchParams in the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolState, selectedAssetTokenSymbol, selectedPoolSymbol, setSearchParams])

  return (
    <MyContext.Provider
      value={{
        poolState,
        setPoolState,
        selectedPoolSymbol,
        selectedAssetTokenSymbol,
        updateSelectedPoolInfo,
        resetPoolPageStates,
        selectedPoolFilterGroup,
        updateSelectedPoolFilterGroup,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

export default PoolPageProvider

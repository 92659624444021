import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const ETHEREUM_MAINNET_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0xA45C0ABeef67C363364E0e73832df9986aBa3800',
  },
  [PoolLabels.MAIN]: {
    name: 'Main Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0x89B88A45E23978b38A14695b63F475d4e4CcaF95',
    version: 'volatile_v2',
  },
  [PoolLabels.FRAX]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x9c02eaf31EFE3FeE36ebE5AEBCa12Ca979dF25cC',
    isHiddenInPoolPage: true,
  },
  [PoolLabels.ETHx]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x647CC8816C2d60A5fF4d1ffeF27a5b3637d5ac81',
  },
  [PoolLabels.frxETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x3161f40EA6c0C4cC8b2433d6D530EF255816E854',
  },
  [PoolLabels.USDV]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x05A33c0eaf81367Ce953d2dCd4ea1BE8758f4D32',
  },
  [PoolLabels.wstETH]: {
    name: 'Lido Pool',
    filterGroup: PoolFilterGroup.LSD,
    address: '0xF8E32cA46AC28799c8FB7DCe1Ac11A4541160734',
  },
  [PoolLabels.EURA]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x0020A8890e723CD94660A5404C4BCCBB91680db6',
  },
  [PoolLabels.wmxWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0xe43c1695df76CcA4D6079061924D7150Fd553c21',
  },
  [PoolLabels.mWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0xcf2e56E086fcD21eaB3614A5A78c8Ae27c2F0536',
  },
}

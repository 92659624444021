import React from 'react'

type Props = {
  className?: string
  fill?: string
  size?: number
}

function SearchIcon({ className, fill = '#4F4B5C', size = 17 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C8.57234 14 10.0236 13.4816 11.1922 12.6064L14.7071 16.1213L16.1213 14.7071L12.6064 11.1922C13.4816 10.0236 14 8.57233 14 7C14 3.13401 10.866 0 7 0ZM2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7Z"
        fill={fill}
      />
    </svg>
  )
}

export default SearchIcon

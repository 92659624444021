import {
  EthereumProvider,
  IWidgetParams,
  OkxEventListeners,
  OkxEvents,
  OkxSwapWidgetHandler,
  ProviderType,
  createOkxSwapWidget,
} from '@okxweb3/dex-widget'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useAccount } from 'wagmi'
import { useWalletSelected } from '../../context/WalletSelectedContext'
export default function WidgetWom() {
  const widgetRef = useRef(null)
  const instance = useRef<OkxSwapWidgetHandler>()
  const { open: openModalSelectWallet } = useWalletSelected()
  const { connector, address } = useAccount()
  const [providerFromWindow, setProviderFromWindow] = useState()
  const getProviderType = useCallback(() => {
    console.log('getProviderType====>', connector)
    // @ts-expect-error not check type
    const isWalletConnect = connector?.id === 'walletConnect' || connector?.type === 'walletConnect'

    if (!connector || !address) {
      return undefined
    }
    const providerType = isWalletConnect ? ProviderType.WALLET_CONNECT : ProviderType.EVM
    return providerType
  }, [connector, address])

  const getProviderFromConnectorOrWindow = useCallback(async () => {
    const providerFromWagmi = await connector?.getProvider?.()
    setProviderFromWindow(providerFromWagmi)
    console.log('log-providerFromWagmi.chainId', providerFromWagmi?.chainId)

    return providerFromWagmi
  }, [connector])

  const updateProvider = useCallback(async () => {
    const provider = await getProviderFromConnectorOrWindow()
    console.log('provider====>', provider, getProviderType())
    if (instance.current) {
      instance.current?.updateProvider(
        provider as EthereumProvider,
        getProviderType() as ProviderType
      )
    }
  }, [getProviderType, getProviderFromConnectorOrWindow])

  const renderWidget = useCallback(async () => {
    const provider = await getProviderFromConnectorOrWindow()
    const providerType = getProviderType()
    const listeners: OkxEventListeners = [
      {
        event: OkxEvents.ON_PRESIGNED_ORDER,
        handler: (payload) => {
          console.log('Received data:', payload, window)
        },
      },
      {
        event: OkxEvents.ON_CONNECT_WALLET,
        handler: (payload) => {
          console.log('NO_WALLET_CONNECT===>', payload)
          openModalSelectWallet?.()
        },
      },
      {
        event: OkxEvents.ON_FROM_CHAIN_CHANGE,
        handler: (payload) => {
          const { params } = payload?.[0] || {
            params: ['{}'],
          }
          const argsStr = params?.[0]
          const args = typeof argsStr === 'string' ? JSON.parse(argsStr) : argsStr

          console.log('onFromChainChange', args)

          const { token, preToken } = args

          const providerMap = {
            // @ts-expect-error not check type
            [ProviderType.SOLANA]: window?.okexchain?.solana || window?.solana,
            [ProviderType.EVM]: window?.ethereum,
          }

          if (preToken && providerType !== ProviderType.WALLET_CONNECT) {
            if (Number(token.chainId) === 501) {
              console.log('update provider solana')
              instance.current?.updateProvider(
                providerMap[ProviderType.SOLANA],
                ProviderType.SOLANA
              )
            } else if (Number(preToken.chainId) === 501) {
              console.log('update provider evm')
              // @ts-expect-error not check type
              instance.current?.updateProvider(providerMap[ProviderType.EVM], ProviderType.EVM)
            }
          }
        },
      },
    ]
    const params = {
      width: 375,
      providerType: providerType || 'EVM',
      tokenPair: {
        fromChain: 56,
        toChain: 56,
        fromToken: '', // BNB
        toToken: '0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1', // WOM
      },
    } as IWidgetParams
    if (widgetRef.current) {
      instance.current = createOkxSwapWidget(widgetRef.current, { params, listeners, provider })
    }
  }, [getProviderFromConnectorOrWindow, getProviderType, openModalSelectWallet])

  useEffect(() => {
    if (!connector?.getProvider || !address) {
      if (providerFromWindow) {
        instance.current?.updateProvider(null!, ProviderType.EVM)
        instance.current?.destroy?.()
        renderWidget()
      }
      // widgetRef.current
      return
    }
    updateProvider()
  }, [connector, updateProvider, address, providerFromWindow, renderWidget])

  useEffect(() => {
    !instance.current && renderWidget()
  }, [renderWidget])

  useEffect(() => {
    return () => {
      instance.current?.destroy?.()
    }
  }, [])
  return (
    <div className="flex h-[100vh] flex-1 items-center justify-center">
      <div ref={widgetRef}></div>
    </div>
  )
}

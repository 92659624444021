import { AssetConfig } from '..'
import { defaultAssetConfig } from '../defaultAssetConfig'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'

export const OPTIMISM_MAINNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#A88FBD',
      hasBribeV2: true,
    },
    [TokenSymbols['USDC.e']]: {
      name: 'USDC.e',
      color: '#A88FBD',
      hasBribeV2: true,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#C2A2FF',
      hasBribeV2: true,
    },
  },
  [PoolLabels.frxETH]: {
    [TokenSymbols.frxETH]: {
      name: 'Frax Ether',
      color: '#C2A2FF',
      // enableVoting: true,
    },
    [TokenSymbols.WETH]: {
      name: 'ETH',
      color: '#E5C5FF',
      enableVoting: true,
      displaySymbol: 'ETH',
    },
    [TokenSymbols.sfrxETH]: {
      name: 'Frax Staked Ether',
      color: '#A88FBD',
      // enableVoting: true,
    },
  },
  [PoolLabels.FRAX]: {
    [TokenSymbols.FRAX]: {
      name: 'FRAX',
      color: '#373131',
      // enableVoting: true,
    },
    [TokenSymbols.USDC]: {
      name: 'USDC',
      color: '#426A98',
      // enableVoting: true,
    },
  },
  [PoolLabels.USDV]: {
    [TokenSymbols.USDV]: {
      name: 'USDV',
      color: '#D4D4D4',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#767676',
    },
  },
  [PoolLabels.DOLA]: {
    [TokenSymbols.USDC]: {
      name: 'USDC.e',
      color: '#426A98',
    },
    [TokenSymbols.DOLA]: {
      name: 'DOLA',
      color: '#767676',
    },
  },
}

import { configureChains } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import { createClient } from 'wagmi'
import { chainInfos } from './web3/chains'
import { SUPPORTED_WALLETS } from './web3/wallets'

const { provider } = configureChains(Object.values(chainInfos), [publicProvider()])

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: Object.values(SUPPORTED_WALLETS).map((wallet) => wallet.connector),
  provider,
})

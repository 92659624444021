import clsx from 'clsx'
import React from 'react'
import styles from './ScrollableBox.module.scss'
interface Props {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
  scrollDirection?: 'horizontal' | 'vertical' | 'both'
}

function ScrollableBox({ children, className, style, scrollDirection }: Props) {
  const getDefaultStyle = (): React.CSSProperties => {
    let style: React.CSSProperties = {}
    switch (scrollDirection) {
      case 'horizontal':
        style = {
          paddingRight: 0,
          overflowX: 'auto',
          overflowY: 'hidden',
        }
        break
      case 'vertical':
        style = {
          paddingBottom: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
        }
        break
      case 'both':
        style = {
          overflow: 'auto',
        }
        break
    }
    return style
  }
  return (
    <div
      style={{ ...getDefaultStyle(), ...style }}
      className={clsx(styles.ScrollableBox, className)}
    >
      {children}
    </div>
  )
}

export default ScrollableBox

import { HexString } from '../../interfaces/contract'
import { SupportedChainId } from '../web3/supportedChainId'
import { POOL_ABI } from './abis/pool'
import { Contract } from './Contract'

export class StandalonePool extends Contract<typeof POOL_ABI> {
  constructor({ chainId, address }: { chainId: SupportedChainId; address: HexString }) {
    super({
      chainId,
      address,
      abi: POOL_ABI,
    })
  }
}

export const StandalonePools: { [id in SupportedChainId]?: StandalonePool[] | [] } = {
  [SupportedChainId.BSC_MAINNET]: [
    new StandalonePool({
      chainId: SupportedChainId.BSC_MAINNET,
      address: '0x6569DDC1Cc2648c89BC8025046A7dd65EB8940F3',
    }),
    new StandalonePool({
      chainId: SupportedChainId.BSC_MAINNET,
      address: '0xaded24B510a137b05a8eD958a029DACD6a59efDc',
    }),
    new StandalonePool({
      chainId: SupportedChainId.BSC_MAINNET,
      address: '0xfcd11c01c14e4c12C3F9835CD5192fE774038d46',
    }),
  ],
  [SupportedChainId.BSC_TESTNET]: [
    new StandalonePool({
      chainId: SupportedChainId.BSC_TESTNET,
      /** We assume Side Pool as Standalone Pool */
      address: '0x32997a613fbabe911887e296c27f55d735d55084',
    }),
  ],
  [SupportedChainId.ARBITRUM_ONE]: [
    new StandalonePool({
      chainId: SupportedChainId.ARBITRUM_ONE,
      address: '0xd64816fbdf50a1c4aea456a4006ad21a928305f3',
    }),
  ],
}

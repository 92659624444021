import axios from 'axios'
import React, { ReactChild, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { chainInfos } from '../constants/web3/chains'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { useWeb3 } from './Web3Context'

interface IRefreshTxnReceiptContext {
  receiptTxn: ITxn[] | string | null
  isLoaddingReceiptTxn: boolean
  refreshTxnReceipt(): void
  clearTxnReceipt(): void
}

interface ITxn {
  hash: string
  blockNumber: string
  timeStamp: string
}

const Context = React.createContext<IRefreshTxnReceiptContext>({
  receiptTxn: null,
  isLoaddingReceiptTxn: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  refreshTxnReceipt: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearTxnReceipt: () => {},
})

export const TxnReceiptProvider = ({ children }: { children: ReactChild }) => {
  const [isLoaddingReceiptTxn, setIsLoaddingReceiptTxn] = useState(false)
  const [receiptTxn, setReceiptTxn] = useState<ITxn[] | null>(null)
  const { chainId, account } = useWeb3()
  const explorerApi = chainInfos[chainId].explorerApi

  const getTxnReceipt = useCallback(async () => {
    if (!chainId || !account || !explorerApi) return
    let apiKey: string | undefined = ''
    switch (chainId) {
      case SupportedChainId.ARBITRUM_ONE:
        apiKey = process.env.NEXT_PUBLIC_ARBISCAN_API_KEY
        break
      default:
        apiKey = process.env.NEXT_PUBLIC_BSCSCAN_API_KEY
        break
    }
    const urlToGet = `${explorerApi}?module=account&action=txlist&address=${account}&startblock=0&endblock=99999999&page=1&offset=5&sort=desc&apikey=${apiKey}`
    try {
      setIsLoaddingReceiptTxn(true)
      const res = await axios.get(urlToGet)
      if (res) {
        setIsLoaddingReceiptTxn(false)
        const items = res.data.result
        setReceiptTxn(items)
      } else {
        setIsLoaddingReceiptTxn(false)
      }
    } catch (error) {
      setIsLoaddingReceiptTxn(false)
      const errInfo = '@axios.get\n' + `urlToGet: ${urlToGet}\n`
      console.error(errInfo, error)
    }
  }, [explorerApi, account, chainId])

  // Mount one time when component reder
  useEffect(() => {
    getTxnReceipt()
  }, [getTxnReceipt])

  // Refresh data after swap
  const refreshTxnReceipt = useCallback(() => {
    getTxnReceipt()
  }, [getTxnReceipt])

  const clearTxnReceipt = useCallback(() => {
    setReceiptTxn(null)
  }, [])

  const contextValue = useMemo(
    () => ({
      refreshTxnReceipt,
      clearTxnReceipt,
      receiptTxn,
      isLoaddingReceiptTxn,
    }),
    [clearTxnReceipt, refreshTxnReceipt, receiptTxn, isLoaddingReceiptTxn]
  )

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const useTxnReceipt = () => {
  return useContext(Context)
}

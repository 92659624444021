import { strToWad, wsqrt } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils, constants } from 'ethers'
import { RewardsBreakDown } from '../interfaces/pool'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { WAD, safeWdiv, wmul } from '@hailstonelabs/big-number-utils'
import { UserInfo } from '../interfaces/masterWombat'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { readContract } from '@wagmi/core'
import { CROSS_CHAIN_POOL_ABI } from '../constants/contract/abis/pool'
import { POOLS } from '../constants/contract/pool'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { HexString } from '../interfaces/contract'
import { AssetProperty } from '../store/Asset/types'

export const getMinimumReceived = (toAmount: BigNumber, slippage: string): BigNumber => {
  const factor = safeWdiv(WAD, utils.parseEther(slippage).add(WAD))
  return wmul(toAmount, factor)
}
/**
 * Calculates the minimum credit received based on a specified credit amount and slippage.
 * @param {BigNumber} creditAmount - The Credit amount in Wad
 * @param {string} slippage - The value in decimal format (e.g. "0.05" for 5%).
 * @returns {BigNumber} - The calculated minimum credit received.
 */
export const getMinimumCreditReceived = (creditAmount: BigNumber, slippage: string): BigNumber => {
  const factor = wsqrt(safeWdiv(WAD, utils.parseEther(slippage).add(WAD)))
  return wmul(creditAmount, factor)
}
/**
 * Calculates the average fee sharing APR over a given period of time.
 * @param {Object} liabilitiesWad - Liabilities object containing "new" and "old" WAD values.
 * @param {Object} totalSuppliesWad - Total supplies object containing "new" and "old" WAD values.
 * @param {number} daysBetweenNewAndOldData - Number of days between the "new" and "old" data points.
 * @returns {string | undefined} average fee sharing apr in percentage
 */
export const calculateAvgFeeSharingApr = ({
  liabilitiesWad,
  totalSuppliesWad,
  daysBetweenNewAndOldData,
}: {
  liabilitiesWad: { new: BigNumber; old: BigNumber }
  totalSuppliesWad: { new: BigNumber; old: BigNumber }
  daysBetweenNewAndOldData: number
}) => {
  if (totalSuppliesWad.old.isZero() || totalSuppliesWad.new.isZero()) return
  const newLpToTokenRatioWad = safeWdiv(liabilitiesWad.new, totalSuppliesWad.new)
  const oldLpToTokenRatioWad = safeWdiv(liabilitiesWad.old, totalSuppliesWad.old)
  if (strToWad(daysBetweenNewAndOldData.toString()).isZero() || oldLpToTokenRatioWad.isZero())
    return
  // averageApr = (new - old / old) / days * 365 * 100%
  return utils.formatEther(
    safeWdiv(
      wmul(newLpToTokenRatioWad.sub(oldLpToTokenRatioWad), strToWad('365')).mul(100),
      oldLpToTokenRatioWad.mul(daysBetweenNewAndOldData)
    )
  )
}

export const getTotalClaimableRewardInUsdWad = (
  rewardsBreakdown: RewardsBreakDown[],
  tokenPrices: { [token in TokenSymbol]?: string }
) => {
  const totalRewardsAmountInUsdWad = rewardsBreakdown.reduce(
    (acc, { tokenSymbol, amount: rewardAmount }) => {
      const rewardPrice = tokenPrices[tokenSymbol as TokenSymbol]
      return acc.add(wmul(strToWad(rewardAmount), strToWad(rewardPrice)))
    },
    constants.Zero
  )
  return totalRewardsAmountInUsdWad
}

/**

Checks if the rewarder has drained by comparing the rewarder balance and user info.
Returns a boolean indicating whether the rewarder has drained.
@param {AssetProperty<string[]>} rewarderBalance - The rewarder balance object containing pool and asset balances.
@param {AssetProperty<UserInfo>} userInfos - The user info object containing pool and asset information.
@returns {boolean} - Returns true if the rewarder has drained; otherwise, returns false.
*/
export const checkHasRewarderDrained = (
  rewarderBalance: AssetProperty<string[]>,
  userInfos: AssetProperty<UserInfo>
) => {
  for (const [poolLabels, rewarderBalanceOfEachPool] of Object.entries(rewarderBalance)) {
    for (const [tokenSymbol, rewarderBalanceOfEachAsset] of Object.entries(
      rewarderBalanceOfEachPool
    )) {
      const hasUserStaked =
        userInfos[poolLabels as PoolLabels][tokenSymbol as TokenSymbol]?.amount.gt('0')
      if (
        hasUserStaked &&
        rewarderBalanceOfEachAsset.some((rewarderBalance) => strToWad(rewarderBalance).isZero())
      )
        return true
    }
  }
  return false
}

interface PotentialDepositForCrossChainPoolQuotation {
  chainId: SupportedChainId
  tokenAddress: HexString
  tokenAmountBN: BigNumber
}

/**
 * Retrieves the potential deposit for a crosschain pool quotation.
 *
 * @param {Object} params - The parameters for the potential deposit quotation.
 * @param {number} params.chainId - The chain ID.
 * @param {string} params.tokenAddress - The token address.
 * @param {BN} params.tokenAmountBN - The token amount as a BigNumber.
 * @returns {Promise<Object>} - The liquidity object.
 * @throws {Error} - Throws an error if no pool address is found.
 */

export const getPotentialDepositForCrossChainPoolQuotation = async ({
  chainId,
  tokenAddress,
  tokenAmountBN,
}: PotentialDepositForCrossChainPoolQuotation) => {
  const poolAddress = POOLS[chainId][PoolLabels.CROSS_CHAIN]?.address
  if (!poolAddress) throw new Error('no pool address')
  try {
    const liquidity = await readContract({
      chainId,
      address: poolAddress,
      abi: CROSS_CHAIN_POOL_ABI,
      functionName: 'quotePotentialDeposit',
      args: [tokenAddress, tokenAmountBN],
    })
    return liquidity
  } catch (error) {
    const errInfo =
      '@crossChainPool.quotePotentialDeposit\n' +
      `amount: ${tokenAmountBN.toString()}\n` +
      `poolAddr: ${poolAddress}\n`
    console.error(errInfo, error)
  }
}

import { useAppSelector } from '../hooks'
import { selectDelistedData, selectMasterWombatMigrationData, selectMw2Data } from './selectors'

export const useMasterWombatMigrationData = () => {
  return useAppSelector(selectMasterWombatMigrationData)
}

export const useDelistedData = () => {
  return useAppSelector(selectDelistedData)
}

export const useMw2Data = () => {
  return useAppSelector(selectMw2Data)
}

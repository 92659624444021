import { BigNumber, utils } from 'ethers'
import { sqrt } from './math'

// eslint-disable-next-line @typescript-eslint/no-loss-of-precision
const lockConstant = 0.0261622379926302
const lockBase = 2

export function getFactor(depositAmount: BigNumber, veWomAmount: BigNumber): BigNumber {
  return sqrt(depositAmount.mul(veWomAmount))
}

// low precision function to approximate veWOM locking amount
export function getExpectedVeWom(womAmount: BigNumber, lockDay: number): number {
  // veWOM = 0.026 * lockDays^0.5 * WOM
  if (lockDay < 7) return 0
  return lockConstant * Math.pow(lockDay, 1 / lockBase) * Number(utils.formatEther(womAmount))
}

// low precision function to approximate the number of lockday for a lock position
export function getLockDay(veWomBalance: number, womBalance: number): number {
  return Math.round(Math.pow(veWomBalance / womBalance / lockConstant, lockBase))
}

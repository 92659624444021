import React, { ReactChild, useCallback, useContext, useEffect, useState } from 'react'
import { getSIDName } from '../utils/spaceId'
import { useWeb3 } from './Web3Context'

const SpaceIdContext = React.createContext<string>('')

export const SpaceIdProvider = ({ children }: { children: ReactChild }) => {
  const { multicallProvider, account } = useWeb3()

  const [sidName, setSidName] = useState('')
  const fetchSidName = useCallback(async () => {
    if (!multicallProvider || !account) return
    const res = await getSIDName(account, multicallProvider)
    setSidName(res)
  }, [account, multicallProvider])

  useEffect(() => {
    fetchSidName()
  }, [fetchSidName])

  return <SpaceIdContext.Provider value={sidName}>{children}</SpaceIdContext.Provider>
}

export const useSpaceId = () => {
  return useContext(SpaceIdContext)
}

import { RootState } from '../store'

export const selectTokenPrices = (state: RootState) => state.tokenPrice.tokenPrices

export const selectExchangeRatesFromUniswapV3 = (state: RootState) =>
  state.tokenPrice.exchangeRatesFromUniswapV3

export const selectPythPrices = (state: RootState) => state.tokenPrice.pythPrices

export const selectCoingeckoPrices = (state: RootState) => state.tokenPrice.coingeckoPrices

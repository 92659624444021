import { BigNumber, ContractTransaction } from 'ethers'
import { useState } from 'react'
import { useContract } from 'wagmi'
import { LATEST_MASTER_WOMBATS } from '../constants/contract'
import { MASTER_WOMBAT_V3_ABI } from '../constants/contract/abis/masterWombat'
import { ASSETS } from '../constants/contract/asset'
import { useWeb3 } from '../context/Web3Context'
import { useMasterWombat } from '../context/masterWombatContext'
import { addErrorToast, addSuccessToast } from '../store/Toast/actions'
import { useAppDispatch } from '../store/hooks'
import { calculateGasMargin } from '../utils'
import { useTxnReceipt } from './../context/TxnReceiptProvider'

export enum HandleClaimState {
  UNKNOWN,
  LOADING,
  FAILED,
  SUCCESS,
}

export default function useHandleClaim(onTxnSubmited: (txn: ContractTransaction) => void) {
  const { signer, chainId } = useWeb3()
  // add abi for solving type error (masterWombat abi is V2|V3)
  const masterWombatContract = useContract({
    ...LATEST_MASTER_WOMBATS[chainId].get(),
    abi: MASTER_WOMBAT_V3_ABI,
    signerOrProvider: signer,
  })
  const [claimState, setClaimState] = useState(HandleClaimState.UNKNOWN)
  const [txnHash, setTxnHash] = useState('')
  const [isNewTxn, setIsNewTxn] = useState(false)
  const dispatch = useAppDispatch()
  const { allBonusTokenRewards } = useMasterWombat()

  const { refreshTxnReceipt } = useTxnReceipt()
  async function tryHandleClaim() {
    if (!masterWombatContract) {
      return
    }

    const pidBns: BigNumber[] = []
    allBonusTokenRewards.assetsWithNonZeroRewards.forEach(({ poolSymbol, assetTokenSymbol }) => {
      const asset = ASSETS[chainId][poolSymbol][assetTokenSymbol]
      if (!asset) return
      const pid = asset.pid
      pidBns.push(BigNumber.from(pid))
    })
    if (pidBns.length === 0) {
      return
    }

    setClaimState(HandleClaimState.LOADING)

    masterWombatContract.estimateGas
      .multiClaim(pidBns)
      .then((estimateGas) =>
        masterWombatContract.multiClaim(pidBns, { gasLimit: calculateGasMargin(estimateGas) })
      )
      .then((txn) => {
        onTxnSubmited(txn)
        setTxnHash(txn.hash)
        setIsNewTxn(true)
        setTimeout(() => {
          setClaimState(HandleClaimState.SUCCESS)
          setIsNewTxn(false)
        }, 3000)
        txn.wait().then(() => {
          refreshTxnReceipt()
          dispatch(
            addSuccessToast({
              message: 'Success',
              title: 'Claim Completed',
              txHash: txn.hash,
            })
          )
        })
      })
      .catch((error) => {
        dispatch(
          addErrorToast({
            message: 'Error',
            title: 'Claim Failed',
          })
        )
        setClaimState(HandleClaimState.FAILED)
        const _pids = pidBns.map((pid) => pid.toString())
        const errInfo = '@MasterWombat.multiClaim\n' + `_pids: ${_pids}\n`
        console.error(errInfo, error)
      })
  }

  return { claimState, tryHandleClaim, txnHash, isNewTxn }
}

import { SupportedChainId } from '../../web3/supportedChainId'
import { Contract } from '../Contract'
import {
  MASTER_WOMBAT_V2_ABI,
  MASTER_WOMBAT_V3_ABI,
  MASTER_WOMBAT_BOOSTED_ABI,
} from '../abis/masterWombat'
import { HexString } from '../../../interfaces/contract'

export enum MasterWombatId {
  MW2 = 'MW2',
  MW3 = 'MW3',
  BOOSTED = 'BOOSTED',
}

const masterWombatABIs = {
  [MasterWombatId.MW2]: MASTER_WOMBAT_V2_ABI,
  [MasterWombatId.MW3]: MASTER_WOMBAT_V3_ABI,
  [MasterWombatId.BOOSTED]: MASTER_WOMBAT_BOOSTED_ABI,
}

export class MasterWombat extends Contract<
  typeof MASTER_WOMBAT_V2_ABI | typeof MASTER_WOMBAT_V3_ABI | typeof MASTER_WOMBAT_BOOSTED_ABI
> {
  readonly masterWombatId: MasterWombatId
  constructor(address: HexString, chainId: SupportedChainId, masterWombatId: MasterWombatId) {
    super({ address, abi: masterWombatABIs[masterWombatId], chainId })
    this.masterWombatId = masterWombatId
  }
}

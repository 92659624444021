/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { SupportedChainId } from '../../web3/supportedChainId'
import { MasterWombat, MasterWombatId } from './MasterWombat'

const MASTER_WOMBATS: {
  [id in SupportedChainId]?: {
    [id in MasterWombatId]?: MasterWombat
  }
} = {
  [SupportedChainId.BSC_TESTNET]: {
    [MasterWombatId.MW2]: new MasterWombat(
      '0x78BAEc04D81fCF87551a8495d87035911a7875C6',
      SupportedChainId.BSC_TESTNET,
      MasterWombatId.MW2
    ),
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x8C0e9334DBFAC1b9184bC01Ef638BA705cc13EaF',
      SupportedChainId.BSC_TESTNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.BSC_MAINNET]: {
    [MasterWombatId.MW2]: new MasterWombat(
      '0xE2C07d20AF0Fb50CAE6cDD615CA44AbaAA31F9c8',
      SupportedChainId.BSC_MAINNET,
      MasterWombatId.MW2
    ),
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x489833311676B566f888119c29bd997Dc6C95830',
      SupportedChainId.BSC_MAINNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x62A83C6791A3d7950D823BB71a38e47252b6b6F4',
      SupportedChainId.ARBITRUM_ONE,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.FUJI]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0xBA3A9EC587722BB78De2860beaFb880daFB0fD3D',
      SupportedChainId.FUJI,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.ETHEREUM_MAINNET]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0xC9bFC3eFeFe4CF96877009F75a61F5c1937e5d1a',
      SupportedChainId.ETHEREUM_MAINNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x01722147c9502ECCbd68bB328A47A080C13D8108',
      SupportedChainId.SCROLL_SEPOLIA_TESTNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.SCROLL_MAINNET]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0xbc007EEA00EA4b240E803A7dD39026554fBbA64a',
      SupportedChainId.SCROLL_MAINNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.SKALE_TESTNET]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x2c5bdB54A5A2C899D7a8BAA091EEcd0bCB2Bdd31',
      SupportedChainId.SKALE_TESTNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.OP_MAINNET]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x25C9dd8a3774EF7C918cd28ff59cF9e29504C914',
      SupportedChainId.OP_MAINNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.POLYGON_MAINNET]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0xE85f625404eef8a55D9A8B385777d7C8E45E0C73',
      SupportedChainId.POLYGON_MAINNET,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.BASE]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x6521a549834F5E6d253CD2e5F4fbe4048f86cd7b',
      SupportedChainId.BASE,
      MasterWombatId.BOOSTED
    ),
  },
  [SupportedChainId.AVAX]: {
    [MasterWombatId.BOOSTED]: new MasterWombat(
      '0x6521a549834F5E6d253CD2e5F4fbe4048f86cd7b',
      SupportedChainId.AVAX,
      MasterWombatId.BOOSTED
    ),
  },
  /** @todo unhide when needed  */
  // [SupportedChainId.ZKSYNC_MAINNET]: {
  //   [MasterWombatId.BOOSTED]: new MasterWombat(
  //     '0x',
  //     SupportedChainId.ZKSYNC_MAINNET,
  //     MasterWombatId.BOOSTED
  //   ),
  // },
  // [SupportedChainId.ZKSYNC_TESTNET]: {
  //   [MasterWombatId.BOOSTED]: new MasterWombat(
  //     '0x1ffEf0bf395d80c33e29DbAf93210Bb5ed9Fb6E4',
  //     SupportedChainId.ZKSYNC_TESTNET,
  //     MasterWombatId.BOOSTED
  //   ),
  // },
}

const LATEST_MASTER_WOMBATS: { [id in SupportedChainId]: MasterWombat } = {
  [SupportedChainId.BSC_TESTNET]: MASTER_WOMBATS[SupportedChainId.BSC_TESTNET]?.BOOSTED!,
  [SupportedChainId.BSC_MAINNET]: MASTER_WOMBATS[SupportedChainId.BSC_MAINNET]?.BOOSTED!,
  [SupportedChainId.ARBITRUM_ONE]: MASTER_WOMBATS[SupportedChainId.ARBITRUM_ONE]?.BOOSTED!,
  [SupportedChainId.FUJI]: MASTER_WOMBATS[SupportedChainId.FUJI]?.BOOSTED!,
  [SupportedChainId.ETHEREUM_MAINNET]: MASTER_WOMBATS[SupportedChainId.ETHEREUM_MAINNET]?.BOOSTED!,
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]:
    MASTER_WOMBATS[SupportedChainId.SCROLL_SEPOLIA_TESTNET]?.BOOSTED!,
  [SupportedChainId.SCROLL_MAINNET]: MASTER_WOMBATS[SupportedChainId.SCROLL_MAINNET]?.BOOSTED!,
  [SupportedChainId.SKALE_TESTNET]: MASTER_WOMBATS[SupportedChainId.SKALE_TESTNET]?.BOOSTED!,
  [SupportedChainId.OP_MAINNET]: MASTER_WOMBATS[SupportedChainId.OP_MAINNET]?.BOOSTED!,
  [SupportedChainId.POLYGON_MAINNET]: MASTER_WOMBATS[SupportedChainId.POLYGON_MAINNET]?.BOOSTED!,
  [SupportedChainId.AVAX]: MASTER_WOMBATS[SupportedChainId.AVAX]?.BOOSTED!,
  [SupportedChainId.BASE]: MASTER_WOMBATS[SupportedChainId.BASE]?.BOOSTED!,
  /** @todo unhide it when needed */
  // [SupportedChainId.ZKSYNC_MAINNET]: MASTER_WOMBATS[SupportedChainId.ZKSYNC_MAINNET]?.BOOSTED!,
  // [SupportedChainId.ZKSYNC_TESTNET]: MASTER_WOMBATS[SupportedChainId.ZKSYNC_TESTNET]?.BOOSTED!,
}
export { MasterWombatId, MASTER_WOMBATS, LATEST_MASTER_WOMBATS }

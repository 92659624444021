import { TOKENS } from '../../../constants/contract'
import { FetchPriceMethod } from '../../../constants/contract/token/Token'
import { UniswapV3Pool } from '../../../constants/contract/uniswap/UniswapV3Pool'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { TokenSymbolGenericType } from '../../../interfaces/common'
import { HexString } from '../../../interfaces/contract'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'

export type UniswapV3PoolData = TokenSymbolGenericType<{
  token0?: HexString | null
  token1?: HexString | null
  fee?: number | null
}>

export function fetchUniswapV3PoolData(chainId: SupportedChainId) {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: UniswapV3PoolData = {}
  for (const token of Object.values(TOKENS[chainId])) {
    if (token.fetchPriceData.method !== FetchPriceMethod.UNISWAPV3) {
      continue
    }
    const uniswapV3Pool = new UniswapV3Pool({
      address: token.fetchPriceData.payload.poolAddress,
      chainId,
    })
    const tokenSymbol = token.symbol
    contractCalls.push(uniswapV3Pool.multicall('token0'))
    callbacks.push((value: HexString) => {
      states[tokenSymbol] = {
        ...states[tokenSymbol],
        token0: value,
      }
    })
    contractCalls.push(uniswapV3Pool.multicall('token1'))
    callbacks.push((value: HexString) => {
      states[tokenSymbol] = {
        ...states[tokenSymbol],
        token1: value,
      }
    })
    contractCalls.push(uniswapV3Pool.multicall('fee'))
    callbacks.push((value: number) => {
      states[tokenSymbol] = {
        ...states[tokenSymbol],
        fee: value,
      }
    })
  }
  return {
    contractCalls,
    callbacks,
    states,
  }
}

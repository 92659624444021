import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { useWeb3 } from './Web3Context'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { TOKENS } from '../constants/contract'
import { useUserPreference } from './userPreferenceContext'
import { getSwapCreditForTokenQuotation } from '../utils/crossChainSwap'
import { CROSS_CHAIN_SWAP_GAS_LIMIT, MARKET_CREDIT } from '../constants/common'
import { utils } from 'ethers'
import {
  getFirstCrossChainNetwork,
  getFirstCrossChainToken,
  getPriceImpactWad,
} from '../utils/swap'
import { strToWad } from '@hailstonelabs/big-number-utils'
import { useSwapContext } from './SwapContext'
import { getFilteredTokenMaps } from '../utils/router'
import { usePoolData } from '../store/MulticallData/hooks'

interface ContextType {
  priceImpact: string
  minimumReceive: string
  estDeliveryFee: string
  selectedToChainId: SupportedChainId
  creditInput: string
  selectedToTokenSymbol: TokenSymbol | null
  isCrossChainCreditSwap: boolean
  recipientAddress: string
  creditBalance: string
  fee: string
  quotedToTokenAmount: string
  isQuotingSwapInfo: boolean
  setCreditBalance: React.Dispatch<React.SetStateAction<string>>
  handleToChainIdChange: (chainId: SupportedChainId) => void
  setSelectedToTokenSymbol: React.Dispatch<React.SetStateAction<TokenSymbol | null>>
  resetAllInputAmount: () => void
  setRecipientAddress: React.Dispatch<React.SetStateAction<string>>
  updateSwapCreditInput: (amount: string) => Promise<void>
}

const initialState: ContextType = {
  priceImpact: '',
  minimumReceive: '',
  estDeliveryFee: '',
  selectedToChainId: SupportedChainId.BSC_MAINNET,
  creditInput: '',
  selectedToTokenSymbol: null,
  isCrossChainCreditSwap: false,
  recipientAddress: '',
  creditBalance: '',
  fee: '',
  quotedToTokenAmount: '',
  isQuotingSwapInfo: false,
  setCreditBalance: () => undefined,
  handleToChainIdChange: () => undefined,
  setSelectedToTokenSymbol: () => undefined,
  resetAllInputAmount: () => undefined,
  setRecipientAddress: () => undefined,
  updateSwapCreditInput: () => {
    return new Promise<void>((resolve) => resolve())
  },
}

const SwapCreditContext = createContext<ContextType>(initialState)
interface Props {
  children: React.ReactNode
}

export const useSwapCreditContext = (): ContextType => {
  return useContext(SwapCreditContext)
}

function SwapCreditProvider({ children }: Props): ReactElement {
  const [selectedToChainId, setSelectedToChainId] = useState(initialState.selectedToChainId)
  const [priceImpact, setPriceImpact] = useState('')
  const [quotedToTokenAmount, setQuotedToTokenAmount] = useState('')
  const [minimumReceive, setMinimumReceive] = useState('')
  const [estDeliveryFee, setEstDeliveryFee] = useState('')
  const [fee, setFee] = useState('')
  const [isQuotingSwapInfo, setIsQuotingSwapInfo] = useState(false)
  const { chainId: fromChainId, account } = useWeb3()

  const { receiverValue } = useSwapContext()

  const isCrossChainCreditSwap = fromChainId !== selectedToChainId

  const firstCrossChainToken = getFirstCrossChainToken(selectedToChainId)

  const [selectedToTokenSymbol, setSelectedToTokenSymbol] = useState<TokenSymbol | null>(
    firstCrossChainToken.symbol || null
  )
  const [recipientAddress, setRecipientAddress] = useState('')

  const {
    userPreference: { slippage },
  } = useUserPreference()

  const [creditInput, setCreditInput] = useState('')
  const [creditBalance, setCreditBalance] = useState('')

  const poolData = usePoolData()

  const selectedToToken = selectedToTokenSymbol
    ? TOKENS[selectedToChainId][selectedToTokenSymbol]
    : null

  const getSwapCreditQuotation = useCallback(
    async (targetCreditInput: string) => {
      if (!selectedToTokenSymbol) return
      try {
        setIsQuotingSwapInfo(true)
        const targetQuote = await getSwapCreditForTokenQuotation({
          fromChainId,
          toChainId: selectedToChainId,
          toTokenSymbol: selectedToTokenSymbol,
          creditAmount: targetCreditInput,
          slippage,
          receiverValue,
          gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
        })

        const marketQuote = await getSwapCreditForTokenQuotation({
          fromChainId,
          toChainId: selectedToChainId,
          toTokenSymbol: selectedToTokenSymbol,
          creditAmount: MARKET_CREDIT,
          slippage,
          receiverValue,
          gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
        })

        const priceImpact = utils.formatEther(
          getPriceImpactWad(
            targetCreditInput,
            targetQuote.toTokenAmount,
            MARKET_CREDIT,
            marketQuote.toTokenAmount
          )
        )
        setQuotedToTokenAmount(targetQuote.toTokenAmount)
        setPriceImpact(priceImpact)
        setMinimumReceive(targetQuote.minimumReceived)
        setFee(targetQuote.toTokenFee)
        setEstDeliveryFee(targetQuote.deliveryFee)
      } catch (err) {
        console.log(err)
      } finally {
        setIsQuotingSwapInfo(false)
      }
    },
    [fromChainId, receiverValue, selectedToChainId, selectedToTokenSymbol, slippage]
  )

  const resetAllInputAmount = useCallback(() => {
    setCreditInput('')
    setMinimumReceive('')
    setPriceImpact('')
    setEstDeliveryFee('')
    setFee('')
  }, [])

  const handleToChainIdChange = useCallback(
    (targetChainId: SupportedChainId) => {
      setSelectedToChainId(targetChainId)
      resetAllInputAmount()

      /** check if current selectedToToken is in the target chain  */
      const isInTargetChain = Object.values(getFilteredTokenMaps(targetChainId, true))
        .filter((token) => token.isCrossChainAvailable)
        .some((token) => token.symbol === selectedToToken?.symbol)

      /** reset selectToToken the first cross chain token if selectedToToken is not in target chain */
      if (!isInTargetChain) {
        const { symbol: firstCrossChainTokenSymbolForTargetChain } =
          getFirstCrossChainToken(targetChainId)

        setSelectedToTokenSymbol(firstCrossChainTokenSymbolForTargetChain)
      }
    },
    [resetAllInputAmount, selectedToToken?.symbol]
  )

  useEffect(() => {
    if (!selectedToToken) {
      setSelectedToTokenSymbol(firstCrossChainToken.symbol)
    }
  }, [firstCrossChainToken.symbol, selectedToToken])

  const updateSwapCreditInput = useCallback(
    async (amount: string) => {
      if (strToWad(amount).isZero()) return
      setCreditInput(amount)
      await getSwapCreditQuotation(amount)
    },
    [getSwapCreditQuotation]
  )

  useEffect(() => {
    // default credit balance = contract
    setCreditBalance(poolData.withAccount?.creditBalance ?? '')
  }, [poolData.withAccount?.creditBalance])

  useLayoutEffect(() => {
    const firstCrossChainNetwork = getFirstCrossChainNetwork()
    setRecipientAddress(account as string)
    setSelectedToChainId(firstCrossChainNetwork.id)
    // default receipient = account
    // default selected network = first cross chain network
  }, [account])

  return (
    <SwapCreditContext.Provider
      value={{
        priceImpact,
        minimumReceive,
        selectedToChainId,
        estDeliveryFee,
        creditInput,
        selectedToTokenSymbol,
        isCrossChainCreditSwap,
        recipientAddress,
        creditBalance,
        fee,
        quotedToTokenAmount,
        isQuotingSwapInfo,
        setCreditBalance,
        handleToChainIdChange,
        setSelectedToTokenSymbol,
        resetAllInputAmount,
        setRecipientAddress,
        updateSwapCreditInput,
      }}
    >
      {children}
    </SwapCreditContext.Provider>
  )
}

export default SwapCreditProvider

import { BigNumber } from 'ethers'
import { ASSETS } from '../../../constants/contract/asset'
import { MultiRewarder } from '../../../constants/contract/multiRewarder'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { AssetProperty, getInitialAssetProperty } from '../../../utils/asset'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'

export interface UserInfo {
  amount: BigNumber
  rewardDebt: BigNumber
  unpaidRewards: BigNumber
}

export interface PoolRewarderDataWithAccountType {
  userInfo: AssetProperty<UserInfo[]>
}

export function fetchPoolRewarderData(
  chainId: SupportedChainId,
  account: string | null | undefined
) {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withAccount: PoolRewarderDataWithAccountType
  } = {
    withAccount: {
      userInfo: getInitialAssetProperty<UserInfo[]>(),
    },
  }

  if (account) {
    const assets = ASSETS[chainId]
    Object.entries(assets).forEach(([poolLabelStr, assetsInPool]) => {
      const poolLabel = poolLabelStr as PoolLabels

      Object.entries(assetsInPool).forEach(([, asset]) => {
        if (asset.poolRewarder) {
          const address = asset.poolRewarder.rewarderAddress

          if (address) {
            const rewarder = new MultiRewarder({ chainId, address })
            const rewardTokenAddresses = asset.poolRewarder.rewardTokenAddresses || []

            if (rewarder) {
              for (let i = 0; i < rewardTokenAddresses.length; i++) {
                contractCalls.push(rewarder.multicall('userInfo', [i, account]))
                callbacks.push((value) => {
                  const oldData: UserInfo[] =
                    i === 0 ? [] : states.withAccount.userInfo[poolLabel][asset.symbol] || []
                  states.withAccount.userInfo[poolLabel][asset.symbol] = [
                    ...oldData,
                    {
                      amount: value[0],
                      rewardDebt: value[1],
                      unpaidRewards: value[2],
                    },
                  ]
                })
              }
            }
          }
        }
      })
    })
  }

  return { contractCalls, callbacks, states }
}

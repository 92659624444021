import { useState } from 'react'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { useWeb3 } from '../../context/Web3Context'
import { TOKENS } from '../../constants/contract'
import { chainInfos } from '../../constants/web3/chains'
import { ContractReceipt, ethers, utils } from 'ethers'
import { hexToUint8Array, transferFromEth, uint8ArrayToHex } from '@certusone/wormhole-sdk'
import { arrayify, zeroPad } from 'ethers/lib/utils.js'
import { HexString } from '../../interfaces/contract'
import { PROVIDER_ERROR_CODES } from '../../context/errorMessage'
import { ErrorMessages } from '../../context/errorMessage'
import useSignedVaa from './useSignedVaa'
import { useModalContext } from '../../context/ModalContext'
import { ModalId } from '../../interfaces/modal'
import { DELAY_TIME_ANIMATION_GIF } from '../../constants/common'
import { useAppDispatch } from '../../store/hooks'
import { addSuccessToast, addErrorToast, addInfoToast } from '../../store/Toast/actions'

function useTransfer({
  sourceChainId,
  targetChainId,
}: {
  sourceChainId: SupportedChainId
  targetChainId: SupportedChainId
}) {
  const {
    actions: { openModal, closeModal },
  } = useModalContext()
  const { account, signer, chainId } = useWeb3()
  const { signedVaa, updateSignedVaa, isLoading: isFetchingSignedVaa } = useSignedVaa()
  const [receipt, setReceipt] = useState<ContractReceipt | null>(null)
  const dispatch = useAppDispatch()
  const [isTransferring, setIsTransferring] = useState(false)
  const [isRecoveringTransferReceipt, setIsRecoveringTransferReceipt] = useState(false)
  const [isDisplayGifFlyToTheMoon, setIsDisplayGifFlyToTheMoon] = useState(false)
  const handleTransfer = async (amount: string, recipientAddress?: HexString) => {
    try {
      setIsTransferring(true)
      openModal({ currentModalId: ModalId.BRIDGE_TRANSFER_WAIT_FOR_CONFIRMATION })
      const womToken = TOKENS[sourceChainId].WOM
      if (!account || !signer || !womToken) return
      const sourceChainInfo = chainInfos[sourceChainId]
      const targetChainInfo = chainInfos[targetChainId]
      const sourceWormholeConfig = sourceChainInfo.wormholeConfig
      const targetWormholeConfig = targetChainInfo.wormholeConfig
      if (sourceChainId != chainId) {
        /** @todo update messages later */
        throw new Error(`Please switch to ${sourceChainInfo.name}.`)
      }
      dispatch(
        addInfoToast({
          message: `Transferring ${amount} WOM to Wormhole token bridge on ${sourceChainInfo.name}.`,
        })
      )
      const tokenBridgeAddress = sourceWormholeConfig?.tokenBridgeAddress
      if (!tokenBridgeAddress)
        throw new Error(`Wormhole config is not found in ${sourceChainInfo.name}.`)
      if (!targetWormholeConfig?.chainId) {
        throw new Error(`Wormhole chain id is not found in ${targetChainInfo.name}.`)
      }
      const tokenBridgeCheckSumAddress = utils.getAddress(tokenBridgeAddress)
      // approve token bridge address to spend the user's WOM token
      // requires 0s at the begining (the length of the account is 64)
      const addressHex = uint8ArrayToHex(zeroPad(arrayify(recipientAddress || account), 32))
      const receipt = await transferFromEth(
        tokenBridgeCheckSumAddress,
        signer,
        womToken.address,
        utils.parseUnits(amount, womToken.decimals),
        targetWormholeConfig.chainId,
        hexToUint8Array(addressHex)
      )
      dispatch(
        addSuccessToast({
          message: `Transferred ${amount} WOM to Wormhole token bridge on ${sourceChainInfo.name}.`,
          txHash: receipt.transactionHash,
        })
      )
      setReceipt(receipt)
      setIsDisplayGifFlyToTheMoon(true)
      setTimeout(() => {
        setIsDisplayGifFlyToTheMoon(false) //reset default value
        closeModal()
      }, DELAY_TIME_ANIMATION_GIF)
      void updateSignedVaa(sourceChainId, receipt)
      return
    } catch (err) {
      /** @todo refactor error message handling */
      /** @todo update messages later */
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(err).includes(code)) {
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        console.log(err)
        const error = err as Error
        const errorMessage = error.message || 'Failed to transfer.'
        dispatch(
          addErrorToast({
            message: errorMessage,
          })
        )
      }
      closeModal()
    } finally {
      setIsTransferring(false)
    }
    setReceipt(null)
    closeModal()
  }

  const recoverTransferReceipt = async (txnHash: string) => {
    try {
      setIsRecoveringTransferReceipt(true)
      const chainInfo = chainInfos[sourceChainId]
      const provider = new ethers.providers.JsonRpcProvider(chainInfo.rpcUrls.default.http[0])
      const recoveredReceipt = await provider.getTransactionReceipt(txnHash)
      setReceipt(recoveredReceipt)
      if (!recoveredReceipt) {
        dispatch(
          addErrorToast({
            message: 'Failed to get transaction receipt',
          })
        )
        return
      }
      const signedVaa = await updateSignedVaa(sourceChainId, recoveredReceipt)
      return signedVaa
    } catch (err) {
      /** @todo update messages later */
      console.log(err)
      const error = err as Error
      const errorMessage = error.message || 'Failed to recover.'
      dispatch(
        addErrorToast({
          message: errorMessage,
          title: '',
        })
      )
    } finally {
      setIsRecoveringTransferReceipt(false)
    }
  }

  return {
    receipt,
    signedVaa,
    isFetchingSignedVaa,
    isTransferring,
    isRecoveringTransferReceipt,
    isDisplayGifFlyToTheMoon,
    handleTransfer,
    recoverTransferReceipt,
  }
}

export default useTransfer

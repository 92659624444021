import { useCallback } from 'react'
import { useWeb3 } from '../context/Web3Context'
import { safeFetchJson } from '../utils/utils'

interface ReturnType {
  fetchBlockNumberByTimestamp: (timestamp: number) => Promise<string | null>
}

// Snowtrace api has a rate limit of 5 calls per sec/IP
function useBscScanApi(): ReturnType {
  const { chainInfo } = useWeb3()
  const api = chainInfo?.explorerApi
  const fetchBlockNumberByTimestamp = useCallback(
    async (timestamp: number) => {
      if (api) {
        // timestamp is in seconds
        const targetEndpoint = `${api}?module=block&action=getblocknobytime&timestamp=${timestamp}&closest=before&apikey=${process.env.NEXT_PUBLIC_BSCSCAN_API_KEY}`
        const responseJson = await safeFetchJson<{
          status: string
          message: string
          result: string
        }>(targetEndpoint)
        if (responseJson) {
          const { message, result } = responseJson
          if (message === 'OK') {
            return result
          }
        }
      }
      return null
    },
    [api]
  )
  return { fetchBlockNumberByTimestamp }
}

export default useBscScanApi

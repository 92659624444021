import { SupportedChainId } from '../../../web3/supportedChainId'
import { PoolConstructorProps } from '../Pool'
import { PoolLabels } from '../PoolLabels'
import { ARB1_POOL_CONFIG } from './arbitrum/arb1'
import { AVAX_POOL_CONFIG } from './avalanche/mainnet'
import { FUJI_POOL_CONFIG } from './avalanche/testnet'
import { BASE_POOL_CONFIG } from './base/mainnet'
import { BSC_MAINNET_POOL_CONFIG } from './bsc/mainnet'
import { BSC_TESTNET_POOL_CONFIG } from './bsc/testnet'
import { ETHEREUM_MAINNET_POOL_CONFIG } from './ethereum/mainnet'
import { OPTIMISM_MAINNET_POOL_CONFIG } from './optimism/mainnet'
import { POLYGON_MAINNET_POOL_CONFIG } from './polygon/mainnet'
import { SCROLL_MAINNET_POOL_CONFIG } from './scroll/mainnet'
import { SCROLL_TESTNET_POOL_CONFIG } from './scroll/testnet'
import { SKALE_TESTNET_POOL_CONFIG } from './skale/testnet'
// import { ZKSYNC_MAINNET_POOL_CONFIG } from './zkSync/mainnet'
// import { ZKSYNC_TESTNET_POOL_CONFIG } from './zkSync/testnet'

export type PoolConfig = {
  [pool in PoolLabels]?: Omit<PoolConstructorProps, 'chainId' | 'label'>
}

/**
 * Caveat: if we want to put pool addresses into POOL_CONFIG, we can not put POOL_CONFIG into pool/index.ts because of a dependency problem.
 * Pools need Assets to find their supportedAssetTokenSymbols. Assets need PoolLabelByPoolAddressMap to find their pool labels.
 * PoolLabelByPoolAddressMap need POOL_CONFIG to generate
 */

/** New Pool Release: Update pool config */
export const POOL_CONFIG: { [chainId in SupportedChainId]: PoolConfig } = {
  [SupportedChainId.BSC_MAINNET]: BSC_MAINNET_POOL_CONFIG,
  [SupportedChainId.BSC_TESTNET]: BSC_TESTNET_POOL_CONFIG,
  [SupportedChainId.ARBITRUM_ONE]: ARB1_POOL_CONFIG,
  [SupportedChainId.FUJI]: FUJI_POOL_CONFIG,
  [SupportedChainId.ETHEREUM_MAINNET]: ETHEREUM_MAINNET_POOL_CONFIG,
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: SCROLL_TESTNET_POOL_CONFIG,
  [SupportedChainId.SCROLL_MAINNET]: SCROLL_MAINNET_POOL_CONFIG,
  [SupportedChainId.SKALE_TESTNET]: SKALE_TESTNET_POOL_CONFIG,
  [SupportedChainId.OP_MAINNET]: OPTIMISM_MAINNET_POOL_CONFIG,
  [SupportedChainId.AVAX]: AVAX_POOL_CONFIG,
  [SupportedChainId.BASE]: BASE_POOL_CONFIG,
  [SupportedChainId.POLYGON_MAINNET]: POLYGON_MAINNET_POOL_CONFIG,
  /** @todo unhide it when needed  */
  // [SupportedChainId.ZKSYNC_MAINNET]: ZKSYNC_MAINNET_POOL_CONFIG,
  // [SupportedChainId.ZKSYNC_TESTNET]: ZKSYNC_TESTNET_POOL_CONFIG,
}

type PoolAddressToPoolLabelMap = {
  [k in SupportedChainId]: {
    [key in string]: PoolLabels
  }
}

function getPoolAddressToPoolLabelMap(poolConfig: {
  [chainId in SupportedChainId]: PoolConfig
}): PoolAddressToPoolLabelMap {
  const result: PoolAddressToPoolLabelMap = {} as PoolAddressToPoolLabelMap
  for (const chainIdStr in poolConfig) {
    const chainId = chainIdStr as unknown as SupportedChainId
    const labelToAddressMap: {
      [id in PoolLabels]?: Omit<PoolConstructorProps, 'chainId' | 'label'>
    } = poolConfig[chainId]
    const addressToLabelMap: { [id: string]: PoolLabels } = {} as { [id: string]: PoolLabels }
    for (const poolLabelStr in labelToAddressMap) {
      const poolLabel = poolLabelStr as PoolLabels
      const poolAddress = labelToAddressMap[poolLabel as PoolLabels]?.address
      if (!poolAddress) continue
      addressToLabelMap[poolAddress.toLowerCase()] = poolLabel as PoolLabels
    }
    result[chainId] = addressToLabelMap
  }
  return result
}

export const POOL_ADDRESS_TO_POOL_LABEL_MAP = getPoolAddressToPoolLabelMap(POOL_CONFIG)

import { useCallback, useEffect, useState } from 'react'
import { usePoller } from '../usePoller'
import { chainInfos } from '../../constants/web3/chains'
import { ContractReceipt, ethers } from 'ethers'
import { SupportedChainId } from '../../constants/web3/supportedChainId'

function useTransferTxnProgress(chainId: SupportedChainId, receipt: ContractReceipt | null) {
  const [currentBlock, setCurrentBlock] = useState<number | null>(null)
  const chainInfo = chainInfos[chainId]

  // By default, it's 15 block confirmations
  // This expected blocks are just minimum block confirmations. However, it doesn't mean users can redeem their tokens after the confirmations
  // They will need to get the signed vaa first.
  const minBlockConfirmations = chainInfo.wormholeConfig?.minBlockConfirmations || 15
  const blockDiff =
    receipt && receipt.blockNumber && currentBlock ? currentBlock - receipt.blockNumber : null
  const hasReachedMinBlocks = !!(blockDiff && blockDiff > minBlockConfirmations)
  const fetchCurrentBlock = useCallback(async () => {
    if (receipt && !hasReachedMinBlocks) {
      const provider = new ethers.providers.JsonRpcProvider(chainInfo.rpcUrls.default.http[0])
      const newBlock = await provider.getBlockNumber()
      setCurrentBlock(newBlock)
    }
    // don't put hasReachedMinBlocks as a dependency as it contains currentBlock state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainInfo.rpcUrls.default.http, receipt])

  // Clear current block on chain change
  useEffect(() => {
    return () => {
      setCurrentBlock(null)
    }
  }, [chainId])

  usePoller(fetchCurrentBlock, receipt && !hasReachedMinBlocks ? 500 : undefined)
  return {
    currentBlock,
    minBlockConfirmations,
    blockDiff,
  }
}

export default useTransferTxnProgress

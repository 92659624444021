import { AddressZero } from '@ethersproject/constants'
import { BigNumber, utils } from 'ethers'
import { TOKENS } from '../../../constants/contract'
import { ASSETS } from '../../../constants/contract/asset'
import { LATEST_MASTER_WOMBATS } from '../../../constants/contract/masterWombat'
import { MultiRewarder } from '../../../constants/contract/multiRewarder'
import { POOLS } from '../../../constants/contract/pool'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import {
  AvgFeeSharingAprs,
  PendingTokens,
  PendingTokensSC,
  PoolInfo,
  UserInfo,
} from '../../../interfaces/masterWombat'
import { getInitialAssetProperty } from '../../../store/Asset/helpers'
import { AssetProperty } from '../../../store/Asset/types'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'

export interface MasterWombatDataWithoutAccountType {
  basePartition: number | null
  boostedPartition: number | null
  // pool info
  poolInfos: AssetProperty<PoolInfo>
  totalLpStakedBNs: AssetProperty<BigNumber>
  baseAprWads: AssetProperty<BigNumber> // calculated
  bonusTokenPerSec: AssetProperty<BigNumber[]>
  poolRewarderBalances: AssetProperty<string[]>
  boostedRewarderBalances: AssetProperty<string[]>
  boostedBonusTokenPerSec: AssetProperty<BigNumber[]>
  avgFeeSharingAprs: AvgFeeSharingAprs
}

export interface MasterWombatDataWithAccountType {
  userInfos: AssetProperty<UserInfo>
  poolRewards: AssetProperty<PendingTokens>
}

export const fetchMasterWombatData = (
  chainId: SupportedChainId,
  account: string | null | undefined,
  onlyXChain?: boolean
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: {
    withAccount: MasterWombatDataWithAccountType
    withoutAccount: MasterWombatDataWithoutAccountType
  }
} => {
  /**
   *  Create empty contractCalls and callbacks array
   */
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  /**
   * MW Data
   */
  const states: {
    withAccount: MasterWombatDataWithAccountType
    withoutAccount: MasterWombatDataWithoutAccountType
  } = {
    withAccount: {
      userInfos: getInitialAssetProperty<UserInfo>(),
      poolRewards: getInitialAssetProperty<PendingTokens>(),
    },
    withoutAccount: {
      basePartition: null,
      boostedPartition: null,
      poolInfos: getInitialAssetProperty<PoolInfo>(),
      bonusTokenPerSec: getInitialAssetProperty<BigNumber[]>(),
      poolRewarderBalances: getInitialAssetProperty<string[]>(),
      boostedRewarderBalances: getInitialAssetProperty<string[]>(),
      boostedBonusTokenPerSec: getInitialAssetProperty<BigNumber[]>(),
      totalLpStakedBNs: getInitialAssetProperty<BigNumber>(),
      baseAprWads: getInitialAssetProperty<BigNumber>(),
      avgFeeSharingAprs: getInitialAssetProperty(),
    },
  }

  const handleMasterWombatData = () => {
    const latestMasterWombat = LATEST_MASTER_WOMBATS[chainId]
    if (!latestMasterWombat) return
    // withAccount data: rewards
    if (account) {
      Object.entries(ASSETS[chainId]).forEach(([poolLabelStr, assetsInPool]) => {
        if (onlyXChain && poolLabelStr !== PoolLabels.CROSS_CHAIN) return
        Object.entries(assetsInPool).forEach(([assetSymbolStr, asset]) => {
          if (asset.pid !== -1) {
            const poolLabel = poolLabelStr as PoolLabels
            const assetSymbol = assetSymbolStr as TokenSymbol
            /**
             * Only get info with latest master wombat.
             */
            if (
              POOLS[chainId][poolLabel]?.masterWombatId ===
              LATEST_MASTER_WOMBATS[chainId].masterWombatId
            ) {
              contractCalls.push(latestMasterWombat.multicall('userInfo', [asset.pid, account]))
              callbacks.push((userInfo) => {
                states.withAccount.userInfos[poolLabel][assetSymbol] = userInfo
              })
              // pool rewards
              contractCalls.push(
                latestMasterWombat.multicall('pendingTokens', [asset.pid, account])
              )
              callbacks.push((value: PendingTokensSC) => {
                states.withAccount.poolRewards[poolLabel][assetSymbol] = value
              })
            }
          }
        })
      })
    }
    // withoutAccount data: bonusTokenPerSec, totalLpStakedBn, poolInfos, bonusTokenInfos
    /** @todo fetch once */
    contractCalls.push(
      latestMasterWombat.multicall('basePartition'),
      latestMasterWombat.multicall('boostedPartition')
    )
    callbacks.push(
      (value) => {
        states.withoutAccount.basePartition = value
      },
      (value) => {
        states.withoutAccount.boostedPartition = value
      }
    )
    Object.entries(ASSETS[chainId]).forEach(([poolLabelStr, assetsInPool]) => {
      Object.entries(assetsInPool).forEach(([assetSymbolStr, asset]) => {
        // totalLpStakeds
        const poolLabel = poolLabelStr as PoolLabels
        const assetSymbol = assetSymbolStr as TokenSymbol
        contractCalls.push(asset.multicall('balanceOf', [latestMasterWombat.address]))
        callbacks.push((value) => {
          states.withoutAccount.totalLpStakedBNs[poolLabel][assetSymbol] = value
        })
        // poolInfos
        if (asset.pid != -1) {
          contractCalls.push(latestMasterWombat.multicall('poolInfoV3', [asset.pid]))
          callbacks.push((value) => {
            states.withoutAccount.poolInfos[poolLabel][assetSymbol] = value
          })
        }
        if (asset.poolRewarder) {
          const address = asset.poolRewarder.rewarderAddress
          if (address && address !== AddressZero) {
            const rewarder = new MultiRewarder({ chainId, address })

            if (rewarder) {
              for (let i = 0; i < (asset.poolRewarder.rewardTokenAddresses || []).length; i++) {
                contractCalls.push(rewarder.multicall('rewardInfo', [i]))
                callbacks.push((value) => {
                  states.withoutAccount.bonusTokenPerSec[poolLabel][assetSymbol] = [
                    ...(states.withoutAccount.bonusTokenPerSec[poolLabel][assetSymbol] || []),
                    value.tokenPerSec,
                  ]
                })
              }
              contractCalls.push(rewarder.multicall('balances'))
              callbacks.push((values) => {
                for (let i = 0; i < (asset.poolRewarder?.rewardTokenAddresses || []).length; i++) {
                  const tokenSymbol = asset.poolRewarder?.rewardTokenSymbols?.[i]
                  const decimals = tokenSymbol ? TOKENS[chainId][tokenSymbol]?.decimals || 18 : 18
                  states.withoutAccount.poolRewarderBalances[poolLabel][assetSymbol] = [
                    ...(states.withoutAccount.poolRewarderBalances[poolLabel][assetSymbol] || []),
                    utils.formatUnits(values[i], decimals),
                  ]
                }
              })
            }
          }
        }
        if (asset.boostedPoolRewarder) {
          const address = asset.boostedPoolRewarder.rewarderAddress

          if (address && address !== AddressZero) {
            const rewarder = new MultiRewarder({ chainId, address })

            if (rewarder) {
              for (
                let i = 0;
                i < (asset.boostedPoolRewarder.rewardTokenAddresses || []).length;
                i++
              ) {
                contractCalls.push(rewarder.multicall('rewardInfo', [i]))
                callbacks.push((value) => {
                  states.withoutAccount.boostedBonusTokenPerSec[poolLabel][assetSymbol] = [
                    ...(states.withoutAccount.boostedBonusTokenPerSec[poolLabel][assetSymbol] ||
                      []),
                    value.tokenPerSec,
                  ]
                })
              }
              contractCalls.push(rewarder.multicall('balances'))
              callbacks.push((values) => {
                for (
                  let i = 0;
                  i < (asset.boostedPoolRewarder?.rewardTokenAddresses || []).length;
                  i++
                ) {
                  const tokenSymbol = asset.boostedPoolRewarder?.rewardTokenSymbols?.[i]
                  const decimals = tokenSymbol ? TOKENS[chainId][tokenSymbol]?.decimals || 18 : 18
                  states.withoutAccount.boostedRewarderBalances[poolLabel][assetSymbol] = [
                    ...(states.withoutAccount.boostedRewarderBalances[poolLabel][assetSymbol] ||
                      []),
                    utils.formatUnits(values[i], decimals),
                  ]
                }
              })
            }
          }
        }
      })
    })
  }

  handleMasterWombatData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}

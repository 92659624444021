import { multicall } from '@wagmi/core'
import { BigNumber, utils } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { Contract } from '../constants/contract/Contract'
import {
  CROSS_CHAIN_POOL_ABI,
  POOL_ABI,
  VOLATILE_POOL_ABI_V2,
} from '../constants/contract/abis/pool'
import { Pool } from '../constants/contract/pool/Pool'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TOKENS } from '../constants/contract/token'
import { Token } from '../constants/contract/token/Token'
import { SwapGroupSymbol, TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { useWeb3 } from '../context/Web3Context'
import { IContractCalls, executeCallBacks } from '../utils/executeCallBacks'

type SameTokenWithdrawQuote = {
  amount: BigNumber
  stringAmount: string
  fee: BigNumber
}
type OtherTokenWithdrawQuote = {
  amount: BigNumber
  stringAmount: string
  withdrewAmount: BigNumber
}

type CrossChainPoolWithdrawQuote = {
  amount: BigNumber
  stringAmount: string
}

type QuoteResponse = {
  [id in TokenSymbol]?:
    | SameTokenWithdrawQuote
    | OtherTokenWithdrawQuote
    | CrossChainPoolWithdrawQuote
}

export const useQuoteWithdrawBalances = (
  currentPool: Pool,
  fromToken: Token | null,
  amount: BigNumber | null,
  tokens?: Token[]
): QuoteResponse => {
  const { chainId } = useWeb3()

  const [quotes, setQuotes] = useState<QuoteResponse>({})

  const isCrossChainPool = currentPool.label === PoolLabels.CROSS_CHAIN
  const getWithdrawQuote = useCallback(async () => {
    if (!amount || amount.eq(0) || !fromToken) {
      setQuotes({})
      return
    }
    const vals = []
    for (const tokenSymbol of currentPool.supportedAssetTokenSymbols) {
      const token = TOKENS[chainId][tokenSymbol]
      if (token && token.swapGroupSymbol !== SwapGroupSymbol.UNAVAILABLE) {
        vals.push(token)
      }
    }
    const listToken = tokens || vals
    const queries: IContractCalls = []
    const quoteData: QuoteResponse = {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolver: ((val: any) => void)[] = []
    listToken.forEach((toToken) => {
      if (!currentPool) {
        const errInfo =
          '@before @pool.quotePotentialWithdraw\n' +
          `chainId: ${chainId}\n` +
          `fromTokenAddr: ${fromToken.address}\n` +
          `toTokenAddr: ${toToken.address}\n`
        console.error(errInfo, 'Withdrawal quote: invalid')
        return
      }

      const currentPoolContract = new Contract<
        typeof CROSS_CHAIN_POOL_ABI | typeof POOL_ABI | typeof VOLATILE_POOL_ABI_V2
      >({
        address: currentPool.address,
        chainId,
        abi: isCrossChainPool
          ? CROSS_CHAIN_POOL_ABI
          : currentPool.version === 'volatile_v2'
          ? VOLATILE_POOL_ABI_V2
          : POOL_ABI,
      })

      if (toToken.address === fromToken.address) {
        queries.push(
          currentPoolContract.multicall('quotePotentialWithdraw', [fromToken.address, amount])
        )
        resolver.push((quote) => {
          if (currentPool.version === 'volatile_v2') {
            quoteData[toToken.symbol] = {
              amount: quote,
              stringAmount: utils.formatUnits(quote, toToken.decimals) || '',
              fee: BigNumber.from(0),
            }
          } else if (!isCrossChainPool) {
            quoteData[toToken.symbol] = {
              amount: quote.amount,
              stringAmount: utils.formatUnits(quote.amount, toToken.decimals) || '',
              fee: quote.fee,
            }
          } else {
            /**Cross Chain Pool Quotation */
            quoteData[toToken.symbol] = {
              amount: quote,
              stringAmount: utils.formatUnits(quote, toToken.decimals) || '',
              fee: BigNumber.from('0'),
            }
          }
        })
      } else {
        queries.push(
          currentPoolContract.multicall('quotePotentialWithdrawFromOtherAsset', [
            fromToken.address,
            toToken.address,
            amount,
          ])
        )
        resolver.push((quote) => {
          if (!isCrossChainPool && currentPool.version !== 'volatile_v2') {
            quoteData[toToken.symbol] = {
              amount: quote.amount,
              stringAmount: utils.formatUnits(quote.amount, toToken.decimals) || '',
              withdrewAmount: quote.withdrewAmount,
            }
          } else {
            /**Cross Chain Pool Quotation */
            quoteData[toToken.symbol] = {
              amount: quote.finalAmount,
              stringAmount: utils.formatUnits(quote.finalAmount, toToken.decimals) || '',
              withdrewAmount: quote.withdrewAmount,
            }
          }
        })
      }
    })
    try {
      const result = await multicall({
        contracts: queries,
        allowFailure: true,
        chainId,
      })
      executeCallBacks(result, resolver)
    } catch (e) {
      const errInfo = '@multicallProvider.tryAll for useQuoteWithdrawBalances\n'
      console.error(errInfo, e)
      return
    }
    const quoteData1 = Object.assign({}, quoteData)
    setQuotes(quoteData1)
  }, [amount, chainId, currentPool, fromToken, isCrossChainPool, tokens])

  useEffect(() => {
    getWithdrawQuote()
  }, [getWithdrawQuote])

  return quotes
}

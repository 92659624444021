import { createAsyncThunk } from '@reduxjs/toolkit'
import { TokenPricesState } from '../slice'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { MulticallDataState } from '../../MulticallData/slice'
import { aggregatePrice } from '../../../context/MulticallDataContext/helpers/fetchTokenPricesHelper'
import { TOKENS } from '../../../constants/contract'
import { FetchPriceMethod } from '../../../constants/contract/token/Token'
import { utils } from 'ethers'
import { wmul } from '@hailstonelabs/big-number-utils'

const fetchTokenPrices = createAsyncThunk<
  Pick<TokenPricesState, 'tokenPrices'>,
  {
    chainId: SupportedChainId
    coingeckoPrices: TokenPricesState['coingeckoPrices']
    pythPrices: TokenPricesState['pythPrices']
    exchangeRatesFromUniswapV3: TokenPricesState['exchangeRatesFromUniswapV3']
    tokenData: MulticallDataState['tokenData']
  }
>(
  'tokenPrices/fetchTokenPrices',
  async ({ chainId, coingeckoPrices, pythPrices, exchangeRatesFromUniswapV3, tokenData }) => {
    const initTokenPrices = await aggregatePrice(
      chainId,
      coingeckoPrices,
      pythPrices,
      exchangeRatesFromUniswapV3,
      tokenData.withoutAccount
    )
    // TODO: need to check if is correct --> the `handleBnbExchangeRatePrice` method in the TokenPriceContextProvider
    const tokensInTheChain = TOKENS[chainId]
    if (initTokenPrices.WBNB) {
      const bnbVariantsExchangeRates = tokenData.withoutAccount?.bnbVariantsExchangeRates
      for (const token of Object.values(tokensInTheChain)) {
        if (token.fetchPriceData.method === FetchPriceMethod.BNBEXCHANGERATE) {
          const bnbVariantsExchangeRateWad = bnbVariantsExchangeRates?.[token.symbol]
          if (!bnbVariantsExchangeRateWad) continue
          initTokenPrices[token.symbol] = utils.formatEther(
            wmul(bnbVariantsExchangeRateWad, utils.parseEther(initTokenPrices.WBNB))
          )
        }
      }
    }
    return {
      tokenPrices: initTokenPrices,
    }
  }
)

export default fetchTokenPrices

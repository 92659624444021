import AddBribeForm from './AddBribeForm'
import AddedBribesTable from './AddedBribesTable'
import SelfServiceBribePageProvider from '../../context/SelfServiceBribePageContext'
import Header from './Header'

function SelfServiceBribePage() {
  return (
    <SelfServiceBribePageProvider>
      <section className="max-w-[1440px] font-Jamjuree text-wombatBrown">
        {/* Header */}
        <Header />
        {/* Body */}
        <div className="flex flex-row flex-wrap gap-6">
          <AddBribeForm />
          <AddedBribesTable />
        </div>
      </section>
    </SelfServiceBribePageProvider>
  )
}

export default SelfServiceBribePage

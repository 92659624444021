// Function to pack a fetch request into a promise
export function fetchPromise<T>(url: string) {
  return new Promise<T>((resolve, reject) => {
    fetch(url)
      .then((response) => {
        // Check if the response is successful
        if (response.ok) {
          resolve(response.json() as T)
        } else {
          // Handle the error case
          reject(new Error('Request failed'))
        }
      })
      .catch((error) => {
        // Handle any network or other errors
        reject(error)
      })
  })
}

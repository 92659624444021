// https://github.com/pancakeswap/pancake-frontend/blob/develop/src/utils/index.ts

import { chainInfos } from '../constants/web3/chains'
import { SupportedChainId } from '../constants/web3/supportedChainId'

export function getExplorerLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: SupportedChainId
): string {
  switch (type) {
    case 'transaction': {
      return `${chainInfos[chainId].explorerUrl}/tx/${data}`
    }
    case 'token': {
      return `${chainInfos[chainId].explorerUrl}/token/${data}`
    }
    case 'block': {
      return `${chainInfos[chainId].explorerUrl}/block/${data}`
    }
    case 'countdown': {
      return `${chainInfos[chainId].explorerUrl}/block/countdown/${data}`
    }
    default: {
      return `${chainInfos[chainId].explorerUrl}/address/${data}`
    }
  }
}

import { getCommifiedFormat, isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { SECONDS_PER_WEEK } from '../../../constants/common'
import { TOKENS } from '../../../constants/contract'
import { Asset } from '../../../constants/contract/asset/Asset'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { useWeb3 } from '../../../context/Web3Context'
import { useTokenData } from '../../../store/MulticallData/hooks'
import DatetimeRangePicker from '../../DatetimeRangePicker/DatetimeRangePicker'
import Timeline from '../../Timeline'
import AssetTokenDisplay from '../../display/AssetTokenDisplay'
import TokenDisplay from '../../display/TokenDisplay'
import DropdownInput from '../../inputs/DropdownInput'
import Input, { InputProps } from '../../inputs/Input'

const MIN_DURATION_DAY = 7
const MAX_DURATION_DAY = 60

interface InputControl<T> {
  value: T
  onClick?: (value: T | undefined) => void
  disabled?: boolean
}

export type GaugeInfo = { poolLabel: PoolLabels; assetTokenSymbol: TokenSymbol }

type GuageControlProps = {
  dropdownOptions?: Asset[]
}
type RewardTokenControlProps = {
  dropdownOptions?: TokenSymbol[]
}
type Props = {
  guageControl: GuageControlProps & InputControl<GaugeInfo | null>
  rewardTokenControl: RewardTokenControlProps & InputControl<TokenSymbol | null>
  rewardAmountControl?: Omit<InputProps, 'onMaxBtnClick'> & {
    onMaxBtnClick: (value: string) => void
  }
  durationControl?: {
    value: string
    startDate: Date | null
    endDate: Date | null
    onChange: (startDate: Date | null, endDate: Date | null, duration: string) => void
    validation?: {
      isValid: boolean
      warningMessage: string
    }
    disabled?: boolean
  }
  weeklyEmissionControl?: InputProps
  onFlowChange?: (isCompleted: boolean) => void
  extendedSeconds?: number
}
function SelfServiceBribeForm({
  guageControl,
  rewardTokenControl,
  rewardAmountControl,
  durationControl,
  weeklyEmissionControl,
  onFlowChange,
  extendedSeconds,
}: Props) {
  const { chainId } = useWeb3()
  const tokenData = useTokenData()
  const tokens = TOKENS[chainId]
  const rewardToken = rewardTokenControl.value ? tokens[rewardTokenControl.value] : null
  const [rewardTokenBalance, setRewardTokenBalance] = useState('0')
  const [inputValidation, setInputValidation] = useState<{
    isValid: boolean
    warningMessage: string
  }>({ isValid: true, warningMessage: '' })

  useEffect(() => {
    if (rewardToken) {
      const balance = tokenData.withAccount?.balances[rewardToken.symbol]
      setRewardTokenBalance(balance ?? '0')
    }
  }, [rewardToken, tokenData.withAccount?.balances])

  useEffect(() => {
    const getInputValidation = () => {
      if (strToWad(rewardAmountControl?.value).gt(strToWad(rewardTokenBalance))) {
        return {
          isValid: false,
          warningMessage: 'Insufficient balance',
        }
      }
      // when we have reward input amount same as weekly emission, extendedSeconds becomes 604799.9999999999 which is less than a week
      if (extendedSeconds && _.round(extendedSeconds, 4) < SECONDS_PER_WEEK) {
        return {
          isValid: false,
          warningMessage: `${
            rewardAmountControl ? 'Amount' : 'Warning: Weekly emission'
          } is not enough for extending at least ${MIN_DURATION_DAY} more days`,
        }
      }

      return {
        isValid: true,
        warningMessage: '',
      }
    }

    setInputValidation(getInputValidation())
  }, [extendedSeconds, rewardAmountControl, rewardAmountControl?.value, rewardTokenBalance])

  const handleRewardAmountChange = (value: string) => {
    if (!rewardAmountControl) return
    if (value !== '' && !isParsableString(value, rewardToken?.decimals || 18, true)) return
    rewardAmountControl.onChange(value)
  }
  const handleDurationChange = (startDate: Date | null, endDate: Date | null, value: string) => {
    if (!durationControl) return
    if (+value < 0) {
      durationControl.onChange(startDate, endDate, '0')
      return
    }
    if (
      (value !== '' && !isParsableString(value, 0, true)) ||
      value.includes('.') ||
      +value > MAX_DURATION_DAY
    )
      return
    durationControl.onChange(startDate, endDate, value)
  }

  const handleWeeklyEmissionChange = (value: string) => {
    if (!weeklyEmissionControl) return
    if (value !== '' && !isParsableString(value, rewardToken?.decimals || 18, true)) return
    weeklyEmissionControl.onChange(value)
  }
  const isRewardAmountInputValid = rewardAmountControl
    ? strToWad(rewardAmountControl.value).lte(strToWad(rewardTokenBalance)) &&
      strToWad(rewardAmountControl.value).gt(0)
    : // if rewardAmountControl is not passed, we will treat the input is valid
      true
  const isDurationInputValid = durationControl
    ? +durationControl.value >= MIN_DURATION_DAY && +durationControl.value <= MAX_DURATION_DAY
    : // if durationControl is not passed, we will treat the input is valid
      true
  // if extendedSeconds is not passed, we will treat the input is valid
  // when we have reward input amount same as weekly emission, extendedSeconds becomes 604799.9999999999 which is less than a week
  const isExtendedSecondsValid = extendedSeconds
    ? _.round(extendedSeconds, 4) >= SECONDS_PER_WEEK
    : true

  const hasDropdownOptionsForGaugeControl =
    guageControl.dropdownOptions && guageControl.dropdownOptions.length > 0
  const hasDropdownOptionsForRewardTokenControl =
    rewardTokenControl.dropdownOptions && rewardTokenControl.dropdownOptions.length > 0
  /** check if the flow is completed with valid input values */
  useEffect(() => {
    onFlowChange &&
      onFlowChange(isDurationInputValid && isRewardAmountInputValid && isExtendedSecondsValid)
    // don't pass onFlowChange to the dependancy list to trigger unnecessary re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDurationInputValid, isRewardAmountInputValid, isExtendedSecondsValid])

  return (
    <Timeline>
      {/* Gauge input */}
      <Timeline.Content label="ASSET" disabled={guageControl.disabled}>
        <DropdownInput
          placeholderProps={{
            text: guageControl.value ? (
              <AssetTokenDisplay
                poolLabel={guageControl.value.poolLabel}
                assetTokenSymbol={guageControl.value.assetTokenSymbol}
              />
            ) : (
              'Select an asset'
            ),
          }}
          width="100%"
          optionsMaxHeight="190px"
          disabled={!hasDropdownOptionsForGaugeControl}
        >
          {guageControl.dropdownOptions &&
            guageControl.dropdownOptions.map((asset) => {
              return (
                <DropdownInput.Option
                  key={asset.address}
                  value={{ poolLabel: asset.poolLabel, assetTokenSymbol: asset.symbol }}
                  onClick={guageControl.onClick}
                >
                  <AssetTokenDisplay poolLabel={asset.poolLabel} assetTokenSymbol={asset.symbol} />
                </DropdownInput.Option>
              )
            })}
        </DropdownInput>
      </Timeline.Content>
      {/* Reward token input */}
      <Timeline.Content label="REWARD TOKEN" disabled={rewardTokenControl.disabled}>
        <DropdownInput
          placeholderProps={{
            text: rewardTokenControl.value ? (
              <TokenDisplay
                tokenSymbol={rewardTokenControl.value}
                label={tokens[rewardTokenControl.value]?.displaySymbol}
              />
            ) : hasDropdownOptionsForRewardTokenControl ? (
              'Select a token'
            ) : (
              'No token available'
            ),
          }}
          width="100%"
          disabled={!hasDropdownOptionsForRewardTokenControl}
        >
          {rewardTokenControl.dropdownOptions &&
            rewardTokenControl.dropdownOptions.map((bribeTokenSymbol) => {
              const bribeToken = TOKENS[chainId][bribeTokenSymbol]
              if (!bribeToken) return <></>
              return (
                <DropdownInput.Option
                  key={bribeToken.address}
                  value={bribeTokenSymbol}
                  onClick={rewardTokenControl.onClick}
                >
                  <TokenDisplay tokenSymbol={bribeTokenSymbol} label={bribeToken.displaySymbol} />
                </DropdownInput.Option>
              )
            })}
        </DropdownInput>
      </Timeline.Content>
      {/* Reward token amount input */}
      {rewardAmountControl && (
        <Timeline.Content label="AMOUNT" disabled={rewardAmountControl.disabled}>
          <Input
            {...rewardAmountControl}
            onChange={handleRewardAmountChange}
            disabled={rewardAmountControl.disabled}
            validation={inputValidation}
            labels={{
              topTrailing:
                rewardTokenControl.value &&
                `Balance: ${getCommifiedFormat(strToWad(rewardTokenBalance), 2)} ${
                  rewardTokenControl.value
                }`,
            }}
            onMaxBtnClick={() => {
              rewardAmountControl.onMaxBtnClick(rewardTokenBalance)
            }}
          />
        </Timeline.Content>
      )}
      {/* Duration input */}
      {durationControl && (
        <Timeline.Content label="DURATION" disabled={durationControl.disabled}>
          <DatetimeRangePicker
            startDate={durationControl.startDate}
            endDate={durationControl.endDate}
            showEndTimeSelect={false}
            validation={{
              isValid:
                +durationControl.value >= MIN_DURATION_DAY &&
                +durationControl.value <= MAX_DURATION_DAY,
              warningMessage: `Please choose between ${MIN_DURATION_DAY}-${MAX_DURATION_DAY} days`,
            }}
            onChange={(startDate, endDate) => {
              let duration = 0
              if (endDate && startDate) {
                // Calculate the time difference in milliseconds
                const timeDifference = endDate.getTime() - startDate.getTime()
                /**
                 * Calculate the number of days by dividing the time difference by milliseconds in a day
                 */
                duration = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
              }
              handleDurationChange(startDate, endDate, duration.toString())
            }}
          />
        </Timeline.Content>
      )}
      {/* Weekly Emission input */}
      {weeklyEmissionControl && (
        <Timeline.Content label="WEEKLY EMISSION" disabled={weeklyEmissionControl.disabled}>
          <Input
            {...weeklyEmissionControl}
            onChange={handleWeeklyEmissionChange}
            disabled={weeklyEmissionControl.disabled}
            validation={inputValidation}
          />
        </Timeline.Content>
      )}
    </Timeline>
  )
}

export default SelfServiceBribeForm

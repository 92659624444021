export enum PROVIDER_ERROR_CODES {
  REQUEST_DENIED_ERROR = 'ACTION_REJECTED',
}

export enum PROVIDER_ERROR_REASON {
  VEWOM_NOT_ENOUGH_VOTE = 'VeWom: not enough vote',
  INSUFFICIENT_FUND_FOR_GAS = 'insufficient funds for gas',
}

/**@todo replace with decoded result */
export enum CROSS_CHAIN_SWAP_ERROR_CODE {
  WOMBAT_AMOUNT_TOO_LOW = '0xf512a720',
}

type ErrorMessageType = {
  [key in PROVIDER_ERROR_CODES]: {
    title: string
    message: string | React.ReactNode
  }
}

export const ErrorMessages: ErrorMessageType = {
  [PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR]: {
    title: 'Transaction Rejected',
    message: (
      <div className="font-Helvetican my-auto flex flex-col text-center text-sm text-wombatRed">
        <p>User rejected the transaction.</p>
        <p>Please try again and confirm.</p>
      </div>
    ),
  },
}

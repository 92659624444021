import { useEffect, useState } from 'react'
import { useWindowDimension } from './useWindowDimensions'

/**
 * @param {string} selector - any selector e.g. class, id
 * @returns {[number, number]} width and height of the element
 */
function useElementSize(selector: string) {
  const { width: windowWidth, height: windowHeight } = useWindowDimension()
  const [size, setSize] = useState<[number, number]>([0, 0])
  useEffect(() => {
    const element = document.querySelector(selector) as HTMLElement | null
    if (element) {
      const { width, height } = element.getBoundingClientRect()
      setSize([width, height])
    }
  }, [windowWidth, windowHeight, selector])

  return size
}

export default useElementSize

import { useState } from 'react'
import { SortingState } from './useTable'

function useTableSortingState(): [
  sortingState: SortingState | undefined,
  updateSortingState: (columnIndex: number) => void
] {
  const [sortingState, setSortingState] = useState<SortingState | undefined>(undefined)

  const updateSortingState = (columnIndex: number) => {
    setSortingState((prev) => {
      const isSortingNewColumn = sortingState?.columnIndex !== columnIndex
      return {
        columnIndex,
        ascending: isSortingNewColumn ? false : !prev?.ascending,
        isSorted: true,
      }
    })
  }

  return [sortingState, updateSortingState]
}

export default useTableSortingState

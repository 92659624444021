import { RefObject, useEffect } from 'react'

export default function useDidClickOutside(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refs: RefObject<any>[],
  onClick: () => void
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (
        refs.length > 0 &&
        refs.every((ref) => ref.current && !ref.current.contains(event.target as Node | null))
      ) {
        onClick()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClick, refs])
}

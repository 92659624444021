import Image from 'next/image'
import { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Token } from '../../constants/contract/token/Token'
import { ROUTES_TO_BE_HIDDEN } from '../../constants/routes'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { useWeb3 } from '../../context/Web3Context'
import useAddTokenToMetamask from '../../hooks/useAddToken'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import WombatCheers from '../../public/assets/animations/cheers.gif'
import { getExplorerLink } from '../../utils/getBscScanLink'
import Button from '../Button'
import Modal from '../Modal'

type Props = {
  isOpen: boolean
  hash: string | undefined
  token?: Token
  isAddTokenToWallet?: boolean
  onClose: () => void
  isViewMyVeWOM?: boolean
  isBoostAPR?: boolean
  chainId: SupportedChainId
}

export default function TransactionSubmittedModal({
  isOpen,
  onClose,
  hash,
  token,
  isAddTokenToWallet = true,
  isViewMyVeWOM = false,
  isBoostAPR = false,
  chainId,
}: Props) {
  const { chainInfo } = useWeb3()
  const { addToken } = useAddTokenToMetamask(token)
  const wrapperRef = useRef(null)
  async function handleAddToken() {
    addToken()
  }
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })

  const hasBoost = useMemo(() => {
    return !ROUTES_TO_BE_HIDDEN.BOOST?.includes(chainId)
  }, [chainId])

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Transaction Submitted" isCenterTitle>
      <div className="my-6 flex flex-col items-center justify-center">
        <Image src={WombatCheers} layout="fixed" width={140} height={140}></Image>
      </div>
      {isBoostAPR && hasBoost && (
        <div className="mt-8">
          <Link to="/boost">
            <Button className="bg-wombatPurple1">Boost APR</Button>
          </Link>
        </div>
      )}
      <div
        style={{
          marginTop: '30px',
        }}
      >
        {hash ? (
          <Button className="bg-wombatPurple1">
            <a
              href={getExplorerLink(hash, 'transaction', chainId)}
              target="_blank"
              rel="noreferrer"
            >
              View On {chainInfo.blockExplorers?.default.name}
            </a>
          </Button>
        ) : (
          <></>
        )}
      </div>
      {isAddTokenToWallet && (
        <div>
          <Button className="mt-2 bg-wombatPurple1" onClick={() => handleAddToken()}>
            Add {token?.symbol} to MetaMask
          </Button>
        </div>
      )}
      {isViewMyVeWOM && (
        <div>
          <Button className="mt-2 bg-wombatPurple1">View My $veWOM</Button>
        </div>
      )}
      <div
        style={{
          marginTop: '25px',
        }}
        className="pb-10"
      >
        <Button onClick={() => onClose()}>Done</Button>
      </div>
    </Modal>
  )
}

import { readContract } from '@wagmi/core'
import { BigNumber, constants } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { parseUnits } from 'viem'
import { erc20ABI, useContract } from 'wagmi'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../context/errorMessage'
import { useWeb3 } from '../context/Web3Context'
import { HexString } from '../interfaces/contract'
import { useAppDispatch } from '../store/hooks'
import { addErrorToast } from '../store/Toast/actions'
import { useTxnReceipt } from './../context/TxnReceiptProvider'

export enum TokenApprovalState {
  UNKNOWN,
  NETWORK_UNSUPPORTED,
  LOADING,
  APPROVED,
  NOT_APPROVED,
}

/**
 *
 * @param tokenAddr
 * @param spender
 * @param tokenAmount
 * @param overrideApproved Override the state to be approved. useful for native token where no approval is needed
 * @returns
 */
export default function useApproval(
  tokenAddr: string | null,
  spender: HexString | null,
  tokenAmount: BigNumber | null,
  overrideApproved = false
) {
  const { isSupported, account, signer } = useWeb3()
  const tokenWritableContract = useContract({
    address: tokenAddr || undefined,
    abi: erc20ABI,
    signerOrProvider: signer,
  })

  const [approvalState, setApprovalState] = useState(TokenApprovalState.UNKNOWN)
  const [approvalAmount, setApprovalAmount] = useState<BigNumber | null>(null)
  const [isApproving, setIsApproving] = useState(false)
  const dispatch = useAppDispatch()
  const { refreshTxnReceipt } = useTxnReceipt()
  const checkIfApproved = useCallback(async () => {
    if (!account || !spender || overrideApproved) {
      return
    }
    setIsApproving(true)
    try {
      const allowance = await readContract({
        address: tokenAddr as HexString,
        abi: erc20ABI,
        functionName: 'allowance',
        args: [account, spender],
      })
      setApprovalAmount(allowance)
    } catch (error) {
      const errInfo =
        '@Token.allowance\n' +
        `owner: ${account}\n` +
        `spender: ${spender}\n` +
        `token: ${tokenAddr}\n`
      console.error(errInfo, error)
    }

    setIsApproving(false)
  }, [account, spender, overrideApproved, tokenAddr])

  const updateApprovalState = useCallback(() => {
    if (!isSupported) {
      setApprovalState(TokenApprovalState.NETWORK_UNSUPPORTED)
    } else if (overrideApproved) {
      setApprovalState(TokenApprovalState.APPROVED)
    } else if (isApproving) {
      setApprovalState(TokenApprovalState.LOADING)
    } else if (
      !approvalAmount ||
      approvalAmount.eq(0) ||
      (tokenAmount && approvalAmount.lt(tokenAmount))
    ) {
      setApprovalState(TokenApprovalState.NOT_APPROVED)
    } else {
      setApprovalState(TokenApprovalState.APPROVED)
    }
  }, [approvalAmount, isApproving, isSupported, overrideApproved, tokenAmount])

  useEffect(() => {
    // update approval state if `approvalAmount` and other variables are updated
    updateApprovalState()
  }, [updateApprovalState])

  const tryApproval = useCallback(
    async (value?: string, decimals?: number) => {
      if (tokenWritableContract == null || spender == null) {
        dispatch(addErrorToast({ message: 'Unable to approve token', title: 'Approve Token' }))
        return
      }

      setIsApproving(true)

      try {
        const txn = await tokenWritableContract.approve(
          spender,
          value !== void 0
            ? BigNumber.from(parseUnits(value?.replace(',', '') || '0', decimals || 18))
            : constants.MaxUint256
        )
        await txn.wait()

        await checkIfApproved()
        refreshTxnReceipt()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setIsApproving(false)

        const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
        if (JSON.stringify(error).includes(code)) {
          const errorMessage = ErrorMessages[code]
          dispatch(addErrorToast({ message: errorMessage.message, title: errorMessage.title }))
        } else {
          const errInfo =
            '@Token.approve\n' +
            `spender: ${spender}\n` +
            'amount: constants.MaxUint256\n' +
            `token: ${tokenWritableContract.address}\n`
          console.error(errInfo, error)
        }
      }
    },
    [tokenWritableContract, checkIfApproved, dispatch, refreshTxnReceipt, spender]
  )

  useEffect(() => {
    checkIfApproved()
  }, [checkIfApproved])

  return { approvalState, approvalAmount, checkIfApproved, tryApproval }
}

import { configureStore } from '@reduxjs/toolkit'
import multiChainMultiCallDataReducer from './MultiChainMultiCallData/slice'
import multicallDataReducer from './MulticallData/slice'
import tokenPriceReducer from './Prices/slice'
import migrationDataReducer from './MigrationDetection/slice'
import assetReducer from './Asset/slice'
import masterWombatReducer from './MasterWombat/slice'
import subgraphReducer from './Subgraph/slice'
import toast from './Toast/reducer'
import wormhole from './Wormhole/slice'
import wpp from './WpPoints/slice'

export const store = configureStore({
  reducer: {
    multiChainMultiCallData: multiChainMultiCallDataReducer,
    multicallData: multicallDataReducer,
    tokenPrice: tokenPriceReducer,
    migrationData: migrationDataReducer,
    asset: assetReducer,
    masterWombat: masterWombatReducer,
    subgraph: subgraphReducer,
    toast,
    wormhole,
    wpp,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

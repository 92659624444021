import React from 'react'
import useProposedGaugeWeightTable from '../../hooks/table/useProposedGaugeWeightTable'
import Table from '../Table'

function ProposedGaugeWeightTable() {
  const { tableData, tableHeadData, sortingState, updateSortingState } =
    useProposedGaugeWeightTable()
  return (
    <Table width="100%" maxHeight="300px">
      <Table.Head sticky>
        <Table.Row disableHoverEffect>
          {tableHeadData.map((headData, index) => (
            <Table.HeadData
              key={index}
              style={headData.style}
              onClick={() => {
                updateSortingState(index)
              }}
              columnIndex={index}
              sortingState={sortingState}
              enableSortingIcon
            >
              {headData.cell}
            </Table.HeadData>
          ))}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {tableData.map((config) => {
          return (
            <Table.Row key={config.id}>
              {config.configs.map((config, index) => (
                <Table.Data key={index} style={tableHeadData[index].style}>
                  {config.cell}
                </Table.Data>
              ))}
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default ProposedGaugeWeightTable

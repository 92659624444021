import { BigNumber, ContractTransaction, utils } from 'ethers'
import { useMemo, useState } from 'react'
import { useContract } from 'wagmi'
import { VEWOMS } from '../constants/contract/veWom'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../context/errorMessage'
import { useWeb3 } from '../context/Web3Context'
import { formatNumberUSLocale } from '../utils/numberFormat'
import { getExpectedVeWom } from '../utils/veWom'
import { useTxnReceipt } from './../context/TxnReceiptProvider'
import { useAppDispatch } from '../store/hooks'
import { addErrorToast, addSuccessToast } from '../store/Toast/actions'

export enum HandleLockState {
  IDLE,
  LOADING,
  SUBMITED,
  FAILED,
  SUCCESS,
}

/**
 * @deprecated
 */
export default function useHandleLock(
  amount: BigNumber,
  lockDay: number | string | null,
  onTxnSubmited?: (txn: ContractTransaction) => void
) {
  const { chainId, signer } = useWeb3()
  const vewomContract = useContract({ ...VEWOMS[chainId]?.get(), signerOrProvider: signer })
  const [lockState, setLockState] = useState(HandleLockState.IDLE)
  const [txnHash, setTxnHash] = useState('')
  const [isNewTxn, setIsNewTxn] = useState(false)
  const dispatch = useAppDispatch()

  const veWomEarnings = useMemo(() => {
    const _veWomEarnings = getExpectedVeWom(amount, Number(lockDay))
    return _veWomEarnings.toFixed(2)
  }, [amount, lockDay])

  const { refreshTxnReceipt } = useTxnReceipt()

  async function tryHandleLock(setLockAmountForDisplay: () => void) {
    if (vewomContract != null && amount != null && lockDay && onTxnSubmited) {
      setLockState(HandleLockState.LOADING)

      vewomContract.estimateGas
        .mint(amount, BigNumber.from(lockDay))
        .then((estimateGas) =>
          vewomContract.mint(amount, BigNumber.from(lockDay), { gasLimit: estimateGas })
        )
        .then((txn) => {
          setTxnHash(txn.hash)
          if (txn) {
            setIsNewTxn(true)
            setTimeout(() => {
              setLockState(HandleLockState.SUBMITED)
              setIsNewTxn(false)
            }, 3000)
          }
          setLockAmountForDisplay()
          txn.wait().then(() => {
            refreshTxnReceipt()
            onTxnSubmited(txn)
            setLockState(HandleLockState.SUCCESS)
            dispatch(
              addSuccessToast({
                message: `${formatNumberUSLocale(utils.formatUnits(amount))} WOM`,
                title: 'Lock Completed',
                txHash: txn.hash,
              })
            )
          })
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
          if (JSON.stringify(error).includes(code)) {
            setLockState(HandleLockState.IDLE)
            const errorMessage = ErrorMessages[code]
            dispatch(
              addErrorToast({
                message: errorMessage.message,
                title: errorMessage.title,
              })
            )
          } else {
            dispatch(
              addErrorToast({
                message: `${formatNumberUSLocale(utils.formatUnits(amount))} WOM`,
                title: 'Lock Failed',
              })
            )
            setLockState(HandleLockState.FAILED)
            const errInfo =
              '@VeWom.mint\n' +
              `amount: ${amount.toString()}\n` +
              `lockDay: ${lockDay}\n` +
              `txHash: ${txnHash}\n`
            console.error(errInfo, error)
          }
        })
    }
  }

  return {
    lockState,
    tryHandleLock,
    veWomEarnings,
    txnHash,
    isNewTxn,
  }
}

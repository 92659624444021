import { strToWad } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { TOKENS, VOTERS } from '../../../constants/contract'
import { Contract } from '../../../constants/contract/Contract'
import { BRIBE_ABI } from '../../../constants/contract/abis/bribe'
import { ERC20_ABI } from '../../../constants/contract/abis/erc20'
import { ASSETS } from '../../../constants/contract/asset'
import { Asset } from '../../../constants/contract/asset/Asset'
import { BRIBE_FACTORIES } from '../../../constants/contract/bribeFactory'
import { POOLS } from '../../../constants/contract/pool'
import { poolLabels } from '../../../constants/contract/pool/PoolLabels'
import { tokenAddressTokenMap } from '../../../constants/contract/token'
import { TokenSymbol, TokenSymbols } from '../../../constants/contract/token/TokenSymbols'
import { VEWOMS } from '../../../constants/contract/veWom'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import {
  PoolLabelTokenSymbolStringType,
  PoolLabelsTokenSymbolsGenericType,
} from '../../../interfaces/common'
import { BreedingStructOutput, HexString } from '../../../interfaces/contract'
import { isEmptyAddress } from '../../../utils'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'

export type BribeInfoOfEachPool = PoolLabelsTokenSymbolsGenericType<
  { tokenSymbol: TokenSymbol; value: string }[]
>
export type RewardInfoSCType = [string, BigNumber, BigNumber] & {
  rewardToken: string
  tokenPerSec: BigNumber
  accTokenPerShare: BigNumber
}

type PendingBribesSCType = [string[][], string[][], BigNumber[][]] & {
  bribeTokenAddresses: string[][]
  bribeTokenSymbols: string[][]
  bribeRewards: BigNumber[][]
}

export type InfoSCType = [BigNumber, BigNumber, number, BigNumber, boolean, string, string] & {
  supplyBaseIndex: BigNumber
  supplyVoteIndex: BigNumber
  nextEpochStartTime: number
  claimable: BigNumber
  whitelist: boolean
  gaugeManager: string
  bribe: HexString
}

type WeightsSCType = [BigNumber, BigNumber] & { allocPoint: BigNumber; voteWeight: BigNumber }
export interface VotingDataWithoutAccountType {
  womEmissionsInNextEpoch: PoolLabelTokenSymbolStringType
  totalAllocPoint: string
  totalWomStaked: string
  womPerSec: string
  totalWeight: string
  weightOfEachAsset: PoolLabelTokenSymbolStringType
  allocPointOfEachAsset: PoolLabelTokenSymbolStringType
  bribeTokenPerSecondOfEachAsset: BribeInfoOfEachPool
  bribeTokenBalanceInfoOfEachAsset: PoolLabelsTokenSymbolsGenericType<
    { amount: string; tokenSymbol: string }[]
  >

  /**
   * Currently, we use the first pid to get nextEpochStartTime from Voter.infos().
   * However, this start time could be wrong as only pids with rewards will be passed to MasterWombat.multiClaim().
   * It means only these pids’nextEpochStartTimewill be updated.
   * We might need to loop thru all pids’ nextEpochStartTime (from Voter.infos())
   * and get the latest one as our nextEpochStartTime .
   */
  nextEpochStartTimes: number[] | null
  voteAllocation: string
  whiteListOfEachAsset: PoolLabelsTokenSymbolsGenericType<boolean>
  whitelistedRewardTokens: HexString[]
}

export interface VotingDataWithAccountType {
  womStaked: string
  usedVote: string
  voteOfEachAsset: PoolLabelTokenSymbolStringType
  userEarnedBribeOfEachAsset: BribeInfoOfEachPool
  isbribeDeployerOfEachAsset: PoolLabelsTokenSymbolsGenericType<boolean>
}

export const fetchVotingData = (
  chainId: SupportedChainId,
  account: string | null | undefined
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: {
    withAccount: VotingDataWithAccountType
    withoutAccount: VotingDataWithoutAccountType
  }
} => {
  /**
   * A. Create empty contractCalls and callbacks array
   */
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withAccount: VotingDataWithAccountType
    withoutAccount: VotingDataWithoutAccountType
  } = {
    withoutAccount: {
      womEmissionsInNextEpoch: {},
      totalAllocPoint: '0',
      nextEpochStartTimes: null,
      totalWomStaked: '0',
      womPerSec: '0',
      totalWeight: '0',
      weightOfEachAsset: {},
      allocPointOfEachAsset: {},
      bribeTokenPerSecondOfEachAsset: {},
      bribeTokenBalanceInfoOfEachAsset: {},
      voteAllocation: '0',
      whiteListOfEachAsset: {},
      whitelistedRewardTokens: [],
    },
    withAccount: {
      womStaked: '0',
      usedVote: '0',
      voteOfEachAsset: {},
      userEarnedBribeOfEachAsset: {},
      isbribeDeployerOfEachAsset: {},
    },
  }
  const assetsWithBribe: Asset[] = []

  const handleVotingData = () => {
    const womToken = TOKENS[chainId][TokenSymbols.WOM]
    const vewomAddress = VEWOMS[chainId]?.address
    if (womToken && vewomAddress) {
      contractCalls.push(womToken.multicall('balanceOf', [vewomAddress]))
      callbacks.push((valueWad) => {
        states.withoutAccount.totalWomStaked = utils.formatEther(valueWad)
      })
    }
    const voter = VOTERS[chainId]
    const vewom = VEWOMS[chainId]
    const bribeFactory = BRIBE_FACTORIES[chainId]
    if (vewom && account) {
      contractCalls.push(
        vewom.multicall('getUserInfo', [account]),
        vewom.multicall('usedVote', [account])
      )
      callbacks.push(
        (value) => {
          states.withAccount.womStaked = utils.formatEther(
            (value.breedings as BreedingStructOutput).reduce(
              (acc, breeding) => acc.add(breeding.womAmount),
              constants.Zero
            )
          )
        },
        (value: BigNumber) => {
          states.withAccount.usedVote = utils.formatEther(value)
        }
      )
    }

    // Whitelisted tokens
    if (bribeFactory) {
      contractCalls.push(bribeFactory?.multicall('getWhitelistedRewardTokens', []))
      callbacks.push((value: HexString[]) => {
        states.withoutAccount.whitelistedRewardTokens = value
      })
    }
    for (let i = 0; i < poolLabels.length; i++) {
      const poolLabel = poolLabels[i]
      const pool = POOLS[chainId][poolLabel]
      if (!pool) continue
      const assets = pool.supportedAssetTokenSymbols.reduce((acc, assetTokenSymbol) => {
        const newAsset = ASSETS[chainId][poolLabel][assetTokenSymbol]
        return newAsset ? [...acc, newAsset] : acc
      }, [] as Asset[])
      for (let j = 0; j < assets.length; j++) {
        const asset = assets[j]
        const assetTokenSymbol = asset.symbol
        const assetTokenAddress = asset.address
        if (voter) {
          contractCalls.push(
            voter.multicall('pendingWom', [assetTokenAddress]),
            voter.multicall('infos', [assetTokenAddress]),
            voter.multicall('weights', [assetTokenAddress])
          )
          callbacks.push(
            (valueWad) => {
              states.withoutAccount.womEmissionsInNextEpoch[poolLabel] = {
                ...(states.withoutAccount.womEmissionsInNextEpoch[poolLabel] || {}),
                [assetTokenSymbol]: utils.formatEther(valueWad),
              }
            },
            (value: InfoSCType) => {
              states.withoutAccount.nextEpochStartTimes = [
                ...(states.withoutAccount.nextEpochStartTimes || []),
                value.nextEpochStartTime,
              ]
              states.withoutAccount.whiteListOfEachAsset[poolLabel] = {
                ...(states.withoutAccount.whiteListOfEachAsset[poolLabel] || {}),
                [assetTokenSymbol]: value.whitelist,
              }
            },
            (value: WeightsSCType) => {
              states.withoutAccount.allocPointOfEachAsset[poolLabel] = {
                ...(states.withoutAccount.allocPointOfEachAsset[poolLabel] || {}),
                [assetTokenSymbol]: utils.formatEther(value.allocPoint),
              }
              states.withoutAccount.weightOfEachAsset[poolLabel] = {
                ...(states.withoutAccount.weightOfEachAsset[poolLabel] || {}),
                [assetTokenSymbol]: utils.formatEther(value.voteWeight),
              }
            }
          )
          if (account) {
            contractCalls.push(voter.multicall('getUserVotes', [account, assetTokenAddress]))
            callbacks.push((valueWad) => {
              states.withAccount.voteOfEachAsset[poolLabel] = {
                ...(states.withAccount.voteOfEachAsset[poolLabel] || {}),
                [assetTokenSymbol]: utils.formatEther(valueWad),
              }
            })
          }
        }
        if (bribeFactory && account) {
          contractCalls.push(bribeFactory.multicall('bribeDeployers', [assetTokenAddress]))
          callbacks.push((value) => {
            states.withAccount.isbribeDeployerOfEachAsset[poolLabel] = {
              ...(states.withAccount.isbribeDeployerOfEachAsset[poolLabel] || {}),
              [assetTokenSymbol]: value === account,
            }
          })
        }

        /** Handling bribe rewardInfo */
        const assetBribeRewarder = asset.bribeRewarder

        if (
          !assetBribeRewarder?.rewarderAddress ||
          isEmptyAddress(assetBribeRewarder.rewarderAddress)
        )
          continue
        assetsWithBribe.push(asset)
        const bribeRewarder = new Contract<typeof BRIBE_ABI>({
          address: assetBribeRewarder.rewarderAddress,
          chainId,
          abi: BRIBE_ABI,
        })
        const bribeTokenSymbols = assetBribeRewarder.rewardTokenSymbols ?? []
        for (let bribeIndex = 0; bribeIndex < bribeTokenSymbols.length; bribeIndex++) {
          const bribeTokenSymbol = bribeTokenSymbols[bribeIndex]
          const bribeDecimals = TOKENS[chainId][bribeTokenSymbol]?.decimals
          if (!bribeDecimals) continue
          contractCalls.push(bribeRewarder.multicall('rewardInfo', [bribeIndex]))
          callbacks.push((value: RewardInfoSCType) => {
            states.withoutAccount.bribeTokenPerSecondOfEachAsset[poolLabel] = {
              ...(states.withoutAccount.bribeTokenPerSecondOfEachAsset[poolLabel] || {}),
              [assetTokenSymbol]: [
                ...(states.withoutAccount.bribeTokenPerSecondOfEachAsset[poolLabel]?.[
                  assetTokenSymbol
                ] || []),
                {
                  tokenSymbol: bribeTokenSymbol,
                  value: utils.formatUnits(value.tokenPerSec, bribeDecimals),
                },
              ],
            }
          })

          // add bribe token balance info
          const bribeTokenAddress = assetBribeRewarder.rewardTokenAddresses?.[bribeIndex]
          if (!bribeTokenAddress) continue
          const bribeToken = new Contract<typeof ERC20_ABI>({
            address: bribeTokenAddress,
            chainId,
            abi: ERC20_ABI,
          })
          if (!bribeToken) continue
          contractCalls.push(
            bribeToken.multicall('balanceOf', [assetBribeRewarder.rewarderAddress])
          )
          callbacks.push((value) => {
            states.withoutAccount.bribeTokenBalanceInfoOfEachAsset[poolLabel] = {
              ...(states.withoutAccount.bribeTokenBalanceInfoOfEachAsset[poolLabel] || {}),
              [assetTokenSymbol]: [
                ...(states.withoutAccount.bribeTokenBalanceInfoOfEachAsset[poolLabel]?.[
                  assetTokenSymbol
                ] || []),
                {
                  tokenSymbol: bribeTokenSymbol,
                  amount: utils.formatUnits(value, bribeDecimals),
                },
              ],
            }
          })
        }
      }
    }
    // required to put this part at the end
    if (voter) {
      contractCalls.push(
        voter.multicall('totalAllocPoint'),
        voter.multicall('womPerSec'),
        voter.multicall('totalWeight'),
        voter.multicall('voteAllocation')
      )
      callbacks.push(
        (valueWad) => {
          states.withoutAccount.totalAllocPoint = utils.formatEther(valueWad)
        },
        (valueWad) => {
          states.withoutAccount.womPerSec = utils.formatEther(valueWad)
        },
        (valueWad) => {
          states.withoutAccount.totalWeight = utils.formatEther(valueWad)
        },
        (value) => {
          states.withoutAccount.voteAllocation = value.toString()
        }
      )
      /** Handling user's pendingBribes */
      if (account) {
        contractCalls.push(
          voter.multicall('pendingBribes', [assetsWithBribe.map((a) => a.address), account])
        )
        callbacks.push((value: PendingBribesSCType) => {
          for (let h = 0; h < assetsWithBribe.length; h++) {
            const assetWithBribe = assetsWithBribe[h]
            const bribeTokenAddresses = value.bribeTokenAddresses[h]
            for (
              let bribeTokeAddressIndex = 0;
              bribeTokeAddressIndex < bribeTokenAddresses.length;
              bribeTokeAddressIndex++
            ) {
              // Caveat: sometimes the token symbol is missing because the token has zero address in tokenAddressTokenMap
              const bribeTokenAddress = bribeTokenAddresses[bribeTokeAddressIndex]
              const bribeTokenSymbol =
                tokenAddressTokenMap[chainId]?.[bribeTokenAddress.toLowerCase()]?.symbol
              if (!bribeTokenSymbol) continue
              const bribeDecimals = TOKENS[chainId][bribeTokenSymbol]?.decimals
              if (!bribeDecimals) continue

              const earnedBribe = utils.formatUnits(
                value.bribeRewards[h][bribeTokeAddressIndex],
                bribeDecimals
              )
              if (strToWad(earnedBribe).lte(0)) continue

              states.withAccount.userEarnedBribeOfEachAsset[assetWithBribe.poolLabel] = {
                ...(states.withAccount.userEarnedBribeOfEachAsset[assetWithBribe.poolLabel] || {}),
                [assetWithBribe.symbol]: [
                  ...(states.withAccount.userEarnedBribeOfEachAsset?.[assetWithBribe.poolLabel]?.[
                    assetWithBribe.symbol
                  ] || []),
                  {
                    tokenSymbol: bribeTokenSymbol,
                    value: earnedBribe,
                  },
                ],
              }
            }
          }
        })
      }
    }
  }

  handleVotingData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}

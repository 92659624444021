import { useMemo, useState } from 'react'
import { VOTERS } from '../../constants/contract'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { useModalContext } from '../../context/ModalContext'
import { useWeb3 } from '../../context/Web3Context'
import { useVotingData } from '../../context/VotingDataContext'
import { ModalId } from '../../interfaces/modal'
import { calculateGasMargin } from '../../utils'
import { getLpTokenAddressesAndVewomVoteDeltaWads } from '../../utils/voting'
import { useContract } from 'wagmi'
import { useVewomData } from '../../store/MulticallData/hooks'
import { useAppDispatch } from '../../store/hooks'
import { addSuccessToast, addErrorToast } from '../../store/Toast/actions'
type Props = {
  isWaiting: boolean
  isDone: boolean
  handleVote: (votingWeightPercentages: {
    [id in PoolLabels]?: {
      [id in TokenSymbol]?: string
    }
  }) => Promise<void>
}
const useVote = (): Props => {
  const { chainId, signer } = useWeb3()
  const voterContract = useContract({ ...VOTERS[chainId]?.get(), signerOrProvider: signer })
  const vewomData = useVewomData()
  const vewomBalanceWad = useMemo(() => {
    return vewomData?.withAccount?.vewomBalanceWad
  }, [vewomData?.withAccount?.vewomBalanceWad])
  const [isWaiting, setIsWaiting] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const { user } = useVotingData()
  const dispatch = useAppDispatch()
  const {
    actions: { openModal },
  } = useModalContext()
  const handleVote = async (proposedUserVotingWeightPercentages: {
    [id in PoolLabels]?: {
      [id in TokenSymbol]?: string
    }
  }) => {
    setIsDone(false)
    setIsWaiting(true)
    let transaction
    try {
      if (!vewomBalanceWad) return
      const { lpTokenAddresses, vewomVoteDeltaWads } = getLpTokenAddressesAndVewomVoteDeltaWads(
        proposedUserVotingWeightPercentages,
        user.voteWeightOfEachAsset.vewom,
        vewomBalanceWad,
        chainId
      )
      if (voterContract) {
        openModal({ currentModalId: ModalId.VOTE_WAIT_FOR_CONFIRMATION })
        const estimatedGas = await voterContract.estimateGas.vote(
          lpTokenAddresses,
          vewomVoteDeltaWads
        )
        transaction = await voterContract.vote(lpTokenAddresses, vewomVoteDeltaWads, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
        await transaction.wait()
        dispatch(
          addSuccessToast({
            message: '',
            title: 'Vote Completed',
            txHash: transaction.hash,
          })
        )
        setIsDone(true)
      }
    } catch (err) {
      console.error(err)
      dispatch(
        addErrorToast({
          message: '',
          title: 'Vote Failed',
          txHash: transaction?.hash,
        })
      )
    } finally {
      setIsWaiting(false)
      openModal({ currentModalId: ModalId.UNSET })
    }
  }
  return { handleVote, isWaiting, isDone }
}

export default useVote

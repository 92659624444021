import styles from './settingBtn.module.css'
type Props = {
  checked: boolean
  onClick: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

export default function SettingButton({ onClick, checked, children }: Props) {
  return (
    <div className="form-check form-check-inline">
      <label className={`${styles.rd} whitespace-nowrap text-xs`}>
        <span className={checked ? 'font-medium' : ''}>{children}</span>
        <input
          className="mr-2 h-4 w-4 rounded border border-gray-300 text-indigo-500 focus:ring-indigo-400 focus:ring-opacity-25"
          type="radio"
          onClick={onClick}
          checked={checked}
          readOnly
        />
        <span className={`${styles.checkmark}`}></span>
      </label>
    </div>
  )
}

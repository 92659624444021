import {
  arbitrum,
  avalanche,
  avalancheFuji,
  bsc,
  bscTestnet,
  Chain,
  mainnet,
  optimism,
  polygon,
} from '@wagmi/core/chains'
import ARBITRUM_ONE_LOGO from '../../public/assets/chains/arbitrum-one.svg'
import BSC_LOGO from '../../public/assets/chains/bsc.svg'
import ETH_MAINNET_LOGO from '../../public/assets/chains/eth.svg'
import avaxLogo from '../../public/assets/chains/fuji.svg'
import OP_LOGO from '../../public/assets/chains/optimism.svg'
import POLYGON_LOGO from '../../public/assets/chains/polygon.svg'
import SCROLL_LOGO from '../../public/assets/chains/scroll.svg'
import SAKLE_LOGO from '../../public/assets/chains/skale.svg'
// import ZKSYNC_LOGO from '../../public/assets/chains/zksync.svg'
import { ChainId as WormholeChainId } from '@certusone/wormhole-sdk'
import { ethers } from 'ethers'
import { shuffle } from 'lodash'
import { HexString } from '../../interfaces/contract'
import BASE_LOGO from '../../public/assets/chains/base.svg'
import { PoolFilterGroup } from '../contract/pool/poolFilterGroup'
import {
  SUPPORTED_CHAIN_IDS,
  SupportedChainId,
  supportedChainIdToWormholeChainIdMap,
} from './supportedChainId'
import { base } from './wagmiChains/base'
import { scrollMainnet } from './wagmiChains/scrollMainnet'
import { scrollSepolia } from './wagmiChains/scrollSepolia'
import { skaleChaosTestnet } from './wagmiChains/skaleChaosTestnet'

export interface ChainInfo extends Chain {
  // When we instantiate JsonRpcProvider with Ethers, it will automatically send a request for initialisation.
  // That's why we use a getter function to access archive node provider when needed.
  getArchiveNodeProvider?: () => ethers.providers.JsonRpcProvider
  isCrossChainAvailable?: boolean
  icon: string
  label: string
  womSubgraphUrl: string | null
  womStakingSubgraphUrl: string | null
  blockSubgraphUrl: string | null
  pythPriceServiceUrl: string
  displayPoolFilterGroups: PoolFilterGroup[]
  readonly explorerApi?: string
  readonly explorerUrl: string
  readonly explorerAlias: string
  /** these wormhole addresses are used for wormhole sdk and they have to be check sum addresses */
  readonly wormholeConfig?: {
    adaptorAddress: HexString
    tokenBridgeAddress?: HexString
    coreBridgeAddress?: HexString
    relayerAddress: HexString
    // this chain id is from token bridge contract
    chainId: WormholeChainId | undefined
    // minimum confirmations enforced by guardians or specified by the contract
    minBlockConfirmations?: number
  }
}

const rpcUrls: {
  [id in SupportedChainId]: string[]
} = {
  [SupportedChainId.FUJI]: [],
  [SupportedChainId.ARBITRUM_ONE]: shuffle([
    // 'https://arbitrum.llamarpc.com',
    // 'https://1rpc.io/arb',
    // 'https://arb1.arbitrum.io/rpc',
    // 'https://arbitrum.blockpi.network/v1/rpc/public',
    // 'https://rpc.ankr.com/arbitrum',
    'https://arb-mainnet.g.alchemy.com/v2/3zn_zCdcBPvlwtchGmrB-OEqgPNjeF1v',
  ]),
  [SupportedChainId.BSC_MAINNET]: shuffle([
    // Temporarily disable this rpc since it is added to ad block list:
    // https://github.com/easylist/easylist/blob/HEAD/easylist/easylist_thirdparty.txt#L98
    // 'https://bsc-dataseed1.binance.org/',
    'https://bsc-dataseed2.binance.org/',
    'https://bsc-dataseed3.binance.org/',
    'https://bsc-dataseed4.binance.org/',
    'https://bsc-dataseed1.defibit.io/',
    'https://bsc-dataseed2.defibit.io/',
    'https://bsc-dataseed3.defibit.io/',
    'https://bsc-dataseed4.defibit.io/',
    'https://bsc-dataseed1.ninicoin.io/',
    'https://bsc-dataseed2.ninicoin.io/',
    'https://bsc-dataseed3.ninicoin.io/',
    'https://bsc-dataseed4.ninicoin.io/',
  ]),
  [SupportedChainId.BSC_TESTNET]: shuffle([
    'https://data-seed-prebsc-1-s1.binance.org:8545/',
    'https://data-seed-prebsc-1-s2.binance.org:8545',
    'https://data-seed-prebsc-1-s3.binance.org:8545/',
    'https://data-seed-prebsc-2-s2.binance.org:8545/',
    'https://data-seed-prebsc-2-s3.binance.org:8545/',
  ]),
  [SupportedChainId.ETHEREUM_MAINNET]: shuffle([
    'https://eth.llamarpc.com',
    'https://ethereum.publicnode.com',
    'https://1rpc.io/eth',
    'https://endpoints.omniatech.io/v1/eth/mainnet/public',
    'https://eth.drpc.org',
  ]),
  [SupportedChainId.SKALE_TESTNET]: shuffle([
    'https://staging-v3.skalenodes.com/v1/staging-fast-active-bellatrix',
  ]),
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: shuffle([
    'https://alpha-rpc.scroll.io/l2',
    'https://scroll-alphanet.public.blastapi.io',
  ]),
  [SupportedChainId.SCROLL_MAINNET]: shuffle([
    'https://rpc.scroll.io',
    'https://rpc.ankr.com/scroll',
  ]),
  [SupportedChainId.OP_MAINNET]: shuffle([
    'https://mainnet.optimism.io',
    'https://rpc.ankr.com/optimism',
    'https://optimism-mainnet.public.blastapi.io',
    'https://optimism.llamarpc.com	',
    'https://optimism.publicnode.com',
    'https://optimism.blockpi.network/v1/rpc/public',
  ]),
  [SupportedChainId.AVAX]: shuffle([
    'https://avalanche.public-rpc.com',
    'https://avalanche-c-chain.publicnode.com',
    'https://rpc.ankr.com/avalanche',
    'https://1rpc.io/avax/c',
  ]),
  [SupportedChainId.POLYGON_MAINNET]: shuffle([
    'https://polygon.llamarpc.com',
    'https://poly-rpc.gateway.pokt.network',
    'https://endpoints.omniatech.io/v1/matic/mainnet/public	',
    'https://polygon-bor.publicnode.com	',
  ]),
  [SupportedChainId.BASE]: shuffle([
    'https://mainnet.base.org',
    'https://base.llamarpc.com',
    'https://base.meowrpc.com',
    'https://1rpc.io/base',
    'https://base.publicnode.com',
  ]),
  /** @todo unhide ZKSYNC_MAINNET  */
  // [SupportedChainId.ZKSYNC_MAINNET]: shuffle([
  //   'https://mainnet.era.zksync.io',
  //   'https://zksync.meowrpc.com',
  //   'https://zksync.drpc.org',
  // ]),
  // [SupportedChainId.ZKSYNC_TESTNET]: shuffle([
  //   'https://sepolia.era.zksync.dev',
  //   // 'https://zksync-era-testnet.blockpi.network/v1/rpc/public',
  // ]),
}

export const chainInfos: {
  [id in SupportedChainId]: ChainInfo
} = {
  [SupportedChainId.ARBITRUM_ONE]: {
    ...arbitrum,
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://arbitrum-one-rpc.allthatnode.com/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x'
      ),
    icon: ARBITRUM_ONE_LOGO,
    label: 'Arbitrum',
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/arb/data',
    womStakingSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/arb/data',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/arb/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.ECO,
      PoolFilterGroup.LSD,
      PoolFilterGroup.MAIN,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.CROSS_CHAIN,
    ],
    explorerApi: 'https://api.arbiscan.io/api',
    explorerUrl: 'https://arbiscan.io',
    explorerAlias: 'ARBISCAN',
    rpcUrls: {
      default: {
        http: rpcUrls[SupportedChainId.ARBITRUM_ONE],
      },
      public: {
        http: rpcUrls[SupportedChainId.ARBITRUM_ONE],
      },
    },
    wormholeConfig: {
      relayerAddress: '0x27428DD2d3DD32A4D7f7C497eAaa23130d894911',
      adaptorAddress: '0xdAA1918e5C8Ed59C9889adb171F0ef7a09170211',
      tokenBridgeAddress: '0x0b2402144Bb366A632D14B83F244D2e0e21bD39c',
      coreBridgeAddress: '0xa5f208e072434bC67592E4C49C1B991BA79BCA46',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.ARBITRUM_ONE],
      minBlockConfirmations: 64,
    },
  },
  [SupportedChainId.BSC_MAINNET]: {
    ...bsc,
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://bsc-mainnet-rpc.allthatnode.com/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x'
      ),
    icon: BSC_LOGO,
    label: 'BNB Chain',
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/bsc/data',
    womStakingSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/bsc/data',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/bsc/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    explorerApi: 'https://api.bscscan.com/api',
    explorerUrl: 'https://bscscan.com',
    explorerAlias: 'BscScan',
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.ECO,
      PoolFilterGroup.LSD,
      PoolFilterGroup.MAIN,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.CROSS_CHAIN,
    ],
    rpcUrls: {
      default: {
        http: rpcUrls[SupportedChainId.BSC_MAINNET],
      },
      public: {
        http: rpcUrls[SupportedChainId.BSC_MAINNET],
      },
    },
    wormholeConfig: {
      adaptorAddress: '0x191601af39927bA165BF89B238BFbF615bAd205B',
      relayerAddress: '0x27428DD2d3DD32A4D7f7C497eAaa23130d894911',
      tokenBridgeAddress: '0xB6F6D86a8f9879A9c87f643768d9efc38c1Da6E7',
      coreBridgeAddress: '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.BSC_MAINNET],
      minBlockConfirmations: 15,
    },
  },
  [SupportedChainId.BSC_TESTNET]: {
    isCrossChainAvailable: true,
    ...bscTestnet,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://bsc-testnet-rpc.allthatnode.com/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x'
      ),
    icon: BSC_LOGO,
    label: 'BNB Testnet',
    womSubgraphUrl:
      'https://api.thegraph.com/subgraphs/name/corey-wombat/wombat-exchange-v2-staging',
    womStakingSubgraphUrl:
      'https://api.thegraph.com/subgraphs/name/corey-wombat/wombat-staking-staging',
    blockSubgraphUrl: 'https://api.thegraph.com/subgraphs/name/corey-wombat/wombat-block-chapel',
    pythPriceServiceUrl: 'https://hermes-beta.pyth.network',
    explorerApi: 'https://api-testnet.bscscan.com/api',
    explorerUrl: 'https://testnet.bscscan.com',
    explorerAlias: 'BscScan Testnet',
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.ECO,
      PoolFilterGroup.LSD,
      PoolFilterGroup.MAIN,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.CROSS_CHAIN,
      PoolFilterGroup.NONE,
    ],
    rpcUrls: {
      default: {
        http: rpcUrls[SupportedChainId.BSC_TESTNET],
      },
      public: {
        http: rpcUrls[SupportedChainId.BSC_TESTNET],
      },
    },
    wormholeConfig: {
      relayerAddress: '0x80aC94316391752A193C1c47E27D382b507c93F3',
      coreBridgeAddress: '0x68605AD7b15c732a30b1BbC62BE8F2A509D74b4D',
      adaptorAddress: '0xebd34d7d249686d7cfb391dd18a220773e72fedb',
      tokenBridgeAddress: '0x9dcF9D205C9De35334D646BeE44b2D2859712A09',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.BSC_TESTNET],
    },
  },
  [SupportedChainId.FUJI]: {
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://avalanche-fuji-rpc.allthatnode.com/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x/ext/bc/C/rpc'
      ),
    icon: avaxLogo,
    label: 'Fuji',
    womSubgraphUrl: null,
    womStakingSubgraphUrl: null,
    blockSubgraphUrl: null,
    pythPriceServiceUrl: '',
    explorerApi: '',
    explorerUrl: 'https://testnet.snowtrace.io',
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.ECO,
      PoolFilterGroup.LSD,
      PoolFilterGroup.MAIN,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.CROSS_CHAIN,
      PoolFilterGroup.NONE,
    ],
    ...avalancheFuji,
    explorerAlias: 'FUJI',
    wormholeConfig: {
      coreBridgeAddress: '0x7bbcE28e64B3F8b84d876Ab298393c38ad7aac4C',
      adaptorAddress: '0x0683e2c4d6e26274bd0574d09bfb8ce25e4dfa85',
      relayerAddress: '0xA3cF45939bD6260bcFe3D66bc73d60f19e49a8BB',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.FUJI],
    },
  },
  [SupportedChainId.ETHEREUM_MAINNET]: {
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://ethereum-mainnet-rpc.allthatnode.com/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x'
      ),
    icon: ETH_MAINNET_LOGO,
    label: 'Ethereum',
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/eth/data',
    womStakingSubgraphUrl: '',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/eth/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    explorerApi: 'https://api.etherscan.io/api',
    explorerUrl: 'https://etherscan.io/',
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.MAIN,
      PoolFilterGroup.LSD,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.ECO,
      PoolFilterGroup.CROSS_CHAIN,
    ],
    ...mainnet,
    rpcUrls: {
      default: {
        http: rpcUrls[SupportedChainId.ETHEREUM_MAINNET],
      },
      public: {
        http: rpcUrls[SupportedChainId.ETHEREUM_MAINNET],
      },
    },
    explorerAlias: 'Etherscan',
    wormholeConfig: {
      coreBridgeAddress: '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B',
      tokenBridgeAddress: '0x3ee18B2214AFF97000D974cf647E7C347E8fa585',
      relayerAddress: '0x98f3c9e6E3fAce36bAAd05FE09d375Ef1464288B',
      adaptorAddress: '0xACEB589012edbBEe4CE7b4E89B916700B43419CA',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.ETHEREUM_MAINNET],
    },
    network: 'ethereum',
  },
  [SupportedChainId.SKALE_TESTNET]: {
    isCrossChainAvailable: false,
    icon: SAKLE_LOGO,
    label: 'Skale Testnet',
    womSubgraphUrl: null,
    womStakingSubgraphUrl: null,
    blockSubgraphUrl: null,
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    /** @todo explorer api */
    explorerUrl: skaleChaosTestnet.blockExplorers?.default.url || '',
    displayPoolFilterGroups: [PoolFilterGroup.ALL, PoolFilterGroup.MAIN],
    ...skaleChaosTestnet,
    explorerAlias: skaleChaosTestnet.blockExplorers?.default.name || '',
    network: 'skale-testnet',
  },
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: {
    isCrossChainAvailable: true,
    icon: SCROLL_LOGO,
    label: 'Scroll Sepolia Testnet',
    womSubgraphUrl: null,
    womStakingSubgraphUrl: null,
    blockSubgraphUrl: null,
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    explorerApi: 'https://blockscout.scroll.io/api',
    explorerUrl: scrollSepolia.blockExplorers?.default.url || '',
    displayPoolFilterGroups: [PoolFilterGroup.ALL, PoolFilterGroup.MAIN],
    ...scrollSepolia,
    explorerAlias: 'Sepolia blockscout',
    network: 'scroll-testnet',
    wormholeConfig: {
      coreBridgeAddress: '0x055F47F1250012C6B20c436570a76e52c17Af2D5',
      tokenBridgeAddress: '0x22427d90B7dA3fA4642F7025A854c7254E4e45BF',
      relayerAddress: '0x',
      adaptorAddress: '0x',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.SCROLL_SEPOLIA_TESTNET],
    },
  },
  [SupportedChainId.SCROLL_MAINNET]: {
    ...scrollMainnet,
    isCrossChainAvailable: true,
    icon: SCROLL_LOGO,
    label: 'Scroll',
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider('https://rpc.ankr.com/scroll'),
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/scroll/data',
    womStakingSubgraphUrl: '',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/scroll/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    /** @todo is it a correct api? */
    explorerApi: 'https://blockscout.scroll.io/api',
    explorerUrl: scrollMainnet.blockExplorers?.default.url || '',
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.MAIN,
      PoolFilterGroup.CROSS_CHAIN,
    ],
    explorerAlias: 'Scroll',
    network: 'scroll',
    wormholeConfig: {
      coreBridgeAddress: '0xbebdb6C8ddC678FfA9f8748f85C815C556Dd8ac6',
      tokenBridgeAddress: '0x24850c6f61C438823F01B7A3BF2B89B72174Fa9d',
      relayerAddress: '0x27428DD2d3DD32A4D7f7C497eAaa23130d894911',
      adaptorAddress: '0xD9E4945b690617E003553D47611f75E46e387911',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.SCROLL_MAINNET],
    },
  },
  [SupportedChainId.OP_MAINNET]: {
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        // 'https://optimism-mainnet-archive.allthatnode.com/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x'
        'https://mainnet.optimism.io'
      ),
    icon: OP_LOGO,
    label: optimism.name,
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/op/data',
    womStakingSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/op/data',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/op/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    explorerApi: 'https://api-optimistic.etherscan.io/',
    explorerUrl: optimism.blockExplorers.etherscan.url,
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.CROSS_CHAIN,
    ],
    ...optimism,
    rpcUrls: {
      default: {
        http: rpcUrls[SupportedChainId.OP_MAINNET],
      },
      public: {
        http: rpcUrls[SupportedChainId.OP_MAINNET],
      },
    },
    explorerAlias: optimism.blockExplorers.default.name,
    wormholeConfig: {
      relayerAddress: '0x27428DD2d3DD32A4D7f7C497eAaa23130d894911',
      adaptorAddress: '0x3ccC0dC754E380cDA16EF94292F278bbc4Ff463E',
      tokenBridgeAddress: '0x1D68124e65faFC907325e3EDbF8c4d84499DAa8b',
      coreBridgeAddress: '0xEe91C335eab126dF5fDB3797EA9d6aD93aeC9722',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.OP_MAINNET],
    },
  },
  [SupportedChainId.AVAX]: {
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://avalanche-mainnet-archive.allthatnode.com/PSl7dUmhBhRrrS1WMNVnWaHUutFtXca2/ext/bc/C/rpc'
      ),
    icon: avaxLogo,
    label: avalanche.name,
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/avax/data',
    womStakingSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/avax/data',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/avax/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    explorerApi: 'https://api.snowtrace.io/api',
    explorerUrl: avalanche.blockExplorers.etherscan.url,
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.MAIN,
      PoolFilterGroup.LSD,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.CROSS_CHAIN,
    ],
    ...avalanche,
    rpcUrls: {
      default: {
        http: rpcUrls[SupportedChainId.AVAX],
      },
      public: {
        http: rpcUrls[SupportedChainId.AVAX],
      },
    },
    explorerAlias: avalanche.blockExplorers.default.name,
    wormholeConfig: {
      relayerAddress: '0x27428DD2d3DD32A4D7f7C497eAaa23130d894911',
      coreBridgeAddress: '0x54a8e5f9c4CbA08F9943965859F6c34eAF03E26c',
      tokenBridgeAddress: '0x0e082F06FF657D94310cB8cE8B0D9a04541d8052',
      adaptorAddress: '0x489833311676B566f888119c29bd997Dc6C95830',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.AVAX],
    },
  },
  [SupportedChainId.POLYGON_MAINNET]: {
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://polygon-mainnet-archive.allthatnode.com:8545/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x'
      ),
    icon: POLYGON_LOGO,
    label: polygon.name,
    /**@todo update polygon url */
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/polygon/data',
    womStakingSubgraphUrl: '',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/polygon/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    explorerApi: 'https://api.polygonscan.com/api',
    explorerUrl: polygon.blockExplorers.etherscan.url,
    displayPoolFilterGroups: [PoolFilterGroup.ALL, PoolFilterGroup.MAIN],
    ...polygon,
    explorerAlias: polygon.blockExplorers.default.name,
    wormholeConfig: {
      coreBridgeAddress: '0x7A4B5a56256163F07b2C80A7cA55aBE66c4ec4d7',
      tokenBridgeAddress: '0x5a58505a96D1dbf8dF91cB21B54419FC36e93fdE',
      relayerAddress: '0x27428DD2d3DD32A4D7f7C497eAaa23130d894911',
      adaptorAddress: '0x9da4edBed6068666ea8EF6505C909e1ff8eA5725',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.POLYGON_MAINNET],
    },
  },
  [SupportedChainId.BASE]: {
    isCrossChainAvailable: true,
    getArchiveNodeProvider: () =>
      new ethers.providers.JsonRpcProvider(
        'https://base-mainnet-archive.allthatnode.com/YpS8ObnbQ9JzH4PQzDsKqREEs3NRUk0x'
      ),
    icon: BASE_LOGO,
    label: base.name,
    womSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/base/data',
    womStakingSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/base/data',
    blockSubgraphUrl: 'https://subgraph-proxy.wombat.exchange/base/block',
    pythPriceServiceUrl: 'https://hermes.pyth.network',
    explorerApi: '',
    explorerUrl: base.blockExplorers?.etherscan?.url || '',
    displayPoolFilterGroups: [
      PoolFilterGroup.ALL,
      PoolFilterGroup.STABLES,
      PoolFilterGroup.CROSS_CHAIN,
    ],
    ...base,
    explorerAlias: base.blockExplorers?.default.name || '',
    wormholeConfig: {
      relayerAddress: '0x706f82e9bb5b0813501714ab5974216704980e31',
      coreBridgeAddress: '0xbebdb6C8ddC678FfA9f8748f85C815C556Dd8ac6',
      tokenBridgeAddress: '0x8d2de8d2f73F1F4cAB472AC9A881C9b123C79627',
      adaptorAddress: '0x489833311676B566f888119c29bd997Dc6C95830',
      chainId: supportedChainIdToWormholeChainIdMap[SupportedChainId.BASE],
    },
  },
  /** @todo unhide it when needed  */
  // [SupportedChainId.ZKSYNC_MAINNET]: {
  //   isCrossChainAvailable: false,
  //   archiveNodeProvider: new ethers.providers.JsonRpcProvider(''),
  //   icon: ZKSYNC_LOGO,
  //   label: zkSync.name,
  //   /**@todo update base url */
  //   womSubgraphUrl: '',
  //   womStakingSubgraphUrl: '',
  //   blockSubgraphUrl: '',
  //   pythPriceServiceUrl: 'https://hermes.pyth.network',
  //   explorerApi: '',
  //   explorerUrl: zkSync.blockExplorers.default.url || '',
  //   displayPoolFilterGroups: [PoolFilterGroup.ALL, PoolFilterGroup.MAIN],
  //   ...zkSync,
  //   explorerAlias: zkSync.blockExplorers?.default.name || '',
  // },
  // [SupportedChainId.ZKSYNC_TESTNET]: {
  //   isCrossChainAvailable: false,
  //   getArchiveNodeProvider: () => new ethers.providers.JsonRpcProvider(''),
  //   icon: ZKSYNC_LOGO,
  //   label: zkSyncTestnet.name,
  //   /**@todo update base url */
  //   womSubgraphUrl: '',
  //   womStakingSubgraphUrl: '',
  //   blockSubgraphUrl: '',
  //   pythPriceServiceUrl: 'https://hermes.pyth.network',
  //   explorerApi: '',
  //   explorerUrl: zkSyncTestnet.blockExplorers.default.url || '',
  //   displayPoolFilterGroups: [PoolFilterGroup.ALL, PoolFilterGroup.MAIN],
  //   ...zkSyncTestnet,
  //   explorerAlias: zkSyncTestnet.blockExplorers?.default.name || '',
  // },
}

/**
*
@param {string} network the network property in @wagmi/core/chains's Chain
@returns {SupportedChainId | undefined} chain id of the corresponding network, undefined if network does not exist in chainInfos.
*/
export function getSupportedChainIdByNetwork(network: string | null) {
  for (const supportedChainId of SUPPORTED_CHAIN_IDS) {
    if (network === chainInfos[supportedChainId].network) {
      return supportedChainId
    }
  }
}

export const CROSS_CHAIN_GAS_RECEIVING_AMOUNT: {
  [chainId in SupportedChainId]: {
    defaultAmount: string
    maxAmount: string
  }
} = {
  [SupportedChainId.ARBITRUM_ONE]: {
    defaultAmount: '0.002',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.BSC_TESTNET]: {
    defaultAmount: '0.02',
    maxAmount: '0.1',
  },
  [SupportedChainId.BSC_MAINNET]: {
    defaultAmount: '0.02',
    maxAmount: '0.3',
  },
  /** mock value */
  [SupportedChainId.FUJI]: {
    defaultAmount: '0.02',
    maxAmount: '0.1',
  },
  [SupportedChainId.ETHEREUM_MAINNET]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.SKALE_TESTNET]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.SCROLL_MAINNET]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.OP_MAINNET]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.AVAX]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.POLYGON_MAINNET]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  /** mock value */
  [SupportedChainId.BASE]: {
    defaultAmount: '0.005',
    maxAmount: '0.1',
  },
  // /** mock value */
  // [SupportedChainId.ZKSYNC_MAINNET]: {
  //   defaultAmount: '0.005',
  //   maxAmount: '0.1',
  // },
  /** mock value */
  // [SupportedChainId.ZKSYNC_TESTNET]: {
  //   defaultAmount: '0.005',
  //   maxAmount: '0.1',
  // },
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { MasterWombatState } from '../types'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { AssetProperty } from '../../Asset/types'
import { BigNumber, utils } from 'ethers'
import { TokenPricesState } from '../../Prices/slice'
import { getInitialAssetProperty } from '../../Asset/helpers'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TOKENS } from '../../../constants/contract'
import { strToWad } from '@hailstonelabs/big-number-utils'
import { calLPPrice } from '../../../utils/math'
import { calculateApr as calculateAprWad } from '../../../utils'
import { ASSETS } from '../../../constants/contract/asset'

const updateExtraRewards = createAsyncThunk<
  Pick<MasterWombatState, 'bonusTokenAprs'>,
  {
    chainId: SupportedChainId
    bonusTokenPerSec: AssetProperty<BigNumber[]> | undefined
    tokenPrices: TokenPricesState['tokenPrices']
    lpTokenToTokenRates: AssetProperty<BigNumber>
    totalLpStakedBNs: AssetProperty<BigNumber> | undefined
  }
>(
  'masterWombat/updateExtraRewards',
  async ({ chainId, bonusTokenPerSec, tokenPrices, lpTokenToTokenRates, totalLpStakedBNs }) => {
    const bonusTokenAprs_ = getInitialAssetProperty<BigNumber[]>()
    Object.entries(ASSETS[chainId]).forEach(([poolLabelStr, assetsInPool]) => {
      Object.entries(assetsInPool).forEach(([tokenSymbolStr, asset]) => {
        const tokenSymbol = tokenSymbolStr as TokenSymbol
        const poolLabel = poolLabelStr as PoolLabels
        const aprs = asset.poolRewarder?.rewardTokenSymbols?.map(
          (bonusTokenSymbol: TokenSymbol, index: number) => {
            const rewardPerSec = bonusTokenPerSec?.[poolLabel][tokenSymbol]?.[index]
            const bonusToken = TOKENS[chainId][bonusTokenSymbol]
            if (!bonusToken) return BigNumber.from(0)
            if (!rewardPerSec) return BigNumber.from(0)

            // get annulized reward emission in WAD
            /** @TODO bug assuming all mainnet reward 18 decimals */
            const factorToWad = utils.parseUnits('1', 18 - bonusToken.decimals)
            const annualizedRewardEmission = rewardPerSec.mul(86400).mul(365).mul(factorToWad)
            const lpPriceWad = strToWad(tokenPrices[tokenSymbol])
            const lpUnitPrice = calLPPrice(
              utils.parseEther('1'),
              lpTokenToTokenRates[poolLabel][tokenSymbol] ?? null,
              lpPriceWad
            )
            const rewardPriceWad = strToWad(tokenPrices[bonusTokenSymbol])
            const totalLpStakedBN = totalLpStakedBNs?.[poolLabel][tokenSymbol]
            const apr =
              !totalLpStakedBN ||
              totalLpStakedBN.isZero() ||
              !lpUnitPrice ||
              lpUnitPrice.isZero() ||
              !annualizedRewardEmission
                ? BigNumber.from(0)
                : calculateAprWad(
                    utils.parseEther('1'),
                    annualizedRewardEmission,
                    rewardPriceWad,
                    totalLpStakedBN,
                    lpUnitPrice
                  )
            return apr
          }
        )
        bonusTokenAprs_[poolLabel][tokenSymbol] = aprs
      })
    })
    return {
      bonusTokenAprs: bonusTokenAprs_,
    }
  }
)

export default updateExtraRewards

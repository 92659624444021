// Don't put it into pool/index.ts because of a dependency problem
export enum PoolFilterGroup {
  ALL = 'ALL',
  MAIN = 'MAIN',
  LSD = 'LSD',
  STABLES = 'STABLES',
  ECO = 'ECO',
  CROSS_CHAIN = 'X-CHAIN',
  NONE = 'NONE',
  UNAVALIABLE = 'UNAVALIABLE',
}

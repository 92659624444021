import clsx from 'clsx'
import styles from './ToggleSlider.module.scss'
import { HTMLAttributes } from 'react'

interface IToggleSlider extends HTMLAttributes<HTMLDivElement> {
  title: string
  isChecked: boolean
  containerClassName?: string
  onToggleChange: () => void
}

function ToggleSlider({
  title,
  isChecked,
  onToggleChange,
  className,
  containerClassName,
  ...restProps
}: IToggleSlider) {
  return (
    <div
      onClick={onToggleChange}
      className={clsx(
        'flex items-center rounded-md border-1 border-wombatBrown1 p-[3px]',
        containerClassName
      )}
    >
      <div className={clsx('mx-1 font-Jamjuree text-[14px]', styles['Toggle__Slider__Header'])}>
        {title}
      </div>
      <div
        className={clsx(
          'relative flex h-[24px] w-[56px] rounded-[3px] p-[4px]',
          isChecked ? 'bg-linearGradientApprove' : 'bg-wombatPurple3',
          className
        )}
        {...restProps}
      >
        <div
          className={clsx(
            'absolute top-[50%] h-[15px] w-[15px] translate-y-[-50%] cursor-pointer rounded-[3px] bg-white',
            isChecked ? 'right-1' : 'left-1'
          )}
        ></div>
      </div>
    </div>
  )
}

export default ToggleSlider

import React from 'react'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { TokenInfo } from '../PoolPage'
import PoolInfoCard from '../PoolInfoCard'
import { POOLS } from '../../constants/contract/pool'
import { useWeb3 } from '../../context/Web3Context'

interface IPoolGroupWrapper {
  poolLabel: PoolLabels
  tokensInfo: TokenInfo[]
}

export const PoolGroupWrapper = ({ poolLabel, tokensInfo }: IPoolGroupWrapper) => {
  const { chainId } = useWeb3()

  const isBetaaLogoShown = poolLabel === PoolLabels.CROSS_CHAIN
  return (
    <div className="relative my-2 flex flex-col">
      <div className="mb-2 flex flex-col">
        {tokensInfo.map((tokenInfo) => {
          return (
            <PoolInfoCard
              key={`${tokenInfo.poolLabel}_${tokenInfo.symbol}`}
              poolLabel={tokenInfo.poolLabel}
              tokenInfo={tokenInfo}
            />
          )
        })}
      </div>
      <div
        className={`item-center absolute top-0 left-0 ml-4 flex h-[20px] translate-y-[-10px] justify-center border-2 border-wombatPurple2 bg-white px-4 text-[12px]  text-wombatBrown1  ${
          isBetaaLogoShown ? 'h-[25px] w-[200px] py-1' : 'w-[180px]'
        }`}
      >
        {isBetaaLogoShown && (
          <div className="mr-2 flex items-center bg-linearGradientApprove px-[5px] font-Quantico font-bold text-white">
            Beta
          </div>
        )}
        <div className="flex items-center">{POOLS[chainId][poolLabel]?.name}</div>
      </div>
    </div>
  )
}

import { AddressZero } from '@ethersproject/constants'
import { nativeToWAD, safeWdiv } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import { formatEther } from 'ethers/lib/utils.js'
import { AssetDataWithoutAccountType } from '.'
import { TOKENS } from '../../../constants/contract'
import { Contract } from '../../../constants/contract/Contract'
import { MULTICALL3_ABI } from '../../../constants/contract/abis/multicall'
import { POOLS } from '../../../constants/contract/pool'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { ABNBCS, BNBX_STAKE_MANAGERS, PSTAKE_POOLS } from '../../../constants/contract/stakedBnb'
import {
  NATIVE_WRAPPED_TOKEN_IN_CHAIN,
  tokenAddressTokenMap,
} from '../../../constants/contract/token'
import { FetchPriceMethod } from '../../../constants/contract/token/Token'
import { TokenSymbol, TokenSymbols } from '../../../constants/contract/token/TokenSymbols'
import { UniswapV2Pair } from '../../../constants/contract/uniswap/UniswapV2Pair'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { HexString } from '../../../interfaces/contract'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'
// [reserve0, reserve1, blockTimestampLast],
// In Fraxswap, getReserves return [_reserve0, _reserve1, blockTimestampLast]
type ReservesResponse = [BigNumber, BigNumber, number]
export interface TokenDataWithAccountType {
  balances: {
    [id in TokenSymbol]?: string
  }
}
export interface TokenDataWithoutAccountType {
  bnbVariantsExchangeRates: { [id in TokenSymbol]?: BigNumber }
  exchangeRatesFromDex: { [id in TokenSymbol]?: string }
  exchangeRatesFromWombat: { [id in TokenSymbol]?: string }
  exchangeRatesFromUniswapV3: { [id in TokenSymbol]?: string }
}

export const fetchTokenData = ({
  chainId,
  account,
  whitelistedRewardTokens = [],
  skipPriceFetching = false,
  assetData,
  onlyXChain,
}: {
  chainId: SupportedChainId
  account: string | null | undefined
  whitelistedRewardTokens?: HexString[]
  skipPriceFetching?: boolean
  assetData?: AssetDataWithoutAccountType
  onlyXChain?: boolean
}): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: { withAccount: TokenDataWithAccountType; withoutAccount: TokenDataWithoutAccountType }
} => {
  /**
   *  Create empty contractCalls and callbacks array
   */
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  /**
   * Balance Data:
   * 1. tokenAmount, tokenApproval
   * 2. lpTokenAmount, lpTokenApproval
   */
  const states: {
    withAccount: TokenDataWithAccountType
    withoutAccount: TokenDataWithoutAccountType
  } = {
    withAccount: {
      balances: {},
    },
    withoutAccount: {
      bnbVariantsExchangeRates: {},
      exchangeRatesFromDex: {},
      exchangeRatesFromWombat: {},
      exchangeRatesFromUniswapV3: {},
    },
  }

  const handleTokenData = () => {
    // withAccount data
    if (chainId && account) {
      // query balances for tokens in different assets
      for (const pool of Object.values(POOLS[chainId])) {
        // TODO: remove this when we have a better way to handle crosschain data
        if (onlyXChain && pool.label !== PoolLabels.CROSS_CHAIN) continue
        for (const tokenSymbol of pool.supportedAssetTokenSymbols) {
          const token = TOKENS[chainId][tokenSymbol]
          if (!token || token.address === AddressZero) continue
          contractCalls.push(token.multicall('balanceOf', [account]))
          states.withAccount.balances[token.symbol] = '0'
          callbacks.push((res: BigNumber) => {
            states.withAccount.balances[token.symbol] = res.isZero()
              ? '0'
              : utils.formatUnits(res, token.decimals)
          })
        }
      }
      // query balances for whitelisted rewards token
      if (whitelistedRewardTokens.length) {
        const tokenMap = tokenAddressTokenMap[chainId]
        if (tokenMap) {
          for (const whitelistedAddress of whitelistedRewardTokens) {
            const token = tokenMap[whitelistedAddress.toLowerCase()]
            if (!token) continue
            contractCalls.push(token.multicall('balanceOf', [account]))
            callbacks.push((res: BigNumber) => {
              states.withAccount.balances[token.symbol] = res.isZero()
                ? '0'
                : utils.formatUnits(res, token.decimals)
            })
          }
        }
      }

      // query native token balance
      contractCalls.push(
        new Contract({
          address: '0xcA11bde05977b3631167028862bE2a173976CA11',
          abi: MULTICALL3_ABI,
          chainId,
        }).multicall('getEthBalance', [account])
      )
      callbacks.push((res: BigNumber) => {
        const tokenSymbol = NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]
        const token = TOKENS[chainId][tokenSymbol] || null
        states.withAccount.balances[tokenSymbol] = utils.formatUnits(res, token?.decimals)
      })

      // query WOM balance
      const womToken = TOKENS[chainId][TokenSymbols.WOM]
      if (womToken) {
        contractCalls.push(womToken.multicall('balanceOf', [account]))
        callbacks.push((res: BigNumber) => {
          states.withAccount.balances[TokenSymbols.WOM] = utils.formatUnits(res, womToken.decimals)
        })
      }
    }
    // withoutAccount data
    // Get relative prices
    /**
     * 1. query exchange rate of bnb variants
     */
    // query exchange rate of pStakePool
    if (skipPriceFetching) return
    const pStakePool = PSTAKE_POOLS[chainId]
    if (pStakePool) {
      // ignore type
      contractCalls.push(pStakePool.multicall('exchangeRate'))
      callbacks.push(
        ({ poolTokenSupply, totalWei }: { poolTokenSupply: BigNumber; totalWei: BigNumber }) => {
          states.withoutAccount.bnbVariantsExchangeRates[TokenSymbols.stkBNB] = totalWei
            .mul(utils.parseEther('1'))
            .div(poolTokenSupply)
        }
      )
    }
    // query exchange rate of bnbXStakeManager
    const bnbXStakeManager = BNBX_STAKE_MANAGERS[chainId]
    if (bnbXStakeManager) {
      contractCalls.push(bnbXStakeManager.multicall('convertBnbXToBnb', [utils.parseEther('1')]))
      callbacks.push((res: BigNumber) => {
        states.withoutAccount.bnbVariantsExchangeRates[TokenSymbols.BNBx] = res
      })
    }
    // query exchange rate of aBNBc
    const aBNBc = ABNBCS[chainId]
    if (aBNBc) {
      contractCalls.push(aBNBc.multicall('ratio'))
      callbacks.push((res: BigNumber) => {
        states.withoutAccount.bnbVariantsExchangeRates[TokenSymbols.ankrBNB] = BigNumber.from(10)
          .pow(36)
          .div(res)
      })
    }

    /**
     * query exchange rate of tokens from DEX/Wombat
     */
    for (const tokenInstance of Object.values(TOKENS[chainId])) {
      const method = tokenInstance.fetchPriceData?.method
      if (method === FetchPriceMethod.DEX) {
        const pairAddress = tokenInstance.fetchPriceData.payload.pairAddress
        const toTokenSymbolReserveIndex = tokenInstance.fetchPriceData.payload.toToken.reserveIndex
        const toTokenSymbol = tokenInstance.fetchPriceData.payload.toToken.symbol
        const reserve0TokenSymbol =
          toTokenSymbolReserveIndex === '0' ? toTokenSymbol : tokenInstance.symbol
        const reserve1TokenSymbol =
          toTokenSymbolReserveIndex === '1' ? toTokenSymbol : tokenInstance.symbol
        if (!reserve0TokenSymbol || !reserve1TokenSymbol) continue
        const reserve0Token = TOKENS[chainId][reserve0TokenSymbol]
        const reserve1Token = TOKENS[chainId][reserve1TokenSymbol]
        if (!pairAddress || !toTokenSymbolReserveIndex || !reserve0Token || !reserve1Token) continue
        const uniswapV2Pair = new UniswapV2Pair({ address: pairAddress, chainId })
        contractCalls.push(uniswapV2Pair.multicall('getReserves'))
        callbacks.push(([reserve0, reserve1]: ReservesResponse) => {
          const reserve0Decimals = reserve0Token.decimals
          const reserve1Decimals = reserve1Token.decimals
          states.withoutAccount.exchangeRatesFromDex[tokenInstance.symbol] =
            reserve1TokenSymbol === tokenInstance.symbol
              ? utils.formatEther(
                  safeWdiv(
                    nativeToWAD(reserve0, reserve0Decimals),
                    nativeToWAD(reserve1, reserve1Decimals)
                  )
                )
              : utils.formatEther(
                  safeWdiv(
                    nativeToWAD(reserve1, reserve0Decimals),
                    nativeToWAD(reserve0, reserve1Decimals)
                  )
                )
        })
      }
      if (method === FetchPriceMethod.WOMBAT) {
        const poolLabel = tokenInstance.fetchPriceData.payload.poolLabel
        const toTokenSymbol = tokenInstance.fetchPriceData.payload.toTokenSymbol
        if (!poolLabel || !toTokenSymbol) continue
        const toToken = TOKENS[chainId][toTokenSymbol]
        if (!toToken) continue
        const pool = POOLS[chainId][poolLabel]
        if (!pool) continue
        const fromAmount = 1
        const cashBn = assetData?.cashesBn[pool.label][tokenInstance.symbol]
        const cash = cashBn ? Number(formatEther(cashBn)) : 0
        const liabilityBn = assetData?.liabilitiesBn[pool.label][tokenInstance.symbol]
        const liability = liabilityBn ? Number(formatEther(liabilityBn)) : 0
        const covRatio = liability > 0 ? (cash + fromAmount) / liability : 0
        contractCalls.push(pool.multicall('endCovRatio'))

        callbacks.push((endCovRatio: BigNumber) => {
          /**
           * To avoid the WOMBAT_COV_RATIO_LIMIT_EXCEEDED error
           * when calling the QuotePotentialSwap function
           * we will have to check if the asset's `covRatio` is less than `endCovRatio`
           * if yes then call the fuction
           */
          if (assetData && covRatio < Number(formatEther(endCovRatio))) {
            contractCalls.push(
              pool.multicall('quotePotentialSwap', [
                tokenInstance.address,
                toToken.address,
                utils.parseUnits('1', tokenInstance.decimals),
              ])
            )
            callbacks.push(
              (
                res: [BigNumber, BigNumber] & {
                  potentialOutcome: BigNumber
                  haircut: BigNumber
                }
              ) => {
                states.withoutAccount.exchangeRatesFromWombat[tokenInstance.symbol] =
                  utils.formatUnits(res.potentialOutcome.add(res.haircut), toToken.decimals)
              }
            )
          }
        })
      }
    }
  }

  handleTokenData()
  return {
    contractCalls,
    callbacks,
    states,
  }
}

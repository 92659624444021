import { useCallback } from 'react'
import { Token } from '../constants/contract/token/Token'
import { useWeb3 } from '../context/Web3Context'

// @dev https://eips.ethereum.org/EIPS/eip-747#wallet_watchasset
export default function useAddTokenToMetamask(token: Token | undefined): {
  addToken: () => void
} {
  const { connector } = useWeb3()

  const addToken = useCallback(() => {
    if (token && connector) {
      connector.watchAsset?.({
        address: token.address,
        decimals: token.decimals,
        symbol: token.symbol,
      })
    }
  }, [token, connector])

  return { addToken }
}

import { AssetConfig } from '..'
import { defaultAssetConfig } from '../defaultAssetConfig'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'

export const BSC_TESTNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.FACTORY_STABLES]: {
    [TokenSymbols.BUSD]: {
      name: 'Binance USD',
      color: '#D1A563',
      hasBribeV2: true,
    },
    [TokenSymbols.FRAX]: {
      name: 'Frax',
      color: '#000000',
    },
    [TokenSymbols.TUSD]: {
      name: 'TrueUSD',
      color: '#2347A4',
      hasBribeV2: true,
    },
  },
  [PoolLabels.MAIN]: {
    [TokenSymbols.BUSD]: {
      name: 'Binance USD',
      color: '#F8B900',
    },
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
    },
    [TokenSymbols.DAI]: {
      name: 'Dai Stablecoin',
      color: '#FFB118',
    },
    [TokenSymbols.vUSDC]: {
      name: 'Venus USDC',
      color: '#1D72B9',
    },
  },
  [PoolLabels.SIDE]: {
    [TokenSymbols.BUSD]: {
      name: 'Binance USD',
      color: '#F3DCAD',
      paused: true,
      delisted: true,
    },
    [TokenSymbols.HAY]: {
      name: 'HAY',
      color: '#E38E2A',
      hasBribeV2: true,
    },
    [TokenSymbols.FRAX]: {
      name: 'FRAX',
      color: '#000000',
      hasBribeV2: true,
    },
    [TokenSymbols.MIM]: {
      name: 'MIM',
      color: '#444',
      hasBribeV2: true,
    },
  },
  [PoolLabels.BNB]: {
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#F0B90B',
      displaySymbol: 'BNB', // display as BNB
      paused: true,
      delisted: true,
    },
    [TokenSymbols.BNBx]: {
      name: 'Stader BNB',
      color: '#755E21',
      paused: true,
      delisted: true,
    },
    [TokenSymbols.stkBNB]: {
      name: 'pSTAKE BNB',
      color: '#B53F3D',
      paused: true,
      delisted: true,
    },
    [TokenSymbols.ankrBNB]: {
      name: 'Ankr BNB',
      color: '#FAE853',
      paused: true,
      displaySymbol: 'aBNBc',
    },
  },
  [PoolLabels.qWOM]: {
    [TokenSymbols.qWOM]: {
      name: 'Quoll WOM',
      color: '#00F078',
      hasBribeV2: true,
    },
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#C6F4A2',
      hasBribeV2: true,
    },
  },
  [PoolLabels.mWOM]: {
    [TokenSymbols.mWOM]: {
      name: 'mWOM',
      color: '#A8D1FF',
      hasBribeV2: true,
    },
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#5DA6F8',
      hasBribeV2: true,
    },
  },
  [PoolLabels.wmxWOM]: {
    [TokenSymbols.wmxWOM]: {
      name: 'Wombex WOM',
      color: '#685D54',
    },
    [TokenSymbols.WOM]: {
      name: 'Wombat Token',
      color: '#9D561C',
    },
  },
  [PoolLabels.BNBx]: {
    [TokenSymbols.WBNB]: {
      name: 'BNB',
      color: '#F0B90B',
      displaySymbol: 'BNB', // display as BNB
    },
    [TokenSymbols.BNBx]: {
      name: 'Stader BNB',
      color: '#755E21',
    },
  },
  [PoolLabels.axlUSDC]: {
    [TokenSymbols.BUSD]: {
      name: 'Binance USD',
      color: '#2F455B',
    },
    [TokenSymbols.axlUSDC]: {
      name: 'Axelar Wrapped USDC',
      color: '#40648A',
    },
  },
  [PoolLabels.CUSD]: {
    [TokenSymbols.HAY]: {
      name: 'HAY',
      color: '#F8D675',
    },
    [TokenSymbols.CUSD]: {
      name: 'Coin98 Dollar',
      color: '#D8BD70',
    },
  },
  [PoolLabels.iUSD]: {
    [TokenSymbols.BUSD]: {
      name: 'Binance USD',
      color: '#4D719B',
    },
    [TokenSymbols.iUSD]: {
      name: 'iZUMi Bond USD',
      color: '#2A60F1',
    },
  },
  [PoolLabels.USDD]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#0E9189',
    },
    [TokenSymbols.USDD]: {
      name: 'Decentralized USD',
      color: '#216C58',
    },
  },
  [PoolLabels.BOB]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#F6AAAE',
    },
    [TokenSymbols.BOB]: {
      name: 'zkBob',
      color: '#EB73D9',
    },
  },
}

import { getParsableString, strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { COIN_GECKO_API, WAD_DECIMALS } from '../../../constants/common'
import { TOKENS } from '../../../constants/contract'
import { FetchPriceMethod } from '../../../constants/contract/token/Token'
import { TokenSymbol, TokenSymbols } from '../../../constants/contract/token/TokenSymbols'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { EvmPriceServiceConnection } from '@pythnetwork/pyth-evm-js'
import { chainInfos } from '../../../constants/web3/chains'
import {
  pythIdTokenSymbolInSupportedChainIdsMapping,
  tokenAddressTokenMap,
} from '../../../constants/contract/token'
import { ethers, utils } from 'ethers'
import { TokenDataWithoutAccountType } from './fetchTokenDataHelper'
import { UniswapV3PoolData } from './fetchUniswapV3PoolDataHelper'
import { UNISWAP_V3_QUOTER_ABI } from '../../../constants/contract/abis/uniswap'
import { TokenSymbolStringType } from '../../../interfaces/common'
import { CoinGeckoReponse } from '../../../store/Prices/types'

export const fetchCoingeckoPriceData = async (chainIds: SupportedChainId[]) => {
  // combine chains' tokens
  const tokensInTheChain = chainIds
    .map((chainId) => {
      return Object.values(TOKENS[chainId])
    })
    .flat()
  const coingeckoPrices: { [token in TokenSymbol]?: string } = {}
  try {
    const geckoIds: string[] = []
    for (const token of tokensInTheChain) {
      if (token.fetchPriceData.method === FetchPriceMethod.COINGECKO) {
        const id = token.fetchPriceData.payload.id
        if (!geckoIds.includes(id)) {
          // only contains unique ids
          geckoIds.push(token.fetchPriceData.payload.id)
        }
      }
    }
    if (!geckoIds.length) return undefined
    const geckoResponse = await fetch(
      `${COIN_GECKO_API}?ids=${encodeURIComponent(
        geckoIds.join(',')
      )}&vs_currencies=usd&include_market_cap=true&include_24hr_change=true`,
      {
        headers: {
          x_cg_demo_api_key: process.env.NEXT_PUBLIC_COINGECKO_API_KEY ?? '',
        },
      }
    )

    const geckoBody = (await geckoResponse.json()) as CoinGeckoReponse

    const allGeckoIdIncluded = Object.keys(geckoBody).every((geckoId) => geckoIds.includes(geckoId))
    if (allGeckoIdIncluded) {
      for (const token of tokensInTheChain) {
        if (token.fetchPriceData.method === FetchPriceMethod.COINGECKO) {
          const geckoId = token.fetchPriceData.payload.id
          if (geckoBody[geckoId]) {
            const { usd } = geckoBody[geckoId]
            coingeckoPrices[token.symbol] = getParsableString(String(usd), WAD_DECIMALS, true)
          }
        }
      }
    } else {
      console.debug(`NotAllGeckoIdIncluded: config:${geckoIds} vs res:${Object.keys(geckoBody)}`)
    }
    return coingeckoPrices
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const fetchPythPriceData = async (chainId: SupportedChainId) => {
  const pythPrices: { [token in TokenSymbol]?: string } = {}
  try {
    const connection = new EvmPriceServiceConnection(chainInfos[chainId].pythPriceServiceUrl)
    const pythPriceFeedIds: string[] = Object.values(
      pythIdTokenSymbolInSupportedChainIdsMapping[chainId]
    )

    const priceFeeds = await connection.getLatestPriceFeeds(pythPriceFeedIds)

    if (!priceFeeds) return

    for (const [tokenSymbol, pythId] of Object.entries(
      pythIdTokenSymbolInSupportedChainIdsMapping[chainId]
    )) {
      for (const priceFeed of priceFeeds) {
        const priceFeedObject = priceFeed.toJson()
        const price = priceFeedObject.price.price
        const id = priceFeed.id
        if (price && tokenSymbol && pythId.includes(id)) {
          // USDC price example: 999999989(Pyth price feed output) represents 0.999999989
          pythPrices[tokenSymbol as TokenSymbol] = utils.formatUnits(price, 8)
        }
      }
    }
    return pythPrices
  } catch (error) {
    console.error(`Pyth price feed error:${error}`)
    return undefined
  }
}

export const fetchUnitPriceData = async (
  chainId: SupportedChainId,
  uniswapV3PoolData: UniswapV3PoolData | undefined,
  readonlyProvider: ethers.providers.JsonRpcProvider
) => {
  const tokensInTheChain = TOKENS[chainId]

  const initExchangeRatesFromUniswapV3 = {} as {
    [id in TokenSymbol]: { rate: string; pairTokenSymbol: TokenSymbol }
  }
  for (const tokenInstance of Object.values(tokensInTheChain)) {
    const method = tokenInstance.fetchPriceData?.method
    if (method === FetchPriceMethod.UNISWAPV3) {
      const poolData = uniswapV3PoolData?.[tokenInstance.symbol]
      if (!poolData) continue
      const { fee, token0, token1 } = poolData
      const tokenAddressMap = tokenAddressTokenMap[chainId]
      if (!fee || !token0 || !token1 || !tokenAddressMap) continue
      const token0And1 = [token0.toLowerCase(), token1.toLowerCase()]
      const isTargetTokenAddressExisted = token0And1.some(
        (address) => tokenInstance.address.toLowerCase() === address
      )
      if (!isTargetTokenAddressExisted) {
        console.error(
          `FetchPriceMethod.UNISWAPV3: token0 or token1 doesn't contain ${tokenInstance.name}`
        )
        continue
      }

      const pairTokenAddress = token0And1.filter(
        (address) => tokenInstance.address.toLowerCase() !== address
      )[0]
      const pairToken = tokenAddressMap[pairTokenAddress]
      if (!pairToken) {
        console.error(
          `FetchPriceMethod.UNISWAPV3 (target token ${tokenInstance.symbol}): pairToken (${pairTokenAddress}) is not found`
        )
        continue
      }
      const quoterContract = new ethers.Contract(
        tokenInstance.fetchPriceData.payload.quoterAddress,
        UNISWAP_V3_QUOTER_ABI,
        readonlyProvider
      )
      // quoteExactInputSingle is a write method so we need to use `callStatic` from ether.js
      const quotedAmountOutBn = await quoterContract.callStatic.quoteExactInputSingle(
        tokenInstance.address,
        pairTokenAddress,
        fee,
        utils.parseUnits('1', tokenInstance.decimals),
        0
      )

      initExchangeRatesFromUniswapV3[tokenInstance.symbol] = {
        ...initExchangeRatesFromUniswapV3[tokenInstance.symbol],
        rate: utils.formatUnits(quotedAmountOutBn, pairToken.decimals),
        pairTokenSymbol: pairToken.symbol,
      }
    }
  }
  return initExchangeRatesFromUniswapV3
}

export type TokenPricesType = {
  [token in TokenSymbol]?: string
}

export function getInitialTokenPricesProperty(): TokenPricesType {
  return Object.values(TokenSymbols).reduce((previousValue, currentValue) => {
    return { ...previousValue, [currentValue]: undefined }
  }, {} as TokenPricesType)
}

export const handlePriceWithHardcodeMethod = (
  chainId: SupportedChainId,
  tokenPrices: TokenPricesType
) => {
  const tokensInTheChain = TOKENS[chainId]
  Object.values(tokensInTheChain).forEach((tokenInstance) => {
    const method = tokenInstance.fetchPriceData?.method
    const tokenSymbol = tokenInstance.symbol
    if (method === FetchPriceMethod.HARDCODE) {
      const hardcodeTokenSymbol = tokenInstance.fetchPriceData.payload.tokenSymbol
      const hardcodeValue = tokenInstance.fetchPriceData.payload.value
      if (hardcodeTokenSymbol) {
        tokenPrices[tokenSymbol] = tokenPrices[hardcodeTokenSymbol]
      }
      if (hardcodeValue) tokenPrices[tokenSymbol] = hardcodeValue
    }
  })

  return tokenPrices
}

export const aggregatePrice = async (
  chainId: SupportedChainId,
  coingeckoPrices:
    | {
        [id in TokenSymbol]?: string
      }
    | undefined,
  pythPrices:
    | {
        [id in TokenSymbol]?: string
      }
    | undefined,
  exchangeRatesFromUniswapV3:
    | {
        [id in TokenSymbol]?: { rate: string; pairTokenSymbol: TokenSymbol }
      },
  tokenData: TokenDataWithoutAccountType | undefined
) => {
  const tokensInTheChain = TOKENS[chainId]
  let tokenPrices: { [token in TokenSymbol]?: string } = {}

  // Step 1: push pythPrices to initPricesInUsdWad -> get WBNB and BUSD price (Dex method needed)
  tokenPrices = { ...coingeckoPrices, ...pythPrices }

  // Step 2: push dex method Prices to initPricesInUsdWad -> get wom price (Wombat method needed)
  for (const [tokenSymbol, dexRelativePrice] of Object.entries(
    tokenData?.exchangeRatesFromDex || {}
  )) {
    const token = tokensInTheChain[tokenSymbol as TokenSymbol]
    if (token && token.fetchPriceData.method === FetchPriceMethod.DEX) {
      const toTokenSymbol = token.fetchPriceData.payload.toToken.symbol
      const toTokenPrice = tokenPrices[toTokenSymbol as TokenSymbol]
      if (!toTokenPrice) continue
      tokenPrices[tokenSymbol as TokenSymbol] = utils.formatEther(
        wmul(utils.parseEther(toTokenPrice), utils.parseEther(dexRelativePrice))
      )
    }
  }

  // Step 3: compute prices with UniswapV3 method. WOM pirce in arbitrum chain relies on this method.
  // Pair token prices are normally fetched from DEX method.
  for (const [targetTokenSymbol, { pairTokenSymbol, rate }] of Object.entries(
    exchangeRatesFromUniswapV3
  )) {
    const pairTokenPrice = tokenPrices[pairTokenSymbol as TokenSymbol]
    if (!pairTokenPrice) {
      console.error(
        `FetchPriceMethod.UNISWAPV3 (target token ${targetTokenSymbol}): pair token (${pairTokenSymbol}) has no price.`
      )
      continue
    }
    tokenPrices[targetTokenSymbol as TokenSymbol] = utils.formatEther(
      wmul(utils.parseEther(rate), utils.parseEther(pairTokenPrice))
    )
  }

  // Step 4: push wombat method Prices to initPricesInUsdWad
  for (const [tokenSymbolStr, token] of Object.entries(tokensInTheChain)) {
    const tokenSymbol = tokenSymbolStr as TokenSymbol
    if (token.fetchPriceData.method !== FetchPriceMethod.WOMBAT) continue
    const toTokenSymbol = token.fetchPriceData.payload.toTokenSymbol
    if (!toTokenSymbol) continue
    const exchangeRatesFromWombat = tokenData?.exchangeRatesFromWombat
    const wombatExchangeRate = exchangeRatesFromWombat?.[tokenSymbol]
    // if no wombatExchangeRate, then assign it to toTokenSymbol price
    const toTokenPrice = tokenPrices[toTokenSymbol]
    tokenPrices[tokenSymbol] = toTokenPrice
    if (!toTokenPrice || !wombatExchangeRate) continue
    tokenPrices[tokenSymbol] = utils.formatEther(
      wmul(strToWad(toTokenPrice), strToWad(wombatExchangeRate))
    )
  }

  tokenPrices = handlePriceWithHardcodeMethod(chainId, tokenPrices)
  return tokenPrices
}

export const fetchTokenPrices = async (
  chainId: SupportedChainId,
  tokenData: TokenDataWithoutAccountType | undefined,
  uniswapV3PoolData: UniswapV3PoolData,
  readonlyProvider: ethers.providers.JsonRpcProvider,
  coingeckoPrices: Partial<TokenSymbolStringType> | undefined
) => {
  const pythPrices = await fetchPythPriceData(chainId)
  const exchangeRatesFromUniswapV3 = await fetchUnitPriceData(
    chainId,
    uniswapV3PoolData,
    readonlyProvider
  )

  return await aggregatePrice(
    chainId,
    coingeckoPrices,
    pythPrices,
    exchangeRatesFromUniswapV3,
    tokenData
  )
}

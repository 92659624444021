import { useState } from 'react'
import { HexString } from '../../interfaces/contract'
import { useContract } from 'wagmi'
import { useWeb3 } from '../../context/Web3Context'
import { BRIBE_FACTORIES } from '../../constants/contract/bribeFactory'
import { LATEST_MASTER_WOMBATS, TOKENS, VOTERS } from '../../constants/contract'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { BigNumber, ethers, utils } from 'ethers'
import { calculateGasMargin } from '../../utils'
import { ERC20_ABI } from '../../constants/contract/abis/erc20'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { ASSETS } from '../../constants/contract/asset'
import { POOLS } from '../../constants/contract/pool'
import { BRIBE_V2_ABI } from '../../constants/contract/abis/bribeV2'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../../context/errorMessage'
import { useModalContext } from '../../context/ModalContext'
import { ModalId } from '../../interfaces/modal'
import { isParsableString } from '@hailstonelabs/big-number-utils'
import { multicall } from '@wagmi/core'
import { AssetRewarderType } from '../../interfaces/selfServiceBribeRewarder'
import { useAppDispatch } from '../../store/hooks'
import { addSuccessToast, addErrorToast } from '../../store/Toast/actions'

/** Helper */
function getStartUnixTimestampBn(delayInMin = 1) {
  const startTimestamp = new Date()
  // we assume the rewarder will be effective after 60 seconds
  startTimestamp.setMinutes(startTimestamp.getMinutes() + delayInMin)
  return BigNumber.from(Math.floor(startTimestamp.getTime() / 1000))
}
/** Main Hook */
function useSelfServiceBribe() {
  const { chainId, signer, readonlyProvider } = useWeb3()
  const [isAddingNewBribeRewarder, setIsAddingNewBribeRewarder] = useState(false)
  const [isAddingNewBribeToken, setIsAddingNewBribeToken] = useState(false)
  const [isUpdatingBribeRewardRate, setIsUpdatingBribeRewardRate] = useState(false)
  const [isToppingUpBribe, setIsToppingUpBribe] = useState(false)
  const dispatch = useAppDispatch()
  const bribeFactoryContract = useContract({
    ...BRIBE_FACTORIES[chainId]?.get(),
    signerOrProvider: signer,
  })
  const voterContract = useContract({
    ...VOTERS[chainId]?.get(),
    signerOrProvider: readonlyProvider,
  })
  const {
    actions: { openModal },
  } = useModalContext()
  /**
   * This function is for an undeployed bribe rewarder
   */
  async function addNewBribeRewarder({
    poolLabel,
    assetTokenSymbol,
    rewardTokenSymbol,
    tokenPerSec,
    amount,
    rewarderType,
    startDate,
  }: {
    poolLabel: PoolLabels
    assetTokenSymbol: TokenSymbol
    rewardTokenSymbol: TokenSymbol
    tokenPerSec: string
    amount: string
    rewarderType: AssetRewarderType
    startDate?: Date | null
  }) {
    try {
      setIsAddingNewBribeRewarder(true)
      if (!signer) throw new Error('Signer is not found!')
      if (bribeFactoryContract) {
        const rewardToken = TOKENS[chainId][rewardTokenSymbol]
        if (!rewardToken) {
          throw new Error(`${rewardTokenSymbol}'s token config is not found.`)
        }
        const assetToken = ASSETS[chainId][poolLabel][assetTokenSymbol]
        if (!assetToken) {
          throw new Error(`${poolLabel}-${assetTokenSymbol}'s asset config is not found.`)
        }

        let formattedTokenPerSec = tokenPerSec
        if (!isParsableString(tokenPerSec, rewardToken.decimals, true)) {
          formattedTokenPerSec = (+tokenPerSec).toFixed(rewardToken.decimals).toString()
        }
        const tokenPerSecBn = utils.parseUnits(formattedTokenPerSec, rewardToken.decimals)
        const userInputStartUnixTimestampBn = startDate
          ? BigNumber.from(Math.floor(startDate?.getTime() / 1000))
          : BigNumber.from(0)
        const defaultStartUnixTimestampBn = getStartUnixTimestampBn(15)
        const currentUnixTimestampBn = BigNumber.from(Math.floor(new Date().getTime() / 1000))

        const deployBribeRewarderFnArgs: [HexString, BigNumber, HexString, BigNumber] = [
          assetToken.address,
          userInputStartUnixTimestampBn.gt(currentUnixTimestampBn)
            ? userInputStartUnixTimestampBn
            : defaultStartUnixTimestampBn,
          rewardToken.address,
          tokenPerSecBn,
        ]
        let transaction: ethers.ContractTransaction | null = null
        const isPoolRewarder = rewarderType === 'boostedPoolRewarder'
        /**
         * Step 1: Deploy a bribe rewarder or a pool rewarder
         */
        if (isPoolRewarder) {
          const estimatedGas =
            await bribeFactoryContract.estimateGas.deployRewarderContractAndSetRewarder(
              ...deployBribeRewarderFnArgs
            )
          transaction = await bribeFactoryContract.deployRewarderContractAndSetRewarder(
            ...deployBribeRewarderFnArgs,
            {
              gasLimit: calculateGasMargin(estimatedGas),
            }
          )
        } else {
          const estimatedGas =
            await bribeFactoryContract.estimateGas.deployBribeContractAndSetBribe(
              ...deployBribeRewarderFnArgs
            )
          transaction = await bribeFactoryContract.deployBribeContractAndSetBribe(
            ...deployBribeRewarderFnArgs,
            {
              gasLimit: calculateGasMargin(estimatedGas),
            }
          )
        }

        const receipt = await transaction.wait()

        dispatch(
          addSuccessToast({
            message: `A new rewarder is deployed for ${assetToken.displaySymbol} (${POOLS[chainId][poolLabel]?.name})`,
            title: 'Successfully deployef a new bribe rewarder',
            txHash: receipt.transactionHash,
          })
        )

        // retrieve bribeAddress / poolRewarderAddress from the target event
        const eventName = isPoolRewarder ? 'DeployRewarderContract' : 'DeployBribeContract'
        const event = receipt.events?.find((event) => event.event === eventName)
        const deployedBribeRewarderAddress =
          (isPoolRewarder ? event?.args?.rewarder : event?.args?.bribe) || null

        // update asset rewarder address for data fetching
        assetToken[rewarderType] = {
          ...assetToken[rewarderType],
          rewarderAddress: deployedBribeRewarderAddress,
        }
        /**
         * Step 2: send tokens to the deployed rewarder
         */
        await topUpBribe(deployedBribeRewarderAddress, rewardTokenSymbol, amount)
      }
    } catch (err) {
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(err).includes(code)) {
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        const error = err as Error
        const errorMessage = error.message || 'Failed to add a new bribe rewarder.'
        dispatch(
          addErrorToast({
            message: errorMessage,
          })
        )
      }
    } finally {
      setIsAddingNewBribeRewarder(false)
    }
  }
  /**
   * This function is for a deployed bribe rewarder
   */
  async function addNewBribeToken({
    poolLabel,
    assetTokenSymbol,
    rewardTokenSymbol,
    tokenPerSec,
    amount,
    rewarderType,
    startDate,
  }: {
    poolLabel: PoolLabels
    assetTokenSymbol: TokenSymbol
    rewardTokenSymbol: TokenSymbol
    tokenPerSec: string
    amount: string
    rewarderType: AssetRewarderType
    startDate?: Date | null
  }) {
    try {
      setIsAddingNewBribeToken(true)
      if (!signer) throw new Error('Signer is not found!')
      const rewardToken = TOKENS[chainId][rewardTokenSymbol]
      if (!rewardToken) {
        throw new Error(`${rewardTokenSymbol}'s token config is not found.`)
      }
      const assetToken = ASSETS[chainId][poolLabel][assetTokenSymbol]
      if (!assetToken) {
        throw new Error(`${poolLabel}-${assetTokenSymbol}'s asset config is not found.`)
      }

      const rewarderAddress = assetToken[rewarderType]?.rewarderAddress
      if (!rewarderAddress) {
        throw new Error(`Rewarder is not found in ${poolLabel}-${assetTokenSymbol}.`)
      }

      let formattedTokenPerSec = tokenPerSec
      if (!isParsableString(tokenPerSec, rewardToken.decimals, true)) {
        formattedTokenPerSec = (+tokenPerSec).toFixed(rewardToken.decimals).toString()
      }
      const tokenPerSecBn = utils.parseUnits(formattedTokenPerSec, rewardToken.decimals)
      const rewarderContract = new ethers.Contract(rewarderAddress, BRIBE_V2_ABI, signer)

      const userInputStartUnixTimestampBn = startDate
        ? BigNumber.from(Math.floor(startDate.getTime() / 1000))
        : BigNumber.from(0)

      const addRewardTokenFnArgs: [HexString, BigNumber, BigNumber] = [
        rewardToken.address,
        userInputStartUnixTimestampBn,
        tokenPerSecBn,
      ]
      /**
       * Step 1: Add a new bribe token to an existing bribe rewarder
       */
      const estimatedGas = await rewarderContract.estimateGas.addRewardToken(
        ...addRewardTokenFnArgs
      )
      const transaction = await rewarderContract.addRewardToken(...addRewardTokenFnArgs, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      const receipt = await transaction.wait()
      dispatch(
        addSuccessToast({
          message: `${rewardToken.displaySymbol} is added ${assetToken.displaySymbol} (${POOLS[chainId][poolLabel]?.name})`,
          title: 'Successfully added a new bribe reward',
          txHash: receipt.transactionHash,
        })
      )
      /**
       * Step 2: send tokens to the deployed rewarder
       */
      await topUpBribe(rewarderAddress, rewardTokenSymbol, amount)
    } catch (err) {
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(err).includes(code)) {
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        const error = err as Error
        const errorMessage = error.message || 'Failed to add a new bribe token.'
        dispatch(
          addErrorToast({
            message: errorMessage,
          })
        )
      }
    } finally {
      setIsAddingNewBribeToken(false)
    }
  }

  async function updateBribeRewardRate({
    poolLabel,
    assetTokenSymbol,
    rewardTokenSymbol,
    tokenPerSec,
    rewarderType,
  }: {
    poolLabel: PoolLabels
    assetTokenSymbol: TokenSymbol
    rewardTokenSymbol: TokenSymbol
    tokenPerSec: string
    rewarderType: AssetRewarderType
  }) {
    try {
      setIsUpdatingBribeRewardRate(true)
      if (!signer) throw new Error('Signer is not found!')
      const rewardToken = TOKENS[chainId][rewardTokenSymbol]
      if (!rewardToken) {
        throw new Error(`${rewardTokenSymbol}'s token config is not found.`)
      }
      const assetToken = ASSETS[chainId][poolLabel][assetTokenSymbol]
      if (!assetToken) {
        throw new Error(`${poolLabel}-${assetTokenSymbol}'s asset config is not found.`)
      }

      let formattedTokenPerSec = tokenPerSec
      if (!isParsableString(tokenPerSec, rewardToken.decimals, true)) {
        formattedTokenPerSec = (+tokenPerSec).toFixed(rewardToken.decimals).toString()
      }
      const tokenPerSecBn = utils.parseUnits(formattedTokenPerSec, rewardToken.decimals)

      const rewarderAddress = assetToken[rewarderType]?.rewarderAddress
      if (!rewarderAddress) {
        throw new Error(`Rewarder is not found in ${poolLabel}-${assetTokenSymbol}.`)
      }
      const rewardTokenId = assetToken[rewarderType]?.rewardTokenSymbols?.indexOf(rewardTokenSymbol)
      if (rewardTokenId === undefined) {
        throw new Error(
          `${rewardToken.displaySymbol} reward is not found in ${poolLabel}-${assetTokenSymbol}.`
        )
      }
      const bribeRewarderContract = new ethers.Contract(rewarderAddress, BRIBE_V2_ABI, signer)
      const setRewardRateFnArgs: [BigNumber, BigNumber, BigNumber] = [
        BigNumber.from(rewardTokenId),
        tokenPerSecBn,
        BigNumber.from(0),
      ]
      const estimatedGas = await bribeRewarderContract.estimateGas.setRewardRate(
        ...setRewardRateFnArgs
      )
      const transaction = await bribeRewarderContract.setRewardRate(...setRewardRateFnArgs, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      const receipt = await transaction.wait()
      openModal({
        currentModalId: ModalId.TRANSACTION_SUBMITTED,
        payload: { transactionHashes: [receipt.transactionHash] },
      })
    } catch (err) {
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(err).includes(code)) {
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        const error = err as Error
        const errorMessage = error.message || 'Failed to update reward rate.'
        dispatch(
          addErrorToast({
            message: errorMessage,
          })
        )
      }
    } finally {
      setIsUpdatingBribeRewardRate(false)
    }
  }

  async function topUpBribe(
    rewarderAddress: HexString,
    rewardTokenSymbol: TokenSymbol,
    amount: string
  ) {
    try {
      setIsToppingUpBribe(true)
      if (!signer) throw new Error('Signer is not found!')
      const rewardToken = TOKENS[chainId][rewardTokenSymbol]
      if (!rewardToken) {
        throw new Error(`${rewardTokenSymbol}'s token config is not found.`)
      }
      const amountBn = utils.parseUnits(amount, rewardToken.decimals)
      const rewardTokenContract = new ethers.Contract(rewardToken.address, ERC20_ABI, signer)
      const transferFnArgs: [HexString, BigNumber] = [rewarderAddress, amountBn]
      const estimatedGas = await rewardTokenContract.estimateGas.transfer(...transferFnArgs)
      const transaction = await rewardTokenContract.transfer(...transferFnArgs, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      const receipt = await transaction.wait()
      openModal({
        currentModalId: ModalId.TRANSACTION_SUBMITTED,
        payload: { transactionHashes: [receipt.transactionHash] },
      })
    } catch (err) {
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(err).includes(code)) {
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        const error = err as Error
        const errorMessage = error.message || 'Failed to top up the rewarder.'
        dispatch(
          addErrorToast({
            message: errorMessage,
          })
        )
      }
    } finally {
      setIsToppingUpBribe(false)
    }
  }

  async function isBribeRewarderDeployed({
    poolLabel,
    assetTokenSymbol,
    isPoolRewarder,
  }: {
    poolLabel: PoolLabels
    assetTokenSymbol: TokenSymbol
    isPoolRewarder: boolean
  }) {
    try {
      const assetToken = ASSETS[chainId][poolLabel][assetTokenSymbol]
      if (!assetToken) {
        throw new Error(`${poolLabel}-${assetTokenSymbol}'s asset config is not found.`)
      }
      if (isPoolRewarder) {
        const masterWombatContract = LATEST_MASTER_WOMBATS[chainId]
        const result = await multicall({
          contracts: [masterWombatContract.multicall('boostedRewarders', [assetToken.pid])],
          chainId,
        })
        return result[0] !== ethers.constants.AddressZero
      }
      if (!isPoolRewarder && voterContract) {
        const infos = await voterContract.infos(assetToken.address)
        return infos.bribe !== ethers.constants.AddressZero
      }
      return false
    } catch (err) {
      const error = err as Error
      const errorMessage = error.message || 'Failed to check bribe rewawarder deployed or not.'
      dispatch(
        addErrorToast({
          message: errorMessage,
        })
      )
      return false
    }
  }

  return {
    addNewBribeRewarder,
    topUpBribe,
    addNewBribeToken,
    updateBribeRewardRate,
    isAddingNewBribeRewarder,
    isAddingNewBribeToken,
    isUpdatingBribeRewardRate,
    isToppingUpBribe,
    isBribeRewarderDeployed,
  }
}

export default useSelfServiceBribe

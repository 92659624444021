import { createAsyncThunk } from '@reduxjs/toolkit'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { TokenPricesState } from '../slice'
import { ethers } from 'ethers'
import { UniswapV3PoolData } from '../../../context/MulticallDataContext/helpers/fetchUniswapV3PoolDataHelper'
import { fetchUnitPriceData } from '../../../context/MulticallDataContext/helpers/fetchTokenPricesHelper'

const getUnitPriceData = createAsyncThunk<
  Pick<TokenPricesState, 'exchangeRatesFromUniswapV3'>,
  {
    chainId: SupportedChainId
    readonlyProvider: ethers.providers.JsonRpcProvider
    uniswapV3PoolData: UniswapV3PoolData | undefined
  }
>('tokenPrices/getUnitPriceData', async ({ chainId, readonlyProvider, uniswapV3PoolData }) => {
  const res = await fetchUnitPriceData(chainId, uniswapV3PoolData, readonlyProvider)
  return {
    exchangeRatesFromUniswapV3: res,
  }
})

export default getUnitPriceData

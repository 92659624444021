import Image from 'next/image'
import veWOMicon from '../../public/assets/icons/veWOM-grey-boost.svg'
import warningIcon from '../../public/assets/icons/warning-icon.svg'
import Button from '../Button'
import Modal from '../Modal'

interface Props {
  open: boolean
  onClose: () => void
  tryHandleUnLock: () => void
}

const BoostUnlockModal = ({ onClose, open, tryHandleUnLock }: Props) => {
  return (
    <Modal
      title="Unlock WOM"
      isOpen={open}
      onClose={onClose}
      isCenterTitle
      isShowBackButton={false}
    >
      <div
        style={{
          marginTop: '34px',
        }}
        className="flex w-full items-center justify-center"
      >
        <Image src={veWOMicon} width={80} height={80} />
      </div>
      <div
        style={{
          marginTop: '24px',
        }}
        className="flex items-center justify-center"
      >
        <div className="flex w-[300px] justify-center md:justify-between">
          <div className="flex items-center">
            <Image src={warningIcon} width={32} height={32} />
          </div>
          <div className="ml-1 hidden w-full text-center text-xs text-wombatRed md:block">
            Unlocking WOM will remove any veWOM earned on the specified slot.
          </div>
          <div className="ml-1 w-[210px] text-center text-xs text-wombatRed md:hidden">
            <div>Unlock WOM will cost</div>
            <div>
              <span className="text-sm font-semibold">PERMANENT LOST</span> of $veWOM
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: '30px',
          marginBottom: '50px',
        }}
        className="flex items-center justify-center"
      >
        <div className="flex w-[300px] flex-col items-center justify-center">
          <Button
            className="font-bold"
            onClick={() => {
              onClose()
            }}
          >
            CANCEL
          </Button>
          <Button
            className="mt-6 bg-wombatBrown2 font-bold"
            onClick={() => {
              tryHandleUnLock()
              onClose()
            }}
          >
            UNLOCK
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default BoostUnlockModal

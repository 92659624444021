import { AssetConfig } from '..'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'
import { defaultAssetConfig } from '../defaultAssetConfig'

export const AVAX_MAINNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
      enableVoting: false,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
      enableVoting: false,
    },
  },
  [PoolLabels.MAIN]: {
    [TokenSymbols.WAVAX]: {
      name: 'Wrapped AVAX',
      color: '#F3BA2F',
      enableVoting: false,
      displaySymbol: 'AVAX',
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
      enableVoting: false,
    },
    [TokenSymbols['BTC.b']]: {
      name: 'Bitcoin Avalanche Bridged',
      color: '#F7931A',
      enableVoting: false,
    },
  },
  [PoolLabels.Agora_Stable_Pool]: {
    [TokenSymbols.AUSD]: {
      name: 'Avalanche USD',
      color: '#9B9253',
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
    },
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
    },
  },
  [PoolLabels.Liquid_Staking_Pool]: {
    [TokenSymbols.ggAVAX]: {
      name: 'ggAVAX',
      color: '#D4D4D4',
    },
    [TokenSymbols.sAVAX]: {
      name: 'Staked AVAX',
      color: '#2AD4B7',
    },
    [TokenSymbols.WAVAX]: {
      name: 'Wrapped AVAX',
      color: '#F3BA2F',
    },
  },
  [PoolLabels.sAVAX]: {
    [TokenSymbols.WAVAX]: {
      name: 'Wrapped AVAX',
      color: '#F3BA2F',
      enableVoting: false,
      displaySymbol: 'AVAX',
    },
    [TokenSymbols.sAVAX]: {
      name: 'Staked AVAX',
      color: '#2AD4B7',
      enableVoting: false,
    },
  },
  [PoolLabels.USDV]: {
    [TokenSymbols.USDV]: {
      name: 'USDV',
      color: '#D4D4D4',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#767676',
    },
  },
  [PoolLabels.ggAVAX_AVAX]: {
    [TokenSymbols.ggAVAX]: {
      name: 'ggAVAX',
      color: '#D4D4D4',
    },
    [TokenSymbols.GGP]: {
      name: 'GGP',
      color: '#767676',
    },
  },
  [PoolLabels.AXON_STABLE]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
      wppBadge: '2',
    },
    [TokenSymbols.UXDA]: {
      name: 'UXDA',
      color: '#0a48e5',
      wppBadge: '2',
    },
  },
}

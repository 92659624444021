import { BigNumber, ContractTransaction } from 'ethers'
import { useState } from 'react'
import { POOL_STATE } from '../components/PoolPage'
import { Asset } from '../constants/contract/asset/Asset'
import {
  LATEST_MASTER_WOMBATS,
  MasterWombatId,
  MASTER_WOMBATS,
} from '../constants/contract/masterWombat'
import { DELAY_TIME_ANIMATION_GIF } from '../constants/common'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../context/errorMessage'
import { useWeb3 } from '../context/Web3Context'
import { calculateGasMargin } from '../utils'
import { useTxnReceipt } from './../context/TxnReceiptProvider'
import { useContract } from 'wagmi'
import { MASTER_WOMBAT_V3_ABI } from '../constants/contract/abis/masterWombat'
import { useAppDispatch } from '../store/hooks'
import { addErrorToast, addSuccessToast } from '../store/Toast/actions'

export enum HandleUnStakeState {
  IDLE,
  LOADING,
  SUBMITED,
  FAILED,
  SUCCESS,
}

export default function useHandleUnStake(
  asset: Asset,
  amount: BigNumber | null,
  onTxnSubmited: (txn: ContractTransaction) => void,
  data: string,
  redirect: (value: POOL_STATE | null) => void,
  masterWombatId: 'latest' | MasterWombatId = 'latest',
  pidBn?: BigNumber
) {
  const { chainId, signer } = useWeb3()
  const masterWombat =
    masterWombatId === 'latest'
      ? LATEST_MASTER_WOMBATS[chainId]
      : MASTER_WOMBATS[chainId]?.[masterWombatId]
  // add abi for solving type error (masterWombat abi is V2|V3)
  const masterWombatContract = useContract({
    ...masterWombat?.get(),
    abi: MASTER_WOMBAT_V3_ABI,
    signerOrProvider: signer,
  })

  const [unStakeState, setUnStakeState] = useState(HandleUnStakeState.IDLE)
  const [txnHash, setTxnHash] = useState('')
  const [isDisplayGifFlyToTheMoon, setIsDisplayGifFlyToTheMoon] = useState(false) // used for changing gif FlyToTheMoon
  const { refreshTxnReceipt } = useTxnReceipt()
  const dispatch = useAppDispatch()

  async function tryHandleUnStake(setTokenAmountForDisplay: () => void) {
    const selectedPidBn = pidBn ? pidBn : BigNumber.from(asset.pid)
    if (!masterWombatContract || !amount || !selectedPidBn) {
      console.error(
        '@tryHandleUnStake:\n' +
          `token: ${asset.symbol}\n` +
          `MasterWombat: ${masterWombatContract}\n` +
          `pid: ${selectedPidBn}`
      )
      return
    }
    setUnStakeState(HandleUnStakeState.LOADING)

    masterWombatContract.estimateGas
      .withdraw(selectedPidBn, amount)
      .then((estimateGas) =>
        masterWombatContract.withdraw(selectedPidBn, amount, {
          gasLimit: calculateGasMargin(estimateGas),
        })
      )
      .then((txn) => {
        onTxnSubmited(txn)
        setTxnHash(txn.hash)
        setIsDisplayGifFlyToTheMoon(true) // USED FOR CHANGING GIF FLY TO THE MOON
        setTimeout(() => {
          setTokenAmountForDisplay()
          setUnStakeState(HandleUnStakeState.SUBMITED)
          setIsDisplayGifFlyToTheMoon(false)
        }, DELAY_TIME_ANIMATION_GIF) // SHOW MODAL SUBMITTED AFTER GIF FLY TO THE MOON OUTSIDE
        txn
          .wait()
          .then(() => {
            setUnStakeState(HandleUnStakeState.SUCCESS)
            refreshTxnReceipt()
            dispatch(
              addSuccessToast({
                message: data,
                title: 'Unstake Completed',
                txHash: txn.hash,
                childrenButton: 'Stake LP',
                handleClickButton: () => redirect(POOL_STATE.STAKE),
              })
            )
          })
          .catch(() => {
            dispatch(
              addErrorToast({
                message: data,
                title: 'Unstake Failed',
                txHash: txn.hash,
              })
            )
            setUnStakeState(HandleUnStakeState.FAILED)
          })
      })
      .catch((error) => {
        const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
        if (JSON.stringify(error).includes(code)) {
          setUnStakeState(HandleUnStakeState.IDLE)
          const errorMessage = ErrorMessages[code]
          dispatch(
            addErrorToast({
              message: errorMessage.message,
              title: errorMessage.title,
            })
          )
        } else {
          dispatch(
            addErrorToast({
              message: data,
              title: 'Unstake Failed',
              txHash: txnHash,
            })
          )
          setUnStakeState(HandleUnStakeState.FAILED)
          const errInfo =
            '@MasterWombat.withdraw\n' +
            `_pid: ${selectedPidBn.toString()}\n` +
            `_amount: ${amount.toString()}\n` +
            `txHash: ${txnHash}\n`
          console.error(errInfo, error)
        }
      })
  }

  return { unStakeState, tryHandleUnStake, txnHash, isDisplayGifFlyToTheMoon }
}

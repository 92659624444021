import { useEffect, useState } from 'react'

interface WindowDimension {
  width: number
  height: number
}

const getWindowDimensions = (): WindowDimension => {
  if (typeof window === 'undefined') {
    return {
      width: 0,
      height: 0,
    }
  }

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

export const useWindowDimension = (): WindowDimension => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimension>(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

import React, { useEffect, useMemo, useState } from 'react'
import Modal from '../../Modal'
import { useModalContext } from '../../../context/ModalContext'
import Button from '../../Button'
import Image from 'next/image'
import arrowRight from '../../../public/assets/icons/arrow-right-with-tail.svg'
import { useWeb3 } from '../../../context/Web3Context'
import { chainInfos } from '../../../constants/web3/chains'
import ChainSelectionDropdown from '../../ChainSelectionDropdown'
import SwapRecipientBox from '../SwapRecipientBox'
import { getFilteredTokenMaps } from '../../../utils/router'
import DropdownInput from '../../inputs/DropdownInput'
import { Token } from '../../../constants/contract/token/Token'
import TokenImage from '../../TokenImage'
import { TOKENS } from '../../../constants/contract'
import Input from '../../inputs/Input'
import { getCommifiedFormat, isParsableString } from '@hailstonelabs/big-number-utils'
import Tooltip from '../../Tooltip'
import FormattedNumber from '../../FormattedNumber'
import TooltipNum from '../../TooltipNum'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../../constants/contract/token'
import { useSwapCreditContext } from '../../../context/SwapCreditContext'
import { useSwapCreditForTokens } from '../../../hooks/swap/useSwapCreditForTokens'
import { useDebounce } from '../../../hooks/useDebounce'
import { isNonEmptyAddress } from '../../../utils'
import SettingIcon from '../../../public/assets/icons/setting.svg'
import { ModalId } from '../../../interfaces/modal'
import { useSwapContext } from '../../../context/SwapContext'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { useUserPreference } from '../../../context/userPreferenceContext'
import { SWAP_GAS_ON_DEST_CHAIN_TOOLTIP_MSG } from '../../../constants/infoData'

type Props = {
  isOpen: boolean
}

function SwapCreditModal({ isOpen }: Props) {
  const {
    userPreference: { isRecipientShown },
  } = useUserPreference()
  const {
    actions: { closeModal, openModal },
  } = useModalContext()
  const { chainId, account } = useWeb3()
  const [hasInvalidRecipientAddress, setHasInvalidRecipientAddress] = useState(false)
  const {
    priceImpact,
    estDeliveryFee,
    selectedToChainId,
    selectedToTokenSymbol,
    minimumReceive,
    isCrossChainCreditSwap,
    recipientAddress,
    creditBalance,
    fee,
    creditInput,
    isQuotingSwapInfo,
    handleToChainIdChange,
    setSelectedToTokenSymbol,
    setRecipientAddress,
    resetAllInputAmount,
    updateSwapCreditInput,
  } = useSwapCreditContext()

  const { receiverValue } = useSwapContext()

  const { handleSameChainSwapCreditForToken, handleCrossChainSwapCreditForToken, isLoading } =
    useSwapCreditForTokens()

  // credit inputted in the text field
  const [creditDisplayInput, setCreditDisplayInput] = useState(creditInput || '')

  // debounced credit input amount
  const debouncedCreditInputted = useDebounce(creditDisplayInput)

  const selectedToToken = selectedToTokenSymbol
    ? TOKENS[selectedToChainId][selectedToTokenSymbol]
    : null

  const isInputExceedingCreditBalance = +creditDisplayInput > +creditBalance
  const isReadyToSwapCreditForTokens =
    recipientAddress &&
    +creditDisplayInput > 0 &&
    isNonEmptyAddress(recipientAddress) &&
    !isInputExceedingCreditBalance &&
    !isQuotingSwapInfo

  const availableTokenList: Token[] = useMemo(
    () =>
      Object.values(getFilteredTokenMaps(selectedToChainId, true)).filter(
        (token) => token.isCrossChainAvailable
      ),
    [selectedToChainId]
  )

  // when debounced  amount changes, update it to swap credit context
  useEffect(() => {
    void updateSwapCreditInput(debouncedCreditInputted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCreditInputted])

  const handleCreditInputChange = (value: string | null) => {
    if (!value || value === '' || !selectedToTokenSymbol) {
      setCreditDisplayInput('')
      resetAllInputAmount()
      return
    }
    const selectedToken = TOKENS[selectedToChainId][selectedToTokenSymbol]
    if (!selectedToken) return
    if (isParsableString(value, selectedToken.decimals, true)) {
      setCreditDisplayInput(value)
    }
  }

  /** when user swap succesfully swap credit, creditInput in context will be reset. Thus, credit display input will also be reset */
  useEffect(() => {
    if (creditInput === '') {
      setCreditDisplayInput('')
    }
  }, [creditInput])

  useEffect(() => {
    return () => {
      if (!account) return
      /** reset recipient address after close modal */
      setRecipientAddress(account)
    }
  }, [account, setRecipientAddress])

  const handleCreditSettingBtnOnClick = () => {
    openModal({ currentModalId: ModalId.SWAP_CREDIT_SETTING })
  }

  const handleSwapCreditModalOnClose = () => {
    /** Clear input when user close the swap credit modal */
    resetAllInputAmount()
    closeModal()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleSwapCreditModalOnClose}
        title="SWAP CREDIT"
        topRightIcon={<Image layout="fixed" width={14} height={14} src={SettingIcon} />}
        onTopRightIconOnClick={handleCreditSettingBtnOnClick}
      >
        <div className="flex flex-col gap-[10px]">
          <div className="flex flex-col gap-[16px] rounded-[8px] bg-wombatIdle p-[16px]">
            <div className="flex flex-row items-center justify-center max-md:flex-col">
              <p className="flex flex-row gap-1 rounded-[4px] bg-white p-2 text-sm">
                <Image src={chainInfos[chainId].icon} width={22} height={22} />
                <span>{getCommifiedFormat(creditBalance)} CR</span>
              </p>
              <div className="mx-[10px] flex items-center max-md:rotate-90">
                <Image src={arrowRight} height={20} width={20} />
              </div>
              <p className="inline-block w-[230px] rounded-[4px] bg-white [&>div]:rounded-[4px] [&>div]:border-inherit">
                <ChainSelectionDropdown
                  chainId={selectedToChainId}
                  onSelectionChange={handleToChainIdChange}
                  width="100%"
                  enablePortal={false}
                  className="[&>div]:border-inherit"
                  connectedChainId={chainId}
                  showCrossChainLabel
                  showCrossChainAvailableOnly
                />
              </p>
            </div>
            {/** receipent */}
            {account && isRecipientShown && (
              <SwapRecipientBox
                recipientAddress={recipientAddress}
                setRecipientAddress={setRecipientAddress}
                hasInvalidAddress={hasInvalidRecipientAddress}
                onHasInvalidAddressChange={(isInvalid) => setHasInvalidRecipientAddress(isInvalid)}
              />
            )}
            {/** token selector */}
            <div className="flex flex-row items-center justify-between gap-3">
              <DropdownInput
                placeholderProps={{
                  text: selectedToToken ? (
                    <div className="flex flex-row items-center gap-2">
                      <TokenImage tokenSymbol={selectedToToken.symbol} width="32" height="32" />
                      {selectedToToken.displaySymbol}
                    </div>
                  ) : (
                    'No Token Found'
                  ),
                }}
                width="140px"
                optionsMaxHeight="200px"
                variant="transparent"
                className="[&_div]:text-[14px] [&_div]:font-semibold"
              >
                {availableTokenList.map((token) => {
                  return (
                    <DropdownInput.Option
                      key={token.symbol}
                      value={token.symbol}
                      onClick={(tokenSymbol) => {
                        setSelectedToTokenSymbol(tokenSymbol || null)
                      }}
                    >
                      <TokenImage tokenSymbol={token.symbol} /> {token.displaySymbol}
                    </DropdownInput.Option>
                  )
                })}
              </DropdownInput>
              <div className="[&_div]:text-[16px] [&_input]:text-center">
                <Input
                  value={creditDisplayInput}
                  onChange={handleCreditInputChange}
                  variant="whiteBg"
                  unit="Credit"
                  onMaxBtnClick={() => {
                    handleCreditInputChange(creditBalance)
                  }}
                  validation={{
                    isValid: !isInputExceedingCreditBalance,
                    warningMessage: 'Amount exceeds credit balance',
                  }}
                />
              </div>
            </div>
          </div>
          {/** Swap info  */}
          <div className="p-2 outline outline-2 outline-wombatPurple2">
            {/* Minimum Recevied */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-1 text-wombatPurple">Minimum Received</div>
                <Tooltip>
                  Your transaction will fail if you&apos;re unable to receive at least this amount.
                  Adjust it in the setting&apos;s slippage tolerance option.
                </Tooltip>
              </div>
              <div className="text-wombatPurple">
                <TooltipNum amount={minimumReceive} />
                &nbsp;{selectedToToken?.displaySymbol}
              </div>
            </div>
            {isCrossChainCreditSwap && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="mr-1 text-wombatPurple">Gas on destination chain</div>
                  <Tooltip>{SWAP_GAS_ON_DEST_CHAIN_TOOLTIP_MSG}</Tooltip>
                </div>
                <div className="text-wombatPurple">
                  <TooltipNum amount={receiverValue} fixedDigits={3} />
                  &nbsp;
                  {
                    TOKENS[selectedToChainId][NATIVE_WRAPPED_TOKEN_IN_CHAIN[selectedToChainId]]
                      ?.displaySymbol as TokenSymbol
                  }
                </div>
              </div>
            )}
            {/* Fee */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-1 text-wombatPurple">Fee</div>
              </div>
              <span className="text-wombatPurple">
                <TooltipNum fixedDigits={6} amount={fee} millified />
                &nbsp;
                {selectedToToken?.displaySymbol}
              </span>
            </div>
            {/* Est. Delivery Fee */}
            {/* no delivery fee for same chain credit swap */}
            {isCrossChainCreditSwap && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="mr-1 text-wombatPurple">Estimate Delivery Fee</div>
                </div>
                <span className="text-wombatPurple">
                  <TooltipNum fixedDigits={6} amount={estDeliveryFee} millified />
                  &nbsp;
                  {TOKENS[chainId][NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]]?.displaySymbol}
                </span>
              </div>
            )}
            {/* Price Impact */}
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="mr-1 text-wombatPurple">Price Impact</div>
                <Tooltip>The difference between the market price and the ideal price.</Tooltip>
              </div>
              <span
                className={
                  priceImpact && Number(priceImpact) > 0.01 ? 'text-red-500' : 'text-wombatPurple'
                }
              >
                <FormattedNumber
                  amount={Number(priceImpact) ?? 0}
                  threshold={0.001}
                  showColor={false}
                />
              </span>
            </div>
          </div>
          <Button
            disabled={!isReadyToSwapCreditForTokens}
            onClick={
              !isCrossChainCreditSwap
                ? handleSameChainSwapCreditForToken
                : handleCrossChainSwapCreditForToken
            }
            isLoading={isLoading}
          >
            <p className="text-sm font-semibold">SWAP</p>
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default React.memo(SwapCreditModal)

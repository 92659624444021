import { ConnectArgs, FetchSignerResult, Signer } from '@wagmi/core'
import * as _tanstack_react_query from '@tanstack/react-query'
import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useAccount,
  useConnect,
  useDisconnect,
  useSigner,
  useSwitchNetwork,
  useNetwork,
  Connector,
} from 'wagmi'
import { BUILD_TYPE } from '../constants/common'
import { ChainInfo, chainInfos, getSupportedChainIdByNetwork } from '../constants/web3/chains'
import {
  DEFAULT_CHAIN_ID,
  SUPPORTED_CHAIN_IDS,
  SupportedChainId,
} from '../constants/web3/supportedChainId'
import { SUPPORTED_WALLETS, WalletId } from '../constants/web3/wallets'
import { ethers } from 'ethers'
import { MulticallProvider } from '../utils/multicallProvider'
import { HexString } from '../interfaces/contract'
import useLocalStorage from '../hooks/useLocalStorage'
import { useAppDispatch } from '../store/hooks'
import { addErrorToast } from '../store/Toast/actions'

type ContextType = {
  chainId: SupportedChainId
  chainInfo: ChainInfo
  isSupported: boolean
  connect: (args?: Partial<ConnectArgs> | undefined) => void
  disconnect: _tanstack_react_query.UseMutateFunction<void, Error, void, unknown>
  account: null | HexString
  setMockAccount: React.Dispatch<React.SetStateAction<HexString | null>>
  switchNetwork: (chainId: SupportedChainId) => Promise<boolean | undefined>
  activeWalletId?: WalletId
  signer: FetchSignerResult<Signer> | undefined
  readonlyProvider: ethers.providers.JsonRpcProvider
  multicallProvider: MulticallProvider
  connector: Connector | undefined
}
const Web3Context = createContext<ContextType>({} as unknown as ContextType)
Web3Context.displayName = 'Web3Context'

interface Props {
  children: React.ReactNode
}

export const useWeb3 = () => {
  return useContext(Web3Context)
}
const initMulticallProvider = new MulticallProvider(DEFAULT_CHAIN_ID)
const initReadOnlyProvider = initMulticallProvider.getReadOnlyProvider()

function Web3Provider({ children }: Props): ReactElement {
  // view as another account for development purpose
  const [mockAccount, setMockAccount] = useState<HexString | null>(null)
  const { address: account, connector } = useAccount()
  const selectedWallet = useMemo(
    () => Object.values(SUPPORTED_WALLETS).find((wallet) => wallet.connector === connector),
    [connector]
  )
  const { chain: chainWithLogin } = useNetwork()

  // if user has not logged in, use the chainId from the local storage or default chainId
  const [cachedChainId, setCachedChainId] = useLocalStorage<SupportedChainId>({
    key: 'PREVIOUS_CHAIN_ID',
    initialValue: DEFAULT_CHAIN_ID,
  })

  const dispatch = useAppDispatch()
  const { switchNetworkAsync } = useSwitchNetwork()
  const { connect } = useConnect({
    onError: (error) => {
      dispatch(addErrorToast({ message: error.message, title: 'Connect Wallet Failed' }))
    },
  })
  const { disconnect } = useDisconnect({
    onError: (error) => {
      dispatch(addErrorToast({ message: error.message, title: 'warning' }))
    },
  })
  const { data: signer } = useSigner()
  const [readonlyProvider, setReadonlyProvider] =
    useState<ethers.providers.JsonRpcProvider>(initReadOnlyProvider)
  const [multicallProvider, setMulticallProvider] =
    useState<MulticallProvider>(initMulticallProvider)
  const isSwitchNetworkAsyncReady = !!switchNetworkAsync
  const switchNetwork = useCallback(
    async (chainId: SupportedChainId) => {
      if (switchNetworkAsync) {
        try {
          await switchNetworkAsync(chainInfos[chainId].id)
          return true
        } catch (error) {
          dispatch(
            addErrorToast({
              message: `Please switch network in your ${
                selectedWallet ? selectedWallet.name : ''
              } device.`,
              title: 'Switch Network Failed',
            })
          )
          console.log(error)
        }
      }
      setCachedChainId(chainId)
    },
    [dispatch, selectedWallet, setCachedChainId, switchNetworkAsync]
  )
  const { chainInfo, isSupported, chainId } = useMemo(() => {
    // if user has not logged in, use the chainId from the local storage or default chainId
    let isSupported = SUPPORTED_CHAIN_IDS.includes(cachedChainId)
    // if user has logged in, use the chainId from the RPC
    if (chainWithLogin) {
      const typedChainId = chainWithLogin.id as SupportedChainId
      isSupported = SUPPORTED_CHAIN_IDS.includes(typedChainId)
      if (isSupported) {
        return {
          chainId: typedChainId,
          chainInfo: chainInfos[typedChainId],
          isSupported: isSupported,
        }
      }
    }
    return {
      chainId: cachedChainId,
      chainInfo: chainInfos[cachedChainId],
      isSupported: isSupported,
    }
  }, [chainWithLogin, cachedChainId])
  /**
   * ask users to switch network when they enter our Dapp via the URL with "chain" query string
   */
  useEffect(() => {
    if (typeof window === 'undefined') return
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)
    const queryChainId = getSupportedChainIdByNetwork(params.get('chain'))
    if (!queryChainId) return
    if (chainId !== queryChainId) {
      switchNetwork(queryChainId)
    }
    // depends on isSwitchNetworkAsyncReady as it may be undefined at the beginning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSwitchNetworkAsyncReady])

  useEffect(() => {
    // if user has logged in and update chain which is supported, update the previous chainId in the local storage
    if (chainWithLogin && SUPPORTED_CHAIN_IDS.includes(chainWithLogin.id)) {
      setCachedChainId(chainWithLogin.id)
    }
  }, [chainWithLogin, setCachedChainId])

  useEffect(() => {
    // wrap multicallProvider and readonlyProvider in useEffect to avoid
    // them being updated and make duplicated calls to the RPC
    console.log('chainId', chainId)
    const provider = new MulticallProvider(chainId)
    setMulticallProvider(provider)
    setReadonlyProvider(provider.getReadOnlyProvider())
  }, [chainId])

  return (
    <Web3Context.Provider
      value={{
        readonlyProvider,
        multicallProvider,
        chainId,
        chainInfo,
        isSupported,
        connect,
        disconnect,
        account:
          (process.env.NEXT_PUBLIC_BUILD_TYPE !== BUILD_TYPE.PROD && mockAccount) ||
          account ||
          null,
        setMockAccount,
        switchNetwork,
        activeWalletId: connector
          ? Object.values(SUPPORTED_WALLETS).find((wallet) => wallet.connector === connector)?.id
          : undefined,
        signer,
        connector,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

export default Web3Provider

import React from 'react'
import { SECONDS_PER_WEEK } from '../constants/common'

export function delay(times: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, times)
  })
}

export function getDeadlineFromNow(secondSince: string | number): number {
  return Math.round(Date.now() / 1000) + Number(secondSince)
}

export function withLineBreaks(text: string): React.ReactElement {
  return (
    <>
      {text.split('\n').map((line, idx) => (
        <p key={idx}>{line}</p>
      ))}
    </>
  )
}

export async function safeFetchJson<T>(apiUrl: string): Promise<T | null> {
  try {
    const res = await fetch(apiUrl)
    try {
      const value = (await res.json()) as T
      if (res.ok) {
        return value
      }
    } catch {
      throw new Error(`response is not json: ${await res.text()}`)
    }
    throw new Error(await res.text())
  } catch (err) {
    console.error(err)
  }
  return null
}

export function getNextEpochStartTime() {
  const firstEpochStartTime = 1673416500
  const now = Math.round(Date.now() / 1000)

  if (now < firstEpochStartTime) {
    return firstEpochStartTime
  }

  const epochCount = (now - firstEpochStartTime) / SECONDS_PER_WEEK
  return firstEpochStartTime + (epochCount + 1) * SECONDS_PER_WEEK
}

import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const AVAX_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0xC9bFC3eFeFe4CF96877009F75a61F5c1937e5d1a',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.MAIN]: {
    name: 'Main Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0x89B88A45E23978b38A14695b63F475d4e4CcaF95',
    isShownInBoosterCalculator: false,
    version: 'volatile_v2',
  },
  [PoolLabels.Agora_Stable_Pool]: {
    name: 'Agora Stable Pool',
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x911a98f54da5355EAba1c8D57933ae5493c4223b',
  },
  [PoolLabels.Liquid_Staking_Pool]: {
    name: 'Liquid Staking Pool',
    filterGroup: PoolFilterGroup.LSD,
    address: '0xc12c0Ced34b115655234E8a4dB87EBc8F6F362d0',
  },
  [PoolLabels.sAVAX]: {
    name: 'sAVAX Pool',
    filterGroup: PoolFilterGroup.LSD,
    address: '0xe3abc29b035874a9f6dcdb06f8f20d9975069d87',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.ggAVAX_AVAX]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0xBbA43749efC1bC29eA434d88ebaf8A97DC7aEB77',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.AXON_STABLE]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x74163B79733AEA2d9C4cED777dc49D591Db739E9',
  },
  [PoolLabels.USDV]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x108c990c93Fa8E3cD88DDb13594D39f09D9B3C02',
  },
}

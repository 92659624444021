import { useEffect } from 'react'
import { ITxData, useCrossChainSwapTxStore } from './useCrossChainSwapTx'
import { useWeb3 } from '../../context/Web3Context'
import { chainInfos } from '../../constants/web3/chains'
import {
  IRelayStatus,
  getCaseAndMetaData,
  getWormholeStatusEndPoint,
} from '../../utils/crossChainSwap'
import { isTestnet } from '../../utils'
/**
 * Listen on Delivery events from relayer contract and update the transaction list accordingly
 */
function useCrossChainSwapEvents() {
  const { chainId, account } = useWeb3()
  const { getPendingTxnList, updateSingleCrossChainSwapTxn, txList } = useCrossChainSwapTxStore()

  useEffect(() => {
    const pendingTxnList = account ? getPendingTxnList(chainId, account) : []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventHandler = async (txnData: ITxData, result: IRelayStatus) => {
      const sequence = result.id.split('/')[2]
      const txnHash = result.data.toTxHash

      if (txnData.sequence === sequence) {
        // When we receive Delivery event from the target relayer contract, it means the transaction successfully arrives destination chain (status: redeemed).
        // A user either receives tokens or credits
        const updatedMetaData = await getCaseAndMetaData(
          { data: { toTxHash: txnHash }, status: 'redeemed' },
          txnData.toChainId
        )

        const newTxnData = {
          ...txnData,
          ...updatedMetaData,
        } as ITxData

        updateSingleCrossChainSwapTxn(txnData.fromChainId, txnData.sequence, newTxnData)
      }
    }

    // const targetRelayerContracts: Contract[] = []
    /**
     * Add event listeners to each pending record
     */
    pendingTxnList.forEach(async (txnData) => {
      const targetChainId = txnData.toChainId
      const targetChainInfo = chainInfos[targetChainId]
      const targetRelayerAddress = targetChainInfo.wormholeConfig?.relayerAddress
      const targetAdaptorAddress = targetChainInfo.wormholeConfig?.adaptorAddress
      const wormholeSourceChainId = chainInfos[chainId].wormholeConfig?.chainId
      const emitterAddress = chainInfos[chainId].wormholeConfig?.relayerAddress
      if (targetRelayerAddress && targetAdaptorAddress && wormholeSourceChainId && emitterAddress) {
        // get a provider of target chain
        // const targetPublicClient = getProvider({
        //   chainId: targetChainId,
        // })
        // const targetRelayerContract = new Contract(
        //   targetRelayerAddress,
        //   relayerContractAbi,
        //   targetPublicClient
        // )
        // const eventFilter = targetRelayerContract.filters.Delivery(
        //   targetAdaptorAddress,
        //   wormholeSourceChainId,
        //   txnData.sequence
        // )

        // targetRelayerContract.once(eventFilter, (...parameters) => {
        //   console.log('eventHandler')
        //   eventHandler(txnData, ...parameters)
        // })
        const endpoint = getWormholeStatusEndPoint(
          wormholeSourceChainId,
          emitterAddress,
          txnData.sequence,
          isTestnet(chainId)
        )
        const result = await (await fetch(endpoint)).json()
        if (!result.error) {
          eventHandler(txnData, result)
        }
        // targetRelayerContracts.push(targetRelayerContract)
      }
    })
    return () => {
      // clean up listeners
      // targetRelayerContracts.forEach((contract) => {
      //   contract.removeAllListeners('Delivery')
      // })
    }
    // depend on txList to get the most updated pending txn list
  }, [account, chainId, getPendingTxnList, updateSingleCrossChainSwapTxn, txList])
}

export default useCrossChainSwapEvents

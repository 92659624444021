import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import React from 'react'
import { ASSETS } from '../../../constants/contract/asset'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { POOLS } from '../../../constants/contract/pool'
import { useModalContext } from '../../../context/ModalContext'
import { useWeb3 } from '../../../context/Web3Context'

import { useVotingData } from '../../../context/VotingDataContext'
import useSnapshot from '../../../hooks/useSnapshot'
import WaitingModal from '../../Modal/WaitingModal'
import ModalInfoBox from '../../ModalInfoBox'

type Props = {
  isOpen: boolean
}

function ClaimBribeWaitForConfirmationModal({ isOpen }: Props) {
  const {
    actions: { closeModal },
  } = useModalContext()
  const {
    user: { earnedBribeOfEachAsset },
  } = useSnapshot(useVotingData())
  const { chainId } = useWeb3()

  const claimableRewards = Object.entries(earnedBribeOfEachAsset).map(
    ([poolSymbol, bribeRewardsOfEachAsset]) => {
      const transformedBribeRewardsOfEachAsset = Object.entries(bribeRewardsOfEachAsset).map(
        ([assetTokenSymbol, bribeRewards]) => ({
          poolSymbol: poolSymbol as PoolLabels,
          assetTokenSymbol: assetTokenSymbol as TokenSymbol,
          bribeRewards,
        })
      )
      return {
        poolSymbol: poolSymbol as PoolLabels,
        bribeRewardsOfEachAsset: transformedBribeRewardsOfEachAsset,
      }
    }
  )
  return (
    <WaitingModal isOpen={isOpen} isDisplayGifFlyToTheMoon={false} onClose={closeModal}>
      <ModalInfoBox title="Claim accumulated reward">
        {claimableRewards.map(({ poolSymbol, bribeRewardsOfEachAsset }) => {
          const pool = POOLS[chainId][poolSymbol]
          return bribeRewardsOfEachAsset.map(({ assetTokenSymbol, bribeRewards }) => {
            const asset = ASSETS[chainId][poolSymbol][assetTokenSymbol]
            const label = `${asset?.displaySymbol} (${pool?.name})`
            return bribeRewards.map(({ tokenSymbol: bribeTokenSymbol, value: reward }) => {
              return (
                <ModalInfoBox.InfoItem
                  key={poolSymbol + assetTokenSymbol + bribeTokenSymbol}
                  leadingLabel={label}
                  trailingLabel={`${getCommifiedFormat(reward, 2)} ${bribeTokenSymbol}`}
                />
              )
            })
          })
        })}
      </ModalInfoBox>
    </WaitingModal>
  )
}

export default ClaimBribeWaitForConfirmationModal

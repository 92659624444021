import clsx from 'clsx'
import Image from 'next/image'
import React from 'react'
import styles from './Table.module.scss'
import arrowDown from '../../public/assets/icons/arrow-down.svg'
import arrowUpDown from '../../public/assets/icons/arrow-up-down.svg'
import arrowUp from '../../public/assets/icons/arrow-up-fill.svg'
import { SortingState } from '../../hooks/table/useTable'
import ScrollableBox from '../ScrollableBox'
type Props = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  width?: string
  maxWidth?: string
  height?: string
  maxHeight?: string
  scrollDirection?: 'horizontal' | 'vertical' | 'both'
  stickyLastColumn?: boolean
  enableRowGap?: boolean
}

function Table({
  children,
  className,
  width,
  height,
  maxWidth,
  maxHeight,
  style,
  scrollDirection = 'both',
  stickyLastColumn,
  enableRowGap,
}: Props) {
  const defaultStyle: React.CSSProperties = {
    width: width ? width : '100%',
    height: height ? height : 'unset',
    maxWidth: maxWidth ? maxWidth : 'unset',
    maxHeight: maxHeight ? maxHeight : 'unset',
  }
  return (
    <ScrollableBox
      className={className}
      style={{ ...defaultStyle, ...style }}
      {...(scrollDirection && { scrollDirection })}
    >
      <table
        className={clsx(
          styles.Table,
          stickyLastColumn && styles['Table--sticky-last-column'],
          enableRowGap && styles['Table--separate-row']
        )}
      >
        {children}
      </table>
    </ScrollableBox>
  )
}

export default Table

export type RowProps = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  disableHoverEffect?: boolean
}
function Row({ children, className, style, disableHoverEffect }: RowProps) {
  return (
    <tr
      className={clsx(
        !disableHoverEffect && 'hover:bg-wombatYellow3 group-hover/table-head:bg-inherit',
        styles.Table__Row,
        className
      )}
      style={style}
    >
      {children}
    </tr>
  )
}

type HeadProps = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  sticky?: boolean
  disableBg?: boolean
}

function Head({ children, className, style, sticky, disableBg }: HeadProps) {
  return (
    <thead
      className={clsx(
        sticky && styles['Table__Head--sticky'],
        'group/table-head',
        disableBg && styles['Table__Head--no-bg'],
        className
      )}
      style={style}
    >
      {children}
    </thead>
  )
}

type HeadDataProps = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
  columnIndex: number
  sortingState?: SortingState
  enableSortingIcon?: boolean
}
function HeadData({
  children,
  className,
  style,
  onClick,
  columnIndex,
  sortingState,
  enableSortingIcon,
}: HeadDataProps) {
  return (
    <th
      className={clsx(
        'Table__HeadData bg-wombatBrownAlpha p-[8px] font-Work text-base font-semibold ',
        enableSortingIcon && 'cursor-pointer select-none',
        className
      )}
      style={style}
      onClick={onClick}
    >
      <div className="flex flex-row items-center justify-center gap-1" style={style}>
        {enableSortingIcon && (
          <div className="w-[7px]">
            <Image
              height="7px"
              width="7px"
              layout="fixed"
              src={
                (sortingState?.isSorted &&
                  sortingState?.columnIndex === columnIndex &&
                  (sortingState.ascending ? arrowUp : arrowDown)) ||
                arrowUpDown
              }
              alt="arrow"
            />
          </div>
        )}
        {children}
      </div>
    </th>
  )
}

type DataProps = {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}
function Data({ children, style }: DataProps) {
  return (
    <td
      className="Table__Data border-b-[1px] border-[#B7A38E] p-[8px] text-center font-Work text-sm font-normal first-of-type:pl-[16px] last-of-type:pr-[16px]"
      style={style}
    >
      {children}
    </td>
  )
}

interface BodyProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

function Body({ children, className, style }: BodyProps) {
  return (
    <tbody className={className} style={style}>
      {children}
    </tbody>
  )
}

Table.Row = React.memo(Row)
Table.Head = React.memo(Head)
Table.HeadData = React.memo(HeadData)
Table.Data = React.memo(Data)
Table.Body = React.memo(Body)

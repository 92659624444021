import { createAsyncThunk } from '@reduxjs/toolkit'
import { MasterWombatState } from '../types'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { CallbacksType, executeCallBacks } from '../../../utils/executeCallBacks'
import { PoolLabelsTokenSymbolsGenericType } from '../../../interfaces/common'
import { ASSETS } from '../../../constants/contract/asset'
import { fetchPromise } from '../../../utils/common'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'

const fetchInterestBearingApr = createAsyncThunk<
  Pick<MasterWombatState, 'interestBearingAprs'>,
  {
    chainId: SupportedChainId
  }
>('masterWombat/fetchInterestBearingApr', async ({ chainId }) => {
  const promises: Promise<{ value: number }>[] = []
  const callbacks: CallbacksType = []
  let data: PoolLabelsTokenSymbolsGenericType<string> = {}
  Object.entries(ASSETS[chainId]).forEach(([poolLabel, assetsInPool]) => {
    Object.entries(assetsInPool).forEach(([assetSymbol, asset]) => {
      if (asset.interestBearingAprEndpoint) {
        promises.push(fetchPromise<{ value: number }>(asset.interestBearingAprEndpoint))
        callbacks.push(({ value }: { value: number }) => {
          data = {
            ...data,
            [poolLabel]: {
              ...data[poolLabel as PoolLabels],
              [assetSymbol]: (value / 100).toString(),
            },
          }
        })
      }
    })
  })
  const values = await Promise.all(promises)
  executeCallBacks(values, callbacks)
  return { interestBearingAprs: data }
})

export default fetchInterestBearingApr

import React from 'react'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import TokenImage from '../../TokenImage'
import clsx from 'clsx'

type Props = {
  tokenSymbol: TokenSymbol
  label: React.ReactNode
  sublabel?: React.ReactNode
  center?: boolean
}

function TokenDisplay({ tokenSymbol, label, sublabel, center }: Props) {
  return (
    <div className={clsx('flex flex-row items-center gap-2 text-left', center && 'justify-center')}>
      <TokenImage tokenSymbol={tokenSymbol} width="26px" height="26px" />
      <div className="flex flex-col items-start text-sm leading-none">
        <p className="font-medium">{label}</p>
        {sublabel && <p className="text-xs font-normal leading-none">{sublabel}</p>}
      </div>
    </div>
  )
}

export default TokenDisplay

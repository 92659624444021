import React from 'react'
import styles from './SwapStep.module.scss'
import { TokenApprovalState } from '../../hooks/useApproval'

interface SwapStep {
  approvalState: TokenApprovalState
}

const SwapStep = ({ approvalState }: SwapStep) => {
  return (
    <div className="mt-6 flex w-full justify-center gap-1">
      <SwapStepCircle number={1} isActive={approvalState === TokenApprovalState.NOT_APPROVED} />
      <div className={styles['SwapStep']} />
      <SwapStepCircle number={2} isActive={approvalState === TokenApprovalState.APPROVED} />
    </div>
  )
}

interface SwapStepCircle {
  number: number
  isActive: boolean
}

export const SwapStepCircle = ({ number, isActive }: SwapStepCircle) => {
  return (
    <div
      className={`flex h-[32px] w-[32px] rounded-full font-Jamjuree ${
        isActive ? 'bg-wombatPurple1 text-white' : 'bg-wombatPurple4 text-wombatPurple2'
      }`}
    >
      <div className="flex w-full items-center justify-center">{number}</div>
    </div>
  )
}

export default SwapStep

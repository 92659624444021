import { SupportedChainId } from '../../web3/supportedChainId'
import { PoolLabels } from '../pool/PoolLabels'
import { SwapGroupSymbol, TokenSymbol, TokenSymbols } from './TokenSymbols'
import { Contract } from '../Contract'
import { HexString } from '../../../interfaces/contract'
import { ERC20_ABI } from '../abis/erc20'

export enum UniswapQuoterAddress {
  v3 = '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
}
export enum FetchPriceMethod {
  PYTH = 'PYTH',
  WOMBAT = 'WOMBAT',
  BNBEXCHANGERATE = 'BNBEXCHANGERATE',
  DEX = 'DEX',
  HARDCODE = 'HARDCODE',
  UNISWAPV3 = 'UNISWAPV3',
  COINGECKO = 'COINGECKO',
}
interface PriceFetchingConfigType {
  method: FetchPriceMethod
  payload: unknown
}
interface PythPriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.PYTH
  payload: { pythPriceFeedId: string }
}
interface WombatPriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.WOMBAT
  payload: { toTokenSymbol: TokenSymbol; poolLabel: PoolLabels }
}
interface BnbExchangeRatePriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.BNBEXCHANGERATE
}
interface DexPriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.DEX
  payload: {
    // toTokenSymbol need to be fetched from PYTH / early fetched from DEX
    toToken: {
      symbol: TokenSymbols.WBNB | TokenSymbols.BUSD | TokenSymbols.USDC
      reserveIndex: '0' | '1'
    }
    pairAddress: HexString
  }
}
interface HardcodePriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.HARDCODE
  payload: {
    tokenSymbol?: TokenSymbol
    value?: string
  }
}

interface UniswapV3PriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.UNISWAPV3
  payload: {
    readonly poolAddress: HexString
    readonly quoterAddress: UniswapQuoterAddress
  }
}
interface CoingeckoPriceFetchingConfig extends PriceFetchingConfigType {
  method: FetchPriceMethod.COINGECKO
  payload: {
    readonly id: string
  }
}
type FetchPriceDataType =
  | PythPriceFetchingConfig
  | WombatPriceFetchingConfig
  | BnbExchangeRatePriceFetchingConfig
  | DexPriceFetchingConfig
  | HardcodePriceFetchingConfig
  | UniswapV3PriceFetchingConfig
  | CoingeckoPriceFetchingConfig

// Unlike other contracts, each Token Contract correspond to one chain only
export class Token extends Contract<typeof ERC20_ABI> {
  readonly decimals: number
  readonly name: string
  readonly symbol: TokenSymbol
  readonly fetchPriceData: FetchPriceDataType
  readonly displaySymbol: string
  readonly swapGroupSymbol: SwapGroupSymbol
  readonly isCrossChainAvailable: boolean

  constructor(
    symbol: TokenSymbol,
    name: string,
    decimals: number,
    chainId: SupportedChainId,
    address: HexString,
    fetchPriceData: FetchPriceDataType,
    swapGroupSymbol: SwapGroupSymbol,
    displaySymbol: string = symbol,
    isCrossChainAvailable?: boolean
  ) {
    super({ address, abi: ERC20_ABI, chainId })
    this.fetchPriceData = fetchPriceData
    this.symbol = symbol
    this.decimals = decimals
    this.name = name
    this.swapGroupSymbol = swapGroupSymbol
    this.displaySymbol = displaySymbol
    this.isCrossChainAvailable = !!isCrossChainAvailable
  }
}

import { BigNumber, ContractTransaction, utils } from 'ethers'
import { useState } from 'react'
import { TOKENS } from '../../../constants/contract'
import { Asset } from '../../../constants/contract/asset/Asset'
import { Pool } from '../../../constants/contract/pool/Pool'
// import { useMigrationDetectionContext } from '../../../context/MigrationDetectionContext'
import { useWeb3 } from '../../../context/Web3Context'
import useHandleUnStake, { HandleUnStakeState } from '../../../hooks/useHandleUnstake'
import { formatNumberUSLocale } from '../../../utils/numberFormat'
import WaitingModal from '../../Modal/WaitingModal'
import { POOL_STATE } from '../../PoolPage'
import TokenImage from '../../TokenImage'
import TransactionFailedModal from '../../TransactionFailedModal'
import SubmittedWithdraw from '../../TransactionSubmittedModal'
import WButton, { Variant } from '../../WButton'
import { MasterWombatId } from '../../../constants/contract/masterWombat'
import { useMw2Data } from '../../../store/MigrationDetection/hooks'
import { useAppDispatch } from '../../../store/hooks'
import updateAllDelistedData from '../../../store/MigrationDetection/thunks/updateAllDelistedData'

interface Props {
  pool: Pool
  asset: Asset
  redirect: (value: POOL_STATE | null) => void
}

export default function UnstakeMW2({ pool, asset, redirect }: Props) {
  const [isDisplayTxnModal, setIsDisplayTxnModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  // const { mw2Data, updateAllDelistedData } = useMigrationDetectionContext()
  const mw2Data = useMw2Data()
  const lpAmountBN = utils.parseUnits(
    mw2Data[pool.label]?.[asset.symbol]?.lpAmountInTermsOfLp.staked || '0',
    asset.decimals
  )
  const { chainId, account } = useWeb3()
  const token = TOKENS[chainId][asset.symbol]
  const { tryHandleUnStake, unStakeState, txnHash, isDisplayGifFlyToTheMoon } = useHandleUnStake(
    asset,
    lpAmountBN,
    (txn: ContractTransaction) => {
      txn.wait().then(() => {
        dispatch(
          updateAllDelistedData({
            chainId,
            account,
          })
        )
      })
    },
    formatNumberUSLocale(utils.formatEther(lpAmountBN ?? 0)) + ' ' + asset.symbol,
    redirect,
    MasterWombatId.MW2,
    BigNumber.from(mw2Data[pool.label]?.[asset.symbol]?.pid)
  )
  const button = () => {
    if (!lpAmountBN || lpAmountBN?.eq(0)) {
      return (
        <>
          <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
            UNSTAKE
          </WButton>
        </>
      )
    } else {
      return (
        <WButton
          onClick={async () => {
            setIsDisplayTxnModal(true)
            await tryHandleUnStake(() => {
              return
            })
          }}
          isLoading={
            unStakeState === HandleUnStakeState.LOADING ||
            unStakeState === HandleUnStakeState.SUBMITED
          }
          variant={Variant.GRADIENT}
          width="w-full"
          className={`unstake-${asset.symbol}-unstake`}
        >
          UNSTAKE
        </WButton>
      )
    }
  }

  const transactionModal = () => {
    switch (unStakeState) {
      case HandleUnStakeState.FAILED:
        return <TransactionFailedModal isOpen onClose={() => setIsDisplayTxnModal(false)} />
      case HandleUnStakeState.SUBMITED:
        return (
          token && (
            <SubmittedWithdraw
              // isViewMyVeWOM
              hash={txnHash}
              isAddTokenToWallet={false}
              token={token}
              isOpen
              onClose={() => setIsDisplayTxnModal(false)}
              chainId={chainId}
            />
          )
        )
      case HandleUnStakeState.LOADING:
        return (
          <WaitingModal
            textAboveBalance="Unstake Token"
            isDisplayGifFlyToTheMoon={isDisplayGifFlyToTheMoon}
            data={
              (lpAmountBN ? formatNumberUSLocale(utils.formatEther(lpAmountBN)) : '0') +
              ' ' +
              asset.displaySymbol
            }
            isOpen
            onClose={() => setIsDisplayTxnModal(false)}
          />
        )
    }
  }

  return (
    <>
      {asset && (
        <div className="mb-2 w-full rounded-lg bg-wombatCream p-3 font-Work">
          <div className="flex items-center gap-2 pb-2">
            <div className="h-[34px]">
              <TokenImage tokenSymbol={asset.symbol} width="34" height="34" />
            </div>
            <div className="flex w-full items-center justify-between">
              <div className="flex select-none items-center pl-1">
                <div className="flex-row">
                  <div className="font-semibold leading-5">{asset.displaySymbol}</div>
                  <div className="font-Work text-xs leading-3 text-wombatBrown1">{asset.name}</div>
                </div>
              </div>
            </div>
            <div className="flex h-8 w-full  items-center justify-between rounded-md  bg-wombatCream px-2 font-Work font-semibold text-wombatBrown1 ">
              {Number(utils.formatEther(lpAmountBN)).toFixed(6)}
            </div>
          </div>
        </div>
      )}
      {button()}

      {isDisplayTxnModal && transactionModal()}
    </>
  )
}

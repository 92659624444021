import React, { useEffect, useState } from 'react'

type Props = {
  onClickLabel?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onInputChange: (value: string) => void
  onClickMax?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onClickInput?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  label: React.ReactNode
  inputValue: string
  isEditing?: boolean
}

function EditableLabelInput({
  onClickLabel,
  onInputChange,
  onClickMax,
  onClickInput,
  label,
  inputValue,
  isEditing,
}: Props) {
  const [editingLabel, setEditingLabel] = useState(isEditing)

  useEffect(() => {
    setEditingLabel(isEditing)
  }, [isEditing])
  return editingLabel ? (
    <div
      className="flex items-center justify-between rounded-md border-1 border-wombatBrown1 px-2"
      onClick={(e) => {
        onClickInput && onClickInput(e)
      }}
    >
      <input
        type="text"
        inputMode="numeric"
        placeholder="0.00"
        className="h-6 rounded-lg bg-transparent font-Work text-sm text-wombatBrown1 placeholder:text-xs focus:outline-none"
        style={{ width: onClickMax ? '50px' : '100%' }}
        value={inputValue}
        onChange={(e) => {
          onInputChange(e.target.value)
        }}
      />
      {onClickMax && (
        <button
          className="button-hover-opacity px-1 font-Work font-bold text-wombatPurple1"
          onClick={(e) => {
            onClickMax(e)
          }}
        >
          MAX
        </button>
      )}
    </div>
  ) : (
    <div
      style={{
        background: '#E3D6E8',
        padding: '4px 12px',
        width: '70%',
        borderRadius: '4px',
      }}
      onClick={(e) => {
        setEditingLabel(true)
        onClickLabel && onClickLabel(e)
      }}
    >
      {label}
    </div>
  )
}

export default EditableLabelInput

import { createSlice } from '@reduxjs/toolkit'
import getWpPoints from './thunks/getWpPoints'
import { WpPointsState } from './types'

const initialState: WpPointsState = {
  points: {},
  needAuth: false,
  pointsLoading: false,
}

const wpPointsSlice = createSlice({
  name: 'wpPoints',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWpPoints.pending, (state) => {
      state.pointsLoading = true
    })
    builder.addCase(getWpPoints.fulfilled, (state, action) => {
      state.pointsLoading = false
      state.points = {
        ...state.points,
        ...action.payload.points,
      }
      state.needAuth = action.payload.needAuth
    })
    builder.addCase(getWpPoints.rejected, (state) => {
      state.pointsLoading = false
    })
  },
})

export default wpPointsSlice.reducer

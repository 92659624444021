import { IContractConstructor } from '../../interfaces/contract'
import { SupportedChainId } from '../web3/supportedChainId'
import { Contract } from './Contract'
import { BRIBE_FACTORY_ABI } from './abis/bribeFactory'

class BribeFactory extends Contract<typeof BRIBE_FACTORY_ABI> {
  constructor({ chainId, address }: IContractConstructor) {
    super({ chainId, address, abi: BRIBE_FACTORY_ABI })
  }
}

export const BRIBE_FACTORIES: { [id in SupportedChainId]?: BribeFactory } = {
  [SupportedChainId.BSC_MAINNET]: new BribeFactory({
    chainId: SupportedChainId.BSC_MAINNET,
    address: '0xF53811d69E1581425a28317eE773A82E6D4dAbDB',
  }),
  [SupportedChainId.BSC_TESTNET]: new BribeFactory({
    chainId: SupportedChainId.BSC_TESTNET,
    address: '0x8cBdb9558d122f5c56F9E2D809f3e3020c981A22',
  }),
  [SupportedChainId.ARBITRUM_ONE]: new BribeFactory({
    chainId: SupportedChainId.ARBITRUM_ONE,
    address: '0x97E4F52531C9d8E984e4D7935410E576e791D81f',
  }),
  [SupportedChainId.ETHEREUM_MAINNET]: new BribeFactory({
    chainId: SupportedChainId.ETHEREUM_MAINNET,
    address: '0xCd69ca1e4dA21F65896A8d2e4ea625FF5B19E8Fe',
  }),
  [SupportedChainId.FUJI]: new BribeFactory({
    chainId: SupportedChainId.FUJI,
    address: '0x11c5361A9565cd64dfCe0cbF0E6304A19ee7D905',
  }),
  [SupportedChainId.AVAX]: new BribeFactory({
    chainId: SupportedChainId.AVAX,
    address: '0x8240aAdc2784c881F22a4F2425Dfe40A2bF6AC24',
  }),
  [SupportedChainId.BASE]: new BribeFactory({
    chainId: SupportedChainId.BASE,
    address: '0x640e7Ecd9346AA7190EFeb47Be24742086f78F47',
  }),
  [SupportedChainId.OP_MAINNET]: new BribeFactory({
    chainId: SupportedChainId.OP_MAINNET,
    address: '0x160eE9Edb41d241fCAf4ea8fEdb64Aa9a8511a97',
  }),
  [SupportedChainId.SCROLL_MAINNET]: new BribeFactory({
    chainId: SupportedChainId.SCROLL_MAINNET,
    address: '0xD6eE97A2621b2f9E6CE1bD36844945822f5cdDfa',
  }),
}

import React from 'react'
import styles from './index.module.scss'
import { BridgeTransfer } from './BridgeTransfer/BridgeTransfer'
import { BridgeRedeem } from './BridgeRedeem/BridgeRedeem'
import BridgeTransferProvider from '../../context/BridgeTransferContext'
import useBreakpoints from '../../hooks/useBreakpoints'
import BridgeRedeemProvider from '../../context/BridgeRedeemContext'

function BridgePageContainer() {
  const { isMd } = useBreakpoints()
  return (
    <div className={`mx-auto max-w-[1088px] p-0 font-Work max-md:mx-4 ${isMd ? 'mb-20' : ''}`}>
      <div className="mb-[24px] flex w-[100%] flex-row items-center justify-between gap-4 px-[10px] ">
        <div className="w-[100%]">
          <h2 className="font-Jamjuree text-3xl font-bold max-md:text-2xl">$WOM Bridge</h2>
          <p className="mt-[2px] text-sm max-md:hidden">Transfer $WOM across chains.</p>
        </div>
        <div className="flex w-[100%] max-w-[500px] select-none flex-row flex-wrap items-center justify-around gap-y-1 max-md:w-[unset] max-md:flex-col max-md:items-end max-md:gap-0"></div>
      </div>
      <div className={styles.section}>
        <p className={`${isMd ? 'mb-[10px]' : 'mb-[32px]'} text-base font-semibold`}>Transfer</p>
        <BridgeTransferProvider>
          <BridgeTransfer />
        </BridgeTransferProvider>
      </div>
      <div className={styles.section}>
        <p className={`${isMd ? 'mb-[10px]' : 'mb-[32px]'} text-base font-semibold`}>Redeem</p>
        <BridgeRedeemProvider>
          <BridgeRedeem />
        </BridgeRedeemProvider>
      </div>
    </div>
  )
}

export default BridgePageContainer

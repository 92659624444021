import { redeemOnEth } from '@certusone/wormhole-sdk'
import { useWeb3 } from '../../context/Web3Context'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { chainInfos } from '../../constants/web3/chains'
import { useState } from 'react'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../../context/errorMessage'
import { utils } from 'ethers'
import { DELAY_TIME_ANIMATION_GIF } from '../../constants/common'
import { useModalContext } from '../../context/ModalContext'
import { ModalId } from '../../interfaces/modal'
import { useAppDispatch } from '../../store/hooks'
import { addErrorToast } from '../../store/Toast/actions'

interface RedeemProps {
  targetModalId:
    | ModalId.BRIDGE_REDEEM_REDEEM_WAIT_FOR_CONFIRMATION
    | ModalId.BRIDGE_TRANSFER_REDEEM_WAIT_FOR_CONFIRMATION
}

function useRedeem({ targetModalId }: RedeemProps) {
  const { signer, chainId } = useWeb3()
  const dispatch = useAppDispatch()
  const [isRedeeming, setIsRedeeming] = useState(false)
  const {
    actions: { openModal, closeModal },
  } = useModalContext()
  const [isDisplayGifFlyToTheMoon, setIsDisplayGifFlyToTheMoon] = useState(false)
  const [redeemTransaction, setRedeemTransaction] = useState('')

  const handleRedeem = async (targetChainId: SupportedChainId, signedVaa: Uint8Array | null) => {
    try {
      setIsRedeeming(true)
      openModal({
        currentModalId: targetModalId,
      })
      const targetChainInfo = chainInfos[targetChainId]
      if (targetChainId != chainId) {
        /** @todo update messages later */
        throw new Error(`Please switch to ${targetChainInfo.name}.`)
      }
      if (!signedVaa || !signer) {
        throw new Error(`No signed VAA or signer for ${targetChainInfo.name}`)
      }
      const tokenBridgeAddress = targetChainInfo.wormholeConfig?.tokenBridgeAddress
      if (!tokenBridgeAddress)
        throw new Error(`No token bridge address for ${targetChainInfo.name}`)
      const tokenBridgeCheckSumAddress = utils.getAddress(tokenBridgeAddress)
      const receipt = await redeemOnEth(tokenBridgeCheckSumAddress, signer, signedVaa)
      /** @todo update messages later */
      setIsDisplayGifFlyToTheMoon(true)
      setTimeout(() => {
        setIsDisplayGifFlyToTheMoon(false) //reset default value
        if (targetModalId === ModalId.BRIDGE_REDEEM_REDEEM_WAIT_FOR_CONFIRMATION) {
          openModal({ currentModalId: ModalId.BRIDGE_REDEEM_COMPLETED })
        } else {
          closeModal()
        }
      }, DELAY_TIME_ANIMATION_GIF)
      setRedeemTransaction(receipt.transactionHash)
      return receipt.transactionHash
    } catch (err) {
      /** @todo refactor error message handling */
      /** @todo update messages later */
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(err).includes(code)) {
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        console.log(err)
        const error = err as Error
        const errorMessage = error.message || 'Failed to redeem.'
        dispatch(
          addErrorToast({
            message: errorMessage,
          })
        )
      }
      closeModal()
    } finally {
      setIsRedeeming(false)
    }
    closeModal()
  }

  return { handleRedeem, isRedeeming, isDisplayGifFlyToTheMoon, redeemTransaction }
}

export default useRedeem

import { useCallback, useState } from 'react'
import { TOKENS } from '../../constants/contract'
import { ROUTERS } from '../../constants/contract/router'
import { useContract } from 'wagmi'
import { useWeb3 } from '../../context/Web3Context'
import { useSwapContext } from '../../context/SwapContext'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../constants/contract/token'
import { useUserPreference } from '../../context/userPreferenceContext'
import { getDeadlineFromNow } from '../../utils/utils'
import { DELAY_TIME_ANIMATION_GIF, WAD_DECIMALS } from '../../constants/common'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import { BigNumber, constants, utils } from 'ethers'
import { useTxnReceipt } from '../../context/TxnReceiptProvider'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../../context/errorMessage'
import useAddTokenToMetamask from '../useAddToken'
import { swap } from '../../utils/swap'
import { useSwapInputContext } from '../../context/SwapInputContext'
import { useModalContext } from '../../context/ModalContext'
import { ModalId } from '../../interfaces/modal'
import { strToWad, wadToNative } from '@hailstonelabs/big-number-utils'
import { HexString } from '../../interfaces/contract'
import { isNonEmptyAddress } from '../../utils'
import { useAppDispatch } from '../../store/hooks'
import getSubgraphData from '../../store/Subgraph/thunks/getSubgraphData'
import { useBlock24hAgo } from '../../store/Subgraph/hooks'
import { addSuccessToast, addErrorToast } from '../../store/Toast/actions'

export const useSwap = () => {
  const { chainId, signer, account, chainInfo } = useWeb3()
  const dispatch = useAppDispatch()
  const block24hAgo = useBlock24hAgo()
  const routerWritableContract = useContract({
    ...ROUTERS[chainId]?.get(),
    signerOrProvider: signer,
  })

  const {
    swapPathData,
    minimumReceive,
    fromTokenSymbol,
    toTokenSymbol,
    fromTokenAmount,
    toTokenAmount,
  } = useSwapContext()

  const {
    actions: { openModal, closeModal },
  } = useModalContext()

  const [swapTransactionHash, setSwapTransactionHash] = useState<string>('')
  const { resetAllInputAmount } = useSwapInputContext()

  const { userPreference } = useUserPreference()
  const { refreshTxnReceipt } = useTxnReceipt()

  const fromToken = TOKENS[chainId][fromTokenSymbol]
  const toToken = TOKENS[chainId][toTokenSymbol]

  const { addToken } = useAddTokenToMetamask(toToken)

  const handleSwap = useCallback(
    async (recipient: HexString) => {
      if (!isNonEmptyAddress(recipient)) {
        throw new Error('do not send tokens to empty address!')
      }

      if (!routerWritableContract || !swapPathData) {
        return
      }

      if (!fromToken || !toToken) return

      const { tokenAddresses, poolAddresses } = swapPathData

      const nativeToken =
        fromToken.symbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]
          ? 'fromToken'
          : toToken.symbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]
          ? 'toToken'
          : 'none'

      const deadline = getDeadlineFromNow(userPreference.transactionDeadline)

      const fromTokenAmountBN = fromTokenAmount
        ? utils.parseUnits(fromTokenAmount, TOKENS[chainId][fromTokenSymbol]?.decimals)
        : constants.Zero

      const minimumReceiveWad = strToWad(minimumReceive)
      const minimumReceiveBN = wadToNative(
        minimumReceiveWad,
        TOKENS[chainId][toTokenSymbol]?.decimals ?? WAD_DECIMALS
      )

      try {
        openModal({ currentModalId: ModalId.SWAP_WAIT_FOR_CONFIRMATION })
        const txn = await swap(
          tokenAddresses,
          poolAddresses,
          fromTokenAmountBN,
          minimumReceiveBN,
          recipient,
          BigNumber.from(deadline),
          nativeToken,
          routerWritableContract
        )
        if (txn) {
          openModal({
            currentModalId: ModalId.SWAP_WAIT_FOR_CONFIRMATION,
            payload: { isTxnPending: true },
          })
          setSwapTransactionHash(txn.hash)
          setTimeout(() => {
            openModal({
              currentModalId: ModalId.SWAP_WAIT_FOR_CONFIRMATION,
              payload: { isTxnPending: false },
            })
            openModal({
              currentModalId: ModalId.SWAP_SUBMITTED,
              payload: { transactionHashes: [txn.hash] },
            })
          }, DELAY_TIME_ANIMATION_GIF)
        }
        const receiptTxn = await txn.wait()
        if (receiptTxn) {
          dispatch(
            addSuccessToast({
              message: `+${formatNumberUSLocale(toTokenAmount)} ${toToken.symbol}`,
              title: 'Swap Completed',
              txHash: txn.hash,
              childrenButton: 'Add to Wallet',
              handleClickButton: () => addToken(),
            })
          )
          closeModal()
          refreshTxnReceipt()
          dispatch(
            getSubgraphData({
              chainId,
              block24hAgo,
              chainInfo,
            })
          )
          resetAllInputAmount()
        }
      } catch (error) {
        const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
        if (JSON.stringify(error).includes(code)) {
          closeModal()
          const errorMessage = ErrorMessages[code]
          dispatch(
            addErrorToast({
              message: errorMessage.message,
              title: errorMessage.title,
            })
          )
        } else {
          dispatch(
            addErrorToast({
              message: `${formatNumberUSLocale(toTokenAmount)} ${toToken.symbol}`,
              title: 'Swap Failed',
              txHash: swapTransactionHash,
            })
          )
          const errInfo =
            `@swap\n` +
            `tokenAddresses: ${tokenAddresses.join(', ')}\n` +
            `fromTokenAmount: ${fromTokenAmount.toString()}\n` +
            `toTokenAmount: ${toTokenAmount.toString()}\n` +
            `pool: ${poolAddresses.join(', ')}\n` +
            `signerAddress: ${account}\n` +
            `deadline: ${deadline}\n` +
            `txHash: ${swapTransactionHash}\n`
          console.error(errInfo, error)
          openModal({ currentModalId: ModalId.SWAP_FAILED })
        }
      }
    },
    [
      routerWritableContract,
      swapPathData,
      fromToken,
      toToken,
      chainId,
      userPreference.transactionDeadline,
      fromTokenAmount,
      fromTokenSymbol,
      minimumReceive,
      toTokenSymbol,
      openModal,
      toTokenAmount,
      closeModal,
      refreshTxnReceipt,
      dispatch,
      block24hAgo,
      chainInfo,
      resetAllInputAmount,
      addToken,
      swapTransactionHash,
      account,
    ]
  )

  return {
    handleSwap,
  }
}

import styles from '../PoolPage/pool.module.css'
// import { useMigrationDetectionContext } from '../../context/MigrationDetectionContext'
import { strToWad } from '@hailstonelabs/big-number-utils'
import { useScrollToTop } from '../../hooks/useScroll'
import clsx from 'clsx'
import TokenImage from '../TokenImage'
import { commafyWithDecimals } from '../../utils/commafy'
import WButton, { Variant } from '../WButton'
import { MODAL_STATE, useWithdrawalPageContext } from './WithdrawalPageContext'
import { Asset } from '../../constants/contract/asset/Asset'
import { useMw2Data } from '../../store/MigrationDetection/hooks'

type Props = {
  asset: Asset
}
export default function PoolInfoCardMW2({ asset }: Props) {
  // const { mw2Data } = useMigrationDetectionContext()
  const mw2Data = useMw2Data()
  const assetDisplayTokenSymbol = asset.displaySymbol
  const assetName = asset.name
  const assetTokenSymbol = asset.symbol
  const { scrollToTop } = useScrollToTop()
  const { setModalState, setSelectedAssetTokenSymbol, setSelectedPoolLabel } =
    useWithdrawalPageContext()
  const mw2DataInThisAsset = mw2Data?.[asset.poolLabel]?.[asset.symbol]
  const stakedLpAmount = mw2DataInThisAsset?.lpAmountInTermsOfLp?.staked
  const isStaked = strToWad(stakedLpAmount).gt('0')

  return (
    <div className="mb-2 md:mb-4">
      <div className={`relative rounded-lg shadow-lg`}>
        <div
          className={mw2DataInThisAsset?.isWithdrawalNeeded ? styles.fluidBorderAnimation : ''}
        />
        <div
          className={clsx(
            'relative rounded-t-lg border-2 border-wombatPurple2 md:border-[3px]',
            'select-none py-3.5 px-0 text-black md:py-5 md:pl-4 lg:pl-10',
            isStaked ? 'bg-wombatYellow3' : 'border-wombatPurple2 bg-wombatCream',
            'rounded-lg'
          )}
          id={`pool-${assetTokenSymbol}-expand`}
        >
          {/* mobile */}
          <div className="block md:hidden">
            <div className="flex pb-3.5">
              {/* TokenImage */}
              <div
                className={`flex flex-1 items-center justify-center gap-2 border-r border-wombatPurple3`}
              >
                <div className="flex flex-col items-center">
                  <TokenImage tokenSymbol={assetTokenSymbol} width={'48'} height={'48'} />
                  <div className="flex flex-col md:ml-3">
                    <h2 className="font-Work font-semibold text-black">
                      {assetDisplayTokenSymbol}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex min-h-[70px] border-t border-wombatPurple3 pt-3.5 text-sm">
              {/* My Staked */}
              <div className="my-auto flex flex-1 flex-col border-r border-wombatPurple3 text-center font-Work">
                <p className="mb-1 font-semibold text-wombatBrown1">My Staked</p>
                <div className="relative mx-auto flex w-fit">
                  <p>
                    <span>
                      {isStaked
                        ? commafyWithDecimals(Number(stakedLpAmount)) +
                          ` LP-${assetDisplayTokenSymbol}`
                        : '-'}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex py-3.5">
              <div className="my-auto flex flex-1 justify-between border-r border-wombatPurple3 text-center font-Work">
                <WButton
                  variant={isStaked ? Variant.GRADIENT : Variant.LIGHT_PURPLE}
                  className={`pool-${assetTokenSymbol}-liquidity my-auto`}
                  onClick={() => {
                    setSelectedAssetTokenSymbol(assetTokenSymbol)
                    setSelectedPoolLabel(asset.poolLabel)
                    setModalState(MODAL_STATE.UNSTAKE)
                    scrollToTop()
                  }}
                  disabled={!isStaked}
                  width="w-full px-4 mx-2"
                >
                  UNSTAKE
                </WButton>
              </div>
            </div>
          </div>

          {/* desktop */}
          <div className={clsx('hidden  items-center justify-between md:flex')}>
            <div className="flex w-[130px]">
              <TokenImage tokenSymbol={assetTokenSymbol} width={'32'} height={'32'} />
              {/* token image & name */}
              <div className="ml-2 flex flex-col">
                <h2 className="font-Work font-semibold leading-5">{assetDisplayTokenSymbol}</h2>
                <p className="font-Work text-sm leading-4 text-wombatBrown1">{assetName}</p>
              </div>
            </div>

            {/* My Staked */}
            <div className="text-center font-Work">
              <p className="mb-1 font-semibold text-wombatBrown1">My Staked</p>
              <div className="relative mx-auto flex w-fit">
                <p>
                  <span className={clsx(isStaked ? 'hover-text' : '')}>
                    {isStaked
                      ? commafyWithDecimals(Number(stakedLpAmount)) +
                        ` LP-${assetDisplayTokenSymbol}`
                      : '-'}
                  </span>
                </p>
              </div>
            </div>
            {/* unstake button */}
            <div className="relative flex w-fit flex-row justify-self-center">
              <WButton
                variant={isStaked ? Variant.GRADIENT : Variant.LIGHT_PURPLE}
                className={`pool-${assetTokenSymbol}-liquidity my-auto`}
                onClick={() => {
                  setModalState(MODAL_STATE.UNSTAKE_MW2)
                  setSelectedAssetTokenSymbol(assetTokenSymbol)
                  setSelectedPoolLabel(asset.poolLabel)
                  scrollToTop()
                }}
                disabled={!isStaked}
                width="lg:w-[200px] px-3 mr-2"
              >
                UNSTAKE
              </WButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { useMemo } from 'react'
import { TOKENS } from '../../constants/contract'
import { useSwapContext } from '../../context/SwapContext'
import { useWeb3 } from '../../context/Web3Context'
import { TokenApprovalState } from '../../hooks/useApproval'
import WButton, { Variant } from '../WButton'
import WppBadge from '../WppBadge'

interface ISwapApprovalButton {
  approvalState: TokenApprovalState
  tryApproval: () => Promise<void>
}

export const SwapApprovalButton = ({ approvalState, tryApproval }: ISwapApprovalButton) => {
  const { chainId, isSupported: isOnSupportedChain } = useWeb3()

  const { covRatioBetter } = useSwapContext()

  const approveHandler = async () => {
    const fromToken = TOKENS[chainId]
    if (!fromToken || !tryApproval) return
    await tryApproval()
  }

  const badge = useMemo(() => {
    if (covRatioBetter) {
      return <WppBadge></WppBadge>
    }
    return null
  }, [covRatioBetter])

  if (!isOnSupportedChain) {
    return (
      <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-11" disabled badge={badge}>
        APPROVE
      </WButton>
    )
  } else if (approvalState === TokenApprovalState.NOT_APPROVED) {
    return (
      <WButton
        variant={Variant.GRADIENT}
        width="w-full"
        height="h-11"
        onClick={approveHandler}
        badge={badge}
      >
        APPROVE
      </WButton>
    )
  } else if (approvalState === TokenApprovalState.LOADING) {
    return (
      <WButton variant={Variant.GRADIENT} width="w-full" height="h-11" isLoading badge={badge} />
    )
  } else {
    return (
      <WButton variant={Variant.LIGHT_PURPLE} width="w-full" height="h-11" disabled badge={badge}>
        APPROVED
      </WButton>
    )
  }
}

import Image from 'next/image'
import { useMemo, useRef } from 'react'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import WombatToTheMoonFlyUp from '../../public/assets/animations/ToTheMoon2.gif'
import WombatToTheMoonNoText from '../../public/assets/animations/ToTheMoon_noText.gif'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import Modal from '../Modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  isNewTxn: boolean
  rewards: {
    tokenSymbol: string
    amount: string
  }[]
}

export default function ClaimAllConfirmModal({ isOpen, onClose, isNewTxn, rewards }: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })

  const renderGif = useMemo(() => {
    if (isNewTxn) {
      return (
        <>
          <div className="absolute bottom-0 top-[-88px] h-[400] w-24 overflow-hidden">
            <div className="absolute bottom-0">
              <Image
                src={WombatToTheMoonFlyUp}
                layout="fixed"
                width={105}
                height={525}
                unoptimized
              />
            </div>
          </div>
        </>
      )
    }

    return <Image src={WombatToTheMoonNoText} layout="fixed" width={70} height={70} unoptimized />
  }, [isNewTxn])

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Waiting for Confirmation" isCenterTitle>
      <div className="relative flex flex-col items-center justify-center" style={{ marginTop: 0 }}>
        <span className="text-center font-Work text-xs text-wombatBlue">
          Please confirm the transaction in your wallet
        </span>
        <div className="flex flex-col items-center py-10">
          <div className="text-center text-wombatPurple1 md:px-14">
            <p className="mb-2 font-Work">Claim Reward</p>
            <div className="flex flex-col gap-1 rounded-lg bg-wombatPurple1 py-3 px-5 text-white">
              {rewards.map((reward) => (
                <span key={reward.tokenSymbol}>
                  {formatNumberUSLocale(reward.amount || '0')} {reward.tokenSymbol}
                </span>
              ))}
            </div>
          </div>
          <div
            style={{
              paddingBottom: `${isNewTxn ? '70px' : ''}`,
            }}
            className={`mt-4 flex items-center justify-center`}
          >
            {renderGif}
          </div>
        </div>
      </div>
    </Modal>
  )
}

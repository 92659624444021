import { BigNumber, utils } from 'ethers'
import Image from 'next/image'
import { FC } from 'react'
import { Token } from '../../constants/contract/token/Token'
import WombatToTheMoonFlyUp from '../../public/assets/animations/ToTheMoon2.gif'
import WombatToTheMoonNoText from '../../public/assets/animations/ToTheMoon_noText.gif'
import withdrawGroup from '../../public/assets/icons/withdraw-group.svg'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import Modal from '../Modal'

type TGif = {
  txHash: boolean
}

const Gif = ({ txHash }: TGif) => {
  if (txHash) {
    return (
      <>
        <div className="absolute bottom-0 top-[-104px] h-[400] w-24 overflow-hidden">
          <div className="absolute bottom-0">
            <Image src={WombatToTheMoonFlyUp} layout="fixed" width={105} height={525} unoptimized />
          </div>
        </div>
      </>
    )
  }

  return <Image src={WombatToTheMoonNoText} layout="fixed" width={80} height={80} unoptimized />
}

interface WithdrawConfirmModal {
  isOpen: boolean | null
  onClose: () => void
  txHash: boolean
  tokenAmount: BigNumber | null
  tokenFrom: Token | null
  tokenTo: Token | null
  miniumReceived: number
}

const WithdrawConfirmModal: FC<WithdrawConfirmModal> = (props) => {
  const { isOpen, onClose, txHash, tokenAmount, tokenFrom, tokenTo, miniumReceived } = props

  return (
    <Modal isOpen={!!isOpen} onClose={onClose} title="Waiting for Confirmation" isCenterTitle>
      <div className="relative">
        <div className="py-2 text-center text-sm text-wombatBlue ">
          Please confirm the transaction in your wallet
        </div>

        <div className="flex flex-col justify-center">
          <div>
            <div className="w-full text-center text-wombatPurple1">
              <Image src={withdrawGroup} />
            </div>
            <div className="flex justify-center">
              <div className="rounded-lg bg-wombatPurple1 px-6 py-3">
                <div className="text-center font-Helvetica text-base font-medium text-white">
                  {tokenAmount && formatNumberUSLocale(utils.formatEther(tokenAmount ?? '0'), 2)}
                  &nbsp;{tokenFrom && tokenFrom.displaySymbol}
                </div>
                <div className="text-center font-Work text-xs font-normal text-white">in</div>
                <div className="text-center font-Helvetica text-base font-medium text-white">
                  {formatNumberUSLocale(miniumReceived.toString(), 2)}&nbsp;
                  {tokenTo && tokenTo.displaySymbol}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className={`flex h-36 flex-col items-center justify-center`}>
          <Gif txHash={txHash} />
        </div>
      </div>
    </Modal>
  )
}
export default WithdrawConfirmModal

import React, { useMemo } from 'react'
import Image from 'next/image'
import Table from '../../Table'
import AssetTokenDisplay from '../../display/AssetTokenDisplay'
import useTable, { TableData } from '../../../hooks/table/useTable'
import { useWeb3 } from '../../../context/Web3Context'
import TokenImage from '../../TokenImage'
import Button, { ButtonProps } from '../../Button'
import clsx from 'clsx'
import { useSelfServiceBribePageContext } from '../../../context/SelfServiceBribePageContext'
import { TOKENS } from '../../../constants/contract'
import { timeConverter } from '../../../utils'
import useTableSortingState from '../../../hooks/table/useTableSortingState'
import TopUpModal from '../TopUpModal'
import { useModalContext } from '../../../context/ModalContext'
import { ModalId } from '../../../interfaces/modal'
import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import EditEmissionModal from '../EditEmissionModal'
import ViewOnIcon from '../../../public/assets/icons/blank-page.svg'
import { getExplorerLink } from '../../../utils/getBscScanLink'
import { ASSETS } from '../../../constants/contract/asset'
import { WarningIcon } from '../../images'
import AppTooltip from '../../Tooltip/AppTooltip'
type TableHeadData = {
  style?: React.CSSProperties
  cell: React.ReactNode
  sortByDefault?: boolean
  disableSorting?: boolean
}[]

function AddedBribesTable() {
  const { chainId } = useWeb3()
  const {
    modalState,
    actions: { openModal },
  } = useModalContext()
  const { addedRewardList, isToShowBribeData } = useSelfServiceBribePageContext()
  const label = isToShowBribeData ? 'Bribe' : 'Rewarder'
  const tableHeadData: TableHeadData = (() => {
    return [
      {
        cell: 'Asset',
        style: {
          justifyContent: 'center',
        },
      },
      { cell: 'Weekly Emission Rate' },
      { cell: 'Remaining Surplus' },
      { cell: `${label} Ends` },
      { cell: 'Actions', disableSorting: true },
    ]
  })()
  const tableData: TableData = useMemo(() => {
    return addedRewardList.map((rewardData) => {
      const {
        assetSymbol,
        rewardSymbol,
        poolSymbol,
        remainingSurplus,
        runoutTimestamp,
        weeklyEmissionRate,
      } = rewardData
      const rewardDisplaySymbol = TOKENS[chainId][rewardSymbol]?.displaySymbol
      const hasReachedRunoutTimestamp = runoutTimestamp <= 0
      const runoutDateBefore48Hrs = new Date(runoutTimestamp * 1000)
      runoutDateBefore48Hrs.setDate(runoutDateBefore48Hrs.getDate() - 2)
      // if the runout time is less than 48hr, we will change the text colour to red and add a tooltip to the row.
      const isLessThan48hours = Date.now() / 1000 > runoutDateBefore48Hrs.getTime() / 1000
      const isWarningMsgShown = !hasReachedRunoutTimestamp && isLessThan48hours
      const asset = ASSETS[chainId][poolSymbol][assetSymbol]
      const rewarderAddress = isToShowBribeData
        ? asset?.bribeRewarder?.rewarderAddress
        : asset?.boostedPoolRewarder?.rewarderAddress

      return {
        id: `${poolSymbol}${assetSymbol}${rewardSymbol}`,
        configs: [
          {
            cell: (
              <div className="flex items-center gap-2">
                {isWarningMsgShown && (
                  <AppTooltip message="It is running out of rewards soon. Please top up the rewarder to extend the period.">
                    <WarningIcon size={16} />
                  </AppTooltip>
                )}
                <AssetTokenDisplay poolLabel={poolSymbol} assetTokenSymbol={assetSymbol} />
                {rewarderAddress && (
                  <button
                    onClick={() =>
                      window.open(getExplorerLink(rewarderAddress.toString(), 'address', chainId))
                    }
                    className="flex items-center justify-center"
                  >
                    <div className="ml-1 flex items-center">
                      <Image src={ViewOnIcon} height={16} width={16} />
                    </div>
                  </button>
                )}
              </div>
            ),
            sortBy: `${poolSymbol}${assetSymbol}`,
          },
          {
            cell: (
              <p
                className={clsx(
                  'table_token-wrapper--flex justify-center',
                  isWarningMsgShown && 'text-wombatRed'
                )}
              >
                <TokenImage tokenSymbol={rewardSymbol} width="24" height="24" />
                <span>
                  {getCommifiedFormat(weeklyEmissionRate, 2)} {rewardDisplaySymbol}
                </span>
              </p>
            ),
            sortBy: +weeklyEmissionRate,
          },
          {
            cell: (
              <p
                className={clsx(
                  'table_token-wrapper--flex justify-center',
                  isWarningMsgShown && 'text-wombatRed'
                )}
              >
                <TokenImage tokenSymbol={rewardSymbol} width="24" height="24" />
                <span>
                  {getCommifiedFormat(remainingSurplus, 2)} {rewardDisplaySymbol}
                </span>
              </p>
            ),
            sortBy: +remainingSurplus,
          },
          {
            cell: (
              <span className={clsx(isWarningMsgShown && 'text-wombatRed')}>
                {hasReachedRunoutTimestamp
                  ? 'Ended'
                  : `${timeConverter(runoutTimestamp, true, false, true)}`}
              </span>
            ),
            sortBy: runoutTimestamp,
          },
          {
            cell: (
              <p className="flex flex-col items-center justify-center gap-1">
                <ActionButton
                  onClick={() => {
                    openModal({
                      currentModalId: ModalId.TOP_UP_BRIBE,
                      payload: { assetSymbol, rewardSymbol, poolSymbol },
                    })
                  }}
                >
                  Top-up
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    openModal({
                      currentModalId: ModalId.UPDATE_BRIBE_EMISSION_RATE,
                      payload: { assetSymbol, rewardSymbol, poolSymbol },
                    })
                  }}
                >
                  Edit
                </ActionButton>
              </p>
            ),
          },
        ],
      }
    })
  }, [addedRewardList, chainId, isToShowBribeData, openModal])
  const [sortingState, updateSortingState] = useTableSortingState()
  const processedTable = useTable({
    tableData,
    options: {
      sortingState: sortingState,
    },
  })

  return (
    <div className="container--drop-shadow flex flex-1 flex-col p-[32px]">
      <p className="mb-5 text-base font-semibold">{label}s</p>
      <Table enableRowGap style={{ minWidth: '600px' }} scrollDirection="both" maxHeight="500px">
        <Table.Head disableBg sticky>
          <Table.Row>
            {tableHeadData.map((data, index) => (
              <Table.HeadData
                columnIndex={index}
                key={index}
                style={data.style}
                {...(!data.disableSorting && {
                  sortingState: sortingState,
                  enableSortingIcon: true,
                  onClick: () => {
                    updateSortingState(index)
                  },
                })}
              >
                {data.cell}
              </Table.HeadData>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {processedTable.tableData.length > 0 ? (
            processedTable.tableData.map((data, index) => {
              return (
                <Table.Row
                  className={clsx(
                    +addedRewardList[index].remainingSurplus <= 0
                      ? 'bg-wombatBrownAlpha'
                      : 'bg-wombatYellow3'
                  )}
                  disableHoverEffect
                  key={data.id}
                >
                  {data.configs.map((config, index) => {
                    return (
                      <Table.Data key={index} style={{ justifyContent: 'center' }}>
                        {config.cell}
                      </Table.Data>
                    )
                  })}
                  {/** @todo this progress bar is not working as we don't have originalTimestamp but only have runoutTimestamp.  */}
                  {/* <div
                  className="absolute left-0 bottom-0 rounded-full bg-wombatPurple1"
                  style={{
                    height: '5px',
                    width: `${getTimeLeftInPercentage(addedBribeList[index].runoutTimestamp)}%`,
                  }}
                /> */}
                </Table.Row>
              )
            })
          ) : (
            <Table.Row className="h-[70px]" disableHoverEffect>
              <p className="absolute flex h-full w-full items-center justify-center bg-[rgba(220,204,226,0.50)] text-sm text-wombatBrown3">
                {isToShowBribeData
                  ? 'Add Bribe to incentivize votes'
                  : 'Add Rewarder to incentivize liquidity provision'}
              </p>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      {modalState.currentModalId === ModalId.TOP_UP_BRIBE && <TopUpModal isOpen />}
      {modalState.currentModalId === ModalId.UPDATE_BRIBE_EMISSION_RATE && (
        <EditEmissionModal isOpen />
      )}
    </div>
  )
}

export default AddedBribesTable

const ActionButton = ({ className, children, onClick, ...otherProps }: ButtonProps) => {
  return (
    <Button
      className={clsx(
        'w-[70px] rounded-[4px] border-1 border-wombatPurple1 bg-transparent px-[2px] py-[0] text-wombatPurple1',
        className
      )}
      onClick={onClick}
      {...otherProps}
    >
      <span className="text-sm">{children}</span>
    </Button>
  )
}

import { TokenSymbol, TokenSymbols } from '../constants/contract/token/TokenSymbols'
import { SupportedChainId } from '../constants/web3/supportedChainId'

export enum AdminTableColumnId {
  poolInfo = 'poolInfo',
  womEmissionInNextEpoch = 'womEmissionInNextEpoch',
  assetInfo = 'assetInfo',
  pid = 'pid',
  poolRewardersEmissionInCurrentEpochs = 'poolRewardersEmissionInCurrentEpochs',
  poolRewardersBalanceInfos = 'poolRewardersBalanceInfos',
  boostedRewardersEmissionInCurrentEpochs = 'boostedRewardersEmissionInCurrentEpochs',
  boostedRewardersBalanceInfos = 'boostedRewardersBalanceInfos',
  gaugeWeight = 'gaugeWeight',
  bribeInCurrentEpoch = 'bribeInCurrentEpoch',
  totalBribeInUsdInCurrentEpoch = 'totalBribeInUsdInCurrentEpoch',
  bribeEfficiency = 'bribeEfficiency',
  projectedBribeEfficiency = 'projectedBribeEfficiency',
  bribeBalanceInfos = 'bribeBalanceInfos',
  womEmissionInCurrentEpoch = 'womEmissionInCurrentEpoch',
  allocPoint = 'allocPoint',
  bribeInfo = 'bribeInfo',
  ampFactor = 'ampFactor',
  haircutPercentage = 'haircutPercentage',
  remainingSurplus = 'remainingSurplus',
  rates = 'rates',
}

export type SurPlus = {
  value: string
  symbol: TokenSymbols
}

export type SurplusData = {
  pid: number
  address: string
  surplus?: SurPlus[]
  is_rewarder: boolean
  is_v1: boolean
}

export type rewardersEmissionInCurrentEpoch = {
  bonusTokenPerWeek: number
  symbol: TokenSymbol
  rewardTokenPrice: number
}

export type IEpochEmissionData = {
  [AdminTableColumnId.poolInfo]: {
    name: string
    address: string
    chainId: SupportedChainId
  }
  [AdminTableColumnId.assetInfo]: {
    tokenSymbol: TokenSymbol
    address: string
    boostedPoolRewarder: string
    bribeRewarderAddress: string
    poolRewarderAddress: string
    chainId: SupportedChainId
    pid: number
  }
  [AdminTableColumnId.pid]: number
  [AdminTableColumnId.poolRewardersEmissionInCurrentEpochs]: rewardersEmissionInCurrentEpoch[]
  [AdminTableColumnId.poolRewardersBalanceInfos]: {
    amount: string
    tokenSymbol: string
    runoutDays: number
    isTpsZero: boolean
    tokensPerDays: number
  }[]
  [AdminTableColumnId.boostedRewardersEmissionInCurrentEpochs]?: rewardersEmissionInCurrentEpoch[]
  [AdminTableColumnId.boostedRewardersBalanceInfos]?: {
    amount: string
    tokenSymbol: string
    runoutDays: number
    isTpsZero: boolean
    tokensPerDays: number
  }[]
  [AdminTableColumnId.womEmissionInCurrentEpoch]: number
  [AdminTableColumnId.womEmissionInNextEpoch]: number
  [AdminTableColumnId.allocPoint]: string
  [AdminTableColumnId.gaugeWeight]: string
  [AdminTableColumnId.bribeInfo]: {
    tokenSymbol: TokenSymbol
    address: string
    chainId: SupportedChainId
  }[]
  [AdminTableColumnId.bribeInCurrentEpoch]: string
  [AdminTableColumnId.totalBribeInUsdInCurrentEpoch]: string
  [AdminTableColumnId.bribeEfficiency]: string
  [AdminTableColumnId.projectedBribeEfficiency]: string
  [AdminTableColumnId.bribeBalanceInfos]: {
    amount: string
    tokenSymbol: string
    bribeRunoutDays: number
    tokensPerDays: number
  }[]
  [AdminTableColumnId.haircutPercentage]: string
  [AdminTableColumnId.ampFactor]: string
  [AdminTableColumnId.remainingSurplus]: SurplusData[]
  [AdminTableColumnId.rates]: {
    boostedPoolRewarder: string
    values: {
      tokenId: number
      tokenSymbol: string
      currentRate: string
      expectedRate: string
    }[]
  }
}

import Image from 'next/image'
import tooltip from '../../public/assets/icons/tooltip.svg'
type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
  bgColor?: string
  pointerEvent?: boolean
  className?: string
  isFixed?: boolean
  fontSize?: string
  isTextPositionLeftOnMobile?: boolean
  isTextPositionRightOnMobile?: boolean
  width?: number
  hideTooltipImage?: boolean
}
export default function Tooltip({
  children,
  bgColor = 'wombatBrown1',
  pointerEvent,
  className,
  isFixed,
  hideTooltipImage = false,
  isTextPositionLeftOnMobile = false,
  isTextPositionRightOnMobile = false,
  width,
}: Props) {
  return (
    <span className={`${className ? className : isFixed ? 'fixed' : 'relative'}`}>
      <div
        className={`group relative flex h-full items-center justify-center ${
          pointerEvent ? 'cursor-pointer' : 'cursor-default'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {!hideTooltipImage && <Image height={10} width={10} src={tooltip}></Image>}
        <div
          style={{
            transform: 'translateY(-3px)',
            width: `${width ? `${width}px` : '208px'}`,
          }}
          className={`bg-${bgColor} absolute z-10 w-60 p-2.5 font-Work text-sm font-normal text-wombatCream 
           md:left-full md:bottom-0 ${
             isTextPositionLeftOnMobile
               ? 'right-full mr-[2px]'
               : isTextPositionRightOnMobile
               ? 'left-full ml-[2px]'
               : 'bottom-full'
           }
           } invisible
            text-left group-hover:visible
           md:hidden `}
        >
          {children}
        </div>
        <div
          style={{
            transform: 'translateX(2px)',
            width: `${width}px`,
          }}
          className={`bg-${bgColor} } invisible absolute bottom-full z-10 hidden w-52 p-2.5 text-left
           font-Work text-sm
           font-normal text-wombatCream
            group-hover:visible md:left-full md:bottom-0 md:block
           `}
        >
          {children}
        </div>

        {isTextPositionLeftOnMobile ? (
          <div
            style={{
              clipPath: 'polygon(50% 0, 100% 50%, 50% 100%)',
              height: '14px',
            }}
            className={`bg-${bgColor} absolute right-full w-2 opacity-0 group-hover:opacity-100 md:hidden`}
          />
        ) : isTextPositionRightOnMobile ? (
          <div
            style={{
              clipPath: 'polygon(0 50%, 100% 0, 100% 100%)',
              height: '14px',
            }}
            className={`bg-${bgColor} absolute left-full w-2 opacity-0 group-hover:opacity-100 md:hidden`}
          />
        ) : (
          <div
            style={{
              clipPath: 'polygon(100% 50%, 0 50%, 50% 100%)',
              height: '14px',
            }}
            className={`bg-${bgColor} absolute bottom-full w-2 opacity-0 group-hover:opacity-100 md:hidden`}
          />
        )}

        <div
          style={{
            clipPath: 'polygon(50% 0, 0 50%, 50% 100%)',
            height: '14px',
          }}
          className={`bg-${bgColor} absolute left-full bottom-0 hidden w-2 opacity-0 group-hover:opacity-100 md:block`}
        />
      </div>
    </span>
  )
}

import { BigNumber, utils } from 'ethers'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { POOLS } from '../../../constants/contract/pool'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'
import { isEmptyAddress } from '../../../utils'
import { CROSS_CHAIN_POOL_ABI } from '../../../constants/contract/abis/pool'
import { Contract } from '../../../constants/contract/Contract'

export interface PoolDataWithoutAccountType {
  poolFeePercentages: {
    [id in PoolLabels]?: number
  }
  lpDividendRatios: {
    [id in PoolLabels]?: string
  }
  endCovRatios: {
    [id in PoolLabels]?: number
  }
  ampFactor: {
    [id in PoolLabels]?: string
  }
}

export interface PoolDataWithAccountType {
  creditBalance: string
}

export const fetchPoolData = (
  chainId: SupportedChainId,
  account: string | null | undefined,
  onlyXChain?: boolean
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: { withoutAccount: PoolDataWithoutAccountType; withAccount: PoolDataWithAccountType }
} => {
  /**
   *  Create empty contractCalls and callbacks array
   */
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withoutAccount: PoolDataWithoutAccountType
    withAccount: PoolDataWithAccountType
  } = {
    withoutAccount: {
      poolFeePercentages: {},
      lpDividendRatios: {},
      endCovRatios: {},
      ampFactor: {},
    },
    withAccount: {
      creditBalance: '',
    },
  }

  const handlePoolData = () => {
    // withoutAccount data
    const poolList = Object.values(POOLS[chainId])
    for (let i = 0; i < poolList.length; i++) {
      const poolLabel = poolList[i].label
      if (isEmptyAddress(poolList[i].address)) continue
      if (onlyXChain && poolLabel !== PoolLabels.CROSS_CHAIN) continue

      contractCalls.push(
        poolList[i].multicall('haircutRate'),
        poolList[i].multicall('lpDividendRatio'),
        poolList[i].multicall('endCovRatio'),
        poolList[i].multicall('ampFactor')
      )

      callbacks.push(
        (res: BigNumber) => {
          states.withoutAccount.poolFeePercentages = {
            ...states.withoutAccount.poolFeePercentages,
            [poolLabel]: Number(utils.formatEther(res.mul('100'))),
          }
        },
        (res: BigNumber) => {
          states.withoutAccount.lpDividendRatios = {
            ...states.withoutAccount.lpDividendRatios,
            [poolLabel]: utils.formatEther(res),
          }
        },
        (res: BigNumber) => {
          states.withoutAccount.endCovRatios = {
            ...states.withoutAccount.endCovRatios,
            [poolLabel]: Number(utils.formatEther(res)),
          }
        },
        (res: BigNumber) => {
          states.withoutAccount.ampFactor = {
            ...states.withoutAccount.ampFactor,
            [poolLabel]: utils.formatEther(res),
          }
        }
      )
    }
  }
  const handleCrossPoolData = () => {
    /** Multicall for cross chain pool */
    if (account) {
      const crossChainPoolAddress = POOLS[chainId].CROSS_CHAIN?.address

      if (!crossChainPoolAddress) return

      const crossChainPool = new Contract<typeof CROSS_CHAIN_POOL_ABI>({
        address: crossChainPoolAddress,
        chainId,
        abi: CROSS_CHAIN_POOL_ABI,
      })

      contractCalls.push(crossChainPool.multicall('creditBalance', [account]))

      callbacks.push((res: BigNumber) => {
        states.withAccount.creditBalance = utils.formatEther(res)
      })
    }
  }

  handlePoolData()
  handleCrossPoolData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}

import { AssetConfig } from '..'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'
import { defaultAssetConfig } from '../defaultAssetConfig'

export const SCROLL_MAINNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,
  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
      enableVoting: false,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#22B093',
      enableVoting: false,
    },
  },
  [PoolLabels.MAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#1D72B9',
      enableVoting: false,
    },
    [TokenSymbols.WETH]: {
      name: 'ETH',
      color: '#E5C5FF',
      displaySymbol: 'ETH',
    },
    [TokenSymbols.WBTC]: {
      name: 'Wrapped Bitcoin',
      color: '#F7931A',
    },
  },
}

import {
  MultiChainMultiCallDataWithAccountType,
  MultiChainMulticallDataWithoutAccountType,
} from './types'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import {
  fetchAssetData,
  fetchPoolData,
  fetchBoostedPoolRewarderAddressesOn1stMulticall,
  fetchBoostedPoolRewarderDataOn2ndMulticall,
  fetchBoostedPoolRewarderDataOn3rdMulticall,
  fetchTokenData,
} from '../../context/MulticallDataContext/helpers'
import { fetchMasterWombatData } from '../../context/MulticallDataContext/helpers/fetchMasterWombatHelper'
import { multicall } from '@wagmi/core'
import { executeCallBacks } from '../../utils/executeCallBacks'
import { fetchUniswapV3PoolData } from '../../context/MulticallDataContext/helpers/fetchUniswapV3PoolDataHelper'
import { getInitialTokenPricesProperty } from '../../context/MulticallDataContext/helpers/fetchTokenPricesHelper'
import { fetchTradingVol24h } from '../../context/MulticallDataContext/helpers/fetchTradingVolHelper'
import { TokenSymbolStringType } from '../../interfaces/common'
import { ethers } from 'ethers'

export const getMultiChainMultiCallData = async ({
  currentChainId,
  chainIds,
  account,
}: {
  account: `0x${string}` | null
  coingeckoPriceData: Partial<TokenSymbolStringType> | undefined
  currentChainId: SupportedChainId
  readonlyProvider: ethers.providers.JsonRpcProvider
  chainIds: SupportedChainId[]
}) => {
  const multiChainWithAccountData: MultiChainMultiCallDataWithAccountType = {}
  const multiChainWithoutAccountData: MultiChainMulticallDataWithoutAccountType = {}
  /** multicall for the chains that support crosschain swap */
  for (const chainIdStr of chainIds) {
    const chainId = chainIdStr as SupportedChainId
    // if (!chainInfos[chainId].isCrossChainAvailable && chainId === currentChainId) {
    //   continue
    // }
    const {
      contractCalls: multiChainTokenDataCalls,
      callbacks: multiChainTokenDataCallbacks,
      states: tokenDataStates,
    } = fetchTokenData({
      chainId,
      account,
      skipPriceFetching: true,
      onlyXChain: chainId !== currentChainId,
    })

    const {
      contractCalls: multiChainMasterWombatDataCalls,
      callbacks: multiChainMasterWombatDataCallbacks,
      states: masterWombatDataStates,
    } = fetchMasterWombatData(chainId, account, chainId !== currentChainId)

    const {
      contractCalls: poolRewarderDataCallsOn1stMulticall,
      callbacks: poolRewarderDataCallbacksOn1stMulticall,
    } = fetchBoostedPoolRewarderAddressesOn1stMulticall(chainId, chainId !== currentChainId)

    const {
      contractCalls: poolRewarderDataCallsOn2ndMulticall,
      callbacks: poolRewarderDataCallbacksOn2ndMulticall,
    } = fetchBoostedPoolRewarderDataOn2ndMulticall(chainId, chainId !== currentChainId)

    const {
      contractCalls: poolRewarderDataCallsOn3rdMulticall,
      callbacks: poolRewarderDataCallbacksOn3rdMulticall,
      states: poolRewarderDataStates,
    } = fetchBoostedPoolRewarderDataOn3rdMulticall(chainId, account, chainId !== currentChainId)

    /**
     * Without Account
     */
    const {
      contractCalls: multiChainAssetDataCalls,
      callbacks: multiChainAssetDataCallbacks,
      states: assetDataStates,
    } = fetchAssetData(chainId, account, chainId !== currentChainId)

    const {
      contractCalls: multiChainPoolDataCalls,
      callbacks: multiChainPoolDataCallbacks,
      states: poolDataStates,
    } = fetchPoolData(chainId, account, chainId !== currentChainId)

    const {
      contractCalls: uniswapV3PoolDataCalls,
      callbacks: uniswapV3PoolDataCallbacks,
      states: uniswapV3PoolDataStates,
    } = fetchUniswapV3PoolData(chainId)

    // try {
    const values = await multicall({
      contracts: [
        ...multiChainTokenDataCalls,
        ...multiChainMasterWombatDataCalls,
        ...multiChainAssetDataCalls,
        ...uniswapV3PoolDataCalls,
      ],
      allowFailure: true,
      chainId,
    })

    executeCallBacks(values, [
      ...multiChainTokenDataCallbacks,
      ...multiChainMasterWombatDataCallbacks,
      ...multiChainAssetDataCallbacks,
      ...uniswapV3PoolDataCallbacks,
    ])
    const [tradingVol24h, values2] = await Promise.all([
      fetchTradingVol24h(chainId),
      multicall({
        contracts: [
          ...multiChainPoolDataCalls,
          ...poolRewarderDataCallsOn1stMulticall,
          ...poolRewarderDataCallsOn2ndMulticall,
          ...poolRewarderDataCallsOn3rdMulticall,
        ],
        allowFailure: true,
        chainId,
      }),
    ])

    executeCallBacks(values2, [
      ...multiChainPoolDataCallbacks,
      ...poolRewarderDataCallbacksOn1stMulticall,
      ...poolRewarderDataCallbacksOn2ndMulticall,
      ...poolRewarderDataCallbacksOn3rdMulticall,
    ])

    multiChainWithAccountData[chainId] = {
      tokenData: tokenDataStates.withAccount,
      masterWombatData: masterWombatDataStates.withAccount,
    }

    multiChainWithoutAccountData[chainId] = {
      tokenData: tokenDataStates.withoutAccount,
      assetData: assetDataStates.withoutAccount,
      masterWombatData: masterWombatDataStates.withoutAccount,
      poolData: poolDataStates.withoutAccount,
      uniswapV3PoolData: uniswapV3PoolDataStates,
      tokenPrices: getInitialTokenPricesProperty(),
      tradingVol24h,
      boostedPoolRewarderData: poolRewarderDataStates.withoutAccount,
    }
  }
  return {
    withAccount: multiChainWithAccountData,
    withoutAccount: multiChainWithoutAccountData,
  }
}

import React from 'react'
import { Token } from '../../constants/contract/token/Token'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import Modal from '../Modal'
import { ListToken } from './ListToken'
import { TokenSymbolGenericType } from '../../interfaces/common'

type Props = {
  id?: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  tokens: Token[]
  disableTokens: TokenSymbol[] | null
  onTokenChange: (token: Token) => void
  selectedToken: Token | null
  title?: string
  balances?: TokenSymbolGenericType<string>
}

const SelectTokenModal = ({
  isOpen,
  setIsOpen,
  title,
  id,
  tokens,
  disableTokens,
  onTokenChange,
  selectedToken,
  balances,
}: Props) => {
  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      title={title || 'Select a Token'}
      isOpen={isOpen}
      onClose={closeModal}
      width={330}
      height={440}
    >
      <ListToken
        id={id}
        tokens={tokens}
        disableTokens={disableTokens}
        onTokenChange={onTokenChange}
        selectedToken={selectedToken}
        closeModal={closeModal}
        balances={balances}
      />
    </Modal>
  )
}

export default SelectTokenModal

import { createAsyncThunk } from '@reduxjs/toolkit'
import { MultiChainMultiCallDataState } from '../slice'
import { TokenSymbolStringType } from '../../../interfaces/common'
import { ethers } from 'ethers'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { getMultiChainMultiCallData } from '../helpers'

const fetchMultichainMulticallData = createAsyncThunk<
  Pick<MultiChainMultiCallDataState, 'withAccount' | 'withoutAccount'>,
  {
    account: `0x${string}` | null
    coingeckoPriceData: Partial<TokenSymbolStringType> | undefined
    currentChainId: SupportedChainId
    readonlyProvider: ethers.providers.JsonRpcProvider
    chainIds: SupportedChainId[]
  }
>(
  'MultiChainMultiCallData/fetchMultichainMulticallData',
  async ({ account, coingeckoPriceData, currentChainId, readonlyProvider, chainIds }) => {
    const { withoutAccount, withAccount } = await getMultiChainMultiCallData({
      chainIds,
      currentChainId,
      account,
      coingeckoPriceData,
      readonlyProvider,
    })
    return {
      withAccount,
      withoutAccount,
    }
  }
)

export default fetchMultichainMulticallData

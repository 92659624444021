import { createReducer } from '@reduxjs/toolkit'
import {
  Toast,
  addErrorToast,
  addInfoToast,
  addSuccessToast,
  addToast,
  addWarningToast,
  removeToast,
} from './actions'

type ToastList = Toast[]

export interface ApplicationState {
  readonly toastList: ToastList
}

const initialState: ApplicationState = {
  toastList: [],
}

const DEFAULT_INTERVAL = 4500

const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      addToast,
      (
        state,
        {
          payload: {
            message,
            title,
            txHash,
            type,
            lifetime,
            truncate,
            childrenButton,
            handleClickButton,
          },
        }
      ) => {
        state.toastList = state.toastList.concat([
          {
            id: uuidv4(),
            message,
            title: title ?? '',
            txHash: txHash ?? '',
            type: type,
            lifetime: lifetime ?? DEFAULT_INTERVAL,
            truncate: truncate,
            childrenButton,
            handleClickButton,
          },
        ])
      }
    )
    .addCase(
      addErrorToast,
      (state, { payload: { message, title, txHash, lifetime, truncate } }) => {
        console.log('addErrorToast')
        state.toastList = state.toastList.concat([
          {
            id: uuidv4(),
            message,
            title: title ?? '',
            txHash: txHash ?? '',
            type: 'Error',
            lifetime: lifetime ?? DEFAULT_INTERVAL,
            truncate,
          },
        ])
      }
    )
    .addCase(
      addWarningToast,
      (state, { payload: { message, title, txHash, lifetime, truncate } }) => {
        state.toastList = state.toastList.concat([
          {
            id: uuidv4(),
            message,
            title: title ?? '',
            txHash: txHash ?? '',
            type: 'Warning',
            lifetime: lifetime ?? DEFAULT_INTERVAL,
            truncate,
          },
        ])
      }
    )
    .addCase(
      addSuccessToast,
      (
        state,
        {
          payload: {
            message,
            title,
            txHash,
            childrenButton,
            handleClickButton,
            lifetime,
            truncate,
          },
        }
      ) => {
        state.toastList = state.toastList.concat([
          {
            id: uuidv4(),
            message,
            title: title ?? '',
            txHash: txHash ?? '',
            type: 'Success',
            childrenButton,
            handleClickButton,
            lifetime: lifetime ?? DEFAULT_INTERVAL,
            truncate,
          },
        ])
      }
    )
    .addCase(addInfoToast, (state, { payload: { message, title, txHash, lifetime, truncate } }) => {
      state.toastList = state.toastList.concat([
        {
          id: uuidv4(),
          message,
          title: title ?? '',
          txHash: txHash ?? '',
          type: 'Info',
          lifetime: lifetime ?? DEFAULT_INTERVAL,
          truncate,
        },
      ])
    })
    .addCase(removeToast, (state, { payload: { id } }) => {
      state.toastList = state.toastList.filter((t) => t.id !== id)
    })
)

import SearchIcon from './SearchIcon'
import WarningIcon from './WarningIcon'
const ShowMoreSvg = ({ fill }: { fill: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    className=""
  >
    <rect x="4" y="10" width="4" height="4"></rect>
    <rect x="10" y="10" width="4" height="4"></rect>
    <rect x="16" y="10" width="4" height="4"></rect>
  </svg>
)
const SwapFooterSvg = ({ fill }: { fill: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 1.5V11.25C18 11.6478 17.842 12.0294 17.5607 12.3107C17.2794 12.592 16.8978 12.75 16.5 12.75H6.30938L7.28437 13.7156C7.42521 13.8578 7.50422 14.0499 7.50422 14.25C7.50422 14.4501 7.42521 14.6422 7.28437 14.7844C7.14102 14.923 6.94941 15.0005 6.75 15.0005C6.55059 15.0005 6.35898 14.923 6.21563 14.7844L3.96563 12.5344C3.82479 12.3922 3.74578 12.2001 3.74578 12C3.74578 11.7999 3.82479 11.6078 3.96563 11.4656L6.21563 9.21563C6.36135 9.09603 6.54634 9.03491 6.73463 9.04416C6.92292 9.0534 7.10104 9.13236 7.23434 9.26566C7.36764 9.39896 7.4466 9.57708 7.45584 9.76537C7.46509 9.95366 7.40397 10.1387 7.28437 10.2844L6.30938 11.25H16.5V1.5H6V2.25C6 2.44891 5.92098 2.63968 5.78033 2.78033C5.63968 2.92098 5.44891 3 5.25 3C5.05109 3 4.86032 2.92098 4.71967 2.78033C4.57902 2.63968 4.5 2.44891 4.5 2.25V1.5C4.5 1.10218 4.65804 0.720644 4.93934 0.43934C5.22064 0.158035 5.60218 0 6 0L16.5 0C16.8978 0 17.2794 0.158035 17.5607 0.43934C17.842 0.720644 18 1.10218 18 1.5ZM12.75 15C12.5511 15 12.3603 15.079 12.2197 15.2197C12.079 15.3603 12 15.5511 12 15.75V16.5H1.5V6.75H11.6906L10.7156 7.71563C10.5748 7.85782 10.4958 8.04986 10.4958 8.25C10.4958 8.45014 10.5748 8.64218 10.7156 8.78437C10.859 8.92299 11.0506 9.00048 11.25 9.00048C11.4494 9.00048 11.641 8.92299 11.7844 8.78437L14.0344 6.53437C14.1752 6.39218 14.2542 6.20014 14.2542 6C14.2542 5.79986 14.1752 5.60782 14.0344 5.46563L11.7844 3.21563C11.6387 3.09603 11.4537 3.03491 11.2654 3.04416C11.0771 3.0534 10.899 3.13236 10.7657 3.26566C10.6324 3.39896 10.5534 3.57708 10.5442 3.76537C10.5349 3.95366 10.596 4.13865 10.7156 4.28437L11.6906 5.25H1.5C1.10218 5.25 0.720644 5.40804 0.43934 5.68934C0.158035 5.97064 0 6.35218 0 6.75L0 16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H12C12.3978 18 12.7794 17.842 13.0607 17.5607C13.342 17.2794 13.5 16.8978 13.5 16.5V15.75C13.5 15.5511 13.421 15.3603 13.2803 15.2197C13.1397 15.079 12.9489 15 12.75 15Z"
        fill={fill}
      />
    </svg>
  )
}
const PoolFooterSvg = ({ fill }: { fill: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.75 9V7.5H3C2.60218 7.5 2.22064 7.65804 1.93934 7.93934C1.65804 8.22064 1.5 8.60218 1.5 9V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H15C15.3978 22.5 15.7794 22.342 16.0607 22.0607C16.342 21.7794 16.5 21.3978 16.5 21V14.25H15V21H3V9"
        fill={fill}
      />
      <path
        d="M16.5 9H10.5V3H16.5V9ZM9 3V9C9 9.39782 9.15804 9.77936 9.43934 10.0607C9.72064 10.342 10.1022 10.5 10.5 10.5H16.5C16.8978 10.5 17.2794 10.342 17.5607 10.0607C17.842 9.77936 18 9.39782 18 9V3C18 2.60218 17.842 2.22064 17.5607 1.93934C17.2794 1.65804 16.8978 1.5 16.5 1.5H10.5C10.1022 1.5 9.72064 1.65804 9.43934 1.93934C9.15804 2.22064 9 2.60218 9 3ZM10.5 13.5V16.5H7.5V13.5H10.5ZM6 13.5V16.5C6 16.8978 6.15804 17.2794 6.43934 17.5607C6.72064 17.842 7.10218 18 7.5 18H10.5C10.8978 18 11.2794 17.842 11.5607 17.5607C11.842 17.2794 12 16.8978 12 16.5V13.5C12 13.1022 11.842 12.7206 11.5607 12.4393C11.2794 12.158 10.8978 12 10.5 12H7.5C7.10218 12 6.72064 12.158 6.43934 12.4393C6.15804 12.7206 6 13.1022 6 13.5ZM21 12.75V14.25H19.5V12.75H21ZM18 12V15C18 15.1989 18.079 15.3897 18.2197 15.5303C18.3603 15.671 18.5511 15.75 18.75 15.75H21.75C21.9489 15.75 22.1397 15.671 22.2803 15.5303C22.421 15.3897 22.5 15.1989 22.5 15V12C22.5 11.8011 22.421 11.6103 22.2803 11.4697C22.1397 11.329 21.9489 11.25 21.75 11.25H18.75C18.5511 11.25 18.3603 11.329 18.2197 11.4697C18.079 11.6103 18 11.8011 18 12Z"
        fill={fill}
      />
    </svg>
  )
}
const BoostFooterSvg = ({ fill }: { fill: string }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.43066 11.7973C1.23998 12.0197 1.19621 12.3327 1.31858 12.5988C1.44096 12.865 1.70707 13.0355 2 13.0355H6.81733L5.04995 19.8107C4.96181 20.1486 5.11852 20.5026 5.42791 20.6645C5.73731 20.8264 6.11751 20.7534 6.34488 20.4884L15.1692 10.2029C15.36 9.98051 15.4038 9.66745 15.2815 9.40126C15.1591 9.13508 14.893 8.9645 14.6 8.9645H9.78213L11.5457 2.18892C11.6337 1.85102 11.4768 1.49713 11.1674 1.33536C10.858 1.17358 10.4779 1.24673 10.2506 1.51179L1.43066 11.7973Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
const GaugeFooterSvg = ({ fill }: { fill: string }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7913 2.61157L11.375 17.3856L6.20874 10.6998L7.79131 9.47691L11.375 14.1147L21.2087 1.38867L22.7913 2.61157Z"
        fill={fill}
      />
      <path
        d="M9 4V2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V11H20V20H4V4"
        fill={fill}
      />
    </svg>
  )
}

export {
  ShowMoreSvg,
  SwapFooterSvg,
  PoolFooterSvg,
  BoostFooterSvg,
  GaugeFooterSvg,
  WarningIcon,
  SearchIcon,
}

import { BigNumber } from 'ethers'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'
import { VEWOMS } from '../../../constants/contract/veWom'

export interface VewomDataWithoutAccountType {
  vewomTotalSupplyWad: BigNumber | null
}

export interface VewomDataWithAccountType {
  vewomBalanceWad: BigNumber | null
}

export const fetchVewomData = (
  chainId: SupportedChainId,
  account: string | null | undefined
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: { withoutAccount: VewomDataWithoutAccountType; withAccount: VewomDataWithAccountType }
} => {
  /**
   *  Create empty contractCalls and callbacks array
   */
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withoutAccount: VewomDataWithoutAccountType
    withAccount: VewomDataWithAccountType
  } = {
    withoutAccount: {
      vewomTotalSupplyWad: null,
    },
    withAccount: {
      vewomBalanceWad: null,
    },
  }
  const handleVewomData = () => {
    // withoutAccount data
    const veWom = VEWOMS[chainId]
    // Note: This function is invoked twice when `account` is updated
    if (!chainId || !veWom) return
    contractCalls.push(veWom.multicall('totalSupply'))
    callbacks.push((res) => {
      states.withoutAccount.vewomTotalSupplyWad = res
    })
    if (account) {
      contractCalls.push(veWom.multicall('balanceOf', [account]))
      callbacks.push((res) => (states.withAccount.vewomBalanceWad = res))
    }
  }
  handleVewomData()
  return {
    contractCalls,
    callbacks,
    states,
  }
}

interface Props {
  listItem: string[]
  onSelect: (page: string) => void
  classname?: string
}

const SelectOption = ({ listItem, onSelect, classname }: Props) => {
  const renderItem = () => {
    return listItem.map((item, i) => {
      return (
        <div
          className="mt-4 md:mt-2 first:mt-[10px] md:first:mt-2"
          key={item}
          onClick={() => {
            onSelect(item)
          }}
        >
          {item}
        </div>
      )
    })
  }

  return (
    <div
      style={{
        margin: '0 -2px',
        paddingTop: '0px',
      }}
      className={`bg-wombatCream absolute top-full bottom-auto md:bottom-0 md:top-auto z-10 border-2 border-t-0 md:border-t-2 md:border-b-0
      border-wombatPurple2 rounded rounded-t-none md:rounded-t md:rounded-b-none left-0 right-0 p-1
        pt-0 md:px-2 md:py-1 text-wombatBrown flex flex-col md:flex-col-reverse ${classname}`}
    >
      {renderItem()}
    </div>
  )
}

export default SelectOption

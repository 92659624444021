import React from 'react'
import { shortenHashString } from '../../../utils'
import Image from 'next/image'
import arrowRight from '../../../public/assets/icons/arrow-right-thin.svg'
import TokenImage from '../../TokenImage'
import { TokenSymbols } from '../../../configx/tokenSymbols'
import { useBridgeRedeemContext } from '../../../context/BridgeRedeemContext'

export const BridgeRedeemInfoBox = () => {
  const { receipt, bridgedTokenAmount, toAddress } = useBridgeRedeemContext()

  return (
    <div className="flex w-full flex-col gap-2 rounded border-1 border-wombatBrown1 p-2 text-[14px]">
      <div className="flex items-center justify-between">
        <span className="font-bold">Receive</span>
        <div className="flex gap-1">
          <div>{receipt && shortenHashString(receipt.from, 4, receipt.from.length)}</div>
          <div>
            <Image src={arrowRight} height={8} width={8} />
          </div>
          <div>{toAddress && shortenHashString(toAddress, 4, toAddress.length)}</div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span className="font-bold">Amount</span>
        <div className="flex items-center gap-1">
          <span>
            {bridgedTokenAmount} {TokenSymbols.WOM}
          </span>
          <TokenImage tokenSymbol={TokenSymbols.WOM} width="16px" height="16px" />
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import WButton, { Variant } from '../../WButton'
import { useWeb3 } from '../../../context/Web3Context'
import {
  getCaseAndMetaData,
  getDecodedSwapCreditForTokensCrossChainInputData,
  getDecodedSwapTokensForTokensCrossChainInputData,
  getRelayStatusAndEndpoint,
} from '../../../utils/crossChainSwap'
import { chainInfos } from '../../../constants/web3/chains'
import { ethers } from 'ethers'
import { InputTxData, useCrossChainSwapTx } from '../../../hooks/crossChainSwap/useCrossChainSwapTx'
import { useUserPreference } from '../../../context/userPreferenceContext'
import { useTokenPrices } from '../../../store/Prices/hooks'
import { CrossChainCase } from '../../../constants/crossChain'

const CrossChainTxnInputBox = () => {
  const [recoverTxnHashInput, setRecoverTxnHashInput] = useState('')
  const [txnInputWarning, setTxnInputWarning] = useState<
    'INVALID_ADDRESS' | 'DUPLICATED_TXN' | null
  >(null)
  const { chainId: fromChainId, account } = useWeb3()
  const { txList, appendCrossChainSwapTx } = useCrossChainSwapTx()
  const tokenPrices = useTokenPrices()
  const [isLoading, setIsLoading] = useState(false)
  const {
    userPreference: { slippage },
  } = useUserPreference()

  const handleRecoverTxnHashInputOnchange = (value: string) => {
    setRecoverTxnHashInput(value)
    setTxnInputWarning(null)
  }

  const handleDecodeTxnData = async (txnInputData: string, numOfEvents: number) => {
    if (!account) return
    const data = await getRelayStatusAndEndpoint(fromChainId, recoverTxnHashInput)

    /** Decode SwapCreditForTokens Input data */
    if (numOfEvents === 3) {
      const decodedSwapCreditForTokensData = await getDecodedSwapCreditForTokensCrossChainInputData(
        txnInputData,
        fromChainId,
        slippage
      )
      const status = await getCaseAndMetaData(data.status, decodedSwapCreditForTokensData.toChainId)
      if (decodedSwapCreditForTokensData) {
        appendCrossChainSwapTx({
          isCreditToToken: true,
          fromChainId,
          fromTxhash: recoverTxnHashInput,
          receiverValue: decodedSwapCreditForTokensData.receiverValue,
          toChainId: decodedSwapCreditForTokensData.toChainId,
          toTokenAmount: decodedSwapCreditForTokensData.toTokenAmount,
          toTokenSymbol: decodedSwapCreditForTokensData.toTokenSymbol,
          userAddress: account,
          fromCreditAmount: decodedSwapCreditForTokensData.fromCreditAmount,
          status: status.case ?? CrossChainCase.PENDING,
        })
      }
      return
    }

    /** Decode SwapTokensForTokens Input data */
    if (numOfEvents === 5 || numOfEvents === 6) {
      const decodedSwapTokensForTokensData = await getDecodedSwapTokensForTokensCrossChainInputData(
        txnInputData,
        fromChainId,
        tokenPrices
      )

      if (decodedSwapTokensForTokensData) {
        const status = await getCaseAndMetaData(
          data.status,
          decodedSwapTokensForTokensData.toChainId
        )
        appendCrossChainSwapTx({
          fromChainId,
          toChainId: decodedSwapTokensForTokensData.toChainId,
          fromTokenSymbol: decodedSwapTokensForTokensData.fromTokenSymbol,
          toTokenSymbol: decodedSwapTokensForTokensData.toTokenSymbol,
          fromTokenAmount: decodedSwapTokensForTokensData.fromTokenAmount,
          toTokenAmount: decodedSwapTokensForTokensData.toTokenAmount,
          fromTxhash: recoverTxnHashInput,
          userAddress: account,
          receiverValue: decodedSwapTokensForTokensData.receiverValue,
          isCreditToToken: false,
          status: status.case ?? CrossChainCase.PENDING,
        } as InputTxData)
      }
    }
  }

  const handleEnterBtnOnClick = async () => {
    try {
      setIsLoading(true)
      // Transaction hash validity checking
      const chainInfo = chainInfos[fromChainId]
      const provider = new ethers.providers.JsonRpcProvider(chainInfo.rpcUrls.default.http[0])
      const transactionReceipt = await provider.getTransactionReceipt(recoverTxnHashInput)

      if (transactionReceipt) {
        const transaction = await provider.getTransaction(recoverTxnHashInput)
        const txnInputData = transaction.data

        const { from: msgSender } = transactionReceipt
        /** Check msg sender */
        if (msgSender !== account) return
        const isContainInTxnList = txList.some(
          (tx) => tx.fromTxhash.toLowerCase() === recoverTxnHashInput.toLowerCase()
        )
        if (isContainInTxnList) {
          setTxnInputWarning('DUPLICATED_TXN')
          return
        }
        await handleDecodeTxnData(txnInputData, transactionReceipt.logs.length)
      }
    } catch (error) {
      console.error(error)
      setTxnInputWarning('INVALID_ADDRESS')
    } finally {
      setIsLoading(false)
      setRecoverTxnHashInput('')
    }
  }

  return (
    <div className="mb-3 flex flex-col">
      <div className="flex items-center gap-2">
        <input
          type="text"
          inputMode="text"
          placeholder="Paste your transaction hash to check your transfer"
          className={`rounded border-1 ${
            txnInputWarning ? 'border-wombatRed' : 'border-wombatBrown1'
          }  h-[40px] w-[80%] bg-transparent px-2 font-Helvetica text-[12px] text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none `}
          value={recoverTxnHashInput}
          onChange={(e) => handleRecoverTxnHashInputOnchange(e.target.value)}
        />
        <WButton
          variant={Variant.PURPLE}
          width="w-full"
          height="h-10"
          onClick={handleEnterBtnOnClick}
          isLoading={isLoading}
          className="w-[20%]"
          disabled={!recoverTxnHashInput}
          enableDimmed
        >
          Enter
        </WButton>
      </div>
      {txnInputWarning === 'INVALID_ADDRESS' && (
        <div className="flex text-[12px] text-wombatRed">Invalid Transaction Hash</div>
      )}
      {txnInputWarning === 'DUPLICATED_TXN' && (
        <div className="flex text-[12px] text-wombatRed">Transaction is already existed</div>
      )}
    </div>
  )
}

export default CrossChainTxnInputBox

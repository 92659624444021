import Image from 'next/image'
import React, { useEffect, useRef } from 'react'
import useBreakpoints from '../../hooks/useBreakpoints'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import useNavbarSize from '../../hooks/useNavbarSize'
import ArrowBack from '../../public/assets/icons/arrow-back-ios.svg'
import {
  default as ButtonClose,
  default as CloseIcon,
} from '../../public/assets/icons/button-close.svg'

export type ModalProps = {
  isOpen: boolean
  title?: React.ReactNode
  isCenterTitle?: boolean
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
  width?: number
  height?: number
  isOverflowHidden?: boolean
  isShowBackButton?: boolean
  isHideMobileNav?: boolean
  variant?: 'primary' | 'secondary' | 'bordered'
  disableOutsideClick?: boolean
  hideButtonClose?: boolean
  disableScrolling?: boolean
  topRightIcon?: React.ReactNode
  onTopRightIconOnClick?: () => void
}

export default function Modal({
  isOpen,
  title,
  isCenterTitle = false,
  onClose,
  children,
  width,
  height,
  isOverflowHidden = false,
  isShowBackButton = true,
  isHideMobileNav = false,
  variant = 'secondary',
  disableOutsideClick = false,
  hideButtonClose = false,
  disableScrolling = false,
  topRightIcon,
  onTopRightIconOnClick,
}: ModalProps) {
  const { isMd: isMobile } = useBreakpoints()
  const {
    desktop: { height: navbarHeight },
    mobile: { height: mobileNavbarHeight },
  } = useNavbarSize()
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    if (disableOutsideClick) return
    onClose()
  })

  /**
   * Styles for mobile
   */
  useEffect(() => {
    /**@todo refactor modal */
    const navbarElement = document.getElementById('navbar') as HTMLDivElement
    if ((isOpen && isMobile) || (isOpen && disableScrolling)) {
      document.body.style.overflow = 'hidden'
      if (navbarElement && !disableScrolling) {
        navbarElement.style.position = 'sticky'
        navbarElement.style.top = '0'
        navbarElement.style.zIndex = '30'
        navbarElement.style.background = '#FAF8FB'
      }
    }
    return () => {
      document.body.style.overflow = 'initial'
      if (navbarElement) {
        navbarElement.style.position = 'relative'
        navbarElement.style.zIndex = 'initial'
        navbarElement.style.background = 'unset'
      }
    }
  }, [isOpen, isMobile, disableScrolling])
  if (!isOpen) return <></>

  const variantStyles = ((): { className: string; style?: React.CSSProperties } => {
    switch (variant) {
      case 'primary':
        return { className: 'bg-wombatCream border-4' }
      case 'secondary':
        return {
          className: 'bg-white',
          style: {
            filter: 'drop-shadow(0px 8px 16px rgba(182, 162, 141, 0.5))',
          },
        }
      case 'bordered':
        return { className: 'border-wombatPurple1 border-2 bg-white' }
      default:
        return { className: '' }
    }
  })()
  return (
    <>
      {!isMobile ? (
        <div
          id="transparentBackground"
          className="fixed left-0 right-0 top-0 z-[12] flex h-screen w-full items-center justify-center  p-8 shadow-lg"
        >
          <div
            ref={wrapperRef}
            className={`flex flex-col ${
              isOverflowHidden ? 'overflow-hidden' : ''
            } rounded-3xl p-6 ${width ? '' : 'w-full'} max-w-lg border-wombatPurple1 ${
              variantStyles.className
            } space-y-4`}
            style={{
              width: width + 'px',
              minHeight: height + 'px',
              ...variantStyles.style,
            }}
          >
            <div className="flex items-center justify-end rounded-t ">
              {topRightIcon && (
                <button
                  className="button-hover-opacity flex items-center p-2"
                  onClick={onTopRightIconOnClick}
                >
                  {topRightIcon}
                </button>
              )}
              <button className="button-hover-opacity -m-2 w-10 p-2" onClick={onClose}>
                <Image src={ButtonClose} height={12} width={12} />
              </button>
            </div>
            {title && (
              <div
                className={`!mt-0 font-Work text-base font-semibold ${
                  isCenterTitle ? 'text-center' : ''
                }`}
              >
                {title}
              </div>
            )}
            {children}
          </div>
        </div>
      ) : (
        <div
          className="fixed left-0 bottom-0 z-20 w-full bg-white px-8 py-8"
          style={{
            top: `${navbarHeight}px`,
            height: `calc(100vh - ${
              !isHideMobileNav ? mobileNavbarHeight : 0
            }px - ${navbarHeight}px)`,
          }}
        >
          <div className="absolute top-0 left-0 bottom-0 z-10 h-full w-full space-y-4 overflow-scroll bg-wombatBackgroundMobile bg-cover bg-center bg-no-repeat px-8 py-8 ">
            <div
              className={`mb-6 flex items-center ${isShowBackButton ? '' : 'flex-row-reverse'} `}
            >
              {!hideButtonClose && (
                <button className="button-hover-opacity flex items-center" onClick={onClose}>
                  {isShowBackButton ? (
                    <div className="absolute left-3 flex items-center">
                      <div className="flex items-center">
                        <Image src={ArrowBack} width={6} height={12} alt="" />
                      </div>
                      <div className="ml-1 font-Work text-sm font-semibold text-wombatGrey">
                        BACK
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Image src={CloseIcon} width={12} height={12} />
                    </div>
                  )}
                </button>
              )}
              <h1 className="m-auto font-Work text-base font-semibold">{title}</h1>
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  )
}

import Image from 'next/image'
import { chainInfos } from '../../constants/web3/chains'
import { TokenInfo } from '../PoolPage'
import clsx from 'clsx'
import AppTooltip from '../Tooltip/AppTooltip'
import MoreIcon from '../../public/assets/icons/more.svg'

export const SupportedChains = ({
  isMobile,
  tokenInfo,
}: {
  isMobile?: boolean
  tokenInfo: TokenInfo
}) => {
  const MAX_ITEMS = 4
  const slicedSupportedChainIds = tokenInfo.supportedChainIds.slice(0, MAX_ITEMS)
  const remainingChainIds = tokenInfo.supportedChainIds.slice(MAX_ITEMS)

  return (
    <div
      className={clsx(
        'mr-3 flex flex-wrap items-center gap-1',
        isMobile ? 'mb-2 justify-end px-4' : 'justify-center'
      )}
    >
      {slicedSupportedChainIds.map((chainId) => {
        const chainInfo = chainInfos[chainId]

        return (
          <div key={chainId}>
            <Image layout="fixed" src={chainInfo.icon} width={22} height={22} />
            {/* <AppTooltip message={chainInfo.label} place="top">
            </AppTooltip> */}
          </div>
        )
      })}
      {tokenInfo.supportedChainIds.length > MAX_ITEMS && (
        <AppTooltip
          message={
            <div className="flex flex-col gap-2">
              {remainingChainIds.map((chainId) => {
                const chainInfo = chainInfos[chainId]

                return (
                  <div key={chainId} className="flex items-center gap-2">
                    <Image layout="fixed" src={chainInfo.icon} width={20} height={20} />
                    <span>{chainInfo.label}</span>
                  </div>
                )
              })}
            </div>
          }
        >
          <Image layout="fixed" src={MoreIcon} />
        </AppTooltip>
      )}
    </div>
  )
}

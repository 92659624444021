import React, { createContext, useCallback, useContext, useState } from 'react'
import { ModalId, ModalState } from '../interfaces/modal'

type ModalContextType = {
  modalState: ModalState
  actions: {
    openModal: (modalState: ModalState) => void
    closeModal: () => void
  }
}
const ModalContext = createContext<ModalContextType>({} as ModalContextType)
ModalContext.displayName = 'ModalContext'

type Props = {
  children: React.ReactNode
}

export const useModalContext = () => {
  return useContext(ModalContext)
}
function ModalProvider({ children }: Props) {
  const [modalState, setModalState] = useState<ModalState>({
    currentModalId: ModalId.UNSET,
  })

  const openModal = useCallback((modalState: ModalState) => {
    setModalState(modalState)
  }, [])

  const closeModal = useCallback(() => {
    setModalState({
      currentModalId: ModalId.UNSET,
    })
  }, [])
  return (
    <ModalContext.Provider
      value={{
        modalState,
        actions: {
          openModal,
          closeModal,
        },
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider

import { createAsyncThunk } from '@reduxjs/toolkit'
import { TokenPricesState } from '../slice'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { fetchCoingeckoPriceData } from '../../../context/MulticallDataContext/helpers/fetchTokenPricesHelper'

const getCoingeckoPriceData = createAsyncThunk<
  Pick<TokenPricesState, 'coingeckoPrices'>,
  {
    chainId: SupportedChainId
  }
>('tokenPrices/getCoingeckoPriceData', async ({ chainId }) => {
  const res = await fetchCoingeckoPriceData([chainId])
  return {
    coingeckoPrices: res || {},
  }
})

export default getCoingeckoPriceData

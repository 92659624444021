import { commify } from '../../utils'
import commafy from '../../utils/commafy'
import { getMillifiedFormat } from '../../utils/numberFormat'
type Props = {
  fixedDigits?: number | null
  amount: string | number
  millified?: boolean
}

export default function TooltipNum({ amount, millified, fixedDigits }: Props) {
  function getToolTipFormat() {
    if (!amount) return '0.00'
    if (amount === 'string') return commify(amount)
    return commify(amount.toString())
  }

  return (
    <span className="relative sm:mx-auto sm:max-w-xl">
      <div className="group relative inline-block cursor-pointer ">
        {Number(amount) === 0
          ? '0.00'
          : millified
          ? Number(amount) < 0.01
            ? '< 0.01'
            : getMillifiedFormat(Number(amount))
          : commafy(Number(amount).toFixed(fixedDigits ?? 2))}

        <div className="w-50 pointer-events-none absolute bottom-full -left-1/2 z-10 ml-14 rounded-lg bg-wombatBrown1 py-2 px-3 text-center text-sm text-white opacity-0 group-hover:opacity-100">
          {getToolTipFormat()}
        </div>
      </div>
    </span>
  )
}

import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { createSlice } from '@reduxjs/toolkit'
import getCoingeckoPriceData from './thunks/getCoingeckoPriceData'
import getPythPriceData from './thunks/getPythPriceData'
import getUnitPriceData from './thunks/getUnitPriceData'
import fetchTokenPrices from './thunks/fetchTokenPrices'

export interface TokenPricesState {
  tokenPrices: { [token in TokenSymbol]?: string }
  exchangeRatesFromUniswapV3: {
    [id in TokenSymbol]?: { rate: string; pairTokenSymbol: TokenSymbol }
  }
  pythPrices: {
    [id in TokenSymbol]?: string
  }
  coingeckoPrices: {
    [id in TokenSymbol]?: string
  }
}

export const initialState: TokenPricesState = {
  tokenPrices: {},
  exchangeRatesFromUniswapV3: {},
  pythPrices: {},
  coingeckoPrices: {},
}

const tokenPricesSlice = createSlice({
  name: 'tokenPrices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoingeckoPriceData.fulfilled, (state, action) => {
      state.coingeckoPrices = {
        ...state.coingeckoPrices,
        ...action.payload.coingeckoPrices,
      }
    })

    builder.addCase(getPythPriceData.fulfilled, (state, action) => {
      state.pythPrices = {
        ...state.pythPrices,
        ...action.payload.pythPrices,
      }
    })

    builder.addCase(getUnitPriceData.fulfilled, (state, action) => {
      state.exchangeRatesFromUniswapV3 = {
        ...state.exchangeRatesFromUniswapV3,
        ...action.payload.exchangeRatesFromUniswapV3,
      }
    })

    builder.addCase(fetchTokenPrices.fulfilled, (state, action) => {
      state.tokenPrices = {
        ...state.tokenPrices,
        ...action.payload.tokenPrices,
      }
    })
  },
})

export default tokenPricesSlice.reducer

/* Author: Dalibor Kundrat  https://github.com/damikun */

import clsx from 'clsx'
import Image from 'next/image'
import { useCallback, useEffect, useState } from 'react'
import { usePrevious } from '../../hooks/usePrevious'
import ViewOnIcon from '../../public/assets/icons/blank-page.svg'
import ButtonClose from '../../public/assets/icons/button-close.svg'
import { getExplorerLink } from '../../utils/getBscScanLink'
import { useNetwork } from 'wagmi'
import { Toast } from '../../store/Toast/actions'

const VARIANTS = {
  Info: {
    base: 'bg-wombatCream border-blue-500',
    text: 'text-blue-500 ',
    name: 'Info',
    countDown: 'bg-blue-500',
  },

  Error: {
    base: 'bg-wombatCream',
    text: 'text-wombatRed',
    name: 'Error',
    countDown: 'bg-wombatRed',
  },

  Warning: {
    base: 'bg-wombatCream border-yellow-500',
    text: 'text-yellow-500 ',
    name: 'Warning',
    countDown: 'bg-yellow-500',
  },

  Success: {
    base: 'bg-wombatCream',
    text: 'text-wombatPurple',
    name: 'Success',
    countDown: 'bg-wombatPurple',
  },
}

export type Truncate = 'truncate-1-lines' | 'truncate-2-lines' | 'truncate-3-lines'

export type ToastMessage = {
  id: string
  lifetime?: number
  variant?: keyof typeof VARIANTS | undefined
  onRemove?: (id: string) => void
  truncate?: Truncate
  childrenButton?: string
  handleClickButton?: () => void
} & Toast

export default function ToastMessage({
  id,
  header,
  title,
  message,
  txHash,
  lifetime,
  onRemove,
  truncate = 'truncate-1-lines',
  icon,
  type,
  childrenButton,
  handleClickButton,
}: ToastMessage) {
  const Var = type
    ? VARIANTS[type]
    : {
        base: 'bg-white border-gray-600 ',
        text: '',
        icon: icon,
        name: header,
        countDown: 'bg-white',
      }

  const { chain } = useNetwork()
  const [isHover, setIsHover] = useState(false)
  const isPreviousHover = usePrevious(isHover)
  const [timer, setTimer] = useState<NodeJS.Timeout>()

  const showToast = useCallback(
    (lifeTime: number) => {
      if (!onRemove) return

      const timerId = setTimeout(() => {
        onRemove(id)
        setTimer(undefined)
      }, lifeTime)
      setTimer(timerId)
    },
    [id, onRemove]
  )

  // show toast at the beginning
  useEffect(() => {
    if (lifetime) {
      showToast(lifetime)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
        setTimer(undefined)
      }
    }
    // only call once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, lifetime, showToast])

  useEffect(() => {
    // clear timeout when user hover toast
    if (isHover && timer) {
      clearTimeout(timer)
      setTimer(undefined)
    }
  }, [isHover, timer])

  useEffect(() => {
    // when user unhover toast, show toast again
    if (!isHover && isPreviousHover) {
      showToast(3000)
    }
  }, [id, isHover, isPreviousHover, showToast])

  const viewOnButton = (
    <button
      onClick={() => {
        if (chain) {
          window.open(getExplorerLink(txHash, 'transaction', chain.id))
        }
      }}
      className={clsx(
        'button-hover-opacity flex w-full items-center justify-center',
        txHash ? '' : 'invisible'
      )}
    >
      <Image src={ViewOnIcon} height={16} width={16} />
    </button>
  )
  return (
    <div
      className={clsx(
        'visible flex w-full flex-row opacity-95 shadow-lg',
        'rounded-md duration-100',
        'hover:scale-102 transform py-1 transition',
        Var.base,
        type && 'max-h-40'
      )}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="flex-no-wrap flex w-full flex-row p-2">
        <div className="flex-no-wrap flex w-full flex-col px-1">
          <div
            className={clsx(
              'my-auto flex select-none justify-center font-Work font-semibold',
              Var.text,
              'mb-1'
            )}
          >
            {title} {!message && <span className="ml-2 flex justify-center">{viewOnButton}</span>}
          </div>
          <div className="mb-1 flex flex-row justify-around align-middle">
            {typeof message === 'string' ? (
              <p
                className={clsx(
                  'my-auto flex',
                  Var.text,
                  'font-Helvetican text-center text-sm',
                  typeof message === 'string' && truncate
                )}
              >
                {message}
              </p>
            ) : (
              message
            )}
            {childrenButton && (
              <button
                className={clsx(
                  'my-auto flex break-all rounded-md px-3 py-2',
                  'button-hover-opacity-light font-Work text-sm font-semibold text-white',
                  Var.countDown
                )}
                onClick={handleClickButton}
              >
                {childrenButton}
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          'absolute right-0 mx-auto h-full w-10 items-center py-3',
          'text-center text-lg leading-none'
        )}
      >
        <div className="flex h-full flex-col justify-between">
          <button
            className="button-hover-opacity float-right border-0 bg-transparent text-black"
            onClick={() => onRemove && onRemove(id)}
          >
            <Image alt={''} src={ButtonClose} height={12} width={12} />
          </button>

          {message && viewOnButton}
        </div>
      </div>
      <span
        className={clsx(
          'absolute bottom-0 h-1 w-full rounded-b-md bg-black',
          Var.countDown,
          'animate-toast-count-down'
        )}
      ></span>
    </div>
  )
}

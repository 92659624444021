import PoolContainer from '../PoolContainer'
import { POOL_STATE } from '../../PoolPage/index'
import Withdraw from './Withdraw'
import { MODAL_STATE, useWithdrawalPageContext } from '../WithdrawalPageContext'
import { TOKENS } from '../../../constants/contract'
import { ASSETS } from '../../../constants/contract/asset'
import Unstake from './Unstake'
import { POOLS } from '../../../constants/contract/pool'
import { useWeb3 } from '../../../context/Web3Context'
import { StandalonePools } from '../../../constants/contract/standalonePool'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import UnstakeMW2 from './UnstakeMW2'

export const Modal: React.FC = () => {
  const {
    modalState,
    setModalState,
    selectedAssetTokenSymbol,
    selectedPoolLabel,
    selectedStandalonePoolAddress,
  } = useWithdrawalPageContext()
  const { chainId } = useWeb3()
  const handleRedirect = () => setModalState(null)
  const selectedPool = selectedPoolLabel ? POOLS[chainId][selectedPoolLabel] : null
  const standalonePools = StandalonePools[chainId]?.filter(
    (p) => p.address === selectedStandalonePoolAddress
  )
  const standalonePool = standalonePools ? standalonePools[0] : StandalonePools[chainId]?.[0]
  const pool = selectedPool?.label !== PoolLabels.BNB ? standalonePool : selectedPool
  const asset =
    selectedPoolLabel && selectedAssetTokenSymbol
      ? ASSETS[chainId][selectedPoolLabel][selectedAssetTokenSymbol]
      : null
  const token = selectedAssetTokenSymbol ? TOKENS[chainId][selectedAssetTokenSymbol] || null : null
  return (
    <>
      {selectedPool && asset && token ? (
        <>
          {pool && modalState === MODAL_STATE.WITHDRAW && (
            <PoolContainer nav={[POOL_STATE.WITHDRAW]}>
              <Withdraw
                redirect={handleRedirect}
                poolLabel={selectedPool.label}
                poolForOperation={pool}
                asset={asset}
                token={token}
              />
            </PoolContainer>
          )}
          {modalState === MODAL_STATE.UNSTAKE && (
            <PoolContainer nav={[POOL_STATE.UNSTAKE]}>
              <Unstake asset={asset} redirect={handleRedirect} pool={selectedPool} />
            </PoolContainer>
          )}
          {modalState === MODAL_STATE.UNSTAKE_MW2 && (
            <PoolContainer nav={[POOL_STATE.UNSTAKE]}>
              <UnstakeMW2 asset={asset} redirect={handleRedirect} pool={selectedPool} />
            </PoolContainer>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

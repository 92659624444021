import { MasterWombatState } from './types'
import { getInitialAssetProperty } from '../Asset/helpers'
import { BigNumber } from 'ethers'
import { UserInfo } from '../../interfaces/masterWombat'
import { createSlice } from '@reduxjs/toolkit'
import fetchInterestBearingApr from './thunks/fetchInterestBearingApr'
import fetchFeeSharingAprs from './thunks/fetchFeeSharingAprs'
import updateExtraRewards from './thunks/updateExtraRewards'

const initialState: MasterWombatState = {
  bonusTokenAprs: getInitialAssetProperty<BigNumber[]>(),
  averageBoostedAprs: getInitialAssetProperty<string>(),
  baseAprWads: getInitialAssetProperty<BigNumber>(),
  userInfos: getInitialAssetProperty<UserInfo>(),
  boostedAprs: getInitialAssetProperty<BigNumber>(),
  avgFeeSharingAprs: getInitialAssetProperty(),
  allBonusTokenRewards: {
    pendingRewards: BigNumber.from(0),
    pendingBonusRewards: [],
    bonusTokenSymbols: [],
    assetsWithNonZeroRewards: [],
  },
  interestBearingAprs: {},
}

const masterWombatSlice = createSlice({
  name: 'masterWombat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInterestBearingApr.fulfilled, (state, action) => {
      state.interestBearingAprs = {
        ...action.payload.interestBearingAprs,
      }
    })
    builder.addCase(fetchInterestBearingApr.rejected, (state, action) => {
      console.log('fetchInterestBearingApr', action.error)
    })

    builder.addCase(fetchFeeSharingAprs.fulfilled, (state, action) => {
      state.avgFeeSharingAprs = {
        ...action.payload.avgFeeSharingAprs,
      }
    })

    builder.addCase(updateExtraRewards.fulfilled, (state, action) => {
      state.bonusTokenAprs = {
        ...action.payload.bonusTokenAprs,
      }
    })
  },
})

export default masterWombatSlice.reducer

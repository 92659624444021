import clsx from 'clsx'
import Image from 'next/image'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { Asset } from '../../constants/contract/asset/Asset'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import arrowUp from '../../public/assets/icons/arrow-up.svg'
import { commafyPercentage } from '../../utils/commafy'
import { TokenInfo } from '../PoolPage/index'
import TokenImage from '../TokenImage'
import { MedianTotalAprTooltip } from './medianTotalAprTooltip'
import { POOLS } from '../../constants/contract/pool'
import DesktopInfo from './desktopInfo'
import MobileInfo from './mobileInfo'
import { useMasterWombatData } from '../../store/MulticallData/hooks'

interface ChildrenProp {
  depositedOrStaked: boolean | null
  DISCONNECT: boolean
  hasStaked: boolean | null
  poolLabels: PoolLabels
  tokenInfo: TokenInfo
  chainId: SupportedChainId
  asset: Asset
  openExpand: boolean
  handleClickExpand: () => void
}

export const Children: React.FC<ChildrenProp> = ({
  depositedOrStaked,
  DISCONNECT,
  hasStaked,
  tokenInfo,
  chainId,
  asset: token,
  openExpand,
  handleClickExpand,
}) => {
  const medianTotalAprUi = commafyPercentage(tokenInfo.medianTotalApr ?? 0)

  const womRewardUi = tokenInfo.reward ? `${tokenInfo.reward?.toString()}` : '0.00'

  const avgDailyFeeSharingAprUi = tokenInfo.avgFeeSharingApr.daily
    ? commafyPercentage(Number(tokenInfo.avgFeeSharingApr.daily) / 100)
    : '-'

  const poolName = POOLS[chainId][tokenInfo.poolLabel]
    ? POOLS[chainId][tokenInfo.poolLabel]?.name
    : ''

  /**@todo open weekly avg sharing apr */
  // const avgWeeklyFeeSharingAprUi = tokenInfo.avgFeeSharingApr.weekly
  //   ? commafyPercentage(Number(tokenInfo.avgFeeSharingApr.weekly) / 100)
  //   : '-'

  function getTotalAprUi() {
    // if user is staked, show my total apr
    if (tokenInfo.myTotalApr && hasStaked && !DISCONNECT)
      return commafyPercentage(tokenInfo.myTotalApr)
    // if user is deposited or staked, show avg fee sharing apr only
    if (tokenInfo.avgFeeSharingApr && depositedOrStaked) return avgDailyFeeSharingAprUi
    return '-'
  }

  const masterWombatData = useMasterWombatData()
  const rewarderBalanceArr =
    masterWombatData.withoutAccount?.poolRewarderBalances[tokenInfo.poolLabel][tokenInfo.symbol]

  return (
    <div
      onClick={handleClickExpand}
      className={clsx(
        'relative cursor-pointer border-wombatPurple2',
        'select-none py-3.5 px-0 text-black md:py-5 md:pl-4 lg:pl-10',
        depositedOrStaked ? 'bg-wombatYellow3' : 'border-wombatPurple2 bg-white',
        openExpand ? 'border-b-1 border-b-wombatPurple3 md:border-b' : ''
      )}
      id={`pool-${token.symbol}-expand`}
    >
      {/* mobile */}
      <div className="block md:hidden">
        <div className="flex items-center pb-3.5">
          <div className="flex w-full justify-between border-wombatPurple3 px-4 leading-[20px]">
            <div className="flex items-center">
              <TokenImage tokenSymbol={tokenInfo.symbol} width={'30'} height={'30'} />
              <div className="ml-2 flex flex-col">
                <div className="font-bold">{tokenInfo.symbol}</div>
                <div className="text-[14px] text-wombatBrown1">{poolName}</div>
              </div>
            </div>
            <div className="ml-2 flex flex-col text-right">
              <div className="text-[12px] text-wombatBrown1">Avg. APR</div>
              <div className="relative font-bold text-wombatPurple1">
                {medianTotalAprUi}
                <MedianTotalAprTooltip
                  DISCONNECT={DISCONNECT}
                  tokenInfo={tokenInfo}
                  depositedOrStaked={depositedOrStaked}
                  hasStaked={hasStaked}
                  isMobile
                />
              </div>
            </div>
          </div>
        </div>
        <MobileInfo
          chainId={chainId}
          tokenInfo={tokenInfo}
          asset={token}
          depositedOrStaked={depositedOrStaked}
          DISCONNECT={DISCONNECT}
          hasStaked={hasStaked}
          myTotalAprUi={getTotalAprUi()}
          womRewardUi={womRewardUi}
          rewarderBalanceArr={rewarderBalanceArr}
        />
        <div
          style={{ transform: `${!openExpand ? 'rotate(180deg)' : ''}` }}
          className="flex justify-center"
        >
          <Image alt={''} src={arrowUp} />
        </div>
      </div>
      {/* desktop */}
      <div className="hidden grid-cols-[32px,90px,1fr,115px,1fr,1fr,1fr,30px] items-center gap-1 md:grid lg:grid-cols-[32px,1fr,1fr,115px,,1fr,1fr,1fr,44px]">
        <DesktopInfo
          chainId={chainId}
          tokenInfo={tokenInfo}
          asset={token}
          depositedOrStaked={depositedOrStaked}
          DISCONNECT={DISCONNECT}
          hasStaked={hasStaked}
          medianTotalAprUi={medianTotalAprUi}
          myTotalAprUi={getTotalAprUi()}
          womRewardUi={womRewardUi}
          rewarderBalanceArr={rewarderBalanceArr}
        />
        <div
          style={{
            transform: `${!openExpand ? 'rotate(180deg)' : ''}`,
          }}
          className="m-auto transition-transform"
        >
          <Image src={arrowUp} alt="" />
        </div>
      </div>
    </div>
  )
}

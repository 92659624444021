import { BigNumber, ContractTransaction } from 'ethers'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContract } from 'wagmi'
import { LATEST_MASTER_WOMBATS } from '../constants/contract'
import { MASTER_WOMBAT_V3_ABI } from '../constants/contract/abis/masterWombat'
import { Asset } from '../constants/contract/asset/Asset'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../context/errorMessage'
import { useWeb3 } from '../context/Web3Context'
import { calculateGasMargin } from '../utils'
import { DELAY_TIME_ANIMATION_GIF } from '../constants/common'
import { useTxnReceipt } from './../context/TxnReceiptProvider'
import { useAppDispatch } from '../store/hooks'
import { addErrorToast, addSuccessToast } from '../store/Toast/actions'

export enum HandleStakeState {
  IDLE,
  LOADING,
  SUBMITED,
  FAILED,
  SUCCESS,
}

export default function useHandleStake(
  currentPoolLabel: PoolLabels,
  asset: Asset,
  amount: BigNumber | null,
  onTxnSubmited: (txn: ContractTransaction) => void,
  data: string
) {
  const [stakeState, setStakeState] = useState(HandleStakeState.IDLE)
  const [txnHash, setTxnHash] = useState('')
  const [isDisplayGifFlyToTheMoon, setIsDisplayGifFlyToTheMoon] = useState(false) // used for changing gif FlyToTheMoon
  const { refreshTxnReceipt } = useTxnReceipt()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { signer, chainId } = useWeb3()
  // add abi for solving type error (masterWombat abi is V2|V3)
  const masterWombatContract = useContract({
    ...LATEST_MASTER_WOMBATS[chainId].get(),
    abi: MASTER_WOMBAT_V3_ABI,
    signerOrProvider: signer,
  })
  async function tryHandleStake(setTokenAmountForDisplay: () => void) {
    const pidBn = BigNumber.from(asset.pid)
    if (!masterWombatContract || !amount) {
      console.error(
        '@tryHandleStake:\n' +
          `token: ${asset.symbol}\n` +
          `MasterWombat: ${!!masterWombatContract}\n` +
          `pid: ${asset.pid}`
      )
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setStakeState(HandleStakeState.LOADING)

    masterWombatContract.estimateGas
      .deposit(pidBn, amount)
      .then((estimateGas) =>
        masterWombatContract.deposit(pidBn, amount, {
          gasLimit: calculateGasMargin(estimateGas),
        })
      )
      .then((txn) => {
        onTxnSubmited(txn)
        setTxnHash(txn.hash)
        setTokenAmountForDisplay()
        setIsDisplayGifFlyToTheMoon(true) // USED FOR CHANGING GIF FLY TO THE MOON
        setTimeout(() => {
          setStakeState(HandleStakeState.SUBMITED)
          setIsDisplayGifFlyToTheMoon(false)
        }, DELAY_TIME_ANIMATION_GIF) // SHOW MODAL SUBMITTED AFTER GIF FLY TO THE MOON OUTSIDE
        txn
          .wait()
          .then(() => {
            setStakeState(HandleStakeState.SUCCESS)
            refreshTxnReceipt()
            dispatch(
              addSuccessToast({
                message: data,
                title: 'Stake Completed',
                txHash: txn.hash,
                childrenButton: 'Lock WOM',
                handleClickButton: () => navigate('/boost'),
              })
            )
          })
          .catch(() => {
            dispatch(
              addErrorToast({
                message: data,
                title: 'Stake Failed',
                txHash: txn.hash,
              })
            )
            setStakeState(HandleStakeState.FAILED)
          })
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
        if (JSON.stringify(error).includes(code)) {
          setStakeState(HandleStakeState.IDLE)
          const errorMessage = ErrorMessages[code]
          dispatch(
            addErrorToast({
              message: errorMessage.message,
              title: errorMessage.title,
            })
          )
        } else {
          dispatch(
            addErrorToast({
              message: data,
              title: 'Stake Failed',
              txHash: txnHash,
            })
          )
          setStakeState(HandleStakeState.FAILED)
          const errInfo =
            '@MasterWombat.deposit\n' +
            `_pid: ${pidBn.toString()}\n` +
            `_amount: ${amount.toString()}\n` +
            `txHash: ${txnHash}\n`
          console.error(errInfo, error)
        }
      })
  }

  return { stakeState, tryHandleStake, txnHash, isDisplayGifFlyToTheMoon }
}

import clsx from 'clsx'
import Image from 'next/image'
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ArrowDownIcon from '../../../public/assets/icons/toggle-down.svg'
import ArrowUpIcon from '../../../public/assets/icons/toggle-up.svg'
import * as ReactDOM from 'react-dom'
import ScrollableBox from '../../ScrollableBox'
import { useWindowDimension } from '../../../hooks/useWindowDimensions'
import { uniqueId } from 'lodash'
import useDidClickOutside from '../../../hooks/useDidClickOutside'
import { useDebounce } from '../../../hooks/useDebounce'
import { SearchIcon } from '../../images'
type ContextType = {
  setIsOptionsOpen: React.Dispatch<React.SetStateAction<boolean>>
  enableMultiSelect?: boolean
}
const DropdownInputContext = createContext<ContextType>({} as ContextType)
export type DropdownInputProps = {
  children: React.ReactNode
  placeholderProps: {
    text: React.ReactNode
    // tailwind classname
    color?: 'wombatPurple1'
    icon?: React.ReactNode
    fontStyle?: string
  }
  variant?: 'primary' | 'secondary' | 'borderless' | 'transparent'
  optionsMaxHeight?: string
  width?: string
  height?: string
  searchBarProps?: {
    onSearch: (value: string) => void
  }
  enableMultiSelect?: boolean
  enableUnderline?: boolean
  // enable portal to put the position of dropdown above everything
  enablePortal?: boolean
  disabled?: boolean
  inputStyle?: string
} & React.HTMLAttributes<HTMLDivElement>

const useDropdownInput = () => useContext(DropdownInputContext)
function DropdownInput({
  children,
  placeholderProps,
  variant = 'primary',
  optionsMaxHeight,
  width,
  height,
  searchBarProps,
  enableMultiSelect,
  enableUnderline,
  enablePortal,
  disabled,
  className,
  ...otherProps
}: DropdownInputProps) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const divElement = useMemo(() => document.createElement('div'), [])
  const { height: windowHeight, width: windowWidth } = useWindowDimension()
  const inputContainerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const uuidRef = useRef(uniqueId('dropdown'))
  const [searchInput, setSearchInput] = useState('')
  const debouncedSearchInputValue = useDebounce(searchInput)
  /**
   * close dropdown options when users click other areas
   */
  useDidClickOutside([inputContainerRef, dropdownRef], () => {
    setIsOptionsOpen(false)
  })
  useEffect(() => {
    document.getElementById('__next')?.appendChild(divElement)
    return () => {
      document.getElementById('__next')?.removeChild(divElement)
    }
  }, [divElement, isOptionsOpen])

  const dropdownStyles: React.CSSProperties = useMemo(() => {
    let defaultStyles: React.CSSProperties = {
      // offset the border of the dropdown
      left: variant === 'borderless' ? '0px' : '-1px',
      width: variant === 'borderless' ? '100%' : 'calc(100% + 2px)',
    }
    if (inputContainerRef.current && enablePortal) {
      const borderBottomWidth = +window
        .getComputedStyle(inputContainerRef.current)
        .getPropertyValue('border-bottom-width')
        .replace('px', '')
      const { top, left, width, height } = inputContainerRef.current.getBoundingClientRect()
      const { scrollY, scrollX } = window
      defaultStyles = {
        width: `${width}px`,
        top: `${top + scrollY + height - borderBottomWidth}px`,
        left: `${left + scrollX}px`,
      }
    }
    return defaultStyles
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptionsOpen, windowWidth, windowHeight])

  const toggleIsOptionsOpen = () => {
    setIsOptionsOpen((prev) => !prev)
  }

  const getDropdownStyle = (variant: DropdownInputProps['variant']) => {
    if (variant === 'borderless') return 'border-none'
    if (variant === 'secondary')
      return 'border-wombatPurple1 shadow-[0px_8px_16px_rgba(182,162,141,0.3)]'
    return 'border-wombatBrown1'
  }

  const renderDropdown = () => {
    return (
      <div
        id={'DropdownInput__dropdown' + uuidRef.current}
        ref={dropdownRef}
        style={dropdownStyles}
        className={clsx(
          `absolute z-10 rounded rounded-t-none border-1 border-t-0 bg-white p-2 pt-0 font-Work ${
            isOptionsOpen ? '' : 'hidden'
          } ${optionsMaxHeight ? 'pr-0' : ''} ${getDropdownStyle(variant)}`
        )}
      >
        {enableUnderline && (
          <hr className="absolute top-[-2px] left-[50%] h-0.5 w-[90%] translate-x-[-50%] bg-wombatPurple1" />
        )}
        <ScrollableBox
          {...(optionsMaxHeight && {
            scrollDirection: 'vertical',
            style: { maxHeight: optionsMaxHeight },
          })}
        >
          {children}
        </ScrollableBox>
      </div>
    )
  }

  const getDropdownInputStyle = (variant: DropdownInputProps['variant']) => {
    if (variant === 'transparent')
      return isOptionsOpen ? '!bg-white border-1' : 'bg-transparent border-none'
    if (variant === 'secondary') return 'md:shadow-[0px_8px_16px_rgba(182,162,141,0.3)]'
    if (variant === 'borderless') return 'bg-white border-none font-normal'
    return ''
  }

  // Handle debouncing
  useEffect(() => {
    searchBarProps?.onSearch(debouncedSearchInputValue)
  }, [debouncedSearchInputValue, searchBarProps])
  return (
    <DropdownInputContext.Provider value={{ setIsOptionsOpen, enableMultiSelect }}>
      <div
        id="DropdownInput"
        ref={inputContainerRef}
        className={clsx(
          `relative select-none rounded border-1 border-wombatBrown1 ${getDropdownInputStyle(
            variant
          )}`,
          isOptionsOpen ? 'rounded-b-none border-b-0 bg-white' : '',
          className
        )}
        style={{
          width: width || 'unset',
          height: height || '40px',
        }}
        {...otherProps}
      >
        <button
          className="button-hover-opacity-light flex h-full w-full items-center justify-between px-2"
          // don't want to close the menu when the search bar is clicked
          {...(!searchBarProps && !disabled && { onClick: toggleIsOptionsOpen })}
        >
          {searchBarProps ? (
            // with search bar
            <>
              <SearchIcon className="mr-2" size={14} />

              <input
                type="text"
                placeholder={typeof placeholderProps.text === 'string' ? placeholderProps.text : ''}
                className={clsx(
                  'h-8 w-full rounded-lg bg-transparent font-Work text-sm font-normal text-wombatBrown1 focus:outline-none',
                  'placeholder:text-' + 'text-' + placeholderProps.color
                )}
                value={searchInput}
                onChange={(e) => {
                  e.preventDefault()
                  const value = e.target.value
                  setSearchInput(value)
                }}
                onFocus={() => {
                  // open the menu when users click the input
                  if ((!searchInput.length && !isOptionsOpen) || !isOptionsOpen) {
                    toggleIsOptionsOpen()
                  }
                }}
              />
            </>
          ) : (
            // without search bar
            <div
              className={clsx(
                'flex items-center font-bold',
                'text-' + placeholderProps.color,
                `${
                  placeholderProps.fontStyle
                    ? `${placeholderProps.fontStyle}`
                    : 'font-Jamjuree text-xs'
                }`
              )}
            >
              {placeholderProps.icon && (
                <div className="mr-[6px] flex">{placeholderProps.icon}</div>
              )}
              <p> {placeholderProps.text}</p>
            </div>
          )}

          {!disabled && (
            <div
              className="flex items-center"
              {...(searchBarProps && { onClick: toggleIsOptionsOpen })}
            >
              <Image
                alt={''}
                src={isOptionsOpen ? ArrowUpIcon : ArrowDownIcon}
                width={10}
                height={5}
              />
            </div>
          )}
        </button>
        {!disabled &&
          (enablePortal ? ReactDOM.createPortal(renderDropdown(), divElement) : renderDropdown())}
      </div>
    </DropdownInputContext.Provider>
  )
}

export default DropdownInput

export type OptionProps<T> = {
  children: React.ReactNode
  seperator?: 'primary' | 'secondary'
  value?: T
  onClick?: (value: T | undefined) => void
  className?: string
}

function Option<T>({ children, seperator, value, onClick, className }: OptionProps<T>) {
  const { setIsOptionsOpen, enableMultiSelect } = useDropdownInput()
  return seperator ? (
    <div className={clsx('my-[2px] flex items-center gap-2 text-xs', className)}>
      {seperator === 'primary' && <hr className="h-0.5 grow bg-linearGradientApprove" />}
      <p
        className="w-full bg-linearGradientApprove bg-clip-text"
        style={{ WebkitTextFillColor: 'transparent' }}
      >
        {children}
      </p>
      {seperator === 'primary' && <hr className="h-0.5 grow bg-linearGradientApprove" />}
    </div>
  ) : (
    <button
      className="flex w-full items-center gap-2 py-1 pl-2 text-sm hover:bg-wombatYellow3"
      onClick={() => {
        onClick && onClick(value)
        !enableMultiSelect && setIsOptionsOpen(false)
      }}
    >
      {children}
    </button>
  )
}

DropdownInput.Option = Option

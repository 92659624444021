import React, { useMemo, useState } from 'react'
import ChainSelectionDropdown from '../../ChainSelectionDropdown'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import WButton, { Variant } from '../../WButton'
import BridgeRedeemConfirmationModal from '../BridgeRedeemConfirmationModal/BridgeRedeemConfirmationModal'
import useRedeem from '../../../hooks/bridge/useRedeem'
import { useWeb3 } from '../../../context/Web3Context'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useModalContext } from '../../../context/ModalContext'
import { ModalId } from '../../../interfaces/modal'
import BridgeRedeemCompletedModal from '../BridgeRedeemCompletedModal/BridgeRedeemCompletedModal'
import { BridgeRedeemInfoBox } from './BridgeRedeemInfoBox'
import { useWalletSelected } from '../../../context/WalletSelectedContext'
import { useBridgeRedeemContext } from '../../../context/BridgeRedeemContext'
import { getTokenTransferVaaMetadata } from '../../../utils/bridge'

export const BridgeRedeem = () => {
  const {
    isFetchingSignedVaa,
    isRecoveringTransferReceipt,
    sourceChainId,
    targetChainId,
    bridgedTokenAmount,
    signedVaa,
    receipt,
    recoverTransferReceipt,
    updateSourceChainId,
    fetchIsRedeemCompleted,
  } = useBridgeRedeemContext()

  const { isMd } = useBreakpoints()
  const { chainId, switchNetwork, account } = useWeb3()
  const { open: onOpenModalConnectWallet } = useWalletSelected()
  const {
    handleRedeem,
    isRedeeming,
    isDisplayGifFlyToTheMoon: isDisplayGifFlyToTheMoonForRedeem,
    redeemTransaction,
  } = useRedeem({ targetModalId: ModalId.BRIDGE_REDEEM_REDEEM_WAIT_FOR_CONFIRMATION })

  const {
    modalState: currentModalState,
    actions: { closeModal },
  } = useModalContext()
  const [recoverTxnHashInput, setRecoverTxnHashInput] = useState('')
  const [displayRedeemedWarning, setDisplayRedeemedWarning] = useState(false)
  const [displayRedeemInfoBox, setDisplayRedeemInfoBox] = useState(false)

  const handleRecoveryBtnOnClick = async () => {
    const recoveredSignedVaa = await recoverTransferReceipt(recoverTxnHashInput)
    if (!recoveredSignedVaa) return
    const { targetChainId: newTargetChainId } = getTokenTransferVaaMetadata(recoveredSignedVaa)
    if (!newTargetChainId) return
    setDisplayRedeemInfoBox(true)
    /** switch to target network */
    if (newTargetChainId && chainId !== newTargetChainId) {
      await switchNetwork(newTargetChainId)
    }
  }

  const handleRecoverTxnHashInputOnchange = (value: string) => {
    setRecoverTxnHashInput(value)
    setDisplayRedeemedWarning(false)
    setDisplayRedeemInfoBox(false)
  }

  const handleSourceChainIdOnChange = async (selectedChainId: SupportedChainId) => {
    updateSourceChainId(selectedChainId)
  }

  const handleRedeemBtnOnClick = async () => {
    if (!signedVaa) return
    const isRedeemCompleted = await fetchIsRedeemCompleted(signedVaa)

    if (isRedeemCompleted) {
      setDisplayRedeemedWarning(true)
      return
    }

    const txnHash = await handleRedeem(targetChainId, signedVaa)
    if (txnHash) {
      setRecoverTxnHashInput('')
      setDisplayRedeemInfoBox(false)
    }
  }

  const isRecoveryBtnShown = useMemo(() => {
    if (!signedVaa || !receipt) return true
    if (receipt?.transactionHash !== recoverTxnHashInput) return true
    return false
  }, [receipt, recoverTxnHashInput, signedVaa])

  return (
    <>
      <div className={`flex w-full gap-2 ${isMd ? 'flex-col' : 'flex-row'}`}>
        <div className={`flex flex-row gap-2 ${isMd ? '' : 'w-[80%] gap-2'}`}>
          <div className="relative flex flex-[40%]">
            <ChainSelectionDropdown
              chainId={sourceChainId}
              onSelectionChange={handleSourceChainIdOnChange}
              width="100%"
            />
            <div
              className="absolute top-[-20px]
            "
            >
              <span>Source Chain</span>
            </div>
          </div>

          <div className={`flex w-[60%]  ${isMd ? '' : 'mb-2'} items-centefr flex-col`}>
            <input
              type="text"
              inputMode="text"
              placeholder={`Paste your Source Transaction ID${
                !isMd ? ' to resume your transfer and redeem' : ''
              }`}
              className={`rounded border-1 ${
                displayRedeemedWarning ? 'border-wombatRed' : 'border-wombatBrown1'
              }  h-[40px] w-full bg-transparent px-2 font-Helvetica text-[12px] text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none ${
                isMd ? '' : 'mb-2'
              }`}
              value={recoverTxnHashInput}
              onChange={(e) => handleRecoverTxnHashInputOnchange(e.target.value)}
            />
            {displayRedeemInfoBox && !displayRedeemedWarning && !isMd && <BridgeRedeemInfoBox />}
            {displayRedeemedWarning && (
              <div className="w-full text-left text-wombatRed">
                This transaction has already redeemed
              </div>
            )}
            {isRecoveringTransferReceipt && (
              <div className="w-full text-left">Waiting for Wormhole Network consensus...</div>
            )}
          </div>
        </div>
        {displayRedeemInfoBox && !displayRedeemedWarning && isMd && <BridgeRedeemInfoBox />}
        {!account ? (
          <WButton
            variant={Variant.GRADIENT}
            width="w-full"
            height="h-10"
            onClick={onOpenModalConnectWallet}
            className={isMd ? 'w-[100%]' : 'w-[20%]'}
          >
            CONNECT WALLET
          </WButton>
        ) : (
          <>
            {/**@todo integrate recovery & redeem into one step */}
            {isRecoveryBtnShown ? (
              <WButton
                variant={Variant.PURPLE}
                width="w-full"
                height="h-10"
                onClick={handleRecoveryBtnOnClick}
                isLoading={isRecoveringTransferReceipt || isFetchingSignedVaa}
                className={isMd ? 'w-[100%]' : 'w-[20%]'}
                disabled={!recoverTxnHashInput}
                enableDimmed
              >
                Recover
              </WButton>
            ) : (
              <WButton
                variant={Variant.PURPLE}
                width="w-full"
                height="h-10"
                onClick={handleRedeemBtnOnClick}
                isLoading={isRedeeming}
                className={isMd ? 'w-[100%]' : 'w-[20%]'}
                disabled={!recoverTxnHashInput}
                enableDimmed
              >
                Redeem
              </WButton>
            )}
          </>
        )}
      </div>
      {currentModalState.currentModalId === ModalId.BRIDGE_REDEEM_REDEEM_WAIT_FOR_CONFIRMATION && (
        <BridgeRedeemConfirmationModal
          isOpen
          onClose={closeModal}
          redeemTargetChainId={targetChainId}
          isDisplayGifFlyToTheMoon={isDisplayGifFlyToTheMoonForRedeem}
          bridgedTokenAmount={bridgedTokenAmount}
        />
      )}
      {currentModalState.currentModalId === ModalId.BRIDGE_REDEEM_COMPLETED && (
        <BridgeRedeemCompletedModal
          isOpen
          onClose={closeModal}
          chainId={targetChainId}
          hash={redeemTransaction}
        />
      )}
    </>
  )
}

import { AssetState } from './types'
import { getInitialAssetProperty } from './helpers'
import { BigNumber } from 'ethers'
import { createSlice } from '@reduxjs/toolkit'
import fetchCashLiability from './thunks/getCashLiability'

const initialState: AssetState = {
  cashes: getInitialAssetProperty<BigNumber>(),
  liabilities: getInitialAssetProperty<BigNumber>(),
  totalSupplies: getInitialAssetProperty<BigNumber>(),
  lpTokenToTokenRates: getInitialAssetProperty<BigNumber>(),
  maxSupply: getInitialAssetProperty<BigNumber>(),
  deposits: getInitialAssetProperty<BigNumber>(),
  loadingCashes: false,
}

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCashLiability.pending, (state) => {
      state.loadingCashes = true
    })
    builder.addCase(fetchCashLiability.rejected, (state) => {
      state.loadingCashes = false
    })
    builder.addCase(fetchCashLiability.fulfilled, (state, action) => {
      if (!action.payload) return
      state.cashes = action.payload.cashes
      state.liabilities = action.payload.liabilities
      state.totalSupplies = action.payload.totalSupplies
      state.lpTokenToTokenRates = action.payload.lpTokenToTokenRates
      state.maxSupply = action.payload.maxSupply
      state.loadingCashes = false
    })
  },
})

export default assetSlice.reducer

import { IContractConstructor } from '../../interfaces/contract'
import { SupportedChainId } from '../web3/supportedChainId'
import { VEWOM_ABI } from './abis/vewom'
import { Contract } from './Contract'
export class VeWom extends Contract<typeof VEWOM_ABI> {
  constructor({ address, chainId }: IContractConstructor) {
    super({ address, chainId, abi: VEWOM_ABI })
  }
}

export const VEWOMS: { [id in SupportedChainId]?: VeWom | null } = {
  [SupportedChainId.BSC_MAINNET]: new VeWom({
    address: '0x3DA62816dD31c56D9CdF22C6771ddb892cB5b0Cc',
    chainId: SupportedChainId.BSC_MAINNET,
  }),
  [SupportedChainId.BSC_TESTNET]: new VeWom({
    address: '0x3868B84D4cea3110694d07A2d222418F7B401fE8',
    chainId: SupportedChainId.BSC_TESTNET,
  }),
  [SupportedChainId.ARBITRUM_ONE]: new VeWom({
    address: '0x488B34F704a601DAeEf14135146a3dA79F2d3EFC',
    chainId: SupportedChainId.ARBITRUM_ONE,
  }),
  [SupportedChainId.ETHEREUM_MAINNET]: new VeWom({
    address: '0xEF0B4d9Dba6ea82B59437Fe5E37b16A2896aAF94',
    chainId: SupportedChainId.ETHEREUM_MAINNET,
  }),
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: new VeWom({
    address: '0x338159fA37Fa3c5711a6F48A1055B2Cb1dDD422b',
    chainId: SupportedChainId.SCROLL_SEPOLIA_TESTNET,
  }),
  [SupportedChainId.SKALE_TESTNET]: new VeWom({
    address: '0xE42b4e663fdEc68e3744de07ef54519102De5b1e',
    chainId: SupportedChainId.SKALE_TESTNET,
  }),
  [SupportedChainId.OP_MAINNET]: new VeWom({
    address: '0xE91cfd7f8e5A5a1EA4803FcF781e1C8d6FfCa279',
    chainId: SupportedChainId.OP_MAINNET,
  }),
  [SupportedChainId.BASE]: new VeWom({
    address: '0x34E2F923bBa206358EcE221af73E8d121837F873',
    chainId: SupportedChainId.BASE,
  }),
  [SupportedChainId.POLYGON_MAINNET]: new VeWom({
    address: '0x9A65781bFff8E43E4345D6B1b5157B2657F2735D',
    chainId: SupportedChainId.POLYGON_MAINNET,
  }),
  [SupportedChainId.AVAX]: new VeWom({
    address: '0x34E2F923bBa206358EcE221af73E8d121837F873',
    chainId: SupportedChainId.AVAX,
  }),
}

import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const OPTIMISM_MAINNET_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0x50a39b94B1Dc8472faa08c36a3Ef5b0A01c5BD10',
  },
  [PoolLabels.FRAX]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x6BB82A9b0b9b9716B885baeEfDBE47b685a0F919',
  },
  [PoolLabels.frxETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0xB86BA65b75D34402bf377cF83b184554a18Fcafa',
  },
  [PoolLabels.USDV]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x1a023186eF2994249120CC7fAEdBEA07F40C6fbd',
  },
  [PoolLabels.DOLA]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x489818F2eeAef737105887710F7C5b9323Ad3d01',
  },
}

import { assetData } from '../../../configx/assetData'
import { HexString } from '../../../interfaces/contract'
import { SUPPORTED_CHAIN_IDS, SupportedChainId } from '../../web3/supportedChainId'
import { PoolLabels, poolLabels } from '../pool/PoolLabels'
import { POOL_ADDRESS_TO_POOL_LABEL_MAP } from '../pool/poolConfig'
import { TokenSymbol, TokenSymbols } from '../token/TokenSymbols'
import { Asset } from './Asset'
import { SortConf } from './SortConf'
import { ASSET_CONFIG } from './assetConfig'

/** @todo [label in PoolLabels]: should be [label in PoolLabels]?:  */
export type PoolAssetMap = {
  [label in PoolLabels]: {
    [id in TokenSymbol]?: Asset
  }
}

/** @TODO split to different folder */
/** New Pool Release: Go to AssetConfig folder */
/**
 * This HARCODED_ASSETS is to handle some assets that cannot be fetched from configx-wombat package.
 * For example, MasterWombat id is not set in assets on bsc testnet.
 *
 */
export const HARDCODED_ASSETS: {
  [chainId in SupportedChainId]?: Partial<PoolAssetMap>
} = {
  [SupportedChainId.BSC_MAINNET]: {
    /** BNB pool is using MasterWombat v2 */
    [PoolLabels.BNB]: {
      [TokenSymbols.WBNB]: new Asset({
        pid: 2,
        symbol: TokenSymbols.WBNB,
        name: 'BNB', // display as BNB
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0x74f019A5C4eD2C2950Ce16FaD7Af838549092c5b',
        poolLabel: PoolLabels.BNB,
        color: '#F0B90B',
        displaySymbol: 'BNB', // display as BNB
        paused: true,
        delisted: true,
        bribeRewarder: {
          rewarderAddress: '0x414D1a760320f948147FB71113851BB11CB53976',
          rewardTokenSymbols: [TokenSymbols.WBNB],
        },
      }),
      [TokenSymbols.BNBx]: new Asset({
        pid: 3,
        symbol: TokenSymbols.BNBx,
        name: 'Stader BNB',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0x10F7C62f47F19e3cE08fef38f74E3C0bB31FC24f',
        poolLabel: PoolLabels.BNB,
        color: '#755E21',
        paused: true,
        delisted: true,
        bribeRewarder: {
          rewarderAddress: '0x053cd96D5BeB742189E21D9B9112a9195E19435f',
          rewardTokenSymbols: [TokenSymbols.SD],
        },
      }),
      [TokenSymbols.stkBNB]: new Asset({
        pid: 5,
        symbol: TokenSymbols.stkBNB,
        name: 'pSTAKE BNB',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0xc496f42eA6Fc72aF434F48469b847A469fe0D17f',
        poolLabel: PoolLabels.BNB,
        color: '#B53F3D',
        paused: true,
        delisted: true,
        bribeRewarder: {
          rewarderAddress: '0x946207061de96bfc2a5CD544EA4eC2F7fBE84A98',
          rewardTokenSymbols: [TokenSymbols.PSTAKE],
        },
      }),
      [TokenSymbols.ankrBNB]: new Asset({
        pid: 4,
        symbol: TokenSymbols.ankrBNB,
        name: 'Ankr BNB',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0x9d2deaD9547EB65Aa78E239647a0c783f296406B',
        poolLabel: PoolLabels.BNB,
        color: '#FAE853',
        paused: true,
        displaySymbol: 'aBNBc',
        bribeRewarder: {
          rewarderAddress: '0x2DC5C0A6b83Dc3B7eC92c4A868a87b464Aa27501',
          rewardTokenSymbols: [TokenSymbols.ANKR],
        },
      }),
    },
    [PoolLabels.FACTORY_STABLES]: {
      [TokenSymbols.BUSD]: new Asset({
        pid: 12,
        symbol: TokenSymbols.BUSD,
        name: 'Binance USD',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0xcF434949c242C2D32514BA971947bD3700EFB015',
        poolLabel: PoolLabels.FACTORY_STABLES,
        color: '#F8B900',
        paused: true,
        delisted: true,
        standalonePoolAddress: '0xaded24B510a137b05a8eD958a029DACD6a59efDc',
      }),
      [TokenSymbols.TUSD]: new Asset({
        pid: 13,
        symbol: TokenSymbols.TUSD,
        name: 'TUSD', // display as BNB
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0x3C8e744f6c4Ed2c9D82e33D69DDcC5961Aa05367',
        poolLabel: PoolLabels.FACTORY_STABLES,
        color: '#F0B90B',
        paused: true,
        delisted: true,
      }),
    },
    // For standalone pool config
    [PoolLabels.SMART_HAY]: {
      // It should be side pool
      [TokenSymbols.BUSD]: new Asset({
        pid: 4,
        symbol: TokenSymbols.BUSD,
        name: 'Binance USD',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0xA649Be04619a8F3B3475498E1ac15C90C9661C1A',
        poolLabel: PoolLabels.SMART_HAY,
        color: '#F0B90B',
        paused: true,
        delisted: true,
      }),
      [TokenSymbols.lisUSD]: new Asset({
        pid: 13,
        symbol: TokenSymbols.lisUSD,
        name: 'lisUSD',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0xa393D61fE1532257B69b753aF7d1EfB0e22f1A6E',
        poolLabel: PoolLabels.SMART_HAY,
        color: '#F0B90B',
        paused: true,
        delisted: true,
      }),
    },
    // For standalone pool config
    [PoolLabels.MAIN]: {
      [TokenSymbols.BUSD]: new Asset({
        pid: 0,
        symbol: TokenSymbols.BUSD,
        name: 'Binance USD',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0xF319947eCe3823b790dd87b0A509396fE325745a',
        poolLabel: PoolLabels.MAIN,
        color: '#F8B900',
        paused: true,
        delisted: true,
        standalonePoolAddress: '0xfcd11c01c14e4c12C3F9835CD5192fE774038d46',
      }),
    },
    [PoolLabels.Overnight]: {
      [TokenSymbols.CUSD]: new Asset({
        pid: 21,
        symbol: TokenSymbols.CUSD,
        name: 'CUSD',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0x3ac762C607ed6Dba156cBcF11efF96340e86b490',
        poolLabel: PoolLabels.Overnight,
        color: '#F8D675',
        paused: true,
        delisted: true,
        standalonePoolAddress: '0x6569DDC1Cc2648c89BC8025046A7dd65EB8940F3',
      }),
      [TokenSymbols.lisUSD]: new Asset({
        pid: 22,
        symbol: TokenSymbols.lisUSD,
        name: 'lisUSD',
        chainId: SupportedChainId.BSC_MAINNET,
        address: '0xa6eF6C45EbFDBc13f6D032fbDFeC9b389C1603E5',
        poolLabel: PoolLabels.Overnight,
        color: '#F8D675',
        paused: true,
        delisted: true,
        standalonePoolAddress: '0xfcd11c01c14e4c12C3F9835CD5192fE774038d46',
      }),
    },
  },
  [SupportedChainId.BSC_TESTNET]: {
    [PoolLabels.frxETH]: {},
    /** the MasterWombat address in axlUSD pool has not been set */
    [PoolLabels.axlUSDC]: {
      [TokenSymbols.BUSD]: new Asset({
        symbol: TokenSymbols.BUSD,
        name: 'Binance USD',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0x148cB0c89bD3fC0F89c81cDfDC2cB6Cd6D790317',
        poolLabel: PoolLabels.axlUSDC,
        color: '#2F455B',
        pid: -1,
      }),
      [TokenSymbols.axlUSDC]: new Asset({
        symbol: TokenSymbols.axlUSDC,
        name: 'Axelar Wrapped USDC',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0xb826313c8B122757e617c0Ea963a4310c14a8Cc8',
        poolLabel: PoolLabels.axlUSDC,
        color: '#40648A',
        pid: -1,
      }),
    },
    /** the MasterWombat address in CUSD pool has not been set */
    [PoolLabels.CUSD]: {
      [TokenSymbols.HAY]: new Asset({
        pid: -1,
        symbol: TokenSymbols.HAY,
        name: 'HAY',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0xcE2bb46e4121819d5Bf77e28480393083738549A',
        poolLabel: PoolLabels.CUSD,
        color: '#F8D675',
      }),
      [TokenSymbols.CUSD]: new Asset({
        pid: -1,
        symbol: TokenSymbols.CUSD,
        name: 'Coin98 Dollar',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0x8e16b6c0C6121c58733Af331B07F1C2fda7F8561',
        poolLabel: PoolLabels.CUSD,
        color: '#D8BD70',
      }),
    },
    /** the MasterWombat address in iUSD pool has not been set */
    [PoolLabels.iUSD]: {
      [TokenSymbols.BUSD]: new Asset({
        pid: -1,
        symbol: TokenSymbols.BUSD,
        name: 'Binance USD',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0x0e2D70ac977E3435403efB46159315E8aBa78278',
        poolLabel: PoolLabels.iUSD,
        color: '#4D719B',
      }),
      [TokenSymbols.iUSD]: new Asset({
        pid: -1,
        symbol: TokenSymbols.iUSD,
        name: 'iZUMi Bond USD',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0x5f38B59905F5Fe9161EFF4730f035032eb830241',
        poolLabel: PoolLabels.iUSD,
        color: '#2A60F1',
      }),
    },
    /** the MasterWombat address in USDD pool has not been set */
    [PoolLabels.USDD]: {
      [TokenSymbols.USDC]: new Asset({
        pid: -1,
        symbol: TokenSymbols.USDC,
        name: 'USD Coin',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0x3c64D9870632E12036888D5fc0CFA5dEb261B453',
        poolLabel: PoolLabels.USDD,
        color: '#0E9189',
      }),
      [TokenSymbols.USDD]: new Asset({
        pid: -1,
        symbol: TokenSymbols.USDD,
        name: 'Decentralized USD',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0xAB8D5b703edBed5733271d1Af5134e1C667dda0D',
        poolLabel: PoolLabels.USDD,
        color: '#216C58',
      }),
    },
    /** the MasterWombat address in BOB pool has not been set */
    [PoolLabels.BOB]: {
      [TokenSymbols.USDC]: new Asset({
        pid: -1,
        symbol: TokenSymbols.USDC,
        name: 'USD Coin',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0xFF870dF0B97971D87264D7100c50CDd5FF51853c',
        poolLabel: PoolLabels.BOB,
        color: '#F6AAAE',
      }),
      [TokenSymbols.BOB]: new Asset({
        pid: -1,
        symbol: TokenSymbols.BOB,
        name: 'zkBob',
        chainId: SupportedChainId.BSC_TESTNET,
        address: '0xcdD244afC4e8df284939528E9eD5ee413C09128C',
        poolLabel: PoolLabels.BOB,
        color: '#EB73D9',
      }),
    },
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    [PoolLabels.FRAX_MAI_USDplus]: {
      [TokenSymbols.MAI]: new Asset({
        name: 'MAI',
        chainId: SupportedChainId.ARBITRUM_ONE,
        symbol: TokenSymbols.MAI,
        poolLabel: PoolLabels.FRAX_MAI_USDplus,
        pid: 11,
        decimals: 18,
        address: '0x51880CEE87bF2F5ffb1AbC84E20889771b025D0A',
        color: '#',
        paused: true,
        delisted: true,
      }),
    },
  },
  [SupportedChainId.FUJI]: {
    [PoolLabels.CROSS_CHAIN]: {
      [TokenSymbols.BUSD]: new Asset({
        // cannot find pid in https://testnet.snowtrace.io/address/0xBA3A9EC587722BB78De2860beaFb880daFB0fD3D
        pid: 0,
        symbol: TokenSymbols.BUSD,
        name: 'BNB', // display as BNB
        chainId: SupportedChainId.FUJI,
        address: '0x326335BA4e70cb838Ee55dEB18027A6570E5144d',
        poolLabel: PoolLabels.CROSS_CHAIN,
        color: '#F0B90B',
      }),
      [TokenSymbols.vUSDC]: new Asset({
        // cannot find pid in https://testnet.snowtrace.io/address/0xBA3A9EC587722BB78De2860beaFb880daFB0fD3D
        pid: 0,
        symbol: TokenSymbols.vUSDC,
        name: 'vUSDC', // display as BNB
        chainId: SupportedChainId.FUJI,
        address: '0xE42b4e663fdEc68e3744de07ef54519102De5b1e',
        poolLabel: PoolLabels.CROSS_CHAIN,
        color: '#F0B90B',
      }),
    },
  },
}

const getAssets = (): {
  [chainId in SupportedChainId]: PoolAssetMap
} => {
  const output: {
    [chainId in SupportedChainId]: PoolAssetMap
  } = Object.values(poolLabels).reduce(
    // create an empty assets variable
    (map, poolLabel) => {
      for (const chainIdStr of Object.values(SupportedChainId)) {
        const chainId = chainIdStr as SupportedChainId
        map[chainId] = {
          ...map[chainId],
          [poolLabel]: {},
        }
      }

      return map
    },
    {} as {
      [chainId in SupportedChainId]: PoolAssetMap
    }
  )
  for (const data of assetData) {
    const {
      address: addressStr,
      chainId: chainIdStr,
      decimals,
      poolAddress,
      bribeRewarder,
      poolRewarder,
      pid,
    } = data
    let tokenSymbol = data.tokenSymbol
    const chainId = chainIdStr as unknown as SupportedChainId

    if (
      // USDT's symbol is USDt on avalanche */
      +chainId === SupportedChainId.AVAX &&
      tokenSymbol &&
      tokenSymbol.toUpperCase() === TokenSymbols.USDT.toUpperCase()
    ) {
      // maybe we can do it in configx
      // turn USDt to USDT in order to group USDT and USDt into the same crosschain pool
      tokenSymbol = tokenSymbol.toUpperCase()
    }

    let displaySymbol = tokenSymbol
    if (
      +chainId === SupportedChainId.BSC_MAINNET &&
      tokenSymbol &&
      tokenSymbol === TokenSymbols.lisUSD
    ) {
      displaySymbol = `${TokenSymbols.lisUSD} (LP-HAY)`
    }
    const address = addressStr as unknown as HexString
    const poolLabel = POOL_ADDRESS_TO_POOL_LABEL_MAP[chainId]?.[poolAddress.toLowerCase()]
    if (!poolLabel) continue
    const assetConfig = ASSET_CONFIG[chainId]?.[poolLabel]?.[tokenSymbol as TokenSymbol]
    const asset = new Asset({
      pid,
      address,
      chainId,
      decimals,
      poolLabel,
      symbol: tokenSymbol as TokenSymbol,
      displaySymbol,
      name: assetConfig?.name || '',
      color: assetConfig?.color || '',
      ...assetConfig,
    })
    if (bribeRewarder) {
      asset.bribeRewarder = {
        rewardTokenAddresses: bribeRewarder.rewardTokenSymbolAddresses as HexString[],
        rewarderAddress: bribeRewarder.rewarderAddress as HexString,
        rewardTokenSymbols: bribeRewarder.rewardTokenSymbols as TokenSymbol[],
      }
    }
    if (poolRewarder) {
      asset.poolRewarder = {
        rewardTokenAddresses: poolRewarder.rewardTokenAddresses as HexString[],
        rewarderAddress: poolRewarder.rewarderAddress as HexString,
        rewardTokenSymbols: poolRewarder.rewardTokenSymbols as TokenSymbol[],
      }
    }
    output[asset.chainId] = {
      ...output[asset.chainId],
      [asset.poolLabel]: {
        ...(output[asset.chainId]?.[asset.poolLabel] || {}),
        [asset.symbol]: asset,
      },
    }
  }
  for (const chainIdStr in HARDCODED_ASSETS) {
    const chainId = chainIdStr as unknown as SupportedChainId
    const assetsInPools = HARDCODED_ASSETS[chainId]
    if (assetsInPools) {
      for (const poolLabelStr in assetsInPools) {
        const poolLabel = poolLabelStr as PoolLabels
        output[chainId][poolLabel] = {
          ...output[chainId][poolLabel],
          ...assetsInPools[poolLabel],
        }
      }
    }
  }
  return sortAssets(output)
}

const sortAssets = (assets: {
  [chainId in SupportedChainId]: PoolAssetMap
}): {
  [chainId in SupportedChainId]: PoolAssetMap
} => {
  for (const chainIdStr in assets) {
    const chainId = chainIdStr as unknown as SupportedChainId
    const assetsInPools = assets[chainId]
    for (const poolLabelStr in assetsInPools) {
      const poolLabel = poolLabelStr as PoolLabels
      const assetsInPool = assetsInPools[poolLabel]
      const sorted = Object.keys(assetsInPool)
        .sort((a, b) => {
          return (SortConf[poolLabel]?.[b] || 0) - (SortConf[poolLabel]?.[a] || 0)
        })
        .reduce((acc, key) => {
          return {
            ...acc,
            [key]: assetsInPool?.[key as TokenSymbols],
          }
        }, {})
      assets[chainId][poolLabel] = {
        ...sorted,
      }
    }
  }
  return assets
}

/**
 * Caveat:
 * Assets must be added to MasterWombat as configx-wombat package relies on MasterWombat.
 */
export const ASSETS = getAssets()
export const assetListMap: {
  [chainId in SupportedChainId]?: Asset[]
} = Object.entries(ASSETS).reduce((acc, [chainIdStr, poolAssetMap]) => {
  const chainId = chainIdStr as unknown as SupportedChainId
  const assetList = Object.values(poolAssetMap).reduce(
    (acc, assets) => [...acc, ...Object.values(assets)],
    [] as Asset[]
  )
  return {
    ...acc,
    [chainId]: assetList,
  }
}, {})

export const getSupportedChainIdsForCrossChainAssets = (
  tokenSymbol: TokenSymbol
): SupportedChainId[] => {
  const supportedChainIds: SupportedChainId[] = []

  for (const [chainIdStr, poolAssetMap] of Object.entries(ASSETS)) {
    const chainId = chainIdStr as unknown as SupportedChainId
    const crossChainAssets = poolAssetMap[PoolLabels.CROSS_CHAIN]

    if (
      SUPPORTED_CHAIN_IDS.includes(Number(chainId)) &&
      Object.keys(crossChainAssets).includes(tokenSymbol)
    ) {
      supportedChainIds.push(chainId)
    }
  }
  return supportedChainIds
}

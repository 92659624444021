import Image from 'next/image'
import { useMemo, useRef } from 'react'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import WombatToTheMoonFlyUp from '../../public/assets/animations/ToTheMoon2.gif'
import WombatToTheMoonNoText from '../../public/assets/animations/ToTheMoon_noText.gif'
import WOMIcon from '../../public/assets/tokens/WOM.svg'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import Modal from '../Modal'

type Props = {
  isOpen: boolean
  onClose: () => void
  balances?: string | null
  txHash: boolean
  textAboveBalance?: string
  data?: string
  newLockDay?: string
}

export default function WaitingModal({
  isOpen,
  onClose,
  balances,
  txHash,
  textAboveBalance,
  data,
  newLockDay,
}: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })

  const renderGif = useMemo(() => {
    if (txHash) {
      return (
        <div className="absolute -top-60">
          <Image src={WombatToTheMoonFlyUp} layout="fixed" width={105} height={525} unoptimized />
        </div>
      )
    }

    return <Image src={WombatToTheMoonNoText} layout="fixed" width={70} height={70} unoptimized />
  }, [txHash])

  // const convertBalances = Number(
  //   utils.formatUnits(balances?._hex ?? 0, 18)
  // ).toFixed(2)
  const renderBalances = useMemo(() => {
    return balances && formatNumberUSLocale(balances)
  }, [balances])

  return isOpen === true ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Waiting for confirmation"
      isCenterTitle
      isOverflowHidden
    >
      <div className="relative flex flex-col items-center justify-center" style={{ marginTop: 0 }}>
        <span className="text-center font-Work text-xs text-wombatBlue">
          Please confirm the transaction in your wallet
        </span>
        <div className="flex flex-col items-center py-10">
          <div className="font-semibold text-wombatPurple1">{textAboveBalance}</div>

          <div className="mt-2 mb-12 flex items-center justify-center whitespace-nowrap rounded-lg bg-wombatPurple1 py-3.5 px-20 font-Helvetica text-base font-medium text-wombatCream">
            {balances && (
              <div className="flex items-center">
                <Image src={WOMIcon} height={15} width={15} />
              </div>
            )}
            <div className="ml-1">
              {renderBalances}
              {data ? `${data} WOM` : ''}
              {newLockDay ? `${newLockDay} Days` : ''}
            </div>
          </div>
          <div
            style={{
              paddingBottom: `${txHash ? '70px' : ''}`,
            }}
            className={`flex items-center justify-center`}
          >
            {renderGif}
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <>{''}</>
  )
}

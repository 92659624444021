import clsx from 'clsx'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import useBreakpoints from '../../hooks/useBreakpoints'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import TokenImage from '../TokenImage'
import AppTooltip from '../Tooltip/AppTooltip'
import { POOL_REWARDER_DRAINED_REMINDER } from '../../constants/infoData'

interface TokenRewardProp {
  symbol: TokenSymbol
  amount: string
  hideTokenName?: boolean
  isTextWhite?: boolean
  hasDrained?: boolean
}

export const TokenReward: React.FC<TokenRewardProp> = ({
  symbol,
  amount,
  hideTokenName = false,
  isTextWhite = false,
  hasDrained = false,
}) => {
  const { isMd } = useBreakpoints()
  const shouldShowHoverBox = Number(amount) < 0.01
  return (
    <div className="relative flex items-center justify-between lg:ml-1" key={symbol}>
      <TokenImage tokenSymbol={symbol} />
      <div
        className={clsx(
          `group ml-1 ${isTextWhite ? 'text-white' : 'text-wombatBrown'}`,
          shouldShowHoverBox && 'hover-text'
        )}
        onClick={(e) => shouldShowHoverBox && e.stopPropagation()}
      >
        {formatNumberUSLocale(amount)}
        {!hideTokenName && <>&nbsp;{symbol}</>}
        {shouldShowHoverBox && (
          <div
            className={`tooltip-brown ${
              isMd ? '-left-24' : '-right-24'
            } w-fit py-2 px-3 text-center text-sm text-white`}
          >
            {Number(amount).toFixed(6)}
          </div>
        )}
      </div>
      {hasDrained && (
        <div className="absolute right-[-15px]">
          <AppTooltip
            message={POOL_REWARDER_DRAINED_REMINDER}
            className="ml-2"
            iconVariant="alert"
          ></AppTooltip>
        </div>
      )}
    </div>
  )
}

import Image from 'next/image'
import { useMemo, useRef } from 'react'
import WombatToTheMoonFlyUp from '../../../public/assets/animations/ToTheMoon2.gif'
import WombatToTheMoonNoText from '../../../public/assets/animations/ToTheMoon_noText.gif'
import Modal from '../../Modal'
import useDidClickOutside from '../../../hooks/useDidClickOutside'
import { TokenSymbols } from '../../../configx/tokenSymbols'
import TokenImage from '../../TokenImage'
import { chainInfos } from '../../../constants/web3/chains'
import { useBridgeTransferContext } from '../../../context/BridgeTransferContext'
import arrowRight from '../../../public/assets/icons/arrow-right-thin.svg'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { shortenHashString } from '../../../utils'

type Props = {
  isOpen: boolean
  onClose: () => void
  bridgeAmount: string
  recipient: string
}

export default function BridgeTransferConfirmationModal({
  isOpen,
  onClose,
  bridgeAmount,
  recipient,
}: Props) {
  const { isMd } = useBreakpoints()
  const { sourceChainId, targetChainId, isDisplayGifFlyToTheMoonForTransfer } =
    useBridgeTransferContext()
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })

  const renderGif = useMemo(() => {
    if (isDisplayGifFlyToTheMoonForTransfer) {
      return (
        <div className="absolute -top-60">
          <Image src={WombatToTheMoonFlyUp} layout="fixed" width={105} height={525} unoptimized />
        </div>
      )
    }

    return <Image src={WombatToTheMoonNoText} layout="fixed" width={70} height={70} unoptimized />
  }, [isDisplayGifFlyToTheMoonForTransfer])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<div className="text-wombatPurple">Transfer Tokens</div>}
      isCenterTitle
      isOverflowHidden
    >
      <div className="relative flex flex-col items-center justify-center">
        <div
          className={`item-center my-3 flex  flex-col ${
            isMd ? 'w-[80%] gap-6 ' : 'w-[65%] gap-4 '
          }`}
        >
          <span className="text-center font-Work text-xs text-black">
            Please confirm the transaction in your wallet
          </span>
          <div className="flex">
            <div className="flex flex-1 flex-col items-center justify-center gap-2">
              <div className="flex items-center gap-1">
                <b>{bridgeAmount}</b>
                <span>{TokenSymbols.WOM}</span>
                <TokenImage tokenSymbol={TokenSymbols.WOM} width="18" height="18" />
              </div>
              <div className="flex items-center gap-1">
                <Image src={chainInfos[sourceChainId].icon} width={15} height={15} />
                <div>{chainInfos[sourceChainId].label}</div>
              </div>
            </div>
            <div className="flex flex-1 items-center justify-center">
              <Image src={arrowRight} height={10} width={10} />
            </div>
            <div className="flex flex-1 flex-col items-center justify-center gap-2">
              <div className="flex items-center gap-1">
                <b>{bridgeAmount}</b>
                <span>{TokenSymbols.WOM}</span>
                <TokenImage tokenSymbol={TokenSymbols.WOM} width="18" height="18" />
              </div>
              <div className="flex items-center gap-1">
                <Image src={chainInfos[targetChainId].icon} width={15} height={15} />
                <div>{chainInfos[targetChainId].label}</div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center rounded border-1 border-wombatBrown1 p-2 text-center">
            {recipient && shortenHashString(recipient, 4, recipient.length)}
          </div>
        </div>

        <div className="flex flex-col items-center pt-10">
          <div
            style={{
              paddingBottom: `${isDisplayGifFlyToTheMoonForTransfer ? '70px' : ''}`,
            }}
            className={`flex items-center justify-center`}
          >
            {renderGif}
          </div>
        </div>
      </div>
    </Modal>
  )
}

import clsx from 'clsx'
import React from 'react'

type PropsPassedToChildren<T> = {
  currentActiveTabId: T
  onTabClick: (tabId: T) => void
  variant?: 'primary' | 'secondary'
}
type Props<T> = {
  children: React.ReactNode
} & PropsPassedToChildren<T> &
  React.HTMLAttributes<HTMLDivElement>

function Tabs<T>({
  children,
  className,
  currentActiveTabId,
  variant = 'primary',
  onTabClick,
  ...otherProps
}: Props<T>) {
  const variantStyles = (() => {
    switch (variant) {
      case 'secondary':
        return 'border-1 rounded-[4px] border-wombatBrown1 p-[4px] bg-[#EFEFEF]'

      default:
        return 'border-b border-wombatPurple4'
    }
  })()
  return (
    <div
      className={clsx(
        'mb-5 self-center text-center font-Jamjuree text-sm font-semibold text-wombatBrown1',
        variantStyles,
        className
      )}
      {...otherProps}
    >
      <ul className="-mb-px flex flex-wrap justify-around">
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as React.ReactElement<PropsPassedToChildren<T>>, {
              currentActiveTabId,
              onTabClick,
              variant,
            })
          }
          return child
        })}
      </ul>
    </div>
  )
}

export default Tabs

type TabItemProps<T> = {
  children: React.ReactNode
  tabId: T
}
function Tab<T>({ tabId, children, ...otherProps }: TabItemProps<T>) {
  const { currentActiveTabId, onTabClick, variant } =
    otherProps as unknown as PropsPassedToChildren<T>
  const variantStyles = (() => {
    switch (variant) {
      case 'secondary':
        return clsx(
          'rounded-[4px] p-[4px]',
          currentActiveTabId === tabId &&
            'bg-white shadow-[0px_2px_8px_0px_rgba(0,0,0,0.16)] text-wombatPurple1 transition-[background-color]'
        )

      default:
        return clsx(
          'block cursor-pointer rounded-t-lg border-b-2 border-transparent p-2 transition-[border] hover:border-wombatPurple1',
          currentActiveTabId === tabId && '!border-wombatPurple1 text-[#171003]'
        )
    }
  })()
  return (
    <li className="flex-1 select-none">
      <p
        onClick={() => {
          onTabClick(tabId)
        }}
        className={clsx('block cursor-pointer p-2', variantStyles)}
      >
        {children}
      </p>
    </li>
  )
}

Tabs.Tab = Tab

import { getDpFormat, getMillifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { constants, utils } from 'ethers'
import React, { useEffect, useMemo } from 'react'
import { EMISSION_PAUSED_REMINDER } from '../../components/Admin/constants'
import TokenImage from '../../components/TokenImage'
import AppTooltip from '../../components/Tooltip/AppTooltip'
import { TOKENS } from '../../constants/contract'
import { ASSETS } from '../../constants/contract/asset'
import { POOLS } from '../../constants/contract/pool'
import { useVotingData } from '../../context/VotingDataContext'
import { useWeb3 } from '../../context/Web3Context'
import { getBribeAprWithBalanceBreakdown } from '../../utils/voting'
import useTable, { SortingState, TableData } from './useTable'
import useTableSortingState from './useTableSortingState'

type TableHeadData = { style?: React.CSSProperties; cell: React.ReactNode; sortByDefault?: boolean }

type ReturnType = {
  sortedGaugeWeightInVeWomArr: string[]
  tableData: TableData
  tableHeadData: TableHeadData[]
  sortingState: SortingState | undefined
  updateSortingState: (columnIndex: number) => void
}
function useProposedGaugeWeightTable(): ReturnType {
  const { chainId } = useWeb3()
  const {
    total: { voteWeightOfEachAsset, bribeTokenBalanceInfoOfEachAsset, whiteListOfEachAsset },
    bribeAprData,
  } = useVotingData()

  const tableHeadData: TableHeadData[] = [
    {
      cell: '#',
    },
    {
      cell: 'Token',
      style: {
        justifyContent: 'flex-start',
      },
    },
    {
      cell: 'Pool',
    },
    {
      style: {
        whiteSpace: 'nowrap',
      },
      cell: 'Gauge Weight',
    },
    {
      style: {
        whiteSpace: 'nowrap',
      },
      cell: (
        <>
          Avg. Bribe APR
          <AppTooltip message="The average bribe APR on this gauge. This amount may vary based on the total vote of this gauge, and the calculation is based on the average staked WOM." />
        </>
      ),
      sortByDefault: true,
    },
    {
      style: {
        whiteSpace: 'nowrap',
      },
      cell: (
        <>
          My Max Bribe APR
          <AppTooltip message="The max bribe APR is if you use 100% of your voting power on this gauge. This amount may vary based on the total vote of this gauge, and the calculation is based on your staked WOM." />
        </>
      ),
    },
  ]

  const sortedGaugeWeightInVeWomArr = useMemo(() => {
    const gaugeWeightInVeWomArr: string[] = []
    Object.values(POOLS[chainId]).forEach((pool) => {
      const poolSymbol = pool.label

      for (const assetTokenSymbol of pool.supportedAssetTokenSymbols) {
        const asset = ASSETS[chainId][poolSymbol]?.[assetTokenSymbol]
        if (!asset?.enableVoting) continue
        const gaugeWeightInVeWom =
          voteWeightOfEachAsset.vewom[poolSymbol]?.[assetTokenSymbol] || '0'
        gaugeWeightInVeWomArr.push(gaugeWeightInVeWom)
      }
    })
    gaugeWeightInVeWomArr.sort((gaugeWeightForTokenA, gaugeWeightForTokenB) => {
      return Number(gaugeWeightForTokenB) - Number(gaugeWeightForTokenA)
    })
    return gaugeWeightInVeWomArr
  }, [chainId, voteWeightOfEachAsset.vewom])

  const tableData: TableData = useMemo(() => {
    const outputData: TableData = []
    Object.values(POOLS[chainId]).forEach((pool) => {
      const poolSymbol = pool.label
      for (const assetTokenSymbol of pool.supportedAssetTokenSymbols) {
        const asset = ASSETS[chainId][poolSymbol]?.[assetTokenSymbol]
        const token = TOKENS[chainId]?.[assetTokenSymbol]
        if (!asset?.enableVoting) continue
        const assetDisplaySymbol = token?.displaySymbol

        const gaugeWeightInVeWom =
          voteWeightOfEachAsset.vewom[poolSymbol]?.[assetTokenSymbol] || '0'

        // # = order by gaugeWeightInVeWom
        const order = sortedGaugeWeightInVeWomArr.indexOf(gaugeWeightInVeWom) + 1

        const gaugeWeightInPercentage =
          voteWeightOfEachAsset.percentage[poolSymbol]?.[assetTokenSymbol] || '0'

        const averageBribeAprBreakdown =
          bribeAprData.average[poolSymbol]?.[assetTokenSymbol]?.breakdown

        const userMaxBribeAprBreakdown =
          bribeAprData.user.max[poolSymbol]?.[assetTokenSymbol]?.breakdown

        const bribeBalanceInfo = bribeTokenBalanceInfoOfEachAsset[poolSymbol]?.[assetTokenSymbol]

        const averageBribeAprWithBalanceBreakdown = getBribeAprWithBalanceBreakdown(
          bribeBalanceInfo,
          averageBribeAprBreakdown
        )

        const userMaxBribeAprWithBalanceBreakdown = getBribeAprWithBalanceBreakdown(
          bribeBalanceInfo,
          userMaxBribeAprBreakdown
        )

        // aggregate bribe apr without drained bribe balance
        const averageBribeAprSumWithoutDrainedWad = averageBribeAprWithBalanceBreakdown?.reduce(
          (acc, item) => {
            return acc.add(strToWad(item.bribeApr))
          },
          constants.Zero
        )

        const maxBribeAprSumWithoutDrainedWad = userMaxBribeAprWithBalanceBreakdown?.reduce(
          (acc, item) => {
            return acc.add(strToWad(item.bribeApr))
          },
          constants.Zero
        )
        const hasWhiteListed = whiteListOfEachAsset[poolSymbol]?.[assetTokenSymbol]

        outputData.push({
          id: poolSymbol + assetTokenSymbol,
          configs: [
            {
              sortBy: order,
              cell: order,
            },
            {
              sortBy: assetTokenSymbol,
              cell: (
                <p className="table_token-wrapper--flex">
                  <TokenImage tokenSymbol={assetTokenSymbol} width="24" height="24" />
                  <span>
                    {assetDisplaySymbol}{' '}
                    {!hasWhiteListed && (
                      <AppTooltip
                        message={EMISSION_PAUSED_REMINDER}
                        className="ml-0"
                        iconVariant="alert"
                      ></AppTooltip>
                    )}
                  </span>
                </p>
              ),
            },
            {
              sortBy: pool.name,
              cell: <>{pool.name.replace(' Pool', '')}</>,
            },
            {
              sortBy: gaugeWeightInVeWom,
              cell: (
                <span className="border-bottom--dotted">
                  <AppTooltip
                    message={getDpFormat(gaugeWeightInVeWom, 2)}
                    fullWidth
                    padding="sm"
                    place="left"
                    centerChildren
                  >
                    {getMillifiedFormat(gaugeWeightInVeWom)} (
                    {getDpFormat(gaugeWeightInPercentage, 2)}%)
                  </AppTooltip>
                </span>
              ),
            },
            {
              sortBy: utils.formatEther(averageBribeAprSumWithoutDrainedWad) || '0',
              cell:
                averageBribeAprWithBalanceBreakdown.length > 0 ? (
                  <div className="table__token-wrapper flex flex-row justify-center">
                    {averageBribeAprWithBalanceBreakdown.map(({ bribeTokenSymbol }) => (
                      <AppTooltip
                        key={bribeTokenSymbol}
                        message={bribeTokenSymbol}
                        fullWidth
                        padding="sm"
                        place="left"
                      >
                        <TokenImage tokenSymbol={bribeTokenSymbol} width="24" height="24" />
                      </AppTooltip>
                    ))}
                    <div className="flex flex-col justify-center">
                      <AppTooltip
                        message={
                          <div>
                            {averageBribeAprWithBalanceBreakdown.map(
                              ({ bribeTokenSymbol, bribeApr }) => (
                                <p
                                  key={bribeTokenSymbol}
                                  className="flex flex-row items-center gap-[2px] py-[2px]"
                                >
                                  <TokenImage
                                    tokenSymbol={bribeTokenSymbol}
                                    width="24"
                                    height="24"
                                  />
                                  &nbsp;
                                  {getDpFormat(bribeApr ?? '0', 2)}%
                                </p>
                              )
                            )}
                          </div>
                        }
                        fullWidth
                        padding="sm"
                        place="right"
                      >
                        {`${getDpFormat(averageBribeAprSumWithoutDrainedWad, 2)}%`}
                      </AppTooltip>
                    </div>
                  </div>
                ) : (
                  '-'
                ),
            },
            {
              sortBy: utils.formatEther(maxBribeAprSumWithoutDrainedWad) || '0',
              cell:
                userMaxBribeAprWithBalanceBreakdown.length > 0 ? (
                  <div className="table__token-wrapper">
                    <p className="inline-flex flex-row gap-[2px]">
                      {userMaxBribeAprWithBalanceBreakdown.map(({ bribeTokenSymbol }) => (
                        <AppTooltip
                          key={bribeTokenSymbol}
                          message={bribeTokenSymbol}
                          fullWidth
                          padding="sm"
                          place="left"
                        >
                          <TokenImage tokenSymbol={bribeTokenSymbol} width="24" height="24" />
                        </AppTooltip>
                      ))}
                    </p>

                    <AppTooltip
                      message={
                        <div>
                          {userMaxBribeAprWithBalanceBreakdown.map(
                            ({ bribeTokenSymbol, bribeApr }) => (
                              <p
                                key={bribeTokenSymbol}
                                className="flex flex-row items-center gap-[2px] py-[2px]"
                              >
                                <TokenImage tokenSymbol={bribeTokenSymbol} width="24" height="24" />
                                &nbsp;
                                {getDpFormat(bribeApr ?? '0', 2)}%
                              </p>
                            )
                          )}
                        </div>
                      }
                      fullWidth
                      padding="sm"
                      place="left"
                    >
                      {`${getDpFormat(maxBribeAprSumWithoutDrainedWad, 2)}%`}
                    </AppTooltip>
                  </div>
                ) : (
                  '-'
                ),
            },
          ],
        })
      }
    })

    return outputData
  }, [
    chainId,
    voteWeightOfEachAsset.vewom,
    voteWeightOfEachAsset.percentage,
    sortedGaugeWeightInVeWomArr,
    bribeAprData.average,
    bribeAprData.user.max,
    bribeTokenBalanceInfoOfEachAsset,
    whiteListOfEachAsset,
  ])

  const [sortingState, updateSortingState] = useTableSortingState()

  /**
   * sort the table by the target column by default
   */

  useEffect(() => {
    const targetColIndex = tableHeadData.findIndex((data) => data.sortByDefault)
    updateSortingState(targetColIndex)
    // only update it for the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const processedTable = useTable({
    tableData,
    options: {
      sortingState: sortingState,
    },
  })

  return {
    sortedGaugeWeightInVeWomArr,
    tableData: processedTable.tableData,
    tableHeadData,
    sortingState,
    updateSortingState,
  }
}

export default useProposedGaugeWeightTable

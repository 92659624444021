import React, { useEffect, useState } from 'react'
import { useModalContext } from '../../../context/ModalContext'
import { useVotingData } from '../../../context/VotingDataContext'
import useUserVoteAllocationTableData from '../../../hooks/voting/useUserVoteAllocationTableData'
import useClaimBribeRewards from '../../../hooks/voting/useClaimBribeReward'
import useVote from '../../../hooks/voting/useVote'
import { ModalId } from '../../../interfaces/modal'
import Table from '../../Table'
import WButton, { Variant } from '../../WButton'
import ClaimBribeWaitForConfirmationModal from '../ClaimBribeWaitForConfirmationModal'
import VoteWaitForConfirmationModal from '../VoteWaitForConfirmationModal'
import { useUserVoteAllocationTable } from './UserVoteAllocationTableContext'
import plusIcon from '../../../public/assets/icons/plus.svg'
import Image from 'next/image'
import useBreakpoints from '../../../hooks/useBreakpoints'
import GaugeSelectionModal from '../GaugeSelectionModal'
function UserVoteAllocationTable() {
  const { isMd } = useBreakpoints()
  const [isGaugeSelectionModalOpen, setIsGaugeSelectionModalOpen] = useState(false)
  const { modalState: currentModalState } = useModalContext()
  const { tableData, tableHeadData } = useUserVoteAllocationTableData()
  const { handleVote, isDone: isSuccessfullyVoted, isWaiting: isWaitingToVote } = useVote()
  const { handleClaim } = useClaimBribeRewards()
  const {
    voteWeightPercentageInputs,
    editedGaugeList,
    actions: { exitEditMode },
  } = useUserVoteAllocationTable()
  const {
    user: { hasEarnedBribeRewards },
  } = useVotingData()
  useEffect(() => {
    if (isSuccessfullyVoted) {
      exitEditMode()
    }
  }, [exitEditMode, isSuccessfullyVoted])
  const disableVoteButton = !(editedGaugeList.length > 0) || isWaitingToVote
  return (
    <>
      <p className="mb-4 font-Work text-base font-semibold">Vote with veWOM</p>
      <Table width="100%" scrollDirection="both" stickyLastColumn>
        <Table.Head sticky>
          <Table.Row>
            {tableHeadData.map((headData, index) => (
              <Table.HeadData key={index} style={headData.style} columnIndex={index}>
                {headData.cell}
              </Table.HeadData>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {tableData.map((config) => {
            return (
              <Table.Row key={config.id} {...config.rowProps}>
                {config.configs.map((config, index) => (
                  <Table.Data key={index} style={tableHeadData[index].style}>
                    {config.cell}
                  </Table.Data>
                ))}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {isMd && (
        <div className="text-center font-Work" onClick={() => setIsGaugeSelectionModalOpen(true)}>
          <div className="inline-flex h-[28px] w-[28px] justify-center rounded-[8px] border-1 border-wombatBrown1">
            <Image src={plusIcon} />
          </div>
        </div>
      )}
      <div className="my-3 flex flex-row justify-end gap-3 max-sm:justify-between">
        <WButton
          className="w-[200px] md:w-[200px]"
          variant={!hasEarnedBribeRewards ? Variant.LIGHT_PURPLE : Variant.PURPLE}
          disabled={!hasEarnedBribeRewards}
          onClick={() => {
            handleClaim()
          }}
        >
          CLAIM ALL REWARDS
        </WButton>
        <WButton
          variant={disableVoteButton ? Variant.LIGHT_PURPLE : Variant.GRADIENT}
          disabled={disableVoteButton}
          onClick={() => {
            handleVote(voteWeightPercentageInputs)
          }}
        >
          {isWaitingToVote ? 'VOTING' : 'VOTE'}
        </WButton>
      </div>
      {currentModalState.currentModalId === ModalId.VOTE_WAIT_FOR_CONFIRMATION && (
        <VoteWaitForConfirmationModal isOpen />
      )}
      {currentModalState.currentModalId === ModalId.CLAIM_BRIBE_WAIT_FOR_CONFIRMATION && (
        <ClaimBribeWaitForConfirmationModal isOpen />
      )}
      {isGaugeSelectionModalOpen && (
        <GaugeSelectionModal onClose={() => setIsGaugeSelectionModalOpen(false)} />
      )}
    </>
  )
}

export default UserVoteAllocationTable

import { safeWdiv, strToWad } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import useSWR from 'swr'
import { ASSETS } from '../constants/contract/asset'
import { getPoolTokenPair } from '../constants/contract/pool'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import useBscScanApi from '../hooks/useBscScanApi'
import { PoolLabelsTokenSymbolsGenericType } from '../interfaces/common'
import { AllBonusTokenRewards, UserInfo } from '../interfaces/masterWombat'
import { getInitialAssetProperty } from '../store/Asset/helpers'
import { useCashesData } from '../store/Asset/hooks'
import { AssetProperty } from '../store/Asset/types'
import fetchFeeSharingAprs from '../store/MasterWombat/thunks/fetchFeeSharingAprs'
import fetchInterestBearingApr from '../store/MasterWombat/thunks/fetchInterestBearingApr'
import updateExtraRewards from '../store/MasterWombat/thunks/updateExtraRewards'
import {
  useBoostedPoolRewarderData,
  useMasterWombatData,
  usePoolData,
  useVotingData,
} from '../store/MulticallData/hooks'
import { useTokenPrices } from '../store/Prices/hooks'
import { useTradingVol24h } from '../store/Subgraph/hooks'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { calculateApr as calculateAprWad } from '../utils'
import { calLPPrice } from '../utils/math'
import { getFactor } from '../utils/veWom'
import { useWeb3 } from './Web3Context'
type AvgFeeSharingAprs = AssetProperty<{ daily: string; weekly: string }>
// TODO: query for each asset instead of each token
interface ContextType {
  baseAprWads: AssetProperty<BigNumber> // calculated
  averageBoostedAprs: AssetProperty<string>
  bonusTokenAprs: AssetProperty<BigNumber[]> // calculated
  // user info
  userInfos: AssetProperty<UserInfo>
  boostedAprs: AssetProperty<BigNumber> // calculated
  allBonusTokenRewards: AllBonusTokenRewards // reduced from `rewards`
  avgFeeSharingAprs: AvgFeeSharingAprs
  interestBearingAprs: PoolLabelsTokenSymbolsGenericType<string>
}

interface Actions {
  actions: {
    estimateBoostedApr: (
      pool: PoolLabels,
      token: TokenSymbol,
      oldDepositAmount: BigNumber,
      oldVeWomAmount: BigNumber,
      newDepositAmount: BigNumber,
      newVeWomAmount: BigNumber
    ) => BigNumber | null
  }
}

const initialPreference: ContextType = {
  bonusTokenAprs: getInitialAssetProperty<BigNumber[]>(),
  averageBoostedAprs: getInitialAssetProperty<string>(),
  baseAprWads: getInitialAssetProperty<BigNumber>(),
  userInfos: getInitialAssetProperty<UserInfo>(),
  boostedAprs: getInitialAssetProperty<BigNumber>(),
  avgFeeSharingAprs: getInitialAssetProperty(),
  allBonusTokenRewards: {
    pendingRewards: BigNumber.from(0),
    pendingBonusRewards: [],
    bonusTokenSymbols: [],
    assetsWithNonZeroRewards: [],
  },
  interestBearingAprs: {},
}

const MyContext = createContext<ContextType & Actions>(initialPreference as ContextType & Actions)

MyContext.displayName = 'MasterWombatContext'
interface Props {
  children: React.ReactNode
}

export const useMasterWombat = (): ContextType & Actions => {
  return useContext(MyContext)
}

function MasterWombatProvider({ children }: Props): ReactElement {
  const { chainId } = useWeb3()
  const { fetchBlockNumberByTimestamp } = useBscScanApi()
  const tokenPrices = useTokenPrices()
  const { lpTokenToTokenRates, liabilities } = useCashesData()
  const { withoutAccount: votingDataWithoutAccount } = useVotingData()
  const masterWombatData = useMasterWombatData()
  const poolRewarderData = useBoostedPoolRewarderData()
  const bonusTokenAprs = useAppSelector((state) => state.masterWombat.bonusTokenAprs)
  const interestBearingAprs = useAppSelector((state) => state.masterWombat.interestBearingAprs)
  const {
    poolInfos,
    bonusTokenPerSec,
    totalLpStakedBNs,
    basePartition,
    boostedPartition,
    userInfos,
    userPoolRewards,
  } = useMemo(() => {
    return {
      userPoolRewards: masterWombatData.withAccount?.poolRewards,
      userInfos: masterWombatData.withAccount?.userInfos || getInitialAssetProperty<UserInfo>(),
      poolInfos: masterWombatData.withoutAccount?.poolInfos,
      bonusTokenPerSec: masterWombatData.withoutAccount?.bonusTokenPerSec,
      totalLpStakedBNs: masterWombatData.withoutAccount?.totalLpStakedBNs,
      basePartition: masterWombatData.withoutAccount?.basePartition,
      boostedPartition: masterWombatData.withoutAccount?.boostedPartition,
    }
  }, [
    masterWombatData.withAccount?.poolRewards,
    masterWombatData.withAccount?.userInfos,
    masterWombatData.withoutAccount?.basePartition,
    masterWombatData.withoutAccount?.bonusTokenPerSec,
    masterWombatData.withoutAccount?.boostedPartition,
    masterWombatData.withoutAccount?.poolInfos,
    masterWombatData.withoutAccount?.totalLpStakedBNs,
  ])
  const { rewardRateWadOfEachAsset } = useMemo(() => {
    const rewardRateWadOfEachAsset: PoolLabelsTokenSymbolsGenericType<BigNumber> = {}
    Object.entries(poolInfos || {}).forEach(([poolLabelStr, poolInfo]) => {
      Object.entries(poolInfo).forEach(([assetSymbolStr, assetInfo]) => {
        const poolLabel = poolLabelStr as PoolLabels
        const assetSymbol = assetSymbolStr as TokenSymbol
        rewardRateWadOfEachAsset[poolLabel] = {
          ...(rewardRateWadOfEachAsset[poolLabel] || {}),
          [assetSymbol]: assetInfo.rewardRate || null,
        }
      })
    })
    return {
      womPerSec: votingDataWithoutAccount?.womPerSec || null,
      allocPointOfEachAsset: null,
      rewardRateWadOfEachAsset,
    }
  }, [poolInfos, votingDataWithoutAccount?.womPerSec])

  const allBonusTokenRewards = useMemo<AllBonusTokenRewards>(() => {
    const initAllBonusTokenRewards: AllBonusTokenRewards = {
      pendingRewards: BigNumber.from(0),
      pendingBonusRewards: [],
      bonusTokenSymbols: [],
      assetsWithNonZeroRewards: [],
    }
    Object.entries(userPoolRewards || {}).forEach(([poolSymbol, pool]) => {
      Object.entries(pool).forEach(([assetTokenSymbol, userPoolReward]) => {
        initAllBonusTokenRewards.pendingRewards = initAllBonusTokenRewards.pendingRewards.add(
          userPoolReward.pendingRewards
        )
        initAllBonusTokenRewards.pendingBonusRewards =
          initAllBonusTokenRewards.pendingBonusRewards.concat(userPoolReward.pendingBonusRewards)
        initAllBonusTokenRewards.bonusTokenSymbols =
          initAllBonusTokenRewards.bonusTokenSymbols.concat(userPoolReward.bonusTokenSymbols)

        // Only push assets with rewards greater than 0
        if (
          userPoolReward.pendingRewards.gt(0) ||
          userPoolReward.pendingBonusRewards.some((reward) => reward.gt(0))
        ) {
          initAllBonusTokenRewards.assetsWithNonZeroRewards = [
            ...initAllBonusTokenRewards.assetsWithNonZeroRewards,
            {
              poolSymbol: poolSymbol as PoolLabels,
              assetTokenSymbol: assetTokenSymbol as TokenSymbol,
            },
          ]
        }
      })
    })
    return initAllBonusTokenRewards
  }, [userPoolRewards])

  const womPriceWad = useMemo(() => strToWad(tokenPrices.WOM), [tokenPrices.WOM])
  // update InterestBearingAprs
  useSWR(
    [chainId, 'InterestBearingAprs'],
    ([chainId]) => {
      dispatch(
        fetchInterestBearingApr({
          chainId,
        })
      )
    },
    {
      refreshInterval: 30 * 1000,
    }
  )
  // update baseAprs

  const { averageBoostedAprs, baseAprWads } = useMemo(() => {
    const baseAprsWad_ = getInitialAssetProperty<BigNumber>()
    const initAverageBoostedAprs = getInitialAssetProperty<string>()
    getPoolTokenPair(chainId).forEach((pair) => {
      const assetPeriodFinish =
        masterWombatData.withoutAccount?.poolInfos[pair.poolLabel]?.[pair.tokenSymbol]
          ?.periodFinish || 0
      /** If an asset's periodFinish is less than current time. It means the asset's apr is outdated. We will set it to 0. */
      if (Date.now() / 1000 > assetPeriodFinish) {
        baseAprsWad_[pair.poolLabel][pair.tokenSymbol] = strToWad('0')
        initAverageBoostedAprs[pair.poolLabel][pair.tokenSymbol] = '0'
        return
      }
      const rewardRateWad = rewardRateWadOfEachAsset?.[pair.poolLabel]?.[pair.tokenSymbol]
      if (!rewardRateWad || !basePartition || !boostedPartition) return
      // Note: we could refactor this after we refactor our asset config
      const tokenPriceWad = strToWad(tokenPrices[pair.tokenSymbol])
      const lpUnitPrice = calLPPrice(
        utils.parseEther('1'),
        lpTokenToTokenRates[pair.poolLabel][pair.tokenSymbol] ?? null,
        tokenPriceWad
      )
      const poolInfo = poolInfos?.[pair.poolLabel][pair.tokenSymbol]
      const totalLpStakedBN = totalLpStakedBNs?.[pair.poolLabel][pair.tokenSymbol]
      const annualWomRewardWad = rewardRateWad
        .mul(60 * 60 * 24 * 365)
        .mul(basePartition)
        .div(1000)
      const baseAprWad =
        poolInfo && totalLpStakedBN && lpUnitPrice && !totalLpStakedBN.isZero()
          ? calculateAprWad(
              utils.parseEther('1'),
              annualWomRewardWad,
              womPriceWad,
              totalLpStakedBN,
              lpUnitPrice
            )
          : BigNumber.from(0)
      baseAprsWad_[pair.poolLabel][pair.tokenSymbol] = baseAprWad
      const averageBoostedApr = utils.formatEther(
        safeWdiv(baseAprWad.mul(boostedPartition), strToWad(String(basePartition)))
      )
      initAverageBoostedAprs[pair.poolLabel][pair.tokenSymbol] = averageBoostedApr
    })
    return {
      baseAprWads: baseAprsWad_,
      averageBoostedAprs: initAverageBoostedAprs,
    }
  }, [
    basePartition,
    boostedPartition,
    chainId,
    lpTokenToTokenRates,
    masterWombatData.withoutAccount?.poolInfos,
    poolInfos,
    rewardRateWadOfEachAsset,
    tokenPrices,
    totalLpStakedBNs,
    womPriceWad,
  ])
  const dispatch = useAppDispatch()
  useEffect(() => {
    // updateExtraRewards()
    dispatch(
      updateExtraRewards({
        chainId,
        bonusTokenPerSec,
        tokenPrices,
        lpTokenToTokenRates,
        totalLpStakedBNs,
      })
    )
  }, [chainId, bonusTokenPerSec, tokenPrices, lpTokenToTokenRates, totalLpStakedBNs, dispatch])

  // update boostedAprs
  const { boostedAprs } = useMemo(() => {
    const boostedAprs_ = getInitialAssetProperty<BigNumber>()
    getPoolTokenPair(chainId).forEach((pair) => {
      const assetPeriodFinish =
        masterWombatData.withoutAccount?.poolInfos[pair.poolLabel]?.[pair.tokenSymbol]
          ?.periodFinish || 0
      /** If an asset's periodFinish is less than current time. It means the asset's apr is outdated. We will set it to 0. */
      if (Date.now() / 1000 > assetPeriodFinish) {
        boostedAprs_[pair.poolLabel][pair.tokenSymbol] = strToWad('0')
        return
      }
      const rewardRateWad = rewardRateWadOfEachAsset?.[pair.poolLabel]?.[pair.tokenSymbol]
      if (!rewardRateWad || !boostedPartition) return
      const userInfo = userInfos[pair.poolLabel][pair.tokenSymbol]
      const sumOfFactors = poolInfos?.[pair.poolLabel][pair.tokenSymbol]?.sumOfFactors
      const tokenPriceWad = utils.parseEther(tokenPrices[pair.tokenSymbol] || '0')
      const lpUnitPrice = calLPPrice(
        utils.parseEther('1'),
        lpTokenToTokenRates[pair.poolLabel][pair.tokenSymbol] ?? null,
        tokenPriceWad
      )
      const annualWomRewardWad = rewardRateWad
        .mul(60 * 60 * 24 * 365)
        .mul(boostedPartition)
        .div(1000)
      if (
        !userInfo ||
        !sumOfFactors ||
        sumOfFactors.isZero() ||
        !userInfo.factor ||
        !userInfo.amount ||
        userInfo.amount.isZero() ||
        !lpUnitPrice ||
        lpUnitPrice.isZero()
      ) {
        return
      }
      const boostedApr = calculateAprWad(
        userInfo.factor.mul(utils.parseEther('1')).div(userInfo.amount),
        annualWomRewardWad,
        womPriceWad,
        sumOfFactors,
        lpUnitPrice
      )

      boostedAprs_[pair.poolLabel][pair.tokenSymbol] = boostedApr
    })
    return {
      boostedAprs: boostedAprs_,
    }
  }, [
    chainId,
    lpTokenToTokenRates,
    poolInfos,
    tokenPrices,
    userInfos,
    womPriceWad,
    rewardRateWadOfEachAsset,
    boostedPartition,
    masterWombatData.withoutAccount?.poolInfos,
  ])

  const estimateBoostedApr = useCallback(
    (
      poolLabel: PoolLabels,
      tokenSymbol: TokenSymbol,
      oldDepositAmount: BigNumber,
      oldVeWomAmount: BigNumber,
      newDepositAmount: BigNumber,
      newVeWomAmount: BigNumber
    ): BigNumber | null => {
      const womRewardRateWad = rewardRateWadOfEachAsset?.[poolLabel]?.[tokenSymbol]
      const boostedPartition = masterWombatData.withoutAccount?.boostedPartition
      if (!womRewardRateWad || !poolInfos || !boostedPartition) {
        return null
      }

      const sumOfFactors = poolInfos[poolLabel][tokenSymbol]?.sumOfFactors
      const newFactor = getFactor(newDepositAmount, newVeWomAmount)
      const oldFactor = getFactor(oldDepositAmount, oldVeWomAmount)

      if (!poolInfos[poolLabel][tokenSymbol] || !sumOfFactors || newDepositAmount.isZero()) {
        return null
      }

      let aprs: BigNumber = BigNumber.from(0)
      const asset = ASSETS[chainId][poolLabel][tokenSymbol]

      const assetPeriodFinish =
        masterWombatData.withoutAccount?.poolInfos[poolLabel]?.[tokenSymbol]?.periodFinish || 0
      if (Date.now() / 1000 > assetPeriodFinish && !asset?.boostedPoolRewarder) {
        return null
      }
      const rewardTokenPerSecs =
        poolRewarderData.withoutAccount?.rewardTokenPerSec[poolLabel]?.[tokenSymbol]

      if (rewardTokenPerSecs && asset) {
        asset.boostedPoolRewarder?.rewardTokenSymbols?.forEach((rewardTokenSymbol, index) => {
          const rewardPerSecWad = strToWad(rewardTokenPerSecs[index].value)
          const rewardTokenPriceWad = strToWad(tokenPrices[rewardTokenSymbol])
          const assetTokenPriceWad = strToWad(tokenPrices[tokenSymbol])
          const lpUnitPrice = calLPPrice(
            utils.parseEther('1'),
            lpTokenToTokenRates[poolLabel][tokenSymbol] ?? null,
            assetTokenPriceWad
          )
          const allAnnualRewardWad = rewardPerSecWad.mul(60 * 60 * 24 * 365)

          if (boostedPartition) {
            const poolInfo = masterWombatData.withoutAccount?.poolInfos[poolLabel][tokenSymbol]
            const annualRewardWadForBoosted = allAnnualRewardWad.mul(boostedPartition).div(1000)

            const boostedAprWad =
              lpUnitPrice && poolInfo
                ? calculateAprWad(
                    newFactor.mul(utils.parseEther('1')).div(newDepositAmount),
                    annualRewardWadForBoosted,
                    rewardTokenPriceWad,
                    sumOfFactors.sub(oldFactor).add(newFactor),
                    lpUnitPrice
                  )
                : BigNumber.from(0)

            aprs = aprs.add(boostedAprWad)
          }
        })
      }

      const annualWomRewardWad = womRewardRateWad
        .mul(60 * 60 * 24 * 365)
        .mul(boostedPartition)
        .div(1000)
      const tokenPriceWad = utils.parseEther(tokenPrices[tokenSymbol] || '0')
      const lpUnitPrice = calLPPrice(
        utils.parseEther('1'),
        lpTokenToTokenRates[poolLabel][tokenSymbol] ?? null,
        tokenPriceWad
      )

      const estimatedWomApr = !lpUnitPrice
        ? BigNumber.from(0)
        : calculateAprWad(
            newFactor.mul(utils.parseEther('1')).div(newDepositAmount),
            annualWomRewardWad,
            womPriceWad,
            sumOfFactors.sub(oldFactor).add(newFactor),
            lpUnitPrice
          )

      const totalApr = aprs.add(estimatedWomApr)
      return totalApr
    },
    [
      rewardRateWadOfEachAsset,
      masterWombatData.withoutAccount?.boostedPartition,
      masterWombatData.withoutAccount?.poolInfos,
      poolInfos,
      chainId,
      poolRewarderData.withoutAccount?.rewardTokenPerSec,
      tokenPrices,
      lpTokenToTokenRates,
      womPriceWad,
    ]
  )

  /**
   * Average fee sharing aprs
   */
  const avgFeeSharingAprs = useAppSelector((state) => state.masterWombat.avgFeeSharingAprs)
  const poolData = usePoolData()
  const tradingVol24h = useTradingVol24h()

  useEffect(() => {
    dispatch(
      fetchFeeSharingAprs({
        method: 'tradingVolume',
        chainId,
        fetchBlockNumberByTimestamp,
        liabilities,
        lpTokenToTokenRates,
        lpDividendRatios: poolData.withoutAccount?.lpDividendRatios,
        poolFeePercentages: poolData.withoutAccount?.poolFeePercentages,
        tokenPrices,
        tradingVol24h,
      })
    )
  }, [
    chainId,
    dispatch,
    fetchBlockNumberByTimestamp,
    liabilities,
    lpTokenToTokenRates,
    poolData.withoutAccount?.lpDividendRatios,
    poolData.withoutAccount?.poolFeePercentages,
    tokenPrices,
    tradingVol24h,
  ])

  return (
    <MyContext.Provider
      value={{
        baseAprWads,
        averageBoostedAprs,
        bonusTokenAprs,
        userInfos,
        boostedAprs,
        allBonusTokenRewards,
        avgFeeSharingAprs,
        interestBearingAprs,
        actions: {
          estimateBoostedApr,
        },
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

export default MasterWombatProvider

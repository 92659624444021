import { nativeToWAD, safeWdiv, strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { useMemo } from 'react'
import { TOKENS } from '../../constants/contract'
import { ASSETS, getSupportedChainIdsForCrossChainAssets } from '../../constants/contract/asset'
import { Asset } from '../../constants/contract/asset/Asset'
import { POOLS } from '../../constants/contract/pool'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { AprType } from '../../interfaces/pool'
import { getInitialAssetProperty } from '../../store/Asset/helpers'
import {
  useWithAccountData,
  useWithoutAccountData,
} from '../../store/MultiChainMultiCallData/hooks'
import { useTokenPrices } from '../../store/Prices/hooks'
import { calculateApr as calculateAprWad } from '../../utils'
import { commafyPercentage } from '../../utils/commafy'
import { calLPPrice } from '../../utils/math'
import { RewardInfo, TokenInfo } from '../PoolPage'
import DesktopInfo from './desktopInfo'
import MobileInfo from './mobileInfo'

interface PoolsOnOtherChainsProps {
  chainId: SupportedChainId
  tokenSymbol: TokenSymbol
  asset: Asset
  depositedOrStaked: boolean | null
  DISCONNECT: boolean
  hasStaked: boolean | null
}

function PoolsOnOtherChains({
  chainId,
  tokenSymbol,
  asset: token,
  depositedOrStaked,
  DISCONNECT,
  hasStaked,
}: PoolsOnOtherChainsProps) {
  const multichainDataWithAccount = useWithAccountData()
  const multichainDataWithoutAccount = useWithoutAccountData()

  const masterWombatData = multichainDataWithAccount[chainId]?.masterWombatData
  const rewards = useMemo(() => {
    return masterWombatData?.poolRewards
  }, [masterWombatData?.poolRewards])

  const asset = ASSETS[chainId][PoolLabels.CROSS_CHAIN][tokenSymbol]
  const assetData = multichainDataWithoutAccount[chainId]?.assetData
  const poolData = multichainDataWithoutAccount[chainId]?.poolData
  // const tokenPrices = multichainDataWithoutAccount[chainId]?.tokenPrices
  const tokenPrices = useTokenPrices()

  const { cashesBn, totalSupplyBn, liabilityBn, lpTokenToTokenRateBn } = useMemo(() => {
    let lpTokenToTokenRateBn = BigNumber.from(0)
    const liabilityBn = assetData?.liabilitiesBn[PoolLabels.CROSS_CHAIN][tokenSymbol]
    const totalSupplyBn = assetData?.totalSuppliesBn[PoolLabels.CROSS_CHAIN][tokenSymbol]

    if (liabilityBn && totalSupplyBn) {
      lpTokenToTokenRateBn = safeWdiv(liabilityBn, totalSupplyBn)
    }

    return {
      cashesBn:
        assetData?.cashesBn[PoolLabels.CROSS_CHAIN][tokenSymbol] ||
        getInitialAssetProperty<BigNumber>(),
      totalSupplyBn,
      liabilityBn: liabilityBn || BigNumber.from(0),
      lpTokenToTokenRateBn,
    }
  }, [assetData?.cashesBn, assetData?.liabilitiesBn, assetData?.totalSuppliesBn, tokenSymbol])

  const masterWombatDataWithoutAccount = multichainDataWithoutAccount[chainId]?.masterWombatData
  const tradingVol24h = multichainDataWithoutAccount[chainId]?.tradingVol24h
  const boostedPoolRewarderData = multichainDataWithoutAccount[chainId]?.boostedPoolRewarderData

  const {
    userInfo,
    baseAprWad,
    averageBoostedApr,
    boostedApr,
    bonusTokenApr,
    avgFeeSharingApr,
    aprsFromBoostedRewarder,
  } = useMemo(() => {
    const poolInfos = masterWombatDataWithoutAccount?.poolInfos
    const totalLpStakedBNs = masterWombatDataWithoutAccount?.totalLpStakedBNs
    const poolInfo = poolInfos?.[PoolLabels.CROSS_CHAIN][tokenSymbol]

    const rewardRateWad = poolInfo?.rewardRate
    const basePartition = masterWombatDataWithoutAccount?.basePartition
    const boostedPartition = masterWombatDataWithoutAccount?.boostedPartition

    const tokenPriceWad = strToWad(tokenPrices?.[tokenSymbol])
    const lpUnitPrice = calLPPrice(
      utils.parseEther('1'),
      lpTokenToTokenRateBn ?? null,
      tokenPriceWad
    )
    const totalLpStakedBN = totalLpStakedBNs?.[PoolLabels.CROSS_CHAIN][tokenSymbol]
    const annualWomRewardWad =
      rewardRateWad && basePartition
        ? rewardRateWad
            .mul(60 * 60 * 24 * 365)
            .mul(basePartition)
            .div(1000)
        : BigNumber.from(0)
    const womPriceWad = strToWad(tokenPrices?.WOM)

    const baseAprWad =
      poolInfo && totalLpStakedBN && lpUnitPrice && !totalLpStakedBN.isZero()
        ? calculateAprWad(
            utils.parseEther('1'),
            annualWomRewardWad,
            womPriceWad,
            totalLpStakedBN,
            lpUnitPrice
          )
        : BigNumber.from(0)

    const averageBoostedApr = boostedPartition
      ? utils.formatEther(
          safeWdiv(baseAprWad.mul(boostedPartition), strToWad(String(basePartition)))
        )
      : BigNumber.from(0)

    const boostedApr: BigNumber =
      userInfo && poolInfo && lpUnitPrice && !lpUnitPrice.isZero()
        ? calculateAprWad(
            userInfo.factor.mul(utils.parseEther('1')).div(userInfo.amount),
            annualWomRewardWad,
            womPriceWad,
            poolInfo?.sumOfFactors,
            lpUnitPrice
          )
        : BigNumber.from(0)

    const bonusTokenPerSec =
      masterWombatDataWithoutAccount?.bonusTokenPerSec[PoolLabels.CROSS_CHAIN][tokenSymbol]
    let bonusTokenApr_: BigNumber[] = []
    Object.entries(ASSETS[chainId]).forEach(([poolLabelStr, assetsInPool]) => {
      Object.entries(assetsInPool).forEach(([tokenSymbolStr, asset]) => {
        if (poolLabelStr === PoolLabels.CROSS_CHAIN && tokenSymbol === tokenSymbolStr) {
          const aprs = asset.poolRewarder?.rewardTokenSymbols?.map(
            (bonusTokenSymbol: TokenSymbol, index: number) => {
              const rewardPerSec = bonusTokenPerSec?.[index]
              const bonusToken = TOKENS[chainId][bonusTokenSymbol]
              if (!bonusToken) return BigNumber.from(0)
              if (!rewardPerSec) return BigNumber.from(0)

              // get annulized reward emission in WAD
              /** @TODO bug assuming all mainnet reward 18 decimals */
              const factorToWad = utils.parseUnits('1', 18 - bonusToken.decimals)
              const annualizedRewardEmission = rewardPerSec.mul(86400).mul(365).mul(factorToWad)
              const lpPriceWad = strToWad(tokenPrices?.[tokenSymbol])
              const lpUnitPrice = calLPPrice(
                utils.parseEther('1'),
                lpTokenToTokenRateBn ?? null,
                lpPriceWad
              )
              const rewardPriceWad = strToWad(tokenPrices?.[bonusTokenSymbol])
              const apr =
                !totalLpStakedBN ||
                totalLpStakedBN.isZero() ||
                !lpUnitPrice ||
                lpUnitPrice.isZero() ||
                !annualizedRewardEmission
                  ? BigNumber.from(0)
                  : calculateAprWad(
                      utils.parseEther('1'),
                      annualizedRewardEmission,
                      rewardPriceWad,
                      totalLpStakedBN,
                      lpUnitPrice
                    )
              return apr
            }
          )
          bonusTokenApr_ = aprs ? aprs : []
        }
      })
    })

    /**
     * @todo should refactor these codes as there are similar codes in useBoostedPoolRewarderAprs
     * Boosted Rewarder
     */
    const rewardTokenPerSecs =
      boostedPoolRewarderData?.rewardTokenPerSec[PoolLabels.CROSS_CHAIN]?.[tokenSymbol]
    const aprsFromBoostedRewarder: AprType = {
      base: [],
      average: [],
      boosted: [],
    }
    if (asset && rewardTokenPerSecs && tokenPrices) {
      asset.boostedPoolRewarder?.rewardTokenSymbols?.forEach((rewardTokenSymbol, index) => {
        const rewardPerSecWad = strToWad(rewardTokenPerSecs[index].value)
        const assetTokenPriceWad = strToWad(tokenPrices[tokenSymbol])
        const rewardTokenPriceWad = strToWad(tokenPrices[rewardTokenSymbol])
        const lpUnitPrice = calLPPrice(
          utils.parseEther('1'),
          lpTokenToTokenRateBn ?? null,
          assetTokenPriceWad
        )
        const allAnnualRewardWad = rewardPerSecWad.mul(60 * 60 * 24 * 365)
        /**
         * Base APR
         */
        if (basePartition) {
          const annualRewardWadForBase = allAnnualRewardWad.mul(basePartition).div(1000)
          const baseAprWad =
            totalLpStakedBN && lpUnitPrice && !totalLpStakedBN.isZero()
              ? calculateAprWad(
                  utils.parseEther('1'),
                  annualRewardWadForBase,
                  rewardTokenPriceWad,
                  totalLpStakedBN,
                  lpUnitPrice
                )
              : BigNumber.from(0)
          const baseApr = utils.formatEther(baseAprWad)
          let baseAprs = aprsFromBoostedRewarder?.base
          if (baseAprs) {
            baseAprs.push(baseApr)
          } else {
            baseAprs = [baseApr]
          }
          /**
           * Average APR
           */
          if (boostedPartition) {
            const averageBoostedAprWad = safeWdiv(
              baseAprWad.mul(boostedPartition),
              strToWad(String(basePartition))
            )
            const averageBoostedApr = utils.formatEther(averageBoostedAprWad)
            let averageBoostedAprs = aprsFromBoostedRewarder?.average
            if (averageBoostedAprs) {
              averageBoostedAprs.push(averageBoostedApr)
            } else {
              averageBoostedAprs = [averageBoostedApr]
            }
          }
        }
      })
    }
    const assetDecimals = asset?.decimals
    const assetTradingVol24h = tradingVol24h?.[PoolLabels.CROSS_CHAIN][tokenSymbol]?.toString()
    const lpDividendRatio = poolData?.lpDividendRatios[PoolLabels.CROSS_CHAIN]
    const poolFeePercentage = poolData?.poolFeePercentages[PoolLabels.CROSS_CHAIN]?.toString()

    const tvl = assetDecimals
      ? calLPPrice(
          nativeToWAD(liabilityBn, assetDecimals),
          lpTokenToTokenRateBn ?? null,
          strToWad(tokenPrices?.[tokenSymbol])
        )
      : BigNumber.from(0)
    const dailyApr = tvl
      ? utils.formatEther(
          safeWdiv(
            wmul(
              strToWad(assetTradingVol24h),
              wmul(strToWad(lpDividendRatio), strToWad(poolFeePercentage))
            ).mul(365),
            tvl
          )
        )
      : BigNumber.from(0)

    const avgFeeSharingApr = {
      daily: dailyApr,
    }

    return {
      userInfo: masterWombatData?.userInfos[PoolLabels.CROSS_CHAIN][tokenSymbol],
      baseAprWad,
      averageBoostedApr,
      boostedApr,
      bonusTokenApr: bonusTokenApr_,
      avgFeeSharingApr,
      aprsFromBoostedRewarder,
    }
  }, [
    asset,
    boostedPoolRewarderData?.rewardTokenPerSec,
    chainId,
    liabilityBn,
    lpTokenToTokenRateBn,
    masterWombatData?.userInfos,
    masterWombatDataWithoutAccount?.basePartition,
    masterWombatDataWithoutAccount?.bonusTokenPerSec,
    masterWombatDataWithoutAccount?.boostedPartition,
    masterWombatDataWithoutAccount?.poolInfos,
    masterWombatDataWithoutAccount?.totalLpStakedBNs,
    poolData?.lpDividendRatios,
    poolData?.poolFeePercentages,
    tokenPrices,
    tokenSymbol,
    tradingVol24h,
  ])

  const pool = POOLS[chainId].CROSS_CHAIN
  const tokenInfo = useMemo<TokenInfo | undefined>(() => {
    if (!pool) return undefined
    for (const symbol of pool.supportedAssetTokenSymbols) {
      if (symbol === tokenSymbol) {
        const cash = cashesBn ? Number(cashesBn) / 1e18 : null
        const liability = liabilityBn ? Number(liabilityBn) / 1e18 : null
        const covRatio = cash && liability ? cash / liability : null
        const baseAPR = baseAprWad ? Number(baseAprWad) / 1e18 : null
        const isBaseAprMoreThanZero = !(baseAprWad || constants.Zero).eq(0)
        const boostAPR = boostedApr ? Number(boostedApr) / 1e18 : null
        const medianBoostedApr = Number(averageBoostedApr) ?? null
        const tradingVol = tradingVol24h?.[PoolLabels.CROSS_CHAIN][tokenSymbol] ?? null

        const bonusTokens =
          (ASSETS[chainId][pool.label][tokenSymbol]?.poolRewarder?.rewardTokenSymbols || [])
            .map((symbol, index) => {
              return {
                displaySymbol: TOKENS[chainId][symbol]?.displaySymbol || '',
                symbol: symbol as TokenSymbol,
                amount:
                  rewards?.[pool.label][tokenSymbol]?.pendingBonusRewards[index] ??
                  BigNumber.from(0),
                apr: bonusTokenApr?.[index] ?? BigNumber.from(0),
              }
            })
            .filter((value): value is RewardInfo => value !== undefined) ?? null

        const avgDailyFeeSharingApr = avgFeeSharingApr?.daily || '0'
        const avgWeeklyFeeSharingApr = '0' //avgFeeSharingApr?.weekly || '0'
        /**
         * Boosted Rewarder
         */
        let totalBaseAprFromBoostedRewarder = 0
        let totalAvgBoostedAprFromBoostedRewarder = 0
        const boostedRewarderRewardData = (
          asset?.boostedPoolRewarder?.rewardTokenSymbols || []
        ).map((rewardSymbol, index) => {
          const baseApr = aprsFromBoostedRewarder.base[index] || '0'
          const averageApr = aprsFromBoostedRewarder.average[index] || '0'
          const boostedApr = aprsFromBoostedRewarder.boosted[index] || '0'
          totalBaseAprFromBoostedRewarder += +baseApr
          totalAvgBoostedAprFromBoostedRewarder += +averageApr
          return {
            displaySymbol: TOKENS[chainId][rewardSymbol]?.displaySymbol || '',
            symbol: rewardSymbol as TokenSymbol,
            amount: '0',
            baseApr,
            boostedApr,
            averageApr,
          }
        })
        // sum all APRs
        const totalBonusApr =
          bonusTokens
            ?.map((rewardInfo) => Number(rewardInfo.apr) / 1e18)
            .reduce((partialSum, a) => partialSum + a, 0) ?? 0
        // only put average daily fee sharing apr into median total apr
        const medianTotalApr =
          (baseAPR ?? 0) +
          (medianBoostedApr ?? 0) +
          totalBonusApr +
          Number(avgDailyFeeSharingApr) / 100 +
          totalAvgBoostedAprFromBoostedRewarder +
          totalBaseAprFromBoostedRewarder
        const stakedAmount = userInfo ? userInfo.amount : BigNumber.from(0)
        const STAKED = Number(stakedAmount) > 0
        // only put average daily fee sharing apr into my total apr
        const myTotalApr = STAKED
          ? (baseAPR ?? 0) + (boostAPR ?? 0) + totalBonusApr + Number(avgDailyFeeSharingApr) / 100
          : null
        const tvlInWei = tokenPrices
          ? calLPPrice(
              totalSupplyBn ?? null,
              lpTokenToTokenRateBn ?? null,
              strToWad(tokenPrices[tokenSymbol])
            )
          : BigNumber.from(0)
        const tvl = tvlInWei ? Number(utils.formatEther(tvlInWei)) : null
        const supportedChainIds =
          pool.label === PoolLabels.CROSS_CHAIN
            ? getSupportedChainIdsForCrossChainAssets(tokenSymbol)
            : [chainId]
        return {
          displaySymbol: TOKENS[chainId][tokenSymbol]?.displaySymbol || '',
          symbol: tokenSymbol,
          covRatio,
          liability,
          tvl,
          baseAPR,
          boostAPR,
          medianBoostedApr,
          medianTotalApr,
          myTotalApr,
          tradingVol24h: tradingVol,
          bonusTokens,
          isBaseAprMoreThanZero,
          avgFeeSharingApr: {
            daily: avgDailyFeeSharingApr,
            weekly: avgWeeklyFeeSharingApr,
          },
          poolLabel: pool.label,
          supportedChainIds,
          boostedRewarderRewardData,
        } as TokenInfo
      }
    }
  }, [
    aprsFromBoostedRewarder.average,
    aprsFromBoostedRewarder.base,
    aprsFromBoostedRewarder.boosted,
    asset?.boostedPoolRewarder?.rewardTokenSymbols,
    averageBoostedApr,
    avgFeeSharingApr?.daily,
    baseAprWad,
    bonusTokenApr,
    boostedApr,
    cashesBn,
    chainId,
    liabilityBn,
    lpTokenToTokenRateBn,
    pool,
    rewards,
    tokenPrices,
    tokenSymbol,
    totalSupplyBn,
    tradingVol24h,
    userInfo,
  ])

  const medianTotalAprUi = tokenInfo ? commafyPercentage(tokenInfo.medianTotalApr ?? 0) : '0'
  const womRewardUi = tokenInfo && tokenInfo.reward ? `${tokenInfo.reward?.toString()}` : '0.00'

  const avgDailyFeeSharingAprUi =
    tokenInfo && tokenInfo.avgFeeSharingApr.daily
      ? commafyPercentage(Number(tokenInfo.avgFeeSharingApr.daily) / 100)
      : '-'

  function getTotalAprUi() {
    // if user is staked, show my total apr
    if (tokenInfo && tokenInfo.myTotalApr && hasStaked && !DISCONNECT)
      return commafyPercentage(tokenInfo.myTotalApr)
    // if user is deposited or staked, show avg fee sharing apr only
    if (tokenInfo && tokenInfo.avgFeeSharingApr && depositedOrStaked) return avgDailyFeeSharingAprUi
    return '-'
  }

  return (
    <>
      {tokenInfo && (
        <>
          <div className="block bg-[#fff] text-black md:hidden">
            <MobileInfo
              chainId={chainId}
              tokenInfo={tokenInfo}
              asset={token}
              depositedOrStaked={depositedOrStaked}
              DISCONNECT={DISCONNECT}
              hasStaked={hasStaked}
              myTotalAprUi={getTotalAprUi()}
              womRewardUi={womRewardUi}
              inExpander
            />
          </div>
          <div className="hidden grid-cols-[22px,90px,1fr,115px,1fr,2fr] items-center gap-1 border-b-[2px] border-wombatPurple3 bg-[#fff] px-3 py-3 text-black md:grid lg:grid-cols-[22px,1fr,1fr,115px,,1fr,2fr]">
            <DesktopInfo
              chainId={chainId}
              tokenInfo={tokenInfo}
              asset={token}
              depositedOrStaked={depositedOrStaked}
              DISCONNECT={DISCONNECT}
              hasStaked={hasStaked}
              medianTotalAprUi={medianTotalAprUi}
              myTotalAprUi={getTotalAprUi()}
              womRewardUi={womRewardUi}
              inExpander
            />
          </div>
        </>
      )}
    </>
  )
}

export default PoolsOnOtherChains

import { PoolConfig } from '..'
import { ZERO_ADDRESS } from '../../../../common'
import { MasterWombatId } from '../../../masterWombat'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const BSC_TESTNET_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0xBD4E8B36d9Da92f0bAFa14C30994c6c0Bf74bF02',
  },
  [PoolLabels.MAIN]: {
    name: 'Main Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0x76f3378f13c6e9c5f477d1d9de2a21151e883d71',
  },
  [PoolLabels.SIDE]: {
    name: 'Side Pool',
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x32997a613fbabe911887e296c27f55d735d55084',
  },
  [PoolLabels.FACTORY_STABLES]: {
    name: 'Innovation Pool',
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x9817a92bd6b79b64974275730adbec7462d42e2f',
  },
  [PoolLabels.BNB]: {
    masterWombatId: MasterWombatId.MW2,
    filterGroup: PoolFilterGroup.UNAVALIABLE,
    address: '0x37d75ffe7f96d5f74d2b5668b1605cee17102159',
  },
  [PoolLabels.BNBx]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x7749c4737424bdaa69755e20e46ec53053dfa921',
  },
  [PoolLabels.frxETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: ZERO_ADDRESS,
  },
  [PoolLabels.axlUSDC]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xb0219a90ef6a24a237bc038f7b7a6eac5e01edb0',
  },
  [PoolLabels.CUSD]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x7f95d391e5f1a4b0a265e40bf588739bea2202c4',
  },
  [PoolLabels.iUSD]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xe2a60c52c4abb9fbc3f711da3cb9e633269dbc67',
  },
  [PoolLabels.USDD]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xb1583d73898e5f24311a1457dd4bcff051e2d427',
  },
  [PoolLabels.BOB]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x9e9d11befb6251874e04008cc2cdf9a064ad48ff',
  },
  [PoolLabels.wmxWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0x46da9508026b45ad44be42bf5a3ff60f0ed3cbcb',
  },
  [PoolLabels.mWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0xd3c8392b86db591ff95b4b8472c5344f9b237525',
  },
  [PoolLabels.qWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0xccea75f57044df96a5cbc1dc9bceda48efa22e1e',
  },
}

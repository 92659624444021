import { SupportedChainId } from '../constants/web3/supportedChainId'

export enum BridgeProgress {
  SET_SOURCE_AND_TARGET_CHAIN = 'SET_SOURCE_AND_TARGET_CHAIN',
  SET_TARGET_RECIPIENT = 'SET_TARGET_RECIPIENT',
  SEND_TOKENS = 'SEND_TOKENS',
  REDEEM_TOKENS = 'REDEEM_TOKENS',
}

export type TokenTransferVaaMetadata = {
  sourceChainId: SupportedChainId | undefined
  targetChainId: SupportedChainId | undefined
  bridgedTokenAmount: string
  toAddress: string
}

export interface UserTransferSelection {
  sourceChainId: SupportedChainId | undefined
  sourceTokenBalance: string | undefined
  targetChainId: SupportedChainId | undefined
}

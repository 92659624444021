import { useRef } from 'react'
/**
 * This is used to take a snapshot of any data.
 * It is useful for modals to make sure data won't change.
 * @param {T} target
 * @returns {T}
 */
function useSnapshot<T>(target: T): T {
  const snapshot = useRef(target)

  return snapshot.current
}

export default useSnapshot

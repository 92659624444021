import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'

export enum MODAL_STATE {
  WITHDRAW = 'withdraw',
  UNSTAKE = 'unstake',
  UNSTAKE_MW2 = 'unstakeMW2',
}
type Returns = {
  modalState: MODAL_STATE | null
  setModalState: Dispatch<SetStateAction<MODAL_STATE | null>>
  selectedPoolLabel: PoolLabels | null
  setSelectedPoolLabel: Dispatch<SetStateAction<PoolLabels | null>>
  selectedAssetTokenSymbol: TokenSymbol | null
  setSelectedAssetTokenSymbol: Dispatch<SetStateAction<TokenSymbol | null>>
  selectedStandalonePoolAddress: string
  setSelectedStandalonePoolAddress: Dispatch<SetStateAction<string>>
  resetAllStates: () => void
}
const initReturns = {
  modalState: null,
  setModalState: () => {
    return
  },
  selectedPoolLabel: null,
  setSelectedPoolLabel: () => {
    return
  },
  selectedAssetTokenSymbol: null,
  setSelectedAssetTokenSymbol: () => {
    return
  },
  selectedStandalonePoolAddress: '',
  setSelectedStandalonePoolAddress: () => {
    return ''
  },
  resetAllStates: () => {
    return
  },
}
export const WithdrawalPageContext = createContext<Returns>(initReturns)

export const useWithdrawalPageContext = () => {
  return useContext(WithdrawalPageContext)
}

export const WithdrawalPageProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalState, setModalState] = useState<MODAL_STATE | null>(null)
  const [selectedPoolLabel, setSelectedPoolLabel] = useState<PoolLabels | null>(null)
  const [selectedAssetTokenSymbol, setSelectedAssetTokenSymbol] = useState<TokenSymbol | null>(null)
  const [selectedStandalonePoolAddress, setSelectedStandalonePoolAddress] = useState<string>('')
  const resetAllStates = () => {
    setModalState(null)
    setSelectedPoolLabel(null)
    setSelectedAssetTokenSymbol(null)
  }
  return (
    <WithdrawalPageContext.Provider
      value={{
        modalState,
        setModalState,
        selectedPoolLabel,
        setSelectedPoolLabel,
        selectedAssetTokenSymbol,
        setSelectedAssetTokenSymbol,
        selectedStandalonePoolAddress,
        setSelectedStandalonePoolAddress,
        resetAllStates,
      }}
    >
      {children}
    </WithdrawalPageContext.Provider>
  )
}

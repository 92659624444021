import { WppMultiple } from '../../../components/WppBadge'
import { HexString } from '../../../interfaces/contract'
import { SupportedChainId } from '../../web3/supportedChainId'
import { Contract } from '../Contract'
import { ASSET_ABI } from '../abis/asset'
import { PoolLabels } from '../pool/PoolLabels'
import { TokenSymbol } from '../token/TokenSymbols'
type RewarderType = {
  rewardTokenAddresses?: HexString[]
  rewardTokenSymbols?: TokenSymbol[]
  rewarderAddress?: HexString
}

type ConstructorProps = {
  pid: number
  symbol: TokenSymbol
  name: string
  chainId: SupportedChainId
  address: HexString
  poolLabel: PoolLabels
  color: string
  displaySymbol?: string
  decimals?: number
  delisted?: boolean
  standalonePoolAddress?: string
  paused?: boolean
  enableVoting?: boolean
  bribeRewarder?: RewarderType
  poolRewarder?: RewarderType
  boostedPoolRewarder?: RewarderType
  interestBearingAprEndpoint?: string
  wppBadge?: WppMultiple
  hasBribeV2?: boolean
}
// Unlike other contracts, each Asset Contract correspond to one chain only
export class Asset extends Contract<typeof ASSET_ABI> {
  readonly decimals: number
  readonly name: string
  readonly symbol: TokenSymbol
  readonly poolLabel: PoolLabels
  readonly displaySymbol: string
  readonly enableVoting: boolean
  readonly color: string
  // show in delisted page etc. bnb pool: without abnbc
  readonly delisted: boolean
  readonly paused: boolean
  // Does this asset exist in what standalone pool?
  readonly standalonePoolAddress?: string
  bribeRewarder?: RewarderType
  poolRewarder?: RewarderType
  boostedPoolRewarder?: RewarderType
  readonly pid: number
  interestBearingAprEndpoint?: string
  wppBadge?: WppMultiple
  // whether the asset has a bribe rewarder v2
  readonly hasBribeV2: boolean
  constructor({
    pid,
    symbol,
    name,
    chainId,
    address,
    poolLabel,
    color,
    displaySymbol = symbol,
    decimals = 18,
    paused = false,
    delisted = false,
    enableVoting = poolLabel !== PoolLabels.MAIN && paused === false,
    bribeRewarder,
    poolRewarder,
    boostedPoolRewarder,
    interestBearingAprEndpoint,
    wppBadge,
    hasBribeV2 = false,
    standalonePoolAddress,
  }: ConstructorProps) {
    super({ address, chainId, abi: ASSET_ABI })
    this.pid = pid
    this.symbol = symbol
    this.displaySymbol = displaySymbol
    this.decimals = decimals
    this.name = name
    this.poolLabel = poolLabel
    this.color = color
    this.paused = paused
    this.delisted = delisted
    this.standalonePoolAddress = standalonePoolAddress
    // Treasury put enough veWom for voting in main pool, so we decide to hide it.
    this.enableVoting = enableVoting
    this.bribeRewarder = bribeRewarder
    this.poolRewarder = poolRewarder
    this.boostedPoolRewarder = boostedPoolRewarder
    this.interestBearingAprEndpoint = interestBearingAprEndpoint
    this.wppBadge = wppBadge
    this.hasBribeV2 = hasBribeV2
  }

  setBribeRewarder(bribeRewarder: RewarderType | undefined): void {
    this.bribeRewarder = bribeRewarder
  }
}

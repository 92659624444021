import React from 'react'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import Image from 'next/image'
import vector from '../../public/assets/icons/vertor-between-button.svg'
import { TOKENS } from '../../constants/contract'
import TokenImage from '../../components/TokenImage'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { chainInfos } from '../../constants/web3/chains'

interface ISwapRoute {
  tokenRoute: { tokenSymbol: TokenSymbol; chainId: SupportedChainId }[]
  enableChainLabel?: boolean
}

export const SwapRoute = ({ tokenRoute, enableChainLabel = false }: ISwapRoute) => {
  return (
    <div className="flex">
      {tokenRoute.map(({ tokenSymbol, chainId }, index) => {
        return (
          <div className="flex" key={index}>
            <div className="flex flex-col items-center text-center">
              <TokenImage key={index} tokenSymbol={tokenSymbol} width="20" height="20" />
              <div className="text-xs">
                {TOKENS[chainId][tokenSymbol]?.displaySymbol}
                {enableChainLabel && (
                  <>
                    <br />[{chainInfos[chainId].label}]
                  </>
                )}
              </div>
            </div>
            {tokenRoute.length != index + 1 && (
              <div className="mx-1">
                <Image width={30} height={30} src={vector} />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

import { Contract } from './Contract'
import { MULTI_REWARDER_ABI } from './abis/multiRewarder'
import { IContractConstructor } from '../../interfaces/contract'
import { BOOSTED_MULTI_REWARDER_ABI } from './abis/boostedMultiRewarder'

export class MultiRewarder extends Contract<typeof MULTI_REWARDER_ABI> {
  constructor({ chainId, address }: IContractConstructor) {
    super({ address, chainId, abi: MULTI_REWARDER_ABI })
  }
}

export class BoostedMultiRewarder extends Contract<typeof BOOSTED_MULTI_REWARDER_ABI> {
  constructor({ chainId, address }: IContractConstructor) {
    super({ address, chainId, abi: BOOSTED_MULTI_REWARDER_ABI })
  }
}

import Image from 'next/image'
import React, { useState } from 'react'
import EditPen from '../../public/assets/icons/edit-pen.svg'
import TickIcon from '../TickIcon'
import { utils } from 'ethers'

interface SwapRecipientBox {
  recipientAddress?: string
  setRecipientAddress: React.Dispatch<React.SetStateAction<string>>
  hasInvalidAddress: boolean
  onHasInvalidAddressChange: (isInvalid: boolean) => void
}

const SwapRecipientBox = ({
  recipientAddress,
  setRecipientAddress,
  hasInvalidAddress,
  onHasInvalidAddressChange,
}: SwapRecipientBox) => {
  const [isEditActive, setIsEditActive] = useState<boolean>(false)

  const handleTickIconOnClick = async () => {
    if (!recipientAddress) return
    try {
      // Address validity checking
      const isAddressValid = utils.getAddress(recipientAddress)
      if (isAddressValid) {
        /** Escape edit mode when address in valid */
        setIsEditActive(false)
      }
    } catch (error) {
      onHasInvalidAddressChange(true)
    }
  }

  const handleRecipientAddressOnChange = (value: string) => {
    onHasInvalidAddressChange(false)
    setRecipientAddress(value)
  }

  return (
    <div className="grid grid-cols-[0.3fr_0.7fr] items-center gap-[2px_8px] text-[12px]">
      <div className="text-right font-semibold">Recipient:</div>
      <div
        className={`flex items-center justify-end gap-2 rounded-[4px] ${
          isEditActive ? 'bg-white' : 'bg-wombatYellow4'
        } py-[6px] px-[8px] text-[12px] ${
          hasInvalidAddress ? 'border-2 border-solid border-wombatRed' : ''
        }`}
      >
        <input
          className={`w-full bg-transparent text-left text-wombatBrown1 placeholder-wombatPurple3
          focus:outline-none`}
          value={recipientAddress}
          onChange={(e) => handleRecipientAddressOnChange(e.target.value)}
          disabled={!isEditActive}
        />
        {!isEditActive && (
          <Image
            layout="fixed"
            width={16}
            height={16}
            src={EditPen}
            className="cursor-pointer"
            onClick={() => setIsEditActive((prev) => !prev)}
          />
        )}
        {isEditActive && (
          <TickIcon handleIconOnClick={handleTickIconOnClick} disabled={hasInvalidAddress} />
        )}
      </div>
      {hasInvalidAddress && <div className="col-start-2 text-wombatRed">Invalid Address</div>}
    </div>
  )
}

export default SwapRecipientBox

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SelfServiceBribeForm, { GaugeInfo } from '../SelfServiceBribeForm'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { TOKENS } from '../../../constants/contract'
import { useWeb3 } from '../../../context/Web3Context'
import { getDpFormat } from '../../../utils/numberFormat'
import Button from '../../Button'
import { ASSETS, assetListMap } from '../../../constants/contract/asset'
import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import {
  getAssetsInfoByDeployerOrOperator,
  getWhitelistedRewardTokens,
} from '../../../utils/voting'
import useSelfServiceBribe from '../../../hooks/bribe/useSelfServiceBribe'
import { SECONDS_PER_WEEK } from '../../../constants/common'
import { useSelfServiceBribePageContext } from '../../../context/SelfServiceBribePageContext'
import {
  useBribeConfigData,
  useBoostedPoolRewarderData,
  useVotingData,
} from '../../../store/MulticallData/hooks'

const getNextNearestHour = (date: Date) => {
  // get the next nearest hour
  date.setHours(date.getHours() + 1, 0, 0, 0)
  return date
}

const getEndDateAfterNDays = (date: Date, days: number) => {
  return new Date(date.getTime() + 86400 * days * 1000)
}
function AddBribeForm() {
  const { chainId } = useWeb3()
  const votingData = useVotingData()
  const bribeConfigData = useBribeConfigData()
  const poolRewarderData = useBoostedPoolRewarderData()
  const { isToShowBribeData } = useSelfServiceBribePageContext()
  const label = isToShowBribeData ? 'Bribe' : 'Rewarder'
  const {
    addNewBribeRewarder,
    addNewBribeToken,
    isAddingNewBribeRewarder,
    isAddingNewBribeToken,
    isToppingUpBribe,
    isBribeRewarderDeployed,
  } = useSelfServiceBribe()
  const [selectedGauge, setSelectedGauge] = useState<GaugeInfo | null>(null)
  const [selectedRewardTokenSymbol, setSelectedRewardTokenSymbol] = useState<TokenSymbol | null>(
    null
  )
  const [rewardAmountInputted, setRewardAmountInputted] = useState('')
  const [durationInputted, setDurationInputted] = useState('7')
  /**
   * Related to start and end dates
   */
  const currentDate = getNextNearestHour(new Date())
  const [startDateInputted, setStartDateInputted] = useState<Date | null>(currentDate)
  const [endDateInputted, setEndDateInputted] = useState<Date | null>(
    // the default end date is 7 days after the start date
    getEndDateAfterNDays(currentDate, 7)
  )

  const [isAddingBribe, setIsAddingBribe] = useState(false)
  const [isFlowCompleted, setIsFlowCompleted] = useState(false)
  const [buttonText, setButtonText] = useState(`Add ${label}`)
  const selectedRewardTokenDisplaySymbol = selectedRewardTokenSymbol
    ? TOKENS[chainId][selectedRewardTokenSymbol]?.displaySymbol
    : ''

  const filteredAssetList = useMemo(() => {
    const assetList = assetListMap[chainId]
    if (isToShowBribeData) {
      const isbribeDeployerOfEachAsset = votingData.withAccount?.isbribeDeployerOfEachAsset
      const isUserHasBribeRoleOfEachAsset = bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset

      return getAssetsInfoByDeployerOrOperator(
        isbribeDeployerOfEachAsset,
        isUserHasBribeRoleOfEachAsset,
        assetList,
        'bribeRewarder'
      )
    } else {
      return getAssetsInfoByDeployerOrOperator(
        poolRewarderData.withAccount?.hasDeployerRoleOfEachAsset,
        poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
        assetList,
        'boostedPoolRewarder'
      )
    }
  }, [
    chainId,
    isToShowBribeData,
    votingData.withAccount?.isbribeDeployerOfEachAsset,
    bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset,
    poolRewarderData.withAccount?.hasDeployerRoleOfEachAsset,
    poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
  ])

  const availableWhitelistedRewardTokens = useMemo(() => {
    const tokenList = TOKENS[chainId]
    const whitelistedRewardTokenSymbols = getWhitelistedRewardTokens(
      tokenList,
      votingData.withoutAccount?.whitelistedRewardTokens
    )
    let filteredWhitelistedRewardTokens = whitelistedRewardTokenSymbols

    if (selectedGauge) {
      const { assetTokenSymbol, poolLabel } = selectedGauge
      const asset = ASSETS[chainId][poolLabel][assetTokenSymbol]

      // tokens that have already been added to the rewarder
      const rewardTokenSymbols = isToShowBribeData
        ? asset?.bribeRewarder?.rewardTokenSymbols
        : asset?.boostedPoolRewarder?.rewardTokenSymbols

      // merge two arrays
      const mergedArray = [...whitelistedRewardTokenSymbols, ...(rewardTokenSymbols ?? [])]

      // if the token is added to the rewarder, we won't show it in the dropdown
      // include only items that occur exactly once
      filteredWhitelistedRewardTokens = mergedArray.filter(
        (item, index, array) => array.indexOf(item) === array.lastIndexOf(item)
      )
      console.log({ whitelistedRewardTokenSymbols })
    }
    return filteredWhitelistedRewardTokens
  }, [
    chainId,
    votingData.withoutAccount?.whitelistedRewardTokens,
    selectedGauge,
    isToShowBribeData,
  ])

  const handleAddBribe = async () => {
    setIsAddingBribe(true)

    if (!selectedGauge || !selectedRewardTokenSymbol) return
    const { assetTokenSymbol, poolLabel } = selectedGauge
    const tokenPerSec = (+weeklyEmission / SECONDS_PER_WEEK).toString()
    const isPoolRewarder = !isToShowBribeData
    const isDeployed = await isBribeRewarderDeployed({
      poolLabel,
      assetTokenSymbol,
      isPoolRewarder,
    })
    const bribeArgs = {
      poolLabel,
      assetTokenSymbol,
      amount: rewardAmountInputted,
      rewardTokenSymbol: selectedRewardTokenSymbol,
      tokenPerSec,
      startDate: startDateInputted,
    }
    const rewarderType = isPoolRewarder ? 'boostedPoolRewarder' : 'bribeRewarder'
    if (isDeployed) {
      await addNewBribeToken({
        ...bribeArgs,
        rewarderType,
      })
    } else {
      await addNewBribeRewarder({ ...bribeArgs, rewarderType })
    }

    setIsAddingBribe(false)
    clearInputs()
  }

  const clearInputs = useCallback(() => {
    setDurationInputted('7')
    const currentDate = getNextNearestHour(new Date())
    setStartDateInputted(currentDate)
    setEndDateInputted(getEndDateAfterNDays(currentDate, 7))
    setSelectedGauge(null)
    setSelectedRewardTokenSymbol(null)
    setRewardAmountInputted('')
    setButtonText(`Add ${label}`)
  }, [label])

  const hasSelectedGaugeAndReward = selectedGauge && selectedRewardTokenSymbol
  const isReadyToAddBribe = !!(
    hasSelectedGaugeAndReward &&
    rewardAmountInputted &&
    durationInputted
  )

  const weeklyEmission = getDpFormat((+rewardAmountInputted / +durationInputted) * 7, 2)
  useEffect(() => {
    clearInputs()
    // clear inputs when chainId is changed or users toggle between Bribe and Rewarder tabs
  }, [chainId, clearInputs, isToShowBribeData])

  useEffect(() => {
    if (isAddingNewBribeRewarder) {
      setButtonText(`Adding ${label}`)
    }
    if (isAddingNewBribeToken) {
      setButtonText(`Adding Reward`)
    }

    if (isToppingUpBribe) {
      setButtonText('Sending Tokens')
    }
  }, [isAddingNewBribeRewarder, isAddingNewBribeToken, isToppingUpBribe, label])

  return (
    <div className="container--drop-shadow flex w-[525px] flex-col p-[32px]">
      <p className="mb-5 text-base font-semibold">Add {label}</p>
      <SelfServiceBribeForm
        onFlowChange={(isCompleted) => {
          setIsFlowCompleted(isCompleted)
        }}
        guageControl={{
          value: selectedGauge,
          onClick: (value) => {
            setSelectedGauge(value || null)
            setSelectedRewardTokenSymbol(null)
          },
          dropdownOptions: filteredAssetList,
          disabled: filteredAssetList.length <= 0,
        }}
        rewardTokenControl={{
          value: selectedRewardTokenSymbol,
          onClick: (value) => {
            setSelectedRewardTokenSymbol(value || null)
          },
          disabled: !selectedGauge || availableWhitelistedRewardTokens.length <= 0,
          dropdownOptions: availableWhitelistedRewardTokens,
        }}
        rewardAmountControl={{
          value: rewardAmountInputted,
          onChange: (value) => setRewardAmountInputted(value),
          unit: selectedRewardTokenDisplaySymbol,
          onMaxBtnClick: (mockRewardTokenBalance) =>
            setRewardAmountInputted(mockRewardTokenBalance),
          disabled: !selectedRewardTokenSymbol,
        }}
        durationControl={{
          value: durationInputted,
          startDate: startDateInputted,
          endDate: endDateInputted,
          onChange: (startDate, endDate, duration) => {
            setStartDateInputted(startDate)
            if (startDate && endDate) {
              // make sure the time in the end date is same as the start date
              endDate.setHours(startDate.getHours())
            }
            setEndDateInputted(endDate)
            setDurationInputted(duration)
          },
          disabled: !selectedRewardTokenSymbol,
        }}
      />
      <div className="mt-4 flex flex-col gap-3 text-sm font-medium">
        <div className="flex flex-row justify-between">
          <p>Duration</p>
          <p>{durationInputted && hasSelectedGaugeAndReward ? `${durationInputted} days` : '-'}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p>Weekly Emission:</p>
          <p>
            {rewardAmountInputted && durationInputted
              ? `${getCommifiedFormat(weeklyEmission, 2)} ${selectedRewardTokenDisplaySymbol}`
              : '-'}
          </p>
        </div>
      </div>
      <Button
        className="mt-5 mr-auto ml-auto w-[200px] py-[4px]"
        onClick={handleAddBribe}
        isLoading={isAddingBribe}
        disabled={!isReadyToAddBribe || !isFlowCompleted}
      >
        <span className="text-sm">{buttonText}</span>
      </Button>
    </div>
  )
}

export default AddBribeForm

import { getDpFormat, getMillifiedFormat } from '@hailstonelabs/big-number-utils'
import React from 'react'
import { useVotingData } from '../../../context/VotingDataContext'
import useBreakpoints from '../../../hooks/useBreakpoints'
import AppTooltip from '../../Tooltip/AppTooltip'

function YourVoteAllocationBar() {
  const { isSm } = useBreakpoints()
  const {
    user: {
      usedVote: { percentage: usedVotesPercentage },
      remainingVote,
    },
  } = useVotingData()
  return (
    <div className="font-Work">
      <p className="mb-4 text-base font-semibold">Your Vote Allocation</p>
      <div className="flex flex-row justify-between gap-2 text-sm">
        <span>
          Used votes:{' '}
          <span className="inline-flex">
            {getDpFormat(usedVotesPercentage, 2)}%
            <AppTooltip message="The percentage of veWOM you used to vote." />
          </span>
        </span>
        <span className="text-right">
          Remaining votes:{' '}
          <span className="inline-flex">
            <AppTooltip message={`${getDpFormat(remainingVote.vewom, 2)} veWOM`}>
              {getMillifiedFormat(remainingVote.vewom)} veWOM
            </AppTooltip>
          </span>
        </span>
      </div>
      <div className="relative mt-1 h-4 w-full bg-linearGradientApprove ">
        <div
          className="absolute top-0 right-0 h-full bg-[#D9D9D9]"
          // 100% - used votes% to show correct bar
          style={{ width: `${100 - Number(usedVotesPercentage)}%` }}
        >
          {/* only show the label when the percentage is greater than 10 as there is no space for small percentage */}
          {+usedVotesPercentage >= 10 && !isSm && (
            <p className="absolute left-[-60px] top-[-2px] text-sm">
              {getDpFormat(usedVotesPercentage, 2)}%
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default YourVoteAllocationBar

import { BigNumber, utils } from 'ethers'

export function sqrt(x: BigNumber) {
  const ONE = BigNumber.from(1)
  const TWO = BigNumber.from(2)

  let z = x.add(ONE).div(TWO)
  let y = x
  while (z.sub(y).isNegative()) {
    y = z
    z = x.div(z).add(z).div(TWO)
  }
  return y
}

/** @TODO refactor calLpPrice split to lpTokenToToken  https://www.notion.so/Refactor-calLpPrice-a1d0ac7ab39e45ae805a3103c37f7409 */
export function calLPPrice(
  amount: BigNumber | null,
  exchangeRate: BigNumber | null,
  tokenPrice?: BigNumber | null
): BigNumber | null {
  if (!amount || !exchangeRate) {
    return null
  }
  // lp token amount to token amount
  amount = amount.mul(exchangeRate).div(utils.parseEther('1'))
  // if no token price, assume the token price is 1
  if (tokenPrice) {
    amount = amount.mul(tokenPrice).div(utils.parseEther('1'))
  }

  return amount
}

import React, { createContext, ReactElement, useCallback, useContext } from 'react'
import useLocalStorage from '../hooks/useLocalStorage'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { CROSS_CHAIN_GAS_RECEIVING_AMOUNT } from '../constants/web3/chains'
type IGasOnDestinationChain = {
  [id in SupportedChainId]: string
}
interface UserPreferenceType {
  slippage: string
  transactionDeadline: string
  withdrawal: boolean
  gasOnDestinationChain: IGasOnDestinationChain
  isRecipientShown: boolean
}
const initGasOnDestinationChain: IGasOnDestinationChain = Object.entries(
  CROSS_CHAIN_GAS_RECEIVING_AMOUNT
).reduce((prev, curr) => {
  return {
    ...prev,
    [Number(curr[0]) as SupportedChainId]: curr[1].defaultAmount,
  }
}, {} as IGasOnDestinationChain)
interface ContextType {
  userPreference: UserPreferenceType
  initialUserPreference: UserPreferenceType
  actions: {
    updateSlippage: (value: string) => void
    updateTransactionDeadline: (value: string) => void
    updateWithdrawal: (value: boolean) => void
    setValueToLocalStorage: (
      value: UserPreferenceType | ((value: UserPreferenceType) => UserPreferenceType)
    ) => void
    updateGasOnDestinationChain: (value: string, chainId: SupportedChainId) => void
    updateIsRecipientShown: (value: boolean) => void
  }
}

const initialUserPreference: UserPreferenceType = {
  slippage: '0.02',
  transactionDeadline: '300',
  withdrawal: false,
  gasOnDestinationChain: initGasOnDestinationChain,
  isRecipientShown: false,
}
const UserPreferenceContext = createContext<ContextType>({
  userPreference: initialUserPreference,
} as ContextType)

interface Props {
  children: React.ReactNode
}

export const useUserPreference = (): ContextType => {
  return useContext(UserPreferenceContext)
}

function UserPreferenceProvider({ children }: Props): ReactElement {
  // persist data
  const [storedValue, setValueToLocalStorage] = useLocalStorage<UserPreferenceType>({
    key: 'userPreference',
    initialValue: initialUserPreference,
  })
  const updateSlippage = useCallback(
    (value: string) => {
      // when the value is empty, always save the default value to the local storage
      setValueToLocalStorage((prev) => ({
        ...prev,
        slippage: value,
      }))
    },
    [setValueToLocalStorage]
  )

  const updateTransactionDeadline = useCallback(
    (value: string) => {
      // when the value is empty, always save the default value to the local storage
      setValueToLocalStorage((prev) => ({
        ...prev,
        transactionDeadline: value === '' ? initialUserPreference.transactionDeadline : value,
      }))
    },
    [setValueToLocalStorage]
  )

  const updateWithdrawal = useCallback(
    (value: boolean) => {
      // when the value is empty, always save the default value to the local storage
      setValueToLocalStorage((prev) => ({
        ...prev,
        withdrawal: value,
      }))
    },
    [setValueToLocalStorage]
  )

  const updateGasOnDestinationChain = useCallback(
    (value: string, chainId: SupportedChainId) => {
      // when the value is empty, always save the default value to the local storage
      setValueToLocalStorage((prev) => ({
        ...prev,
        gasOnDestinationChain: {
          ...prev.gasOnDestinationChain,
          [chainId]: value,
        },
      }))
    },
    [setValueToLocalStorage]
  )

  const updateIsRecipientShown = (value: boolean) => {
    setValueToLocalStorage((prev) => ({
      ...prev,
      isRecipientShown: value,
    }))
  }
  return (
    <UserPreferenceContext.Provider
      value={{
        userPreference: storedValue,
        initialUserPreference: initialUserPreference,
        actions: {
          updateSlippage,
          setValueToLocalStorage,
          updateTransactionDeadline,
          updateWithdrawal,
          updateGasOnDestinationChain,
          updateIsRecipientShown,
        },
      }}
    >
      {children}
    </UserPreferenceContext.Provider>
  )
}

export default UserPreferenceProvider

import { createAsyncThunk } from '@reduxjs/toolkit'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { TokenPricesState } from '../slice'
import { fetchPythPriceData } from '../../../context/MulticallDataContext/helpers/fetchTokenPricesHelper'

const getPythPriceData = createAsyncThunk<
  Pick<TokenPricesState, 'pythPrices'>,
  {
    chainId: SupportedChainId
  }
>('tokenPrices/getPythPriceData', async ({ chainId }) => {
  const res = await fetchPythPriceData(chainId)
  return {
    pythPrices: res || {},
  }
})

export default getPythPriceData

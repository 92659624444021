import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import useTransfer from '../hooks/bridge/useTransfer'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { ContractReceipt } from 'ethers'
import { fetchIsRedeemCompletedFn, getTokenTransferVaaMetadata } from '../utils/bridge'

type ContextType = {
  toAddress: string
  receipt: ContractReceipt | null
  signedVaa: Uint8Array | null
  sourceChainId: SupportedChainId
  targetChainId: SupportedChainId
  isFetchingSignedVaa: boolean
  bridgedTokenAmount: string
  isRecoveringTransferReceipt: boolean
  updateSourceChainId: (chainId: SupportedChainId) => void
  recoverTransferReceipt: (txnHash: string) => Promise<Uint8Array | undefined>
  fetchIsRedeemCompleted: (signedVaa: Uint8Array | null) => Promise<boolean | undefined>
}
const initialContext: ContextType = {
  toAddress: '',
  receipt: null,
  signedVaa: null,
  sourceChainId: SupportedChainId.BSC_MAINNET,
  targetChainId: SupportedChainId.ARBITRUM_ONE,
  isFetchingSignedVaa: false,
  bridgedTokenAmount: '',
  isRecoveringTransferReceipt: false,
  updateSourceChainId: () => {
    //
  },
  recoverTransferReceipt: async () => {
    return Promise.resolve(undefined)
  },
  fetchIsRedeemCompleted: async () => {
    return Promise.resolve(false)
  },
}

const BridgePageContext = createContext<ContextType>(initialContext)
BridgePageContext.displayName = 'BridgeRedeemContext'

export const useBridgeRedeemContext = (): ContextType => {
  return useContext(BridgePageContext)
}
function BridgeRedeemProvider({ children }: { children: ReactElement }) {
  const [toAddress, setToAddress] = useState('')
  const [sourceChainId, setSourceChainId] = useState(initialContext.sourceChainId)
  const [targetChainId, setTargetChainId] = useState(initialContext.targetChainId)
  const [bridgedTokenAmount, setBridgeTokenAmount] = useState('')
  const {
    receipt,
    signedVaa,
    isFetchingSignedVaa,
    isRecoveringTransferReceipt,
    recoverTransferReceipt,
  } = useTransfer({
    sourceChainId,
    targetChainId,
  })

  /**
   * Recover data from signedVaa
   */
  useEffect(() => {
    const updateTokenTransferDataFromSignedVaa = () => {
      if (!signedVaa) return
      const { sourceChainId, targetChainId, bridgedTokenAmount, toAddress } =
        getTokenTransferVaaMetadata(signedVaa)
      sourceChainId && setSourceChainId(sourceChainId)
      targetChainId && setTargetChainId(targetChainId)
      bridgedTokenAmount && setBridgeTokenAmount(bridgedTokenAmount)
      toAddress && setToAddress(toAddress)
    }

    updateTokenTransferDataFromSignedVaa()
  }, [signedVaa])

  /**
   * Fetch redeem completation for recovered signedVaa
   */
  const fetchIsRedeemCompleted = useCallback(
    async (signedVaa: Uint8Array | null) => {
      return fetchIsRedeemCompletedFn(targetChainId, signedVaa)
    },
    [targetChainId]
  )

  const updateSourceChainId = (chainId: SupportedChainId) => {
    setSourceChainId(chainId)
  }

  return (
    <BridgePageContext.Provider
      value={{
        toAddress,
        receipt,
        signedVaa,
        sourceChainId,
        targetChainId,
        isFetchingSignedVaa,
        isRecoveringTransferReceipt,
        bridgedTokenAmount,
        updateSourceChainId,
        recoverTransferReceipt,
        fetchIsRedeemCompleted,
      }}
    >
      {children}
    </BridgePageContext.Provider>
  )
}

export default BridgeRedeemProvider

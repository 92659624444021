import { createAsyncThunk } from '@reduxjs/toolkit'
import { MigrationDetectionState, UserInfoSCType } from '../types'
import { CallbacksType, executeCallBacks, IContractCalls } from '../../../utils/executeCallBacks'
import { ASSETS } from '../../../constants/contract/asset'
import { POOLS } from '../../../constants/contract/pool'
import { MASTER_WOMBATS } from '../../../constants/contract'
import { pidsForMigration } from '../../../constants/migration'
import { BigNumber, utils } from 'ethers'
import { strToWad } from '@hailstonelabs/big-number-utils'
import { multicall } from '@wagmi/core'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'

const updateAllDelistedData = createAsyncThunk<
  Pick<MigrationDetectionState, 'delistedData'>,
  {
    chainId: SupportedChainId
    account: `0x${string}` | null
  }
>('MigrationDetection/updateAllDelistedData', async ({ chainId, account }) => {
  const initDelistedData: MigrationDetectionState['delistedData'] = {}
  const fetchUserInfo = async () => {
    const contractCalls: IContractCalls = []
    const callbacks: CallbacksType = []

    if (!account) return
    Object.values(ASSETS[chainId]).forEach((assetsInThePool) => {
      Object.values(assetsInThePool).forEach((asset) => {
        const poolLabel = asset.poolLabel
        const masterWombatId = POOLS[chainId][poolLabel]?.masterWombatId
        if (!asset.delisted || !masterWombatId) return
        const masterWombatAddress = MASTER_WOMBATS[chainId]?.[masterWombatId]?.address
        if (!masterWombatAddress) return
        const pid =
          pidsForMigration[chainId][masterWombatAddress.toLowerCase()]?.[
            asset.address.toLowerCase()
          ]
        if (pid === undefined) return
        const assetDecimal = asset.decimals
        const assetPidBN = BigNumber.from(pid)
        const assetTokenSymbol = asset.symbol
        const masterWombatContract = masterWombatId
          ? MASTER_WOMBATS?.[chainId]?.[masterWombatId]
          : null
        const assetDecimals = ASSETS[chainId][poolLabel][assetTokenSymbol]?.decimals
        if (!masterWombatId || !masterWombatContract || !assetDecimals) return
        const lpAmountInTermsOfLp = {
          ...initDelistedData[poolLabel]?.[assetTokenSymbol]?.lpAmountInTermsOfLp,
        }
        contractCalls.push(masterWombatContract.multicall('userInfo', [assetPidBN, account]))
        callbacks.push((value) => {
          const { amount: stakedAmountBN, pendingWom: pendingWomWad } = value as UserInfoSCType
          const stakedAmount = utils.formatUnits(stakedAmountBN, assetDecimals)
          lpAmountInTermsOfLp.staked = stakedAmount
          const totalLpAmountWad = strToWad(stakedAmount).add(
            strToWad(lpAmountInTermsOfLp.stakable)
          )
          lpAmountInTermsOfLp.total = utils.formatEther(totalLpAmountWad)
          const isWithdrawalNeeded = totalLpAmountWad.gt('0')
          initDelistedData[poolLabel] = {
            ...initDelistedData[poolLabel],
            [assetTokenSymbol]: {
              isWithdrawalNeeded,
              pendingWom: utils.formatEther(pendingWomWad),
              lpAmountInTermsOfLp,
              pid: assetPidBN.toString(),
              asset,
            },
          }
        })
        contractCalls.push(asset.multicall('balanceOf', [account]))
        callbacks.push((valueBN) => {
          const lpTokenBalance = utils.formatUnits(valueBN, assetDecimal)
          const lpAmountInTermsOfLp = {
            staked: initDelistedData[poolLabel]?.[asset.symbol]?.lpAmountInTermsOfLp.staked,
            stakable: lpTokenBalance,
            total: utils.formatEther(
              strToWad(initDelistedData[poolLabel]?.[asset.symbol]?.lpAmountInTermsOfLp.staked).add(
                strToWad(lpTokenBalance)
              )
            ),
          }
          initDelistedData[poolLabel] = {
            ...initDelistedData[poolLabel],
            [asset.symbol]: {
              ...initDelistedData[poolLabel]?.[asset.symbol],
              isWithdrawalNeeded:
                initDelistedData[poolLabel]?.[asset.symbol]?.isWithdrawalNeeded || valueBN.gt('0'),
              lpAmountInTermsOfLp,
            },
          }
        })
      })
    })

    try {
      const values = await multicall({ contracts: contractCalls, chainId, allowFailure: true })
      executeCallBacks(values, callbacks)
    } catch (error) {
      console.debug(error)
    }
  }
  await fetchUserInfo()
  return {
    delistedData: initDelistedData,
  }
})

export default updateAllDelistedData

import { useAppSelector } from '../hooks'
import {
  selectCoingeckoPrices,
  selectExchangeRatesFromUniswapV3,
  selectPythPrices,
  selectTokenPrices,
} from './selectors'

export const useTokenPrices = () => {
  return useAppSelector(selectTokenPrices)
}

export const useExchangeRatesFromUniswapV3 = () => {
  return useAppSelector(selectExchangeRatesFromUniswapV3)
}

export const usePythPrices = () => {
  return useAppSelector(selectPythPrices)
}

export const useCoingeckoPrices = () => {
  return useAppSelector(selectCoingeckoPrices)
}

export const BRIBE_FACTORY_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'previousAdmin',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'newAdmin',
        type: 'address',
      },
    ],
    name: 'AdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'beacon',
        type: 'address',
      },
    ],
    name: 'BeaconUpgraded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'implementation',
        type: 'address',
      },
    ],
    name: 'Upgraded',
    type: 'event',
  },
  {
    stateMutability: 'payable',
    type: 'fallback',
  },
  {
    inputs: [],
    name: 'admin',
    outputs: [
      {
        internalType: 'address',
        name: 'admin_',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'implementation',
    outputs: [
      {
        internalType: 'address',
        name: 'implementation_',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newImplementation',
        type: 'address',
      },
    ],
    name: 'upgradeTo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newImplementation',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes',
      },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    stateMutability: 'payable',
    type: 'receive',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_startTimestamp',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'contract IERC20',
        name: '_rewardToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint96',
        name: '_tokenPerSec',
        type: 'uint96',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'bribe',
        type: 'address',
      },
    ],
    name: 'DeployBribeContract',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: '_startTimestamp',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'contract IERC20',
        name: '_rewardToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint96',
        name: '_tokenPerSec',
        type: 'uint96',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'rewarder',
        type: 'address',
      },
    ],
    name: 'DeployRewarderContract',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IBeacon',
        name: 'beacon',
        type: 'address',
      },
    ],
    name: 'SetBribeBeacon',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'bribe',
        type: 'address',
      },
    ],
    name: 'SetBribeContract',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IBeacon',
        name: 'beacon',
        type: 'address',
      },
    ],
    name: 'SetRewarderBeacon',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'rewarder',
        type: 'address',
      },
    ],
    name: 'SetRewarderContract',
    type: 'event',
  },
  {
    inputs: [],
    name: 'bribeBeacon',
    outputs: [
      {
        internalType: 'contract IBeacon',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '',
        type: 'address',
      },
    ],
    name: 'bribeDeployers',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_startTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'contract IERC20',
        name: '_rewardToken',
        type: 'address',
      },
      {
        internalType: 'uint96',
        name: '_tokenPerSec',
        type: 'uint96',
      },
    ],
    name: 'deployBribeContract',
    outputs: [
      {
        internalType: 'address',
        name: 'bribe',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_startTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'contract IERC20',
        name: '_rewardToken',
        type: 'address',
      },
      {
        internalType: 'uint96',
        name: '_tokenPerSec',
        type: 'uint96',
      },
    ],
    name: 'deployBribeContractAndSetBribe',
    outputs: [
      {
        internalType: 'address',
        name: 'bribe',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_startTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'contract IERC20',
        name: '_rewardToken',
        type: 'address',
      },
      {
        internalType: 'uint96',
        name: '_tokenPerSec',
        type: 'uint96',
      },
    ],
    name: 'deployRewarderContract',
    outputs: [
      {
        internalType: 'address',
        name: 'rewarder',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '_lpToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_startTimestamp',
        type: 'uint256',
      },
      {
        internalType: 'contract IERC20',
        name: '_rewardToken',
        type: 'address',
      },
      {
        internalType: 'uint96',
        name: '_tokenPerSec',
        type: 'uint96',
      },
    ],
    name: 'deployRewarderContractAndSetRewarder',
    outputs: [
      {
        internalType: 'address',
        name: 'rewarder',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getWhitelistedRewardTokens',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IBeacon',
        name: '_rewarderBeacon',
        type: 'address',
      },
      {
        internalType: 'contract IBeacon',
        name: '_bribeBeacon',
        type: 'address',
      },
      {
        internalType: 'contract IBoostedMasterWombat',
        name: '_masterWombat',
        type: 'address',
      },
      {
        internalType: 'contract IVoter',
        name: '_voter',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: '_token',
        type: 'address',
      },
    ],
    name: 'isRewardTokenWhitelisted',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'masterWombat',
    outputs: [
      {
        internalType: 'contract IBoostedMasterWombat',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: '_token',
        type: 'address',
      },
    ],
    name: 'revokeRewardToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'rewarderBeacon',
    outputs: [
      {
        internalType: 'contract IBeacon',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewarderDeployers',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IBeacon',
        name: '_bribeBeacon',
        type: 'address',
      },
    ],
    name: 'setBribeBeacon',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '_token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_deployer',
        type: 'address',
      },
    ],
    name: 'setBribeDeployer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IBeacon',
        name: '_rewarderBeacon',
        type: 'address',
      },
    ],
    name: 'setRewarderBeacon',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IAsset',
        name: '_token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_deployer',
        type: 'address',
      },
    ],
    name: 'setRewarderDeployer',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'voter',
    outputs: [
      {
        internalType: 'contract IVoter',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: '_token',
        type: 'address',
      },
    ],
    name: 'whitelistRewardToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_logic',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'admin_',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: '_data',
        type: 'bytes',
      },
    ],
    stateMutability: 'payable',
    type: 'constructor',
  },
] as const

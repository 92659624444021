import { getDpFormat, getMillifiedFormat } from '@hailstonelabs/big-number-utils'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'
import { ASSETS } from '../../../constants/contract/asset'
import { POOLS } from '../../../constants/contract/pool'
import { useWeb3 } from '../../../context/Web3Context'

import { useVotingData } from '../../../context/VotingDataContext'
import useProposedGaugeWeightTable from '../../../hooks/table/useProposedGaugeWeightTable'
import useBreakpoints from '../../../hooks/useBreakpoints'
import CircularProgressBar from '../../CircularProgressBar'
import TokenImage from '../../TokenImage'
import AppTooltip from '../../Tooltip/AppTooltip'
import DoughnutChart, { DoughnutChartData } from '../DoughnutChart'
import styles from './index.module.scss'
function ProposedGaugeWeightChart() {
  const { chainId } = useWeb3()
  const { isMd } = useBreakpoints()
  const {
    nextEpochStartTime,
    total: { currentVote, votedPercentage, voteWeightOfEachAsset },
  } = useVotingData()
  const [chartDataIndex, setChartDataIndex] = useState<number | null>(null)
  const effectiveDate = new Date(nextEpochStartTime ? nextEpochStartTime * 1000 : 0)
  // getUTCMonth() starts from 0
  const formattedEffectiveDate = `${effectiveDate.getUTCDate()}/${
    effectiveDate.getUTCMonth() + 1
  }/${effectiveDate.getUTCFullYear()}`
  const { sortedGaugeWeightInVeWomArr } = useProposedGaugeWeightTable()

  const chartData = useMemo(() => {
    const outputData: DoughnutChartData = {
      dataset: [],
      backgroundColors: [],
      labels: [],
    }
    Object.values(POOLS[chainId]).forEach((pool) => {
      const poolSymbol = pool.label
      for (const assetTokenSymbol of pool.supportedAssetTokenSymbols) {
        const asset = ASSETS[chainId][poolSymbol]?.[assetTokenSymbol]
        if (!asset?.enableVoting) continue
        const assetDisplaySymbol = asset?.displaySymbol
        const gaugeWeightInVeWom =
          voteWeightOfEachAsset.vewom[poolSymbol]?.[assetTokenSymbol] || '0'
        // # = order by gaugeWeightInVeWom
        const order = sortedGaugeWeightInVeWomArr.indexOf(gaugeWeightInVeWom) + 1

        outputData.dataset.push(
          Number(voteWeightOfEachAsset.percentage[poolSymbol]?.[assetTokenSymbol])
        )
        outputData.labels.push(
          <div className="flex flex-row items-center gap-2" key={poolSymbol + assetTokenSymbol}>
            #{order}
            <span className="inline-flex flex-row items-center justify-center gap-[2px]">
              <TokenImage tokenSymbol={assetTokenSymbol} width="16" height="16" />
              {assetDisplaySymbol}
            </span>
            {getDpFormat(voteWeightOfEachAsset.percentage[poolSymbol]?.[assetTokenSymbol] || '0')}%
          </div>
        )
        outputData.backgroundColors.push(asset?.color || '#685D54')
      }
    })
    return outputData
  }, [
    chainId,
    sortedGaugeWeightInVeWomArr,
    voteWeightOfEachAsset.percentage,
    voteWeightOfEachAsset.vewom,
  ])
  return (
    <div className="flex w-[100%] flex-row flex-wrap max-md:flex-col">
      <div
        className={clsx(
          styles['title-width'],
          'font-Work',
          'max-md:flex max-md:w-full max-md:flex-row max-md:justify-between'
        )}
      >
        <p className="text-base font-semibold">Proposed Gauge Weight</p>
        <p className="text-sm font-normal max-md:text-right">
          Effective on <span className="max-md:inline-block">{formattedEffectiveDate} UTC</span>
        </p>
      </div>
      <DoughnutChart
        data={chartData}
        maxWidth="420px"
        margin="0 auto 16px"
        onArcHover={(dataIndex) => setChartDataIndex(dataIndex)}
        disableTooltip={isMd}
      >
        <CircularProgressBar
          percentage={+votedPercentage}
          size={isMd ? '240px' : '105%'}
          style={{
            background: '#fff',
            borderRadius: '999px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div className="flex flex-col items-center justify-center gap-1">
            <span className="text-xl font-bold max-md:text-xl">Current vote</span>
            <span className="text-2xl font-bold text-wombatPurple1">
              <AppTooltip message={`${getDpFormat(currentVote, 2)} veWOM`} place="left" fullWidth>
                {getMillifiedFormat(currentVote)} veWOM
              </AppTooltip>
            </span>
            <span className="text-center font-Work text-sm">
              {getDpFormat(votedPercentage, 2)}%<span>&nbsp;of total supply</span>
            </span>
            {chartDataIndex !== null && isMd && (
              <span className="font-Work text-sm font-normal">
                {chartData.labels[chartDataIndex]}
              </span>
            )}
          </div>
        </CircularProgressBar>
      </DoughnutChart>
      {/* a spacer to help center the chart */}
      <div className={styles['title-width']} />
    </div>
  )
}

export default ProposedGaugeWeightChart

import { BigNumber, utils } from 'ethers'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useMasterWombat } from '../../context/masterWombatContext'
import { useWeb3 } from '../../context/Web3Context'
import { usePoolPage } from '../../context/PoolPageContext'
import { POOL_STATE, TokenInfo } from '../PoolPage/index'
import { Children } from './children'
import { ExpandChildren } from './expandChildren'
import { useSearchParams } from 'react-router-dom'
import { ASSETS } from '../../constants/contract/asset'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { calLPPrice } from '../../utils/math'
import { safeWdiv, strToWad } from '@hailstonelabs/big-number-utils'
import { useWithoutAccountData } from '../../store/MultiChainMultiCallData/hooks'
import { useCashesData } from '../../store/Asset/hooks'
import { useTokenPrices } from '../../store/Prices/hooks'

type Props = {
  poolLabel: PoolLabels
  tokenInfo: TokenInfo
}

const EXPANDED_CLASS_NAME = 'expanded'
export default function PoolInfoCard({ poolLabel, tokenInfo }: Props) {
  const { selectedAssetTokenSymbol, selectedPoolSymbol, setPoolState, updateSelectedPoolInfo } =
    usePoolPage()
  const multichainDataWithoutAccount = useWithoutAccountData()

  const { chainId, account } = useWeb3()

  const tokenPrices = useTokenPrices()

  const { deposits } = useCashesData()

  const { userInfos } = useMasterWombat()

  const [openExpand, setOpenExpand] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const DISCONNECT = !account
  // eslint-disable-next-line
  const asset = ASSETS[chainId][poolLabel][tokenInfo.symbol]

  const depositedAmount = utils.formatEther(
    deposits[poolLabel][tokenInfo.symbol] ?? BigNumber.from(0)
  )
  const stakedAmount = utils.formatEther(
    userInfos[poolLabel][tokenInfo.symbol]?.amount ?? BigNumber.from(0)
  )

  const hasStaked = Number(stakedAmount) > 0

  const hasDeposited = Number(deposits[poolLabel][tokenInfo.symbol]) > 0

  const hasDepositedOrStaked = hasStaked || hasDeposited

  const handleClickExpand = () => {
    setOpenExpand(!openExpand)
  }

  useEffect(() => {
    const queryPool = searchParams.get('pool') as PoolLabels
    const queryToken = searchParams.get('token') as TokenSymbol
    const queryAction = searchParams.get('action')?.toLowerCase() as POOL_STATE
    if (queryPool && queryToken) {
      if (asset === ASSETS[chainId][queryPool][queryToken]) {
        updateSelectedPoolInfo(queryPool, queryToken)
        setPoolState(queryAction)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /**
   * Styles for a card's expansion and collapse
   */
  const poolInfoCardRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const currentPoolInfoCardEle = poolInfoCardRef.current
    if (!currentPoolInfoCardEle) return
    const previousSibling = currentPoolInfoCardEle.previousElementSibling as HTMLDivElement | null
    const nextSibling = currentPoolInfoCardEle.nextElementSibling as HTMLDivElement | null
    const borderWidth = '2px'
    if (openExpand) {
      /**
       * current card is expanded
       */
      currentPoolInfoCardEle.style.borderBottomWidth = borderWidth
      if (previousSibling) {
        previousSibling.style.borderBottomWidth = borderWidth
      }
    } else {
      /**
       * current card is collapsed
       */
      // if next card is collapsed, we need to remove current card's bottom border.
      // Otherwise, current card's bottom border and next card's top border will be overlapped.
      if (nextSibling && !nextSibling.classList.contains(EXPANDED_CLASS_NAME)) {
        currentPoolInfoCardEle.style.borderBottomWidth = '0px'
      }
      // if previous card is collapsed, we need to remove its bottom border.
      // Otherwise, current card's top border and previous card's bottom border will be overlapped.
      if (previousSibling && !previousSibling.classList.contains(EXPANDED_CLASS_NAME)) {
        previousSibling.style.borderBottomWidth = '0px'
      }
    }
    return () => {
      if (previousSibling && !previousSibling.classList.contains(EXPANDED_CLASS_NAME)) {
        previousSibling.style.borderBottomWidth = borderWidth
      }
    }
  }, [openExpand])

  const _tokenInfo = useMemo<TokenInfo>(() => {
    if (tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN) {
      let multiChainLiabilityInWei: BigNumber | null = BigNumber.from(0)
      // let multichainTVLInWei: BigNumber = tokenInfo.tvl
      //   ? utils.parseEther(tokenInfo.tvl.toString())
      //   : BigNumber.from(0)
      let multichainTVLInWei = BigNumber.from(0)

      // let multiChainTradingVol24h = tokenInfo.tradingVol24h ?? 0
      let multiChainTradingVol24h = 0

      for (let i = 0; i < tokenInfo.supportedChainIds.length; i++) {
        const chainId_ = tokenInfo.supportedChainIds[i]
        const tokenSymbol = tokenInfo.symbol
        const assetData = multichainDataWithoutAccount[chainId_]?.assetData
        const liabilityBn =
          assetData?.liabilitiesBn[PoolLabels.CROSS_CHAIN][tokenSymbol] || BigNumber.from(0)
        // const tokenPrices = multichainDataWithoutAccount[chainId_]?.tokenPrices

        let lpTokenToTokenRateBn = BigNumber.from(0)
        const totalSupplyBn = assetData?.totalSuppliesBn[PoolLabels.CROSS_CHAIN][tokenSymbol]

        if (liabilityBn && totalSupplyBn) {
          lpTokenToTokenRateBn = safeWdiv(liabilityBn, totalSupplyBn)
          multiChainLiabilityInWei = multiChainLiabilityInWei?.add(liabilityBn)
        }

        const otherChainsTVL = tokenPrices
          ? calLPPrice(liabilityBn, lpTokenToTokenRateBn, strToWad(tokenPrices[tokenSymbol]))
          : BigNumber.from(0)
        multichainTVLInWei = multichainTVLInWei.add(otherChainsTVL ?? BigNumber.from(0))

        // total trading vol on this chain
        const tradingVol24h_ = multichainDataWithoutAccount[chainId_]?.tradingVol24h
        const assetTradingVol_ = tradingVol24h_?.[PoolLabels.CROSS_CHAIN][tokenSymbol] ?? 0
        multiChainTradingVol24h += assetTradingVol_
      }
      return {
        ...tokenInfo,
        multichainTVL: Number(utils.formatEther(multichainTVLInWei)),
        multichainLiability: Number(utils.formatEther(multiChainLiabilityInWei)),
        multiChainTradingVol24h,
      }
    }
    return tokenInfo
  }, [multichainDataWithoutAccount, tokenInfo, tokenPrices])

  return (
    <div
      ref={poolInfoCardRef}
      onClick={() => {
        // if users click twice on the same card, it will only update once.
        if (poolLabel !== selectedPoolSymbol || tokenInfo.symbol !== selectedAssetTokenSymbol) {
          updateSelectedPoolInfo(poolLabel, tokenInfo.symbol)
        }
      }}
      className={`border-x-2 border-t-2  last:border-b-2 ${
        openExpand
          ? `${EXPANDED_CLASS_NAME} my-4 border-wombatPurple1 shadow-[0px_4px_16px_rgba(0,0,0,0.2)] transition-all duration-75 first:mt-0 [&+.expanded]:mt-[0]`
          : 'my-0 border-wombatPurple2'
      }`}
    >
      <div className={`relative rounded-lg shadow-lg`}>
        {asset && (
          <>
            <Children
              depositedOrStaked={hasDepositedOrStaked}
              DISCONNECT={DISCONNECT}
              hasStaked={hasStaked}
              poolLabels={poolLabel}
              tokenInfo={_tokenInfo}
              chainId={chainId}
              asset={asset}
              openExpand={openExpand}
              handleClickExpand={handleClickExpand}
            />
            <ExpandChildren
              openExpand={openExpand}
              depositedOrStaked={hasDepositedOrStaked}
              DISCONNECT={DISCONNECT}
              hasStaked={hasStaked}
              tokenInfo={tokenInfo}
              asset={asset}
              depositedAmount={depositedAmount}
              stakedAmount={stakedAmount}
              poolLabels={poolLabel}
              chainId={chainId}
            />
          </>
        )}
      </div>
    </div>
  )
}

import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const SKALE_TESTNET_POOL_CONFIG: PoolConfig = {
  [PoolLabels.MAIN]: {
    name: 'Main Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0x254dF1f8A8Fa9B7bFAd9e25bF912ea71484332cE',
  },
}

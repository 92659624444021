/** New Pool Release: Adding pool labels */
export enum PoolLabels {
  MAIN = 'MAIN',
  SIDE = 'SIDE',
  BNB = 'BNB',
  qWOM = 'qWOM',
  mWOM = 'mWOM',
  wmxWOM = 'wmxWOM',
  FACTORY_STABLES = 'INNOVATION',
  BNBx = 'BNBx',
  stkBNB = 'stkBNB',
  axlUSDC = 'axlUSDC',
  CUSD = 'CUSD',
  iUSD = 'iUSD',
  USDD = 'USDD',
  BOB = 'BOB',
  frxETH = 'frxETH',
  MIM = 'MIM',
  Overnight = 'Overnight',
  FRAX_MAI_USDplus = 'FRAX-USD+',
  FRAX_USDV = 'FRAX-USDV',
  ankrBNB = 'ankrBNB',
  BNBy = 'BNBy',
  jUSDC = 'jUSDC',
  SMART_HAY = 'SMART_HAY',
  wBETH = 'wBETH',
  ankrETH = 'ankrETH',
  wstETH = 'wstETH',
  fUSDC = 'fUSDC',
  mPendle = 'mPendle',
  slisBNB = 'slisBNB',
  CROSS_CHAIN = 'CROSS_CHAIN',
  rBNB = 'rBNB',
  FRAX = 'FRAX',
  EURA = 'EURA',
  ETHx = 'ETHx',
  USDS = 'USDS',
  zUSD = 'zUSD',
  zBNB = 'zBNB',
  sAVAX = 'sAVAX',
  USDV = 'USDV',
  DOLA = 'DOLA',
  FRAX_sFRAX_USDC = 'FRAX-sFRAX-USDC',
  ggAVAX_AVAX = 'ggAVAX-AVAX',
  lUSD = 'lUSD',
  STABLE = 'STABLE',
  AXON_STABLE = 'AXON-STABLE',
  Agora_Stable_Pool = 'Agora Stable Pool',
  Liquid_Staking_Pool = 'Liquid Staking Pool',
}

export const poolLabels = Object.values(PoolLabels)

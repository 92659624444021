import { useCallback } from 'react'
import * as uuidLib from 'uuid'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { CrossChainCase } from '../../constants/crossChain'
import { chainInfos } from '../../constants/web3/chains'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { HexString } from '../../interfaces/contract'
import { isTestnet } from '../../utils'
import {
  IRelayStatus,
  getCaseAndMetaData,
  getSequence,
  getWormholeStatusEndPoint,
} from '../../utils/crossChainSwap'
import { usePoller } from '../usePoller'

export type InputTxData =
  | {
      isCreditToToken: false
      fromChainId: SupportedChainId
      toChainId: SupportedChainId
      fromTokenSymbol: TokenSymbol
      fromTokenAmount: string
      toTokenSymbol: TokenSymbol
      toTokenAmount: string
      fromTxhash: string
      userAddress: string
      receiverValue: string
      status?: CrossChainCase
    }
  | {
      isCreditToToken: true
      fromChainId: SupportedChainId
      toChainId: SupportedChainId
      fromCreditAmount: string
      toTokenSymbol: TokenSymbol
      toTokenAmount: string
      fromTxhash: string
      userAddress: string
      receiverValue: string
      status?: CrossChainCase
    }
type ITxCaseData =
  | {
      case: Exclude<CrossChainCase, CrossChainCase.RECEIVED_CREDIT>
    }
  | { case: CrossChainCase.RECEIVED_CREDIT; creditAmount: string }

type ITxBaseData = {
  id: string
  createTimestamp: number
  toTxhash: string | null
  sequence: string
}

export type ITxData = ITxBaseData & InputTxData & ITxCaseData

interface CrossChainSwapTxState {
  txList: ITxData[]
  appendCrossChainSwapTx: (data: InputTxData) => Promise<void>
  updateCrossChainSwapTx: (newTxList: ITxData[]) => void
  getPendingTxnList: (sourceChainId: SupportedChainId, account: HexString) => ITxData[]
  getHistoryTxnList: (sourceChainId: SupportedChainId, account: HexString) => ITxData[]
  removeCrossChainSwapTxn: (sourceChainId: SupportedChainId, sequence: string) => void
  updateSingleCrossChainSwapTxn: (
    sourceChainId: SupportedChainId,
    sequence: string,
    newData: ITxData
  ) => void
}
export const useCrossChainSwapTxStore = create<CrossChainSwapTxState>()(
  devtools(
    persist(
      (set, get) => ({
        txList: [],
        updateCrossChainSwapTx: (newTxList: ITxData[]) => {
          set({
            txList: newTxList,
          })
        },
        appendCrossChainSwapTx: async (data: InputTxData) => {
          const sequence = await getSequence(data.fromChainId, data.fromTxhash)
          const newData = {
            ...data,
            case: CrossChainCase.PENDING,
            createTimestamp: Date.now(),
            id: uuidLib.v4(),
            toTxhash: null,
            sequence,
          } as ITxData
          set((state) => ({
            txList: [...state.txList, newData],
          }))
        },
        getPendingTxnList: (sourceChainId: SupportedChainId, account: HexString) => {
          return get()
            .txList.filter(
              (tx) =>
                tx.userAddress.toLowerCase() === account.toLowerCase() &&
                tx.fromChainId === sourceChainId &&
                (tx.case === CrossChainCase.PENDING || tx.case === CrossChainCase.NEED_REDELIVERY)
            )
            .sort((a, b) => b.createTimestamp - a.createTimestamp)
        },
        getHistoryTxnList: (sourceChainId: SupportedChainId, account: HexString) => {
          return get()
            .txList.filter(
              (tx) =>
                tx.userAddress.toLowerCase() === account.toLowerCase() &&
                tx.fromChainId === sourceChainId &&
                tx.case !== CrossChainCase.PENDING &&
                tx.case !== CrossChainCase.NEED_REDELIVERY
            )
            .sort((a, b) => b.createTimestamp - a.createTimestamp)
        },
        removeCrossChainSwapTxn: (sourceChainId: SupportedChainId, sequence: string) => {
          set((state) => {
            const updatedTxList = [...state.txList].filter(
              (tx) => !(tx.sequence === sequence && tx.fromChainId === sourceChainId)
            )
            return {
              txList: updatedTxList,
            }
          })
        },
        updateSingleCrossChainSwapTxn: (
          sourceChainId: SupportedChainId,
          sequence: string,
          newData: ITxData
        ) => {
          const oldTxnList = get().txList
          const indexOfTheTxn = oldTxnList.findIndex(
            (data) => data.fromChainId === sourceChainId && data.sequence === sequence
          )
          const newTxList = [...oldTxnList]
          // replace old data with new one
          newTxList.splice(indexOfTheTxn, 1, newData)
          set({
            txList: newTxList,
          })
        },
      }),
      {
        name: 'CrossChainSwapTx-storage',
      }
    )
  )
)

export const useCrossChainSwapTx = () => {
  const { txList, updateCrossChainSwapTx, appendCrossChainSwapTx } = useCrossChainSwapTxStore(
    (state) => state
  )
  const getNewTxData = async (tx: ITxData) => {
    if (!(tx.case === CrossChainCase.PENDING)) {
      return tx
    }
    const chainInfo = chainInfos[tx.fromChainId]
    const emitterChainId = chainInfo.wormholeConfig?.chainId
    const emitterAddress = chainInfo.wormholeConfig?.relayerAddress
    const adaptorAddress = chainInfo.wormholeConfig?.adaptorAddress
    if (!emitterChainId) throw new Error(`No emitterChainId: ${tx.fromChainId}`)
    if (!emitterAddress) throw new Error(`No emitterAddress: ${tx.fromChainId}`)
    if (!adaptorAddress) throw new Error(`No adaptorAddress: ${tx.fromChainId}`)
    const endpoint = getWormholeStatusEndPoint(
      emitterChainId,
      emitterAddress,
      tx.sequence,
      isTestnet(tx.fromChainId)
    )
    try {
      const status = (await (await fetch(endpoint)).json()) as IRelayStatus | undefined
      if (status) {
        const data = await getCaseAndMetaData(status, tx.toChainId)
        return { ...tx, ...data }
      }
      return tx
    } catch (error) {
      console.error(error)
      return tx
    }
  }
  const updateTxData = useCallback(async () => {
    const newTxList = (await Promise.all(txList.map((tx) => getNewTxData(tx)))).filter(
      (t) => t
    ) as ITxData[]
    const txs = newTxList.map((t) => t.fromTxhash)
    const filteredNewTxList = newTxList.filter((t, index) => !txs.includes(t.fromTxhash, index + 1))
    if (JSON.stringify(filteredNewTxList) !== JSON.stringify(txList)) {
      updateCrossChainSwapTx(filteredNewTxList)
    }
  }, [txList, updateCrossChainSwapTx])
  usePoller(updateTxData, 3000)
  return {
    txList,
    appendCrossChainSwapTx,
  }
}

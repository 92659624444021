import Image from 'next/image'
import { useRef } from 'react'
import cheers from '../../../public/assets/animations/cheer2.png'
import Modal from '../../Modal'
import Button from '../../Button'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import useDidClickOutside from '../../../hooks/useDidClickOutside'
import { getExplorerLink } from '../../../utils/getBscScanLink'
import { chainInfos } from '../../../constants/web3/chains'
import useAddTokenToMetamask from '../../../hooks/useAddToken'
import { TOKENS } from '../../../constants/contract'

type Props = {
  isOpen: boolean
  onClose: () => void
  hash: string
  chainId: SupportedChainId
}

export default function BridgeRedeemCompletedModal({ isOpen, onClose, hash, chainId }: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })

  const { addToken } = useAddTokenToMetamask(TOKENS[chainId].WOM)

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Redemption completed" isCenterTitle>
      <div className="flex flex-col items-center justify-center gap-6 px-4 pb-12 text-lg md:text-2xl">
        <div className="pb-3">
          <Image src={cheers} width={84} height={90} />
        </div>
        <Button>
          <a rel="noreferrer" href={getExplorerLink(hash, 'transaction', chainId)} target="_blank">
            View On {chainInfos[chainId].explorerAlias}
          </a>
        </Button>
        <Button onClick={addToken}>Add token to Metamask</Button>
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { QueryResponseType, SubgraphState } from '../types'
import { ChainInfo } from '../../../constants/web3/chains'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { query } from '../helper'

const getBlock24hAgo = createAsyncThunk<
  Pick<SubgraphState, 'block24hAgo'> | null,
  {
    chainId: SupportedChainId
    chainInfo: ChainInfo
  }
>('subgraph/getBlock24hAgo', async ({ chainId, chainInfo }) => {
  const timestamp = Math.floor(Date.now() / 1000) - 86400
  const req = `
            {
              blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: {timestamp_lte: ${timestamp}}) {
                number
                timestamp
              }
            }
            `
  const { data, errors } = (await query(
    req,
    chainInfo?.blockSubgraphUrl ?? null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  )) as QueryResponseType<any>
  if (errors) {
    const errInfo =
      '@subgraph querying\n' +
      `query: ${req}\n` +
      `endpoint: ${chainInfo?.blockSubgraphUrl ?? null}\n`
    console.error(errInfo, JSON.stringify(errors))
    return null
  } else {
    // setBlock24hAgo({ chainId, value: data.blocks[0].number })
    return {
      block24hAgo: { chainId, value: data.blocks[0].number },
    }
  }
})

export default getBlock24hAgo

export interface PoolPageColInfo {
  key: string
  name: string
  detail?: React.ReactNode
  link?: string
}

export const POOL_PAGE_COL_INFO: PoolPageColInfo[] = [
  {
    key: 'symbol',
    name: 'Token',
  },
  {
    key: 'covRatio',
    name: 'Cov Ratio',
    detail:
      'The coverage ratio is the ratio of asset-to-liability of a pool, and it determines the swapping slippage, withdrawal, and deposit fees.',
    link: 'https://docs.wombat.exchange/tokenomics/about-wom',
  },
  { key: 'tvl', name: 'TVL(24H Vol)', detail: '' },
  {
    key: 'medianTotalApr',
    name: 'Avg. APR',
    detail: (
      <span>
        The sum of Base APR and Average Boosted APR. The average boosted APR is for the users who
        have locked WOM and hold veWOM.
        <br />
        If pools show 0% APR, a trigger action is needed to show the latest information.
      </span>
    ),
  },
  {
    key: 'myTotalApr',
    name: 'My APR',
    detail: '',
  },
  { key: 'reward', name: 'Reward', detail: '' },
]

interface PoolPageColInfoObj {
  [key: string]: PoolPageColInfo
}

export const POOL_PAGE_COL_INFO_OBJ: PoolPageColInfoObj = {}
for (const item of POOL_PAGE_COL_INFO) {
  POOL_PAGE_COL_INFO_OBJ[item.key] = item
}

export const POOL_REWARDER_DRAINED_REMINDER =
  'The rewarder contract has run out of funds, any claim attempts will fail. Rewards can only be claimed upon replenishment by the associated protocol. '

export const SWAP_GAS_ON_DEST_CHAIN_TOOLTIP_MSG =
  'You will receive this amount in the destination chain when the crosschain-swap is successful.'

import Image from 'next/image'
import { ReactNode, useState } from 'react'
import Back from '../../public/assets/icons/back.svg'
import setting from '../../public/assets/icons/setting.svg'
import { POOL_STATE } from '../PoolPage'
import SettingModal from '../SettingModal'
import { useWithdrawalPageContext } from './WithdrawalPageContext'

interface Props {
  children: ReactNode
  nav: POOL_STATE[]
}

export default function PoolContainer({ children, nav }: Props) {
  const [isOpenSetting, setIsOpenSetting] = useState(false)
  const { resetAllStates } = useWithdrawalPageContext()

  return (
    <div
      // z-index is below the banner's z-index
      className="absolute left-0 top-0 z-[9] h-full w-screen bg-white bg-wombatBackgroundMobile bg-cover bg-fixed bg-center md:bg-wombatBackground"
      style={{ marginTop: 0 }}
    >
      <div className="mx-auto flex max-w-md flex-col items-center space-y-8 pt-9 font-Work md:pt-20">
        <div className=" w-full rounded-2xl p-2 px-5 text-black md:relative md:bg-wombatCream md:py-5 md:px-7 md:shadow-lg">
          <div className="flex h-10 w-full items-center justify-between font-Quantico font-bold">
            <div className="absolute left-3 md:left-8">
              <button
                className="button-hover-opacity -m-2 flex items-center p-2 text-sm text-wombatGrey"
                onClick={() => {
                  resetAllStates()
                }}
              >
                <Image src={Back} height={12} width={7} />
                <span className="ml-1 font-Work font-semibold">BACK</span>
              </button>
            </div>
            {nav[0] !== POOL_STATE.STAKE && (
              <button
                className="button-hover-opacity absolute right-7 flex h-10 items-center p-2"
                onClick={() => setIsOpenSetting(true)}
              >
                <Image layout="fixed" width={18} height={18} src={setting} />
              </button>
            )}
          </div>
          <SettingModal
            isOpen={isOpenSetting}
            onClose={() => {
              setIsOpenSetting(false)
            }}
          />
          <div className="pt-4">{children}</div>
        </div>
      </div>
    </div>
  )
}

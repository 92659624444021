import React from 'react'
import { POOLS } from '../../../constants/contract/pool'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { ASSETS } from '../../../constants/contract/asset'
import { useWeb3 } from '../../../context/Web3Context'
import TokenDisplay from '../TokenDisplay'

type Props = {
  poolLabel: PoolLabels
  assetTokenSymbol: TokenSymbol
  center?: boolean
}

function AssetTokenDisplay({ poolLabel, assetTokenSymbol, center }: Props) {
  const { chainId } = useWeb3()
  const asset = ASSETS[chainId][poolLabel][assetTokenSymbol]
  if (!asset) return <></>
  return (
    <TokenDisplay
      tokenSymbol={asset.symbol}
      label={asset.displaySymbol}
      sublabel={POOLS[chainId][asset.poolLabel]?.name}
      center={center}
    />
  )
}

export default AssetTokenDisplay

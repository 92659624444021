import { ASSETS } from '../../../constants/contract/asset'
import { POOLS } from '../../../constants/contract/pool'
import { chainInfos } from '../../../constants/web3/chains'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { getInitialSubgraphProperty } from '../../../store/Subgraph/helper'
import { QueryResponseType } from '../../../store/Subgraph/types'

export const fetchTradingVol24h = async (chainId: SupportedChainId) => {
  const chainInfo = chainInfos[chainId]
  let block24hAgo: { chainId: number; value: number } | null = null
  let tradingVol24h = getInitialSubgraphProperty()

  const query = async (query: string, endpoint: string | null) => {
    if (!endpoint) {
      console.error('Invalid subgraph endpoint url')
      return { data: {}, errors: [] }
    }

    try {
      const response: Response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
        }),
      })
      return response.json()
    } catch (error) {
      console.error('error', error)
    }
  }

  const fetchBlock24hAgo = async () => {
    const timestamp = Math.floor(Date.now() / 1000) - 86400
    const req = `
    {
      blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: {timestamp_lte: ${timestamp}}) {
        number
        timestamp
      }
    }
    `

    if (chainInfo?.blockSubgraphUrl) {
      const { data, errors } = (await query(
        req,
        chainInfo?.blockSubgraphUrl
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )) as QueryResponseType<any>
      if (errors) {
        const errInfo =
          '@subgraph querying\n' +
          `chainId: ${chainId}\n` +
          `query: ${req}\n` +
          `endpoint: ${chainInfo?.blockSubgraphUrl ?? null}\n`
        console.error(errInfo, JSON.stringify(errors))
        return null
      } else {
        return { chainId, value: data.blocks[0].number }
      }
    }

    return null
  }

  block24hAgo = await fetchBlock24hAgo()

  const fetchData = async () => {
    const womExchangeQuery = (async () => {
      // to fix the race condition with chain switching

      if (block24hAgo === null || block24hAgo.chainId !== chainId) return
      const req = `
        query{
          assetsNow: assets {
            id
            symbol
            totalTradeVolumeUSD
          }
          assets24hAgo: assets (block:{number:${block24hAgo.value}}) {
            id
            symbol
            totalTradeVolumeUSD
          }
        }`

      const queryData = await query(
        req,
        chainInfo?.womSubgraphUrl ?? null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )

      if (!queryData) return
      const { data, errors } = queryData
      if (errors) {
        const errInfo =
          '@subgraph querying\n' +
          `query: ${req}\n` +
          `endpoint: ${chainInfo?.womSubgraphUrl ?? null}\n`
        console.error(errInfo, JSON.stringify(errors))
      } else {
        const tradingVol24h_ = getInitialSubgraphProperty()
        Object.values(POOLS[chainId])
          .filter((pool) => pool.supportedAssetTokenSymbols.length > 0)
          .forEach((pool) =>
            pool.supportedAssetTokenSymbols.forEach((tokenSymbol) => {
              const lpAddress = ASSETS[chainId][pool.label][tokenSymbol]?.address.toLowerCase()
              if (!lpAddress) return
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const getTradeVol = (result: any) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return result?.filter((obj: any) => obj.id === lpAddress)[0]?.[
                  'totalTradeVolumeUSD'
                ]
              }

              const vol1 = getTradeVol(data?.assets24hAgo) ?? '0'
              const vol2 = getTradeVol(data?.assetsNow)
              if (vol1 && vol2) {
                tradingVol24h_[pool.label][tokenSymbol] = ((vol2 - vol1) / 2) as number
              }
            })
          )
        tradingVol24h = tradingVol24h_
      }
    })()

    await Promise.all([womExchangeQuery])
  }

  if (chainInfo.id !== SupportedChainId.BSC_TESTNET) {
    await fetchData()
  }

  return tradingVol24h
}

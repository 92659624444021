import { createAsyncThunk } from '@reduxjs/toolkit'
import { multicall } from '@wagmi/core'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import {
  fetchAssetData,
  fetchBoostedPoolRewarderAddressesOn1stMulticall,
  fetchBoostedPoolRewarderDataOn2ndMulticall,
  fetchBoostedPoolRewarderDataOn3rdMulticall,
  fetchBribeConfig,
  fetchBribeContractAddresses,
  fetchBribeInfo,
  fetchPoolData,
  fetchPoolRewarderData,
  fetchTokenData,
  fetchVewomData,
  fetchVotingData,
} from '../../../context/MulticallDataContext/helpers'
import { fetchMasterWombatData } from '../../../context/MulticallDataContext/helpers/fetchMasterWombatHelper'
import { fetchUniswapV3PoolData } from '../../../context/MulticallDataContext/helpers/fetchUniswapV3PoolDataHelper'
import { executeCallBacks } from '../../../utils/executeCallBacks'
import { RootState } from '../../store'
import { MulticallDataState } from '../slice'

type ResponseType = Pick<
  MulticallDataState,
  | 'masterWombatData'
  | 'tokenData'
  | 'votingData'
  | 'vewomData'
  | 'assetData'
  | 'bribeConfigData'
  | 'bribeInfoData'
  | 'poolRewarderData'
  | 'boostedPoolRewarderData'
  | 'uniswapV3PoolData'
  | 'poolData'
>

const fetchMulticalData = createAsyncThunk<
  ResponseType,
  {
    chainId: SupportedChainId
    account: `0x${string}` | null
  },
  { state: RootState }
>('MulticallData/fetchMulticalData', async ({ chainId, account }) => {
  const {
    contractCalls: poolDataCalls,
    callbacks: poolDataCallbacks,
    states: poolDataStates,
  } = fetchPoolData(chainId, account)
  const {
    contractCalls: uniswapV3PoolDataCalls,
    callbacks: uniswapV3PoolDataCallbacks,
    states: uniswapV3PoolDataStates,
  } = fetchUniswapV3PoolData(chainId)

  const { contractCalls: bribeContractAddressesCalls, callbacks: bribeContractAddressesCallbacks } =
    fetchBribeContractAddresses(chainId)

  const {
    contractCalls: poolRewarderDataCallsOn1stMulticall,
    callbacks: poolRewarderDataCallbacksOn1stMulticall,
  } = fetchBoostedPoolRewarderAddressesOn1stMulticall(chainId)
  // const isMulticallDataFetched = getState().multicallData.isMulticallDataFetched
  // if (!isMulticallDataFetched) {
  const valuesFromFirstMulticall = await multicall({
    contracts: [
      ...poolDataCalls,
      ...uniswapV3PoolDataCalls,
      ...bribeContractAddressesCalls,
      ...poolRewarderDataCallsOn1stMulticall,
    ],
    allowFailure: true,
    chainId,
  })
  executeCallBacks(valuesFromFirstMulticall, [
    ...poolDataCallbacks,
    ...uniswapV3PoolDataCallbacks,
    ...bribeContractAddressesCallbacks,
    ...poolRewarderDataCallbacksOn1stMulticall,
  ])
  // }

  /**
   * fetch voting data
   */
  const {
    contractCalls: votingDataCalls,
    callbacks: votingDataCallbacks,
    states: votingDataStates,
  } = fetchVotingData(chainId, account)

  const valuesFromVotingDataMulticall = await multicall({
    contracts: [...votingDataCalls],
    allowFailure: true,
    chainId,
  })
  executeCallBacks(valuesFromVotingDataMulticall, [...votingDataCallbacks])

  /**
   * fetch each polling
   */
  /** Multicall stage #2 */
  /**
   * Pushing call and callback
   */
  const {
    contractCalls: masterWombatDataCalls,
    callbacks: masterWombatDataCallbacks,
    states: masterWombatDataStates,
  } = fetchMasterWombatData(chainId, account)
  const {
    contractCalls: vewomDataCalls,
    callbacks: vewomDataCallbacks,
    states: vewomDataStates,
  } = fetchVewomData(chainId, account)
  const {
    contractCalls: assetDataCalls,
    callbacks: assetDataCallbacks,
    states: assetDataStates,
  } = fetchAssetData(chainId, account)
  const {
    contractCalls: tokenDataCalls,
    callbacks: tokenDataCallbacks,
    states: tokenDataStates,
  } = fetchTokenData({
    chainId,
    account,
    whitelistedRewardTokens: votingDataStates.withoutAccount.whitelistedRewardTokens,
    assetData: assetDataStates.withoutAccount,
  })
  const {
    contractCalls: bribeSymbolsCalls,
    callbacks: bribeConfigCallbacks,
    states: bribeSymbolsStates,
  } = fetchBribeConfig(chainId, account)
  const {
    contractCalls: poolRewarderDataCallsOn2ndMulticall,
    callbacks: poolRewarderDataCallbacksOn2ndMulticall,
  } = fetchBoostedPoolRewarderDataOn2ndMulticall(chainId)
  /**
   * fetch each polling
   */
  const valuesFromSecondMulticall = await multicall({
    contracts: [
      ...masterWombatDataCalls,
      ...tokenDataCalls,
      ...assetDataCalls,
      ...vewomDataCalls,
      ...bribeSymbolsCalls,
      ...poolRewarderDataCallsOn2ndMulticall,
    ],
    allowFailure: true,
    chainId,
  })
  executeCallBacks(valuesFromSecondMulticall, [
    ...masterWombatDataCallbacks,
    ...tokenDataCallbacks,
    ...assetDataCallbacks,
    ...vewomDataCallbacks,
    ...bribeConfigCallbacks,
    ...poolRewarderDataCallbacksOn2ndMulticall,
  ])

  /**
   * fetch each polling
   */
  /** Multicall stage #3 */
  /**
   * Pushing call and callback
   */

  const {
    contractCalls: bribeInfoCalls,
    callbacks: bribeInfoCallbacks,
    states: bribeInfoStates,
  } = fetchBribeInfo(chainId)

  const {
    contractCalls: poolRewarderDataCalls,
    callbacks: poolRewarderDataCallbacks,
    states: poolRewarderDataInfoStates,
  } = fetchPoolRewarderData(chainId, account)

  const {
    contractCalls: boostedPoolRewarderDataCallsOn3rdMulticall,
    callbacks: boostedPoolRewarderDataCallbacksOn3rdMulticall,
    states: boostedPoolRewarderDataStates,
  } = fetchBoostedPoolRewarderDataOn3rdMulticall(chainId, account)

  const valuesFromThirdMulticall = await multicall({
    contracts: [
      ...bribeInfoCalls,
      ...poolRewarderDataCalls,
      ...boostedPoolRewarderDataCallsOn3rdMulticall,
    ],
    allowFailure: true,
    chainId,
  })
  executeCallBacks(valuesFromThirdMulticall, [
    ...bribeInfoCallbacks,
    ...poolRewarderDataCallbacks,
    ...boostedPoolRewarderDataCallbacksOn3rdMulticall,
  ])

  return {
    masterWombatData: {
      withoutAccount: masterWombatDataStates.withoutAccount,
      withAccount: masterWombatDataStates.withAccount,
    },
    tokenData: {
      withoutAccount: tokenDataStates.withoutAccount,
      withAccount: tokenDataStates.withAccount,
    },
    votingData: {
      withoutAccount: votingDataStates.withoutAccount,
      withAccount: votingDataStates.withAccount,
    },
    vewomData: {
      withoutAccount: vewomDataStates.withoutAccount,
      withAccount: vewomDataStates.withAccount,
    },
    assetData: {
      withoutAccount: assetDataStates.withoutAccount,
      withAccount: assetDataStates.withAccount,
    },
    bribeConfigData: {
      withoutAccount: bribeSymbolsStates.withoutAccount,
      withAccount: bribeSymbolsStates.withAccount,
    },
    bribeInfoData: {
      withoutAccount: bribeInfoStates.withoutAccount,
    },
    poolRewarderData: {
      withAccount: poolRewarderDataInfoStates.withAccount,
    },
    boostedPoolRewarderData: {
      withAccount: boostedPoolRewarderDataStates.withAccount,
      withoutAccount: boostedPoolRewarderDataStates.withoutAccount,
    },
    uniswapV3PoolData: {
      ...uniswapV3PoolDataStates,
    },
    poolData: {
      withAccount: poolDataStates.withAccount,
      withoutAccount: poolDataStates.withoutAccount,
    },
  }
})

export default fetchMulticalData

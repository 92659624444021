/**
 * Currently, TOKENS is not fetched by configx. Be cautious as the symbols for the same token on various chains may differ.
 * We may need to create identical tokens with different symbols.
 * */
import { TokenSymbols } from '../../../configx/tokenSymbols'
export { TokenSymbols } from '../../../configx/tokenSymbols'

/**
 * HardcodedTokenSymbol is used when some token symbols are not generated by configx-wombat.
 * For example, a bribe reward token hasn't yet added to a bribe rewarder. We can put it here
 */
export enum HardcodedTokenSymbol {
  /**
   * @todo need to keep it to avoid undefined in some files e.g. route.ts
   * remove it when we have at least one hardcoded token symbol
   */

  'DUMMY' = 'DUMMY',
  'newANKR' = 'newANKR',

  // for FUJI (crosschain swap)
  'AVAX' = 'AVAX',

  /** @todo  Replace with Configx */
  'rETH' = 'rETH',
  'LBR' = 'LBR',
  'eUSD' = 'eUSD',
  'SKL' = 'SKL',
  'WMATIC' = 'WMATIC',
}
export type TokenSymbol = TokenSymbols | HardcodedTokenSymbol

export const tokenSymbolEnumKeys = Array.from(
  new Set([...Object.keys(TokenSymbols), ...Object.keys(HardcodedTokenSymbol)])
)
export const tokenSymbolEnumValues = Array.from(
  new Set([...Object.values(TokenSymbols), ...Object.values(HardcodedTokenSymbol)])
)
/**
 * Determine toTokenSymbol in swapping
 * New Pool Release: Adding SwapGroupSymbol if needed
 */
export enum SwapGroupSymbol {
  USD = 'USD',
  WOM = 'WOM',
  BNB = 'BNB',
  ETH = 'ETH',
  EUR = 'EUR',
  AVAX = 'AVAX',
  mPendle = 'mPendle',
  UNAVAILABLE = 'UNAVAILABLE',
}

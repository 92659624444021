import { getPercentageFromTwoWAD, strToWad, safeWdiv, wmul } from '@hailstonelabs/big-number-utils'
import { BigNumber, constants, utils } from 'ethers'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { ASSETS } from '../constants/contract/asset'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { BribeInfoOfEachPool } from '../context/MulticallDataContext/helpers/fetchVotingDataHelper'
import {
  PoolLabelsTokenSymbolsGenericType,
  PoolLabelTokenSymbolStringType,
} from '../interfaces/common'
import { HexString } from '../interfaces/contract'
import _ from 'lodash'
import { Asset } from '../constants/contract/asset/Asset'
import { SECONDS_PER_WEEK } from '../constants/common'
import { AddedRewardData } from '../context/SelfServiceBribePageContext'
import { TokenMaps } from '../constants/contract/token'
import { AssetRewarderType } from '../interfaces/selfServiceBribeRewarder'

export const checkSumOfPercentagesMoreThan100 = (percentages: {
  [id in PoolLabels]?: {
    [id in TokenSymbol]?: string
  }
}): boolean => {
  let totalPercentageWad = constants.Zero
  const poolSymbols = Object.keys(percentages) as PoolLabels[]
  poolSymbols.forEach((poolSymbol) => {
    const assetTokenSymbols = Object.keys(percentages[poolSymbol] || {}) as TokenSymbol[]
    for (const assetTokenSymbol of assetTokenSymbols) {
      const percentage = percentages?.[poolSymbol]?.[assetTokenSymbol]
      if (percentage) {
        totalPercentageWad = totalPercentageWad.add(strToWad(percentage))
      }
    }
  })
  return totalPercentageWad.gt(strToWad('100'))
}
/**
 * Get Vote Delta to execute voter.vote function in SC
 * @param proposedUserVotingWeightPercentages sum of percentages cannot more than 100 percent
 * @param originalVotingWeightOfEachAssets
 * @param vewomBalanceWad
 * @param chainId
 * @returns
 */
export const getLpTokenAddressesAndVewomVoteDeltaWads = (
  proposedUserVotingWeightPercentages: {
    [id in PoolLabels]?: {
      [id in TokenSymbol]?: string
    }
  },
  originalVotingWeightOfEachAssets: PoolLabelTokenSymbolStringType,
  vewomBalanceWad: BigNumber,
  chainId: SupportedChainId
): { lpTokenAddresses: HexString[]; vewomVoteDeltaWads: BigNumber[] } => {
  const lpTokenAddresses: HexString[] = []
  const vewomVoteDeltaWads: BigNumber[] = []
  if (checkSumOfPercentagesMoreThan100(proposedUserVotingWeightPercentages)) {
    throw new Error('User has not enough proposed vote.')
  }
  const poolSymbols = Object.keys(proposedUserVotingWeightPercentages) as PoolLabels[]
  poolSymbols.forEach((poolLabel) => {
    const assetTokenSymbols = Object.keys(
      proposedUserVotingWeightPercentages[poolLabel] || {}
    ) as TokenSymbol[]
    for (const assetTokenSymbol of assetTokenSymbols) {
      const lpToken = ASSETS[chainId][poolLabel][assetTokenSymbol]
      if (!lpToken) continue
      const lpTokenAddress = lpToken.address
      if (!lpTokenAddress) continue
      const originalWeight = originalVotingWeightOfEachAssets[poolLabel]?.[assetTokenSymbol]
      const originalWeightWad = strToWad(originalWeight)
      const proposedWeightWad = vewomBalanceWad
        .mul(strToWad(proposedUserVotingWeightPercentages[poolLabel]?.[assetTokenSymbol]))
        .div(strToWad('100'))
      if (proposedWeightWad.eq(originalWeightWad)) {
        continue
      }
      lpTokenAddresses.push(lpTokenAddress)
      vewomVoteDeltaWads.push(proposedWeightWad.sub(originalWeightWad))
    }
  })

  return { lpTokenAddresses, vewomVoteDeltaWads }
}

/**
 * Get Bribe IncentiveWad with new user votes per N days
 * @param {BigNumber} poolWeightWad
 * @param {BigNumber} userVotesWad
 * @param {BigNumber} bribeTokenPerSecWad
 * @param {number} numberOfDays
 * @returns
 */
export const getBribeIncentiveWadInTokenPerNDays = (
  poolWeightWad: BigNumber,
  userVotesWad: BigNumber,
  bribeTokenPerSecWad: BigNumber,
  numberOfDays: number
): BigNumber => {
  if (userVotesWad.eq(constants.Zero) || numberOfDays <= 0) {
    return constants.Zero
  }
  return wmul(safeWdiv(userVotesWad, poolWeightWad), bribeTokenPerSecWad)
    .mul(60)
    .mul(60)
    .mul(24)
    .mul(numberOfDays)
}

export type GetBribeIncentivesPerUserVotesPerNDaysReturnType = PoolLabelsTokenSymbolsGenericType<{
  sum: { inToken: string; inUsd: string }
  breakdown: { tokenSymbol: TokenSymbol; value: { inToken: string; inUsd: string } }[]
}>
/**
 * Get Bribe Incentives per user vote (Max Vewom or 100000 Vewom/ actual votes) per N days
 * @param {Object} options - The input parameters.
 * @param {SupportedChainId} options.chainId - The chain ID.
 * @param {PoolLabelTokenSymbolStringType} options.poolWeightOfEachAsset
 * @param {BribeInfoOfEachPool} options.bribeTokenPerSecondOfEachAsset
 * @param {TokenSymbolStringType} options.tokenPrices
 * @param { 'MAX' | 'TOTAL_POOL_WEIGHT' | 'ACTUAL'} options.userVotes TOTAL_POOL_WEIGHT is for average apr calculate
 * @param {number} options.numberOfDays
 * @param {PoolLabelStringBigNumberType | undefined} options.userVoteOfEachAsset
 * @param {BigNumber | undefined} options.vewomBalanceWad
 * @param {BribeInfoOfEachPool | undefined} options.isEmissionActiveOfEachAsset - Emission status of each asset's rewards from bribe rewarder v2
 * @returns {GetBribeIncentivesPerUserVotesPerNDaysReturnType} - The calculated bribe incentives.
 */
export const getBribeIncentivesPerUserVotesPerNDays = ({
  chainId,
  poolWeightOfEachAsset,
  bribeTokenPerSecondOfEachAsset,
  tokenPrices,
  userVotes,
  numberOfDays,
  userVoteOfEachAsset,
  vewomBalanceWad,
  isEmissionActiveOfEachAsset,
}: {
  chainId: SupportedChainId
  poolWeightOfEachAsset: PoolLabelTokenSymbolStringType
  bribeTokenPerSecondOfEachAsset: BribeInfoOfEachPool
  tokenPrices: {
    [id in TokenSymbol]?: string
  }
  userVotes: 'MAX' | 'ACTUAL' | 'TOTAL_POOL_WEIGHT'
  numberOfDays: number
  userVoteOfEachAsset?: PoolLabelTokenSymbolStringType
  vewomBalanceWad?: BigNumber
  isEmissionActiveOfEachAsset: BribeInfoOfEachPool | undefined
}): GetBribeIncentivesPerUserVotesPerNDaysReturnType => {
  const bribeIncentivesPerNDays: GetBribeIncentivesPerUserVotesPerNDaysReturnType = {}
  if (numberOfDays > 0) {
    Object.entries(bribeTokenPerSecondOfEachAsset).forEach(
      ([poolSymbolStr, bribeTokenPerSecondOfEachAssetBNInThisPool]) => {
        const poolLabel = poolSymbolStr as PoolLabels
        bribeIncentivesPerNDays[poolLabel] = {}
        Object.entries(bribeTokenPerSecondOfEachAssetBNInThisPool).forEach(
          ([assetTokenSymbolStr, bribeTokensPerSecondInThisAsset]) => {
            const assetTokenSymbol = assetTokenSymbolStr as TokenSymbol
            let bribeIncentiveWadInTokenPerNDays = constants.Zero
            let bribeIncentiveWadInTokenPerNDaysInUsd = constants.Zero
            const breakdown: {
              tokenSymbol: TokenSymbol
              value: { inToken: string; inUsd: string }
            }[] = []
            const asset = ASSETS[chainId][poolLabel][assetTokenSymbol]
            const hasBribeV2 = !!asset?.hasBribeV2
            bribeTokensPerSecondInThisAsset.forEach(
              ({ tokenSymbol: bribeTokenSymbol, value: bribeTokenPerSecondInThisAsset }, index) => {
                const isEmissionActive =
                  isEmissionActiveOfEachAsset?.[poolLabel]?.[assetTokenSymbol]?.[index].value ||
                  false
                /**
                 * If the asset has no bribe v2, it means there is no isEmissionActive property in old bribe rewarders.
                 * Therefore, we will use bribeTokenPerSecondInThisAsset as usual.
                 */
                bribeTokenPerSecondInThisAsset =
                  isEmissionActive || !hasBribeV2 ? bribeTokenPerSecondInThisAsset : '0'

                if (bribeTokenSymbol) {
                  let incentiveWad = constants.Zero
                  if (userVotes === 'MAX' && userVoteOfEachAsset && vewomBalanceWad) {
                    incentiveWad = getBribeIncentiveWadInTokenPerNDays(
                      strToWad(poolWeightOfEachAsset[poolLabel]?.[assetTokenSymbol])
                        .sub(strToWad(userVoteOfEachAsset[poolLabel]?.[assetTokenSymbol]))
                        .add(vewomBalanceWad),
                      vewomBalanceWad,
                      strToWad(bribeTokenPerSecondInThisAsset),
                      numberOfDays
                    )
                  } else if (userVotes === 'TOTAL_POOL_WEIGHT') {
                    incentiveWad = getBribeIncentiveWadInTokenPerNDays(
                      strToWad(poolWeightOfEachAsset[poolLabel]?.[assetTokenSymbol]),
                      strToWad(poolWeightOfEachAsset[poolLabel]?.[assetTokenSymbol]),
                      strToWad(bribeTokenPerSecondInThisAsset),
                      numberOfDays
                    )
                  } else if (userVotes === 'ACTUAL' && userVoteOfEachAsset) {
                    incentiveWad = getBribeIncentiveWadInTokenPerNDays(
                      strToWad(poolWeightOfEachAsset[poolLabel]?.[assetTokenSymbol]).sub(
                        strToWad(userVoteOfEachAsset[poolLabel]?.[assetTokenSymbol])
                      ),
                      strToWad(userVoteOfEachAsset[poolLabel]?.[assetTokenSymbol]),
                      strToWad(bribeTokenPerSecondInThisAsset),
                      numberOfDays
                    )
                  }
                  /**
                   * Calculate sum and breakdown for each asset
                   */
                  bribeIncentiveWadInTokenPerNDays =
                    bribeIncentiveWadInTokenPerNDays.add(incentiveWad)
                  const incentiveWadInUsd = wmul(
                    incentiveWad,
                    strToWad(tokenPrices[bribeTokenSymbol])
                  )
                  bribeIncentiveWadInTokenPerNDaysInUsd =
                    bribeIncentiveWadInTokenPerNDaysInUsd.add(incentiveWadInUsd)
                  breakdown.push({
                    tokenSymbol: bribeTokenSymbol,
                    value: {
                      inToken: utils.formatEther(incentiveWad),
                      inUsd: utils.formatEther(incentiveWadInUsd),
                    },
                  })
                }
              }
            )

            if (bribeIncentivesPerNDays && bribeIncentivesPerNDays[poolLabel]) {
              bribeIncentivesPerNDays[poolLabel] = {
                ...bribeIncentivesPerNDays[poolLabel],
                [assetTokenSymbol]: {
                  sum: {
                    inToken: utils.formatEther(bribeIncentiveWadInTokenPerNDays),
                    inUsd: utils.formatEther(bribeIncentiveWadInTokenPerNDaysInUsd),
                  },
                  breakdown,
                },
              }
            }
          }
        )
      }
    )
  }
  return bribeIncentivesPerNDays
}

/**
 * Get proposed Total weight Wad
 * @param {BigNumber} currentTotalWeightWad
 * @param {BigNumber} currentUserUsedVoteWad
 * @param {string} proposedUserTotalVotePercentage
 * @param {BigNumber} currentUserVewomBalanceWad
 * @returns proposedTotalWeightWad
 */
export const getProposedTotalWeightWad = (
  currentTotalWeightWad: BigNumber,
  currentUserUsedVoteWad: BigNumber,
  proposedUserTotalVotePercentage: string,
  currentUserVewomBalanceWad: BigNumber
): BigNumber => {
  return currentTotalWeightWad
    .sub(currentUserUsedVoteWad)
    .add(
      currentUserVewomBalanceWad.mul(strToWad(proposedUserTotalVotePercentage)).div(strToWad('100'))
    )
}

/**
 * Get proposed pool weight Wad
 * @param {BigNumber} currentUserVoteWeightWad
 * @param {BigNumber} proposedUserVoteWeightWad
 * @param {BigNumber} currentPoolWeightWad
 * @returns proposedPoolWeightWad
 */
export const getProposedPoolWeightWad = (
  currentUserVoteWeightWad: BigNumber,
  proposedUserVoteWeightWad: BigNumber,
  currentPoolWeightWad: BigNumber
): BigNumber => {
  const voteDelta = proposedUserVoteWeightWad.sub(currentUserVoteWeightWad)
  return currentPoolWeightWad.add(voteDelta)
}

export type EarnedBribeRewardType = {
  lpTokenAddress: HexString
  bribes: { tokenSymbol: TokenSymbol; value: string }[]
}
/**
 * Transform earnedBribeOfEachAsset to list of EarnedBribeRewardType data
 * @param {BribeInfoOfEachPool} earnedBribeOfEachAsset
 * @param {SupportedChainId} chainId
 * @returns {EarnedBribeRewardType[]}
 */
export const getEarnedBribeRewards = (
  earnedBribeOfEachAsset: BribeInfoOfEachPool,
  chainId: SupportedChainId
): EarnedBribeRewardType[] => {
  const earnedBribesData: EarnedBribeRewardType[] = []
  Object.entries(earnedBribeOfEachAsset).forEach(
    ([poolSymbol, earnedBribeOfEachAssetsOfThePool]) => {
      Object.entries(earnedBribeOfEachAssetsOfThePool).forEach(
        ([assetSymbol, earnedBribesOfTheAsset]) => {
          const lpToken = ASSETS[chainId][poolSymbol as PoolLabels][assetSymbol as TokenSymbol]
          const lpTokenAddress = lpToken?.address
          if (lpTokenAddress) {
            earnedBribesData.push({
              lpTokenAddress,
              bribes: earnedBribesOfTheAsset,
            })
          }
        }
      )
    }
  )
  return earnedBribesData
}

export type GetAprOfEachBribeReturnType = PoolLabelsTokenSymbolsGenericType<{
  sum: string
  breakdown: { tokenSymbol: TokenSymbol; value: string }[]
}>

/**
 * Get apr of each bribe.
 * The formula is (annual bribe rewards in USD / total staked wom in USD) * 100%
 * @param {GetBribeIncentivesPerUserVotesPerNDaysReturnType} annualBribeRewards - a return value from getBribeIncentivesPerUserVotesPerNDays()
 * @param {string} stakedWomInUsd
 * @returns {GetAprOfEachBribeReturnType}
 */
export const getAprOfEachBribe = (
  annualBribeRewards: GetBribeIncentivesPerUserVotesPerNDaysReturnType,
  stakedWomInUsd?: string,
  voteWeightOfEachAssetInPercentage?: PoolLabelTokenSymbolStringType,
  voteWeightOfEachAssetInUsd?: PoolLabelTokenSymbolStringType
): GetAprOfEachBribeReturnType => {
  const calculateAprPercentage = (
    poolSymbol: PoolLabels,
    assetTokenSymbol: TokenSymbol,
    bribeRewardSumInUsd: string
  ) => {
    if (voteWeightOfEachAssetInUsd) {
      const voteWeightInUsd = strToWad(voteWeightOfEachAssetInUsd[poolSymbol]?.[assetTokenSymbol])
      if (voteWeightInUsd) {
        return getPercentageFromTwoWAD(strToWad(bribeRewardSumInUsd), voteWeightInUsd)
      }
    }
    if (voteWeightOfEachAssetInPercentage) {
      const voteWeightInPercentage =
        voteWeightOfEachAssetInPercentage[poolSymbol]?.[assetTokenSymbol]
      if (voteWeightInPercentage) {
        // Get current bribe apr
        const voteWeightInPercentageWad = strToWad(voteWeightInPercentage)
        const stakedwomInUsdWad = wmul(voteWeightInPercentageWad, totalStakedWomInUsdWad).div('100')
        return getPercentageFromTwoWAD(strToWad(bribeRewardSumInUsd), stakedwomInUsdWad)
      }
    } else {
      // Get max bribe apr
      return getPercentageFromTwoWAD(strToWad(bribeRewardSumInUsd), totalStakedWomInUsdWad)
    }
  }

  const totalStakedWomInUsdWad = strToWad(stakedWomInUsd)

  if (stakedWomInUsd !== undefined && totalStakedWomInUsdWad.lte(0)) return {}

  const output: GetAprOfEachBribeReturnType = {}

  Object.entries(annualBribeRewards).forEach(([poolSymbolStr, annualBribeRewardsOfEachPool]) => {
    const poolSymbol = poolSymbolStr as PoolLabels
    Object.entries(annualBribeRewardsOfEachPool).forEach(
      ([
        assetTokenSymbolStr,
        {
          sum: { inUsd: bribeRewardSumInUsd },
          breakdown: annualBribeRewardBreakdown,
        },
      ]) => {
        const assetTokenSymbol = assetTokenSymbolStr as TokenSymbol
        const totalApr = calculateAprPercentage(poolSymbol, assetTokenSymbol, bribeRewardSumInUsd)
        const breakdown = annualBribeRewardBreakdown.map(
          ({ tokenSymbol, value: { inUsd: bribeRewardInUsd } }) => {
            const bribeApr = calculateAprPercentage(poolSymbol, assetTokenSymbol, bribeRewardInUsd)

            return {
              tokenSymbol,
              value: bribeApr,
            }
          }
        )
        output[poolSymbol] = {
          ...(output[poolSymbol] || {}),
          [assetTokenSymbol]: { sum: totalApr, breakdown },
        }
      }
    )
  })
  return output
}

/**
 * Get total bribe per seconds in usd for all pools.
 * @param {BribeInfoOfEachPool} bribeTokenPerSecondOfEachAsset
 * @param {TokenSymbolStringType} tokenPrices
 * @returns {BigNumber}
 */
const getTotalBribePerSecondInUsdWad = (
  bribeTokenPerSecondOfEachAsset: BribeInfoOfEachPool | undefined,
  tokenPrices: {
    [id in TokenSymbol]?: string
  }
) => {
  if (!bribeTokenPerSecondOfEachAsset) return constants.Zero
  let totalBribePerSecondInUsdWad = constants.Zero
  for (const bribeTokenPerSecondByAsset of Object.values(bribeTokenPerSecondOfEachAsset)) {
    for (const bribeTokenPerSecondForThisAsset of Object.values(bribeTokenPerSecondByAsset)) {
      for (const {
        tokenSymbol: bribeTokenSymbol,
        value: tokenPerSec,
      } of bribeTokenPerSecondForThisAsset) {
        const bribeTokenInUsdPerSecondWad = wmul(
          strToWad(tokenPerSec),
          strToWad(tokenPrices[bribeTokenSymbol])
        )
        totalBribePerSecondInUsdWad = totalBribePerSecondInUsdWad.add(bribeTokenInUsdPerSecondWad)
      }
    }
  }
  return totalBribePerSecondInUsdWad
}

/**
 * Get total bribe efficiency.
 * formula: (Voter.womPerSec * Voter.votePartition * wom price) / [Sum over all bribes (tokenPerSec * token price)]
 * @param {string | null} womPerSec
 * @param {string} voteAllocation
 * @param {TokenSymbolStringType} tokenPrices
 * @returns {BigNumber}
 */
export const getTotalBribeEfficiencyWad = (
  womPerSec: string | undefined | null,
  voteAllocation: string | undefined,
  tokenPrices: {
    [id in TokenSymbol]?: string
  },
  bribeTokenPerSecondOfEachAsset: BribeInfoOfEachPool | undefined
) => {
  let bribeEfficiencyWad = constants.Zero
  const womPriceWad = strToWad(tokenPrices.WOM)
  const voteAllocationWad = strToWad(voteAllocation).div('1000')
  const womPerSecWad = strToWad(womPerSec ?? '0')
  const totalEmissionForVotingPerSecondInUsdWad = wmul(
    wmul(womPerSecWad, womPriceWad),
    voteAllocationWad
  )

  const totalBribePerSecondInUsdWad = getTotalBribePerSecondInUsdWad(
    bribeTokenPerSecondOfEachAsset,
    tokenPrices
  )
  if (totalBribePerSecondInUsdWad && !totalBribePerSecondInUsdWad.isZero()) {
    bribeEfficiencyWad = safeWdiv(
      totalEmissionForVotingPerSecondInUsdWad,
      totalBribePerSecondInUsdWad
    )
  }
  return bribeEfficiencyWad
}

/**
 * Returns the breakdown of bribes with corresponding APR values based on balance information.
 * @param bribeBalanceInfo An array of objects containing bribe balance information.
 * @param aprBreakdown An array of objects containing APR breakdown information.
 *@returns An array of unique bribe breakdown objects.
 */
export const getBribeAprWithBalanceBreakdown = (
  bribeBalanceInfo: { amount: string; tokenSymbol: string }[] | undefined,
  aprBreakdown:
    | {
        tokenSymbol: TokenSymbol
        value: string
      }[]
    | undefined,
  filterOption: 'bribeBalance' | 'bribeApr' | 'all' | 'none' = 'all'
) => {
  if (!bribeBalanceInfo) return []
  const bribeAprWithBalanceBreakdown = _.uniqBy(
    bribeBalanceInfo
      // map apr to bribe balance info
      .map((breakdown1) => {
        const breakdown2 = aprBreakdown?.find(
          (breakdown2) =>
            breakdown2.tokenSymbol === breakdown1.tokenSymbol && strToWad(breakdown2.value).gt('0')
        )
        return {
          bribeTokenSymbol: breakdown1.tokenSymbol as TokenSymbol,
          bribeApr: breakdown2?.value || '0',
          bribeBalance: breakdown1.amount,
        }
      }),
    'bribeTokenSymbol'
  )

  if (filterOption === 'none') return bribeAprWithBalanceBreakdown

  return _.uniqBy(
    bribeAprWithBalanceBreakdown.filter(({ bribeApr, bribeBalance }) => {
      if (filterOption === 'bribeBalance') {
        return !strToWad(bribeBalance).isZero()
      }
      if (filterOption === 'bribeApr') {
        return !strToWad(bribeApr).isZero()
      }
      return !strToWad(bribeApr).isZero() && !strToWad(bribeBalance).isZero()
    }),
    'bribeTokenSymbol'
  )
}

/**

Checks if the gauge table data should be shown.
@param {boolean} hasVoted - Indicates if the user has voted.
@param {boolean | undefined} hasAssetDelisted - Indicates if the asset is delisted.
@param {boolean | undefined} enableVoting - Indicates if voting is enabled for the asset.
@returns {boolean} - Returns true if the gauge table data should be shown, otherwise false.
*/
export const checkIfGaugeTableDataShown = (
  hasVoted: boolean,
  hasAssetDelisted: boolean | undefined,
  enableVoting: boolean | undefined
): boolean => {
  // 1. Firstly, check asset has delisted and voted by user
  if (hasVoted && hasAssetDelisted) return true
  // 2. Secondly, check asset has enabled voting
  if (enableVoting) return true
  return false
}

/**
Retrieves gauge information for assets based on the bribe deployer.
@param {PoolLabelsTokenSymbolsGenericType<boolean>} hasDeployerRoleOfEachAsset - Object containing information
about whether the user is a bribe deployer for each asset.
@param {PoolLabelsTokenSymbolsGenericType<boolean>} hasOperatorRoleOfEachAsset - Object containing information
about whether the user has operator role for each asset.
@param {Asset[]} assetList - List of assets.
@returns {Asset[]} - Array of deployed bribe gauge information.
*/
export const getAssetsInfoByDeployerOrOperator = (
  hasDeployerRoleOfEachAsset: PoolLabelsTokenSymbolsGenericType<boolean> | undefined,
  hasOperatorRoleOfEachAsset: PoolLabelsTokenSymbolsGenericType<boolean> | undefined,
  assetList: Asset[] | undefined,
  rewarderType: AssetRewarderType
) => {
  if (!hasDeployerRoleOfEachAsset || !assetList || !hasOperatorRoleOfEachAsset) return []

  const deployedBribeGaugeInfo = assetList.filter((asset) => {
    const poolLabel = asset.poolLabel
    const assetTokenSymbol = asset.symbol
    if (asset?.[rewarderType]?.rewarderAddress !== constants.AddressZero) {
      const hasOperatorRole = hasOperatorRoleOfEachAsset[poolLabel]
      return hasOperatorRole && hasOperatorRole[assetTokenSymbol]
    } else {
      const hasDeployerRole = hasDeployerRoleOfEachAsset[poolLabel]
      return hasDeployerRole && hasDeployerRole[assetTokenSymbol]
    }
  })

  return deployedBribeGaugeInfo
}

/**
 * Returns a list of AddedBribeRewardData objects for each asset in the given asset list that has a
 * bribe rewarder and for which the user has the bribe role. The data includes the pool symbol, asset
 * symbol, bribe reward symbol, remaining balance, weekly emission rate, and runout timestamp for each
 * bribe reward token.
 *
 * @param {Asset[]|undefined} assetList - The list of assets to check for bribe rewards.
 * @param {PoolLabelsTokenSymbolsGenericType<boolean>|undefined} hasOperatorRoleOfEachAsset
 * @param {PoolLabelsTokenSymbolsGenericType<{ tokenSymbol: TokenSymbol; value: number }[]>|undefined} rewardTokensRunoutTimestamps
 * @param {BribeInfoOfEachPool|undefined} rewardTokenPerSecondOfEachAsset
 * @param {BribeInfoOfEachPool|undefined} rewardTokenBalanceInfoOfEachAsset
 * @returns {AddedRewardData[]}
 */
export const getAddedRewardList = (
  assetList: Asset[] | undefined,
  hasOperatorRoleOfEachAsset: PoolLabelsTokenSymbolsGenericType<boolean> | undefined,
  rewardTokensRunoutTimestamps:
    | PoolLabelsTokenSymbolsGenericType<{ tokenSymbol: TokenSymbol; value: number }[]>
    | undefined,
  rewardTokenPerSecondOfEachAsset: BribeInfoOfEachPool | undefined,
  rewardTokenBalanceInfoOfEachAsset: BribeInfoOfEachPool | undefined,
  rewardTokenSurplusInfoOfEachAsset: BribeInfoOfEachPool | undefined,
  isPoolRewarder: boolean
): AddedRewardData[] => {
  if (
    !hasOperatorRoleOfEachAsset ||
    !assetList ||
    !rewardTokensRunoutTimestamps ||
    !rewardTokenPerSecondOfEachAsset ||
    !rewardTokenBalanceInfoOfEachAsset ||
    !rewardTokenSurplusInfoOfEachAsset
  )
    return []
  const addedRewardList: AddedRewardData[] = []
  for (const asset of assetList) {
    for (const [poolLabelStr, deployedGaugeInfoOfEachPool] of Object.entries(
      hasOperatorRoleOfEachAsset
    )) {
      for (const [assetTokenSymbolStr, hasOperatorRole] of Object.entries(
        deployedGaugeInfoOfEachPool
      )) {
        const poolSymbol = poolLabelStr as PoolLabels
        const assetSymbol = assetTokenSymbolStr as TokenSymbol
        /**check whether a user has an operator role */
        if (asset.poolLabel === poolSymbol && asset.symbol === assetSymbol && hasOperatorRole) {
          const rewardSymbols = isPoolRewarder
            ? asset.boostedPoolRewarder?.rewardTokenSymbols
            : asset.bribeRewarder?.rewardTokenSymbols
          const rewardsRunoutTimestamp = rewardTokensRunoutTimestamps[poolSymbol]?.[assetSymbol]
          const rewardTokensPerSecond = rewardTokenPerSecondOfEachAsset[poolSymbol]?.[assetSymbol]
          const rewardTokensBalance = rewardTokenBalanceInfoOfEachAsset[poolSymbol]?.[assetSymbol]
          const rewardTokensSurplus = rewardTokenSurplusInfoOfEachAsset[poolSymbol]?.[assetSymbol]
          if (!rewardSymbols || !rewardsRunoutTimestamp || !rewardTokensPerSecond) continue
          rewardSymbols.forEach((rewardSymbol) => {
            const secondlyEmissionRate = rewardTokensPerSecond?.find((rewardTokenPerSecond) => {
              return rewardTokenPerSecond.tokenSymbol === rewardSymbol
            })?.value

            const remainingBalance =
              rewardTokensBalance?.find((rewardTokenBalance) => {
                return rewardTokenBalance.tokenSymbol === rewardSymbol
              })?.value ?? '0'

            const remainingSurplus =
              rewardTokensSurplus?.find((rewardTokenSurplus) => {
                return rewardTokenSurplus.tokenSymbol === rewardSymbol
              })?.value ?? '0'

            const runoutTimestamp =
              rewardsRunoutTimestamp?.find((rewardRunoutTimestamp) => {
                return rewardRunoutTimestamp.tokenSymbol === rewardSymbol
              })?.value ?? 0

            const weeklyEmissionRate = utils.formatEther(
              wmul(strToWad(secondlyEmissionRate), strToWad(SECONDS_PER_WEEK.toString()))
            )

            addedRewardList.push({
              poolSymbol,
              assetSymbol,
              rewardSymbol,
              runoutTimestamp,
              remainingBalance,
              remainingSurplus,
              weeklyEmissionRate,
            })
          })
        }
      }
    }
  }

  return addedRewardList
}

export const getWhitelistedRewardTokens = (
  tokenMaps: TokenMaps | undefined,
  whitelistedRewardTokenAddresses: HexString[] | undefined
): TokenSymbol[] => {
  if (!tokenMaps || !whitelistedRewardTokenAddresses) return []

  const result: TokenSymbol[] = []
  for (let i = 0; i < whitelistedRewardTokenAddresses.length; i++) {
    const tokenAddress = whitelistedRewardTokenAddresses[i].toLowerCase()
    for (const [tokenSymbol, token] of Object.entries(tokenMaps)) {
      if (tokenAddress === token.address.toLowerCase()) {
        result.push(tokenSymbol as TokenSymbol)
      }
    }
  }
  return result
}

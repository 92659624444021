import { createAsyncThunk } from '@reduxjs/toolkit'
import { MigrationDetectionState, UserInfoSCType } from '../types'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { CallbacksType, executeCallBacks, IContractCalls } from '../../../utils/executeCallBacks'
import { ASSETS, HARDCODED_ASSETS } from '../../../constants/contract/asset'
import { MasterWombatId } from '../../../constants/contract/masterWombat'
import { MASTER_WOMBATS } from '../../../constants/contract'
import { pidsForMigration } from '../../../constants/migration'
import { BigNumber, utils } from 'ethers'
import { multicall } from '@wagmi/core'

const updateAllMW2Data = createAsyncThunk<
  Pick<MigrationDetectionState, 'mw2Data'>,
  {
    chainId: SupportedChainId
    account: `0x${string}` | null
  }
>('MigrationDetection/updateAllMW2Data', async ({ chainId, account }) => {
  const initMW2Data: MigrationDetectionState['mw2Data'] = {}
  const fetchUserInfo = async () => {
    const contractCalls: IContractCalls = []
    const callbacks: CallbacksType = []

    if (!account) return
    Object.values(HARDCODED_ASSETS[chainId] ?? {}).forEach((assetsInThePool) => {
      Object.values(assetsInThePool).forEach((asset) => {
        const poolLabel = asset.poolLabel
        const masterWombatId = MasterWombatId.MW2
        const masterWombatAddress = MASTER_WOMBATS[chainId]?.[masterWombatId]?.address
        if (!masterWombatAddress) return
        const pid =
          pidsForMigration[chainId][masterWombatAddress.toLowerCase()]?.[
            asset.address.toLowerCase()
          ]
        if (pid === undefined) return
        const assetPidBN = BigNumber.from(pid)
        const assetTokenSymbol = asset.symbol
        const masterWombatContract = MASTER_WOMBATS?.[chainId]?.[masterWombatId]
        const assetDecimals = ASSETS[chainId][poolLabel][assetTokenSymbol]?.decimals
        if (!masterWombatContract || !assetDecimals) return
        const lpAmountInTermsOfLp = {
          ...initMW2Data[poolLabel]?.[assetTokenSymbol]?.lpAmountInTermsOfLp,
        }
        contractCalls.push(masterWombatContract.multicall('userInfo', [assetPidBN, account]))
        callbacks.push((value) => {
          const { amount: stakedAmountBN, pendingWom: pendingWomWad } = value as UserInfoSCType
          const stakedAmount = utils.formatUnits(stakedAmountBN, assetDecimals)
          lpAmountInTermsOfLp.staked = stakedAmount
          const isWithdrawalNeeded = stakedAmountBN.gt('0')
          initMW2Data[poolLabel] = {
            ...initMW2Data[poolLabel],
            [assetTokenSymbol]: {
              isWithdrawalNeeded,
              pendingWom: utils.formatEther(pendingWomWad),
              lpAmountInTermsOfLp,
              pid: assetPidBN.toString(),
              asset,
            },
          }
        })
      })
    })

    try {
      const values = await multicall({ contracts: contractCalls, chainId, allowFailure: true })
      executeCallBacks(values, callbacks)
      return {
        mw2Data: initMW2Data,
      }
    } catch (error) {
      console.debug(error)
      return {
        mw2Data: {},
      }
    }
  }
  await fetchUserInfo()
  return {
    mw2Data: initMW2Data,
  }
})

export default updateAllMW2Data

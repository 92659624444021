import SwapContainer from '../../components/SwapContainers/SwapContainer'
import SwapProvider from '../../context/SwapContext'
import SwapInputProvider from '../../context/SwapInputContext'

export default function SwapPage() {
  return (
    <SwapProvider>
      <SwapInputProvider>
        <SwapContainer />
      </SwapInputProvider>
    </SwapProvider>
  )
}

import { safeWdiv } from '@hailstonelabs/big-number-utils'
import { BigNumber, ContractTransaction, utils } from 'ethers'
import Image from 'next/image'
import { useCallback, useEffect, useState } from 'react'
import { useContract } from 'wagmi'
import { TOKENS } from '../../constants/contract'
import { Asset } from '../../constants/contract/asset/Asset'
import { Pool } from '../../constants/contract/pool/Pool'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { ROUTERS } from '../../constants/contract/router'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../constants/contract/token'
import { Token } from '../../constants/contract/token/Token'
import { tokenSymbolEnumValues } from '../../constants/contract/token/TokenSymbols'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../../context/errorMessage'
import { useMasterWombat } from '../../context/masterWombatContext'
import { useUserPreference } from '../../context/userPreferenceContext'
import { useWeb3 } from '../../context/Web3Context'
import useApproval, { TokenApprovalState } from '../../hooks/useApproval'
import { useDebounce } from '../../hooks/useDebounce'
import { useQuoteWithdrawBalances } from '../../hooks/useQuoteWithdrawBalances'
import { TokenSymbolGenericType } from '../../interfaces/common'
import warnning from '../../public/assets/icons/warning-icon.svg'
import { useCashesData } from '../../store/Asset/hooks'
import { useAppDispatch } from '../../store/hooks'
import { addErrorToast, addSuccessToast } from '../../store/Toast/actions'
import { calculateGasMargin } from '../../utils'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import { delay, getDeadlineFromNow } from '../../utils/utils'
import Accordion from '../Accordion'
import FormattedNumber from '../FormattedNumber'
import { POOL_STATE } from '../PoolPage'
import TransactionFailedModal from '../TransactionFailedModal'
import WithdrawSubmitedModal from '../TransactionSubmittedModal'
import WButton, { Variant } from '../WButton'
import WithdrawConfirmModal from '../WithdrawConfirmModal'
import WithdrawInput from '../WithdrawInput'

enum WithdrawState {
  IDLE,
  LOADING,
  SUCCESS,
  FAILED,
  SUBMITED,
  UNKNOWN,
}

interface Props {
  currentPool: Pool
  asset: Asset
  onTxnSubmited: (txn: ContractTransaction) => void
  redirect: (value: POOL_STATE | null) => void
}

export default function Withdraw({ currentPool, asset, onTxnSubmited, redirect }: Props) {
  const { chainId, account, signer } = useWeb3()
  const [lpAmount, setLpAmount] = useState<BigNumber | null>(null)
  /** @TODO allStaked is not update https://www.notion.so/Fix-no-unused-vars-setAllStaked-f4fbe7ae989e4ddab79e60a6bb064da9 */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const fromToken = TOKENS[chainId][asset.symbol] || null
  const [allStaked, setAllStaked] = useState<boolean>(false)
  const [withdrawToken, setWithdrawToken] = useState<Token | null>(fromToken)
  const [, setFee] = useState<number>(0)
  const [minimumAmountReceived, setMinimumAmountReceived] = useState(BigNumber.from(0))
  const [txHash, setTxHash] = useState<boolean>(false)
  const [transactionHash, setTransactionHash] = useState<string>('')
  const [withdrawState, setWithdrawState] = useState<WithdrawState>(WithdrawState.IDLE)
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(true)
  const dispatch = useAppDispatch()
  const { userPreference } = useUserPreference()
  const [isHasDeposit, setIsHasDeposit] = useState<boolean>(false)
  const poolContract = useContract({
    ...currentPool.get(),
    signerOrProvider: signer,
  })
  const [withdrawalQuote, setWithdrawalQuote] = useState(BigNumber.from(0))
  const [withdrawPending, setWithdrawPending] = useState(false)
  const [withdrawalLock, setWithdrawalLock] = useState(false)
  const routerContract = useContract({
    ...ROUTERS[chainId]?.get(),

    signerOrProvider: signer,
  })
  const [tokenAmountForDisplay, setTokenAmountForDisplay] = useState<string>('')
  const { deposits, lpTokenToTokenRates, totalSupplies } = useCashesData()
  const { userInfos } = useMasterWombat()
  const tokenDeposit = deposits[currentPool.label][asset.symbol]
  const totalSupply = totalSupplies[currentPool.label][asset.symbol]
  const stakedAmount = userInfos[currentPool.label][asset.symbol]?.amount
  const isWithdrawInNative = withdrawToken?.symbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]
  const { approvalState, tryApproval } = useApproval(
    asset.address,
    isWithdrawInNative ? routerContract?.address ?? null : currentPool.address,
    lpAmount
  )

  const debounceLpAmount = useDebounce(lpAmount)
  const quotes = useQuoteWithdrawBalances(currentPool, fromToken, debounceLpAmount)
  const isCrossChainPool = currentPool.label === PoolLabels.CROSS_CHAIN

  useEffect(() => {
    const tokenAddr = fromToken?.address
    if (!tokenAddr || !lpAmount || lpAmount.eq(BigNumber.from(0))) {
      setWithdrawalQuote(BigNumber.from(0))
      return
    }
    let _withdrawalQuote = null
    /** @todo refactor hardcode logic */
    if (fromToken && fromToken === withdrawToken) {
      _withdrawalQuote = quotes[fromToken.symbol]
      if (!_withdrawalQuote) {
        setWithdrawalQuote(BigNumber.from(0))
      } else if ('fee' in _withdrawalQuote) {
        setWithdrawalQuote(_withdrawalQuote.amount)
        setFee(Number(utils.formatEther(_withdrawalQuote.fee)))
      }
    } else if (withdrawToken !== null && account) {
      _withdrawalQuote = quotes[withdrawToken.symbol]
      if (!_withdrawalQuote) {
        setWithdrawalQuote(BigNumber.from(0))
      } else if ('withdrewAmount' in _withdrawalQuote) {
        setWithdrawalQuote(_withdrawalQuote.amount)
        setFee(
          Number(
            lpAmount.mul(
              lpTokenToTokenRates[currentPool.label][asset.symbol] ?? utils.formatEther('1')
            )
          ) /
            1e36 -
            Number(utils.formatUnits(_withdrawalQuote.withdrewAmount, fromToken.decimals ?? 18))
        )
      }
    }
    setWithdrawalLock(false)
  }, [
    account,
    chainId,
    currentPool,
    lpTokenToTokenRates,
    lpAmount,
    quotes,
    fromToken,
    withdrawToken,
    asset.symbol,
  ])

  const nonNegative = (num: BigNumber) => {
    if (num.isNegative()) return BigNumber.from(0)
    else return num
  }

  useEffect(() => {
    if (!isDisplayModal && withdrawState !== WithdrawState.SUBMITED) {
      setWithdrawState(WithdrawState.IDLE)
      setIsDisplayModal(true)
    }
  }, [isDisplayModal, withdrawState])

  const newPoolShare =
    tokenDeposit && stakedAmount && totalSupply && !totalSupply.sub(lpAmount ?? 0).isZero()
      ? nonNegative(
          tokenDeposit
            .add(stakedAmount)
            .sub(lpAmount ?? 0)
            .mul(utils.parseEther('1'))
            .div(totalSupply.sub(lpAmount ?? 0))
        )
      : 0

  useEffect(() => {
    if (tokenDeposit) {
      setIsHasDeposit(!!Number(tokenDeposit))
    }
  }, [tokenDeposit, fromToken])

  useEffect(() => {
    // Floating point representation can exceed 18 decimal places. Truncate this to a maximum of 18 decimal places to avoid exception in utils.parseEther.
    const slippage = utils.parseEther(Number(userPreference.slippage).toFixed(18))
    setMinimumAmountReceived(safeWdiv(withdrawalQuote, slippage.add(utils.parseEther('1'))))
  }, [withdrawalQuote, userPreference])

  useEffect(() => {
    setWithdrawalLock(true)
  }, [tokenAmountForDisplay])

  // withdraw
  const withdraw = useCallback(async () => {
    if (!(fromToken && poolContract && lpAmount && account && withdrawToken)) {
      return
    }
    try {
      setWithdrawState(WithdrawState.LOADING)

      const deadline = getDeadlineFromNow(userPreference.transactionDeadline)
      let txn: ContractTransaction
      if (withdrawToken.symbol === NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]) {
        // unwrap WBNB
        if (!routerContract) throw 'cannot get router'
        txn =
          withdrawToken.symbol !== fromToken.symbol
            ? await routerContract.removeLiquidityFromOtherAssetAsNative(
                poolContract.address,
                fromToken.address,
                lpAmount,
                minimumAmountReceived,
                account,
                BigNumber.from(deadline)
              )
            : await routerContract.removeLiquidityNative(
                poolContract.address,
                lpAmount,
                minimumAmountReceived,
                account,
                BigNumber.from(deadline)
              )
      } else {
        const estimateGas =
          withdrawToken.symbol !== fromToken.symbol
            ? await poolContract.estimateGas.withdrawFromOtherAsset(
                fromToken.address,
                withdrawToken.address,
                lpAmount,
                minimumAmountReceived,
                account,
                BigNumber.from(deadline)
              )
            : await poolContract.estimateGas.withdraw(
                fromToken.address,
                lpAmount,
                minimumAmountReceived,
                account,
                BigNumber.from(deadline)
              )
        txn =
          withdrawToken.symbol !== fromToken.symbol
            ? await poolContract.withdrawFromOtherAsset(
                fromToken.address,
                withdrawToken.address,
                lpAmount,
                minimumAmountReceived,
                account,
                BigNumber.from(deadline),
                {
                  gasLimit: calculateGasMargin(estimateGas),
                }
              )
            : await poolContract.withdraw(
                fromToken.address,
                lpAmount,
                minimumAmountReceived,
                account,
                BigNumber.from(deadline),
                {
                  gasLimit: calculateGasMargin(estimateGas),
                }
              )
      }
      setTransactionHash(txn.hash)
      setTxHash(true)
      await delay(1500)
      setWithdrawState(WithdrawState.SUBMITED)
      await txn.wait()
      setWithdrawState(WithdrawState.SUCCESS)
      dispatch(
        addSuccessToast({
          message: `${tokenAmountForDisplay} ${fromToken.displaySymbol}`,
          title: 'Withdraw completed',
          txHash: txn.hash,
          childrenButton: 'Deposit',
          handleClickButton: () => redirect(POOL_STATE.DEPOSIT),
        })
      )
      onTxnSubmited(txn)
      setIsDisplayModal(true)
      setTokenAmountForDisplay('')
      setLpAmount(null)
      setFee(0)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      if (JSON.stringify(error).includes(code)) {
        setWithdrawState(WithdrawState.IDLE)
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        setIsDisplayModal(true)
        setWithdrawState(WithdrawState.FAILED)
        dispatch(
          addErrorToast({
            message: `${formatNumberUSLocale(tokenAmountForDisplay, 2)} ${fromToken.displaySymbol}`,
            title: 'Withdraw Failed',
            txHash: transactionHash,
          })
        )
      }
    } finally {
      setTxHash(false)
      setIsDisplayModal(true)
    }
  }, [
    account,
    chainId,
    lpAmount,
    minimumAmountReceived,
    onTxnSubmited,
    poolContract,
    dispatch,
    redirect,
    routerContract,
    fromToken,
    tokenAmountForDisplay,
    transactionHash,
    userPreference.transactionDeadline,
    withdrawToken,
  ])

  useEffect(() => {
    if (!withdrawalLock && withdrawPending) {
      setWithdrawPending(false)
      withdraw()
    }
  }, [withdrawalLock, withdrawPending, withdraw])

  const isAmountGreaterThanBalance = (): boolean => {
    if (tokenDeposit && lpAmount) {
      return lpAmount.gt(tokenDeposit)
    }
    return true
  }

  const renderModal = () => {
    switch (withdrawState) {
      case WithdrawState.LOADING:
        return (
          <WithdrawConfirmModal
            isOpen
            tokenAmount={lpAmount}
            txHash={txHash}
            tokenFrom={fromToken}
            tokenTo={withdrawToken}
            miniumReceived={Number(utils.formatUnits(withdrawalQuote, withdrawToken?.decimals))}
            onClose={() => setIsDisplayModal(false)}
          />
        )
      case WithdrawState.SUBMITED:
        return (
          <WithdrawSubmitedModal
            isAddTokenToWallet={false}
            hash={transactionHash}
            isOpen
            onClose={() => setIsDisplayModal(false)}
            chainId={chainId}
          />
        )
      case WithdrawState.FAILED:
        return <TransactionFailedModal isOpen onClose={() => setIsDisplayModal(false)} />
    }
  }

  const buttonGroup = () => {
    switch (approvalState) {
      case TokenApprovalState.UNKNOWN:
      case TokenApprovalState.NETWORK_UNSUPPORTED:
        return (
          <div className="flex flex-row items-center justify-between">
            <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
              APPROVE
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                WITHDRAW
              </WButton>
            </div>
          </div>
        )
      case TokenApprovalState.LOADING:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton variant={Variant.GRADIENT} width="w-full" isLoading={true} />
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                WITHDRAW
              </WButton>
            </div>
          </div>
        )
      case TokenApprovalState.NOT_APPROVED:
        return (
          <div className="flex flex-row items-center text-lg">
            {isHasDeposit && fromToken ? (
              <WButton
                variant={Variant.GRADIENT}
                className={`withdraw-${fromToken.symbol}-approve`}
                width="w-full"
                disabled={!isHasDeposit}
                onClick={() => tryApproval(tokenAmountForDisplay)}
              >
                APPROVE
              </WButton>
            ) : (
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled={!isHasDeposit}>
                APPROVE
              </WButton>
            )}
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
                WITHDRAW
              </WButton>
            </div>
          </div>
        )

      case TokenApprovalState.APPROVED:
        return (
          <div className="flex flex-row items-center text-lg">
            <WButton
              disabled
              width="w-full"
              variant={Variant.LIGHT_PURPLE}
              className={`withdraw-approved-${asset.symbol}`}
            >
              APPROVED
            </WButton>
            <div className="h-0.5 w-8 flex-none border-t-1 border-wombatPurple3"></div>
            <div className="relative w-full">
              <WButton
                className={`withdraw-${asset.symbol}-withdraw`}
                variant={
                  !lpAmount || lpAmount?.eq(0) || isAmountGreaterThanBalance()
                    ? Variant.LIGHT_PURPLE
                    : Variant.GRADIENT
                }
                width="w-full"
                disabled={!lpAmount || lpAmount?.eq(0) || isAmountGreaterThanBalance()}
                isLoading={
                  withdrawPending ||
                  withdrawState === WithdrawState.LOADING ||
                  withdrawState === WithdrawState.SUBMITED
                }
                onClick={async () => {
                  setWithdrawPending(true)
                }}
              >
                {withdrawState !== WithdrawState.LOADING &&
                  withdrawState !== WithdrawState.SUBMITED &&
                  'WITHDRAW'}
              </WButton>
            </div>
          </div>
        )
    }
  }

  const quotebalance: TokenSymbolGenericType<string> = {}
  for (const sym of tokenSymbolEnumValues) {
    quotebalance[sym] = quotes[sym]?.stringAmount
  }
  return (
    <>
      {fromToken && (
        <WithdrawInput
          token={fromToken}
          setLpAmount={setLpAmount}
          withdrawToken={withdrawToken}
          setWithdrawToken={setWithdrawToken}
          withdrawTokenPoolLabel={currentPool.label}
          tokenAmountForDisplay={tokenAmountForDisplay}
          setTokenAmountForDisplay={setTokenAmountForDisplay}
          balances={deposits}
          quotes={quotebalance}
          withdrawalQuote={withdrawalQuote}
          disableWithdrawInOtherAsset={false}
        />
      )}
      <div className="py-4 font-Work text-sm font-normal text-wombatBrown">
        {/* uncomment it when needed */}
        {/* <div className="flex justify-between">
          <div className="flex items-center">
            <span>Fee&nbsp;</span>{' '}
            <Tooltip pointerEvent={true} isTextPositionRightOnMobile={true}>
              Penalty to diverge the coverage ratio of the token.{' '}
              <a
                href="https://docs.wombat.exchange/docs/concepts/fees/deposit-gain-and-withdrawal-fee"
                target="_blank"
                className="font-bold hover:underline"
                rel="noreferrer"
              >
                Learn More
              </a>
            </Tooltip>
          </div>
          <div>
            {fee === 0 ? '0.00' : fee < 0.01 ? '< 0.01' : sliceDecimal(fee, 2)}{' '}
            {fromToken?.displaySymbol}
          </div>
        </div> */}
        {allStaked ? (
          <div className="my-5 flex items-center text-wombatRed">
            <Image alt={''} className="pr-2" src={warnning} width={18} height={18} />
            <span className="ml-1 font-Work text-xs">
              All tokens in this pool have been staked.
            </span>
          </div>
        ) : (
          <div>
            <span>My Remaining Pool Share </span>
            <div className="float-right text-wombatRed">
              <FormattedNumber
                amount={Number(utils.formatEther(newPoolShare))}
                threshold={0.0001}
                showColor={false}
              />
            </div>
          </div>
        )}
        {!isHasDeposit && (
          <div className="mt-4 flex items-center font-Work text-xs text-wombatRed">
            <div className="h-6 w-6">
              <Image alt={''} src={warnning}></Image>
            </div>
            <div>You currently have no deposit to withdraw</div>
          </div>
        )}
      </div>
      <Accordion
        title="Swap fee loss prevention"
        message={
          <>
            Swap fees are paid out when the tip bucket is filled. Payments may not be regular and
            removing your liquidity before the bucket is full may mean you miss out on the swap fees
            of the upcoming payout.
          </>
        }
      />
      {buttonGroup()}
      {lpAmount && account && isAmountGreaterThanBalance() && (
        <div className="-mb-2 mt-2 text-center text-red-600">Insufficient balance</div>
      )}
      {isDisplayModal && renderModal()}
    </>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { BigNumber, ethers, utils } from 'ethers'
import millify from 'millify'
import commafy from './commafy'
import { isParsableString } from './isParsableString'
/**
 * If a value is in WAD, it will format the value.
 * @param {string | BigNumber} value a value in string or in WAD
 * @returns {string} a formatted string value
 */
const getStringInput = (value: string | BigNumber): string => {
  if (ethers.BigNumber.isBigNumber(value)) {
    return utils.formatEther(value)
  }
  return isParsableString(value, 18) ? value : '0'
}

/**
 * To avoid fractional component exceeds decimals
 * Trim extra decimals if 1.1234567 and decimal is 6 then 1.123456
 * @param {string | BigNumber} value a value in string or in WAD
 * @param {number} decimalPlace number of decimal in integer
 * @returns {string}
 */
function getDpFormat(value: number, decimalPlace: number): string {
  const valueStr = String(value)
  let result = valueStr
  // check . exist
  if (valueStr.includes('.')) {
    // it is decimal number
    const [integer, decimalNum] = valueStr.split('.')
    if (decimalPlace === 0) return integer
    result = decimalNum === '0' ? integer : integer + '.' + decimalNum.substring(0, decimalPlace)
  }
  return removeTrailingZeros(result)
}

function removeTrailingZeros(input: string): string {
  const parts = input.split('.')

  if (parts.length === 2) {
    const integerPart = parts[0]
    const decimalPart = parts[1].replace(/0+$/, '') // Remove trailing '0's

    if (decimalPart.length > 0) {
      return `${integerPart}.${decimalPart}`
    } else {
      return integerPart
    }
  }

  return input
}

function getMillifiedFormat(value: number): string {
  // https://crusaders-of-the-lost-idols.fandom.com/wiki/Large_Number_Abbreviations
  if (Math.abs(value) < 100000) {
    return getDpFormat(value, 2)
  } else if (Math.abs(value) <= Number.MAX_SAFE_INTEGER) {
    return millify(value, { precision: 2 })
  } else if (value > 1e18) {
    return commafy(getDpFormat(value / 1e18, 1)) + 'Q'
  } else if (value > 1e15) {
    return commafy(getDpFormat(value / 1e15, 1)) + 'q'
  } else {
    return commafy(getDpFormat(value / 1e12, 1)) + 't'
  }
}

const sliceDecimal = (
  number: number,
  decimal: number,
  locale?: string,
  trailDoubleZero?: boolean
) => {
  function join(wholeNumber: string, decimals: string) {
    if (!decimals) return wholeNumber
    return [wholeNumber, decimals].join('.')
  }

  // avoid exponential notation, 18 is max decimal that user can type to input
  // if trailDoubleZero is provided, that mean number < 10000, have no exponential notation
  // eslint-disable-next-line prefer-const
  let [wholeNumber, decimals] = number.toFixed(18).toString().split('.')
  const [realWholeNumber] = number.toFixed(decimal).toString().split('.')

  if (locale === 'US') {
    wholeNumber = Intl.NumberFormat('en-US').format(Number(wholeNumber))
  }

  if (decimal > 0 && decimals && parseInt(decimals) !== 0) {
    if (decimals.length > decimal) {
      decimals = decimals.substring(0, decimal + 1)
    }
    const lastDigit = parseInt(decimals.charAt(decimal), 10)

    if (decimals.length > decimal) {
      const roundedDecimals = (
        Math.round(parseFloat(`0.${decimals}`) * 10 ** decimal) /
        10 ** decimal
      ).toString()
      decimals = roundedDecimals.substring(2)
    }

    while (decimals.length < decimal) {
      decimals = decimals + '0'
    }

    return join(realWholeNumber >= wholeNumber ? realWholeNumber : wholeNumber, decimals)
  } else if (decimal === 0) {
    const firstDigit = parseInt(decimals.charAt(0), 10)
    if (firstDigit >= 5) {
      wholeNumber = (parseInt(wholeNumber, 10) + 1).toString()
    }
    decimals = ''
  }

  if (trailDoubleZero) return join(wholeNumber, '00')

  return wholeNumber
}

const replaceString = (text: string, from: string, to: string) => text.replace(from, to)

const formatNumberUSLocale = (val: string, decimal = 2): string => {
  const parsedVal = parseFloat(val)
  if (isNaN(parsedVal)) return ''

  const kilo = 1000
  const grand = 10000
  const milion = 1000000
  const bilion = 1000000000

  if (parsedVal === 0) return '0.00'
  if (parsedVal < 0.01) return '<\u00A00.01'
  if (parsedVal >= bilion) {
    return sliceDecimal(parsedVal / bilion, decimal, 'US', false) + 'B'
  }
  if (parsedVal >= milion) {
    return sliceDecimal(parsedVal / milion, decimal, 'US', false) + 'M'
  }
  if (parsedVal >= grand) {
    return sliceDecimal(parsedVal / kilo, decimal, 'US', false) + 'K'
  }
  if (parsedVal >= kilo) {
    return sliceDecimal(parsedVal, 0)
  }
  return sliceDecimal(parsedVal, decimal, 'US', true)
}

/**
 * To avoid floating point error for displaying percentages
 * https://www.codemag.com/article/1811041/JavaScript-Corner-Math-and-the-Pitfalls-of-Floating-Point-Numbers
 * @param {number} value
 * @param {number} pow power of 10 to be multipled. default 0
 * @returns {number}
 */
function roundPercentage(value: number): number {
  return value > 1 ? Math.round(value * 100) / 100 : +((value / 100) * 100).toPrecision(1)
}

export {
  getMillifiedFormat,
  getDpFormat,
  getStringInput,
  sliceDecimal,
  replaceString,
  formatNumberUSLocale,
  roundPercentage,
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import Image from 'next/image'
import { ReactNode, useState } from 'react'
import { Token } from '../../constants/contract/token/Token'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { useWeb3 } from '../../context/Web3Context'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import SelectTokenModal from '../SelectTokenModal'
import TokenImage from '../TokenImage'
import { TokenSymbolGenericType } from '../../interfaces/common'
import ArrowUp from '../../public/assets/icons/arrow-up.svg'
import styled from '@emotion/styled'

const Dots = styled.span<{ position?: 'before' | 'after' }>`
  &::${({ position }) => position ?? 'after'} {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

type Props = {
  id?: string
  tokens: Token[]
  selectedToken: Token
  disableTokens: TokenSymbol[] | null
  onClose: () => void
  onTokenChange: (token: Token) => void
  input: ReactNode
  balances?: TokenSymbolGenericType<string>
}

export default function TokenSelector({
  id,
  tokens,
  selectedToken,
  disableTokens,
  onTokenChange,
  input,
  balances,
}: Props) {
  const { account } = useWeb3()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function openModal() {
    setIsOpen(!isOpen)
  }

  return (
    <div className="w-full">
      <div className="flex w-full flex-row items-center">
        <button
          className="button-hover-opacity hidden cursor-pointer flex-col md:flex md:space-x-2"
          onClick={openModal}
        >
          <TokenImage tokenSymbol={selectedToken.symbol} width="60" height="60" />
        </button>
        <div className="flex w-full select-none flex-col md:pl-4">
          <div className="mb-1 flex items-center justify-between">
            <button
              id={`selector-${id}`}
              className="button-hover-opacity flex	 items-center pl-0 text-left md:mr-2"
              onClick={openModal}
            >
              <div className="md:hidden">
                <TokenImage tokenSymbol={selectedToken.symbol} width="48" height="48" />
              </div>
              <div className="mx-2">
                <div className="flex items-center ">
                  <div className="text-md min-w-14 font-Work font-medium text-wombatBrown1">
                    {selectedToken.displaySymbol}
                  </div>
                  <div className="ml-2 rotate-180">
                    <Image alt={''} src={ArrowUp} width={12} height={12} />
                  </div>
                </div>
              </div>
            </button>
            {account && selectedToken && (
              <div className="text-xs text-wombatBrown1">
                Balance:{' '}
                {balances ? formatNumberUSLocale(balances[selectedToken.symbol] ?? '0') : <Dots />}{' '}
                {selectedToken.displaySymbol}
              </div>
            )}
          </div>
          {input}
        </div>
      </div>
      <SelectTokenModal
        {...{
          id,
          isOpen,
          setIsOpen,
          tokens,
          disableTokens,
          onTokenChange,
          selectedToken,
          balances,
        }}
      />
    </div>
  )
}

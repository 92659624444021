import { safeWdiv, strToWad } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import { useMemo } from 'react'
import { ASSETS } from '../../constants/contract/asset'
import { getPoolTokenPair } from '../../constants/contract/pool'
import { useWeb3 } from '../../context/Web3Context'
import { useCashesData } from '../../store/Asset/hooks'
import { useBoostedPoolRewarderData, useMasterWombatData } from '../../store/MulticallData/hooks'
import { useTokenPrices } from '../../store/Prices/hooks'
import { calculateApr } from '../../utils'
import { getInitialAssetProperty } from '../../utils/asset'
import { calLPPrice } from '../../utils/math'

type AprType = {
  base: string[]
  average: string[]
  boosted: string[]
}
function useBoostedPoolRewarderAprs() {
  const { chainId } = useWeb3()
  const poolRewarderData = useBoostedPoolRewarderData()
  const masterWombatData = useMasterWombatData()
  const tokenPrices = useTokenPrices()
  const { lpTokenToTokenRates } = useCashesData()

  const aprs = useMemo(() => {
    const calculateAprs = () => {
      const basePartition = masterWombatData.withoutAccount?.basePartition
      const boostedPartition = masterWombatData.withoutAccount?.boostedPartition
      const aprs_ = getInitialAssetProperty<AprType>()
      const poolTokenPair = getPoolTokenPair(chainId)
      poolTokenPair.forEach(({ poolLabel, tokenSymbol: assetTokenSymbol }) => {
        const asset = ASSETS[chainId][poolLabel][assetTokenSymbol]
        aprs_[poolLabel][assetTokenSymbol] = {
          base: [],
          average: [],
          boosted: [],
        }
        const rewardTokenPerSecs =
          poolRewarderData.withoutAccount?.rewardTokenPerSec[poolLabel]?.[assetTokenSymbol]
        if (!rewardTokenPerSecs || !asset) return
        const totalLpStakedBN =
          masterWombatData.withoutAccount?.totalLpStakedBNs[poolLabel]?.[assetTokenSymbol]
        if (!totalLpStakedBN) return

        asset.boostedPoolRewarder?.rewardTokenSymbols?.forEach((rewardTokenSymbol, index) => {
          const isEmissionActive =
            poolRewarderData.withoutAccount?.isEmissionActive?.[poolLabel]?.[assetTokenSymbol]?.[
              index
            ]?.value || false
          // If the reward is not active anymore, we set the reward per sec to 0
          const rewardPerSecWad = strToWad(isEmissionActive ? rewardTokenPerSecs[index].value : '0')
          const assetTokenPriceWad = strToWad(tokenPrices[assetTokenSymbol])
          const rewardTokenPriceWad = strToWad(tokenPrices[rewardTokenSymbol])
          const lpUnitPrice = calLPPrice(
            utils.parseEther('1'),
            lpTokenToTokenRates[poolLabel][assetTokenSymbol] ?? null,
            assetTokenPriceWad
          )
          const allAnnualRewardWad = rewardPerSecWad.mul(60 * 60 * 24 * 365)
          /**
           * Base APR
           */
          if (basePartition) {
            const annualRewardWadForBase = allAnnualRewardWad.mul(basePartition).div(1000)

            const baseAprWad =
              totalLpStakedBN && lpUnitPrice && !totalLpStakedBN.isZero()
                ? calculateApr(
                    utils.parseEther('1'),
                    annualRewardWadForBase,
                    rewardTokenPriceWad,
                    totalLpStakedBN,
                    lpUnitPrice
                  )
                : BigNumber.from(0)
            const baseApr = utils.formatEther(baseAprWad)

            let baseAprs = aprs_[poolLabel][assetTokenSymbol]?.base
            if (baseAprs) {
              baseAprs.push(baseApr)
            } else {
              baseAprs = [baseApr]
            }
            /**
             * Average APR
             */
            if (boostedPartition) {
              const averageBoostedAprWad = safeWdiv(
                baseAprWad.mul(boostedPartition),
                strToWad(String(basePartition))
              )

              const averageBoostedApr = utils.formatEther(averageBoostedAprWad)
              let averageBoostedAprs = aprs_[poolLabel][assetTokenSymbol]?.average
              if (averageBoostedAprs) {
                averageBoostedAprs.push(averageBoostedApr)
              } else {
                averageBoostedAprs = [averageBoostedApr]
              }
            }
          }

          /**
           * Boosted APR
           */
          if (boostedPartition) {
            const userInfo = masterWombatData.withAccount?.userInfos[poolLabel][assetTokenSymbol]
            const poolInfo = masterWombatData.withoutAccount?.poolInfos[poolLabel][assetTokenSymbol]
            const annualRewardWadForBoosted = allAnnualRewardWad.mul(boostedPartition).div(1000)

            const boostedAprWad =
              totalLpStakedBN &&
              lpUnitPrice &&
              !totalLpStakedBN.isZero() &&
              userInfo &&
              poolInfo &&
              !userInfo.amount.isZero()
                ? calculateApr(
                    userInfo.factor.mul(utils.parseEther('1')).div(userInfo.amount),
                    annualRewardWadForBoosted,
                    rewardTokenPriceWad,
                    poolInfo.sumOfFactors,
                    lpUnitPrice
                  )
                : BigNumber.from(0)

            const boostedApr = utils.formatEther(boostedAprWad)
            let boostedAprs = aprs_[poolLabel][assetTokenSymbol]?.boosted
            if (boostedAprs) {
              boostedAprs.push(boostedApr)
            } else {
              boostedAprs = [boostedApr]
            }
          }
        })
      })
      return aprs_
    }
    return calculateAprs()
  }, [
    chainId,
    lpTokenToTokenRates,
    masterWombatData.withAccount?.userInfos,
    masterWombatData.withoutAccount?.basePartition,
    masterWombatData.withoutAccount?.boostedPartition,
    masterWombatData.withoutAccount?.poolInfos,
    masterWombatData.withoutAccount?.totalLpStakedBNs,
    poolRewarderData.withoutAccount?.isEmissionActive,
    poolRewarderData.withoutAccount?.rewardTokenPerSec,
    tokenPrices,
  ])
  return { boostedPoolRewarderAprs: aprs }
}

export default useBoostedPoolRewarderAprs

import clsx from 'clsx'
import React from 'react'

export type InputProps = {
  value: string
  labels?: {
    topLeading?: React.ReactNode
    topTrailing?: React.ReactNode
  }
  unit?: string
  onChange: (value: string) => void
  onMaxBtnClick?: () => void
  variant?: 'primary' | 'secondary' | 'whiteBg'
  disabled?: boolean
  validation?: {
    isValid: boolean
    warningMessage: string
  }
}

function Input({
  value,
  onChange,
  onMaxBtnClick,
  labels,
  variant = 'primary',
  unit,
  disabled,
  validation,
}: InputProps) {
  const inputStyles = (() => {
    switch (variant) {
      case 'primary':
        return 'border-wombatBrown1 focus-within:border-wombatBrown hover:border-wombatBrown'
      case 'secondary':
        return 'border-wombatPurple1 focus-within:border-wombatPurple hover:border-wombatPurple'
      case 'whiteBg':
        return 'bg-white border-none text-wombatPurple'
    }
  })()

  const hasInvalidInputValue = validation && !validation.isValid
  return (
    <div
      className={clsx(
        'relative font-Work text-sm text-wombatBrown',
        hasInvalidInputValue && 'text-red-600'
      )}
    >
      <div
        className={clsx(
          'relative flex items-center justify-between rounded-md border-1 p-2',
          inputStyles,
          hasInvalidInputValue &&
            'border-red-600 focus-within:border-r-red-600 hover:border-red-600'
        )}
      >
        <div className="absolute top-[-50%] left-[0] flex w-full flex-row items-center justify-between text-xs text-wombatBrown">
          <div className="text-left">{labels?.topLeading && labels?.topLeading}</div>
          <div className="text-right">{labels?.topTrailing && labels?.topTrailing}</div>
        </div>
        <input
          type="text"
          inputMode="numeric"
          placeholder="0.00"
          className="w-full bg-transparent font-medium placeholder-wombatPurple3 focus:outline-none"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
        {onMaxBtnClick && (
          <button
            onClick={onMaxBtnClick}
            className={`button-hover-opacity ml-2 rounded-md border-1 border-wombatPurple1 px-2 font-semibold text-wombatPurple1`}
            disabled={disabled}
          >
            MAX
          </button>
        )}
        {unit && <span className="ml-2">{unit}</span>}
      </div>
      {hasInvalidInputValue && (
        <p className="mt-1 text-xs text-red-600">{validation.warningMessage}</p>
      )}
    </div>
  )
}

export default Input

import React from 'react'
import { chainInfos } from '../../constants/web3/chains'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import Image from 'next/image'
import arrowUp from '../../public/assets/icons/arrow-up.svg'

export interface ChainSelectorProps {
  chainId: SupportedChainId
  onSelectionChange: () => void
  leadingLabel?: string
}

const ChainSelector = ({ chainId, leadingLabel, onSelectionChange }: ChainSelectorProps) => {
  return (
    <div
      className="flex cursor-pointer justify-between rounded-lg bg-white p-[8px]"
      onClick={onSelectionChange}
    >
      <div className="flex items-center gap-1">
        {leadingLabel && (
          <span className="bg-linearGradientApprove p-[4px_8px] font-Quantico font-bold text-white">
            {leadingLabel}
          </span>
        )}
        <div className="flex items-center">
          <Image layout="fixed" src={chainInfos[chainId].icon} width={20} height={20}></Image>
        </div>
        <div className="flex text-[14px]"> {chainInfos[chainId].label}</div>
      </div>
      <Image src={arrowUp} alt="" className="rotate-180" />
    </div>
  )
}

export default ChainSelector

import React, { useState } from 'react'
import Modal from '../../Modal'
import { useModalContext } from '../../../context/ModalContext'
import SelfServiceBribeForm from '../SelfServiceBribeForm'
import Button from '../../Button'
import { useSelfServiceBribePageContext } from '../../../context/SelfServiceBribePageContext'
import { ModalId } from '../../../interfaces/modal'
import { TOKENS } from '../../../constants/contract'
import { useWeb3 } from '../../../context/Web3Context'
import { timeConverter } from '../../../utils'
import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { SECONDS_PER_WEEK } from '../../../constants/common'
import useSelfServiceBribe from '../../../hooks/bribe/useSelfServiceBribe'
import { ASSETS } from '../../../constants/contract/asset'

type Props = {
  isOpen: boolean
}

function TopUpModal({ isOpen }: Props) {
  const { chainId } = useWeb3()
  const { topUpBribe } = useSelfServiceBribe()
  const { addedRewardList, isToShowBribeData } = useSelfServiceBribePageContext()
  const {
    actions: { closeModal },
    modalState,
  } = useModalContext()
  const [topUpAmountInputted, setTopUpAmountInputted] = useState('')
  const [isToppingUp, setIsToppingUp] = useState(false)
  const [isReadyToTopUp, setIsReadyToTopUp] = useState(false)
  const [extendedSeconds, setExtendedSeconds] = useState(0)
  if (modalState.currentModalId !== ModalId.TOP_UP_BRIBE) {
    return <></>
  }
  const { assetSymbol, rewardSymbol, poolSymbol } = modalState.payload
  const targetRewardData = addedRewardList.filter(
    (rewardData) =>
      rewardData.assetSymbol === assetSymbol &&
      rewardData.poolSymbol === poolSymbol &&
      rewardData.rewardSymbol === rewardSymbol
  )[0]

  const rewardDisplaySymbol = TOKENS[chainId][rewardSymbol]?.displaySymbol
  const label = isToShowBribeData ? 'Bribe' : 'Rewarder'
  const newBribeEndDateInUnixTime = (() => {
    if (+targetRewardData.weeklyEmissionRate === 0) return
    let newEndDateInUnixTime = 0
    if (targetRewardData.runoutTimestamp <= 0) {
      const newEndDate = new Date()
      newEndDate.setSeconds(newEndDate.getSeconds() + extendedSeconds)
      newEndDateInUnixTime = newEndDate.getTime() / 1000
    } else {
      newEndDateInUnixTime = targetRewardData.runoutTimestamp + extendedSeconds
    }
    return newEndDateInUnixTime
  })()

  const handleTopUpReward = async () => {
    const asset = ASSETS[chainId][poolSymbol][assetSymbol]
    const rewarderAddress = isToShowBribeData
      ? asset?.bribeRewarder?.rewarderAddress
      : asset?.boostedPoolRewarder?.rewarderAddress
    if (rewarderAddress) {
      setIsToppingUp(true)
      await topUpBribe(rewarderAddress, rewardSymbol, topUpAmountInputted)

      setIsToppingUp(false)
      setTopUpAmountInputted('')
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <h1 className="!mt-0 !mb-3 text-center text-xl font-semibold text-wombatPurple1">
        Top-up {label}
      </h1>
      <SelfServiceBribeForm
        onFlowChange={(isCompleted) => {
          setIsReadyToTopUp(isCompleted)
        }}
        guageControl={{
          value: { assetTokenSymbol: assetSymbol, poolLabel: poolSymbol },
          disabled: true,
        }}
        rewardTokenControl={{
          value: rewardSymbol,
          disabled: true,
        }}
        rewardAmountControl={{
          value: topUpAmountInputted,
          onChange: (value) => {
            setTopUpAmountInputted(value)
            const extendedSeconds_ =
              +value / (+targetRewardData.weeklyEmissionRate / SECONDS_PER_WEEK)
            setExtendedSeconds(extendedSeconds_)
          },
          onMaxBtnClick: (maxAmount) => {
            setTopUpAmountInputted(maxAmount)
            const extendedSeconds_ =
              +maxAmount / (+targetRewardData.weeklyEmissionRate / SECONDS_PER_WEEK)
            setExtendedSeconds(extendedSeconds_)
          },
          unit: rewardDisplaySymbol,
        }}
        extendedSeconds={extendedSeconds}
      />
      <div className="mt-4 flex flex-col gap-3 text-sm font-medium">
        <div className="flex flex-row justify-between">
          <p>Current Weekly Emission:</p>
          <p>
            {targetRewardData ? getCommifiedFormat(targetRewardData.weeklyEmissionRate, 2) : '-'}{' '}
            {rewardDisplaySymbol}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="font-bold">New {label} End Date:</p>
          <p className="text-gradient">
            {newBribeEndDateInUnixTime
              ? timeConverter(newBribeEndDateInUnixTime, true, false, true)
              : '-'}
          </p>
        </div>
      </div>
      <Button
        className="mt-5 mr-auto ml-auto w-[200px] py-[4px]"
        onClick={handleTopUpReward}
        isLoading={isToppingUp}
        disabled={!isReadyToTopUp}
      >
        <span className="text-sm">Top-up {label}</span>
      </Button>
    </Modal>
  )
}

export default TopUpModal

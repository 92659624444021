import { createSlice } from '@reduxjs/toolkit'
import {
  MultiChainMultiCallDataWithAccountType,
  MultiChainMulticallDataWithoutAccountType,
} from './types'
import { TokenSymbolStringType } from '../../interfaces/common'
import getCoingeckoPriceData from './thunks/getCoingeckoPriceData'
import fetchMultichainMulticallData from './thunks/fetchMultichainMulticallData'

export interface MultiChainMultiCallDataState {
  withAccount: MultiChainMultiCallDataWithAccountType
  withoutAccount: MultiChainMulticallDataWithoutAccountType
  coingeckoPriceData: Partial<TokenSymbolStringType> | undefined
  multicalldataFetching: boolean
  coingeckoPriceDataFetching: boolean
}

const initialState: MultiChainMultiCallDataState = {
  withAccount: {},
  withoutAccount: {},
  coingeckoPriceData: undefined,
  multicalldataFetching: false,
  coingeckoPriceDataFetching: false,
}

const multiChainMultiCallDataSlice = createSlice({
  name: 'MultiChainMultiCallData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoingeckoPriceData.fulfilled, (state, action) => {
      state.coingeckoPriceData = {
        ...state.coingeckoPriceData,
        ...(action.payload.coingeckoPriceData || {}),
      }
    })
    builder.addCase(getCoingeckoPriceData.pending, (state) => {
      state.coingeckoPriceDataFetching = true
    })
    builder.addCase(getCoingeckoPriceData.rejected, (state) => {
      state.coingeckoPriceDataFetching = false
    })

    builder.addCase(fetchMultichainMulticallData.pending, (state) => {
      state.multicalldataFetching = true
    })
    builder.addCase(fetchMultichainMulticallData.rejected, (state, action) => {
      state.multicalldataFetching = false
      if (!action.error.toString().includes('CALL_EXCEPTION')) {
        console.error('@MultichainMulticallDataProvider', action.error)
      }
    })

    builder.addCase(fetchMultichainMulticallData.fulfilled, (state, action) => {
      state.withAccount = {
        ...state.withAccount,
        ...action.payload.withAccount,
      }
      state.withoutAccount = {
        ...state.withoutAccount,
        ...action.payload.withoutAccount,
      }
    })
  },
})

export default multiChainMultiCallDataSlice.reducer

import { getDpFormat, isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import _ from 'lodash'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { useVotingData } from '../../../context/VotingDataContext'
import { PoolLabelsTokenSymbolsGenericType } from '../../../interfaces/common'

export interface GaugeDataType {
  id?: string
  poolSymbol: PoolLabels
  assetTokenSymbol: TokenSymbol
}
type VoteWeightPercentageInputsType = PoolLabelsTokenSymbolsGenericType<string>

type GaugesInEditModeType = PoolLabelsTokenSymbolsGenericType<boolean>

interface ContextType {
  votedAndAddedGaugeList: GaugeDataType[]
  votedGaugeList: GaugeDataType[]
  addedGaugeList: GaugeDataType[]
  editedGaugeList: GaugeDataType[]
  voteWeightPercentageInputs: VoteWeightPercentageInputsType
  gaugesInEditMode: GaugesInEditModeType
  availableVoteWeightPercentageForFocusedInput: string
  totalVoteWeightPercentageOfTheTable: string
  actions: {
    enterEditMode: (gauge: GaugeDataType, defaultInputValue: string) => void
    exitEditMode: () => void
    addNewGauge: (gauge: GaugeDataType) => void
    updateVoteWeightPercentageInput: (
      poolSymbol: PoolLabels,
      assetTokenSymbol: TokenSymbol,
      value: string
    ) => void
    resetVoteWeightPercentageInputs: () => void
    updateAvailableVoteWeightPercentageForFocusedInput: (
      poolSymbol: PoolLabels,
      assetTokenSymbol: TokenSymbol
    ) => string
    getMaxVoteWeightPercentageForFocusedInput: (
      poolSymbol: PoolLabels,
      assetTokenSymbol: TokenSymbol
    ) => void
  }
}
const UserVoteAllocationTableContext = createContext<ContextType>({} as ContextType)

UserVoteAllocationTableContext.displayName = 'UserVoteAllocationTableContext'

export const useUserVoteAllocationTable = (): ContextType => {
  return useContext(UserVoteAllocationTableContext)
}

interface Props {
  children: React.ReactNode
}
export function UserVoteAllocationTableProvider({ children }: Props) {
  const { user } = useVotingData()
  // contains all gauges' current inputs but it didn't know whether the inputs are edited or not.
  const [voteWeightPercentageInputs, setVoteWeightPercentageInputs] =
    useState<VoteWeightPercentageInputsType>(user.voteWeightOfEachAsset.percentage)
  const [
    availableVoteWeightPercentageForFocusedInput,
    setAvailableVoteWeightPercentageForFocusedInput,
  ] = useState('0')

  useEffect(() => {
    if (
      _.isEmpty(voteWeightPercentageInputs) &&
      !_.isEmpty(user.voteWeightOfEachAsset.percentage)
    ) {
      setVoteWeightPercentageInputs(user.voteWeightOfEachAsset.percentage)
    }
    // don't add voteWeightPercentageInputs to dependency.
    // just want to get initial value of vote weight of each asset.
    // since passing user.voteWeightOfEachAsset.percentage to useState as default value is always getting an empty object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.voteWeightOfEachAsset.percentage])

  const totalVoteWeightPercentageOfTheTable = useMemo(() => {
    let sumWad = strToWad('0')
    for (const poolPercentageInputs of Object.values(voteWeightPercentageInputs)) {
      sumWad = sumWad.add(
        Object.values(poolPercentageInputs).reduce(
          (prev, current) => prev.add(strToWad(current)),
          strToWad('0')
        )
      )
    }
    return utils.formatEther(sumWad)
  }, [voteWeightPercentageInputs])

  /**
   * Gauge List
   */
  const [gaugesInEditMode, setGaugesInEditMode] = useState<GaugesInEditModeType>({})
  // newly added gauges
  const [addedGaugeList, setAddedGaugeList] = useState<GaugeDataType[]>([])
  // previously voted gauges from SC
  const votedGaugeList = useMemo(() => {
    const outputData: GaugeDataType[] = []
    for (const poolSymbolStr in user.voteWeightOfEachAsset.percentage) {
      const poolSymbol = poolSymbolStr as PoolLabels
      const poolVoteWeightOfEachAssetInPercentage =
        user.voteWeightOfEachAsset.percentage[poolSymbol]
      for (const assetTokenSymbolStr in poolVoteWeightOfEachAssetInPercentage) {
        const assetTokenSymbol = assetTokenSymbolStr as TokenSymbol
        // if percentage is more than 0 then show it.
        if (strToWad(poolVoteWeightOfEachAssetInPercentage[assetTokenSymbol]).gt(strToWad('0'))) {
          outputData.push({
            id: `${poolSymbol}-${assetTokenSymbol}`,
            poolSymbol: poolSymbol,
            assetTokenSymbol: assetTokenSymbol,
          })
        }
      }
    }

    return outputData
  }, [user.voteWeightOfEachAsset.percentage])
  // combined gauge list controlling which gauges are showed on the table
  const votedAndAddedGaugeList = useMemo(() => {
    // prevent from showing duplicate gauges.
    // The reason is that SC has the updated data but handleVote() in useVote() hasn't finished running.
    const filteredVotedGaugeList = votedGaugeList.filter(
      (votedGauge) =>
        !addedGaugeList.some(
          (addedGauge) =>
            addedGauge.poolSymbol === votedGauge.poolSymbol &&
            addedGauge.assetTokenSymbol === votedGauge.assetTokenSymbol
        )
    )
    return [...filteredVotedGaugeList, ...addedGaugeList]
  }, [addedGaugeList, votedGaugeList])
  // edited gauges mean users have changed their votes on gauges
  const editedGaugeList = useMemo(() => {
    return votedAndAddedGaugeList.filter((gauge) => {
      const { poolSymbol, assetTokenSymbol } = gauge
      const newVoteWeightPercentageWad = strToWad(
        voteWeightPercentageInputs[poolSymbol]?.[assetTokenSymbol]
      )
      const previousVoteWeightPercentageWad = strToWad(
        user.voteWeightOfEachAsset.percentage[poolSymbol]?.[assetTokenSymbol]
      )
      return !newVoteWeightPercentageWad.eq(previousVoteWeightPercentageWad)
    })
  }, [user.voteWeightOfEachAsset.percentage, voteWeightPercentageInputs, votedAndAddedGaugeList])
  /**
   * Actions
   */
  const updateAvailableVoteWeightPercentageForFocusedInput = useCallback(
    (poolSymbol: PoolLabels, assetTokenSymbol: TokenSymbol) => {
      const currentVoteWeightPercentageInputWad = strToWad(
        voteWeightPercentageInputs[poolSymbol]?.[assetTokenSymbol]
      )
      const availableVoteWeight = utils.formatEther(
        strToWad('100').sub(
          strToWad(totalVoteWeightPercentageOfTheTable).sub(currentVoteWeightPercentageInputWad)
        )
      )
      setAvailableVoteWeightPercentageForFocusedInput(availableVoteWeight)
      return availableVoteWeight
    },
    [totalVoteWeightPercentageOfTheTable, voteWeightPercentageInputs]
  )
  const updateVoteWeightPercentageInput = useCallback(
    (poolSymbol: PoolLabels, assetTokenSymbol: TokenSymbol, value: string) => {
      // only accept 2 d.p for the input
      if (value !== '' && !isParsableString(value, 2, true)) {
        return
      }

      if (strToWad(value).gt(strToWad(availableVoteWeightPercentageForFocusedInput))) {
        return
      }
      setVoteWeightPercentageInputs((prev) => {
        return {
          ...prev,
          [poolSymbol]: { ...prev[poolSymbol], [assetTokenSymbol]: value },
        }
      })
    },
    [availableVoteWeightPercentageForFocusedInput]
  )
  const resetVoteWeightPercentageInputs = useCallback(() => {
    setVoteWeightPercentageInputs(user.voteWeightOfEachAsset.percentage)
  }, [user.voteWeightOfEachAsset.percentage])

  const addNewGauge = ({ poolSymbol, assetTokenSymbol }: GaugeDataType) => {
    setAddedGaugeList((prev) => [
      ...prev,
      // always put the new added gauge at the bottom
      {
        assetTokenSymbol,
        poolSymbol,
        // enter edit mode by default
        isEditing: true,
      },
    ])
    updateVoteWeightPercentageInput(poolSymbol, assetTokenSymbol, '')
    enterEditMode({ poolSymbol, assetTokenSymbol }, '0')
  }

  const enterEditMode = useCallback(
    ({ poolSymbol, assetTokenSymbol }: GaugeDataType, defaultInputValue: string) => {
      setGaugesInEditMode((prev) => {
        return {
          ...prev,
          [poolSymbol]: {
            ...prev[poolSymbol],
            [assetTokenSymbol]: true,
          },
        }
      })
      updateVoteWeightPercentageInput(poolSymbol, assetTokenSymbol, getDpFormat(defaultInputValue))
      updateAvailableVoteWeightPercentageForFocusedInput(poolSymbol, assetTokenSymbol)
    },
    [updateAvailableVoteWeightPercentageForFocusedInput, updateVoteWeightPercentageInput]
  )

  const exitEditMode = useCallback(() => {
    setAddedGaugeList([])
    setGaugesInEditMode({})
  }, [])

  const getMaxVoteWeightPercentageForFocusedInput = useCallback(
    (poolSymbol: PoolLabels, assetTokenSymbol: TokenSymbol) => {
      const availableVoteWeightPercentage = updateAvailableVoteWeightPercentageForFocusedInput(
        poolSymbol,
        assetTokenSymbol
      )
      updateVoteWeightPercentageInput(poolSymbol, assetTokenSymbol, availableVoteWeightPercentage)
    },
    [updateAvailableVoteWeightPercentageForFocusedInput, updateVoteWeightPercentageInput]
  )
  return (
    <UserVoteAllocationTableContext.Provider
      value={{
        votedGaugeList,
        addedGaugeList,
        votedAndAddedGaugeList,
        editedGaugeList,
        voteWeightPercentageInputs,
        gaugesInEditMode,
        availableVoteWeightPercentageForFocusedInput,
        totalVoteWeightPercentageOfTheTable,
        actions: {
          enterEditMode,
          exitEditMode,
          addNewGauge,
          updateVoteWeightPercentageInput,
          resetVoteWeightPercentageInputs,
          updateAvailableVoteWeightPercentageForFocusedInput,
          getMaxVoteWeightPercentageForFocusedInput,
        },
      }}
    >
      {children}
    </UserVoteAllocationTableContext.Provider>
  )
}

import { AdminTableColumnId } from '../../interfaces/admin'

export const MAX_BRIBE_RUNOUT_DAYS = 7

export const ADMIN_TABLE_COLUMN_NAME: { [id in AdminTableColumnId]: string } = {
  [AdminTableColumnId.poolInfo]: 'Pool Label',
  [AdminTableColumnId.assetInfo]: 'Asset Symbol',
  [AdminTableColumnId.pid]: 'pid',
  [AdminTableColumnId.poolRewardersEmissionInCurrentEpochs]:
    'Pool rewarder emission in current epoch',
  [AdminTableColumnId.poolRewardersBalanceInfos]: 'Pool rewarder balances',
  [AdminTableColumnId.boostedRewardersEmissionInCurrentEpochs]:
    'Boosted rewarder emission in current epoch',
  [AdminTableColumnId.boostedRewardersBalanceInfos]: 'Boosted rewarder balances',
  [AdminTableColumnId.womEmissionInCurrentEpoch]: 'Wom emission in current epoch',
  [AdminTableColumnId.womEmissionInNextEpoch]: 'Wom emission in next epoch',
  [AdminTableColumnId.allocPoint]: 'Allocation point',
  [AdminTableColumnId.gaugeWeight]: 'Gauge Weight',
  [AdminTableColumnId.bribeInfo]: 'Bribe Symbol',
  [AdminTableColumnId.bribeInCurrentEpoch]: 'Bribe in current epoch',
  [AdminTableColumnId.totalBribeInUsdInCurrentEpoch]: 'Total bribe in current epoch in USD',
  [AdminTableColumnId.bribeEfficiency]: 'Current Bribe efficiency',
  [AdminTableColumnId.projectedBribeEfficiency]: 'Projected Bribe efficiency',
  [AdminTableColumnId.bribeBalanceInfos]: '',
  [AdminTableColumnId.ampFactor]: 'Amp Factor',
  [AdminTableColumnId.haircutPercentage]: 'Haircut',
  [AdminTableColumnId.remainingSurplus]: 'Remaining Surplus',
  [AdminTableColumnId.rates]: 'Set Rate',
}

export const EMISSION_PAUSED_REMINDER =
  'The voter emission for this gauge has been paused, no emissions will be accumulated in this gauge. To continue receiving bribes and rewards, we recommend voting on other gauges.'

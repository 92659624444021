import { formattedPercentage } from '../../utils'
import clsx from 'clsx'

type Props = {
  amount: number
  threshold: number
  showColor: boolean
  noMargin?: boolean
}

function withinThreshold(x: number, y: number): boolean {
  return Math.abs(x) < y
}

function isPositive(x: number): boolean {
  return x >= 0
}

export default function FormattedNumber({ amount, threshold, showColor, noMargin = false }: Props) {
  const smallerThanThreshold = isPositive(amount)
    ? '< ' + (threshold * 100).toString() + '%'
    : '< -' + (threshold * 100).toString() + '%'

  return (
    <div className={clsx('relative sm:max-w-xl', noMargin && 'mx-0')}>
      <div className="group relative inline-block ">
        {amount === 0 ? (
          <span className={`${showColor ? 'text-green-600' : ''}`}>0%</span>
        ) : withinThreshold(amount, threshold) ? (
          isPositive(amount) ? (
            <span className={`${showColor ? 'text-green-600' : ''}`}>{smallerThanThreshold}</span>
          ) : (
            <span className={`${showColor ? 'text-red-400' : ''}`}>{smallerThanThreshold}</span>
          )
        ) : isPositive(amount) ? (
          <span className={`${showColor ? 'text-green-600' : ''}`}>
            {formattedPercentage(amount, 2)}
          </span>
        ) : (
          <span className={`${showColor ? 'text-red-400' : ''}`}>
            {formattedPercentage(amount, 2)}
          </span>
        )}
        <div className="tooltip-brown left-1/2 w-fit py-2 px-3 text-center text-sm ">
          {isPositive(amount) ? (
            // positive number in green
            <span className={`${showColor ? 'text-green-600' : 'text-white'}`}>
              {formattedPercentage(amount, 8)}
            </span>
          ) : (
            // negative number in red
            <span className={`${showColor ? 'text-red-400' : 'text-white'}`}>
              {' '}
              {formattedPercentage(amount, 8)}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import Button from '../../Button'
import SwapCreditModal from './SwapCreditModal'
import { useModalContext } from '../../../context/ModalContext'
import { ModalId } from '../../../interfaces/modal'
import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { useWeb3 } from '../../../context/Web3Context'
import { useWalletSelected } from '../../../context/WalletSelectedContext'
import clsx from 'clsx'
import { useSwapCreditContext } from '../../../context/SwapCreditContext'
import SettingModal from '../../SettingModal'

function CrossChainUserCreditInfo() {
  const { account, chainInfo } = useWeb3()
  const { open: openConnectWalletModal } = useWalletSelected()
  const {
    modalState,
    actions: { openModal },
  } = useModalContext()
  const { creditBalance, isCrossChainCreditSwap } = useSwapCreditContext()

  return (
    <div>
      <div className="flex flex-col flex-wrap gap-3 font-Work text-sm font-semibold">
        {account && +creditBalance > 0 ? (
          <div className="flex items-center justify-between">
            <p className="font-normal">Credit available ({chainInfo.label})</p>
            <p>{+creditBalance > 0 ? `${getCommifiedFormat(creditBalance)} CR` : '--'}</p>
          </div>
        ) : (
          <p className="py-4 text-center text-base text-wombatBrown1">No credit available</p>
        )}
        {+creditBalance > 0 && (
          <Button
            className={clsx('px-[10px] py-1 md:flex-[0.3]', !account && 'md:flex-1')}
            style={{ fontSize: 'inherit' }}
            onClick={() => {
              if (account) {
                openModal({ currentModalId: ModalId.SWAP_CREDIT })
              } else {
                openConnectWalletModal()
              }
            }}
            disabled={+creditBalance <= 0}
          >
            {account ? 'SWAP' : 'CONNECT WALLET'}
          </Button>
        )}
      </div>
      {modalState.currentModalId === ModalId.SWAP_CREDIT && <SwapCreditModal isOpen />}
      {modalState.currentModalId === ModalId.SWAP_CREDIT_SETTING && (
        <SettingModal
          isOpen
          onClose={() => openModal({ currentModalId: ModalId.SWAP_CREDIT })}
          isCrossChainSettingShown={isCrossChainCreditSwap}
        />
      )}
    </div>
  )
}

export default CrossChainUserCreditInfo

import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const SCROLL_TESTNET_POOL_CONFIG: PoolConfig = {
  [PoolLabels.MAIN]: {
    name: 'Main Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0x99f1dA4f5bD6082De1eB6A3Df85965df6EB6fD1D',
  },
}

import { useCallback, useEffect, useState } from 'react'

export const useScrollTo = (ref: any) => {
  const [offsetY, setOffsetY] = useState(0)

  useEffect(() => {
    if (typeof window !== 'undefined' && ref) {
      setOffsetY(ref.current.getBoundingClientRect().top + window.scrollY)
    }
  }, [ref])

  const scrollTo = useCallback(() => {
    window.scrollTo(0, offsetY)
  }, [offsetY])

  return { scrollTo }
}

export const useScrollToTop = () => {
  const scrollToTop = useCallback(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [])

  return { scrollToTop }
}

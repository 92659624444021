import { createAsyncThunk } from '@reduxjs/toolkit'
import { AssetState } from '../types'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { ethers } from 'ethers'
import { fetchCashLiability } from '../helpers'

const getCashLiability = createAsyncThunk<
  Pick<
    AssetState,
    'cashes' | 'liabilities' | 'lpTokenToTokenRates' | 'totalSupplies' | 'maxSupply'
  > | null,
  {
    chainId: SupportedChainId
    block?: ethers.CallOverrides['blockTag']
  }
>('asset/fetchCashLiability', async ({ chainId, block }) => {
  return await fetchCashLiability({ chainId, block })
})

export default getCashLiability

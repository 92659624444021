import { BigNumber } from 'ethers'
import { useState } from 'react'
import { useContract } from 'wagmi'
import { VEWOMS } from '../constants/contract/veWom'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../context/errorMessage'
import { useWeb3 } from '../context/Web3Context'
import { useTxnReceipt } from './../context/TxnReceiptProvider'
import { useAppDispatch } from '../store/hooks'
import { addErrorToast, addSuccessToast } from '../store/Toast/actions'

export enum HandleExtendLockState {
  IDLE,
  LOADING,
  SUBMITED,
  FAILED,
  SUCCESS,
}

export default function useHandleExtendLock(slot: number | null, lockDay: string) {
  const { chainId, signer } = useWeb3()
  const vewomContract = useContract({ ...VEWOMS[chainId]?.get(), signerOrProvider: signer })
  const [extendLockState, setExtendLockState] = useState(HandleExtendLockState.IDLE)
  const [txnHash, setTxnHash] = useState('')
  const [isNewTxn, setIsNewTxn] = useState(false)
  const dispatch = useAppDispatch()

  const { refreshTxnReceipt } = useTxnReceipt()

  async function tryHandleExtendLock() {
    if (vewomContract != null && slot != null && lockDay !== '') {
      setExtendLockState(HandleExtendLockState.LOADING)

      vewomContract.estimateGas
        .update(BigNumber.from(slot), BigNumber.from(lockDay))
        .then((estimateGas) =>
          vewomContract.update(BigNumber.from(slot), BigNumber.from(lockDay), {
            gasLimit: estimateGas,
          })
        )
        .then((txn) => {
          setTxnHash(txn.hash)
          if (txn) {
            setIsNewTxn(true)
            setTimeout(() => {
              setExtendLockState(HandleExtendLockState.SUBMITED)
              setIsNewTxn(false)
            }, 3000)
          }
          //   setLockAmountForDisplay()
          txn.wait().then(() => {
            refreshTxnReceipt()
            // onTxnSubmited(txn)
            setExtendLockState(HandleExtendLockState.SUCCESS)
            dispatch(
              addSuccessToast({
                message: `New Lock Period: ${lockDay} days`,
                title: 'Extend Lock Completed',
                txHash: txn.hash,
              })
            )
          })
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
          if (JSON.stringify(error).includes(code)) {
            setExtendLockState(HandleExtendLockState.IDLE)
            const errorMessage = ErrorMessages[code]
            dispatch(
              addErrorToast({
                message: errorMessage.message,
                title: errorMessage.title,
              })
            )
          } else {
            dispatch(
              addErrorToast({
                message: `${lockDay} days`,
                title: 'Lock Failed',
              })
            )
            setExtendLockState(HandleExtendLockState.FAILED)
            const errInfo = '@VeWom.update\n' + `lockDay: ${lockDay}\n` + `txHash: ${txnHash}\n`
            console.error(errInfo, error)
          }
        })
    }
  }

  return {
    extendLockState,
    tryHandleExtendLock,
    txnHash,
    isNewTxn,
  }
}

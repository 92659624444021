import { MASTER_WOMBATS } from './contract'
import { MASTER_WOMBAT_V3_ABI } from './contract/abis/masterWombat'
import { SupportedChainId } from './web3/supportedChainId'
import { readContract, multicall } from '@wagmi/core'
// It is a helper function to get pids for migration on each chain
export const getPids = async () => {
  const pidMapInChains: {
    [chainId in SupportedChainId]?: {
      [mwAddress in string]: {
        [assetAddress in string]: number
      }
    }
  } = {}
  for (const chainIdNum of Object.values(SupportedChainId)) {
    const chainId = chainIdNum as SupportedChainId
    for (const mw of Object.values(MASTER_WOMBATS[chainId] || {})) {
      const pidMapInThisMW: {
        [mwAddress in string]: {
          [assetAddress in string]: number
        }
      } = {}
      /** we only use poolLength and userInfo function so is ok to use V3  */
      const poolLength = (
        await readContract({
          address: mw.address,
          abi: MASTER_WOMBAT_V3_ABI,
          functionName: 'poolLength',
          chainId,
        })
      ).toNumber()
      const contractCalls = []
      for (let index = 0; index < poolLength; index++) {
        contractCalls.push(mw.multicall('poolInfo', [index]))
      }
      const result = (await multicall({
        contracts: contractCalls,
        chainId,
      })) as { lpToken: string }[]
      for (let index = 0; index < result.length; index++) {
        pidMapInThisMW[mw.address] = {
          ...(pidMapInThisMW[mw.address] || {}),
          [result[index].lpToken]: index,
        }
      }
      pidMapInChains[chainId] = {
        ...pidMapInChains[chainId],
        ...pidMapInThisMW,
      }
    }
  }
  console.log(pidMapInChains)
}
export const pidsForMigration: {
  [chainId in SupportedChainId]: {
    [mwAddress in string]?: {
      [assetAddress in string]?: number
    }
  }
} = {
  [SupportedChainId.BSC_MAINNET]: {
    '0xe2c07d20af0fb50cae6cdd615ca44abaaa31f9c8': {
      '0xf319947ece3823b790dd87b0a509396fe325745a': 0,
      '0x4f95fe57bea74b7f642cf9c097311959b9b988f7': 1,
      '0x74f019a5c4ed2c2950ce16fad7af838549092c5b': 2,
      '0x10f7c62f47f19e3ce08fef38f74e3c0bb31fc24f': 3,
      '0x9d2dead9547eb65aa78e239647a0c783f296406b': 4,
      '0xc496f42ea6fc72af434f48469b847a469fe0d17f': 5,
      '0xb43ee2863370a56d3b7743edcd8407259100b8e2': 6,
      '0x9d0a463d5dcb82008e86bf506eb048708a15dd84': 7,
      '0xa649be04619a8f3b3475498e1ac15c90c9661c1a': 8,
      '0x1fa71df4b344ffa5755726ea7a9a56fbbee0d38b': 9,
      '0xf9bdc872d75f76b946e0770f96851b1f2f653cac': 10,
      '0x3c42e4f84573ab8c88c8e479b7dc38a7e678d688': 11,
      '0xeaba290b154af45de72fdf2a40e56349e4e68ac2': 12,
      '0x1f502ff26db12f8e41b373f36dc0abf2d7f6723e': 13,
      '0xb5c9368545a26b91d5f7340205e5d9559f48bcf8': 14,
      '0x87073ba87517e7ca981aae3636754bca95c120e4': 15,
      '0xcf434949c242c2d32514ba971947bd3700efb015': 16,
      '0x47ab513f97e1cc7d7d1a4db4563f1a0fa5c371eb': 17,
      '0x3c8e744f6c4ed2c9d82e33d69ddcc5961aa05367': 18,
    },
    '0x489833311676b566f888119c29bd997dc6c95830': {
      '0xf319947ece3823b790dd87b0a509396fe325745a': 0,
      '0xb43ee2863370a56d3b7743edcd8407259100b8e2': 1,
      '0x4f95fe57bea74b7f642cf9c097311959b9b988f7': 2,
      '0x9d0a463d5dcb82008e86bf506eb048708a15dd84': 3,
      '0xa649be04619a8f3b3475498e1ac15c90c9661c1a': 4,
      '0x1fa71df4b344ffa5755726ea7a9a56fbbee0d38b': 5,
      '0xf9bdc872d75f76b946e0770f96851b1f2f653cac': 6,
      '0x3c42e4f84573ab8c88c8e479b7dc38a7e678d688': 7,
      '0xeaba290b154af45de72fdf2a40e56349e4e68ac2': 8,
      '0x1f502ff26db12f8e41b373f36dc0abf2d7f6723e': 9,
      '0xb5c9368545a26b91d5f7340205e5d9559f48bcf8': 10,
      '0x87073ba87517e7ca981aae3636754bca95c120e4': 11,
      '0xcf434949c242c2d32514ba971947bd3700efb015': 12,
      '0x3c8e744f6c4ed2c9d82e33d69ddcc5961aa05367': 13,
      '0x47ab513f97e1cc7d7d1a4db4563f1a0fa5c371eb': 14,
      '0x16b37225889a038fad42efded462821224a509a7': 15,
      '0x0321d1d769cc1e81ba21a157992b635363740f86': 16,
      '0x0e202a0bcad2712d1fdeeb94ec98c58beed0679f': 17,
      '0x6c7b407411b3db90dfa25da4aa66605438d378ce': 18,
      '0x3a29df144bb54a8bf3d20357c116befa7ade962d': 19,
      '0x7ff1aec17ea060bbcb7df6b8723f6ea7fc905e8f': 20,
      '0x3ac762c607ed6dba156cbcf11eff96340e86b490': 21,
      '0xa6ef6c45ebfdbc13f6d032fbdfec9b389c1603e5': 22,
      '0x77f645ee0c6d47380a942b04b8151fd542927391': 23,
      '0x791b2424df9865994ad570425278902e2b5d7946': 24,
      '0x24a70c1489d521f5e2d2612474630efe7c2ba073': 25,
      '0x9f9cea30d242d7f5527fa900f9fb0f77a98fda82': 26,
      '0x4968e21be7bb0ced1bd3859d3db993ad3a05d2e6': 27,
      '0x6b98d2b6ed0131338c7945db8588da43323d1b8c': 28,
      '0xd67edea100adc2aa8ae0b5cee7bf420ee17e5bb9': 29,
      '0xb268c3181921747379271b9bffce8b16311656e3': 30,
      '0x4d41e9ede1783b85756d3f5bd136c50c4fb8e67e': 31,
      '0xa45c0abeef67c363364e0e73832df9986aba3800': 32,
      '0x61e338828abbdd7bddad918bb3cd1f09d4345752': 33,
      '0x88beb144352bd3109c79076202fac2bceab87117': 34,
      '0xbd459e33307a4ae92fffcb45c6893084cfc273b1': 35,
      '0x8df8b50b73849f0433ee3314bd956e624e67b3ce': 36,
      '0x0e99fbfd04c255124a168c6ae68cce3c7dcc5760': 37,
      '0xb6d83f199b361403bda2c44712a77f55e7f8855f': 38,
      '0xa9a08133af8241e36193b57e4dfe43d147cd23cc': 39,
      '0x5a65cb65c601a396e79ff9e03650bdc97ed2de8b': 40,
      '0x5d64ed5f5121d0a3452ad36d8aefa554d4060f4a': 41,
      '0x184eb7dde8c00fad900120235b534aabc1d96f75': 42,
      '0x681124f5bdd9acda19d1721063254189903cd685': 43,
      '0xa393d61fe1532257b69b753af7d1efb0e22f1a6e': 44,
      '0x975693afe5bb69088a716e3a7f9bff77ec51d57f': 45,
      '0x4447de210475bfa08e5d42271a73d7624c8a5ac6': 46,
      '0x128fa2c0708bd0e357cd97eadaafa3bc9608228b': 47,
      '0x90f6f0e11ef071aecdbbca5ef01c2d630f2b5201': 48,
      '0x6b60066966080deab5090d6026cb134591a1cc95': 49,
    },
  },
  [SupportedChainId.BSC_TESTNET]: {
    '0x78baec04d81fcf87551a8495d87035911a7875c6': {
      '0xa1a8d6688a2def14d6bd3a76e3aa2bdb5670c567': 0,
      '0x61abd791773a7e583ad439f558c6c0f157707e7b': 1,
      '0x2c89105ce90f8c3ae13e01b268eae57b95e1e5a6': 2,
      '0x9f6163070fbca0a61f49f37e0123fce3d28b8e21': 3,
      '0x36c99d7d330f37ac8f22c261242033957fcc6c24': 4,
      '0x07d9b921aece37d070e2fdbf3ca48cd3b9eb6be4': 5,
      '0xbd6767f903003f2f2c7d0ff359f1e8c30817e4c3': 6,
      '0xa89f99ece9706e9d6c573b48e54259d84ab50d95': 7,
      '0x2c0c36e3d0d44b45e9cf32f45d59f5b881dba3bb': 8,
      '0x0d3dbc403d121eb53d14e2fe2a98e78ca3e17c44': 9,
      '0xf52289fd9eafc94e35868f0d5e23140ebca03ef4': 10,
      '0x0af70a8aa5707fd6407f9a583b69ce071ab58fe0': 11,
      '0xc5f2b1df25b9bfc61444b002121330bea9460f3e': 12,
      '0x47307ba3cf325ab10d9c82a22013326f8a72c4de': 13,
      '0xab18e424d43944549e2f15b21d0734c7e75873c9': 14,
      '0x2cd3d87340947ef4aee8ec2c7e612ca92c6cf469': 15,
      '0x1d6c68bc9255a4194c001de81120edf010649bb7': 16,
      '0x6f90bd374f39327bc5a8bddec5d8d26dd2ad9572': 17,
      '0xf738a9e44be4a595529b61af180e2027e71c9ae9': 18,
      '0x0c53d31de26fb198278db8213d5238f6316c6338': 19,
      '0xcaba3da39490673f91303e9e61ca23288debfda4': 20,
      '0x505b0159871f86ae0f4512bb52db5030e31e2459': 21,
      '0x82e5314dfda9ad1a7f594b7d0b5d6b13459f4826': 22,
      '0x22056c9f7e8033bbea9f32b903a0ecf8a7ea0bc7': 23,
      '0x3cbcc2a75d54f9746ec18302a1367bb9ee0c3575': 24,
      '0xaf605d0de1b4654b31b8918587abc45572e22ced': 25,
      '0x80050ff8e1c7284025a3669c30f2cd46b735a7eb': 26,
      '0xc0afb4e0f2a11e2a74f168904f47178865b728ba': 27,
      '0xb9207cc7beafb74773cd08c869d6f6f890105564': 28,
      '0x1bf6d0f4a6faeeaa3f2bb466b5f4f4d78d446317': 29,
    },
    '0x8c0e9334dbfac1b9184bc01ef638ba705cc13eaf': {
      '0xa1a8d6688a2def14d6bd3a76e3aa2bdb5670c567': 0,
      '0x61abd791773a7e583ad439f558c6c0f157707e7b': 1,
      '0x2c89105ce90f8c3ae13e01b268eae57b95e1e5a6': 2,
      '0xe52e4510cbff4712e46499ce6e87ead760542fd5': 3,
      '0x9f6163070fbca0a61f49f37e0123fce3d28b8e21': 4,
      '0x36c99d7d330f37ac8f22c261242033957fcc6c24': 5,
      '0x6f90bd374f39327bc5a8bddec5d8d26dd2ad9572': 6,
      '0x07d9b921aece37d070e2fdbf3ca48cd3b9eb6be4': 7,
      '0xa89f99ece9706e9d6c573b48e54259d84ab50d95': 8,
      '0xbd6767f903003f2f2c7d0ff359f1e8c30817e4c3': 9,
      '0x2c0c36e3d0d44b45e9cf32f45d59f5b881dba3bb': 10,
      '0x0d3dbc403d121eb53d14e2fe2a98e78ca3e17c44': 11,
      '0x3f8e976aef5719f18e03ac9a2407df1d0c601242': 12,
      '0xc5f2b1df25b9bfc61444b002121330bea9460f3e': 13,
      '0x0af70a8aa5707fd6407f9a583b69ce071ab58fe0': 14,
      '0xf52289fd9eafc94e35868f0d5e23140ebca03ef4': 15,
      '0xf738a9e44be4a595529b61af180e2027e71c9ae9': 16,
      '0x0c53d31de26fb198278db8213d5238f6316c6338': 17,
      '0xcaba3da39490673f91303e9e61ca23288debfda4': 18,
      '0x505b0159871f86ae0f4512bb52db5030e31e2459': 19,
      '0xc6824e19820b7474fc186b65853d0eff6e79202b': 20,
      '0x704c122e59c8cd20cd89deb9e00e6f499794dfd4': 21,
      '0x82e5314dfda9ad1a7f594b7d0b5d6b13459f4826': 22,
      '0x22056c9f7e8033bbea9f32b903a0ecf8a7ea0bc7': 23,
      '0x3cbcc2a75d54f9746ec18302a1367bb9ee0c3575': 24,
      '0x80050ff8e1c7284025a3669c30f2cd46b735a7eb': 25,
      '0xaf605d0de1b4654b31b8918587abc45572e22ced': 26,
      '0xc0afb4e0f2a11e2a74f168904f47178865b728ba': 27,
      '0xb9207cc7beafb74773cd08c869d6f6f890105564': 28,
    },
  },
  [SupportedChainId.ARBITRUM_ONE]: {
    '0x62a83c6791a3d7950d823bb71a38e47252b6b6f4': {
      '0x2977b0b54a76c2b56d32cef19f8ea83cc766cfd9': 0,
      '0x85cebd962861be410a777755dfa06914de6af003': 1,
      '0x0fa7b744f18d8e8c3d61b64b110f25cc27e73055': 2,
      '0x6966553568634f4225330d559a8783de7649c7d3': 3,
      '0xe62880cc6872c9e9fb1ddd73f400850fdabe798d': 4,
      '0xbd7568d25338940ba212e3f299d2ccc138fa35f0': 5,
      '0x51e073d92b0c226f7b0065909440b18a85769606': 6,
      '0x6add078996308547c57b052549a19c5f66bf42c8': 7,
      '0x7a8ce23c361a6a93ad8f443a31b20a8617d1a59a': 8,
      '0x4552e884da00baceb30d86458460c24957e65c1f': 9,
      '0xf4b72e9a18e4b7c65165e437c57ff6b6202e4505': 10,
      '0x51880cee87bf2f5ffb1abc84e20889771b025d0a': 11,
      '0xf9c2356a21b60c0c4ddf2397f828dd158f82a274': 12,
      '0x502a88ffceb9363befd1b1c78265fc3ac8abd3a2': 13,
      '0x06228b709ed3c8344ae61e64b48204174d2e48b5': 14,
      '0x75eaa804518a66196946598317aed57ef86235fe': 15,
      '0xdddc78f940e012cfc1ad04de2ca089eb94900145': 16,
      '0x59d8dca660b71ba97a16f781a40809a0fc350769': 17,
      '0xb9e272ff4bfef5d18d83bc63b845e83e9df5612b': 18,
      '0xb1ad5c2573867e8157ded08d065fc761d606c3d9': 19,
      '0xef9187a5f03b988326a79ebd21569a2319df2486': 20,
      '0x6b7c9db425951543aa556b984869c50e6a18c7b2': 21,
      '0x2a20202a6f740200ba188f6d72fa72a08a346aaa': 22,
      '0xde509fe1555ab907e5c29f987ba0be1ac0626dae': 23,
      '0x4688300d46ef400c3506a165d5bdca6a51350978': 24,
      '0x2290281060f2ce6bb73dccf9b1735bc366f3f2c4': 25,
      '0x35c462b3396359cae6c5b40c24e0859b1f1bc6ac': 26,
      '0xeb7e2f8efac7ab8079837417b65cd927f05f7465': 27,
      '0x90971596f486521f496dc05faeb90837a5f19108': 28,
    },
  },
  [SupportedChainId.FUJI]: {},
  [SupportedChainId.ETHEREUM_MAINNET]: {},
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: {},
  [SupportedChainId.SCROLL_MAINNET]: {},
  [SupportedChainId.SKALE_TESTNET]: {},
  [SupportedChainId.OP_MAINNET]: {},
  [SupportedChainId.POLYGON_MAINNET]: {},
  [SupportedChainId.AVAX]: {},
  [SupportedChainId.BASE]: {},
  /** @todo unhide it when needed */
  // [SupportedChainId.ZKSYNC_MAINNET]: {},
  // [SupportedChainId.ZKSYNC_TESTNET]: {},
}

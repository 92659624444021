import React, { useState } from 'react'
import Image from 'next/image'
import arrowUp from '../../public/assets/icons/arrow-up.svg'
import warningIcon from '../../public/assets/icons/warning-icon-triangle.svg'
import clsx from 'clsx'

type Props = {
  message: React.ReactNode
  title: string
  severity?: 'warning'
}

function Accordion({ message, title, severity }: Props) {
  const [openExpand, setOpenExpand] = useState<boolean>(false)

  function getAccordionsIcon() {
    if (severity === 'warning') {
      return warningIcon
    }
    return warningIcon
  }

  return (
    <div className="mb-4 flex flex-col border-2 border-wombatPurple1 py-3 px-4">
      <div className="flex justify-between">
        <div className="flex items-center">
          <Image alt={''} src={getAccordionsIcon()} height={18} width={20} />
          <div className="ml-2 text-xs font-bold text-wombatPurple1 ">{title}</div>
        </div>
        <div
          style={{
            transform: `${!openExpand ? 'rotate(180deg)' : ''}`,
            cursor: 'pointer',
          }}
          className="flex justify-end transition-transform"
          onClick={() => setOpenExpand((prev) => !prev)}
        >
          <Image src={arrowUp} alt="" />
        </div>
      </div>
      <div className={clsx(openExpand ? 'max-h-40 transition-all' : 'max-h-0 overflow-hidden')}>
        <div className="mt-[14px] text-xs leading-4 text-wombatBrown1">{message}</div>
      </div>
    </div>
  )
}

export default Accordion

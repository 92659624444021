import Image from 'next/image'
import React, { useEffect, useMemo, useState } from 'react'
import useCountdown, { CountdownData } from '../../../hooks/useCountdown'
import CircularProgressBar from '../../CircularProgressBar'
import arrowUp from '../../../public/assets/icons/arrow-up.svg'
import userVoteIcon from '../../../public/assets/icons/user-vote-icon.svg'
import closeButtonIcon from '../../../public/assets/icons/button-close.svg'
import wombatWithAClock from '../../../public/assets/icons/wombat-with-clock.svg'
import useBreakpoints from '../../../hooks/useBreakpoints'
import clsx from 'clsx'
import { useScrollToTop } from '../../../hooks/useScroll'
import { useVotingData } from '../../../context/VotingDataContext'
import { getDpFormat, getMillifiedFormat } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import useNavbarSize from '../../../hooks/useNavbarSize'
import ScrollableBox from '../../ScrollableBox'
import AppTooltip from '../../Tooltip/AppTooltip'
import { useVewomData } from '../../../store/MulticallData/hooks'

function GaugeVotingHeaderInfo() {
  const { nextEpochStartTime } = useVotingData()
  const countdownData = useCountdown({
    targetTimestampInMilliseconds: nextEpochStartTime ? nextEpochStartTime * 1000 : 0,
  })
  const [isCountdownOpen, setIsCountdownOpen] = useState(false)
  const { isMd } = useBreakpoints()
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false)

  return (
    <div className="mb-[24px] flex w-[100%] flex-row items-center justify-between gap-4 px-[10px] font-Work">
      <div className="w-[100%] max-w-[230px] max-md:w-[unset]">
        <h2 className="font-Jamjuree text-3xl font-bold max-md:text-2xl">
          Gauge Voting
          <AppTooltip
            className="md:hidden"
            message="Earn bribes and boost your pool emissions with veWOM"
            fullWidth
          />
        </h2>
        <p className="mt-[2px] text-sm max-md:hidden">
          Earn bribes and boost your pool emissions with veWOM
        </p>
      </div>
      <div className="flex w-[100%] max-w-[500px] select-none flex-row flex-wrap items-center justify-around gap-y-1 max-md:w-[unset] max-md:flex-col max-md:items-end max-md:gap-0">
        <div
          className={clsx('flex flex-row items-center justify-center', isMd && 'cursor-pointer')}
          onClick={isMd ? () => setIsCountdownOpen((prev) => !prev) : undefined}
        >
          <p className="text-base font-semibold max-md:font-Jamjuree max-md:font-bold max-md:text-wombatPurple1">
            Epoch ends in<span className="max-md:hidden">:</span>
          </p>
          <div
            style={{ transform: `${!isCountdownOpen ? 'rotate(180deg)' : ''}` }}
            className=" ml-1 transition-transform md:hidden"
          >
            <Image src={arrowUp} width={12} height={12} />
          </div>
        </div>
        <div
          style={{ opacity: !isMd || (isMd && isCountdownOpen) ? '1' : '0' }}
          className="flex flex-row gap-3 transition-opacity max-md:absolute max-md:right-[10px] max-md:top-[60px] max-md:z-[5] max-md:flex-col"
        >
          {countdownData.map((item) => (
            <CircularProgressBar
              key={item.label}
              size={56}
              strokeWidth={9}
              color="linearGradient2"
              percentage={item.value}
              maxValue={item.maxValue}
              enableBackground={isMd}
            >
              <p className="text-center text-xs font-bold">
                {item.value}
                <br />
                <p className="font-normal">{item.label}</p>
              </p>
            </CircularProgressBar>
          ))}
          {isMd && <UserVoteInfoButton onClick={() => setIsUserInfoModalOpen(true)} />}
        </div>
      </div>
      {/* hidden on mobile */}
      <div className="flex w-[100%] max-w-[400px] flex-col gap-2 rounded-[16px] bg-wombatYellow3 px-[16px] py-[12px] font-Work shadow-brown3 max-md:hidden">
        <YourVeWomBalanceInfo />
        <RemainingVotesInfo />
      </div>
      {isUserInfoModalOpen && isMd && (
        <UserVoteInfoModal
          countdownData={countdownData}
          onClose={() => setIsUserInfoModalOpen(false)}
        />
      )}
    </div>
  )
}

export default GaugeVotingHeaderInfo

/**
 * Child Components
 */
type UserVoteInfoProps = {
  label: string
  value: React.ReactNode
  className?: string
}
function UserVoteInfo({ label, value, className }: UserVoteInfoProps) {
  return (
    <div className={clsx('flex flex-row flex-wrap items-center justify-between', className)}>
      <span className="text-sm">{label}</span>
      <span className="text-base font-semibold">{value}</span>
    </div>
  )
}

type UserVoteInfoButtonProps = {
  onClick: () => void
}
function UserVoteInfoButton({ onClick }: UserVoteInfoButtonProps) {
  return (
    <div
      onClick={onClick}
      className="flex h-[56px] w-[56px] cursor-pointer flex-col items-center justify-center rounded-full bg-wombatYellow3 shadow-brown3"
    >
      <Image src={userVoteIcon} width={40} height={40} />
    </div>
  )
}

function YourVeWomBalanceInfo({ className }: { className?: string }) {
  const vewomData = useVewomData()
  const vewomBalanceWad = useMemo(() => {
    return vewomData.withAccount?.vewomBalanceWad || null
  }, [vewomData.withAccount?.vewomBalanceWad])
  const { isMd } = useBreakpoints()
  /** @todo no veWom token to get dp */
  const veWomBalance = utils.formatUnits(vewomBalanceWad || '0', 18) || ''
  const hasVeWomBalance = vewomBalanceWad?.gt(0)
  const renderData = () => {
    return `${getMillifiedFormat(veWomBalance)} veWOM`
  }
  return (
    <UserVoteInfo
      label="Your veWOM Balance"
      value={
        (hasVeWomBalance &&
          (isMd ? (
            <>{renderData()}</>
          ) : (
            <AppTooltip message={`${getDpFormat(veWomBalance, 2)} veWOM`} fullWidth place="left">
              {renderData()}
            </AppTooltip>
          ))) ||
        '-'
      }
      className={className}
    />
  )
}
function RemainingVotesInfo({ className }: { className?: string }) {
  const vewomData = useVewomData()
  const vewomBalanceWad = useMemo(() => {
    return vewomData.withAccount?.vewomBalanceWad || null
  }, [vewomData.withAccount?.vewomBalanceWad])
  const {
    user: { remainingVote },
  } = useVotingData()
  const { isMd } = useBreakpoints()
  const hasVeWomBalance = vewomBalanceWad?.gt(0)

  const renderData = () => {
    return `${getMillifiedFormat(remainingVote.vewom)} veWOM (${getDpFormat(
      remainingVote.percentage,
      2
    )}%)`
  }
  return (
    <UserVoteInfo
      label="Remaining Votes:"
      value={
        (hasVeWomBalance &&
          (isMd ? (
            <>{renderData()}</>
          ) : (
            <AppTooltip
              message={`${getDpFormat(remainingVote.vewom, 2)} veWOM`}
              fullWidth
              place="left"
            >
              {renderData()}
            </AppTooltip>
          ))) ||
        '-'
      }
      className={className}
    />
  )
}

type UserVoteInfoModalProps = {
  onClose: () => void
  countdownData: CountdownData
}
function UserVoteInfoModal({ onClose, countdownData }: UserVoteInfoModalProps) {
  const { scrollToTop } = useScrollToTop()
  const {
    desktop: { height: navbarHeight },
    mobile: { height: mobileNavbarHeight },
  } = useNavbarSize()
  useEffect(() => {
    scrollToTop()
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'initial'
    }
    // only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div
      className="absolute top-0 left-0 z-20 w-[100%]"
      style={{
        background:
          'linear-gradient(0deg, rgba(255, 252, 238, 0.3), rgba(255, 252, 238, 0.3)), linear-gradient(117.54deg, rgba(255, 255, 255, 0.4) -19.85%, rgba(235, 235, 235, 0.293883) 4.2%, rgba(224, 224, 224, 0.230004) 13.88%, rgba(212, 212, 212, 0.169048) 27.98%, rgba(207, 207, 207, 0.140467) 37.8%, rgba(202, 202, 202, 0.114745) 44.38%, rgba(200, 200, 200, 0.10104) 50.54%, rgba(196, 196, 196, 0.08) 60.21%)',
        backdropFilter: 'blur(5px)',
        padding: '8px 8px 31px 8px',
        height: `calc(100vh - ${navbarHeight}px - ${mobileNavbarHeight}px)`,
      }}
    >
      <div
        style={{ background: 'rgba(255, 243, 203, 0.9)', overflow: 'hidden' }}
        className="relative h-[100%] w-[100%] rounded-[16px] bg-wombatYellow3 p-[16px] shadow-brown3"
      >
        <div className="relative cursor-pointer text-right" onClick={onClose}>
          <Image src={closeButtonIcon} width={12} height={12} />
        </div>
        <ScrollableBox className="flex h-[100%] flex-col items-center justify-center gap-4 py-[16px]">
          <div>
            <Image src={wombatWithAClock} width={66} height={95} />
          </div>
          <p className="font-Jamjuree text-base font-bold text-wombatPurple1">Epoch ends in</p>
          <div className="relative flex w-[100%] flex-row justify-center gap-3">
            {countdownData.map((item) => (
              <CircularProgressBar
                key={item.label}
                size={70}
                strokeWidth={11}
                color="linearGradient2"
                percentage={item.value}
                maxValue={item.maxValue}
              >
                <p className="text-center text-xs font-bold">
                  {item.value}
                  <br />
                  <p className="font-normal">{item.label}</p>
                </p>
              </CircularProgressBar>
            ))}
          </div>
          <hr
            className="bg-[rgba(183, 163, 142, 1)] my-6
h-0.5 w-[80%]"
          />
          <div>
            <Image src={userVoteIcon} width={60} height={60} />
          </div>
          <YourVeWomBalanceInfo className=" flex-col" />
          <RemainingVotesInfo className=" flex-col" />
        </ScrollableBox>
      </div>
    </div>
  )
}

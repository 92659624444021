import { replaceString } from '../../utils/numberFormat'
import { decimalAndInteger } from '../../utils/regex'
import styles from '../SettingButton/settingBtn.module.css'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'

type Props = {
  checked: boolean
  placeHolder: string
  onClick: () => void
  onAmountChanged: (value: string) => void
  inputForDisplay: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
  leftIcon?: React.ReactElement
  onMaxOnClick?: () => void
  inputTokenSymbol?: TokenSymbol
  inputWidth?: string
  warningMsg?: string
}

export default function SettingInput({
  onClick,
  checked,
  inputForDisplay,
  onAmountChanged,
  placeHolder,
  children,
  leftIcon,
  onMaxOnClick,
  inputTokenSymbol,
  inputWidth,
  warningMsg,
}: Props) {
  return (
    <div className="form-check form-check-inline ">
      <label className={`${styles.rd} items-center text-xs`}>
        {leftIcon && leftIcon}
        <input
          className="mr-2 h-4 w-4 rounded border border-wombatBrown2 focus:ring-opacity-25"
          type="radio"
          onClick={onClick}
          checked={checked}
        />
        <span className={`${styles.checkmark} top-[25%]`}></span>
        <div className="flex flex-col">
          <div
            className={`flex items-center justify-between rounded border  bg-white px-2 ${
              inputWidth ? inputWidth : 'w-[60px]'
            } ${warningMsg ? 'border-wombatRed' : 'border-black'}`}
          >
            <input
              inputMode="numeric"
              className={`mx-1 h-[32px] w-full py-0.5 px-1 text-left font-Helvetica focus:outline-none ${
                warningMsg ? 'text-wombatRed' : ''
              }`}
              type="text"
              autoCorrect="false"
              autoCapitalize="false"
              onFocus={onClick}
              placeholder={placeHolder}
              value={inputForDisplay}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  onAmountChanged('')
                } else {
                  const replacedAmount = replaceString(e.target.value, ',', '.')
                  if (decimalAndInteger.test(replacedAmount)) {
                    onAmountChanged(replacedAmount)
                  }
                }
              }}
            />
            <div className="flex gap-1">
              {onMaxOnClick && (
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    onMaxOnClick()
                  }}
                  className="button-hover-opacity rounded border-1 border-wombatPurple1 px-2 font-semibold text-wombatPurple1"
                >
                  MAX
                </div>
              )}
              <div>{inputTokenSymbol && inputTokenSymbol}</div>
            </div>
          </div>
          {warningMsg && <div className="text-wombatRed">{warningMsg}</div>}
        </div>
        <span className={checked ? 'font-medium' : ''}>{children}</span>
      </label>
    </div>
  )
}

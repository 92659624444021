import React from 'react'
import TabMenu from './TabMenu'
import { useSelfServiceBribePageContext } from '../../context/SelfServiceBribePageContext'

function Header() {
  const { isToShowBribeData } = useSelfServiceBribePageContext()
  return (
    <div className="flex flex-row justify-between">
      <div>
        <h1 className="text-[40px]">{isToShowBribeData ? 'Bribe' : 'Rewarder'}</h1>
        <p className="mb-5 text-sm">
          {isToShowBribeData
            ? 'Add a bribe reward for an existing gauge to incentivize votes on it'
            : 'Add a rewarder reward for an exiting pool to incentivize liquidity provision on it'}
        </p>
      </div>
      <TabMenu />
    </div>
  )
}

export default Header

import { TokenSymbols } from '../../constants/contract/token/TokenSymbols'
import { commafyPercentage } from '../../utils/commafy'
import { TokenInfo } from '../PoolPage'
import TokenImage from '../TokenImage'
import Tooltip from '../Tooltip'
import AprTooltipItem from './AprTooltipItem'

export const MedianTotalAprTooltip = ({
  isMobile = false,
  tokenInfo,
}: {
  isMobile?: boolean
  DISCONNECT: boolean
  hasStaked: boolean | null
  tokenInfo: TokenInfo
  depositedOrStaked: boolean | null
}) => {
  const medianTotalAprUi = commafyPercentage(tokenInfo.medianTotalApr ?? 0)
  const baseAPR_UI = tokenInfo.baseAPR ? commafyPercentage(tokenInfo.baseAPR) : '-'

  const medianBoostedAprUi = tokenInfo.medianBoostedApr
    ? commafyPercentage(Number(tokenInfo.medianBoostedApr))
    : '-'
  const avgDailyFeeSharingAprUi = tokenInfo.avgFeeSharingApr.daily
    ? commafyPercentage(Number(tokenInfo.avgFeeSharingApr.daily) / 100)
    : '-'
  const interestBearingAprUi = tokenInfo.interestBearingApr
    ? commafyPercentage(Number(tokenInfo.interestBearingApr))
    : '-'
  const hasNonZeroBonusTokenAprs =
    tokenInfo.bonusTokens &&
    tokenInfo.bonusTokens.length > 0 &&
    tokenInfo.bonusTokens.some((rewardInfo) => !rewardInfo.apr.isZero())

  const hasNonZeroBaseAprFromBoostedRewarder = tokenInfo.boostedRewarderRewardData.some(
    (rewardInfo) => +rewardInfo.baseApr > 0
  )
  const hasNonZeroAvgAprFromBoostedRewarder = tokenInfo.boostedRewarderRewardData.some(
    (rewardInfo) => +rewardInfo.averageApr > 0
  )
  return (
    <Tooltip className="absolute inset-0" hideTooltipImage isTextPositionLeftOnMobile={isMobile}>
      <div className="font-semibold">
        <span className="float-left">Average Total APR</span>
        <span className="float-right">{medianTotalAprUi}</span>
        <div className="clear-both"></div>
      </div>
      {(tokenInfo.isBaseAprMoreThanZero ||
        hasNonZeroBonusTokenAprs ||
        hasNonZeroBaseAprFromBoostedRewarder) && (
        <>
          <br />
          <p className="opacity-80">Base APR</p>
          {tokenInfo.isBaseAprMoreThanZero && (
            <div className="flex justify-between">
              <div className="flex">
                <TokenImage tokenSymbol={TokenSymbols.WOM} />
                <div className="ml-1">WOM</div>
              </div>
              <span>{baseAPR_UI}</span>
            </div>
          )}
          {tokenInfo.bonusTokens?.map(
            (rewardInfo) =>
              // only show non-zero apr
              Number(rewardInfo.apr) !== 0 && (
                <div className="flex justify-between" key={rewardInfo.displaySymbol}>
                  <div className="flex">
                    <TokenImage tokenSymbol={rewardInfo.symbol} />
                    <div className="ml-1">{rewardInfo.displaySymbol}</div>
                  </div>
                  <span>{commafyPercentage(Number(rewardInfo.apr) / 1e18)}</span>
                </div>
              )
          )}
          {tokenInfo.boostedRewarderRewardData?.map((rewardInfo) => {
            return (
              <AprTooltipItem
                key={rewardInfo.displaySymbol}
                displaySymbol={rewardInfo.displaySymbol}
                rewardSymbol={rewardInfo.symbol}
                apr={rewardInfo.baseApr}
              />
            )
          })}
        </>
      )}
      {/** Median Boosted APR supported wom only, baseApr means womApr.  */}
      {(tokenInfo.isBaseAprMoreThanZero || hasNonZeroAvgAprFromBoostedRewarder) && (
        <>
          <br />
          <p className="opacity-80">Average Boosted APR</p>
          {tokenInfo.isBaseAprMoreThanZero && (
            <div className="flex justify-between">
              <div className="flex">
                <TokenImage tokenSymbol={TokenSymbols.WOM} />
                <div className="ml-1">WOM</div>
              </div>
              <span>{medianBoostedAprUi}</span>
            </div>
          )}
          {tokenInfo.boostedRewarderRewardData?.map((rewardInfo) => {
            return (
              <AprTooltipItem
                key={rewardInfo.displaySymbol}
                displaySymbol={rewardInfo.displaySymbol}
                rewardSymbol={rewardInfo.symbol}
                apr={rewardInfo.averageApr}
              />
            )
          })}
        </>
      )}

      {/** Avg Daily Fee Sharing APR  */}
      {tokenInfo.avgFeeSharingApr.daily && (
        <>
          <br />
          <p className="opacity-80">Daily Fee Sharing APR</p>
          <div className="flex justify-between">
            <div className="flex">
              <TokenImage tokenSymbol={tokenInfo.symbol} />
              <div className="ml-1">{tokenInfo.displaySymbol}</div>
            </div>
            <span>{avgDailyFeeSharingAprUi}</span>
          </div>
        </>
      )}
      {/** Interest Bearing Sharing APR  */}
      {tokenInfo.interestBearingApr && (
        <>
          <br />
          <p className="opacity-80">Interest-bearing APR</p>
          <div className="flex justify-between">
            <div className="flex">
              <TokenImage tokenSymbol={tokenInfo.symbol} />
              <div className="ml-1">{tokenInfo.displaySymbol}</div>
            </div>
            <span>{interestBearingAprUi}</span>
          </div>
        </>
      )}
      {/**@todo open weekly avg sharing apr */}
      {/* <br /> */}
      {/** Avg Weekly Fee Sharing APR  */}
      {/* {tokenInfo.avgFeeSharingApr.weekly && (
          <>
            <p className="opacity-80">Weekly Fee Sharing APR</p>
            <div className="flex justify-between">
              <div className="flex">
                <TokenImage tokenSymbol={tokenInfo.symbol} />
                <div className="ml-1">{tokenInfo.symbol}</div>
              </div>
              <span>{avgWeeklyFeeSharingAprUi}</span>
            </div>
          </>
        )} */}
    </Tooltip>
  )
}

import { SupportedChainId } from '../web3/supportedChainId'
import { HexString } from '../../interfaces/contract'
import { IContractCalls } from '../../utils/executeCallBacks'
import { utils } from 'ethers'
import * as multicall from 'ethcall'
export class Contract<T> {
  readonly chainId: SupportedChainId
  readonly address: HexString
  readonly abi: T
  constructor({
    chainId,
    address,
    abi,
  }: {
    chainId: SupportedChainId
    address: HexString
    abi: T
  }) {
    this.chainId = chainId
    this.address = address
    this.abi = abi
  }

  get() {
    return {
      address: this.address,
      abi: this.abi,
    }
  }
  multicall(functionName: string, args?: unknown[]) {
    return {
      ...this.get(),
      functionName,
      args,
    } as IContractCalls[0]
  }
  getMultiCall(): multicall.Contract | null {
    return new multicall.Contract(this.address, this.getFunctionOnlyAbi())
  }
  // ethcall requires abi with function type only
  getFunctionOnlyAbi(): utils.Fragment[] {
    return (this.abi as utils.Fragment[]).filter((item) => item.type === 'function')
  }
}

// import {RootState} from "../store";
//
// export const selectCashes = (state: RootState) => state.asset.cashes;
// export const selectLiabilities = (state: RootState) => state.asset.liabilities;
// export const selectTotalSupplies = (state: RootState) => state.asset.totalSupplies;
// export const selectLpTokenToTokenRates = (state: RootState) => state.asset.lpTokenToTokenRates;
// export const selectMaxSupply = (state: RootState) => state.asset.maxSupply;
// export const selectDeposits = (state: RootState) => state.asset.deposits;
// export const selectLoadingCashes = (state: RootState) => state.asset.loadingCashes;

import { createSelector } from 'reselect'
import { selectAssetData } from '../MulticallData/selectors'
import { getInitialAssetProperty } from './helpers'
import { BigNumber } from 'ethers'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { safeWdiv } from '@hailstonelabs/big-number-utils'

export const selectCashesData = createSelector([selectAssetData], (assetData) => {
  const lpTokenToTokenRatesBn = getInitialAssetProperty<BigNumber>()
  Object.entries(assetData.withoutAccount?.liabilitiesBn || {}).forEach(
    ([poolLabelStr, poolData]) => {
      Object.entries(poolData).forEach(([tokenSymbolStr, liabilityBn]) => {
        const poolLabel = poolLabelStr as PoolLabels
        const tokenSymbol = tokenSymbolStr as TokenSymbol
        const totalSupplyBn = assetData.withoutAccount?.totalSuppliesBn[poolLabel][tokenSymbol]
        if (totalSupplyBn) {
          lpTokenToTokenRatesBn[poolLabel][tokenSymbol] = safeWdiv(liabilityBn, totalSupplyBn)
        }
      })
    }
  )

  return {
    cashes: assetData?.withoutAccount?.cashesBn || getInitialAssetProperty<BigNumber>(),
    liabilities: assetData.withoutAccount?.liabilitiesBn || getInitialAssetProperty<BigNumber>(),
    totalSupplies:
      assetData.withoutAccount?.totalSuppliesBn || getInitialAssetProperty<BigNumber>(),
    lpTokenToTokenRates: lpTokenToTokenRatesBn,
    maxSupply: assetData.withoutAccount?.maxSuppliesBn || getInitialAssetProperty<BigNumber>(),
    deposits:
      assetData.withAccount?.myLpBalancesInTermsOfLpBn || getInitialAssetProperty<BigNumber>(),
  }
})

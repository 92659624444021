import { commafyPercentage } from '../../utils/commafy'
import Tooltip from '../Tooltip'
import { TokenInfo } from '../PoolPage'
import TokenImage from '../TokenImage'
import { TokenSymbols } from '../../constants/contract/token/TokenSymbols'
import AprTooltipItem from './AprTooltipItem'

export const MyTotalAprTooltip = ({
  isMobile,
  DISCONNECT,
  hasStaked,
  tokenInfo,
  depositedOrStaked,
}: {
  isMobile?: boolean
  DISCONNECT: boolean
  hasStaked: boolean | null
  tokenInfo: TokenInfo
  depositedOrStaked: boolean | null
}) => {
  const avgDailyFeeSharingAprUi = tokenInfo.avgFeeSharingApr.daily
    ? commafyPercentage(Number(tokenInfo.avgFeeSharingApr.daily) / 100)
    : '-'
  const interstBearingAprUi = tokenInfo.interestBearingApr
    ? commafyPercentage(Number(tokenInfo.interestBearingApr))
    : '-'

  const boostAPR_UI = tokenInfo.boostAPR !== null ? commafyPercentage(tokenInfo.boostAPR) : '-'

  const baseAPR_UI = tokenInfo.baseAPR ? commafyPercentage(tokenInfo.baseAPR) : '-'

  function getTotalAprUi() {
    // if user is staked, show my total apr
    if (tokenInfo.myTotalApr && hasStaked && !DISCONNECT)
      return commafyPercentage(tokenInfo.myTotalApr)
    // if user is deposited or staked, show avg fee sharing apr only
    if (tokenInfo.avgFeeSharingApr && depositedOrStaked) return avgDailyFeeSharingAprUi
    return '-'
  }

  return (
    <Tooltip className="absolute inset-0" isTextPositionLeftOnMobile={isMobile} hideTooltipImage>
      <div className="font-semibold">
        <span className="float-left">My Total APR</span>
        <span className="float-right">{getTotalAprUi()}</span>
        <div className="clear-both"></div>
      </div>
      {hasStaked &&
        !DISCONNECT &&
        (tokenInfo.isBaseAprMoreThanZero ||
          (tokenInfo.bonusTokens && tokenInfo.bonusTokens.length > 0) ||
          tokenInfo.boostedRewarderRewardData.length > 0) && (
          <>
            <br />
            <p className="opacity-80">My Base APR</p>
            {tokenInfo.isBaseAprMoreThanZero && (
              <div className="flex justify-between">
                <div className="flex">
                  <TokenImage tokenSymbol={TokenSymbols.WOM} />
                  <div className="ml-1">WOM</div>
                </div>
                <span>{baseAPR_UI}</span>
              </div>
            )}
            {tokenInfo.bonusTokens?.map(
              (rewardInfo) =>
                Number(rewardInfo.apr) !== 0 && (
                  <div className="flex justify-between" key={rewardInfo.displaySymbol}>
                    <div className="flex">
                      <TokenImage tokenSymbol={rewardInfo.symbol} />
                      <div className="ml-1">{rewardInfo.displaySymbol}</div>
                    </div>
                    <span>{commafyPercentage(Number(rewardInfo.apr) / 1e18)}</span>
                  </div>
                )
            )}
            {tokenInfo.boostedRewarderRewardData.map((rewardInfo) => {
              return (
                <AprTooltipItem
                  key={rewardInfo.displaySymbol}
                  displaySymbol={rewardInfo.displaySymbol}
                  rewardSymbol={rewardInfo.symbol}
                  apr={rewardInfo.baseApr}
                />
              )
            })}
          </>
        )}
      {hasStaked &&
        !DISCONNECT &&
        (tokenInfo.boostedRewarderRewardData.length > 0 || tokenInfo.isBaseAprMoreThanZero) && (
          <>
            <br />
            <p className="opacity-80">My Boosted APR</p>
            {tokenInfo.isBaseAprMoreThanZero && (
              <div className="flex justify-between">
                <div className="flex">
                  <TokenImage tokenSymbol={TokenSymbols.WOM} />
                  <div className="ml-1">WOM</div>
                </div>
                <span>{boostAPR_UI}</span>
              </div>
            )}
            {tokenInfo.boostedRewarderRewardData.map((rewardInfo) => {
              return (
                <AprTooltipItem
                  key={rewardInfo.displaySymbol}
                  displaySymbol={rewardInfo.displaySymbol}
                  rewardSymbol={rewardInfo.symbol}
                  apr={rewardInfo.boostedApr}
                />
              )
            })}
          </>
        )}
      {/** Avg Daily Fee Sharing APR  */}
      {depositedOrStaked && tokenInfo.avgFeeSharingApr.daily && (
        <>
          <br />
          <p className="opacity-80">My Daily Fee Sharing APR</p>
          <div className="flex justify-between">
            <div className="flex">
              <TokenImage tokenSymbol={tokenInfo.symbol} />
              <div className="ml-1">{tokenInfo.displaySymbol}</div>
            </div>
            <span>{avgDailyFeeSharingAprUi}</span>
          </div>
        </>
      )}
      {/** Interest-bearing APR  */}
      {depositedOrStaked && tokenInfo.interestBearingApr && (
        <>
          <br />
          <p className="opacity-80">Interest-bearing APR</p>
          <div className="flex justify-between">
            <div className="flex">
              <TokenImage tokenSymbol={tokenInfo.symbol} />
              <div className="ml-1">{tokenInfo.displaySymbol}</div>
            </div>
            <span>{interstBearingAprUi}</span>
          </div>
        </>
      )}
      {/**@todo open weekly avg sharing apr */}
      {/* <br /> */}
      {/** Avg Weekly Fee Sharing APR  */}
      {/* {tokenInfo.avgFeeSharingApr.weekly && (
          <>
            <p className="opacity-80">My Weekly Fee Sharing APR</p>
            <div className="flex justify-between">
              <div className="flex">
                <TokenImage tokenSymbol={tokenInfo.symbol} />
                <div className="ml-1">{tokenInfo.symbol}</div>
              </div>
              <span>{avgWeeklyFeeSharingAprUi}</span>
            </div>
          </>
        )} */}
    </Tooltip>
  )
}

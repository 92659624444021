import { createAction } from '@reduxjs/toolkit'
import { ToastContainerProps } from '../../components/Toast/ToastContainer'
import { Truncate } from '../../components/Toast/ToastMessage'

export type ToastProviderProps = {
  children: React.ReactNode
} & ToastContainerProps

type TostMessageType = 'Info' | 'Success' | 'Warning' | 'Error'

export type Toast = {
  id: string
  lifetime: number
  message: string | React.ReactNode
  title: string
  txHash: string
  type?: TostMessageType
  truncate?: Truncate
  icon?: string
  header?: string
  childrenButton?: string
  handleClickButton?: () => void
}

export const addToast = createAction<{
  message: string | React.ReactNode
  title?: string
  txHash?: string
  type: TostMessageType
  lifetime?: number
  truncate?: Truncate
  childrenButton?: string
  handleClickButton?: () => void
}>('application/addToast')

export const addErrorToast = createAction<{
  message: string | React.ReactNode
  title?: string
  txHash?: string
  lifetime?: number
  truncate?: Truncate
}>('application/addErrorToast')

export const addWarningToast = createAction<{
  message: string | React.ReactNode
  title?: string
  txHash?: string
  lifetime?: number
  truncate?: Truncate
}>('application/addWarningToast')

export const addSuccessToast = createAction<{
  message: string | React.ReactNode
  title?: string
  txHash?: string
  childrenButton?: string
  handleClickButton?: () => void
  lifetime?: number
  truncate?: Truncate
}>('application/addSuccessToast')

export const addInfoToast = createAction<{
  message: string | React.ReactNode
  title?: string
  txHash?: string
  lifetime?: number
  truncate?: Truncate
}>('application/addInfoToast')

export const removeToast = createAction<{ id: string }>('application/removeToast')

import React, { forwardRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import calendarBrownIcon from '../../public/assets/icons/calendar-brown.svg'
import styles from './index.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import Image from 'next/image'
import clsx from 'clsx'

export type DatetimeRangePickerProps = {
  startDate: Date | null
  endDate: Date | null
  onChange: (startDate: Date | null, endDate: Date | null) => void
  disabled?: boolean
  showEndTimeSelect?: boolean
  validation?: {
    isValid: boolean
    warningMessage: string
  }
}
function DatetimeRangePicker({
  startDate,
  endDate,
  onChange,
  validation,
  disabled,
  showEndTimeSelect,
}: DatetimeRangePickerProps) {
  const hasInvalidInputValue = validation && !validation.isValid

  useEffect(() => {
    onChange(startDate, endDate)
  }, [startDate, endDate, onChange])
  return (
    <>
      <CustomDatetimePicker
        selected={startDate}
        onChange={(date) => onChange(date, endDate)}
        minDate={new Date()}
        startDate={startDate}
        endDate={endDate}
        customInput={<CustomInput label="Start date" />}
        disabled={disabled}
      />
      <br />
      <CustomDatetimePicker
        selected={endDate}
        onChange={(date) => onChange(startDate, date)}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        customInput={<CustomInput label="End date" />}
        showTimeSelect={showEndTimeSelect}
      />

      {hasInvalidInputValue && (
        <p className="mt-1 text-xs text-red-600">{validation.warningMessage}</p>
      )}
    </>
  )
}

export default DatetimeRangePicker

type CustomInputProps = { label: string } & React.ButtonHTMLAttributes<HTMLButtonElement>
const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(
  ({ value, className, label, ...otherProps }: CustomInputProps, ref) => {
    return (
      <div className="relative w-full font-Work text-sm font-medium text-wombatBrown">
        <label>{label}</label>
        <div className="relative mt-2 w-full rounded-md border-1 p-2">
          <button className={clsx('w-full', className)} {...otherProps} ref={ref}>
            <div className="flex w-full flex-row justify-between gap-4">
              {value}
              <Image src={calendarBrownIcon} width={15} height={15} />
            </div>
          </button>
        </div>
      </div>
    )
  }
)

CustomInput.displayName = 'CustomInput'

type CustomDatetimePickerProps = {
  startDate: Date | null
  endDate: Date | null
  selected: Date | null
  minDate: Date | null
  onChange: (date: Date | null) => void
  disabled?: boolean
  customInput: React.ReactNode
  showTimeSelect?: boolean
}
const CustomDatetimePicker = ({
  startDate,
  endDate,
  selected,
  minDate,
  disabled,
  onChange,
  customInput,
  showTimeSelect = true,
}: CustomDatetimePickerProps) => {
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date()
    const selectedDate = new Date(time)

    return currentDate.getTime() < selectedDate.getTime()
  }
  return (
    <DatePicker
      dateFormat="dd MMM, yyyy hh:mm aa zzz"
      selected={selected}
      onChange={onChange}
      selectsStart
      startDate={startDate}
      endDate={endDate}
      minDate={minDate}
      showTimeSelect={showTimeSelect}
      customInput={customInput}
      showPopperArrow={false}
      timeFormat="hh:mm aa"
      popperClassName={styles.popper}
      timeIntervals={60}
      disabled={disabled}
      filterTime={filterPassedTime}
    />
  )
}

import React from 'react'
import styles from './index.module.scss'
import useBreakpoints from '../../hooks/useBreakpoints'
import { Link } from 'react-router-dom'

export const TncPage = () => {
  const { isMd } = useBreakpoints()

  return (
    <div className={`${isMd ? 'mx-10 mb-20' : 'mx-20'} font-Jamjuree ${styles.tncPage} `}>
      <h1 className="mb-4 text-3xl">
        <b>Terms and Conditions</b>
      </h1>
      <main>
        <div>
          <p>
            These Terms and Conditions (these “Terms”) set out the basis on which you may access and
            use{' '}
            <Link to="/" className="font-bold text-wombatPurple">
              Wombat Exchange
            </Link>{' '}
            (the “Site”). The Site is a website hosted user interface provided by Wombat Exchange
            (“Wombat”, “we” or “us”) and the Site enables users to interact with certain smart
            contracts deployed on the blockchain to swap certain digital assets (the “Protocol”).
            You must read and understand these Terms in its entirety before accessing and using the
            Site. Each time you access or use the Site, you indicate your acceptance of these Terms.
            If you do not accept these Terms, you are not permitted to use the Site and must cease
            all activity on the Site.
          </p>
          <p>
            THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION AND CERTAIN WAIVERS. YOU SHOULD READ
            THESE PROVISIONS CAREFULLY AND UNDERSTAND HOW YOUR RIGHTS ARE AFFECTED BEFORE ACCEPTING
            THESE TERMS.
          </p>
          <h2>1. Nature of the Site</h2>
          <p>
            THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION AND CERTAIN WAIVERS. YOU SHOULD READ
            THESE PROVISIONS CAREFULLY AND UNDERSTAND HOW YOUR RIGHTS ARE AFFECTED BEFORE ACCEPTING
            THESE TERMS.
          </p>
          <p>
            By using the Site, you understand that you are not buying or selling crypto assets from
            us and that we do not control trade execution on the Protocol. When users pay fees for
            trades, those fees accrue to liquidity providers for the Protocol. Please note that when
            using the Protocol, crypto assets that have been &quot;bridged&quot; or
            &quot;wrapped&quot; to operate on other blockchain networks (including to blockchains
            compatible with the Ethereum Virtual Machine that are designed to ensure a blockchain
            can effectively process more transactions or other blockchains that are frequently
            referred to as &quot;Layer 2&quot; solutions) are distinct from the asset on its native
            chain.
          </p>
          <h2>2. Permitted Use of the Site</h2>
          <p>
            You agree to use the Site only for lawful purposes and in a manner consistent with all
            applicable laws and regulations and in the manner intended by us. By accessing or using
            the Site, you agree that you are solely and entirely responsible for compliance with all
            laws and regulations that may apply to you, as well as any tax obligations.
          </p>
          <span>You shall not use the Site:</span>
          <ul>
            <li>
              to exploit any vulnerability of the Site, whether or not for financial or other
              personal gain or otherwise probe, scan, or test the vulnerability of the Site. You
              must not breach the security or authentication measures implemented on the Site;
            </li>{' '}
            <li>
              to attempt to gain unauthorized access to the Site, its servers, or any other systems
              or networks connected to the Site. This includes hacking, password &quot;mining&quot;,
              or any other illegitimate mean;
            </li>{' '}
            <li>
              to introduce any malware, harmful material or otherwise unauthorized material to the
              Site or other users of the Site or use any automated device, program, or process
              (including but not limited to deep-linking, page-scraping, robots, spiders, or similar
              methods) to access, copy, monitor, or extract any part of the Site without prior
              written permission from us;
            </li>{' '}
            <li>
              in connection with any cyber attacks, whether against the Site or any other website;
            </li>{' '}
            <li>
              in a manner which involves market manipulation or financial crime or in a manner which
              would make the Site an accessory to any market manipulation or financial crime;
            </li>{' '}
            <li>
              to engage in any activity that is, or is intended to be, libelous, defamatory,
              profane, obscene, indecent, hateful, abusive, fraudulent or deceptive;
            </li>{' '}
            <li>
              to engage in any activity that seeks to reveal or obtain personal identification or
              information about other users or visitors to the Site, or any other customers of ours.
              This includes reverse look-up, tracing, or any other means to uncover such
              information; or
            </li>{' '}
            <li>
              for any activity that is otherwise prohibited by law or infringes upon the rights of
              others.
            </li>{' '}
          </ul>
          <p>
            You agree not to take any action that imposes an unreasonable or disproportionately
            large load on the infrastructure of the Site. In particular, you shall not use any
            device, software, or routine that interferes or attempts to interfere with the proper
            functioning of the Site, or any transaction being conducted on the Site. Additionally,
            you must not interfere with or disrupt any other user’s use of the Site.
          </p>
          <h2>3. Wallet Security</h2>
          <p>
            To access and use the Site, you must use a non-custodial wallet software (your “Wallet”
            and all the non-custodial wallet software you use, your “Wallets”), which allows you to
            interact with public blockchains. Your relationship with that non-custodial wallet
            provider is governed by the applicable terms of service with the third-party. We do not
            have custody or control over the contents of your wallet and have no ability to retrieve
            or transfer its contents.
          </p>{' '}
          <p>
            The security of your Wallets is your sole responsibility. You are required to take
            appropriate measures to ensure the security and confidentiality of your Wallet,
            passwords, and any other authentication information. You shall not share your Wallets,
            or any information related to your Wallets (including your seed phrases or private keys)
            with any other individual or entity.
          </p>{' '}
          <p>
            We strongly recommend that you adopt robust operational security measures to protect
            your Wallets, including but not limited to installing the latest software upgrades on
            your mobile devices, using two-factor authentication as appropriate, and regularly
            update your security settings. It is your responsibility to keep your Wallets secure.
            You understand and acknowledge that any activity conducted through your Wallets will be
            deemed as your own actions. You are solely responsible for any consequences arising from
            unauthorized use or access to your Wallets, whether through your own actions or due to
            your failure to implement adequate security measures. In the event of any suspected or
            actual unauthorized access to your Wallets, or if you believe your Wallet has been
            compromised, you must immediately take appropriate action to mitigate any potential
            risks.
          </p>{' '}
          <p>
            We have no ability to control or reverse any transactions on the Protocol. We shall not
            be held responsible for any loss, damage, or unauthorized transactions resulting from
            any unauthorized access of your Wallet. By using the Site, you agree to assume full
            responsibility for the security of your Wallets, and you acknowledge that we do not
            condone the sharing of your Wallets (or the associated seed phrase and/or private key).
          </p>
          <h2>4. Intellectual Property</h2>
          <p>
            We own all intellectual property and other rights in the Site and its contents,
            including, but not limited to, software, text, images, trademarks, service marks,
            copyrights, patents, designs, and its &quot;look and feel&quot;. Subject to your
            compliance with these Terms and any other terms agreed with us separately, we grant you
            a limited, revocable, non-transferable, and non-exclusive license to access and use the
            Site for personal purposes only. This license does not grant you any ownership or rights
            to our intellectual property. All intellectual property and any other proprietary rights
            associated with the Site and its content are and shall remain our sole property. These
            Terms do not transfer any ownership or intellectual property rights to you. You shall
            not claim or assert any right, title, or interest in or to our intellectual property by
            virtue of your access and use of the Site.
          </p>{' '}
          <p>
            You shall not modify, reproduce, distribute, display, perform, publish, create
            derivative works from, or exploit in any way our intellectual property without our prior
            written permission. We reserve the right to take legal action, including seeking damages
            and injunctive relief, against any individual or entity that violates our intellectual
            property rights or uses the Site in a manner that infringes upon our intellectual
            property.
          </p>{' '}
          <p>
            No licenses or rights are granted to you by implication or estoppel with respect to our
            intellectual property or the intellectual property of any third party.
          </p>
          <h2>5. Representations and Warranties</h2>
          <p>
            On each instance that you access and use the Site, you represent and warrant to us that:
          </p>
          <p>
            <ul>
              {' '}
              <li>
                it is lawful under the laws applicable to you to access and use the Site;
              </li>{' '}
              <li>
                you are not accessing the Site from a country that is sanctioned by any governmental
                or supranational body, including but not limited to the Office of Foreign Assets
                Control of the United States Department of the Treasury, the United Nations Security
                Council, the European Union, any European Union member state and that you are not an
                individual subject to any sanctions by a governmental or supranational body;
              </li>{' '}
              <li>
                you are not a citizen, resident of, or a legal entity incorporated or organized
                under the laws of, the United States, Russia, Cuba, Iran, North Korea and Crimea;
              </li>{' '}
              <li>you understand and accept all risks of using the Site and crypto tokens;</li>{' '}
              <li>
                you have full understanding of the nature and risks of digital assets and blockchain
                networks, and voluntarily and of your own accord;
              </li>{' '}
              <li>
                you have knowledge and experience in all relevant matters as to be capable of
                evaluating the merits and risks of accessing and using the Site and conducting
                transactions using the Protocol;
              </li>{' '}
              <li>
                you have full authority and power to enter into these Terms and that your entry into
                these Terms does not conflict with any other legal obligation (whether under
                statute, contract, tort or otherwise) that is binding on you; and
              </li>{' '}
              <li>
                your acceptance of these Terms are legal, valid, binding and enforceable obligations
                of yours.
              </li>{' '}
            </ul>
          </p>
          <h2>6. Third-Party Websites</h2>
          <p>
            The Site may contain links or references to third-party websites that are not owned or
            controlled by us. These links are provided for your convenience and informational
            purposes only. We do not endorse, guarantee, or assume any responsibility for the
            accuracy, reliability, or legality of the content on these third-party websites. By
            accessing and using any third-party websites linked from the Site, you acknowledge and
            agree that you do so at your own risk. We shall not be held liable for any damages or
            losses arising from your use of or reliance on the content, products, or services
            offered by these third-party websites. We recommend that you review the terms and
            conditions and privacy policies of any third-party websites you visit. Your interactions
            with these websites are governed by their respective terms and policies, which may
            differ from ours. The inclusion of any third-party links on the Site does not imply
            endorsement or affiliation with the linked website or its operators. We disclaim any
            responsibility for the actions, content, or practices of any third-party websites. If
            you decide to access or use any third-party websites linked from the Site, you do so
            voluntarily and solely at your own discretion.
          </p>
          <p>
            Please note that while we strive to provide reliable and useful links, we cannot
            guarantee the accuracy, safety, or reliability of third-party websites. It is your
            responsibility to exercise caution and make informed decisions when accessing or using
            any third-party websites.
          </p>
          <h2>7. Breaches of these Terms</h2>
          <p>
            We reserve the right to take appropriate action, including but not limited to the
            immediate termination or suspension of your access to the Site, in the event of any
            violation of these Terms. If we become aware of any violation of these Terms, we may, at
            our discretion, investigate the matter and take necessary legal action to enforce our
            rights and protect the integrity of the Site. Any failure on our part to enforce these
            Terms shall not be considered a waiver of our right to do so in the future, nor shall it
            constitute a waiver of any other provision or right under these Terms.
          </p>{' '}
          <p>
            You agree to cooperate fully in any investigation regarding a violation of these Terms.
            This may include providing any information or evidence necessary to resolve the matter
            satisfactorily. You understand and agree that we shall not be liable to you or any third
            party for any damages, losses, or expenses incurred as a result of our actions taken in
            response to a violation of these Terms.
          </p>{' '}
          <p>
            By using the Site, you acknowledge and agree that any violation of these Terms may
            result in the termination or suspension of your access to the Site and may lead to legal
            consequences.
          </p>
          <h2>8. Limitation of Liability</h2>
          <p>
            In no event shall we or our affiliates, directors, officers, employees, or agents be
            liable for any direct, indirect, incidental, special, consequential, or exemplary
            damages or loss, including but not limited to damages for loss of profits, goodwill,
            use, data, or other intangible losses or other costs and expenses (including legal or
            other professional adviser fees) (“Losses”), arising out of or in connection with your
            use of the Site or the inability to access or use it.
          </p>{' '}
          <p>
            Notwithstanding that we will not be responsible for any Losses suffered by you, if you
            suffer any Losses, you must take all reasonable steps to mitigate such Losses which has
            occurred or may occurred.
          </p>{' '}
          <p>
            To the extent the limitations of liability are unenforceable, our maximum liability to
            you shall be the lowest amount permitted under the relevant applicable laws.
          </p>
          <h2>9. Indemnity</h2>
          <p>
            You shall indemnify and keep us indemnified in full and on demand, and hold us harmless,
            from all claims, liabilities, direct or indirect damages, losses, costs, expenses, or
            fees (including reasonable attorneys&apos; fees) suffered or incurred by us arising out
            of or in connection with (1) your violation of these Terms, (2) your violation of any
            rights of any other person or entity, including but not limited to intellectual property
            rights, privacy rights or contractual rights, (3) your access to or use of any
            third-party websites linked from the Site, or (4) your violation of any applicable laws,
            regulations or third-party rights which involve the use of the Site.
          </p>{' '}
          <p>
            We reserve the right, at our own expense, to assume the exclusive defense and control of
            any matter otherwise subject to indemnification by you, in which event you will
            cooperate with us in asserting any available defenses.
          </p>{' '}
          <p>
            You acknowledge and agree that the indemnification obligations set forth in this
            provision shall survive the termination or expiration of these Terms and your use of the
            Site.
          </p>
          <h2>10. Circumstances Beyond Our Control</h2>
          <p>
            While we will use reasonable efforts to ensure the Site is operational to a satisfactory
            service level, there are events that are circumstances beyond our reasonable control
            which may inhibit, affect or prevent your access and use of the Site. These include,
            without limitation:
          </p>
          <p>
            <ul>
              {' '}
              <li>
                disruption of the information services required to access the Site such as your
                Internet service provider, our servers, the blockchains on which the Protocol is
                deployed, etc.;
              </li>{' '}
              <li>
                hardware failures of our servers or your personal device or Internet service
                provider;
              </li>{' '}
              <li>
                a temporary or indefinite halt of the functionality of the Site or any blockchain on
                which the Protocol is deployed; and
              </li>{' '}
              <li>
                cyberattacks against the Site or other malicious malware used to henbit the
                functionality of the Site.
              </li>{' '}
            </ul>
          </p>
          <p>
            We will not be responsible for any Losses incurred by you as a result of any
            circumstances beyond our reasonable control which may inhibit, affect or prevent your
            access and use of the Site.
          </p>
          <h2>11. Your Privacy</h2>
          <p>
            When you use the Site, we may collect information on your blockchain wallet address,
            completed transaction hashes, and the token names, symbols, or other blockchain
            identifiers of the tokens that you swap, which are all publicly available from the
            blockchain. When you interact with the blockchain, you are making certain information
            available for public viewing. We do not collect any other personal information from you.
            We use your publicly available information to (1) provide, maintain, customize and
            improve the Site, (2) protect against, investigate, and stop fraudulent, unauthorized,
            or illegal activity, or address security risks or issues, and (3) comply with our legal
            obligations.
          </p>{' '}
          <p>
            The Site does incorporate third-party service providers, such as Infura and Google
            Analytics, which may receive or independently obtain your personal information from
            publicly available sources. We do not control how these third parties handle your data
            and you should review their privacy policies to understand how they collect, use, and
            share your personal information. In particular, please visit the privacy policies of
            Infura and Google to learn more about how these third parties use data. By accessing and
            using the Interface, you understand and consent to our data practices and our service
            providers&apos; treatment of your information.
          </p>{' '}
          <p>
            We use the publicly available information we collect to detect, prevent, and mitigate
            financial crime and other illicit or harmful activities on the Interface. For these
            purposes, we may share the publicly available information we collect with blockchain
            analytics providers or legal authorities as appropriate. We may share your publicly
            available information with these service providers only so that they can help us promote
            the safety, security and integrity of the Site. We do not retain the information we
            collect any longer than necessary for these purposes.
          </p>{' '}
          <p>
            It is our policy to comply with all legal and regulatory requests, provided they are not
            invalid, overbroad, or unconstitutional requests as appropriate. However, we cannot
            guarantee that unauthorized third parties will never be able to obtain or use your
            information or aggregate data for improper purposes. You acknowledge that you provide
            your information and aggregate data at your own risk.
          </p>
          <h2>12. Taxes</h2>
          <p>
            Your access and use of the Site may result in various tax consequences, such as income
            or capital gains tax, value-added tax, goods and services tax, or sales tax in certain
            jurisdictions. It is your responsibility to determine whether taxes apply to any
            transactions you initiate or receive and, if so, to report and/or remit the correct tax
            to the appropriate tax authority.
          </p>
          <h2>13. No Warranties</h2>
          <p>
            The Site and its content are provided on an &quot;as-is&quot; and &quot;as
            available&quot; basis without any representations or warranties of any kind, whether
            express, implied, or statutory. We do not warrant that the website will be
            uninterrupted, error-free, secure, or free from viruses or other harmful components. To
            the extent permitted by the law, we disclaim all warranties, including but not limited
            to the implied warranties of merchantability, fitness for a particular purpose, and
            non-infringement. We do not represent or warrant that access to the Site will be
            continuous, uninterrupted, timely, or secure; that the information contained in the Site
            will be accurate, reliable, complete, or current; or that any of our products will be
            free from errors, defects, viruses, or other harmful elements. No advice, information,
            or statement that we make should be treated as creating any warranty concerning the
            Site.{' '}
          </p>
          <p>
            Similarly, the Protocol is provided &quot;as is&quot;, at your own risk, and without
            warranties of any kind. The Protocol is run autonomously by the smart contracts deployed
            on various blockchains.
          </p>
          <h2>14. No Investment, Legal, Tax, or Other Advice</h2>
          <p>
            The information provided on the Site and any of the social media accounts under the
            control of us or any individuals directly affiliated with us, including but not limited
            to articles, guides, and other content, is for informational purposes only. It does not
            constitute financial, investment, legal, tax, or any other professional advice. We do
            not provide any personalized recommendations or advice regarding the suitability,
            profitability, or legality of any investment, financial strategy, or transaction. Any
            decision to engage in transactions on the Site is solely at your own risk and
            discretion. You should consult with appropriate professionals, such as financial
            advisors, lawyers, or tax advisors, to obtain specific advice tailored to your
            individual circumstances before making any transactions on the Site. Your access and use
            of the Site does not establish a client, advisory, or fiduciary relationship between you
            and us.
          </p>{' '}
          <p>
            We make no representations or warranties regarding the accuracy, completeness, or
            timeliness of the information provided on our Site. We shall not be held liable by any
            person for any errors or omissions in the content or for any actions taken in, or any
            losses, damages, or liabilities arising from, reliance of the information provided. You
            bear full responsibility for evaluating the accuracy, completeness, and usefulness of
            any information or content provided on the Site and for seeking professional advice as
            necessary.
          </p>{' '}
          <p>
            By using the Site, you acknowledge and agree that we do not provide any investment,
            legal, tax, or other professional advice. You should seek appropriate advice from
            qualified professionals before making any financial or investment decisions. We shall
            not be held responsible for any consequences resulting from your reliance on the
            information provided on the Site.
          </p>
          <h2>15. Reservation of Rights</h2>
          <p>
            We reserve the right to take appropriate legal action, including but not limited to
            seeking damages, injunctions, or any other remedies available under applicable law, to
            address any violation of these Terms. We reserve the right to prohibit or block any
            activity on the Site that violates these Terms, including but not limited to the use of
            automated devices, unauthorized access attempts, or probing, scanning, or testing the
            vulnerability of the Site.
          </p>
          <h2>16. Assumption of Risk </h2>
          <p>
            You acknowledge and agree that the access or use of the Site and any involvement with
            crypto assets carry inherent risks. These risks include, but are not limited to, market
            volatility, potential loss of investment, regulatory changes, technological
            vulnerabilities, and cybersecurity risks which are beyond our control. We shall not be
            held liable for any losses, damages, or liabilities arising from your use of crypto
            assets or any transactions related to crypto assets conducted through the Site.
          </p>{' '}
          <p>
            You understand and accept that the value of crypto assets can fluctuate significantly,
            and there is no guarantee of profit or protection against losses. The decision to engage
            in transactions involving crypto assets is solely your responsibility, and you bear all
            risks associated with such transactions.
          </p>{' '}
          <p>
            You are solely responsible for conducting your own research, understanding the risks
            involved, and making informed decisions regarding the use, purchase, sale, or holding of
            crypto assets. You should consult with appropriate professionals before making any
            financial or investment decisions.
          </p>{' '}
          <p>
            It is your responsibility to ensure compliance with applicable laws, regulations, and
            tax obligations related to the use and ownership of crypto assets in your jurisdiction.
          </p>{' '}
          <p>
            We recommend implementing appropriate security measures, including but not limited to
            secure storage, strong passwords, and two-factor authentication, to protect your crypto
            assets and associated information.
          </p>{' '}
          <p>
            You acknowledge that the blockchain technology underlying crypto assets is subject to
            its own risks and limitations, including but not limited to network congestion,
            technological failures, and delays in transaction confirmation. We shall not be
            responsible for any losses or damages arising from such risks and limitations.
          </p>{' '}
          <p>
            By using the Site and engaging with crypto assets, you acknowledge and assume all risks
            associated with crypto assets, including any not described above (which is a
            non-exhaustive coverage of all relevant risks). You agree to release us from any
            liability and hold us harmless from any Losses incurred in connection with the use of
            crypto assets.
          </p>
          <h2>17. Miscellaneous </h2>
          <p>
            We reserve the right to modify, suspend, or terminate the Site or any part thereof, at
            any time without prior notice. We shall not be liable to you or any third party for any
            such modification, suspension, or termination. In addition, we may update these Terms
            from time to time in our sole discretion and without any prior notice to you. It is your
            responsibility to check the Site for the latest version of these Terms. The most recent
            date of update will clearly indicated in these Terms.
          </p>{' '}
          <p>
            If any provision of these Terms is found to be invalid or unenforceable, the remaining
            provisions shall continue to be valid and enforceable to the fullest extent permitted by
            law.
          </p>{' '}
          <p>
            These Terms contain the whole agreement between you and us relating to your access and
            use of the Site. You agree and acknowledge that you are not agreeing to these Terms in
            reliance of any representation, warranty or undertaking made by us.
          </p>{' '}
          <p>
            These Terms shall be governed by and construed in accordance with the laws of the Hong
            Kong, without regard to its conflict of laws principles. Any dispute, controversy,
            difference or claim arising out of or relating to this contract, including the
            existence, validity, interpretation, performance, breach or termination thereof or any
            dispute regarding non-contractual obligations arising out of or relating to it
            (“Dispute”) shall be referred to and finally resolved by arbitration administered by an
            arbitral institution determined by us under arbitral rules determined by us. The seat of
            arbitration shall be Hong Kong and there shall be a single arbitrator appointed by us.
          </p>{' '}
          <p>
            You and us explicitly waive any right to have a Dispute heard by jury trial and any
            Dispute you bring must be brought in your individual capacity and not as a plaintiff in
            or member of any purported class action, collective action, private attorney general
            action, or other representative proceedings. In the event that any immunities apply to
            you, you agree to waive such immunities.
          </p>{' '}
          <p>Last Updated: July 22, 2023</p>
        </div>
      </main>
    </div>
  )
}

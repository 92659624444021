import clsx from 'clsx'
import Image from 'next/image'
import { chainInfos } from '../../constants/web3/chains'
import { useWeb3 } from '../../context/Web3Context'
import Modal from '../Modal'
import { SUPPORTED_CHAIN_IDS, SupportedChainId } from '../../constants/web3/supportedChainId'
import { useModalContext } from '../../context/ModalContext'
import { ModalId } from '../../interfaces/modal'
import { getCrossChainSwapDisabledNetwork } from '../../utils/swap'
import { useSwapContext } from '../../context/SwapContext'
interface Props {
  isOpen: boolean
  onClose: () => void
  isHideMobileNav?: boolean
  onChainIdSelect?: (chainId: SupportedChainId) => void
  selectedChainId?: SupportedChainId
}

const getNetworkBtnStyle = (isNetworkDisabled?: boolean, isNetworkSelected?: boolean) => {
  if (isNetworkSelected || isNetworkDisabled) return 'cursor-not-allowed'
  return 'hover:bg-wombatYellow3'
}

const SwitchNetworkModal = ({
  isOpen,
  onClose,
  isHideMobileNav,
  onChainIdSelect,
  selectedChainId,
}: Props) => {
  const { switchNetwork, chainId } = useWeb3()
  const { modalState } = useModalContext()
  const isSelectingTargetChain = modalState.currentModalId === ModalId.SELECT_TARGET_CHAIN
  const { sourceChainId } = useSwapContext()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={350}
      height={440}
      title={<div className="text-lg">Select network</div>}
      variant="bordered"
      isHideMobileNav={isHideMobileNav}
    >
      <div className="flex flex-col justify-between">
        {SUPPORTED_CHAIN_IDS.map((networkChainId) => {
          const chainInfo = chainInfos[networkChainId]

          const isNetworkSelected = selectedChainId
            ? networkChainId === selectedChainId
            : chainId === networkChainId

          const isNetworkDisabled =
            isSelectingTargetChain &&
            getCrossChainSwapDisabledNetwork(sourceChainId, networkChainId)

          return (
            <div className="first:mt-0" key={networkChainId}>
              <button
                disabled={isNetworkSelected || isNetworkDisabled}
                onClick={() => {
                  if (onChainIdSelect) {
                    onChainIdSelect(networkChainId)
                  } else {
                    switchNetwork(networkChainId)
                  }
                  onClose()
                }}
                className={clsx(
                  'flex h-12 w-full items-center space-x-1 px-3  md:mt-0 md:space-x-4 md:p-1 md:px-3 ',
                  getNetworkBtnStyle(isNetworkDisabled, isNetworkSelected)
                )}
              >
                <div className="py-3 px-1 md:p-0">
                  {/* Mobile network button */}
                  <div className="flex items-center  md:hidden">
                    <Image layout="fixed" src={chainInfo.icon} width={26} height={26}></Image>
                  </div>
                  {/* Desktop network button */}
                  <div className="hidden items-center md:flex">
                    <Image layout="fixed" src={chainInfo.icon} width={26} height={26}></Image>
                  </div>
                </div>
                <p
                  className={clsx(
                    'font-regular md:sem font-Work text-lg md:font-semibold',
                    isNetworkSelected ? '!text-wombatPurple1 md:font-bold' : 'text-black',
                    isNetworkDisabled ? 'text-wombatGrey2' : ''
                  )}
                >
                  {chainInfo.label}
                </p>
              </button>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default SwitchNetworkModal

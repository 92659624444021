import { multicall } from '@wagmi/core'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CallbacksType = ((value: any) => void)[]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function executeCallBacks(values: any[], callbacks: CallbacksType): void {
  if (!values) return
  if (values.length == 0) return
  if (values.length !== callbacks.length) {
    console.error('Callbacks and values length is not equal')
    return
  }
  for (let i = 0; i < callbacks.length; i++) {
    const value = values[i]
    if (process.env.NEXT_PUBLIC_SHOW_MULITCALL_ERROR === 'true' && value === null) {
      console.error('This callback makes error:', callbacks[i])
    }
    const callback = callbacks[i]
    if (value !== null) {
      callback(value)
    }
  }
}

type Writeable<T> = { -readonly [P in keyof T]: T[P] }
export type IContractCalls = Writeable<Parameters<typeof multicall>[0]['contracts']>

import { BigNumber, utils } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import { useWeb3 } from '../context/Web3Context'
import { useContract } from 'wagmi'
import { VEWOMS } from '../constants/contract/veWom'
import { BreedingStructOutput } from '../interfaces/contract'
import { TokenSymbols } from '../configx/tokenSymbols'
import { useTokenData } from '../store/MulticallData/hooks'

type useFetchBoostInfoProps = {
  balance: string | undefined
  totalLocked: string | undefined
  totalEarning: string | undefined
  breedings: BreedingStructOutput | null
  refreshFetchBoost: () => void
}

/**
 * @deprecated
 */
export default function useFetchBoostInfo(): useFetchBoostInfoProps {
  const { account, readonlyProvider, chainId } = useWeb3()
  const vewomContract = useContract({
    ...VEWOMS[chainId]?.get(),
    signerOrProvider: readonlyProvider,
  })
  const { withAccount } = useTokenData()
  const balance = withAccount?.balances[TokenSymbols.WOM]
  const [totalLocked, setTotalLocked] = useState<string | undefined>(undefined)
  const [totalEarning, setTotalEarning] = useState<string | undefined>(undefined)
  const [breedings, setBreedings] = useState<BreedingStructOutput | null>(null)

  const getBreedings = useCallback(async () => {
    if (!account || !vewomContract) return
    const _breedings = (await vewomContract?.getUserInfo(account))?.breedings
    setBreedings(_breedings)
    let totalWom = BigNumber.from(0)
    let totalVeWom = BigNumber.from(0)
    _breedings.forEach((breeding) => {
      totalWom = totalWom.add(breeding.womAmount)
      totalVeWom = totalVeWom.add(breeding.veWomAmount)
    })
    setTotalLocked(Number(utils.formatUnits(totalWom)).toFixed(2))
    setTotalEarning(Number(utils.formatUnits(totalVeWom)).toFixed(2))
  }, [account, vewomContract])

  const refreshFetchBoost = useCallback(() => {
    getBreedings()
  }, [getBreedings])

  useEffect(() => {
    refreshFetchBoost()
  }, [refreshFetchBoost])

  return { balance, breedings, totalLocked, totalEarning, refreshFetchBoost }
}

import React from 'react'
import CrossChainTabs from './CrossChainTabs'
import useCrossChainSwapEvents from '../../../hooks/crossChainSwap/useCrossChainSwapEvents'
import CrossChainTxnInputBox from './CrossChainTxnInputBox'
import { useWeb3 } from '../../../context/Web3Context'
import { useWormholeHealth } from '../../../store/Wormhole/hooks'
import useSWR from 'swr'
import { useAppDispatch } from '../../../store/hooks'
import getWormholeStatus from '../../../store/Wormhole/thunks/getWormholeStatus'
import Image from 'next/image'
import Icon from '../../../public/assets/icons/wormhole-icon.svg'

function CrossChainSwapInfoContainer() {
  const { account } = useWeb3()
  const health = useWormholeHealth()
  const dispatch = useAppDispatch()
  useSWR(
    'wormholeHealth',
    () => {
      dispatch(getWormholeStatus())
    },
    { refreshInterval: 5000 }
  )
  useCrossChainSwapEvents()

  return (
    <div className="container--drop-shadow w-full p-[32px] max-md:shadow-none">
      {account && <CrossChainTxnInputBox />}
      <div className={'mb-3 flex items-center justify-between '}>
        <p className="font-Jamjuree text-base font-medium text-wombatPurple">
          Crosschain Swap Status
        </p>
        <div className={'flex items-center justify-end gap-1'}>
          <div className={'-mb-2 inline md:hidden'}>
            <Image src={Icon} width={20} height={20}></Image>
          </div>
          <p className="font-regular hidden font-Jamjuree text-base text-wombatPurple md:inline">
            Wormhole Status
          </p>
          <div className={`h-2 w-2 rounded-xl ${health ? 'bg-green-500' : 'bg-red-500'}`}></div>
        </div>
      </div>
      <CrossChainTabs />
    </div>
  )
}

export default CrossChainSwapInfoContainer

import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { WPP_API_HOST } from '../../../constants/common'
import { WpPointsState } from '../types'

const getWpPoints = createAsyncThunk<Pick<WpPointsState, 'points' | 'needAuth'>, { user: string }>(
  'wpPoints/getWpPoints',
  async ({ user }) => {
    if (!user) {
      return { points: {}, needAuth: true }
    }
    const response = await axios.get(`${WPP_API_HOST}/api/v1/point/wpp_balance`, {
      params: {
        wallet: user,
      },
    })
    let needAuth = false
    let point = 0
    if (response.data.code !== 0) {
      if (response.data.code === 10001) {
        needAuth = true
        point = 0
      } else {
        throw new Error(response.data.message)
      }
    } else {
      point = response.data.data.wpp
      needAuth = false
    }
    return {
      points: {
        [user.toLowerCase()]: point,
      },
      needAuth,
    }
  }
)

export default getWpPoints

import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react'
import { TOKENS } from '../constants/contract'
import { useDebounce } from '../hooks/useDebounce'
import { useSwapContext } from './SwapContext'
import { useWeb3 } from './Web3Context'
import { getDpFormat, isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../constants/contract/token'
import { CROSS_CHAIN_GAS_RECEIVING_AMOUNT } from '../constants/web3/chains'
import { usePoolData, useTokenData } from '../store/MulticallData/hooks'
import { useCashesData } from '../store/Asset/hooks'

interface ContextType {
  displayedToValue: string
  displayedFromValue: string
  fromTokenAmountGtBalance: boolean
  isEndCovRatioExceed: boolean
  recipientAddress: string
  displayInvalidAddressWarning: boolean
  handleFromInputChange: (value: string | null) => void
  handleToInputChange: (value: string | null) => void
  handleSwitchButtonOnClick: () => void
  handleMaxButtonOnClick: () => void
  resetAllInputAmount: () => void
  setRecipientAddress: Dispatch<SetStateAction<string>>
  setDisplayInvalidAddressWarning: Dispatch<SetStateAction<boolean>>
  handleReceiverValueOnChange: (value: string) => void
  handleReceiverMaxOnClick: () => void
}

const initialState: ContextType = {
  displayedToValue: '',
  displayedFromValue: '',
  displayInvalidAddressWarning: false,
  fromTokenAmountGtBalance: false,
  isEndCovRatioExceed: false,
  recipientAddress: '',
  handleFromInputChange: () => undefined,
  handleToInputChange: () => undefined,
  handleSwitchButtonOnClick: () => undefined,
  handleMaxButtonOnClick: () => undefined,
  resetAllInputAmount: () => undefined,
  setRecipientAddress: () => undefined,
  setDisplayInvalidAddressWarning: () => undefined,
  handleReceiverValueOnChange: () => undefined,
  handleReceiverMaxOnClick: () => undefined,
}

const SwapInputContext = createContext<ContextType>(initialState)
SwapInputContext.displayName = 'SwapInputContext'

interface Props {
  children: React.ReactNode
}

export const useSwapInputContext = (): ContextType => {
  return useContext(SwapInputContext)
}

function SwapInputProvider({ children }: Props): ReactElement {
  const {
    fromTokenSymbol,
    toTokenSymbol,
    fromTokenAmount,
    toTokenAmount,
    swapPathData,
    targetChainId,
    updateToTokenAmount,
    updateFromTokenAmount,
    switchTokensDirection,
    resetAllAmount,
    setReceiverValue,
  } = useSwapContext()

  /** @todo rename it as hasInvalidRecipientAddress */
  const [displayInvalidAddressWarning, setDisplayInvalidAddressWarning] = useState(false)

  const { withAccount } = useTokenData()

  const { chainId, account } = useWeb3()
  const { cashes, liabilities } = useCashesData()
  const poolData = usePoolData()

  /**@todo create tokenBalance context for manipulating the native token balance*/
  const nativeTokenBalance = withAccount?.balances[NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]]
  const tokenBalances = useMemo(() => {
    const tokenBalances = Object.assign({}, withAccount?.balances)
    tokenBalances[NATIVE_WRAPPED_TOKEN_IN_CHAIN[chainId]] = nativeTokenBalance ?? '0'
    return tokenBalances
  }, [chainId, nativeTokenBalance, withAccount?.balances])

  // from/to amount inputted in the text field
  const [toTokenAmountInputted, setToTokenAmountInputted] = useState('')
  const [fromTokenAmountInputted, setFromTokenAmountInputted] = useState('')

  // debounced from/to input amount
  const debouncedToAmountInputted = useDebounce(toTokenAmountInputted)
  const debouncedFromAmountInputted = useDebounce(fromTokenAmountInputted)

  const [displayedFromValue, setDisplayedFromValue] = useState('')
  const [displayedToValue, setDisplayedToValue] = useState('')

  const [lastInputValue, setLastInputValue] = useState<'to' | 'from' | null>(null)

  const [recipientAddress, setRecipientAddress] = useState(account || '')

  const handleFromInputChange = (value: string | null) => {
    if (!value || value === '') {
      setFromTokenAmountInputted('')
      resetAllAmount()
      return
    }
    const fromToken = TOKENS[chainId][fromTokenSymbol]
    if (!fromToken) return
    if (isParsableString(value, fromToken.decimals, true)) {
      setLastInputValue('from')
      setFromTokenAmountInputted(value)
    }
  }

  const handleToInputChange = (value: string | null) => {
    if (!value || value === '') {
      setToTokenAmountInputted('')
      resetAllAmount()
      return
    }
    const toToken = TOKENS[chainId][toTokenSymbol]
    if (!toToken) return
    if (isParsableString(value, toToken.decimals, true)) {
      setLastInputValue('to')
      setToTokenAmountInputted(value)
    }
  }

  const handleSwitchButtonOnClick = () => {
    setLastInputValue(null)
    switchTokensDirection()
  }

  const handleMaxButtonOnClick = () => {
    if (!account) {
      setFromTokenAmountInputted('')
      resetAllAmount()
      return
    }

    if (!tokenBalances) return
    const fromTokenBalance = tokenBalances[fromTokenSymbol]
    const fromToken = TOKENS[chainId][fromTokenSymbol]
    if (!fromTokenBalance || !fromToken) return
    if (isParsableString(fromTokenBalance, fromToken.decimals, true)) {
      setLastInputValue('from')
      setFromTokenAmountInputted(fromTokenBalance ?? '0')

      // directly update the fromAmount since if user last input is toAmount and then click max,
      // fromTokenAmountInputted will not update hence updateFromTokenAmount will not triggered
      updateFromTokenAmount(fromTokenBalance ?? '0')
    }
  }

  // when debounced to/from amount changes, update it to swap context
  useEffect(() => {
    void updateToTokenAmount(debouncedToAmountInputted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedToAmountInputted])

  useEffect(() => {
    void updateFromTokenAmount(debouncedFromAmountInputted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFromAmountInputted])

  // show display value base on lastInputValue
  useEffect(() => {
    const formattedToTokenAmount = toTokenAmount
      ? getDpFormat(toTokenAmount, 6, 'off', false, false)
      : ''
    const formattedFromTokenAmount = fromTokenAmount
      ? getDpFormat(fromTokenAmount, 6, 'off', false, false)
      : ''
    if (lastInputValue === 'from') {
      setDisplayedFromValue(fromTokenAmountInputted)
      setDisplayedToValue(formattedToTokenAmount)
    } else {
      // if user switch token, lastInputValue === null -> displayToAmount = toTokenAmount
      // if change to input, lastInputValue === 'to' -> displayToAmount = toTokenAmountInputted
      setDisplayedToValue(lastInputValue ? toTokenAmountInputted : toTokenAmount)
      setDisplayedFromValue(formattedFromTokenAmount)
    }
  }, [
    fromTokenAmount,
    fromTokenAmountInputted,
    toTokenAmount,
    toTokenAmountInputted,
    lastInputValue,
  ])

  const fromTokenAmountGtBalance = useMemo(() => {
    const fromTokenBalance = tokenBalances[fromTokenSymbol]
    return strToWad(fromTokenAmount).gt(strToWad(fromTokenBalance))
  }, [fromTokenAmount, fromTokenSymbol, tokenBalances])

  const isEndCovRatioExceed = useMemo(() => {
    if (swapPathData) {
      for (let index = 0; index < swapPathData.poolLabelPath.length; index++) {
        const poolLabel = swapPathData.poolLabelPath[index]
        const tokenSymbol = swapPathData.tokenSymbolPath[index]
        const cash = cashes[poolLabel][tokenSymbol]
          ? Number(cashes[poolLabel][tokenSymbol]) / 1e18
          : null
        const liability = liabilities[poolLabel][tokenSymbol]
          ? Number(liabilities[poolLabel][tokenSymbol]) / 1e18
          : null
        const covRatio = cash && liability ? cash / liability : null
        const endCovRatio = poolData.withoutAccount?.endCovRatios[poolLabel]
        if (endCovRatio && covRatio && covRatio > endCovRatio) {
          return true
        }
      }
    }
    return false
  }, [cashes, liabilities, poolData.withoutAccount?.endCovRatios, swapPathData])

  const resetAllInputAmount = () => {
    resetAllAmount()
    setToTokenAmountInputted('')
    setFromTokenAmountInputted('')
  }

  useEffect(() => {
    if (!account) return
    setRecipientAddress(account)
  }, [account])

  const handleReceiverValueOnChange = (value: string) => {
    if (!value || value === '') {
      setReceiverValue('')
      return
    }
    if (!isParsableString(value, 18, true)) return
    setReceiverValue(value)
  }

  const handleReceiverMaxOnClick = useCallback(() => {
    setReceiverValue(CROSS_CHAIN_GAS_RECEIVING_AMOUNT[targetChainId].maxAmount)
  }, [setReceiverValue, targetChainId])

  return (
    <SwapInputContext.Provider
      value={{
        recipientAddress,
        displayedFromValue,
        displayedToValue,
        fromTokenAmountGtBalance,
        isEndCovRatioExceed,
        displayInvalidAddressWarning,
        handleFromInputChange,
        handleToInputChange,
        handleSwitchButtonOnClick,
        handleMaxButtonOnClick,
        resetAllInputAmount,
        setRecipientAddress,
        setDisplayInvalidAddressWarning,
        handleReceiverValueOnChange,
        handleReceiverMaxOnClick,
      }}
    >
      {children}
    </SwapInputContext.Provider>
  )
}

export default SwapInputProvider

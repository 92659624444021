import { VOTERS } from '../../constants/contract'
import { useModalContext } from '../../context/ModalContext'
import { useWeb3 } from '../../context/Web3Context'
import { useVotingData } from '../../context/VotingDataContext'
import { ModalId } from '../../interfaces/modal'
import { calculateGasMargin } from '../../utils'
import { useContract } from 'wagmi'
import { useAppDispatch } from '../../store/hooks'
import { addSuccessToast, addErrorToast } from '../../store/Toast/actions'

type Props = {
  handleClaim: () => Promise<void>
}
const useClaimBribeRewards = (): Props => {
  const { chainId, signer } = useWeb3()
  const voterContract = useContract({ ...VOTERS[chainId]?.get(), signerOrProvider: signer })
  const {
    user: { earnedBribeRewards },
  } = useVotingData()
  const {
    actions: { openModal },
  } = useModalContext()
  const dispatch = useAppDispatch()

  const handleClaim = async () => {
    let transaction
    try {
      if (voterContract) {
        openModal({ currentModalId: ModalId.CLAIM_BRIBE_WAIT_FOR_CONFIRMATION })
        const lpTokenAddesses = earnedBribeRewards.map((reward) => reward.lpTokenAddress)
        const estimatedGas = await voterContract.estimateGas.claimBribes(lpTokenAddesses)
        transaction = await voterContract.claimBribes(lpTokenAddesses, {
          gasLimit: calculateGasMargin(estimatedGas),
        })
        await transaction.wait()
        dispatch(
          addSuccessToast({
            message: '',
            title: 'Bribe Rewards Claimed',
            txHash: transaction.hash,
          })
        )
      }
    } catch (err) {
      console.error(err)
      dispatch(
        addErrorToast({
          message: '',
          title: 'Claim Failed',
          txHash: transaction?.hash,
        })
      )
    }
    openModal({ currentModalId: ModalId.UNSET })
  }
  return { handleClaim }
}

export default useClaimBribeRewards

import { createAsyncThunk } from '@reduxjs/toolkit'
import { MasterWombatState } from '../types'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { AssetProperty } from '../../Asset/types'
import { BigNumber, utils } from 'ethers'
import { PoolDataWithoutAccountType } from '../../../context/MulticallDataContext/helpers'
import { TokenPricesState } from '../../Prices/slice'
import { AvgFeeSharingAprs } from '../../../interfaces/masterWombat'
import { fetchCashLiability, getInitialAssetProperty } from '../../Asset/helpers'
import { PoolLabelsTokenSymbolsGenericType } from '../../../interfaces/common'
import { getPoolTokenPair } from '../../../constants/contract/pool'
import { ASSETS } from '../../../constants/contract/asset'
import { nativeToWAD, safeWdiv, strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { calculateAvgFeeSharingApr } from '../../../utils/pool'
import { calLPPrice } from '../../../utils/math'
import { SubgraphProperty } from '../../Subgraph/types'

const fetchFeeSharingAprs = createAsyncThunk<
  Pick<MasterWombatState, 'avgFeeSharingAprs'>,
  {
    method: 'tradingVolume' | 'lpTokenToTokenRatio'
    chainId: SupportedChainId
    liabilities: AssetProperty<BigNumber>
    lpTokenToTokenRates: AssetProperty<BigNumber>
    lpDividendRatios: PoolDataWithoutAccountType['lpDividendRatios'] | undefined
    poolFeePercentages: PoolDataWithoutAccountType['poolFeePercentages'] | undefined
    tokenPrices: TokenPricesState['tokenPrices']
    tradingVol24h: SubgraphProperty<number | null> | null
    fetchBlockNumberByTimestamp: (timestamp: number) => Promise<string | null>
  }
>(
  'masterWombat/fetchFeeSharingAprs',
  async ({
    method,
    chainId,
    liabilities,
    lpTokenToTokenRates,
    lpDividendRatios,
    poolFeePercentages,
    tokenPrices,
    tradingVol24h,
    fetchBlockNumberByTimestamp,
  }) => {
    const _avgFeeSharingAprs: AvgFeeSharingAprs = getInitialAssetProperty()

    /**
     * Method 1: Use lpTokenToToken ratio to calculate fee sharing apr
     */
    if (method === 'lpTokenToTokenRatio') {
      /**
       * A helper function
       */
      const getAvgFeeSharingApr = async (previousBlock: number, days: number) => {
        const result: PoolLabelsTokenSymbolsGenericType<string> = {}
        // TODO: to be test
        const currentCashLiabilityBnData = await fetchCashLiability({
          block: 'latest',
          chainId,
        })
        if (!currentCashLiabilityBnData) return
        /** @todo can just use lpTokenToTokenRates from currentCashLiabilityBnData  */
        const { liabilities: currentLiabilities, totalSupplies: currentTotalSupplies } =
          currentCashLiabilityBnData
        const previousCashLiabilityBnData = await fetchCashLiability({
          chainId,
          block: previousBlock,
        })
        if (previousCashLiabilityBnData) {
          /** @todo can just use lpTokenToTokenRates from previousCashLiabilityBnData  */
          const { liabilities: previousLiabilities, totalSupplies: previousTotalSupplies } =
            previousCashLiabilityBnData

          getPoolTokenPair(chainId).forEach(({ poolLabel, tokenSymbol }) => {
            const asset = ASSETS[chainId][poolLabel][tokenSymbol]
            const previousLiabilityBn = previousLiabilities[poolLabel][tokenSymbol]
            const previousTotalSupplyBn = previousTotalSupplies[poolLabel][tokenSymbol]
            const currentLiabilityBn = currentLiabilities[poolLabel][tokenSymbol]
            const currentTotalSupplyBn = currentTotalSupplies[poolLabel][tokenSymbol]
            if (
              !asset ||
              !previousLiabilityBn ||
              !currentLiabilityBn ||
              !previousTotalSupplyBn ||
              !currentTotalSupplyBn
            ) {
              return
            }

            const assetDecimals = asset.decimals
            const currentLiabilityWad = nativeToWAD(currentLiabilityBn, assetDecimals)
            const currentTotalSupplyWad = nativeToWAD(currentTotalSupplyBn, assetDecimals)
            const previousLiabilityWad = nativeToWAD(previousLiabilityBn, assetDecimals)
            const previousTotalSupplyWad = nativeToWAD(previousTotalSupplyBn, assetDecimals)
            const averageApr = calculateAvgFeeSharingApr({
              liabilitiesWad: { new: currentLiabilityWad, old: previousLiabilityWad },
              totalSuppliesWad: {
                new: currentTotalSupplyWad,
                old: previousTotalSupplyWad,
              },
              daysBetweenNewAndOldData: days,
            })

            result[poolLabel] = { ...result[poolLabel], [tokenSymbol]: averageApr }
          })
        }

        return result
      }

      /**
       * Main function
       */
      const daysForWeek = 7
      const dayForYesterday = 1
      const dayInSecond = 86400
      const nowInSecond = Date.now() / 1000
      const yesterdayInSecond = nowInSecond - dayInSecond * dayForYesterday
      const yesterdayBlock = await fetchBlockNumberByTimestamp(Math.floor(yesterdayInSecond))
      const lastWeekInSecond = nowInSecond - dayInSecond * daysForWeek
      const lastWeekBlock = await fetchBlockNumberByTimestamp(Math.floor(lastWeekInSecond))

      let dailyFeeSharingApr: PoolLabelsTokenSymbolsGenericType<string> | undefined
      let weeklyFeeSharingApr: PoolLabelsTokenSymbolsGenericType<string> | undefined
      if (yesterdayBlock) {
        dailyFeeSharingApr = await getAvgFeeSharingApr(+yesterdayBlock, dayForYesterday)
      }
      if (lastWeekBlock) {
        weeklyFeeSharingApr = await getAvgFeeSharingApr(+lastWeekBlock, daysForWeek)
      }

      getPoolTokenPair(chainId).forEach(({ poolLabel, tokenSymbol }) => {
        _avgFeeSharingAprs[poolLabel] = {
          ..._avgFeeSharingAprs[poolLabel],
          [tokenSymbol]: {
            daily: dailyFeeSharingApr?.[poolLabel]?.[tokenSymbol] || '0',
            weekly: weeklyFeeSharingApr?.[poolLabel]?.[tokenSymbol] || '0',
          },
        }
      })
    }

    /**
     * Method 2: Use trading volume to calculate fee sharing apr
     */
    if (method === 'tradingVolume') {
      getPoolTokenPair(chainId).forEach(({ poolLabel, tokenSymbol }) => {
        const assetDecimals = ASSETS[chainId][poolLabel][tokenSymbol]?.decimals
        if (!assetDecimals) return
        const assetTradingVol24h = tradingVol24h?.[poolLabel][tokenSymbol]?.toString()
        const lpDividendRatio = lpDividendRatios ? lpDividendRatios[poolLabel] : undefined
        const poolFeePercentage = poolFeePercentages
          ? poolFeePercentages[poolLabel]?.toString()
          : undefined
        const liabilityBn = liabilities[poolLabel][tokenSymbol]
        if (
          lpDividendRatio &&
          assetTradingVol24h &&
          poolFeePercentage &&
          liabilityBn &&
          !liabilityBn.isZero()
        ) {
          /**
           * Formula:
           * (asset trading volume * lp dividend ratio * haircut / number of days of the volume * 365) / asset tvl (liability in USD)
           */

          const tvl = calLPPrice(
            nativeToWAD(liabilityBn, assetDecimals),
            lpTokenToTokenRates[poolLabel][tokenSymbol] ?? null,
            strToWad(tokenPrices[tokenSymbol])
          )
          if (!tvl || tvl.isZero()) return
          const dailyApr = utils.formatEther(
            safeWdiv(
              wmul(
                strToWad(assetTradingVol24h),
                wmul(strToWad(lpDividendRatio), strToWad(poolFeePercentage))
              ).mul(365),
              tvl
            )
          )
          _avgFeeSharingAprs[poolLabel] = {
            ..._avgFeeSharingAprs[poolLabel],
            [tokenSymbol]: {
              daily: dailyApr,
            },
          }
        }
      })
    }
    return {
      avgFeeSharingAprs: _avgFeeSharingAprs,
    }
  }
)

export default fetchFeeSharingAprs

import { strToWad, wadToNative } from '@hailstonelabs/big-number-utils'
import { BigNumber, utils } from 'ethers'
import { formatEther } from 'ethers/lib/utils.js'
import { useMemo } from 'react'
import { WAD_DECIMALS } from '../../constants/common'
import { TOKENS } from '../../constants/contract'
import { useWeb3 } from '../../context/Web3Context'
import { ISwapPathData } from '../../interfaces/swap'
import { useCashesData } from '../../store/Asset/hooks'

const useCovRatioBetter = ({
  path,
  fromTokenAmount,
  minimumReceive,
}: {
  path: ISwapPathData | null
  fromTokenAmount: string
  minimumReceive: string
}) => {
  const { cashes, liabilities } = useCashesData()
  const { chainId } = useWeb3()
  return useMemo(() => {
    if (!path) return false
    const lastPool = path.poolLabelPath[path.poolLabelPath.length - 1]
    const lastTwoToken = path.tokenSymbolPath.slice(-2)
    const fromTokenSymbol = lastTwoToken[0]
    const toTokenSymbol = lastTwoToken[1]

    const fromAmountNumber = Number(fromTokenAmount)

    const minimumReceiveWad = strToWad(minimumReceive)
    const minimumReceiveNumber = Number(
      formatEther(
        wadToNative(minimumReceiveWad, TOKENS[chainId][toTokenSymbol]?.decimals ?? WAD_DECIMALS)
      )
    )

    const fromCash = Number(
      utils.formatEther(cashes[lastPool][fromTokenSymbol] || BigNumber.from(0))
    )
    const fromLiability = Number(
      formatEther(liabilities[lastPool][fromTokenSymbol] || BigNumber.from(0))
    )

    const toCash = Number(formatEther(cashes[lastPool][toTokenSymbol] || BigNumber.from(0)))
    const toLiability = Number(
      formatEther(liabilities[lastPool][toTokenSymbol] || BigNumber.from(0))
    )

    const fromOldCovRatioDifference = Math.abs(fromCash / fromLiability - 1)
    const fromNewCovRatioDifference = Math.abs((fromCash + fromAmountNumber) / fromLiability - 1)

    const toOldCovRatioDifference = Math.abs(toCash / toLiability - 1)
    const toNewCovRatioDifference = Math.abs((toCash - minimumReceiveNumber) / toLiability - 1)

    return (
      fromNewCovRatioDifference < fromOldCovRatioDifference ||
      toNewCovRatioDifference < toOldCovRatioDifference
    )
  }, [cashes, chainId, fromTokenAmount, liabilities, minimumReceive, path])
}

export default useCovRatioBetter

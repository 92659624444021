import { HexString } from '../../interfaces/contract'
import { SupportedChainId } from '../web3/supportedChainId'
import { ROUTER_ABI } from './abis/router'
import { Contract } from './Contract'

export class Router extends Contract<typeof ROUTER_ABI> {
  constructor({ chainId, address }: { chainId: SupportedChainId; address: HexString }) {
    super({
      chainId,
      address,
      abi: ROUTER_ABI,
    })
  }
}

export const ROUTERS: { [id in SupportedChainId]?: Router } = {
  [SupportedChainId.BSC_MAINNET]: new Router({
    chainId: SupportedChainId.BSC_MAINNET,
    address: '0x19609B03C976CCA288fbDae5c21d4290e9a4aDD7',
  }),
  [SupportedChainId.BSC_TESTNET]: new Router({
    chainId: SupportedChainId.BSC_TESTNET,
    address: '0xED430B9b729260DA55006A49E06aFc451F958f1E',
  }),
  [SupportedChainId.ARBITRUM_ONE]: new Router({
    chainId: SupportedChainId.ARBITRUM_ONE,
    address: '0xc4B2F992496376C6127e73F1211450322E580668',
  }),
  [SupportedChainId.ETHEREUM_MAINNET]: new Router({
    chainId: SupportedChainId.ETHEREUM_MAINNET,
    address: '0x6BB82A9b0b9b9716B885baeEfDBE47b685a0F919',
  }),
  [SupportedChainId.SCROLL_SEPOLIA_TESTNET]: new Router({
    chainId: SupportedChainId.SCROLL_SEPOLIA_TESTNET,
    address: '0x9aACf1E6A7076052590A672F9ba641DdD424C17d',
  }),
  [SupportedChainId.SCROLL_MAINNET]: new Router({
    chainId: SupportedChainId.SCROLL_MAINNET,
    address: '0x010931D4d82ff3F5Ae8bD94e0752570711f78959',
  }),
  [SupportedChainId.SKALE_TESTNET]: new Router({
    chainId: SupportedChainId.SKALE_TESTNET,
    address: '0xbBB38138dD216C60a38181565f552BcA4Ac8B06B',
  }),
  [SupportedChainId.OP_MAINNET]: new Router({
    chainId: SupportedChainId.OP_MAINNET,
    address: '0x35d531Fd45D5E3A5d407A4898360c757C98Bdf79',
  }),
  // [SupportedChainId.ZKSYNC_TESTNET]: new Router({
  //   chainId: SupportedChainId.ZKSYNC_TESTNET,
  //   address: '0x44A32fE13903Cf0D18290736969EA311C01FF2c4',
  // }),
  [SupportedChainId.AVAX]: new Router({
    chainId: SupportedChainId.AVAX,
    address: '0x4A88C44B8D9B9f3F2BA4D97236F737CF03DF76CD',
  }),
  [SupportedChainId.BASE]: new Router({
    chainId: SupportedChainId.BASE,
    address: '0x4A88C44B8D9B9f3F2BA4D97236F737CF03DF76CD',
  }),
  [SupportedChainId.POLYGON_MAINNET]: new Router({
    chainId: SupportedChainId.POLYGON_MAINNET,
    address: '0x34E2F923bBa206358EcE221af73E8d121837F873',
  }),
}

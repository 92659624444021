import { getCommifiedFormat, getDpFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React, { useMemo } from 'react'
import { ASSETS } from '../../../constants/contract/asset'
import { POOLS } from '../../../constants/contract/pool'
import { useModalContext } from '../../../context/ModalContext'
import { useWeb3 } from '../../../context/Web3Context'
import useSnapshot from '../../../hooks/useSnapshot'
import { getValueFromPercentage } from '../../../utils'
import WaitingModal from '../../Modal/WaitingModal'
import ModalInfoBox from '../../ModalInfoBox'
import { useUserVoteAllocationTable } from '../UserVoteAllocationTable/UserVoteAllocationTableContext'
import { useVewomData } from '../../../store/MulticallData/hooks'

type Props = {
  isOpen: boolean
}

function VoteWaitForConfirmationModal({ isOpen }: Props) {
  const {
    actions: { closeModal },
  } = useModalContext()
  const { chainId } = useWeb3()
  const { editedGaugeList, voteWeightPercentageInputs, totalVoteWeightPercentageOfTheTable } =
    useSnapshot(useUserVoteAllocationTable())
  const vewomData = useVewomData()
  const vewomBalanceWad = useMemo(() => {
    return vewomData.withAccount?.vewomBalanceWad || null
  }, [vewomData.withAccount?.vewomBalanceWad])
  /** @todo no veWom token to get dp */
  const veWomBalance = utils.formatUnits(vewomBalanceWad || '0', 18) || ''
  const voteSummary = useMemo(() => {
    const totalVoteAllocated = getValueFromPercentage(
      veWomBalance,
      totalVoteWeightPercentageOfTheTable
    )
    const unallocatedWeightPercentage = utils.formatEther(
      strToWad('100').sub(strToWad(totalVoteWeightPercentageOfTheTable))
    )
    return [
      {
        label: 'Total vote allocated',
        value: `${getCommifiedFormat(totalVoteAllocated)} veWOM`,
      },
      {
        label: 'Vote weight',
        value: `${getDpFormat(totalVoteWeightPercentageOfTheTable)}%`,
      },
      {
        label: 'Unallocated weight',
        value: `${getDpFormat(unallocatedWeightPercentage)}%`,
      },
    ]
  }, [totalVoteWeightPercentageOfTheTable, veWomBalance])
  return (
    <WaitingModal
      variant="secondary"
      isOpen={isOpen}
      isDisplayGifFlyToTheMoon={false}
      onClose={closeModal}
    >
      <ModalInfoBox title="Vote Allocation">
        <div>
          {editedGaugeList.map(({ poolSymbol, assetTokenSymbol }) => {
            const asset = ASSETS[chainId][poolSymbol][assetTokenSymbol]
            const pool = POOLS[chainId][poolSymbol]
            const votesPercentage = voteWeightPercentageInputs[poolSymbol]?.[assetTokenSymbol] || ''
            const votes = getCommifiedFormat(
              getValueFromPercentage(veWomBalance, votesPercentage),
              2
            )
            return (
              <ModalInfoBox.InfoItem
                key={poolSymbol + assetTokenSymbol}
                leadingLabel={`${asset?.displaySymbol} (${pool?.name})`}
                trailingLabel={`${votes} veWOM`}
              />
            )
          })}
        </div>
        <ModalInfoBox.Divider />
        <div>
          {voteSummary.map((data) => (
            <ModalInfoBox.InfoItem
              key={data.label}
              className="font-Jamjuree text-wombatPurple1"
              leadingLabel={data.label}
              trailingLabel={data.value}
              bold
            />
          ))}
        </div>
      </ModalInfoBox>
    </WaitingModal>
  )
}

export default VoteWaitForConfirmationModal

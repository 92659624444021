import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const ARB1_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0xe78876C360716f2225F55A6726B32324FE1B1145',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.MAIN]: {
    name: 'Main Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0x39a2f59875bC636b7eFEcAc30b6E97066a850B1e',
    version: 'volatile_v2',
  },
  [PoolLabels.STABLE]: {
    name: 'Stable Pool',
    filterGroup: PoolFilterGroup.MAIN,
    address: '0xc6bc781e20f9323012f6e422bdf552ff06ba6cd1',
  },
  [PoolLabels.frxETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0x20d7ee728900848752fa280fad51af40c47302f1',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.mPendle]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0xe7159f15e7b1d6045506B228A1ed2136dcc56F48',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.USDV]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xa6eF6C45EbFDBc13f6D032fbDFeC9b389C1603E5',
  },
  [PoolLabels.FRAX_USDV]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x3cc8c886575968642Cab9F430261c81C5b044d4b',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.fUSDC]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x956454C7BE9318863297309183C79b793D370401',
  },
  [PoolLabels.FRAX_sFRAX_USDC]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xaBF19eAdb08693278FdbAD35Cb4E3c1D6484c8Bb',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.wstETH]: {
    name: 'Lido Pool',
    filterGroup: PoolFilterGroup.LSD,
    address: '0xe14302040c0A1eb6fB5A4A79EfA46D60029358d9',
  },
  [PoolLabels.qWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0x12fa5ab079cff564d599466d39715d35d90af978',
  },
  [PoolLabels.mWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0x90ecddec4e4116e30769a4e1ea52c319aca338b6',
  },
  [PoolLabels.wmxWOM]: {
    filterGroup: PoolFilterGroup.ECO,
    address: '0xee9b42b40852a53c7361f527e638b485d49750cd',
  },
  [PoolLabels.ankrETH]: {
    filterGroup: PoolFilterGroup.LSD,
    address: '0xB9bdfE449Da096256Fe7954Ef61A18eE195Db77B',
  },
  [PoolLabels.Overnight]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xcf20fda54e37f3fb456930f02fb07fccf49e4849',
  },
  [PoolLabels.FRAX_MAI_USDplus]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x4a8686df475d4c44324210ffa3fc1dea705296e0',
    isShownInBoosterCalculator: true,
  },
  [PoolLabels.MIM]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x29eeb257a2a6ecde2984acedf80a1b687f18ec91',
  },
  [PoolLabels.BOB]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x917caf2b4d6040a9d67a5f8cefc4f89d1b214c1a',
  },
  [PoolLabels.jUSDC]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0xc7a6bA5F28993BaDb566007bD2E0CB253c431974',
    isShownInBoosterCalculator: true,
  },
}

import React from 'react'
import ProposedGaugeWeightTable from '../ProposedGaugeWeightTable'
import ProposedGaugeWeightChart from '../charts/ProposedGaugeWeightChart'
import GaugeVotingHeaderInfo from './GaugeVotingHeaderInfo'
import styles from './index.module.scss'
import UserVoteAllocationTable from './UserVoteAllocationTable'
import YourVoteAllocationBar from './YourVoteAllocationBar'
import clsx from 'clsx'
import UserVoteAllocationSectionOverlay from './UserVoteAllocationSectionOverlay'
import { UserVoteAllocationTableProvider } from './UserVoteAllocationTable/UserVoteAllocationTableContext'

function GaugeVotingPage() {
  return (
    <div className="mx-auto max-w-[1088px] p-0 max-md:mx-4">
      <GaugeVotingHeaderInfo />
      <div className={styles.section}>
        <ProposedGaugeWeightChart />
        <ProposedGaugeWeightTable />
      </div>
      <div className={clsx(styles.section, 'relative max-md:mb-20')}>
        <UserVoteAllocationTableProvider>
          <UserVoteAllocationTable />
        </UserVoteAllocationTableProvider>
        <YourVoteAllocationBar />
        <UserVoteAllocationSectionOverlay />
      </div>
    </div>
  )
}

export default GaugeVotingPage

import Image from 'next/image'
import { useMemo, useState } from 'react'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { PoolFilterGroup } from '../../constants/contract/pool/poolFilterGroup'
import { POOL_PAGE_COL_INFO } from '../../constants/infoData'
import PoolCardProvider from '../../context/PoolCardContext'
import arrowDown from '../../public/assets/icons/arrow-down.svg'
import arrowUpDown from '../../public/assets/icons/arrow-up-down.svg'
import arrowUp from '../../public/assets/icons/arrow-up-fill.svg'
import { PoolGroupWrapper } from '../PoolGroupWrapper'
import { Modal } from '../PoolInfoCard/modal'
import { TokenInfo } from '../PoolPage'
import Tooltip from '../Tooltip'
import TooltipMobileFullScreen from '../TooltipMobileFullScreen'

type Props = {
  tokensInfo: TokenInfo[]
  selectedPoolFilterGroup: PoolFilterGroup
  onClick?: (e: { key: string; name: string; decrease: boolean }) => void
}

export type GroupedAndSortTokenInfo = {
  [key in PoolLabels]: TokenInfo[]
}

export default function PoolList({ tokensInfo, selectedPoolFilterGroup }: Props) {
  const [isOpenToolTipMobile, setIsOpenToolTipMobile] = useState<boolean>(false)
  const [valueSort, setValueSort] = useState<{
    key: string
    name: string
    decrease: boolean
  }>({ key: '', name: '', decrease: false })

  const onCloseToolTipMobile = () => {
    setIsOpenToolTipMobile(false)
  }

  /**@todo sort by unStaked Deposit & staked Deposit  */
  const sortedTokens = useMemo(
    () =>
      valueSort && valueSort.key
        ? tokensInfo.sort((a: TokenInfo, b: TokenInfo) => {
            if (valueSort.decrease) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              return a[valueSort.key] > b[valueSort.key] ? -1 : 1
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return a[valueSort.key] < b[valueSort.key] ? -1 : 1
          })
        : tokensInfo,
    [tokensInfo, valueSort]
  )

  //Group Sorted Token by the poolLabels
  const groupedAndSortedTokens = useMemo(() => {
    return sortedTokens.reduce((acc, obj) => {
      const key = obj.poolLabel
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {} as GroupedAndSortTokenInfo)
  }, [sortedTokens])

  return (
    <PoolCardProvider value={{ selectedPoolFilterGroup }}>
      <>
        <div id="pool-list">
          <div className="hidden md:block">
            <ul
              className={`grid grid-cols-[32px,90px,1fr,115px,1fr,1fr,1fr,30px] gap-1 pl-4 lg:grid-cols-[32px,1fr,1fr,115px,,1fr,1fr,1fr,44px] lg:pl-10`}
            >
              <li className="justify-center"></li>
              {POOL_PAGE_COL_INFO.map(({ name, detail, link, key }) => (
                <li
                  key={key}
                  className="no-select my-2 flex cursor-pointer items-center justify-center text-wombatBrown1"
                  onClick={() => setValueSort({ key, name, decrease: !valueSort.decrease })}
                >
                  <Image
                    height={7}
                    width={7}
                    src={
                      valueSort.key == key && valueSort.name
                        ? valueSort.decrease
                          ? arrowDown
                          : arrowUp
                        : arrowUpDown
                    }
                    alt=""
                  />
                  <p className="ml-1 text-center font-Work text-[11px]">{name}</p>
                  {detail ? (
                    <div className="ml-1 w-3">
                      <Tooltip pointerEvent={true}>
                        {detail}
                        {link ? (
                          <a
                            target="_blank"
                            href={link}
                            rel="noreferrer"
                            className="ml-1 font-semibold hover:underline"
                          >
                            Learn More
                          </a>
                        ) : null}
                      </Tooltip>
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
          <div className="mb-3 flex w-full justify-between px-4 md:hidden">
            <TooltipMobileFullScreen isOpen={isOpenToolTipMobile} onClose={onCloseToolTipMobile}>
              {POOL_PAGE_COL_INFO.map(({ name, detail, link, key }) => {
                return detail ? (
                  <div className="mt-6 px-4" key={key}>
                    <p className="font-Work text-xs font-bold text-wombatBrown1">{name}</p>
                    <p className="mt-3 font-Work text-xs text-wombatPurple1">
                      {detail}
                      {link ? (
                        <a
                          target="_blank"
                          href={link}
                          rel="noreferrer"
                          className="ml-1 font-semibold hover:underline"
                        >
                          Learn More
                        </a>
                      ) : null}
                    </p>
                  </div>
                ) : null
              })}
              <div className="mt-20 text-center">
                <a
                  className="font-Work text-[10px] text-wombatPurple"
                  href="https://docs.wombat.exchange"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  https://docs.wombat.exchange
                </a>
              </div>
            </TooltipMobileFullScreen>
          </div>
          <div className="flex flex-col px-4 pb-12 text-white md:px-0 md:pb-6">
            {Object.entries(groupedAndSortedTokens).map(([poolLabel, tokensInfo]) => {
              return (
                <PoolGroupWrapper
                  key={poolLabel}
                  poolLabel={poolLabel as PoolLabels}
                  tokensInfo={tokensInfo}
                />
              )
            })}
          </div>
        </div>
      </>
      <Modal groupedAndSortedTokens={groupedAndSortedTokens} />
    </PoolCardProvider>
  )
}

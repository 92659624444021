import clsx from 'clsx'
import { uniqueId } from 'lodash'
import Image from 'next/image'
import React, { useRef } from 'react'
import { PlacesType, Tooltip } from 'react-tooltip'
import tooltip from '../../public/assets/icons/tooltip.svg'
import warningGreyIcon from '../../public/assets/icons/warning-icon-triangle-grey.svg'
import warningRedIcon from '../../public/assets/icons/warning-icon.svg'
import styles from './AppTooltip.module.scss'

type Props = {
  children?: React.ReactNode
  message?: React.ReactNode
  width?: string
  padding?: 'sm' | 'md'
  fullWidth?: boolean
  place?: PlacesType
  centerChildren?: boolean
  className?: string
  iconVariant?: 'warning' | 'alert'
}

const renderToolTipIcon = (iconVariant: string) => {
  if (iconVariant === 'warning') return <Image height={14} width={14} src={warningGreyIcon} />
  if (iconVariant === 'alert') return <Image height={12} width={12} src={warningRedIcon} />
  return <Image height={12} width={12} src={tooltip} />
}

/** @todo can replace Tooltip component. Currently, Tooltip component is not working on its parent with overflow set to hidden. */
function AppTooltip({
  children,
  message,
  width,
  padding = 'md',
  fullWidth,
  place = 'right',
  centerChildren,
  className,
  iconVariant,
}: Props) {
  const uuidRef = useRef(uniqueId('tooltip'))

  return (
    <>
      {children ? (
        <a
          data-tip
          data-for={uuidRef.current}
          className={clsx(
            'flex cursor-pointer items-center',
            centerChildren && 'justify-center',
            className
          )}
        >
          {children}
        </a>
      ) : (
        <a
          data-tip
          data-for={uuidRef.current}
          className={clsx('ml-1 w-[14px] cursor-pointer', className)}
        >
          {iconVariant && renderToolTipIcon(iconVariant)}
        </a>
      )}
      <Tooltip
        id={uuidRef.current}
        aria-haspopup="true"
        arrowColor="#695B51"
        place={place}
        className={styles.AppTooltip}
        delayHide={0}
      >
        <div
          className={clsx(
            'flex w-52 whitespace-pre-wrap bg-wombatBrown1 text-left font-Work text-sm font-normal text-wombatCream'
          )}
          style={{
            width: fullWidth ? '100%' : width,
            padding: padding === 'md' ? '10px' : '4px',
          }}
        >
          {message}
        </div>
      </Tooltip>
    </>
  )
}

export default React.memo(AppTooltip)

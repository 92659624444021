import { prepareWriteContract, writeContract } from '@wagmi/core'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { POOLS } from '../constants/contract/pool'
import { TOKENS } from '../constants/contract'
import { BigNumber, ethers, utils } from 'ethers'
import { HexString } from '../interfaces/contract'
import { chainInfos } from '../constants/web3/chains'
import { strToWad, getDpFormat, nativeToWAD, wmul, wdiv } from '@hailstonelabs/big-number-utils'
import { readContract } from '@wagmi/core'
import { WORMHOLE_ADAPTOR_ABI } from '../constants/contract/abis/WormholeAdaptor'
import { CROSS_CHAIN_POOL_ABI } from '../constants/contract/abis/pool'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { isNonEmptyAddress, isTestnet } from '.'
import { CrossChainCase, RELAY_STATUS_ENDPOINT } from '../constants/crossChain'
import { getMinimumReceived } from './pool'
import { ChainId as WormholeChainId } from '@certusone/wormhole-sdk'
import {
  getSupportedChainIdByTokenAddress,
  tokenAddressTokenMap,
} from '../constants/contract/token'
import { CROSS_CHAIN_SWAP_GAS_LIMIT } from '../constants/common'

/** @todo put this util in Big number utils  */
/**
 * Ensure the string value is parasable with a specified number of decimals.
 * Then, it parses a string value into a BigNumber with the specified decimals.
 *
 * @param {string} value - The value to parse.
 * @param {number} [decimals=18] - The number of decimals (optional, default is 18).
 * @returns {BigNumber} The parsed BigNumber value.
 */
function safeParseUnits(value: string, decimals = 18): BigNumber {
  const safeParsedValue = utils.parseUnits(
    getDpFormat(value, decimals, 'off', false, false),
    decimals
  )
  return safeParsedValue
}

type IEstimateDeliveryFeeArgs = {
  fromChainId: SupportedChainId
  toChainId: SupportedChainId
  receiverValue: string
  gasLimit: string
  isRedeliveryFee?: boolean
}

interface SwapCreditForTokensQuotation {
  fromChainId: SupportedChainId
  toChainId: SupportedChainId
  toTokenSymbol: TokenSymbol
  creditAmount: string
  slippage: string
  receiverValue?: string
  gasLimit?: string
}

interface SwapCreditForTokensSameChain {
  chainId: SupportedChainId
  toTokenSymbol: TokenSymbol
  creditAmount: string
  minimumToAmount: string
  recipient: HexString
}

interface SwapCreditForTokensCrossChain {
  fromChainId: SupportedChainId
  toChainId: SupportedChainId
  toTokenSymbol: TokenSymbol
  creditAmount: string
  minimumToAmount: string
  receiverValue: string
  gasLimit: string
  recipient: string
  deliveryFee: string
}

export const estimateDeliveryFeeWad = async ({
  fromChainId,
  toChainId,
  receiverValue,
  gasLimit,
  isRedeliveryFee = false,
}: IEstimateDeliveryFeeArgs) => {
  const receiverValueWad = strToWad(receiverValue)
  const gasLimitNum = Number(gasLimit)
  const adaptorAddress = chainInfos[fromChainId].wormholeConfig?.adaptorAddress
  const wormholeToChainId = chainInfos[toChainId].wormholeConfig?.chainId
  if (!wormholeToChainId) throw new Error('No wormholeToChainId')
  if (!adaptorAddress) throw new Error('No adaptorAddress')
  if (isRedeliveryFee) {
    const [nativePriceQuoteWad] = await readContract({
      chainId: fromChainId,
      address: adaptorAddress,
      abi: WORMHOLE_ADAPTOR_ABI,
      functionName: 'estimateRedeliveryFee',
      args: [wormholeToChainId, receiverValueWad, gasLimitNum],
    })
    return nativePriceQuoteWad
  }
  const [nativePriceQuoteWad] = await readContract({
    chainId: fromChainId,
    address: adaptorAddress,
    abi: WORMHOLE_ADAPTOR_ABI,
    functionName: 'estimateDeliveryFee',
    args: [wormholeToChainId, receiverValueWad, gasLimitNum],
  })
  return nativePriceQuoteWad
}

type ICrossChainSwapArgs = {
  fromTokenSymbol: TokenSymbol
  toTokenSymbol: TokenSymbol
  fromChainId: SupportedChainId
  toChainId: SupportedChainId
  fromAmount: string
  minimumCreditAmount: string
  minimumToAmount: string
  recipient: HexString
  receiverValue: string
  gasLimit: string
  deliveryFeeWad: BigNumber
}
export const crossChainSwap = async ({
  fromTokenSymbol,
  toTokenSymbol,
  toChainId,
  fromChainId,
  fromAmount,
  minimumCreditAmount,
  minimumToAmount,
  recipient,
  receiverValue,
  gasLimit,
  deliveryFeeWad,
}: ICrossChainSwapArgs) => {
  const pool = POOLS[fromChainId][PoolLabels.CROSS_CHAIN]
  const poolAddress = pool?.address
  const fromToken = TOKENS[fromChainId][fromTokenSymbol]
  const toToken = TOKENS[toChainId][toTokenSymbol]
  if (!toToken) throw new Error('no toToken')
  if (!fromToken) throw new Error('no fromToken')

  const { address: fromTokenAddress, decimals: fromTokenDecimals } = fromToken
  const { address: toTokenAddress, decimals: toTokenDecimals } = toToken
  const wormholeToChainId = chainInfos[toChainId].wormholeConfig?.chainId
  if (!poolAddress) throw new Error('No poolAddress')
  if (!wormholeToChainId) throw new Error('No wormholeChainId')

  const fromAmountBN = safeParseUnits(fromAmount, fromTokenDecimals)
  // assume credit amount has 18 decimals
  const minimumCreditAmountBN = safeParseUnits(minimumCreditAmount, 18)
  const minimumToAmountBN = safeParseUnits(minimumToAmount, toTokenDecimals)

  if (!isNonEmptyAddress(recipient)) {
    throw new Error('do not send tokens to empty address!')
  }
  const config = await prepareWriteContract({
    chainId: fromChainId,
    address: poolAddress,
    abi: CROSS_CHAIN_POOL_ABI,
    functionName: 'swapTokensForTokensCrossChain',
    args: [
      fromTokenAddress,
      toTokenAddress,
      BigNumber.from(wormholeToChainId),
      fromAmountBN,
      minimumCreditAmountBN,
      minimumToAmountBN,
      recipient,
      strToWad(receiverValue),
      BigNumber.from(gasLimit),
    ],
    overrides: {
      value: deliveryFeeWad,
    },
  })

  const txn = await writeContract(config)
  return txn
}

type IGetFeeAndTokenReceiveArgs = {
  fromTokenSymbol: TokenSymbol
  fromChainId: SupportedChainId
  fromTokenAmount: string
  toChainId: SupportedChainId
  toTokenSymbol: TokenSymbol
  tokenPrices: {
    [id in TokenSymbol]?: string
  }
}
export const getCrossChainSwapFeeAndTokenReceive = async ({
  fromTokenSymbol,
  fromChainId,
  fromTokenAmount,
  toTokenSymbol,
  toChainId,
  tokenPrices,
}: IGetFeeAndTokenReceiveArgs) => {
  const poolFromChainAddress = POOLS[fromChainId][PoolLabels.CROSS_CHAIN]?.address
  const poolToChainAddress = POOLS[toChainId][PoolLabels.CROSS_CHAIN]?.address
  const fromToken = TOKENS[fromChainId][fromTokenSymbol]

  const toToken = TOKENS[toChainId][toTokenSymbol]
  if (!poolFromChainAddress) throw new Error('no poolFromChain Address')
  if (!poolToChainAddress) throw new Error('no poolToChain Address')
  if (!toToken) throw new Error('no toToken')
  if (!fromToken) throw new Error('no fromToken')
  const fromTokenDecimals = fromToken.decimals
  const fromTokenAddress = fromToken.address
  const toTokenAddress = toToken.address
  const toTokenDecimals = toToken.decimals
  const fromTokenAmountBN = utils.parseUnits(fromTokenAmount, fromTokenDecimals)
  const [creditAmountWad, feeInFromTokenWad] = await readContract({
    chainId: fromChainId,
    address: poolFromChainAddress,
    abi: CROSS_CHAIN_POOL_ABI,
    functionName: 'quoteSwapTokensForCredit',
    args: [fromTokenAddress, fromTokenAmountBN],
  })

  const [toTokenAmountBN, feeInToTokenBN] = await readContract({
    chainId: toChainId,
    address: poolToChainAddress,
    abi: CROSS_CHAIN_POOL_ABI,
    functionName: 'quoteSwapCreditForTokens',
    args: [toTokenAddress, creditAmountWad],
  })

  /** Evaluate Total Fee in terms of fromToken  */
  const feeInFromTokenInUsdWad = wmul(feeInFromTokenWad, strToWad(tokenPrices[fromToken.symbol]))
  const feeInToTokenWad = nativeToWAD(feeInToTokenBN, toTokenDecimals)
  const feeInToTokenInUsdWad = wmul(feeInToTokenWad, strToWad(tokenPrices[toToken.symbol]))
  const totalFeeInUsdWad = feeInFromTokenInUsdWad.add(feeInToTokenInUsdWad)
  const totalFeeInFromTokenWad = wdiv(totalFeeInUsdWad, strToWad(tokenPrices[fromToken.symbol]))

  return {
    totalFeeInFromToken: utils.formatEther(totalFeeInFromTokenWad),
    creditAmount: utils.formatEther(creditAmountWad),
    toTokenAmount: utils.formatUnits(toTokenAmountBN, toTokenDecimals),
  }
}
// interface Metadata {
//   attempts: number
//   maxAttempts: number
//   didError: boolean
//   errorName: string | null
//   didSubmitTransaction: boolean
//   executionStartTime: number
//   executionEndTime: number
//   emitterChain: number
//   emitterAddress: string
//   sequence: string
//   rawVaaHex: string
//   rawVaaPayloadHex: string
//   payloadType: number
//   didParse: boolean
//   instructions: object[]
//   specifiedDeliveryProvider: string | null
//   didMatchDeliveryProvider: boolean
//   redeliveryRecord: object | null
//   deliveryRecord: object[]
//   fatalStackTrace: string | null
// }

// export interface IRelayStatus {
//   _id: string
//   emitterChain: number
//   emitterAddress: string
//   sequence: string
//   vaa: string
//   fromTxHash: string
//   status: string
//   addedTimes: number
//   attempts: number
//   maxAttempts: number
//   receivedAt: string
//   completedAt: string
//   failedAt: string | null
//   errorMessage: string | null
//   toTxHash: string
//   metadata: Metadata
// }

export interface IRelayStatusData {
  fromTxHash: string
  toTxHash: string
  maxAttempts: number
  instructions: object
  delivery: object
}

export interface IRelayStatus {
  id: string
  data: IRelayStatusData
  status: string
  receivedAt: string
  completedAt: string
  failedAt: string | null
}

export async function getReceipt(
  readonlyProvider: ethers.providers.JsonRpcProvider,
  fromTxhash: string
) {
  let receipt = null
  let count = 0
  while (receipt === null) {
    receipt = await readonlyProvider.getTransactionReceipt(fromTxhash)

    if (receipt !== null) {
      break
    }

    await new Promise((resolve) => setTimeout(resolve, 2000))
    count += 1
    if (count === 10) {
      throw new Error('No receipt')
    }
  }

  return receipt
}

export const getSequence = async (fromChainId: SupportedChainId, fromTxhash: string) => {
  const chainInfo = chainInfos[fromChainId]
  const emitterChainId = chainInfo.wormholeConfig?.chainId
  const emitterAddress = chainInfo.wormholeConfig?.relayerAddress
  const adaptorAddress = chainInfo.wormholeConfig?.adaptorAddress
  const coreAddress = chainInfo.wormholeConfig?.coreBridgeAddress
  const readonlyProvider = chainInfo.getArchiveNodeProvider?.()
  if (!emitterChainId) throw new Error(`No emitterChainId: ${fromChainId}`)
  if (!emitterAddress) throw new Error(`No emitterAddress: ${fromChainId}`)
  if (!adaptorAddress) throw new Error(`No adaptorAddress: ${fromChainId}`)
  if (!coreAddress) throw new Error(`No coreAddress: ${fromChainId}`)
  if (!readonlyProvider) throw new Error(`No readonlyProvider: ${fromChainId}`)
  const receipt = await getReceipt(readonlyProvider, fromTxhash)

  const adaptorLogs = receipt.logs.filter(
    (log) => log.address.toLowerCase() === adaptorAddress.toLowerCase()
  )
  const log = adaptorLogs[0]
  const decodedData = ethers.utils.defaultAbiCoder.decode(
    // BridgeCreditAndSwapForTokens event: address toToken, uint256 toChain, uint256 fromAmount, uint256 minimumToAmount, address receiver, uint256 sequence
    ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256'],
    log.data
  )
  const sequence = (decodedData[5] as BigNumber).toString()
  return sequence
}

export const getWormholeStatusEndPoint = (
  emitterChainId: WormholeChainId,
  emitterAddress: `0x${string}`,
  sequence: string,
  isTestnet: boolean
) => {
  return `${
    isTestnet ? RELAY_STATUS_ENDPOINT.testnet : RELAY_STATUS_ENDPOINT.mainnet
  }/${emitterChainId}/000000000000000000000000${emitterAddress
    .toLowerCase()
    .replace('0x', '')}/${sequence}`
}
export const getRelayStatusAndEndpoint = async (
  fromChainId: SupportedChainId,
  fromTxhash: string
) => {
  const chainInfo = chainInfos[fromChainId]
  const emitterChainId = chainInfo.wormholeConfig?.chainId
  const emitterAddress = chainInfo.wormholeConfig?.relayerAddress
  const adaptorAddress = chainInfo.wormholeConfig?.adaptorAddress
  if (!emitterChainId) throw new Error(`No emitterChainId: ${fromChainId}`)
  if (!emitterAddress) throw new Error(`No emitterAddress: ${fromChainId}`)
  if (!adaptorAddress) throw new Error(`No adaptorAddress: ${fromChainId}`)
  const sequence = await getSequence(fromChainId, fromTxhash)
  const endpoint = getWormholeStatusEndPoint(
    emitterChainId,
    emitterAddress,
    sequence,
    isTestnet(fromChainId)
  )

  try {
    const result = await (await fetch(endpoint)).json()
    return { sequence, endpoint, status: result as IRelayStatus | undefined }
  } catch (error) {
    console.error(error)
    return { sequence, endpoint, status: undefined }
  }
}

export const getCaseAndMetaData = async (
  relayStatus: IRelayStatus | { status: string; data: { toTxHash: string } } | undefined,
  toChainId: SupportedChainId
): Promise<
  | {
      case: CrossChainCase.RECEIVED_TOKEN
      toTxhash: string
    }
  | {
      case: CrossChainCase.PENDING | null | CrossChainCase.NEED_REDELIVERY
    }
  | {
      case: CrossChainCase.RECEIVED_CREDIT
      creditAmount: string
      toTxhash: string
    }
> => {
  if (relayStatus === undefined) {
    return {
      case: CrossChainCase.PENDING,
    }
  }
  const chainInfo = chainInfos[toChainId]
  if (relayStatus.data.toTxHash && relayStatus.status === 'redeemed') {
    // if the txn emit 'MintCredit' event or 'SwapCreditForTokens' event. If it is 'MintCredit', this txn is case 3
    const readonlyProvider = chainInfo.getArchiveNodeProvider?.()
    if (!readonlyProvider) throw new Error(`No readonlyProvider: ${toChainId}`)
    const receipt = await readonlyProvider.getTransactionReceipt(relayStatus.data.toTxHash)
    const iface = new ethers.utils.Interface(CROSS_CHAIN_POOL_ABI)
    for (let index = 0; index < receipt.logs.length; index++) {
      const log = receipt.logs[index]
      try {
        const name = iface.parseLog(log).name
        if (name === 'SwapCreditForTokens') {
          return { case: CrossChainCase.RECEIVED_TOKEN, toTxhash: relayStatus.data.toTxHash }
        } else if (name === 'MintCredit') {
          const decodedData = ethers.utils.defaultAbiCoder.decode(['uint256'], log.data)
          const creditAmount = utils.formatEther(decodedData[0] as BigNumber)
          return {
            case: CrossChainCase.RECEIVED_CREDIT,
            creditAmount,
            toTxhash: relayStatus.data.toTxHash,
          }
        }
      } catch (error) {
        console.debug(error)
        continue
      }
    }
  } else if (relayStatus.status === 'waiting') {
    return { case: CrossChainCase.PENDING }
  } else if (relayStatus.status === 'failed') {
    return { case: CrossChainCase.NEED_REDELIVERY }
  }
  return { case: null }
}
/**

Retrieves a quotation for swapping credit for tokens.
* @param {object} options - The options for the quotation.
* @param {number} options.fromChainId - The ID of the chain where the credit is coming from.
* @param {number} options.toChainId - The ID of the chain where the tokens will be received.
* @param {string} options.toTokenSymbol - The symbol of the token to be received.
* @param {string} options.creditAmount - The amount of credit to be swapped.
* @param {number} options.slippage - The slippage percentage.
* @param {string} options.receiverValue - The value of the receiver.
* @param {number} options.gasLimit - The gas limit for the transaction.
* @returns {Promise} A promise that resolves to an object containing the quotation details.
* @throws {Error} Throws an error if any required parameter is missing.
*/
export const getSwapCreditForTokenQuotation = async ({
  fromChainId,
  toChainId,
  toTokenSymbol,
  creditAmount,
  slippage,
  receiverValue,
  gasLimit,
}: SwapCreditForTokensQuotation) => {
  let deliveryFeeWad = strToWad('0')
  const poolToChainAddress = POOLS[toChainId][PoolLabels.CROSS_CHAIN]?.address
  const toToken = TOKENS[toChainId][toTokenSymbol]
  if (!poolToChainAddress) throw new Error('no poolToChainAddress')
  if (!toToken) throw new Error('no token')
  const creditAmountWad = strToWad(creditAmount)
  const toTokenAddress = toToken.address
  const toTokenDecimals = toToken.decimals

  const [toTokenAmountBN, toTokenFeeBN] = await readContract({
    chainId: toChainId,
    address: poolToChainAddress,
    abi: CROSS_CHAIN_POOL_ABI,
    functionName: 'quoteSwapCreditForTokens',
    args: [toTokenAddress, creditAmountWad],
  })

  const toTokenAmount = utils.formatUnits(toTokenAmountBN, toTokenDecimals)
  const minimumReceivedWad = getMinimumReceived(strToWad(toTokenAmount), slippage)
  const toTokenFee = utils.formatUnits(toTokenFeeBN, toTokenDecimals)

  /** Evaluate the delivery fee for cross chain swap */
  if (fromChainId !== toChainId && receiverValue && gasLimit) {
    deliveryFeeWad = await estimateDeliveryFeeWad({
      fromChainId,
      toChainId,
      receiverValue,
      gasLimit,
    })
  }

  return {
    minimumReceived: utils.formatEther(minimumReceivedWad),
    toTokenAmount,
    toTokenFee,
    deliveryFee: utils.formatEther(deliveryFeeWad),
  }
}

/**
 * Swaps credit for tokens on the same chain.
 *
 * @async
 * @param {Object} options - The options for the swap.
 * @param {number} options.chainId - The ID of the chain.
 * @param {string} options.toTokenSymbol - The symbol of the toToken.
 * @param {string} options.creditAmount - The amount of credit to swap.
 * @param {string} options.minimumToAmount - The minimum amount of tokens to receive.
 * @param {string} options.recipient - The recipient's address for the tokens.
 * @returns {Promise<Object>} The transaction object of the swap.
 * @throws {Error} If the token is not found, pool address is not found, or recipient address is empty.
 */
export const swapCreditForTokensSameChain = async ({
  chainId,
  toTokenSymbol,
  creditAmount,
  minimumToAmount,
  recipient,
}: SwapCreditForTokensSameChain) => {
  const pool = POOLS[chainId][PoolLabels.CROSS_CHAIN]
  const poolAddress = pool?.address
  const toToken = TOKENS[chainId][toTokenSymbol]
  if (!toToken) throw new Error('no toToken')
  if (!poolAddress) throw new Error('No poolAddress')
  if (!isNonEmptyAddress(recipient)) {
    throw new Error('do not send tokens to empty address!')
  }
  const fromAmountWad = strToWad(creditAmount)
  const minimumToAmountBN = utils.parseUnits(minimumToAmount, toToken.decimals)

  const config = await prepareWriteContract({
    chainId,
    address: poolAddress,
    abi: CROSS_CHAIN_POOL_ABI,
    functionName: 'swapCreditForTokens',
    args: [toToken.address, fromAmountWad, minimumToAmountBN, recipient],
  })

  const txn = await writeContract(config)
  return txn
}

type IRedeliveryArgs = {
  fromChainId: SupportedChainId
  toChainId: SupportedChainId
  sequence: number
  receiverValue: string
  gasLimit: string
  deliveryFeeWad: BigNumber
}
export async function redelivery({
  toChainId,
  fromChainId,
  sequence,
  receiverValue,
  gasLimit,
  deliveryFeeWad,
}: IRedeliveryArgs) {
  const wormholeToChainId = chainInfos[toChainId].wormholeConfig?.chainId
  const wormholeFromChainId = chainInfos[fromChainId].wormholeConfig?.chainId
  const adaptorAddress = chainInfos[fromChainId].wormholeConfig?.adaptorAddress
  if (!wormholeToChainId) throw new Error('No wormholeToChainId')
  if (!wormholeFromChainId) throw new Error('No wormholeFromChainId')
  if (!adaptorAddress) throw new Error('No adaptorAddress')
  const config = await prepareWriteContract({
    chainId: fromChainId,
    address: adaptorAddress,
    abi: WORMHOLE_ADAPTOR_ABI,
    functionName: 'requestResend',
    args: [
      wormholeFromChainId,
      BigNumber.from(sequence),
      wormholeToChainId,
      strToWad(receiverValue),
      BigNumber.from(gasLimit),
    ],
    overrides: {
      value: deliveryFeeWad,
    },
  })

  const { hash } = await writeContract(config)
  return hash
}
/**

Swaps credit for tokens across different chains.
* @param {object} options - The options for the swap.
* @param {number} options.fromChainId - The ID of the chain where the credit is coming from.
* @param {number} options.toChainId - The ID of the chain where the tokens will be received.
* @param {string} options.toTokenSymbol - The symbol of the token to be received.
* @param {string} options.creditAmount - The amount of credit to be swapped.
* @param {string} options.minimumToAmount - The minimum amount of tokens to be received.
* @param {string} options.recipient - The address of the recipient of the tokens.
* @param {string} options.receiverValue - The value of the receiver.
* @param {number} options.gasLimit - The gas limit for the transaction.
* @param {string} options.deliveryFee - The delivery fee for the transaction.
* @returns {Promise} A promise that resolves to the transaction object.
* @throws {Error} Throws an error if any required parameter is missing.
*/
export const swapCreditForTokensCrossChain = async ({
  fromChainId,
  toChainId,
  toTokenSymbol,
  creditAmount,
  minimumToAmount,
  recipient,
  receiverValue,
  gasLimit,
  deliveryFee,
}: SwapCreditForTokensCrossChain) => {
  const poolFromChainAddress = POOLS[fromChainId][PoolLabels.CROSS_CHAIN]?.address
  const toToken = TOKENS[toChainId][toTokenSymbol]
  const wormholeToChainId = chainInfos[toChainId].wormholeConfig?.chainId

  if (!toToken) throw new Error('no toToken')
  if (!poolFromChainAddress) throw new Error('No poolAddress')
  if (!wormholeToChainId) throw new Error('No wormholeToChainId')
  if (!isNonEmptyAddress(recipient)) {
    throw new Error('do not send tokens to empty address!')
  }

  const creditAmountWad = utils.parseEther(creditAmount)
  const minimumToAmountBN = utils.parseUnits(minimumToAmount, toToken.decimals)
  const deliveryFeeWad = strToWad(deliveryFee)

  const config = await prepareWriteContract({
    chainId: fromChainId,
    address: poolFromChainAddress,
    abi: CROSS_CHAIN_POOL_ABI,
    functionName: 'swapCreditForTokensCrossChain',
    args: [
      toToken.address,
      BigNumber.from(wormholeToChainId),
      creditAmountWad,
      minimumToAmountBN,
      recipient as HexString,
      strToWad(receiverValue),
      BigNumber.from(gasLimit),
    ],
    overrides: {
      value: deliveryFeeWad,
    },
  })

  const txn = await writeContract(config)
  return txn
}

export const getDecodedSwapTokensForTokensCrossChainInputData = async (
  txnInputData: string,
  fromChainId: SupportedChainId,
  tokenPrices: {
    [id in TokenSymbol]?: string
  }
) => {
  /** Decode Txn Input Data */
  // swapTokensForTokensCrossChain(address fromToken,address toToken,
  // uint256 toChain,uint256 fromAmount,uint256 minimumCreditAmount,uint256 minimumToAmount,
  // address receiver,uint256 receiverValue,uint256 deliveryGasLimit)
  // hex slice example: 0xa312ff230000000000...00030d40 -> 12ff230000000000...00030d40
  const decodedData = ethers.utils.defaultAbiCoder.decode(
    [
      'address',
      'address',
      'uint256',
      'uint256',
      'uint256',
      'uint256',
      'address',
      'uint256',
      'uint256',
    ],
    ethers.utils.hexDataSlice(txnInputData, 4)
  )

  const fromTokenAddress = decodedData[0]
  const toTokenAddress = decodedData[1]
  const toWormholeChainId = +decodedData[2].toString() as WormholeChainId
  const toChainId = getSupportedChainIdByTokenAddress(toTokenAddress, toWormholeChainId)

  if (!toChainId) throw new Error('no toChainId')

  const toToken = tokenAddressTokenMap[toChainId]?.[toTokenAddress.toLowerCase()]
  const fromToken = tokenAddressTokenMap[fromChainId]?.[fromTokenAddress.toLowerCase()]

  if (!toToken) throw new Error('no to token')
  if (!fromToken) throw new Error('no from token')

  const { symbol: toTokenSymbol, decimals: toTokenDecimals } = toToken
  const { symbol: fromTokenSymbol, decimals: fromTokenDecimals } = fromToken

  const fromTokenAmount = utils.formatUnits(decodedData[3], fromTokenDecimals)
  const minimumCreditAmount = utils.formatEther(decodedData[4])
  const minimumToAmount = utils.formatUnits(decodedData[5], toTokenDecimals)

  const receiverValue = utils.formatEther(decodedData[7])
  const deliveryGasLimit = utils.formatEther(decodedData[8])

  // Do quotation with Decoded input data
  const targetQuote = await getCrossChainSwapFeeAndTokenReceive({
    fromTokenSymbol,
    fromChainId,
    fromTokenAmount,
    toTokenSymbol,
    toChainId,
    tokenPrices,
  })

  const toTokenAmount = targetQuote.toTokenAmount

  return {
    fromTokenSymbol,
    toTokenSymbol,
    toChainId,
    fromTokenAmount,
    toTokenAmount,
    minimumCreditAmount,
    minimumToAmount,
    receiverValue,
    deliveryGasLimit,
  }
}

export const getDecodedSwapCreditForTokensCrossChainInputData = async (
  txnInputData: string,
  fromChainId: SupportedChainId,
  slippage: string
) => {
  /** Decode Txn Input Data */
  // swapCreditForTokensCrossChain(address toToken,uint256 toChain,uint256 fromAmount,uint256 minimumToAmount,
  // address receiver,uint256 receiverValue,uint256 deliveryGasLimit)
  // hex slice example: 0xa312ff230000000000...00030d40 -> 12ff230000000000...00030d40
  const decodedData = ethers.utils.defaultAbiCoder.decode(
    ['address', 'uint256', 'uint256', 'uint256', 'address', 'uint256', 'uint256'],
    ethers.utils.hexDataSlice(txnInputData, 4)
  )
  const toTokenAddress = decodedData[0]
  const toWormholeChainId = +decodedData[1].toString() as WormholeChainId
  const toChainId = getSupportedChainIdByTokenAddress(toTokenAddress, toWormholeChainId)

  if (!toChainId) throw new Error('no toChainId')

  const toToken = tokenAddressTokenMap[toChainId]?.[toTokenAddress.toLowerCase()]

  if (!toToken) throw new Error('no to token')

  const { symbol: toTokenSymbol, decimals: toTokenDecimals } = toToken

  const fromCreditAmount = utils.formatEther(decodedData[2])
  const minimumToAmount = utils.formatUnits(decodedData[3], toTokenDecimals)
  const receiverValue = utils.formatEther(decodedData[5])
  const deliveryGasLimit = utils.formatEther(decodedData[6])

  // Do quotation with Decoded input data
  const targetQuote = await getSwapCreditForTokenQuotation({
    fromChainId,
    toChainId,
    toTokenSymbol,
    creditAmount: fromCreditAmount,
    slippage,
    receiverValue,
    gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
  })

  const toTokenAmount = targetQuote.toTokenAmount

  return {
    toTokenSymbol,
    toChainId,
    fromCreditAmount,
    toTokenAmount,
    minimumToAmount,
    receiverValue,
    deliveryGasLimit,
  }
}

import { useAppSelector } from '../hooks'
import {
  selectAssetData,
  selectBoostedPoolRewarderData,
  selectBribeConfigData,
  selectBribeInfoData,
  selectMasterWombatData,
  selectMasterWombatWithAccountRewards,
  selectPoolData,
  selectPoolRewarderData,
  selectTokenData,
  selectUniswapV3PoolData,
  selectVewomData,
  selectVotingData,
} from './selectors'

export const useTokenData = () => {
  return useAppSelector(selectTokenData)
}

export const usePoolData = () => {
  return useAppSelector(selectPoolData)
}

export const useVotingData = () => {
  return useAppSelector(selectVotingData)
}

export const useMasterWombatData = () => {
  return useAppSelector(selectMasterWombatData)
}

export const useMasterWombatDataWithAccountPoolRewards = () => {
  return useAppSelector(selectMasterWombatWithAccountRewards)
}

export const useVewomData = () => {
  return useAppSelector(selectVewomData)
}

export const useAssetData = () => {
  return useAppSelector(selectAssetData)
}

export const useUniV3PoolData = () => {
  return useAppSelector(selectUniswapV3PoolData)
}

export const useBribeConfigData = () => {
  return useAppSelector(selectBribeConfigData)
}

export const useBribeInfoData = () => {
  return useAppSelector(selectBribeInfoData)
}

export const usePoolRewarderData = () => {
  return useAppSelector(selectPoolRewarderData)
}

export const useBoostedPoolRewarderData = () => {
  return useAppSelector(selectBoostedPoolRewarderData)
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { WormHoleState } from '../types'

const getWormholeStatus = createAsyncThunk<Pick<WormHoleState, 'health'>, undefined>(
  'wormhole/getWormholeStatus',
  async () => {
    const res = await fetch('https://api.wormholescan.io/api/v1/health')
    const data = await res.json()
    console.log(data, 'data')
    return {
      health: data.status === 'OK',
    }
  }
)

export default getWormholeStatus

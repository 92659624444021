import { MasterWombatId } from '../constants/contract/masterWombat'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'

export enum ModalId {
  UNSET = 'UNSET',
  TRANSACTION_SUBMITTED = 'TRANSACTION_SUBMITTED',
  TRANSACTION_FAILED = 'TRANSACTION_FAILED',
  VOTE_WAIT_FOR_CONFIRMATION = 'VOTE_WAIT_FOR_CONFIRMATION',
  CLAIM_BRIBE_WAIT_FOR_CONFIRMATION = 'CLAIM_BRIBE_WAIT_FOR_CONFIRMATION',
  MIGRATION_WAIT_FOR_CONFIRMATION = 'MIGRATION_WAIT_FOR_CONFIRMATION',
  BRIDGE_TRANSFER_WAIT_FOR_CONFIRMATION = 'BRIDGE_TRANSFER_WAIT_FOR_CONFIRMATION',
  BRIDGE_TRANSFER_REDEEM_WAIT_FOR_CONFIRMATION = 'BRIDGE_TRANSFER_REDEEM_WAIT_FOR_CONFIRMATION',
  BRIDGE_REDEEM_REDEEM_WAIT_FOR_CONFIRMATION = 'BRIDGE_REDEEM_REDEEM_WAIT_FOR_CONFIRMATION',
  BRIDGE_REDEEM_COMPLETED = 'BRIDGE_REDEEM_COMPLETED',

  // Self service bribe
  TOP_UP_BRIBE = 'TOP_UP_BRIBE',
  UPDATE_BRIBE_EMISSION_RATE = 'UPDATE_BRIBE_EMISSION_RATE',
  // Swap
  SWAP_SUBMITTED = 'SWAP_SUBMITTED',
  SWAP_WAIT_FOR_CONFIRMATION = ' SWAP_WAIT_FOR_CONFIRMATION',
  SWAP_FAILED = 'SWAP_FAILED',
  // Crosschain swap
  SWAP_CREDIT = 'SWAP_CREDIT',
  SWAP_CREDIT_SETTING = 'SWAP_CREDIT_SETTING',
  SELECT_SOURCE_CHAIN = 'SELECT_SOURCE_CHAIN',
  SELECT_TARGET_CHAIN = 'SELECT_TARGET_CHAIN',
}

interface BaseModalState {
  currentModalId: ModalId
  payload?: unknown
}

// put all common payload here
type DefaultPayload = {
  transactionHashes?: string[]
  isTxnPending?: boolean
}

// when a modal has its own modal state, we need to put its id into this type.
type ModalIdsToExclude =
  | ModalId.MIGRATION_WAIT_FOR_CONFIRMATION
  | ModalId.TOP_UP_BRIBE
  | ModalId.UPDATE_BRIBE_EMISSION_RATE
interface DefaultModalState extends BaseModalState {
  currentModalId: Exclude<ModalId, ModalIdsToExclude>
  payload?: DefaultPayload
}

interface MigrationModalState extends BaseModalState {
  currentModalId: ModalId.MIGRATION_WAIT_FOR_CONFIRMATION
  payload: {
    masterWombatId: MasterWombatId
  } & DefaultPayload
}

interface SelfServiceBribeModalState extends BaseModalState {
  currentModalId: ModalId.TOP_UP_BRIBE | ModalId.UPDATE_BRIBE_EMISSION_RATE
  payload: {
    poolSymbol: PoolLabels
    assetSymbol: TokenSymbol
    rewardSymbol: TokenSymbol
  } & DefaultPayload
}

export type ModalState = DefaultModalState | MigrationModalState | SelfServiceBribeModalState

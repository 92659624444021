import { ASSETS } from '../../../constants/contract/asset'
import { Asset } from '../../../constants/contract/asset/Asset'
import { poolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TokenSymbol, TokenSymbols } from '../../../constants/contract/token/TokenSymbols'
import { InfoSCType, RewardInfoSCType, BribeInfoOfEachPool } from './fetchVotingDataHelper'
import { TOKENS, tokenAddressTokenMap } from '../../../constants/contract/token'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'
import { POOLS } from '../../../constants/contract/pool'
import { Contract } from '../../../constants/contract/Contract'
import { VOTERS } from '../../../constants/contract'
import { BRIBE_V2_ABI } from '../../../constants/contract/abis/bribeV2'
import { BigNumber, utils } from 'ethers'
import { PoolLabelsTokenSymbolsGenericType } from '../../../interfaces/common'
import { ASSET_CONFIG } from '../../../constants/contract/asset/assetConfig'
import { isEmptyAddress } from '../../../utils'
import { HexString } from '../../../interfaces/contract'
import { AssetProperty } from '../../../store/Asset/types'
import { getInitialAssetProperty } from '../../../store/Asset/helpers'

interface RewardInfoSCTypeV2 extends RewardInfoSCType {
  distributedAmount: BigNumber
  claimedAmount: BigNumber
  lastRewardTimestamp: BigNumber
}

export interface BribeConfigDataWithoutAccountType {
  rewardTokens: AssetProperty<TokenSymbols[]>
}

export interface BribeInfoDataWithoutAccountType {
  rewardTokenPerSec: BribeInfoOfEachPool
  rewardTokenBalance: BribeInfoOfEachPool
  rewardTokenSurplus: BribeInfoOfEachPool
  rewardTokensRunoutTimestamps: PoolLabelsTokenSymbolsGenericType<
    { tokenSymbol: TokenSymbol; value: number }[]
  >
  // if a reward has an inactive emission, it is either not started or has ended
  isEmissionActive: BribeInfoOfEachPool
}

export interface BribeConfigDataWithAccountType {
  userHasBribeRoleOfEachAsset: AssetProperty<boolean>
}

/** 1st multicall : fetch rewarder address */
export const fetchBribeContractAddresses = (
  chainId: SupportedChainId
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
} => {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const handleBribeContractAddresses = () => {
    const voter = VOTERS[chainId]
    if (voter) {
      for (let i = 0; i < poolLabels.length; i++) {
        const poolLabel = poolLabels[i]
        const pool = POOLS[chainId][poolLabel]
        if (!pool) continue
        const assets = pool.supportedAssetTokenSymbols.reduce((acc, assetTokenSymbol) => {
          const newAsset = ASSETS[chainId][poolLabel][assetTokenSymbol]
          return newAsset ? [...acc, newAsset] : acc
        }, [] as Asset[])
        for (let j = 0; j < assets.length; j++) {
          const asset = assets[j]
          const assetTokenAddress = asset.address
          contractCalls.push(voter.multicall('infos', [assetTokenAddress]))
          callbacks.push((value: InfoSCType) => {
            asset.setBribeRewarder({ ...asset.bribeRewarder, rewarderAddress: value.bribe })
          })
        }
      }
    }
  }

  handleBribeContractAddresses()

  return {
    contractCalls,
    callbacks,
  }
}

/** 2nd multicall : fetch bribeTokenSymbols from rewarder and whether user has role */
export const fetchBribeConfig = (
  chainId: SupportedChainId,
  account: string | null | undefined
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: {
    withoutAccount: BribeConfigDataWithoutAccountType
    withAccount: BribeConfigDataWithAccountType
  }
} => {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withoutAccount: BribeConfigDataWithoutAccountType
    withAccount: BribeConfigDataWithAccountType
  } = {
    withoutAccount: {
      rewardTokens: getInitialAssetProperty<TokenSymbols[]>(),
    },
    withAccount: {
      userHasBribeRoleOfEachAsset: getInitialAssetProperty<boolean>(),
    },
  }

  const handleBribeConfig = () => {
    const voter = VOTERS[chainId]
    if (voter) {
      for (let i = 0; i < poolLabels.length; i++) {
        const poolLabel = poolLabels[i]
        const pool = POOLS[chainId][poolLabel]
        if (!pool) continue
        const assets = pool.supportedAssetTokenSymbols.reduce((acc, assetTokenSymbol) => {
          const newAsset = ASSETS[chainId][poolLabel][assetTokenSymbol]
          return newAsset ? [...acc, newAsset] : acc
        }, [] as Asset[])
        for (let j = 0; j < assets.length; j++) {
          const asset = assets[j]
          const assetSymbol = asset.symbol
          const bribeRewarderAddress = asset.bribeRewarder?.rewarderAddress
          if (!bribeRewarderAddress || isEmptyAddress(bribeRewarderAddress)) continue
          const bribeRewarderV2 = new Contract<typeof BRIBE_V2_ABI>({
            address: bribeRewarderAddress,
            chainId,
            abi: BRIBE_V2_ABI,
          })

          const hasBribeV2 = ASSET_CONFIG[chainId][poolLabel][assetSymbol]?.hasBribeV2

          const bribeTokenSymbols: TokenSymbols[] = []
          if (!bribeRewarderV2 || !hasBribeV2) continue
          contractCalls.push(bribeRewarderV2.multicall('rewardTokens'))
          callbacks.push((bribeTokenAddresses: HexString[]) => {
            for (let k = 0; k < bribeTokenAddresses.length; k++) {
              const bribeTokenAddress = bribeTokenAddresses[k]
              if (!tokenAddressTokenMap[chainId]?.[bribeTokenAddress.toLowerCase()])
                console.error(`${bribeTokenAddress} not in tokenAddressTokenMap`)
              bribeTokenSymbols.push(
                tokenAddressTokenMap[chainId]?.[bribeTokenAddress.toLowerCase()]
                  ?.symbol as TokenSymbols
              )
            }
            asset.setBribeRewarder({
              ...asset.bribeRewarder,
              rewardTokenAddresses: bribeTokenAddresses,
              rewardTokenSymbols: bribeTokenSymbols,
            })
          })

          if (account) {
            const roleOperator = utils.keccak256(utils.toUtf8Bytes('operator'))
            contractCalls.push(bribeRewarderV2.multicall('hasRole', [roleOperator, account]))
            callbacks.push((hasRole: boolean) => {
              states.withAccount.userHasBribeRoleOfEachAsset[poolLabel] = {
                ...(states.withAccount.userHasBribeRoleOfEachAsset[poolLabel] || {}),
                [assetSymbol]: hasRole,
              }
            })
          }
        }
      }
    }
  }

  handleBribeConfig()

  return {
    contractCalls,
    callbacks,
    states,
  }
}

/** 3nd multicall : fetch bribeInfo (i.e. tokenPerSec, runoutTimestamp, balance) by corresponding bribeTokenSymbols */
export const fetchBribeInfo = (
  chainId: SupportedChainId
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: {
    withoutAccount: BribeInfoDataWithoutAccountType
  }
} => {
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withoutAccount: BribeInfoDataWithoutAccountType
  } = {
    withoutAccount: {
      rewardTokenPerSec: {},
      rewardTokenBalance: {},
      rewardTokenSurplus: {},
      rewardTokensRunoutTimestamps: {},
      isEmissionActive: {},
    },
  }

  const handleBribeInfo = () => {
    for (let i = 0; i < poolLabels.length; i++) {
      const poolLabel = poolLabels[i]
      const pool = POOLS[chainId][poolLabel]
      if (!pool) continue
      const assets = pool.supportedAssetTokenSymbols.reduce((acc, assetTokenSymbol) => {
        const newAsset = ASSETS[chainId][poolLabel][assetTokenSymbol]
        return newAsset ? [...acc, newAsset] : acc
      }, [] as Asset[])
      for (let j = 0; j < assets.length; j++) {
        const asset = assets[j]
        const assetSymbol = asset.symbol
        const bribeRewarderTokenSymbols = asset.bribeRewarder?.rewardTokenSymbols
        const bribeRewarderAddress = asset.bribeRewarder?.rewarderAddress
        const hasBribeV2 = ASSET_CONFIG[chainId][poolLabel][assetSymbol]?.hasBribeV2
        if (!bribeRewarderAddress || isEmptyAddress(bribeRewarderAddress) || !hasBribeV2) continue

        const bribeRewarderV2 = new Contract<typeof BRIBE_V2_ABI>({
          address: bribeRewarderAddress,
          chainId,
          abi: BRIBE_V2_ABI,
        })

        if (!bribeRewarderTokenSymbols) continue
        for (let bribeIndex = 0; bribeIndex < bribeRewarderTokenSymbols.length; bribeIndex++) {
          const bribeTokenSymbol = bribeRewarderTokenSymbols[bribeIndex]
          const bribeDecimals = TOKENS[chainId][bribeTokenSymbol]?.decimals
          if (!bribeDecimals) continue
          contractCalls.push(bribeRewarderV2.multicall('rewardInfo', [bribeIndex]))
          callbacks.push((value: RewardInfoSCTypeV2) => {
            states.withoutAccount.rewardTokenPerSec[poolLabel] = {
              ...(states.withoutAccount.rewardTokenPerSec[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokenPerSec[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: bribeTokenSymbol,
                  value: utils.formatUnits(value.tokenPerSec, bribeDecimals),
                },
              ],
            }
          })

          contractCalls.push(bribeRewarderV2.multicall('balances', []))
          callbacks.push((balance: BigNumber[]) => {
            states.withoutAccount.rewardTokenBalance[poolLabel] = {
              ...(states.withoutAccount.rewardTokenBalance[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokenBalance[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: bribeTokenSymbol,
                  value: utils.formatUnits(balance[bribeIndex], bribeDecimals),
                },
              ],
            }
          })

          contractCalls.push(bribeRewarderV2.multicall('rewardTokenSurpluses', []))
          callbacks.push((surplus: BigNumber[]) => {
            states.withoutAccount.rewardTokenSurplus[poolLabel] = {
              ...(states.withoutAccount.rewardTokenSurplus[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokenSurplus[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: bribeTokenSymbol,
                  value: utils.formatUnits(surplus[bribeIndex], bribeDecimals),
                },
              ],
            }
          })

          contractCalls.push(bribeRewarderV2.multicall('runoutTimestamps', []))
          callbacks.push((runoutTimestamps: number[]) => {
            states.withoutAccount.rewardTokensRunoutTimestamps[poolLabel] = {
              ...(states.withoutAccount.rewardTokensRunoutTimestamps[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.rewardTokensRunoutTimestamps[poolLabel]?.[assetSymbol] ||
                  []),
                {
                  tokenSymbol: bribeTokenSymbol,
                  value: runoutTimestamps[bribeIndex],
                },
              ],
            }
          })

          contractCalls.push(bribeRewarderV2.multicall('isEmissionActive', []))
          callbacks.push((isEmissionActive: boolean[]) => {
            states.withoutAccount.isEmissionActive[poolLabel] = {
              ...(states.withoutAccount.isEmissionActive[poolLabel] || {}),
              [assetSymbol]: [
                ...(states.withoutAccount.isEmissionActive[poolLabel]?.[assetSymbol] || []),
                {
                  tokenSymbol: bribeTokenSymbol,
                  value: isEmissionActive[bribeIndex],
                },
              ],
            }
          })
        }
      }
    }
  }

  handleBribeInfo()

  return {
    contractCalls,
    callbacks,
    states,
  }
}

import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const BASE_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0xC9bFC3eFeFe4CF96877009F75a61F5c1937e5d1a',
  },
  [PoolLabels.USDS]: {
    filterGroup: PoolFilterGroup.STABLES,
    address: '0x20d7B9Ed2c4E2DCC55F9B463975b21bBf2A6eCd1',
  },
}

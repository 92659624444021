type Props = {
  amount: number
  threshold: number
  showColor: boolean
}

function withinThreshold(x: number, y: number): boolean {
  return Math.abs(x) < y
}

export default function FormattedAmount({ amount, threshold, showColor }: Props) {
  const smallerThanThreshold = '<' + threshold.toString()

  return (
    <span className="relative sm:mx-auto sm:max-w-xl">
      <div className="group relative inline-block ">
        {amount == 0 ? (
          <span className="">0</span>
        ) : withinThreshold(amount, threshold) ? (
          <span className={`${showColor ? 'text-green-600' : ''}`}>{smallerThanThreshold}</span>
        ) : (
          <span className={`${showColor ? 'text-green-600' : ''}`}>{amount.toFixed(4)}</span>
        )}
        <div className="w-50 pointer-events-none absolute bottom-full -left-1/2 z-10 ml-14 rounded-lg bg-black py-2 px-3 text-center text-sm opacity-0 group-hover:opacity-100">
          <span className={`${showColor ? 'text-green-600' : 'text-white'}`}>
            {amount.toFixed(10)}
          </span>
        </div>
      </div>
    </span>
  )
}

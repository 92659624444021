import React from 'react'
import Image from 'next/image'
import CopyIcon from '../../../public/assets/icons/copy-icon.svg'
import useCopyToClipboard from '../../../hooks/useCopyToClipboard'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { shortenHashString } from '../../../utils'

interface TransferProgressBar {
  currentProgress: number
  hash?: string
}

export default function TransferProgressBar({ currentProgress, hash }: TransferProgressBar) {
  const copyToClipboard = useCopyToClipboard(hash)
  const { isMd } = useBreakpoints()
  const formattedCurrentProgress = Number(currentProgress.toFixed(2))

  return (
    <div className="relative">
      <div className="flex h-10 w-full bg-gray-200 bg-linearGradientApprove opacity-20"></div>
      <div
        className="absolute h-10 translate-y-[-40px] bg-linearGradientApprove opacity-100"
        style={{ width: `${formattedCurrentProgress > 100 ? 100 : formattedCurrentProgress}%` }}
      ></div>
      <div className="absolute h-10 w-full translate-y-[-40px]">
        <div className="flex h-full w-full items-center px-2">
          <div className="w-[95%] justify-center text-center text-[12px] text-white">
            {' '}
            {isMd && hash ? shortenHashString(hash, 10, hash.length) : hash}
          </div>
          <div className="w-[5%]  items-center justify-center text-center">
            <Image
              src={CopyIcon}
              height={15}
              width={15}
              onClick={copyToClipboard}
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

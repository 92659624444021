import { TokenMaps } from '../../constants/contract/token'
import { Token } from '../../constants/contract/token/Token'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { replaceString } from '../../utils/numberFormat'
import { decimalAndInteger } from '../../utils/regex'
import TokenSelector from '../../components/TokenSelector'
import { useSwapInputContext } from '../../context/SwapInputContext'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import ChainSelector, { ChainSelectorProps } from '../ChainSelector'
import SwapRecipientBox from './SwapRecipientBox'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useWeb3 } from '../../context/Web3Context'
import { useSwapContext } from '../../context/SwapContext'
import { useUserPreference } from '../../context/userPreferenceContext'
import { useWithAccountData } from '../../store/MultiChainMultiCallData/hooks'

type Props = {
  tokens: TokenMaps
  selectedToken: Token
  amount: string
  onSelectedTokenChanged: (token: Token) => void
  onAmountChanged: (value: string | null) => void
  disabled?: boolean
  hideToken?: Token | null
  disableTokens?: TokenSymbol[] | null
  showMaxBtn?: boolean
  chainId: SupportedChainId
  onSelectedChainIdChanged: () => void
  recipientAddress?: string
  setRecipientAddress?: Dispatch<SetStateAction<string>>
  chainSelectorProps?: Omit<ChainSelectorProps, 'onSelectionChange' | 'chainId'>
}

export default function SwapInput({
  tokens,
  selectedToken,
  amount,
  onSelectedTokenChanged,
  onSelectedChainIdChanged,
  onAmountChanged,
  disabled = false,
  hideToken,
  disableTokens = null,
  chainId: inputChainId,
  showMaxBtn,
  recipientAddress,
  setRecipientAddress,
  chainSelectorProps,
}: Props) {
  const {
    userPreference: { isRecipientShown },
  } = useUserPreference()
  const withAccount = useWithAccountData()
  const { account } = useWeb3()
  const { isCrossChainSwap } = useSwapContext()
  const balances = withAccount && withAccount[inputChainId]?.tokenData.balances

  const { displayInvalidAddressWarning, setDisplayInvalidAddressWarning, handleMaxButtonOnClick } =
    useSwapInputContext()

  const tokenList = useMemo(() => {
    let filteredTokens = Object.values(tokens).filter((item) => item !== hideToken)

    /** filter tokens that are not available for cross chain swap */
    if (isCrossChainSwap) {
      filteredTokens = filteredTokens.filter((item) => item.isCrossChainAvailable === true)
    }
    return filteredTokens
  }, [hideToken, isCrossChainSwap, tokens])

  return (
    <div className="md:py-2x flex flex-col gap-2 rounded-lg bg-wombatPurple3 p-2 font-Work">
      {/* chain selector */}
      <ChainSelector
        chainId={inputChainId}
        onSelectionChange={onSelectedChainIdChanged}
        {...chainSelectorProps}
      />
      {/* target address input*/}
      {setRecipientAddress && account && isRecipientShown ? (
        <SwapRecipientBox
          recipientAddress={recipientAddress}
          setRecipientAddress={setRecipientAddress}
          hasInvalidAddress={displayInvalidAddressWarning}
          onHasInvalidAddressChange={(isInvalid) => setDisplayInvalidAddressWarning(isInvalid)}
        />
      ) : (
        <></>
      )}
      <div className="flex w-full items-center">
        {/* token input */}
        <TokenSelector
          id={selectedToken.symbol}
          selectedToken={selectedToken}
          onTokenChange={(token) => {
            onSelectedTokenChanged(token)
          }}
          tokens={tokenList}
          disableTokens={disableTokens}
          onClose={() => null}
          input={
            <div className="flex w-full items-center  justify-between rounded-md bg-white px-2 md:w-auto">
              <input
                id={`swap-input-${selectedToken.symbol}`}
                className=" h-8 w-full bg-white text-left font-Helvetica text-base text-black focus:outline-none"
                type="text"
                inputMode="numeric"
                autoCorrect="false"
                autoCapitalize="false"
                placeholder="0.00"
                disabled={disabled}
                value={amount}
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    onAmountChanged(null)
                  } else {
                    try {
                      const replacedAmount = replaceString(e.target.value, ',', '.')
                      if (decimalAndInteger.test(replacedAmount)) {
                        onAmountChanged(replacedAmount)
                      }
                    } catch (error) {
                      console.error(`@SwapInout.onAmountChanged\n` + error)
                    }
                  }
                }}
              ></input>
              {selectedToken && balances && showMaxBtn ? (
                <div className="px-1 uppercase text-wombatPurple">
                  {/* balances */}
                  {balances &&
                  (amount == null || balances[selectedToken.symbol] !== amount.toString()) ? (
                    <span className="button-hover-opacity rounded border-1 border-wombatPurple1 px-2 font-semibold text-wombatPurple1">
                      <button
                        className="button-hover-opacity font-bold"
                        onClick={handleMaxButtonOnClick}
                      >
                        MAX
                      </button>
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          balances={balances}
        />
      </div>
    </div>
  )
}

import Image from 'next/image'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { useWeb3 } from '../../context/Web3Context'
import { getImageMap } from '../../constants/imageMap'

type Props = {
  tokenSymbol: TokenSymbol
  className?: string
  width?: string
  height?: string
  style?: React.CSSProperties
}

export default function TokenImage({
  tokenSymbol,
  className,
  width = '18px',
  height = '18px',
  style,
}: Props) {
  const { chainId } = useWeb3()
  const targetImageMap = getImageMap(chainId)
  const targetImage = targetImageMap[tokenSymbol]

  return targetImage ? (
    <Image
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      src={targetImage!}
      className={className}
      width={width}
      height={height}
      layout="fixed"
      style={style}
    />
  ) : (
    <div />
  )
}

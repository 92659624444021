import { SUPPORTED_CHAIN_IDS, SupportedChainId } from '../../web3/supportedChainId'
import { TokenSymbol, tokenSymbolEnumKeys } from '../token/TokenSymbols'
import { PoolList, Pool } from './Pool'
import { PoolLabels } from './PoolLabels'
import { POOL_CONFIG } from './poolConfig'

type Pools = { [chainId in SupportedChainId]: PoolList }
const getPools = (): Pools => {
  const result: Pools = {} as Pools
  for (const [chainIdStr, poolConfigByPoolLabel] of Object.entries(POOL_CONFIG)) {
    const chainId = chainIdStr as unknown as SupportedChainId
    for (const [poolLabelStr, poolConfig] of Object.entries(poolConfigByPoolLabel)) {
      const poolLabel = poolLabelStr as PoolLabels
      const pool = new Pool({
        chainId,
        label: poolLabel,
        ...poolConfig,
      })
      result[chainId] = {
        ...result[chainId],
        [poolLabel]: pool,
      }
    }
  }
  return result
}

export const POOLS = getPools()

/**
 * Maps a token to the pools that use the token.
 * Map = {
 *   <Token>: <PoolLabels>[]
 * }
 */

export type TokenPoolMapType = {
  [token in TokenSymbol]: PoolLabels[]
}

/**
 * Maps a chain to its TokenPoolMapType.
 * Map = {
 *   <chainId>: {
 *     <Token>: <PoolLabels>[]
 *   }
 * }
 */
export type ChainTokenPoolMapType = {
  [chainId in SupportedChainId]: TokenPoolMapType
}
export const TokenPoolMap: ChainTokenPoolMapType = buildTokenPoolMap()
function buildTokenPoolMap(): ChainTokenPoolMapType {
  return SUPPORTED_CHAIN_IDS.reduce((current, chainId) => {
    return {
      ...current,
      [chainId]: getPoolMapsFromChain(chainId),
    }
  }, {}) as ChainTokenPoolMapType
}

function initTokenPoolMapMap(): TokenPoolMapType {
  return tokenSymbolEnumKeys.reduce((current, token) => {
    return {
      ...current,
      [token]: [],
    }
  }, {}) as TokenPoolMapType
}

function getPoolMapsFromChain(chainId: SupportedChainId): TokenPoolMapType {
  const tokenPoolMap: TokenPoolMapType = initTokenPoolMapMap()
  Object.values(POOLS[chainId]).forEach((pool) => {
    for (const tokenSymbol of pool.supportedAssetTokenSymbols) {
      tokenPoolMap[tokenSymbol].push(pool.label)
    }
  })
  return tokenPoolMap
}
export interface PoolTokenPair {
  poolLabel: PoolLabels
  tokenSymbol: TokenSymbol
}

export function getPoolTokenPair(chainId: SupportedChainId): PoolTokenPair[] {
  return Object.values(POOLS[chainId])
    .filter((pool) => pool.supportedAssetTokenSymbols.length > 0)
    .flatMap((pool) =>
      pool.supportedAssetTokenSymbols.map((tokenSymbol) => {
        return { poolLabel: pool.label, tokenSymbol: tokenSymbol }
      })
    )
}

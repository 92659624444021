import { useAppSelector } from '../hooks'
import {
  selectCoingeckoPriceData,
  selectWithAccountData,
  selectWithoutAccountData,
} from './selectors'

export const useWithAccountData = () => {
  return useAppSelector(selectWithAccountData)
}

export const useWithoutAccountData = () => {
  return useAppSelector(selectWithoutAccountData)
}

export const useCoingeckoPriceData = () => {
  return useAppSelector(selectCoingeckoPriceData)
}

export const useMultiChainMultiCallDataLoading = () => {
  return useAppSelector((state) => state.multiChainMultiCallData.multicalldataFetching)
}

export const useCoingeckoPriceDataLoading = () => {
  return useAppSelector((state) => state.multiChainMultiCallData.coingeckoPriceDataFetching)
}

import React from 'react'
import Tabs from '../../Tabs'
import { useSelfServiceBribePageContext } from '../../../context/SelfServiceBribePageContext'

enum TabId {
  BRIBE,
  REWARDER,
}
function TabMenu() {
  const { isToShowBribeData, updateIsToShowBribeData } = useSelfServiceBribePageContext()
  const currentActiveTabId = isToShowBribeData ? TabId.BRIBE : TabId.REWARDER
  return (
    <Tabs
      className="w-[230px]"
      currentActiveTabId={currentActiveTabId}
      onTabClick={(tabId) => {
        updateIsToShowBribeData(+tabId === TabId.BRIBE)
      }}
      variant="secondary"
    >
      <Tabs.Tab tabId={TabId.BRIBE}>Bribe</Tabs.Tab>
      <Tabs.Tab tabId={TabId.REWARDER}>Rewarder</Tabs.Tab>
    </Tabs>
  )
}

export default TabMenu

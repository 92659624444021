import { POOLS } from '../../constants/contract/pool'
import { ASSETS } from '../../constants/contract/asset'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { ChainInfo } from '../../constants/web3/chains'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { QueryResponseType, SubgraphProperty } from './types'

export function getInitialSubgraphProperty(): SubgraphProperty<number | null> {
  return Object.values(PoolLabels).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: {},
    }
  }, {}) as SubgraphProperty<number | null>
}

export const query = async (query: string, endpoint: string | null) => {
  if (!endpoint) {
    console.error('Invalid subgraph endpoint url')
    return { data: {}, errors: [] }
  }
  try {
    const response: Response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    })
    return response.json()
  } catch (error) {
    console.error('error', error)
  }
}

export const fetchBlock24hAgo = async ({
  chainId,
  chainInfo,
}: {
  chainInfo: ChainInfo
  chainId: SupportedChainId
}) => {
  const timestamp = Math.floor(Date.now() / 1000) - 86400
  const req = `
            {
              blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: {timestamp_lte: ${timestamp}}) {
                number
                timestamp
              }
            }
    `
  if (!chainInfo?.blockSubgraphUrl) return null
  const { data, errors } = (await query(
    req,
    chainInfo?.blockSubgraphUrl ?? null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  )) as QueryResponseType<any>
  if (errors) {
    const errInfo =
      '@subgraph querying\n' +
      `query: ${req}\n` +
      `endpoint: ${chainInfo?.blockSubgraphUrl ?? null}\n`
    console.error(errInfo, JSON.stringify(errors))
    return null
  } else {
    return { chainId, value: data.blocks[0].number }
  }
}

export const fetchSubgraphData = async ({
  block24hAgo,
  chainInfo,
  chainId,
}: {
  block24hAgo: { chainId: number; value: number } | null
  chainInfo: ChainInfo
  chainId: SupportedChainId
}) => {
  // WOM exchange query
  const womExchangeQuery = (async () => {
    // to fix the race condition with chain switching
    if (block24hAgo === null || block24hAgo.chainId !== chainId) return
    const req = `
              query{
                assetsNow: assets {
                  id
                  symbol
                  totalTradeVolumeUSD
                }
                assets24hAgo: assets (block:{number:${block24hAgo.value}}) {
                  id
                  symbol
                  totalTradeVolumeUSD
                }
              }`

    const queryData = await query(
      req,
      chainInfo?.womSubgraphUrl ?? null
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )

    if (!queryData) return
    const { data, errors } = queryData
    if (errors) {
      const errInfo =
        '@subgraph querying\n' +
        `query: ${req}\n` +
        `endpoint: ${chainInfo?.womSubgraphUrl ?? null}\n`
      console.error(errInfo, JSON.stringify(errors))
      return null
    } else {
      const tradingVol24h_ = getInitialSubgraphProperty()
      Object.values(POOLS[chainId])
        .filter((pool) => pool.supportedAssetTokenSymbols.length > 0)
        .forEach((pool) =>
          pool.supportedAssetTokenSymbols.forEach((tokenSymbol) => {
            const lpAddress = ASSETS[chainId][pool.label][tokenSymbol]?.address.toLowerCase()
            if (!lpAddress) return
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const getTradeVol = (result: any) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return result?.filter((obj: any) => obj.id === lpAddress)[0]?.['totalTradeVolumeUSD']
            }

            const vol1 = getTradeVol(data?.assets24hAgo) ?? '0'
            const vol2 = getTradeVol(data?.assetsNow)
            if (vol1 && vol2) {
              tradingVol24h_[pool.label][tokenSymbol] = ((vol2 - vol1) / 2) as number
            }
          })
        )
      // setTradingVol24h(tradingVol24h_)
      return tradingVol24h_
    }
  })()

  /** @todo this query is no longer in use. This is for median boosted apr */
  // WOM staking query
  const womStakingQuery = (async () => {
    const req = `
                  {
                    assets{
                      id
                      symbol
                    }
                  }
                  `
    // Prevent invalid URL error until mainnet has stakingSubgraph.
    if (!chainInfo?.womStakingSubgraphUrl) return
    const { data, errors } = (await query(
      req,
      chainInfo.womStakingSubgraphUrl
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )) as QueryResponseType<any>

    if (errors) {
      const errInfo =
        '@subgraph querying\n' +
        `query: ${req}\n` +
        `endpoint: ${chainInfo?.womStakingSubgraphUrl ?? null}\n`
      console.error(errInfo, JSON.stringify(errors))
      return null
    } else {
      const medianBoostedAprs_ = getInitialSubgraphProperty()

      Object.values(POOLS[chainId])
        .filter((pool) => pool.supportedAssetTokenSymbols.length > 0)
        .forEach((pool) =>
          pool.supportedAssetTokenSymbols.forEach((tokenSymbol) => {
            const lpAddress = ASSETS[chainId][pool.label][tokenSymbol]?.address.toLowerCase()
            if (!lpAddress) return
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const medianBoostedApr = data.assets.filter((obj: any) => obj.id === lpAddress)[0]?.[
              'medianBoostedAPR'
            ]
            medianBoostedAprs_[pool.label][tokenSymbol] = medianBoostedApr
          })
        )
      // setMedianBoostedAprs(medianBoostedAprs_)
      return medianBoostedAprs_
    }
  })()

  return await Promise.all([womExchangeQuery, womStakingQuery])
}

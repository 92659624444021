import { createAsyncThunk } from '@reduxjs/toolkit'
import { MultiChainMultiCallDataState } from '../slice'
import { fetchCoingeckoPriceData } from '../../../context/MulticallDataContext/helpers/fetchTokenPricesHelper'
import { SUPPORTED_CHAIN_IDS } from '../../../constants/web3/supportedChainId'

const getCoingeckoPriceData = createAsyncThunk<
  Pick<MultiChainMultiCallDataState, 'coingeckoPriceData'>,
  undefined
>('MultiChainMultiCallData/getCoingeckoPriceData', async () => {
  try {
    const newCoingeckoPriceData = await fetchCoingeckoPriceData(SUPPORTED_CHAIN_IDS)
    if (newCoingeckoPriceData) {
      return {
        coingeckoPriceData: newCoingeckoPriceData,
      }
    }
  } catch (e) {
    console.error('getCoingeckoPriceData error', e)
  }
  return {
    coingeckoPriceData: undefined,
  }
})

export default getCoingeckoPriceData

export const WORMHOLE_ADAPTOR_ABI = [
  { inputs: [], name: 'ADAPTOR__CONTRACT_NOT_TRUSTED', type: 'error' },
  { inputs: [], name: 'ADAPTOR__INVALID_TOKEN', type: 'error' },
  {
    inputs: [{ internalType: 'bytes32', name: '_hash', type: 'bytes32' }],
    name: 'ADAPTOR__MESSAGE_ALREADY_DELIVERED',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'toToken', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'toChain', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
      { indexed: false, internalType: 'uint256', name: 'minimumToAmount', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'receiver', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'sequence', type: 'uint256' },
    ],
    name: 'BridgeCreditAndSwapForTokens',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'uint8', name: 'version', type: 'uint8' }],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint256', name: 'emitterChainId', type: 'uint256' },
      { indexed: false, internalType: 'address', name: 'emitterAddress', type: 'address' },
      { indexed: false, internalType: 'bytes', name: 'data', type: 'bytes' },
    ],
    name: 'LogError',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'previousOwner', type: 'address' },
      { indexed: true, internalType: 'address', name: 'newOwner', type: 'address' },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Paused',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'uint16', name: 'wormholeChainId', type: 'uint16' },
      { indexed: false, internalType: 'address', name: 'adaptorAddress', type: 'address' },
    ],
    name: 'SetAdaptorAddress',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: 'address', name: 'emitterAddress', type: 'address' },
      { indexed: false, internalType: 'uint16', name: 'sourceChain', type: 'uint16' },
    ],
    name: 'UnknownEmitter',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: 'address', name: 'account', type: 'address' }],
    name: 'Unpaused',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'uint16', name: '', type: 'uint16' }],
    name: 'adaptorAddress',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'wormholeChainId', type: 'uint256' },
      { internalType: 'address', name: 'tokenAddr', type: 'address' },
    ],
    name: 'approveToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'toToken', type: 'address' },
      { internalType: 'uint256', name: 'toChain', type: 'uint256' },
      { internalType: 'uint256', name: 'fromAmount', type: 'uint256' },
      { internalType: 'uint256', name: 'minimumToAmount', type: 'uint256' },
      { internalType: 'address', name: 'receiver', type: 'address' },
      { internalType: 'uint256', name: 'receiverValue', type: 'uint256' },
      { internalType: 'uint256', name: 'gasLimit', type: 'uint256' },
    ],
    name: 'bridgeCreditAndSwapForTokens',
    outputs: [{ internalType: 'uint256', name: 'sequence', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'crossChainPool',
    outputs: [{ internalType: 'contract ICrossChainPool', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    name: 'deliveredMessage',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint16', name: 'toChain', type: 'uint16' },
      { internalType: 'uint256', name: 'receiverValue', type: 'uint256' },
      { internalType: 'uint32', name: 'gasLimit', type: 'uint32' },
    ],
    name: 'estimateDeliveryFee',
    outputs: [
      { internalType: 'uint256', name: 'nativePriceQuote', type: 'uint256' },
      { internalType: 'uint256', name: 'targetChainRefundPerGasUnused', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint16', name: 'toChain', type: 'uint16' },
      { internalType: 'uint256', name: 'receiverValue', type: 'uint256' },
      { internalType: 'uint32', name: 'gasLimit', type: 'uint32' },
    ],
    name: 'estimateRedeliveryFee',
    outputs: [
      { internalType: 'uint256', name: 'nativePriceQuote', type: 'uint256' },
      { internalType: 'uint256', name: 'targetChainRefundPerGasUnused', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'contract IWormholeRelayer', name: '_relayer', type: 'address' },
      { internalType: 'contract IWormhole', name: '_wormhole', type: 'address' },
      { internalType: 'contract ICrossChainPool', name: '_crossChainPool', type: 'address' },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes', name: 'payload', type: 'bytes' },
      { internalType: 'bytes[]', name: '', type: 'bytes[]' },
      { internalType: 'bytes32', name: 'sourceAddress', type: 'bytes32' },
      { internalType: 'uint16', name: 'sourceChain', type: 'uint16' },
      { internalType: 'bytes32', name: 'deliveryHash', type: 'bytes32' },
    ],
    name: 'receiveWormholeMessages',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'relayer',
    outputs: [{ internalType: 'contract IWormholeRelayer', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint16', name: 'sourceChain', type: 'uint16' },
      { internalType: 'uint64', name: 'sequence', type: 'uint64' },
      { internalType: 'uint16', name: 'targetChain', type: 'uint16' },
      { internalType: 'uint256', name: 'newReceiverValue', type: 'uint256' },
      { internalType: 'uint256', name: 'newGasLimit', type: 'uint256' },
    ],
    name: 'requestResend',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'wormholeChainId', type: 'uint256' },
      { internalType: 'address', name: 'tokenAddr', type: 'address' },
    ],
    name: 'revokeToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint16', name: 'wormholeChainId', type: 'uint16' },
      { internalType: 'address', name: 'addr', type: 'address' },
    ],
    name: 'setAdaptorAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'address', name: '', type: 'address' },
    ],
    name: 'validToken',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'wormhole',
    outputs: [{ internalType: 'contract IWormhole', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const

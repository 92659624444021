import React, {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import useTransfer from '../hooks/bridge/useTransfer'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { HexString } from '../interfaces/contract'
import { ContractReceipt } from 'ethers'
import { fetchIsRedeemCompletedFn, getTokenTransferVaaMetadata } from '../utils/bridge'

type ContextType = {
  receipt: ContractReceipt | null
  signedVaa: Uint8Array | null
  sourceChainId: SupportedChainId
  targetChainId: SupportedChainId
  isFetchingSignedVaa: boolean
  isRedeemCompleted: boolean
  isTransferring: boolean
  bridgedTokenAmount: string
  isDisplayGifFlyToTheMoonForTransfer: boolean
  updateSourceChainId: (chainId: SupportedChainId) => void
  updateTargetChainId: (chainId: SupportedChainId) => void
  handleTransfer: (amount: string, recipientAddress?: HexString) => Promise<void>
  recoverTransferReceipt: (txnHash: string) => Promise<Uint8Array | undefined>
  fetchIsRedeemCompleted: () => Promise<void>
}
const initialContext: ContextType = {
  receipt: null,
  signedVaa: null,
  sourceChainId: SupportedChainId.BSC_MAINNET,
  targetChainId: SupportedChainId.ARBITRUM_ONE,
  isFetchingSignedVaa: false,
  isRedeemCompleted: false,
  isTransferring: false,
  bridgedTokenAmount: '',
  isDisplayGifFlyToTheMoonForTransfer: false,
  updateSourceChainId: () => {
    //
  },
  updateTargetChainId: () => {
    //
  },
  handleTransfer: async () => {
    //
  },
  recoverTransferReceipt: async () => {
    return Promise.resolve(undefined)
  },
  fetchIsRedeemCompleted: async () => {
    return Promise.resolve(undefined)
  },
}
const BridgePageContext = createContext<ContextType>(initialContext)
BridgePageContext.displayName = 'BridgeTransferContext'

export const useBridgeTransferContext = (): ContextType => {
  return useContext(BridgePageContext)
}
function BridgeTransferProvider({ children }: { children: ReactElement }) {
  const [sourceChainId, setSourceChainId] = useState(initialContext.sourceChainId)
  const [targetChainId, setTargetChainId] = useState(initialContext.targetChainId)
  const [bridgedTokenAmount, setBridgeTokenAmount] = useState('')
  const [isRedeemCompleted, setIsRedeemCompleted] = useState(false)
  const {
    receipt,
    signedVaa,
    isFetchingSignedVaa,
    isTransferring,
    isDisplayGifFlyToTheMoon: isDisplayGifFlyToTheMoonForTransfer,
    handleTransfer,
    recoverTransferReceipt,
  } = useTransfer({
    sourceChainId,
    targetChainId,
  })

  /**
   * Recover data from signedVaa
   */
  useEffect(() => {
    const updateTokenTransferDataFromSignedVaa = () => {
      if (!signedVaa) return
      const { sourceChainId, targetChainId, bridgedTokenAmount } =
        getTokenTransferVaaMetadata(signedVaa)
      sourceChainId && setSourceChainId(sourceChainId)
      targetChainId && setTargetChainId(targetChainId)
      bridgedTokenAmount && setBridgeTokenAmount(bridgedTokenAmount)
    }

    updateTokenTransferDataFromSignedVaa()
  }, [signedVaa])

  const fetchIsRedeemCompleted = useCallback(async () => {
    const isRedeemed = await fetchIsRedeemCompletedFn(targetChainId, signedVaa)
    setIsRedeemCompleted(isRedeemed)
  }, [signedVaa, targetChainId])

  /**
   * Check if the redemption is completed
   */
  useEffect(() => {
    void fetchIsRedeemCompleted()
  }, [fetchIsRedeemCompleted, signedVaa, targetChainId])

  const updateSourceChainId = (chainId: SupportedChainId) => {
    setSourceChainId(chainId)
  }
  const updateTargetChainId = (chainId: SupportedChainId) => {
    setTargetChainId(chainId)
  }

  return (
    <BridgePageContext.Provider
      value={{
        receipt,
        signedVaa,
        sourceChainId,
        targetChainId,
        isFetchingSignedVaa,
        isRedeemCompleted,
        isTransferring,
        bridgedTokenAmount,
        isDisplayGifFlyToTheMoonForTransfer,
        fetchIsRedeemCompleted,
        updateSourceChainId,
        updateTargetChainId,
        handleTransfer,
        recoverTransferReceipt,
      }}
    >
      {children}
    </BridgePageContext.Provider>
  )
}

export default BridgeTransferProvider

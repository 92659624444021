import { PoolLabels } from '../pool/PoolLabels'
import { TokenSymbols } from '../token/TokenSymbols'

// DESC sort configuration
export const SortConf: {
  [key: string]: {
    [key: string]: number
  }
} = {
  [PoolLabels.ggAVAX_AVAX]: {
    [TokenSymbols.WAVAX]: 1,
  },
}

import React, { createContext, useContext, useEffect, useState } from 'react'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenSymbol, TokenSymbols } from '../constants/contract/token/TokenSymbols'
import { useWeb3 } from './Web3Context'
import { assetListMap } from '../constants/contract/asset'
import { getAddedRewardList } from '../utils/voting'
import {
  useBribeConfigData,
  useBribeInfoData,
  useBoostedPoolRewarderData,
} from '../store/MulticallData/hooks'

export type AddedRewardData = {
  poolSymbol: PoolLabels
  assetSymbol: TokenSymbol
  rewardSymbol: TokenSymbol
  weeklyEmissionRate: string
  remainingBalance: string
  remainingSurplus: string
  runoutTimestamp: number
}

type ContextType = {
  addedRewardList: AddedRewardData[]
  isToShowBribeData: boolean
  updateIsToShowBribeData: (showBribeData: boolean) => void
}
const initialContext: ContextType = {
  addedRewardList: [],
  isToShowBribeData: false,
  updateIsToShowBribeData: () => {
    //
  },
}
const SelfServiceBribePageContext = createContext(initialContext)

export const useSelfServiceBribePageContext = () => {
  return useContext(SelfServiceBribePageContext)
}
type Props = {
  children: React.ReactNode
}
function SelfServiceBribePageProvider({ children }: Props) {
  const [addedRewardList, setAddedRewardList] = useState<AddedRewardData[]>(
    initialContext.addedRewardList
  )
  const [isToShowBribeData, setIsToShowBribeData] = useState(true)
  const { chainId } = useWeb3()

  const bribeConfigData = useBribeConfigData()
  const bribeInfoData = useBribeInfoData()
  const poolRewarderData = useBoostedPoolRewarderData()

  const assetList = assetListMap[chainId]

  const updateIsToShowBribeData = (showBribeData: boolean) => {
    setIsToShowBribeData(showBribeData)
  }

  useEffect(() => {
    let originalRewardList: AddedRewardData[] = []
    if (isToShowBribeData) {
      originalRewardList = getAddedRewardList(
        assetList,
        bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset,
        bribeInfoData.withoutAccount?.rewardTokensRunoutTimestamps,
        bribeInfoData.withoutAccount?.rewardTokenPerSec,
        bribeInfoData.withoutAccount?.rewardTokenBalance,
        bribeInfoData.withoutAccount?.rewardTokenSurplus,
        false
      )
    } else {
      originalRewardList = getAddedRewardList(
        assetList,
        poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
        poolRewarderData.withoutAccount?.rewardTokensRunoutTimestamps,
        poolRewarderData.withoutAccount?.rewardTokenPerSec,
        poolRewarderData.withoutAccount?.rewardTokenBalance,
        poolRewarderData.withoutAccount?.rewardTokenSurplus,
        true
      )
    }
    setAddedRewardList(
      // hide WOM rewards on any environment
      originalRewardList.filter((rewarder) => rewarder.rewardSymbol !== TokenSymbols.WOM)
    )
  }, [
    assetList,
    bribeConfigData.withAccount?.userHasBribeRoleOfEachAsset,
    bribeInfoData.withoutAccount?.rewardTokenBalance,
    bribeInfoData.withoutAccount?.rewardTokenSurplus,
    bribeInfoData.withoutAccount?.rewardTokenPerSec,
    bribeInfoData.withoutAccount?.rewardTokensRunoutTimestamps,
    isToShowBribeData,
    poolRewarderData.withAccount?.hasOperatorRoleOfEachAsset,
    poolRewarderData.withoutAccount?.rewardTokenPerSec,
    poolRewarderData.withoutAccount?.rewardTokenBalance,
    poolRewarderData.withoutAccount?.rewardTokenSurplus,
    poolRewarderData.withoutAccount?.rewardTokensRunoutTimestamps,
  ])

  return (
    <SelfServiceBribePageContext.Provider
      value={{
        addedRewardList,
        isToShowBribeData,
        updateIsToShowBribeData,
      }}
    >
      {children}
    </SelfServiceBribePageContext.Provider>
  )
}

export default SelfServiceBribePageProvider

import React from 'react'
import useBreakpoints from '../../hooks/useBreakpoints'
import { BridgeProgress } from '../../interfaces/bridge'
import BridgeTransferEditIcon from '../../public/assets/icons/bridge-transfer-edit.svg'
import Image from 'next/image'
import { isTargetOnCurrentProgress } from '../../utils/bridge'
import { useBridgeTransferContext } from '../../context/BridgeTransferContext'

interface BridgeTransferProgressType {
  title: string
  children: React.ReactNode
  marginBottom?: string
  isDimmed?: boolean
  targetProgress?: BridgeProgress
  currentProgress?: BridgeProgress
  handleIconOnClick?: (targetProgress: BridgeProgress) => void
  isLastProgress?: boolean
}

export const BridgeTransferProgress = ({
  title,
  children,
  marginBottom,
  isDimmed = false,
  targetProgress,
  currentProgress,
  handleIconOnClick,
  isLastProgress,
}: BridgeTransferProgressType) => {
  const { isMd } = useBreakpoints()
  const { receipt, isTransferring, signedVaa } = useBridgeTransferContext()
  const isIconShown =
    targetProgress &&
    currentProgress &&
    handleIconOnClick &&
    isTargetOnCurrentProgress(targetProgress, currentProgress, 'AFTER_TARGET')
  const isIconDisabled = isTransferring || !!receipt || !!signedVaa

  return (
    <div className="mb-2 flex flex-row">
      <div className="flex w-[25%] justify-end">
        <div className={`flex h-fit items-center  ${isMd ? 'flex-col-reverse' : ''}`}>
          {isIconShown && (
            <div className="mr-[5px] flex h-fit">
              <Image
                width={20}
                height={20}
                src={BridgeTransferEditIcon}
                onClick={() => {
                  if (isIconDisabled) return
                  handleIconOnClick(targetProgress)
                }}
                className={`${isIconDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              />
            </div>
          )}
          <div className={`${isDimmed ? 'opacity-20' : ''} text-right`}>
            {title.toLocaleUpperCase()}
          </div>
        </div>
        <div
          className={`ml-[8px] ${!isMd ? 'mr-[40px]' : 'mr-[8px]'}  ${
            isDimmed ? 'opacity-20' : ''
          }`}
        >
          <div
            className={`mt-[8px] mb-2 h-[8px] w-[8px]  ${
              isDimmed
                ? 'rounded-full border-1 border-black bg-transparent'
                : 'rounded-full bg-black'
            }`}
          />
          {!isLastProgress && (
            <div className="flex h-[85%] items-center justify-center">
              <div className="h-full w-[1px] bg-black" />
            </div>
          )}
        </div>
      </div>
      <div className={`flex w-[75%] ${marginBottom}`}>{children}</div>
    </div>
  )
}

import clsx from 'clsx'
import React from 'react'

type Props = { children: React.ReactNode; title?: React.ReactNode }

function ModalInfoBox({ children, title }: Props) {
  return (
    <div className="w-full rounded-lg border-1 border-wombatBrown1 px-[16px] py-3 text-sm">
      <p className="mb-2 text-center text-base font-semibold">{title}</p>
      {children}
    </div>
  )
}

export default ModalInfoBox

type InfoItemProps = {
  leadingLabel?: React.ReactNode
  trailingLabel?: React.ReactNode
  className?: string
  bold?: boolean
}
function InfoItem({ className, leadingLabel, trailingLabel, bold }: InfoItemProps) {
  return (
    <div
      className={clsx(
        `flex flex-row justify-between ${bold ? 'font-bold' : 'font-normal'}`,
        className
      )}
    >
      <span>{leadingLabel && leadingLabel}</span>
      <span>{trailingLabel && trailingLabel}</span>
    </div>
  )
}

function Divider() {
  return <hr className="my-2 h-0.5 grow bg-wombatBrown" />
}
ModalInfoBox.InfoItem = InfoItem
ModalInfoBox.Divider = Divider

import React, { ReactChild, useCallback, useContext, useMemo, useState } from 'react'
interface ISelectWalletContext {
  isOpen: boolean
  open(): void
  close(): void
  isDisconnected: boolean
  disconnect(): void
}

const Modal = React.createContext<ISelectWalletContext>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  open: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  close: () => {},
  isDisconnected: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect: () => {},
})

export const WalletSelectedProvider = ({ children }: { children: ReactChild }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isDisconnected, setIsDisconnected] = useState<boolean>(false)

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const disconnect = useCallback(() => {
    setIsDisconnected(true)
  }, [])

  const contextValue = useMemo(
    () => ({
      isOpen,
      close,
      open,
      isDisconnected,
      disconnect,
    }),
    [isOpen, close, open, isDisconnected, disconnect]
  )

  return <Modal.Provider value={contextValue}>{children}</Modal.Provider>
}

export const useWalletSelected = () => {
  return useContext(Modal)
}

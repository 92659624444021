import { PoolConfig } from '..'
import { PoolLabels } from '../../PoolLabels'
import { PoolFilterGroup } from '../../poolFilterGroup'

/** Pool page follows this order  */
export const FUJI_POOL_CONFIG: PoolConfig = {
  [PoolLabels.CROSS_CHAIN]: {
    name: 'Cross-Chain Pool',
    filterGroup: PoolFilterGroup.CROSS_CHAIN,
    address: '0x12F1375725EBf433Adb9cAeC81eEa8E67BA13c42',
  },
}

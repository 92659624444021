export enum CrossChainCase {
  // Success flow: Have toTxHash has address and relayStatus.status === 'redeemed'
  RECEIVED_TOKEN = 'RECEIVED_TOKEN',
  //  Error flow: destination chain pool slippage too high
  RECEIVED_CREDIT = 'RECEIVED_CREDIT',
  //  Error flow: Delivery failure on the destination side
  NEED_REDELIVERY = 'NEED_REDELIVERY',
  // Pending flow: relayStatus.status === 'waiting'
  PENDING = 'PENDING',
  FROM_CHAIN_FAILED = 'FROM_CHAIN_FAILED',
}
// export const RELAY_STATUS_ENDPOINT = {
//   testnet:
//     'https://nextjs-cors-anywhere-ivory.vercel.app/api?endpoint=http://a6163c82a2a6f4c1d9c2cf2c35f0733b-758274193.us-east-2.elb.amazonaws.com:80/relay-status',
//   mainnet:
//     'https://nextjs-cors-anywhere-ivory.vercel.app/api?endpoint=http://ade18dde9976749fca82c41f05d29cbe-364125254.us-east-2.elb.amazonaws.com/relay-status',
// }

export const RELAY_STATUS_ENDPOINT = {
  testnet:
    'https://nextjs-cors-anywhere-ivory.vercel.app/api?endpoint=https://api.testnet.wormholescan.io/api/v1/relays',
  mainnet:
    'https://nextjs-cors-anywhere-ivory.vercel.app/api?endpoint=https://api.wormholescan.io/api/v1/relays',
}

import { Connector } from 'wagmi'
import { MetaMaskConnector } from '@wagmi/core/connectors/metaMask'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { WalletConnectConnector } from '@wagmi/core/connectors/walletConnect'
import { InjectedConnector } from 'wagmi/connectors/injected'
import BinanceIcon from '../../public/assets/icons/binance-icon.svg'
import CoinbaseIcon from '../../public/assets/icons/coinbase-icon.svg'
import GnosisSafeIcon from '../../public/assets/icons/gnosis-safe-icon.png'
import MetamaskIcon from '../../public/assets/icons/metamask-icon.svg'
import SafepalIcon from '../../public/assets/icons/safepal-icon.svg'
import OKXWalletIcon from '../../public/assets/icons/okx-wallet-icon.svg'
import OKXWalletGroupIcon from '../../public/assets/icons/okx-wallet-group.svg'
import Coin98Icon from '../../public/assets/icons/coin98-icon.png'
import WalletconnectIcon from '../../public/assets/icons/walletconnect-icon.svg'
import BinanceGroupIcon from '../../public/assets/icons/binance-group.svg'
import CoinbaseGroupIcon from '../../public/assets/icons/coinbase-group.svg'
import GnosisSafeGroupIcon from '../../public/assets/icons/gnosis-safe-group.png'
import MetamaskGroupIcon from '../../public/assets/icons/metamask-group.svg'
import CoreWalletIcon from '../../public/assets/icons/corewallet-icon.svg'
import CoreWalletGroupIcon from '../../public/assets/icons/corewallet-group.svg'
import SafepalGroupIcon from '../../public/assets/icons/safepal-group.svg'
import Coin98GroupIcon from '../../public/assets/icons/coin98-group.svg'
import TrustWalletGroupIcon from '../../public/assets/icons/trustwallet-group.svg'
import TrustWalletIcon from '../../public/assets/icons/trustwallet-icon.svg'
import WalletconnectGroupIcon from '../../public/assets/icons/walletconnect-group.svg'
import { SafeConnector } from 'wagmi/connectors/safe'
import { StaticImageData } from 'next/image'
import { chainInfos } from './chains'
import { getInjection } from './connection'
export enum WalletId {
  METAMASK = 'METAMASK',
  WALLETCONNECT = 'WALLETCONNECT',
  COIN98 = 'COIN98',
  TRUSTWALLET = 'TRUSTWALLET',
  SAFEPAL = 'SAFEPAL',
  COINBASEWALLET = 'COINBASEWALLET',
  BINANCEWALLET = 'BINANCEWALLET',
  GNOSISSAFE = 'GNOSISSAFE',
  OKX_WALLET = 'OKX_WALLET',
  INJECTED = 'INJECTED',
  COREWALLET = 'COREWALLET',
}

type Props = {
  id: WalletId
  isHidden: boolean
  isWindowEthereum: boolean
  connector: Connector
  name?: string
  icon: string | StaticImageData
  groupIcon: { src: string | StaticImageData; height?: number; width?: number }
}
class Wallet {
  readonly connector: Connector
  readonly name: string
  readonly id: WalletId
  readonly icon: string | StaticImageData
  readonly groupIcon: { src: string | StaticImageData; height?: number; width?: number }
  // hidden on SelectWalletModal
  readonly isHidden: boolean
  // group icons on YourWalletModal (filter the wallet which is window.ethereum)
  readonly isWindowEthereum: boolean
  constructor({ id, connector, icon, groupIcon, name, isHidden, isWindowEthereum }: Props) {
    this.id = id
    this.isHidden = isHidden
    this.isWindowEthereum = isWindowEthereum
    this.connector = connector
    this.icon = icon
    this.groupIcon = groupIcon
    this.name = name || connector.name
  }
}
export const SUPPORTED_WALLETS: { [id in WalletId]: Wallet } = {
  [WalletId.COREWALLET]: new Wallet({
    id: WalletId.COREWALLET,
    isHidden: false,
    isWindowEthereum: true,
    name: 'CoreWallet',
    connector: new InjectedConnector({
      chains: Object.values(chainInfos).filter((c) => c.id === 43113 || c.id === 43114),
    }),
    icon: CoreWalletIcon,
    groupIcon: { src: CoreWalletGroupIcon },
  }),
  [WalletId.METAMASK]: new Wallet({
    id: WalletId.METAMASK,
    isHidden: false,
    isWindowEthereum: true,
    name: 'MetaMask',
    connector: new MetaMaskConnector({ chains: Object.values(chainInfos) }),
    icon: MetamaskIcon,
    groupIcon: { src: MetamaskGroupIcon },
  }),
  [WalletId.INJECTED]: new Wallet({
    id: WalletId.INJECTED,
    isHidden: false,
    isWindowEthereum: true,
    name: getInjection().name,
    connector: new InjectedConnector({
      options: {
        name: getInjection().name,
        getProvider: () => (typeof window !== 'undefined' ? window.ethereum : undefined),
      },
      chains: Object.values(chainInfos),
    }),
    icon: getInjection().icon,
    groupIcon: { src: MetamaskGroupIcon },
  }),
  [WalletId.WALLETCONNECT]: new Wallet({
    id: WalletId.WALLETCONNECT,
    isHidden: false,
    isWindowEthereum: false,
    name: 'Wallet Connect',
    connector: new WalletConnectConnector({
      chains: Object.values(chainInfos),
      options: {
        projectId: 'aa6077666a674fa25600a50c3fabd246',
        showQrModal: true,
      },
    }),
    icon: WalletconnectIcon,
    groupIcon: { src: WalletconnectGroupIcon },
  }),
  [WalletId.TRUSTWALLET]: new Wallet({
    id: WalletId.TRUSTWALLET,
    isHidden: false,
    isWindowEthereum: true,
    name: 'TrustWallet',
    connector: new InjectedConnector({
      chains: Object.values(chainInfos),
      options: {
        name: 'Trust Wallet',
        getProvider: () => (typeof window !== 'undefined' ? window.ethereum : undefined),
      },
    }),
    icon: TrustWalletIcon,
    groupIcon: { src: TrustWalletGroupIcon, height: 100, width: 180 },
  }),
  [WalletId.COIN98]: new Wallet({
    id: WalletId.COIN98,
    isHidden: false,
    isWindowEthereum: true,
    name: 'Coin98',
    connector: new InjectedConnector({
      options: {
        name: 'Coin98',
        getProvider: () => (typeof window !== 'undefined' ? window.ethereum : undefined),
      },
    }),
    icon: Coin98Icon,
    groupIcon: { src: Coin98GroupIcon },
  }),
  [WalletId.SAFEPAL]: new Wallet({
    id: WalletId.SAFEPAL,
    isHidden: false,
    isWindowEthereum: true,
    name: 'SafePal',
    connector: new InjectedConnector({
      chains: Object.values(chainInfos),
      options: {
        name: 'SafePal',
        getProvider: () => (typeof window !== 'undefined' ? window.ethereum : undefined),
      },
    }),
    icon: SafepalIcon,
    groupIcon: { src: SafepalGroupIcon },
  }),
  [WalletId.COINBASEWALLET]: new Wallet({
    id: WalletId.COINBASEWALLET,
    isHidden: false,
    isWindowEthereum: false,
    connector: new CoinbaseWalletConnector({
      chains: Object.values(chainInfos),
      options: {
        appName: 'Wombat Dapp',
      },
    }),
    icon: CoinbaseIcon,
    groupIcon: { src: CoinbaseGroupIcon },
  }),
  [WalletId.BINANCEWALLET]: new Wallet({
    id: WalletId.BINANCEWALLET,
    name: 'BinanceWallet',
    connector: new InjectedConnector({
      chains: Object.values(chainInfos),
      options: {
        name: 'Binance Wallet',
        getProvider: () => (typeof window !== 'undefined' ? window.BinanceChain : undefined),
      },
    }),
    isHidden: false,
    isWindowEthereum: false,
    icon: BinanceIcon,
    groupIcon: { src: BinanceGroupIcon },
  }),
  [WalletId.GNOSISSAFE]: new Wallet({
    id: WalletId.GNOSISSAFE,
    name: 'GnosisSafe',
    connector: new SafeConnector({ chains: Object.values(chainInfos) }),
    isHidden: true,
    isWindowEthereum: false,
    icon: GnosisSafeIcon,
    groupIcon: { src: GnosisSafeGroupIcon },
  }),
  [WalletId.OKX_WALLET]: new Wallet({
    id: WalletId.OKX_WALLET,
    isHidden: false,
    isWindowEthereum: true,
    name: 'OKX Wallet',
    connector: new InjectedConnector({
      chains: Object.values(chainInfos),
      options: {
        name: 'OKX Wallet',
        getProvider: () => (typeof window !== 'undefined' ? window.okexchain : undefined),
      },
    }),
    icon: OKXWalletIcon,
    groupIcon: { src: OKXWalletGroupIcon },
  }),
}

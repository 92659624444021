import Image from 'next/image'
import { useMemo, useRef, useState } from 'react'
import Modal, { ModalProps } from '.'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import WombatToTheMoonFlyUp from '../../public/assets/animations/ToTheMoon2.gif'
import WombatToTheMoonNoText from '../../public/assets/animations/ToTheMoon_noText.gif'
import icon from '../../public/assets/icons/waiting-deposit.svg'

type Props = Omit<ModalProps, 'children'> & {
  data?: string
  isDisplayGifFlyToTheMoon: boolean
  isDisplayDepositIcon?: boolean
  textAboveBalance?: string
  children?: React.ReactNode
}

export default function WaitingModal({
  isOpen,
  onClose,
  data,
  isDisplayGifFlyToTheMoon,
  isDisplayDepositIcon = false,
  textAboveBalance,
  children,
  variant,
  ...otherProps
}: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })
  const [infor] = useState<string | undefined>(data)

  const renderGif = useMemo(() => {
    if (isDisplayGifFlyToTheMoon) {
      return (
        <div className="absolute -top-60">
          <Image src={WombatToTheMoonFlyUp} layout="fixed" width={105} height={525} unoptimized />
        </div>
      )
    }

    return <Image src={WombatToTheMoonNoText} layout="fixed" width={70} height={70} unoptimized />
  }, [isDisplayGifFlyToTheMoon])

  return isOpen === true ? (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Waiting for confirmation"
      isCenterTitle
      isOverflowHidden
      variant={variant}
      {...otherProps}
    >
      <div className="relative flex flex-col items-center justify-center" style={{ marginTop: 0 }}>
        <span className="text-center font-Work text-[14px] text-[#B7A38E]">
          Please confirm the transaction in your wallet
        </span>
        <div className="flex w-full flex-col items-center py-10 font-Work">
          {isDisplayDepositIcon ? (
            <div className="flex">
              <Image src={icon} layout="fixed" width={20} height={20} />
              <div className="ml-3 font-semibold text-wombatPurple1">{textAboveBalance}</div>
            </div>
          ) : (
            <div className="font-semibold text-wombatPurple1">{textAboveBalance}</div>
          )}
          {children ? (
            children
          ) : (
            <div className="mt-2 mb-12 whitespace-nowrap rounded-lg bg-wombatPurple1 py-3.5 px-20 font-Helvetica text-base font-medium text-wombatCream">
              {infor}
            </div>
          )}
          <div
            style={{
              paddingBottom: `${isDisplayGifFlyToTheMoon ? '70px' : ''}`,
              marginTop: '10px',
            }}
            className={`flex items-center justify-center`}
          >
            {renderGif}
          </div>
        </div>
      </div>
    </Modal>
  ) : (
    <>{''}</>
  )
}

import { getCommifiedFormat, isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import Image from 'next/image'
import { useEffect, useMemo, useState } from 'react'
import { TokenSymbols } from '../../../configx/tokenSymbols'
import { BRIDGE_TRANSFER_PROGRESS_LIMIT } from '../../../constants/common'
import { TOKENS } from '../../../constants/contract'
import { chainInfos } from '../../../constants/web3/chains'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { useBridgeTransferContext } from '../../../context/BridgeTransferContext'
import { useModalContext } from '../../../context/ModalContext'
import { useWalletSelected } from '../../../context/WalletSelectedContext'
import { useWeb3 } from '../../../context/Web3Context'
import useRedeem from '../../../hooks/bridge/useRedeem'
import useTransferTxnProgress from '../../../hooks/bridge/useTransferTxnProgress'
import useAddTokenToMetamask from '../../../hooks/useAddToken'
import useApproval, { TokenApprovalState } from '../../../hooks/useApproval'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { BridgeProgress, UserTransferSelection } from '../../../interfaces/bridge'
import { HexString } from '../../../interfaces/contract'
import { ModalId } from '../../../interfaces/modal'
import arrowRight from '../../../public/assets/icons/arrow-right-thin.svg'
import { useTokenData } from '../../../store/MulticallData/hooks'
import { shortenHashString } from '../../../utils'
import { getNewTargetChainId, isTargetOnCurrentProgress } from '../../../utils/bridge'
import { getExplorerLink } from '../../../utils/getBscScanLink'
import ChainSelectionDropdown from '../../ChainSelectionDropdown'
import TokenImage from '../../TokenImage'
import WButton, { Variant } from '../../WButton'
import BridgeRedeemConfirmationModal from '../BridgeRedeemConfirmationModal/BridgeRedeemConfirmationModal'
import BridgeTransferConfirmationModal from '../BridgeTransferConfirmationModal/BridgeTransferConfirmationModal'
import { BridgeTransferProgress } from '../BridgeTransferProgress'
import TransferProgressBar from './TransferProgressBar'

export const BridgeTransfer = () => {
  const {
    sourceChainId,
    targetChainId,
    isFetchingSignedVaa,
    signedVaa,
    isTransferring,
    receipt,
    isRedeemCompleted,
    bridgedTokenAmount,
    updateSourceChainId,
    updateTargetChainId,
    handleTransfer,
    fetchIsRedeemCompleted,
  } = useBridgeTransferContext()
  const { withAccount } = useTokenData()
  const { isMd } = useBreakpoints()
  const { chainId, account, switchNetwork } = useWeb3()
  const [amount, setAmount] = useState('')
  const [currentProgress, setCurrentProgress] = useState<BridgeProgress>(
    BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN
  )
  const {
    actions: { closeModal },
    modalState: currentModalState,
  } = useModalContext()
  const [displayInputWarning, setDisplayInputWarning] = useState(false)
  const [displayInvalidAddressWarning, setDisplayInvalidAddressWarning] = useState(false)
  const { addToken } = useAddTokenToMetamask(TOKENS[targetChainId].WOM)

  /**@todo unlimited tokens check */
  const [, setApproveUnlimitedTokensChecked] = useState<boolean>(false)
  const { open: onOpenModalConnectWallet } = useWalletSelected()
  const womToken = TOKENS[sourceChainId].WOM
  const [recipient, setRecipient] = useState('')
  const {
    handleRedeem,
    isRedeeming,
    isDisplayGifFlyToTheMoon: isDisplayGifFlyToTheMoonForRedeem,
  } = useRedeem({ targetModalId: ModalId.BRIDGE_TRANSFER_REDEEM_WAIT_FOR_CONFIRMATION })
  const { tryApproval, approvalAmount, approvalState } = useApproval(
    womToken?.address || null,
    chainInfos[sourceChainId].wormholeConfig?.tokenBridgeAddress || null,
    utils.parseUnits(amount || '0', womToken?.decimals || 18)
  )
  const [isEditActive, setIsEditActive] = useState<boolean>(false)
  const [redeemTransaction, setRedeemTransaction] = useState('')
  const { blockDiff, minBlockConfirmations } = useTransferTxnProgress(sourceChainId, receipt)

  /** cached user's selection when doing bridge transfer*/
  const [cachedUserSelection, setCachedUserSelection] = useState<UserTransferSelection | null>(null)

  const balances = withAccount?.balances

  const handleSourceTokenAmount = (value: string) => {
    setDisplayInputWarning(false)
    if (value === '') {
      setAmount('')
      return
    }
    const womToken = TOKENS[chainId][TokenSymbols.WOM]
    if (!womToken) return
    if (!isParsableString(value, womToken.decimals, true)) return
    setAmount(value)
  }

  const handleSourceMaxOnClick = () => {
    if (!balances) return
    setAmount(balances[TokenSymbols.WOM] ?? '0')
  }

  const handleNextBtnOnClick = (
    nextBridgeProgress: BridgeProgress,
    amount?: string,
    recipientAddress?: string
  ) => {
    switch (nextBridgeProgress) {
      case BridgeProgress.SET_TARGET_RECIPIENT:
        // Network checking
        if (sourceChainId === targetChainId) return

        // Input checking
        if (!amount) {
          setDisplayInputWarning(true)
        } else if (balances && strToWad(balances[TokenSymbols.WOM]).gte(strToWad(amount))) {
          setDisplayInputWarning(false)
          setCurrentProgress(nextBridgeProgress)
        }
        return
      case BridgeProgress.SEND_TOKENS:
        if (recipientAddress) {
          try {
            // Address validity checking
            const inValidAddress = utils.getAddress(recipientAddress)
            if (inValidAddress) {
              setCurrentProgress(nextBridgeProgress)
            }
            setDisplayInputWarning(false)
          } catch (error) {
            console.error(error)
            setDisplayInvalidAddressWarning(true)
          }
        }
        return
    }
  }

  const handleEditBtnOnClick = (targetBridgeProgress: BridgeProgress) => {
    setCurrentProgress(targetBridgeProgress)
  }

  const handleSendBtnOnClick = async () => {
    if (isTransferring) return
    const womBalance = balances ? balances[TokenSymbols.WOM] : '0'
    await handleTransfer(amount, recipient as HexString)

    //cache user selection after receiving receipt
    setCachedUserSelection({
      sourceChainId: chainId,
      sourceTokenBalance: womBalance,
      targetChainId: targetChainId,
    })
    setRedeemTransaction('')
  }

  const handleRedeemBtnOnClick = async () => {
    /** Check Latest Redeem status*/
    await fetchIsRedeemCompleted()
    if (isRedeeming || isRedeemCompleted) return

    if (chainId !== targetChainId) {
      switchNetwork(targetChainId)
    }
    /** Handle Redeem */
    const redeemTxn = await handleRedeem(targetChainId, signedVaa)
    if (!redeemTxn) return
    setRedeemTransaction(redeemTxn)
  }

  const handleSourceChainIdOnChange = async (selectedChainId: SupportedChainId) => {
    if (sourceChainId !== selectedChainId) {
      const result = await switchNetwork(selectedChainId)
      if (result) {
        const newTargetChainId = getNewTargetChainId(selectedChainId)
        updateTargetChainId(newTargetChainId)
        updateSourceChainId(selectedChainId)
      }
    }
  }

  const handleTargetChainIdOnchange = async (selectedChainId: SupportedChainId) => {
    if (selectedChainId === sourceChainId) return
    updateTargetChainId(selectedChainId)
  }

  const currentTransferProgress = useMemo(() => {
    if (!blockDiff) return 0
    // Progress bar fully filled only when signedVaa has fetched
    if (signedVaa) return 100
    if (blockDiff > minBlockConfirmations) return BRIDGE_TRANSFER_PROGRESS_LIMIT
    return (blockDiff / minBlockConfirmations) * BRIDGE_TRANSFER_PROGRESS_LIMIT
  }, [blockDiff, minBlockConfirmations, signedVaa])

  const sourceChainWomBalance = useMemo(() => {
    /** source chain balance before transfer: balance of current chain */
    /** source chain balance after transfer: cached balance of source chain */
    if (!balances) return null
    if (cachedUserSelection?.sourceTokenBalance) return cachedUserSelection?.sourceTokenBalance
    return balances[TokenSymbols.WOM]
  }, [balances, cachedUserSelection?.sourceTokenBalance])

  useEffect(() => {
    if (account) {
      setRecipient(account)
    }
  }, [account])

  /** when user change network amid transfer progress*/
  useEffect(() => {
    updateSourceChainId(
      cachedUserSelection?.sourceChainId ? cachedUserSelection?.sourceChainId : chainId
    )
    const newTargetChainId = getNewTargetChainId(chainId)
    updateTargetChainId(
      cachedUserSelection?.targetChainId ? cachedUserSelection?.targetChainId : newTargetChainId
    )
    /**only set when network changed */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])

  const isApproved = approvalAmount?.gte(strToWad(amount))

  return (
    <>
      <BridgeTransferProgress
        title="Source"
        marginBottom="mb-6"
        isDimmed={currentProgress !== BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN}
        currentProgress={currentProgress}
        targetProgress={BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN}
        handleIconOnClick={handleEditBtnOnClick}
      >
        <div className="flex w-full flex-col gap-2">
          <div
            className={`flex flex-col gap-2 ${
              currentProgress !== BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN ? 'opacity-20' : ''
            }`}
          >
            {/** Chain Selection*/}
            <div className="flex items-center">
              <ChainSelectionDropdown
                chainId={
                  cachedUserSelection?.sourceChainId
                    ? cachedUserSelection.sourceChainId
                    : sourceChainId
                }
                width="100%"
                onSelectionChange={(selectedChainId) =>
                  handleSourceChainIdOnChange(selectedChainId)
                }
                disabled={currentProgress !== BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN}
              />
              <div className="mx-[4px] ">
                <Image alt={''} src={arrowRight} height={20} width={20} />
              </div>
              <ChainSelectionDropdown
                chainId={targetChainId}
                onSelectionChange={(selectedChainId) =>
                  handleTargetChainIdOnchange(selectedChainId)
                }
                width="100%"
                disabled={currentProgress !== BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN}
              />
            </div>
            {/** Token Amount*/}
            <div className="flex h-[40px] items-center justify-between rounded border-1 border-wombatBrown1 p-2">
              <input
                type="text"
                inputMode="numeric"
                placeholder="0.00"
                className="w-full bg-transparent font-Helvetica text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none"
                value={amount}
                onChange={(e) => handleSourceTokenAmount(e.target.value)}
                disabled={currentProgress !== BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN}
              />
              <button
                onClick={handleSourceMaxOnClick}
                className="button-hover-opacity rounded border-1 border-wombatPurple1 px-2 font-semibold text-wombatPurple1"
                disabled={currentProgress !== BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN}
              >
                MAX
              </button>
              <p className="mx-1">{TokenSymbols.WOM}</p>
              <TokenImage tokenSymbol={TokenSymbols.WOM} width="20px" height="20px" />
            </div>
            {/** User Wom Balance*/}
            {/** Show cached balance after user transferred tokens */}
            {account && (
              <div className="flex justify-end">
                Balance: {getCommifiedFormat(strToWad(sourceChainWomBalance ?? '0'))}
              </div>
            )}
            {/** Warning Message*/}
            {/**@todo update warning msg */}
            {displayInputWarning && (
              <div className="flex justify-center text-wombatRed">Please Enter Input</div>
            )}
            {sourceChainWomBalance && strToWad(sourceChainWomBalance).lt(strToWad(amount)) && (
              <div className="flex justify-center  text-wombatRed">Input Larger than Balance</div>
            )}
          </div>
          {!account ? (
            <WButton
              variant={Variant.GRADIENT}
              width="w-full"
              height="h-11"
              onClick={onOpenModalConnectWallet}
            >
              CONNECT WALLET
            </WButton>
          ) : (
            <>
              {BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN === currentProgress && (
                <WButton
                  variant={Variant.PURPLE}
                  width="w-full"
                  height="h-10"
                  onClick={() => handleNextBtnOnClick(BridgeProgress.SET_TARGET_RECIPIENT, amount)}
                  disabled={currentProgress !== BridgeProgress.SET_SOURCE_AND_TARGET_CHAIN}
                  enableDimmed
                >
                  Next
                </WButton>
              )}
            </>
          )}
        </div>
      </BridgeTransferProgress>
      <BridgeTransferProgress
        title="Target"
        marginBottom="mb-6"
        isDimmed={currentProgress !== BridgeProgress.SET_TARGET_RECIPIENT}
        currentProgress={currentProgress}
        targetProgress={BridgeProgress.SET_TARGET_RECIPIENT}
        handleIconOnClick={handleEditBtnOnClick}
      >
        <div className="flex w-full flex-col gap-2">
          <div
            className={`flex flex-col gap-2 ${
              BridgeProgress.SET_TARGET_RECIPIENT !== currentProgress ? 'opacity-20' : ''
            } `}
          >
            {account && (
              <>
                <div className="flex justify-start">
                  <b>Send to</b>
                </div>
                <div className="flex h-[40px] w-full justify-between rounded border-1 border-wombatBrown1 p-2">
                  <input
                    className={`w-full bg-transparent font-Helvetica text-[16px] text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none ${
                      !isEditActive ? 'opacity-50' : ''
                    }`}
                    value={recipient}
                    onChange={(e) => setRecipient(e.target.value)}
                    placeholder="Wallet Address"
                    disabled={!isEditActive}
                  />
                  <button
                    onClick={() => setIsEditActive((prev) => !prev)}
                    className="button-hover-opacity rounded border-1 border-wombatPurple1 px-2 font-semibold text-wombatPurple1"
                    disabled={currentProgress !== BridgeProgress.SET_TARGET_RECIPIENT}
                  >
                    EDIT
                  </button>
                </div>
              </>
            )}
            {/**@todo update warning msg */}
            {displayInvalidAddressWarning && (
              <div
                className="flex justify-center
               text-wombatRed"
              >
                Please Enter Valid Recipient Address
              </div>
            )}
            {isTargetOnCurrentProgress(
              BridgeProgress.SET_TARGET_RECIPIENT,
              currentProgress,
              'ON_OR_AFTER_TARGET'
            ) && (
              <div className="flex justify-between">
                <div>Bridged Tokens</div>
                <div className="flex">
                  <p className="mx-1">
                    {amount} {TokenSymbols.WOM}
                  </p>
                  <TokenImage tokenSymbol={TokenSymbols.WOM} width="20px" height="20px" />
                </div>
              </div>
            )}
          </div>
          {isTargetOnCurrentProgress(
            BridgeProgress.SET_TARGET_RECIPIENT,
            currentProgress,
            'ON_OR_BEFORE_TARGET'
          ) && (
            <WButton
              variant={Variant.PURPLE}
              width="w-full"
              height="h-10"
              onClick={() => handleNextBtnOnClick(BridgeProgress.SEND_TOKENS, amount, recipient)}
              disabled={currentProgress !== BridgeProgress.SET_TARGET_RECIPIENT}
              enableDimmed
            >
              Next
            </WButton>
          )}
        </div>
      </BridgeTransferProgress>
      <BridgeTransferProgress
        title="Send Tokens"
        marginBottom="mb-6"
        isDimmed={currentProgress !== BridgeProgress.SEND_TOKENS}
      >
        <div className="flex w-full flex-col gap-2">
          {isTargetOnCurrentProgress(
            BridgeProgress.SEND_TOKENS,
            currentProgress,
            'ON_OR_AFTER_TARGET'
          ) && (
            <>
              <div className={`flex bg-wombatYellow2 ${!isMd ? 'px-20' : 'px-2'} py-2`}>
                Initiate the transfer on{' '}
                {
                  chainInfos[
                    cachedUserSelection?.sourceChainId
                      ? cachedUserSelection.sourceChainId
                      : sourceChainId
                  ].label
                }{' '}
                and wait for finalization. If you leave this page before the next step, simply
                redeem your tokens in the section below to complete the transfer.
              </div>
              {!isApproved && (
                <div className="flex">
                  <input
                    type="checkbox"
                    className="mr-2 w-[18px]"
                    onChange={() => setApproveUnlimitedTokensChecked((prev) => !prev)}
                  />
                  <div>Approve Unlimited Tokens</div>
                </div>
              )}
              {signedVaa && (
                <div className="flex w-full flex-col gap-2">
                  <div className="flex justify-center">The tokens have entered the bridge!</div>
                  <div className="h-[40px] rounded border-1 border-wombatBrown1 p-2 text-center ">
                    {isMd && receipt?.transactionHash
                      ? shortenHashString(
                          receipt?.transactionHash,
                          4,
                          receipt?.transactionHash.length
                        )
                      : receipt?.transactionHash}
                  </div>
                  <div className="flex justify-center px-4">
                    <button
                      onClick={() => {
                        if (!receipt?.transactionHash || !cachedUserSelection?.sourceChainId) return
                        window.open(
                          getExplorerLink(
                            receipt?.transactionHash,
                            'transaction',
                            cachedUserSelection.sourceChainId
                          )
                        )
                      }}
                      className={`button-hover-opacity flex justify-center rounded border-1 border-wombatPurple1 px-2 font-semibold text-wombatPurple1 ${
                        !isMd ? 'mt-2' : ''
                      }`}
                    >
                      View on Explorer
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {!isApproved ? (
            <WButton
              variant={Variant.PURPLE}
              width="w-full"
              height="h-10"
              disabled={currentProgress !== BridgeProgress.SEND_TOKENS}
              enableDimmed
              onClick={() => void tryApproval(amount, womToken?.decimals)}
              isLoading={approvalState === TokenApprovalState.LOADING}
            >
              {isTargetOnCurrentProgress(
                BridgeProgress.SET_TARGET_RECIPIENT,
                currentProgress,
                'AFTER_TARGET'
              )
                ? `Approve ${amount} ${TokenSymbols.WOM}`
                : 'Approve Tokens'}
            </WButton>
          ) : (
            !receipt && (
              <WButton
                variant={Variant.PURPLE}
                width="w-full"
                height="h-10"
                onClick={handleSendBtnOnClick}
                disabled={currentProgress !== BridgeProgress.SEND_TOKENS}
                isLoading={isTransferring}
                enableDimmed
              >
                {isTargetOnCurrentProgress(
                  BridgeProgress.SET_TARGET_RECIPIENT,
                  currentProgress,
                  'AFTER_TARGET'
                )
                  ? `Send ${amount} ${TokenSymbols.WOM}`
                  : 'Send Tokens'}
              </WButton>
            )
          )}
          {receipt && !signedVaa && blockDiff ? (
            <div className="flex flex-col gap-2">
              <TransferProgressBar
                currentProgress={currentTransferProgress}
                hash={receipt?.transactionHash}
              />
              <div className={`flex ${isMd ? 'flex-col' : ''}`}>
                {blockDiff &&
                blockDiff > 0 &&
                blockDiff < minBlockConfirmations &&
                isFetchingSignedVaa ? (
                  <div className={` ${isMd ? 'w-[100%]' : 'w-[70%]'} text-center`}>
                    Waiting for
                    <b>
                      &nbsp;{blockDiff}
                      &nbsp; /&nbsp;
                      {minBlockConfirmations}&nbsp;
                    </b>
                    confirmations on {chainInfos[sourceChainId].label}
                  </div>
                ) : (
                  isFetchingSignedVaa && (
                    <div className={` ${isMd ? 'w-[100%]' : 'w-[70%]'} text-center`}>
                      Waiting for Wormhole Network consensus...
                    </div>
                  )
                )}
                <button
                  onClick={() => {
                    if (!receipt?.transactionHash || !cachedUserSelection?.sourceChainId) return
                    window.open(
                      getExplorerLink(
                        receipt?.transactionHash,
                        'transaction',
                        cachedUserSelection.sourceChainId
                      )
                    )
                  }}
                  className={`button-hover-opacity flex ${
                    isMd ? 'mt-2 w-[100%]' : 'w-[30%]'
                  } justify-center rounded border-1 border-wombatPurple1 px-2 font-semibold text-wombatPurple1`}
                >
                  View on Explorer
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </BridgeTransferProgress>
      <BridgeTransferProgress title="Redeem Tokens" isDimmed={!signedVaa} isLastProgress>
        <div className="flex w-full flex-col">
          {!redeemTransaction && (
            <WButton
              variant={Variant.PURPLE}
              width="w-full"
              height="h-10"
              disabled={!signedVaa || isRedeemCompleted}
              onClick={handleRedeemBtnOnClick}
              isLoading={isRedeeming}
              enableDimmed
            >
              Redeem
            </WButton>
          )}
          {isRedeemCompleted && (
            <div className="w-full text-center text-wombatRed">
              This transaction has already redeemed
            </div>
          )}
          {redeemTransaction && (
            <div className="flex w-full flex-col gap-2">
              <div className="flex justify-center  rounded border-1 border-wombatBrown1 px-4 py-2">
                <p>
                  <b>Completed!&nbsp;</b> The redeem transaction was submitted. <br />
                  The tokens will become available once the transaction confirms.
                </p>
              </div>
              <div className="flex justify-between gap-2">
                <WButton
                  variant={Variant.YELLOW}
                  width="w-full"
                  height="h-10"
                  labelClassName="text-black"
                  onClick={() => {
                    if (!redeemTransaction || !targetChainId) return
                    window.open(getExplorerLink(redeemTransaction, 'transaction', targetChainId))
                  }}
                >
                  View on {chainInfos[targetChainId].explorerAlias}
                </WButton>
                <WButton
                  variant={Variant.YELLOW}
                  width="w-full"
                  height="h-10"
                  labelClassName="text-black"
                  onClick={addToken}
                >
                  Add to Metamask
                </WButton>
              </div>
            </div>
          )}
        </div>
      </BridgeTransferProgress>
      {currentModalState.currentModalId === ModalId.BRIDGE_TRANSFER_WAIT_FOR_CONFIRMATION && (
        <BridgeTransferConfirmationModal
          isOpen
          onClose={closeModal}
          recipient={recipient}
          bridgeAmount={amount}
        />
      )}
      {currentModalState.currentModalId ===
        ModalId.BRIDGE_TRANSFER_REDEEM_WAIT_FOR_CONFIRMATION && (
        <BridgeRedeemConfirmationModal
          isOpen
          onClose={closeModal}
          redeemTargetChainId={targetChainId}
          isDisplayGifFlyToTheMoon={isDisplayGifFlyToTheMoonForRedeem}
          bridgedTokenAmount={bridgedTokenAmount}
        />
      )}
    </>
  )
}

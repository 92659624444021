import { BigNumber } from 'ethers'
import { ASSETS } from '../../constants/contract/asset'
import { POOLS } from '../../constants/contract/pool'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { useWeb3 } from '../../context/Web3Context'
import { Modal } from './Modal'
import PoolInfoCard from './PoolInfoCard'
import { WithdrawalPageProvider } from './WithdrawalPageContext'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { MASTER_WOMBATS } from '../../constants/contract/masterWombat'
import { useContract } from 'wagmi'
import { MASTER_WOMBAT_V2_ABI } from '../../constants/contract/abis/masterWombat'
import { useEffect, useState } from 'react'
import { Asset } from '../../constants/contract/asset/Asset'
import PoolInfoCardMW2 from './PoolInfoCardMW2'
// import { useMigrationDetectionContext } from '../../context/MigrationDetectionContext'
import _ from 'lodash'
import { useDelistedData, useMw2Data } from '../../store/MigrationDetection/hooks'

export interface RewardInfo {
  symbol: TokenSymbol
  amount: BigNumber
  apr: BigNumber
}

export default function TokenWithdrawalPage() {
  // const { mw2Data, delistedData } = useMigrationDetectionContext()
  const mw2Data = useMw2Data()
  const delistedData = useDelistedData()
  const { chainId, signer, account } = useWeb3()
  const [mw2Assets, setMW2Assets] = useState<Asset[]>([])

  const mwContractV2 = useContract({
    ...MASTER_WOMBATS[chainId]?.MW2?.get(),
    abi: MASTER_WOMBAT_V2_ABI,
    signerOrProvider: signer,
  })

  useEffect(() => {
    const _mw2Assets: Asset[] = []
    Object.entries(mw2Data).forEach(([poolLabel, data]) => {
      Object.entries(data).map(([key, value]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const delistedAsset = delistedData[poolLabel][key]
        if (
          delistedAsset &&
          value.isWithdrawalNeeded &&
          !_.isEqual(value.lpAmountInTermsOfLp, delistedAsset.lpAmountInTermsOfLp)
        ) {
          _mw2Assets.push(value.asset)
        }
      })
    })

    setMW2Assets(_mw2Assets)
  }, [account, chainId, delistedData, mw2Data, mwContractV2])

  return (
    <WithdrawalPageProvider>
      <div className="mx-auto flex w-full max-w-[1088px] flex-col items-center font-Work">
        <div className="flex w-full flex-col justify-center pb-5 md:py-10">
          <div className="rounded-2xl py-6 px-2 text-black shadow-lg md:bg-white md:py-9 md:px-14 lg:px-20">
            {mw2Assets.length > 0 && (
              <div className="mb-3 flex items-center px-4 md:px-0">
                <hr className="h-0.5 w-6 flex-none border-0 bg-linearGradientApprove" />
                <div className="w-32 flex-none bg-linearGradientApprove bg-clip-text text-center font-Work text-lg font-bold text-transparent md:w-[22%]">
                  MasterWombat V2 (Deprecated)
                </div>
                <hr className="h-0.5 w-full border-0 bg-linearGradientApprove" />
              </div>
            )}
            {mw2Assets.map((asset) => (
              <PoolInfoCardMW2
                key={`${asset.poolLabel}_${asset.symbol}_TokenWithdrawalPage`}
                asset={asset}
              />
            ))}
            {Object.entries(ASSETS[chainId])
              .filter(([, assetsInPoolData]) =>
                Object.values(assetsInPoolData).some((assetData) => assetData.delisted)
              )
              .map(([poolLabel, assetsInPoolData]) => {
                const pool = POOLS[chainId][poolLabel as PoolLabels]
                return (
                  <div key={`${poolLabel}_TokenWithdrawalPage`}>
                    <div className="mb-3 flex items-center px-4 md:px-0">
                      <hr className="h-0.5 w-6 flex-none border-0 bg-linearGradientApprove" />
                      <div className="w-32 flex-none bg-linearGradientApprove bg-clip-text text-center font-Work text-lg font-bold text-transparent md:w-[22%]">
                        {pool?.name} (Deprecated)
                      </div>
                      <hr className="h-0.5 w-full border-0 bg-linearGradientApprove" />
                    </div>
                    <div className="flex flex-col px-4 pb-12 text-white md:px-0 md:pb-6">
                      {Object.values(assetsInPoolData)
                        .filter((asset) => asset.delisted)
                        .map((asset) => {
                          return (
                            <PoolInfoCard
                              key={`${poolLabel}_${asset.symbol}_TokenWithdrawalPage`}
                              asset={asset}
                            />
                          )
                        })}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      {<Modal />}
    </WithdrawalPageProvider>
  )
}

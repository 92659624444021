import clsx from 'clsx'
import Image from 'next/image'
import { ReactNode, useState } from 'react'
import { RemoveScroll } from 'react-remove-scroll'
import { useSearchParams } from 'react-router-dom'
import { usePoolCard } from '../../context/PoolCardContext'
import { usePoolPage } from '../../context/PoolPageContext'
import Back from '../../public/assets/icons/back.svg'
import setting from '../../public/assets/icons/setting.svg'
import { POOL_STATE } from '../PoolPage'
import SettingModal from '../SettingModal'
import styles from './pool.module.css'

interface Props {
  children: ReactNode
  nav: POOL_STATE[]
}

export default function PoolContainer({ children, nav }: Props) {
  const [isOpenSetting, setIsOpenSetting] = useState(false)
  const { poolState, resetPoolPageStates, setPoolState } = usePoolPage()
  const [searchParams, setSearchParams] = useSearchParams()
  const { resetPoolCardInput } = usePoolCard()

  return (
    <RemoveScroll removeScrollBar={true}>
      <div
        // z-index is below the banner's z-index
        className={clsx(
          'absolute left-0 top-0 z-[9] h-[100vh] w-screen bg-white bg-wombatBackgroundMobile md:h-screen',
          'bg-cover bg-fixed bg-center md:bg-wombatBackground',
          'overflow-y-auto pb-20'
        )}
        style={{ marginTop: 0 }}
      >
        <div className="mx-auto flex max-w-md flex-col items-center space-y-8 font-Work md:pt-20">
          <div className=" w-full rounded-2xl p-2 px-5 text-black md:relative md:bg-white md:px-7 md:py-5 md:shadow-lg">
            <div className="flex h-10 w-full items-center justify-between font-Quantico font-bold">
              <div className="absolute left-3 md:left-8">
                <button
                  className="button-hover-opacity -m-2 flex items-center p-2 text-sm text-wombatGrey"
                  onClick={() => {
                    resetPoolPageStates()
                    resetPoolCardInput()
                    searchParams.delete('pool')
                    searchParams.delete('token')
                    searchParams.delete('action')
                    setSearchParams(searchParams)
                  }}
                >
                  <Image src={Back} height={12} width={7} />
                  <span className="ml-1 font-Work font-semibold">BACK</span>
                </button>
              </div>
              <div className="flex w-full items-center justify-center">
                <div
                  className={`${styles.tab} tab flex h-5 bg-wombatCream p-1 font-Jamjuree text-sm md:mx-4 md:h-7 `}
                >
                  <button
                    className={`${
                      poolState === nav[0] ? styles.tab_active : 'button-hover-opacity'
                    } flex h-3 items-center px-2 uppercase md:h-5 md:px-4`}
                    onClick={() => setPoolState(nav[0])}
                  >
                    {nav[0]}
                  </button>
                  <button
                    className={`${
                      poolState === nav[1] ? styles.tab_active : 'button-hover-opacity'
                    } flex h-3 items-center px-2 uppercase md:h-5 md:px-4`}
                    onClick={() => setPoolState(nav[1])}
                  >
                    {nav[1]}
                  </button>
                </div>
              </div>
              {nav[0] !== POOL_STATE.STAKE && (
                <button
                  className="button-hover-opacity absolute right-7 flex h-10 items-center p-2"
                  onClick={() => setIsOpenSetting(true)}
                >
                  <Image layout="fixed" width={18} height={18} src={setting} />
                </button>
              )}
            </div>
            <SettingModal
              isOpen={isOpenSetting}
              onClose={() => {
                setIsOpenSetting(false)
              }}
            />
            <div className="pt-10">{children}</div>
          </div>
        </div>
      </div>
    </RemoveScroll>
  )
}

import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import { BigNumber, ContractTransaction, constants, utils } from 'ethers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TOKENS } from '../../constants/contract'
import { ASSETS, getSupportedChainIdsForCrossChainAssets } from '../../constants/contract/asset'
import { MasterWombatId } from '../../constants/contract/masterWombat'
import { POOLS } from '../../constants/contract/pool'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { PoolFilterGroup } from '../../constants/contract/pool/poolFilterGroup'
import { TokenSymbol, TokenSymbols } from '../../constants/contract/token/TokenSymbols'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { UserRewardInfo } from '../../context/MulticallDataContext/helpers/fetchBoostedPoolRewarderDataHelper'
import { UserInfo } from '../../context/MulticallDataContext/helpers/fetchPoolRewarderDataHelper'
import PoolPageProvider from '../../context/PoolPageContext'
import { useWeb3 } from '../../context/Web3Context'
import { useMasterWombat } from '../../context/masterWombatContext'
import useBoostedPoolRewarderAprs from '../../hooks/pool/useBoostedPoolRewarderAprs'
import useHandleClaim, { HandleClaimState } from '../../hooks/useHandleClaim'
import { useCashesData } from '../../store/Asset/hooks'
import {
  useBoostedPoolRewarderData,
  useMasterWombatDataWithAccountPoolRewards,
  usePoolRewarderData,
} from '../../store/MulticallData/hooks'
import { useTokenPrices } from '../../store/Prices/hooks'
import { useTradingVol24h } from '../../store/Subgraph/hooks'
import { calLPPrice } from '../../utils/math'
import { getTotalClaimableRewardInUsdWad } from '../../utils/pool'
import ClaimAllConfirmModal from '../ClaimAllConfirmModal'
import FilterDropdown from '../FilterDropdown/FilterDropdown'
import MigrationAlert from '../MigrationAlert'
import PoolList from '../PoolList'
import ToggleSlider from '../ToggleSlider'
import TokenImage from '../TokenImage'
import AppTooltip from '../Tooltip/AppTooltip'
import WButton, { Variant } from '../WButton'
import { PoolFilterSelection } from './PoolFilterSelection'
import { TokenReward } from './TokenReward'

export interface TokenInfo {
  displaySymbol: string
  symbol: TokenSymbol
  covRatio: number | null
  liability: number | null
  multichainLiability: number | null
  tvl: number | null
  multichainTVL: number | null
  baseAPR: number | null
  isBaseAprMoreThanZero: boolean
  boostAPR: number | null
  medianBoostedApr: number | null
  medianTotalApr: number | null
  myTotalApr: number | null
  reward: number | null
  tradingVol24h: number | null
  multiChainTradingVol24h: number | null
  bonusTokens: RewardInfo[] | null
  interestBearingApr?: string
  avgFeeSharingApr: { daily: string; weekly: string }
  poolLabel: PoolLabels
  supportedChainIds: SupportedChainId[]
  boostedRewarderRewardData: BoostedRewarderRewardInfo[]
}

export interface PoolInfo {
  tokenInfo: TokenInfo[]
}

export interface RewardInfo {
  displaySymbol: string
  symbol: TokenSymbol
  amount: BigNumber
  apr: BigNumber
  userInfo: UserInfo
}

export interface BoostedRewarderRewardInfo {
  displaySymbol: string
  symbol: TokenSymbol
  amount: string
  baseApr: string
  boostedApr: string
  averageApr: string
  userRewardInfo: UserRewardInfo | undefined
}

export enum POOL_STATE {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  STAKE = 'stake',
  UNSTAKE = 'unstake',
}

export default function PoolPage() {
  const { chainId, isSupported, account } = useWeb3()
  const rewards = useMasterWombatDataWithAccountPoolRewards()
  const poolRewarderData = usePoolRewarderData()
  const boostedPoolRewarderData = useBoostedPoolRewarderData()
  const [isOpenConfirmClaim, setIsOpenConfirmClaim] = useState<boolean>(false)
  const [filteredAssetSymbols, setFilteredAssetSymbols] = useState<string[]>([])
  const [isStakeFiltered, setIsStakeFiltered] = useState<boolean>(false)
  const [selectedPoolFilterGroup, setSelectedPoolFilterGroup] = useState<PoolFilterGroup>(
    PoolFilterGroup.ALL
  )
  const { boostedPoolRewarderAprs } = useBoostedPoolRewarderAprs()
  const [needActivate, setNeedActivate] = useState(false)
  const [skippedRewardTokens, setSkippedRewardTokens] = useState<TokenSymbol[]>([])

  const {
    userInfos,
    baseAprWads,
    averageBoostedAprs,
    boostedAprs,
    bonusTokenAprs,
    allBonusTokenRewards,
    avgFeeSharingAprs,
    interestBearingAprs,
  } = useMasterWombat()

  const { cashes, totalSupplies, liabilities, lpTokenToTokenRates } = useCashesData()

  const tradingVol24h = useTradingVol24h()
  const tokenPrices = useTokenPrices()

  const handleStakeFilterChange = () => {
    setIsStakeFiltered((prev) => !prev)
  }
  const availablePoolsBeforeFilterDropdown = useMemo(
    () =>
      Object.values(POOLS[chainId]).filter((pool) => {
        return (
          pool.supportedAssetTokenSymbols.length > 0 &&
          !pool.isHiddenInPoolPage &&
          // If selectedPoolFilterGroup is ALL, then show all pools
          (pool.filterGroup === selectedPoolFilterGroup ||
            selectedPoolFilterGroup === PoolFilterGroup.ALL) &&
          // some assets are not paused
          pool.supportedAssetTokenSymbols.some((assetTokenSymbol) => {
            return !ASSETS[chainId][pool.label]?.[assetTokenSymbol]?.paused
          })
        )
      }),
    [chainId, selectedPoolFilterGroup]
  )

  const poolInfos = useMemo<PoolInfo[]>(() => {
    const poolInfos: PoolInfo[] = availablePoolsBeforeFilterDropdown
      .filter(
        (pool) =>
          // For select pool filter group
          // If selectedPoolFilterGroup is ALL, then show all pools
          pool.filterGroup === selectedPoolFilterGroup ||
          selectedPoolFilterGroup === PoolFilterGroup.ALL
      )
      .map((pool) => {
        const tokenInfos: TokenInfo[] = []
        for (const tokenSymbol of pool.supportedAssetTokenSymbols) {
          const isAssetDelisted = ASSETS[chainId][pool.label][tokenSymbol]?.delisted
          // For filter dropdown
          if (!filteredAssetSymbols.includes(tokenSymbol) || isAssetDelisted) continue
          const cash = cashes[pool.label][tokenSymbol]
            ? Number(cashes[pool.label][tokenSymbol]) / 1e18
            : null
          const liability = liabilities[pool.label][tokenSymbol]
            ? Number(liabilities[pool.label][tokenSymbol]) / 1e18
            : null
          const covRatio = cash && liability ? cash / liability : null
          const baseAPR = baseAprWads[pool.label][tokenSymbol]
            ? Number(baseAprWads[pool.label][tokenSymbol]) / 1e18
            : null
          const isBaseAprMoreThanZero = !(
            baseAprWads[pool.label][tokenSymbol] || constants.Zero
          ).eq(0)
          const boostAPR = boostedAprs[pool.label][tokenSymbol]
            ? Number(boostedAprs[pool.label][tokenSymbol]) / 1e18
            : null
          const medianBoostedApr = Number(averageBoostedAprs[pool.label][tokenSymbol]) ?? null
          const tradingVol = tradingVol24h ? tradingVol24h[pool.label][tokenSymbol] : null
          const reward = rewards?.[pool.label][tokenSymbol]?.pendingRewards
            ? Number(rewards[pool.label][tokenSymbol]?.pendingRewards) / 1e18
            : null
          const asset = ASSETS[chainId][pool.label][tokenSymbol]
          const bonusTokens =
            (asset?.poolRewarder?.rewardTokenSymbols || [])
              .map((symbol, index) => {
                const bonusReward =
                  rewards?.[pool.label][tokenSymbol]?.pendingBonusRewards[index] ?? constants.Zero
                const userInfo = poolRewarderData.withAccount?.userInfo[pool.label][symbol]?.[index]

                if (
                  bonusReward.gt(constants.Zero) &&
                  userInfo &&
                  userInfo.rewardDebt.isZero() &&
                  userInfo.unpaidRewards.isZero()
                ) {
                  setNeedActivate(true)
                  setSkippedRewardTokens((skippedRewardTokens) => [...skippedRewardTokens, symbol])
                }

                return {
                  displaySymbol: TOKENS[chainId][symbol]?.displaySymbol || '',
                  symbol: symbol as TokenSymbol,
                  amount: bonusReward,
                  apr: bonusTokenAprs[pool.label][tokenSymbol]?.[index] ?? constants.Zero,
                  userInfo,
                }
              })
              .filter((value): value is RewardInfo => value !== undefined) ?? null
          /**
           * Boosted Rewarder
           */
          let totalBaseAprFromBoostedRewarder = 0
          let totalAvgBoostedAprFromBoostedRewarder = 0
          let totalBoostedAprFromBoostedRewarder = 0
          const boostedRewarderRewardData = (
            asset?.boostedPoolRewarder?.rewardTokenSymbols || []
          ).map((rewardSymbol, index) => {
            const aprs = boostedPoolRewarderAprs[pool.label][tokenSymbol]
            const baseApr = aprs?.base[index] || '0'
            const averageApr = aprs?.average[index] || '0'
            const boostedApr = aprs?.boosted[index] || '0'
            totalBaseAprFromBoostedRewarder += +baseApr
            totalAvgBoostedAprFromBoostedRewarder += +averageApr
            totalBoostedAprFromBoostedRewarder += +boostedApr

            const boostedPoolReward =
              boostedPoolRewarderData.withAccount?.pendingTokens[pool.label][tokenSymbol]?.[
                index
              ] || '0'
            const userRewardInfo =
              boostedPoolRewarderData.withAccount?.userRewardInfo[pool.label][tokenSymbol]?.[index]

            if (
              +boostedPoolReward &&
              userRewardInfo &&
              userRewardInfo.rewardDebt.isZero() &&
              userRewardInfo.unpaidRewards.isZero()
            ) {
              setNeedActivate(true)
              setSkippedRewardTokens((skippedRewardTokens) => [
                ...skippedRewardTokens,
                rewardSymbol,
              ])
            }

            return {
              displaySymbol: TOKENS[chainId][rewardSymbol]?.displaySymbol || '',
              symbol: rewardSymbol as TokenSymbol,
              amount: boostedPoolReward,
              baseApr,
              boostedApr,
              averageApr,
              userRewardInfo,
            }
          })
          const avgDailyFeeSharingApr = avgFeeSharingAprs[pool.label][tokenSymbol]?.daily || '0'
          const avgWeeklyFeeSharingApr = avgFeeSharingAprs[pool.label][tokenSymbol]?.weekly || '0'
          const interestBearingApr = interestBearingAprs?.[pool.label]?.[tokenSymbol]
          // sum all APRs
          const totalBonusApr =
            bonusTokens
              ?.map((rewardInfo) => Number(rewardInfo.apr) / 1e18)
              .reduce((partialSum, a) => partialSum + a, 0) ?? 0
          // only put average daily fee sharing apr into median total apr
          const medianTotalApr =
            (baseAPR ?? 0) +
            (medianBoostedApr ?? 0) +
            totalBonusApr +
            Number(avgDailyFeeSharingApr) / 100 +
            Number(interestBearingApr ?? '0') +
            totalAvgBoostedAprFromBoostedRewarder +
            totalBaseAprFromBoostedRewarder
          const stakedAmount = userInfos[pool.label][tokenSymbol]
            ? userInfos[pool.label][tokenSymbol]?.amount
            : constants.Zero
          const STAKED = Number(stakedAmount) > 0
          // only put average daily fee sharing apr into my total apr
          const myTotalApr = STAKED
            ? (baseAPR ?? 0) +
              (boostAPR ?? 0) +
              totalBonusApr +
              Number(avgDailyFeeSharingApr) / 100 +
              Number(interestBearingApr ?? '0') +
              totalBoostedAprFromBoostedRewarder +
              totalBaseAprFromBoostedRewarder
            : null
          /** @TODO  liabilities is in term of token not lp https://www.notion.so/Refactor-calLpPrice-a1d0ac7ab39e45ae805a3103c37f7409   */
          const tvlInWei = calLPPrice(
            totalSupplies[pool.label][tokenSymbol] ?? null,
            lpTokenToTokenRates[pool.label][tokenSymbol] ?? null,
            strToWad(tokenPrices[tokenSymbol])
          )

          const tvl = tvlInWei ? Number(utils.formatEther(tvlInWei)) : null

          const supportedChainIds =
            pool.label === PoolLabels.CROSS_CHAIN
              ? getSupportedChainIdsForCrossChainAssets(tokenSymbol)
              : [chainId]
          const p: TokenInfo = {
            displaySymbol: TOKENS[chainId][tokenSymbol]?.displaySymbol || '',
            symbol: tokenSymbol,
            covRatio,
            liability,
            multichainLiability: 0,
            tvl,
            multichainTVL: 0,
            baseAPR,
            boostAPR,
            medianBoostedApr,
            medianTotalApr,
            myTotalApr,
            reward,
            tradingVol24h: tradingVol,
            multiChainTradingVol24h: 0,
            bonusTokens,
            isBaseAprMoreThanZero,
            interestBearingApr,
            avgFeeSharingApr: {
              daily: avgDailyFeeSharingApr,
              weekly: avgWeeklyFeeSharingApr,
            },
            poolLabel: pool.label,
            supportedChainIds,
            boostedRewarderRewardData,
          }
          tokenInfos.push(p)
        }

        return { tokenInfo: tokenInfos }
      })

    return poolInfos
  }, [
    availablePoolsBeforeFilterDropdown,
    selectedPoolFilterGroup,
    chainId,
    filteredAssetSymbols,
    cashes,
    liabilities,
    baseAprWads,
    boostedAprs,
    averageBoostedAprs,
    tradingVol24h,
    rewards,
    avgFeeSharingAprs,
    interestBearingAprs,
    userInfos,
    totalSupplies,
    lpTokenToTokenRates,
    tokenPrices,
    poolRewarderData.withAccount?.userInfo,
    bonusTokenAprs,
    boostedPoolRewarderAprs,
    boostedPoolRewarderData.withAccount?.pendingTokens,
    boostedPoolRewarderData.withAccount?.userRewardInfo,
  ])

  const allTokenInfos: TokenInfo[] = useMemo(() => {
    const allTokenInfosForDisplay: TokenInfo[] = []
    for (const item of poolInfos) {
      allTokenInfosForDisplay.push(...item.tokenInfo)
    }

    const filteredAllTokenInfosForDisplay = allTokenInfosForDisplay.filter((tokenInfo) => {
      // stakedAmount > 0
      return userInfos[tokenInfo.poolLabel][tokenInfo.symbol]?.amount.gt(0)
    })

    return isStakeFiltered ? filteredAllTokenInfosForDisplay : allTokenInfosForDisplay
  }, [isStakeFiltered, poolInfos, userInfos])

  const [searchInputForAssets, setSearchInputForAssets] = useState('')
  // For asset's filter dropdown
  const availableAssetTokenSymbols = useMemo<{
    original: TokenSymbol[]
  }>(() => {
    const originalTokenSymbols: TokenSymbol[] = []
    for (const pool of availablePoolsBeforeFilterDropdown) {
      const poolSymbol = pool.label

      for (const assetTokenSymbol of pool.supportedAssetTokenSymbols) {
        const asset = ASSETS[chainId][poolSymbol]?.[assetTokenSymbol]
        const isPaused = asset?.paused
        if (isPaused) continue
        if (!originalTokenSymbols.includes(assetTokenSymbol)) {
          originalTokenSymbols.push(assetTokenSymbol)
        }
      }
    }

    return { original: originalTokenSymbols }
  }, [availablePoolsBeforeFilterDropdown, chainId])

  const [refreshPoolData, setRefreshPoolData] = useState<boolean>(false)
  const isClaimAble = (allBonusTokenRewards?.pendingRewards || constants.Zero)
    .add(
      allBonusTokenRewards?.pendingBonusRewards.reduce(
        (prev, curr) => prev.add(curr),
        constants.Zero
      ) || constants.Zero
    )
    .gt(constants.Zero)

  const { tryHandleClaim, claimState, isNewTxn } = useHandleClaim(onTxnSubmited)
  // it contains wom and other bouns rewards
  const rewardsBreakdown = useMemo(() => {
    const bonusRewards = allBonusTokenRewards.bonusTokenSymbols.reduce(
      (breakdown, bonusRewardSymbol, index) => {
        const bonusRewardToken = TOKENS[chainId][bonusRewardSymbol]
        if (!bonusRewardToken) return breakdown
        const bounsRewardDecimals = bonusRewardToken?.decimals
        const previousBonusReward = breakdown[bonusRewardSymbol] || '0'
        const newBonusReward = allBonusTokenRewards?.pendingBonusRewards[index]

        if (!skippedRewardTokens.includes(bonusRewardSymbol)) {
          breakdown[bonusRewardSymbol] = utils.formatUnits(
            utils.parseUnits(previousBonusReward, bounsRewardDecimals).add(newBonusReward),
            bounsRewardDecimals
          )
        }
        return breakdown
      },
      {} as { [id: string]: string }
    )
    /** @todo should get wom decimal */
    const womReward = {
      [TokenSymbols.WOM]: utils.formatUnits(allBonusTokenRewards.pendingRewards, 18),
    }

    // total WOM reward = WOM from pendingRewards + WOM from bonusRewards
    const totalWomRewardFromPendingRewardAndRewarder = utils.formatUnits(
      constants.Zero.add(utils.parseEther(bonusRewards[TokenSymbols.WOM] || '0')).add(
        utils.parseEther(womReward[TokenSymbols.WOM] || '0')
      ),
      18
    )

    return (
      // turn it into an array
      Object.entries({
        ...bonusRewards,
        [TokenSymbols.WOM]: totalWomRewardFromPendingRewardAndRewarder,
      })
        .map(([tokenSymbol, amount]) => {
          return {
            tokenSymbol,
            amount,
          }
        })
        // filter out rewards with 0 amount
        .filter((reward) => {
          const bonusRewardSymbol = reward.tokenSymbol as TokenSymbol
          const bonusRewardToken = TOKENS[chainId][bonusRewardSymbol]
          const bounsRewardDecimals = bonusRewardToken?.decimals ?? 18

          return !utils.parseUnits(reward.amount, bounsRewardDecimals).isZero()
        })
    )
  }, [
    allBonusTokenRewards.bonusTokenSymbols,
    allBonusTokenRewards.pendingRewards,
    allBonusTokenRewards?.pendingBonusRewards,
    chainId,
    skippedRewardTokens,
  ])

  const totalClaimableRewardsInUsdWad = getTotalClaimableRewardInUsdWad(
    rewardsBreakdown,
    tokenPrices
  )

  const isSelectedPoolFilterGroupEmpty = useMemo(() => {
    const poolsInSelectedFilterGroup = Object.values(POOLS[chainId]).filter(
      (p) => p.filterGroup === selectedPoolFilterGroup
    )
    if (!poolsInSelectedFilterGroup.length) return true
    for (const pool of poolsInSelectedFilterGroup) {
      const assetTokenSymbols = pool.supportedAssetTokenSymbols

      return (
        !assetTokenSymbols.length ||
        assetTokenSymbols.every((assetTokenSymbol) => {
          const asset = ASSETS[chainId][pool.label][assetTokenSymbol]
          return asset?.paused || asset?.delisted
        })
      )
    }
  }, [chainId, selectedPoolFilterGroup])

  const onCloseConfirmClaim = () => {
    setIsOpenConfirmClaim(false)
  }

  function onTxnSubmited(txn: ContractTransaction | null) {
    txn?.wait()?.then(() => {
      setRefreshPoolData(true)
    })
  }
  useEffect(() => {
    if (refreshPoolData) {
      setRefreshPoolData(false)
    }
  }, [refreshPoolData, setRefreshPoolData])

  const handleAssetFilterDropdownChange = useCallback((filteredList: string[]) => {
    setFilteredAssetSymbols(filteredList)
  }, [])

  const handlePoolSelectionChange = useCallback((filterGroup: PoolFilterGroup) => {
    setSelectedPoolFilterGroup(filterGroup)
  }, [])

  return (
    <PoolPageProvider>
      <div className="mx-auto flex min-h-[100vh] w-full max-w-[1088px] flex-col items-center font-Work">
        {claimState === HandleClaimState.LOADING && (
          <ClaimAllConfirmModal
            isOpen={isOpenConfirmClaim}
            onClose={onCloseConfirmClaim}
            isNewTxn={isNewTxn}
            rewards={rewardsBreakdown}
          />
        )}
        <div className={`flex w-full flex-col justify-center pb-5 md:py-10`}>
          <div className="rounded-2xl px-2 py-6 text-black shadow-lg max-md:shadow-none md:bg-white md:px-14 md:py-9 lg:px-20">
            <MigrationAlert
              masterWombatId={MasterWombatId.MW2}
              message="To continue earning rewards
              on Wombat, you must migrate your funds to the new contract."
            />

            <div className="mx-4 mb-4 flex justify-between rounded-lg bg-white px-4 py-2.5 shadow-white1 md:mx-0 md:mb-6 md:py-4 md:pl-10 md:pr-6">
              <div className="flex flex-col md:flex-row md:items-center">
                <h3 className="font-Work text-[14px] font-semibold text-wombatBrown md:text-[20px]">
                  Total Claimable
                </h3>

                <h3
                  className={`value text-medium w-fit flex-col text-[14px] text-sm font-semibold text-[#1B0521] md:ml-5 md:mt-0 md:text-[20px] ${
                    account && isClaimAble ? 'hover-text' : ''
                  }`}
                >
                  {account && isClaimAble ? (
                    <div className="flex">
                      <AppTooltip
                        message={
                          <div className="flex flex-col p-2">
                            {rewardsBreakdown.map(({ tokenSymbol, amount }) => {
                              return (
                                <div key={tokenSymbol} className="[&:not(:last-child)]:mb-2">
                                  <TokenReward
                                    symbol={tokenSymbol as TokenSymbol}
                                    amount={amount}
                                    isTextWhite
                                  />
                                </div>
                              )
                            })}
                          </div>
                        }
                        fullWidth
                        padding="sm"
                        place="right"
                      >
                        {account && isClaimAble
                          ? `$${getCommifiedFormat(totalClaimableRewardsInUsdWad)}`
                          : '-'}
                      </AppTooltip>
                    </div>
                  ) : (
                    '-'
                  )}
                </h3>
              </div>
              {needActivate ? (
                <WButton
                  variant={Variant.GRADIENT}
                  className="pool-claim-all my-auto"
                  onClick={() => {
                    setIsOpenConfirmClaim(true)
                    tryHandleClaim()
                  }}
                >
                  <span className="text-[13px]">ACTIVATE & CLAIM ALL</span>
                </WButton>
              ) : (
                <WButton
                  variant={isSupported && isClaimAble ? Variant.GRADIENT : Variant.LIGHT_PURPLE}
                  disabled={!isSupported || !isClaimAble}
                  className="pool-claim-all my-auto"
                  onClick={() => {
                    setIsOpenConfirmClaim(true)
                    tryHandleClaim()
                  }}
                >
                  CLAIM ALL
                </WButton>
              )}
            </div>
            <div className="mx-4 mb-2 flex flex-col gap-2 md:mx-0 md:flex-row md:gap-6">
              <div className="flex flex-1">
                <PoolFilterSelection
                  onPoolFilterSelectionChange={handlePoolSelectionChange}
                  selectedPoolFilterGroup={selectedPoolFilterGroup}
                />
                {/* @todo revamped tokens filter */}
              </div>
              <div className="flex flex-1 justify-between">
                {/* Staked Toggle  */}
                <ToggleSlider
                  title="STAKED"
                  onToggleChange={handleStakeFilterChange}
                  isChecked={isStakeFiltered}
                />
                {/* Asset filter */}
                <FilterDropdown
                  placeholderProps={{
                    text: 'Search Tokens',
                  }}
                  searchBarProps={{
                    onSearch: (value) => {
                      setSearchInputForAssets(value)
                    },
                  }}
                  width="60%"
                  onOptionsChange={handleAssetFilterDropdownChange}
                  enableUnderline
                >
                  {availableAssetTokenSymbols.original.map((assetTokenSymbol) => {
                    // filter assets by search input
                    if (
                      !assetTokenSymbol.toLowerCase().includes(searchInputForAssets.toLowerCase())
                    )
                      return
                    const asset = TOKENS[chainId][assetTokenSymbol]
                    return (
                      <FilterDropdown.Option key={assetTokenSymbol} value={assetTokenSymbol}>
                        <p className="flex flex-row items-center gap-2">
                          <TokenImage tokenSymbol={assetTokenSymbol} />
                          {asset?.displaySymbol}
                        </p>
                      </FilterDropdown.Option>
                    )
                  })}
                </FilterDropdown>
              </div>
            </div>
            {allTokenInfos.length > 0 ? (
              <PoolList
                tokensInfo={allTokenInfos}
                selectedPoolFilterGroup={selectedPoolFilterGroup}
              />
            ) : (
              <div className="flex h-[120px] flex-col justify-center text-center font-Work">
                <p className="text-[16px] font-semibold text-wombatBrown1">
                  {isSelectedPoolFilterGroupEmpty ? 'No Pools Found' : 'Nothing Selected'}
                </p>
                {!isSelectedPoolFilterGroupEmpty && (
                  <p className="text-[14px] font-normal text-[#B7A38E]">
                    Select an item in the list to filter your search.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </PoolPageProvider>
  )
}

import React from 'react'
import Image from 'next/image'
import { chainInfos } from '../../constants/web3/chains'
import { SUPPORTED_CHAIN_IDS, SupportedChainId } from '../../constants/web3/supportedChainId'
import DropdownInput from '../inputs/DropdownInput'
import { ZERO_ADDRESS } from '../../constants/common'

interface ChainSelectionDropdown {
  chainId: SupportedChainId
  connectedChainId?: SupportedChainId
  width: string
  onSelectionChange: (selectedChainId: SupportedChainId) => void
  disabled?: boolean
  enablePortal?: boolean
  className?: string
  showCrossChainLabel?: boolean
  showCrossChainAvailableOnly?: boolean
}

function ChainSelectionDropdown({
  chainId: selectedChainId,
  connectedChainId,
  width,
  onSelectionChange,
  disabled = false,
  enablePortal = true,
  className,
  showCrossChainLabel,
  showCrossChainAvailableOnly,
}: ChainSelectionDropdown) {
  const chainInfo = chainInfos[selectedChainId]

  return (
    <DropdownInput
      placeholderProps={{
        text: (
          <span>
            {chainInfo.label}&nbsp;
            {showCrossChainLabel && (
              <span className="text-[12px] font-normal">
                ({connectedChainId === selectedChainId ? 'same' : 'cross'} chain)
              </span>
            )}
          </span>
        ),
        icon: <Image layout="fixed" src={chainInfo.icon} width={20} height={20}></Image>,
        fontStyle: 'text-[14px] font-Work',
      }}
      optionsMaxHeight="190px"
      width={width}
      enablePortal={enablePortal}
      disabled={disabled}
      className={className}
    >
      <DropdownOptions
        onSelectionChange={onSelectionChange}
        selectedChainId={selectedChainId}
        showCrossChainAvailableOnly={showCrossChainAvailableOnly}
      />
    </DropdownInput>
  )
}

export default ChainSelectionDropdown

interface DropdownOptions {
  onSelectionChange: (selectedChainId: SupportedChainId) => void
  selectedChainId: SupportedChainId
  showCrossChainAvailableOnly?: boolean
}

const DropdownOptions = ({
  onSelectionChange,
  selectedChainId,
  showCrossChainAvailableOnly,
}: DropdownOptions) => {
  return (
    <>
      {SUPPORTED_CHAIN_IDS.filter((chainId) => {
        const wormholeConfig = chainInfos[chainId].wormholeConfig
        if (
          !(
            wormholeConfig?.chainId &&
            wormholeConfig.coreBridgeAddress !== ZERO_ADDRESS &&
            wormholeConfig.tokenBridgeAddress !== ZERO_ADDRESS
          )
        )
          return false
        if (showCrossChainAvailableOnly) {
          return chainId !== selectedChainId && chainInfos[chainId].isCrossChainAvailable
        }
        return chainId !== selectedChainId
      }).map((networkChainId) => {
        const chainInfo = chainInfos[networkChainId]
        return (
          <DropdownInput.Option
            key={chainInfo.id}
            value={networkChainId}
            onClick={(chainId) => {
              if (chainId) {
                onSelectionChange(chainId)
              }
            }}
          >
            <div className="flex items-center">
              <Image layout="fixed" src={chainInfo.icon} width={20} height={20}></Image>
            </div>
            <div className="flex text-[14px]"> {chainInfo.label}</div>
          </DropdownInput.Option>
        )
      })}
    </>
  )
}

import { SubgraphState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import getBlock24hAgo from './thunks/getBlock24hAgo'
import getSubgraphData from './thunks/getSubgraphData'
import { getInitialSubgraphProperty } from './helper'

const initialState: SubgraphState = {
  block24hAgo: null,
  tradingVol24h: getInitialSubgraphProperty(),
  medianBoostedAprs: getInitialSubgraphProperty(),
}

const subgraphSlice = createSlice({
  name: 'subgraph',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBlock24hAgo.fulfilled, (state, action) => {
      if (action.payload) {
        state.block24hAgo = action.payload.block24hAgo
      }
    })

    builder.addCase(getSubgraphData.fulfilled, (state, action) => {
      if (action.payload) {
        state.tradingVol24h = action.payload.tradingVol24h
        state.medianBoostedAprs = action.payload.medianBoostedAprs
      }
    })
  },
})

export default subgraphSlice.reducer

import React, { useState } from 'react'
import { CrossChainCase } from '../../../constants/crossChain'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import Image from 'next/image'
import { HexString } from '../../../interfaces/contract'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import warningIcon from '../../../public/assets/icons/warning-icon.svg'
import arrowRight from '../../../public/assets/icons/arrow-right-with-tail.svg'
import { chainInfos } from '../../../constants/web3/chains'
import { TOKENS } from '../../../constants/contract'
import { getExplorerLink } from '../../../utils/getBscScanLink'
import viewOnIcon from '../../../public/assets/icons/blank-page.svg'
import tickIcon from '../../../public/assets/icons/tick-filled.svg'
import clockIcon from '../../../public/assets/icons/clock.svg'
import Button, { ButtonProps } from '../../Button'
import { getCommifiedFormat } from '@hailstonelabs/big-number-utils'
import { useWeb3 } from '../../../context/Web3Context'
import { estimateDeliveryFeeWad, redelivery } from '../../../utils/crossChainSwap'
import { CROSS_CHAIN_SWAP_GAS_LIMIT } from '../../../constants/common'
import { useModalContext } from '../../../context/ModalContext'
import { ModalId } from '../../../interfaces/modal'
import { useCrossChainSwapTxStore } from '../../../hooks/crossChainSwap/useCrossChainSwapTx'
import { NATIVE_WRAPPED_TOKEN_IN_CHAIN } from '../../../constants/contract/token'

export type ChainData = {
  chainId: SupportedChainId
  tokenSymbol: TokenSymbol
  amount: string
  txnHash?: HexString
}

export type SourceChainData = Omit<ChainData, 'tokenSymbol'> & { tokenSymbol: TokenSymbol | null }
type CommonProps = {
  status: CrossChainCase
  source: SourceChainData
  target: ChainData
  sequence: string
  receiverValue: string
  isCreditToToken: boolean
}

type DefaultProps = {
  status: Exclude<CommonProps['status'], CrossChainCase.RECEIVED_CREDIT>
} & CommonProps

type CreditProps = {
  status: CrossChainCase.RECEIVED_CREDIT
  creditReceived: string
} & CommonProps

type Props = DefaultProps | CreditProps

const statusIconPropsMap: {
  [id in 'success' | 'warning' | 'pending']: {
    path: string
    size: number
  }
} = {
  success: {
    path: tickIcon,
    size: 24,
  },
  warning: {
    path: warningIcon,
    size: 30,
  },
  pending: {
    path: clockIcon,
    size: 24,
  },
}
export default function CrossChainStatusRecord({
  source,
  target,
  sequence,
  receiverValue,
  isCreditToToken,
  ...props
}: Props) {
  const { switchNetwork, chainId: currentChainId, account } = useWeb3()
  const { removeCrossChainSwapTxn, appendCrossChainSwapTx } = useCrossChainSwapTxStore()
  const [isCtaButtonLoading, setIsCtaButtonLoading] = useState(false)
  const {
    actions: { openModal },
  } = useModalContext()
  let warningMessage: string | null = null
  let statusTitle = 'Transaction succeeded'
  let ctaButtonProps: {
    label: string
    onClick: () => void
    variant?: ButtonProps['variant']
  } | null = null
  let statusIconProps = statusIconPropsMap.success
  // source
  const sourceChainId = source.chainId
  const sourceToken = source.tokenSymbol ? TOKENS[sourceChainId][source.tokenSymbol] : null
  const sourceHash = source.txnHash
  // target
  const targetChainId = target.chainId
  const targetToken = TOKENS[targetChainId][target.tokenSymbol]
  const targetHash = target.txnHash

  /**
   * update dislpay data based on status
   */
  switch (props.status) {
    case CrossChainCase.NEED_REDELIVERY:
      warningMessage = 'Redeliver to complete the swap'
      statusTitle = 'Transaction failed'
      ctaButtonProps = {
        label: 'Redeliver',
        onClick: async () => {
          if (!account) throw new Error('No wallet connected')
          try {
            setIsCtaButtonLoading(true)
            const deliveryFeeWad = await estimateDeliveryFeeWad({
              fromChainId: sourceChainId,
              toChainId: targetChainId,
              receiverValue,
              gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
            })
            const txnHash = await redelivery({
              fromChainId: sourceChainId,
              toChainId: targetChainId,
              sequence: +sequence,
              deliveryFeeWad,
              receiverValue,
              gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
            })
            openModal({
              currentModalId: ModalId.TRANSACTION_SUBMITTED,
              payload: { transactionHashes: [txnHash] },
            })
            /** remove the old transaction */
            removeCrossChainSwapTxn(sourceChainId, sequence)
            /** append a new transaction */
            const commonFnArgs = {
              fromChainId: sourceChainId,
              fromTxhash: txnHash,
              receiverValue,
              toChainId: targetChainId,
              toTokenAmount: target.amount,
              toTokenSymbol: target.tokenSymbol,
              userAddress: account,
            }
            if (isCreditToToken) {
              appendCrossChainSwapTx({
                isCreditToToken: true,
                fromCreditAmount: source.amount,
                ...commonFnArgs,
              })
            } else if (source.tokenSymbol) {
              appendCrossChainSwapTx({
                isCreditToToken: false,
                fromTokenAmount: source.amount,
                fromTokenSymbol: source.tokenSymbol,
                ...commonFnArgs,
              })
            }
          } catch (err) {
            console.log(err)
          } finally {
            setIsCtaButtonLoading(false)
          }
        },
      }
      statusIconProps = statusIconPropsMap.warning
      break
    case CrossChainCase.RECEIVED_CREDIT:
      warningMessage = `${getCommifiedFormat(
        props.creditReceived
      )} credit distributed on destination chain`
      statusTitle = 'Transaction failed'
      ctaButtonProps =
        currentChainId !== targetChainId
          ? {
              label: 'Switch chain',
              onClick: () => {
                switchNetwork(targetChainId)
              },
              variant: 'outlined',
            }
          : null
      statusIconProps = statusIconPropsMap.warning
      break
    case CrossChainCase.PENDING:
      statusTitle = 'Transaction processing'
      statusIconProps = statusIconPropsMap.pending
      break
    default:
      break
  }

  /**
   * main UI
   */
  return (
    <div className="group grid grid-cols-[35px_1fr_50px] items-center">
      <Image
        src={statusIconProps.path}
        width={statusIconProps.size}
        height={statusIconProps.size}
      />
      {/* Main body */}
      <div className="relative flex flex-col gap-1 max-md:items-center">
        <p className="text-sm font-semibold">{statusTitle}</p>
        {warningMessage && <p className="text-xs text-wombatRed">{warningMessage}</p>}
        {/* Source -> Target data */}
        <div className="relative flex items-center max-md:flex-col">
          <div className="flex justify-center">
            <Image src={chainInfos[sourceChainId].icon} width={16} height={16} />
            <span>
              &nbsp;{getCommifiedFormat(source.amount)}{' '}
              {isCreditToToken ? 'CR' : sourceToken?.displaySymbol}&nbsp;
            </span>
          </div>
          <div className="relative mx-[10px] inline-flex items-center max-md:rotate-90 ">
            <Image src={arrowRight} height={16} width={16} />
          </div>
          <div className="flex flex-row justify-center gap-1">
            <Image src={chainInfos[targetChainId].icon} width={16} height={16} />
            <div className="flex flex-col">
              <span className="leading-none">
                {getCommifiedFormat(target.amount)} {targetToken?.displaySymbol}
              </span>
              <span className="text-xs text-wombatBrown1">
                Gas: {getCommifiedFormat(receiverValue)}{' '}
                {TOKENS[targetChainId][NATIVE_WRAPPED_TOKEN_IN_CHAIN[targetChainId]]?.displaySymbol}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Transaction links */}
      <div className="ml-auto flex gap-2">
        {[
          { txnHash: sourceHash, chainId: sourceChainId },
          { txnHash: targetHash, chainId: targetChainId },
        ].map(({ txnHash, chainId }) => {
          if (!txnHash) return
          return (
            <a
              key={txnHash}
              className="cursor-pointer"
              href={getExplorerLink(txnHash, 'transaction', chainId)}
              target="_blank"
              rel="noreferrer"
            >
              <Image src={viewOnIcon} height={18} width={18} />
            </a>
          )
        })}
      </div>
      {/* CTA button */}
      {ctaButtonProps && (
        <Button
          className="col-start-2 row-start-2 mt-2 w-full bg-none px-[10px] py-1"
          style={{ fontSize: 'inherit' }}
          onClick={ctaButtonProps.onClick}
          variant={ctaButtonProps.variant}
          isLoading={isCtaButtonLoading}
        >
          {ctaButtonProps.label}
        </Button>
      )}
      {/* Separator */}
      <hr className="col-span-full row-start-3 my-4 w-full border-[#DBD1C6] group-last-of-type:hidden" />
    </div>
  )
}

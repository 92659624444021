import { useEffect, useRef, useState } from 'react'
import styles from './Slider.module.css'

interface Props {
  value: string
  callBack: (value: string) => void
  hasHeadValue?: boolean
}

export default function Slider({ value, callBack, hasHeadValue = false }: Props) {
  const [smartLeft, setSmartLeft] = useState<string>('3')
  const rangeRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (rangeRef.current) {
      const defaultLeft = Number.isInteger(Number(value)) ? 30 : 45
      const cal =
        (Number(rangeRef.current?.value) / 100) * (rangeRef.current?.clientWidth - defaultLeft) + 3
      setSmartLeft(cal.toString())
    }
  }, [smartLeft, value])
  return (
    <div className="md:mt-2">
      {hasHeadValue && (
        <span
          style={{ left: `${Number(smartLeft)}px` }}
          className="relative select-none text-sm text-wombatBrown1"
        >
          {Number(value) > 100 ? 100 : value}%
        </span>
      )}
      <div className="relative">
        <div
          className="absolute left-[25%] top-[30%] h-1.5 w-2 bg-wombatCream opacity-30"
          onClick={() => callBack('25')}
        />
        <div
          className="absolute left-[49%] top-[30%] h-1.5 w-2 bg-wombatCream opacity-30"
          onClick={() => callBack('50')}
        />
        <div
          className="absolute left-[72%] top-[30%] h-1.5 w-2 bg-wombatCream opacity-30"
          onClick={() => callBack('75')}
        />
        <input
          ref={rangeRef}
          type="range"
          min="0"
          max="100"
          value={value}
          className={`${styles.sliderBar}  mb-5`}
          onChange={(e) => {
            const cal = (Number(e.target.value) / 100) * (e.target.clientWidth - 30) + 3
            setSmartLeft(cal.toString())
            callBack(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

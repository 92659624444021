import Image from 'next/image'
import { useMemo, useRef } from 'react'
import WombatToTheMoonFlyUp from '../../../public/assets/animations/ToTheMoon2.gif'
import WombatToTheMoonNoText from '../../../public/assets/animations/ToTheMoon_noText.gif'
import Modal from '../../Modal'
import useDidClickOutside from '../../../hooks/useDidClickOutside'
import { TokenSymbols } from '../../../configx/tokenSymbols'
import TokenImage from '../../TokenImage'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { chainInfos } from '../../../constants/web3/chains'

type Props = {
  isOpen: boolean
  onClose: () => void
  redeemTargetChainId: SupportedChainId
  isDisplayGifFlyToTheMoon: boolean
  bridgedTokenAmount: string
}

export default function BridgeRedeemConfirmationModal({
  isOpen,
  onClose,
  redeemTargetChainId,
  isDisplayGifFlyToTheMoon,
  bridgedTokenAmount,
}: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })
  const chainInfo = chainInfos[redeemTargetChainId]

  const renderGif = useMemo(() => {
    if (isDisplayGifFlyToTheMoon) {
      return (
        <div className="absolute -top-60">
          <Image src={WombatToTheMoonFlyUp} layout="fixed" width={105} height={525} unoptimized />
        </div>
      )
    }

    return <Image src={WombatToTheMoonNoText} layout="fixed" width={70} height={70} unoptimized />
  }, [isDisplayGifFlyToTheMoon])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<div className="text-wombatPurple">Redeem Tokens</div>}
      isCenterTitle
      isOverflowHidden
    >
      <div className="relative flex flex-col items-center justify-center">
        <div className="item-center my-3 flex flex-col gap-1">
          <span className="text-center font-Work text-xs text-black">
            Please confirm the transaction in your wallet
          </span>
          <div className="item-center flex justify-center gap-1">
            <div className="font-bold">
              {bridgedTokenAmount} {TokenSymbols.WOM}
            </div>
            <TokenImage tokenSymbol={TokenSymbols.WOM} width="16px" height="16px" />
          </div>
          <div className="item-center flex justify-center gap-1">
            <Image src={chainInfo.icon} width={16} height={16}></Image>
            <div>{chainInfo.label}</div>
          </div>
        </div>
        <div className="flex flex-col items-center py-10">
          <div
            style={{
              paddingBottom: `${isDisplayGifFlyToTheMoon ? '70px' : ''}`,
            }}
            className="flex items-center justify-center"
          >
            {renderGif}
          </div>
        </div>
      </div>
    </Modal>
  )
}

import { BigNumber, constants, utils } from 'ethers'
import { nativeToWAD, safeWdiv, strToWad, wmul } from '@hailstonelabs/big-number-utils'
import { ISwapPathData, SwapQuoteType } from '../interfaces/swap'
import { GetContractResult } from '@wagmi/core'
import { ROUTER_ABI } from '../constants/contract/abis/router'
import { TOKENS } from '../constants/contract'
import { SupportedChainId } from '../constants/web3/supportedChainId'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'
import { Token } from '../constants/contract/token/Token'
import { INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET } from '../constants/common'
import { HexString } from '../interfaces/contract'
import { calculateGasMargin } from '.'
import { chainInfos, getSupportedChainIdByNetwork } from '../constants/web3/chains'
import { getFilteredTokenMaps } from './router'

/**
 * price impact formula = (market rate - quoted rate) / market rate
 * @param {string} targetfromTokenAmount
 * @param {string} targetToTokenAmount
 * @param {string} marketFromTokenAmount
 * @param {string} marketToTokenAmount
 * @returns {BigNumber} percent in WAD
 */
export const getPriceImpactWad = (
  targetfromTokenAmount: string,
  targetToTokenAmount: string,
  marketFromTokenAmount: string,
  marketToTokenAmount: string
): BigNumber => {
  try {
    // rate = toAmount/fromAmount
    const targetToTokenAmountWad = strToWad(targetToTokenAmount)
    const targetFromTokenAmountWad = strToWad(targetfromTokenAmount)
    const marketToTokenAmountWad = strToWad(marketToTokenAmount)
    const marketFromTokenAmountWad = strToWad(marketFromTokenAmount)
    if (targetFromTokenAmountWad.eq('0') || marketFromTokenAmountWad.eq('0')) {
      return constants.Zero
    }
    const quotedRateWad = safeWdiv(targetToTokenAmountWad, targetFromTokenAmountWad)
    const marketRateWad = safeWdiv(marketToTokenAmountWad, marketFromTokenAmountWad)
    if (marketRateWad.isZero()) {
      return constants.Zero
    }
    // price impact formula = (market rate - quoted rate) / market rate
    const priceImpactWad = safeWdiv(marketRateWad.sub(quotedRateWad), marketRateWad)
    if (priceImpactWad.isNegative()) {
      return constants.Zero
    }
    return priceImpactWad
  } catch {
    return constants.Zero
  }
}

/**
 * Calculates the potential swap quotes for a given input token amount and swap path,
 * both for the target token (the token being bought/sold) and the market token
 * (the other token in the relevant pool).
 *
 * @async
 * @param {GetContractResult<typeof ROUTER_ABI>} router - Router contract instance.
 * @param {ISwapPathData} swapPathData - Object containing information about swap path (i.e. pool and token addresses).
 * @param {'in' | 'out'} quoteDirection - if quoteDirection is 'in', router.getAmountIn will be called, if quoteDirection is 'out', router.getAmountOut will be called for quotation
 * @param {string} tokenAmount - String representation of the token amount being swapped.
 * @param {TokenSymbol} tokenSymbol - Symbol of the token being swapped.
 * @param {SupportedChainId} chainId - ID of the chain on which the swap is taking place.
 *
 * @returns {Promise<{ target: SwapQuoteType, market: SwapQuoteType, errorReason?: string }>} Object with the potential swap quotes for the target and market tokens, as well as an optional error reason (if any).
 */
export const getSwapPotentialQuotesForMarketAndTarget = async (
  router: GetContractResult<typeof ROUTER_ABI>,
  swapPathData: ISwapPathData,
  quoteDirection: 'in' | 'out',
  tokenAmount: string,
  tokenSymbol: TokenSymbol,
  chainId: SupportedChainId
): Promise<{
  target: SwapQuoteType
  market: SwapQuoteType
  errorReason?: string
}> => {
  const { poolAddresses: poolPath, tokenAddresses: tokenPath } = swapPathData

  const tokenDecimal = TOKENS[chainId][tokenSymbol]?.decimals
  const infinitesimal = utils.parseUnits(INFINITESIMAL_SWAP_FROM_AMOUNT_FOR_MARKET, tokenDecimal)
  const tokenAmontBN = utils.parseUnits(tokenAmount, tokenDecimal)

  try {
    if (quoteDirection === 'in') {
      const targetPromise = router.getAmountIn(tokenPath, poolPath, tokenAmontBN)
      const marketPromise = router.getAmountIn(tokenPath, poolPath, infinitesimal)
      const [target, market] = await Promise.all([targetPromise, marketPromise])
      return { target, market }
    } else if (quoteDirection === 'out') {
      const targetPromise = router.getAmountOut(tokenPath, poolPath, tokenAmontBN)
      const marketPromise = router.getAmountOut(tokenPath, poolPath, infinitesimal)
      const [target, market] = await Promise.all([targetPromise, marketPromise])
      return { target, market }
    }
  } catch (err) {
    console.error(err)
    const errorMessage = (err as Error).message
    const regex = /reason="(.+)"/i
    const result = regex.exec(errorMessage)
    return {
      target: null,
      market: null,
      errorReason: (result && result[1]) || undefined,
    }
  }
  return { target: null, market: null }
}

export const getHaircutSumInToTokenWad = (
  haircuts: readonly BigNumber[],
  tokenSymbolPath: TokenSymbol[],
  tokenPrices: { [token in TokenSymbol]?: string },
  chainId: SupportedChainId
) => {
  const toTokenSymbol = tokenSymbolPath[tokenSymbolPath.length - 1]
  const haircutSumInUsdWad = haircuts.reduce((prev, current, i) => {
    const tokenSymbol = tokenSymbolPath[i + 1]
    const tokenDecimal = TOKENS[chainId][tokenSymbolPath[i + 1]]?.decimals
    const tokenPrice = tokenPrices[tokenSymbol]
    if (!tokenDecimal || !tokenPrice) return prev
    const haircutWadInUsd = wmul(nativeToWAD(current, tokenDecimal), strToWad(tokenPrice))
    return prev.add(haircutWadInUsd)
  }, BigNumber.from(0))

  const haircutSumInToTokenWad = safeWdiv(haircutSumInUsdWad, strToWad(tokenPrices[toTokenSymbol]))

  return haircutSumInToTokenWad
}

/**
 * Returns the new toToken to use based on the given inputs.
 * @param {SupportedChainId} chainId - The ID of the blockchain network.
 * @param {Token} newFromToken - The new fromToken to use.
 * @param {Token} selectedToToken - The previously selected toToken.
 * @returns {Token} - The new toToken to use.
 */
export const getNewToToken = (
  chainId: SupportedChainId,
  newFromToken: Token,
  selectedToToken: Token
) => {
  // return selected toToken if newFromToken and newToToken are in same swapGroupSymbol group
  if (selectedToToken.swapGroupSymbol === newFromToken.swapGroupSymbol) return selectedToToken

  // displaying the first token from the swapGroupSymbol group
  return Object.values(TOKENS[chainId]).filter(
    (token) =>
      newFromToken.swapGroupSymbol === token.swapGroupSymbol && newFromToken.symbol !== token.symbol
  )[0]
}

/**
 * Performs a swap operation based on the given parameters.
 *
 * @param {object} params - The parameters for the swap operation.
 * @param {HexString[]} params.tokenPath - The token path for the swap.
 * @param {HexString[]} params.poolPath - The pool path for the swap.
 * @param {string} params.fromTokenAmount - The amount of the from token to be swapped.
 * @param {string} params.minimumReceive - The expected minimum receive amount of the to token.
 * @param {HexString} params.signerAddress - The address of the signer.
 * @param {BigNumber} params.deadline - The deadline for the swap.
 * @param {'fromToken' | 'toToken' | 'none'} params.nativeToken - The type of native token involved in the swap.
 * @param {GetContractResult<typeof ROUTER_ABI>} params.router - The router contract.
 * @returns {Promise<any>} - A promise that resolves to the transaction result of the swap operation.
 */
/**@todo refactor swap function */
export const swap = async (
  tokenPath: HexString[],
  poolPath: HexString[],
  fromTokenAmountBN: BigNumber,
  minimumReceiveBN: BigNumber,
  signerAddress: HexString,
  deadline: BigNumber,
  nativeToken: 'fromToken' | 'toToken' | 'none',
  router: GetContractResult<typeof ROUTER_ABI>
) => {
  let txn, estimatedGas
  switch (nativeToken) {
    case 'fromToken':
      estimatedGas = await router.estimateGas.swapExactNativeForTokens(
        tokenPath,
        poolPath,
        minimumReceiveBN,
        signerAddress,
        deadline,
        { value: fromTokenAmountBN }
      )
      txn = await router.swapExactNativeForTokens(
        tokenPath,
        poolPath,
        minimumReceiveBN,
        signerAddress,
        deadline,
        {
          value: fromTokenAmountBN,
          gasLimit: calculateGasMargin(estimatedGas),
        }
      )
      break
    case 'toToken':
      try {
        estimatedGas = await router.estimateGas.swapExactTokensForNative(
          tokenPath,
          poolPath,
          fromTokenAmountBN,
          minimumReceiveBN,
          signerAddress,
          deadline
        )
        // eslint-disable-next-line no-empty
      } catch (e) {}
      txn = await router.swapExactTokensForNative(
        tokenPath,
        poolPath,
        fromTokenAmountBN,
        minimumReceiveBN,
        signerAddress,
        deadline,
        {
          // let metamask estimate gas if we failed to do so
          gasLimit: estimatedGas ? calculateGasMargin(estimatedGas) : undefined,
        }
      )
      break
    default:
      estimatedGas = await router.estimateGas.swapExactTokensForTokens(
        tokenPath,
        poolPath,
        fromTokenAmountBN,
        minimumReceiveBN,
        signerAddress,
        deadline
      )
      txn = await router.swapExactTokensForTokens(
        tokenPath,
        poolPath,
        fromTokenAmountBN,
        minimumReceiveBN,
        signerAddress,
        deadline,
        {
          gasLimit: calculateGasMargin(estimatedGas),
        }
      )
  }
  return txn
}

/**

Retrieves the first crosschain token available for the specified chain.
@param {SupportedChainId} chainId - The ID of the chain.
@returns {Token | undefined} - The first crosschain token available, or undefined if none is found.
*/
export const getFirstCrossChainToken = (chainId: SupportedChainId) => {
  return Object.values(TOKENS[chainId]).filter((token) => token.isCrossChainAvailable)[0]
}

/**
 * Retrieves the first crosschain network from the chainInfos object.
 *
 * @returns {Object | undefined} The first crosschain network from the chainInfos object, or undefined if none is found.
 */
export const getFirstCrossChainNetwork = () => {
  return Object.values(chainInfos).filter((chainInfo) => chainInfo.isCrossChainAvailable)[0]
}
/**
 * Determines whether crosschain swapping is disabled for the specified network.
 *
 * @param {SupportedChainId} sourceChainId - The source chain ID.
 * @param {SupportedChainId} targetChainId - The target network chain ID.
 * @returns {boolean} - Returns `true` if crosschain swapping is disabled for the network, `false` otherwise.
 */
export const getCrossChainSwapDisabledNetwork = (
  sourceChainId: SupportedChainId,
  targetChainId: SupportedChainId
) => {
  /**  disable network if sourceChainId is not cross chain available */
  if (!chainInfos[sourceChainId].isCrossChainAvailable) return true
  /**  enable network for if it is selected as sourceChainId */
  if (sourceChainId === targetChainId) return false
  /**  check whether network for it is avaliable for cross chain swap */
  return !chainInfos[targetChainId].isCrossChainAvailable
}

/**
 * Retrieves swap pair symbol and chain ID based on query parameters.
 *
 * @param {string} queryFromTokenInfo - The query parameter for the from token information.
 * @param {string} queryToTokenInfo - The query parameter for the to token information.
 * @returns {Object} - An object containing the swap pair symbol and chain IDs.
 *                    Returns undefined if the source or target chain IDs are not supported,
 *                    or if the from/to tokens are not valid.
 */
export const getSwapPairSymbolAndChainIdByQuery = (
  queryFromTokenInfo: string,
  queryToTokenInfo: string
) => {
  /** decode query string */
  const queryFromTokenInfoArr = queryFromTokenInfo?.split(',')
  const queryToTokenInfoArr = queryToTokenInfo?.split(',')

  const queryFromTokenSymbol = queryFromTokenInfoArr[0] as TokenSymbol
  const querySourceChainNetwork = queryFromTokenInfoArr[1]
  const querySourceChainId = getSupportedChainIdByNetwork(querySourceChainNetwork)

  const queryToTokenSymbol = queryToTokenInfoArr[0] as TokenSymbol
  const queryTargetChainNetwork = queryToTokenInfoArr[1]
  const queryTargetChainId = getSupportedChainIdByNetwork(queryTargetChainNetwork)

  if (!querySourceChainId || !queryTargetChainId) return

  /** check if token is in token list corresponding chain */
  const isCrossChainSwap = querySourceChainId !== queryTargetChainId

  /** exclude case: toTokenSymbol === fromTokenSymbol for same chain swap */
  if (!isCrossChainSwap && queryToTokenSymbol === queryFromTokenSymbol) return

  const availableSourceChainTokenList = Object.values(
    getFilteredTokenMaps(querySourceChainId, true, isCrossChainSwap)
  )

  const availableTargetChainTokenList = Object.values(
    getFilteredTokenMaps(queryTargetChainId, true, isCrossChainSwap)
  )

  const isValidFromToken = availableSourceChainTokenList.some(
    (availableToken) => availableToken.symbol === queryFromTokenSymbol
  )

  const isValidToToken = availableTargetChainTokenList.some(
    (availableToken) => availableToken.symbol === queryToTokenSymbol
  )

  if (!isValidFromToken || !isValidToToken) return

  return {
    queryFromTokenSymbol,
    queryToTokenSymbol,
    querySourceChainId,
    queryTargetChainId,
  }
}

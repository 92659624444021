import React from 'react'

type Props = {
  className?: string
  fill?: string
  size?: number
}

function WarningIcon({ className, fill = '#FF4E52', size = 24 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.73 3H8.27L3 8.27V15.73L8.27 21H15.73L21 15.73V8.27L15.73 3ZM19 14.9L14.9 19H9.1L5 14.9V9.1L9.1 5H14.9L19 9.1V14.9Z"
        fill={fill}
      />
      <path d="M11 7H13V13H11V7ZM11 15H13V17H11V15Z" fill={fill} />
    </svg>
  )
}

export default WarningIcon

import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { AssetProperty } from './types'
import { BigNumber, ethers } from 'ethers'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { executeCallBacks, IContractCalls } from '../../utils/executeCallBacks'
import { getPoolTokenPair } from '../../constants/contract/pool'
import { ASSETS } from '../../constants/contract/asset'
import { safeWdiv } from '@hailstonelabs/big-number-utils'
import { multicall } from '@wagmi/core'

export function getInitialAssetProperty<T>(): AssetProperty<T> {
  return Object.values(PoolLabels).reduce((previousValue, currentValue) => {
    return { ...previousValue, [currentValue]: [] }
  }, {} as AssetProperty<T>)
}

export async function fetchCashLiability({
  block,
  chainId,
}: {
  block?: ethers.CallOverrides['blockTag']
  chainId: SupportedChainId
}) {
  if (!chainId) return null
  const queries: IContractCalls = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resolver: ((val: any) => void)[] = []
  const cashes_ = getInitialAssetProperty<BigNumber>()
  const liabilities_ = getInitialAssetProperty<BigNumber>()
  const totalSupplies_ = getInitialAssetProperty<BigNumber>()
  const lpTokenToTokenRates_ = getInitialAssetProperty<BigNumber>()
  const maxSupply_ = getInitialAssetProperty<BigNumber>()
  const output = {
    cashes: cashes_,
    liabilities: liabilities_,
    totalSupplies: totalSupplies_,
    lpTokenToTokenRates: lpTokenToTokenRates_,
    maxSupply: maxSupply_,
  }
  getPoolTokenPair(chainId).forEach((pair) => {
    const asset = ASSETS[chainId][pair.poolLabel][pair.tokenSymbol]
    if (!asset) return

    queries.push(asset.multicall('cash'))
    queries.push(asset.multicall('liability'))
    queries.push(asset.multicall('totalSupply'))
    queries.push(asset.multicall('maxSupply'))

    resolver.push((res) => {
      cashes_[pair.poolLabel][pair.tokenSymbol] = res
    })
    resolver.push((res) => {
      liabilities_[pair.poolLabel][pair.tokenSymbol] = res
    })
    resolver.push((totalSupply) => {
      totalSupplies_[pair.poolLabel][pair.tokenSymbol] = totalSupply
      const liability = liabilities_[pair.poolLabel][pair.tokenSymbol]
      if (liability && totalSupply)
        lpTokenToTokenRates_[pair.poolLabel][pair.tokenSymbol] = safeWdiv(liability, totalSupply)
    })
    resolver.push((res) => {
      maxSupply_[pair.poolLabel][pair.tokenSymbol] = res
    })
  })

  try {
    const result = await multicall({
      contracts: queries,
      chainId,
      allowFailure: true,
      overrides: {
        blockTag: block,
      },
    })
    executeCallBacks(result, resolver)
    return output
  } catch (e) {
    const errInfo = '@multicallProvider.tryAll in fetchCashLiability\n'
    console.error(errInfo, e)
    return null
  }
}

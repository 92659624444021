import METAMASK_ICON from '../../public/assets/icons/metamask-icon.svg'
import RABBY_ICON from '../../public/assets/icons/rabby-icon.svg'
import BRAVE_ICON from '../../public/assets/icons/brave-icon.svg'
import INJECTED_ICON from '../../public/assets/icons/browser-wallet.svg'
import TRUST_WALLET_ICON from '../../public/assets/icons/trustwallet-icon.svg'

export const getIsInjected = () => Boolean(window.ethereum)

const InjectedWalletTable: {
  [key in keyof NonNullable<Window['ethereum']>]?: { name: string; icon: string }
} = {
  isBraveWallet: { name: 'Brave', icon: BRAVE_ICON },
  isRabby: { name: 'Rabby', icon: RABBY_ICON },
  isTrust: { name: 'Trust Wallet', icon: TRUST_WALLET_ICON },
}

/**
 * Checks the window object for the presence of a known injectors and returns the most relevant injector name and icon.
 * Returns a default metamask installation object if no wallet is detected.
 *
 */
export function getInjection(): { name: string; icon: string } {
  if (typeof window !== 'undefined') {
    for (const [key, wallet] of Object.entries(InjectedWalletTable)) {
      if (window.ethereum?.[key as keyof Window['ethereum']]) return wallet
    }

    // Check for MetaMask last, as other injectors will also set this flag, i.e. Brave browser and Phantom wallet
    if (window.ethereum?.isMetaMask) return { name: 'MetaMask', icon: METAMASK_ICON }

    // Prompt metamask installation when there is no injection present or the only injection detected is coinbase (CB has separate entry point in UI)
    if (!window.ethereum || window.ethereum.isCoinbaseWallet)
      return { name: 'Install MetaMask', icon: METAMASK_ICON }
  }
  // Use a generic icon when injection is present but no known non-coinbase wallet is detected
  return { name: 'Browser Wallet', icon: INJECTED_ICON }
}

/**
 * Returns true if `isMetaMask` is set to true and another non-metamask injector cannot be detected.
 *
 * Some non-metamask wallets set `isMetaMask` to true for dapp-compatability reasons. If one of these
 * injectors are detected, this function will return false.
 * https://wallet-docs.brave.com/ethereum/wallet-detection#compatability-with-metamask
 */
export const getIsMetaMaskWallet = () => getInjection().name === 'MetaMask'

import { commafyPercentage } from '../../utils/commafy'
import { TokenInfo } from '../PoolPage'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { Asset } from '../../constants/contract/asset/Asset'
import DesktopInfo from './desktopInfo'
import MobileInfo from './mobileInfo'

interface CrossChainPoolInfoCardProps {
  depositedOrStaked: boolean | null
  DISCONNECT: boolean
  hasStaked: boolean | null
  tokenInfo: TokenInfo
  asset: Asset
  chainId: SupportedChainId
}

export const CrossChainPoolInfoCard = ({
  depositedOrStaked,
  DISCONNECT,
  hasStaked,
  tokenInfo,
  asset: token,
  chainId,
}: CrossChainPoolInfoCardProps) => {
  const medianTotalAprUi = commafyPercentage(tokenInfo.medianTotalApr ?? 0)
  const womRewardUi = tokenInfo.reward ? `${tokenInfo.reward?.toString()}` : '0.00'

  const avgDailyFeeSharingAprUi = tokenInfo.avgFeeSharingApr.daily
    ? commafyPercentage(Number(tokenInfo.avgFeeSharingApr.daily) / 100)
    : '-'

  function getTotalAprUi() {
    // if user is staked, show my total apr
    if (tokenInfo.myTotalApr && hasStaked && !DISCONNECT)
      return commafyPercentage(tokenInfo.myTotalApr)
    // if user is deposited or staked, show avg fee sharing apr only
    if (tokenInfo.avgFeeSharingApr && depositedOrStaked) return avgDailyFeeSharingAprUi
    return '-'
  }

  return (
    <>
      <div className="block text-black md:hidden">
        <MobileInfo
          chainId={chainId}
          tokenInfo={tokenInfo}
          asset={token}
          depositedOrStaked={depositedOrStaked}
          DISCONNECT={DISCONNECT}
          hasStaked={hasStaked}
          myTotalAprUi={getTotalAprUi()}
          womRewardUi={womRewardUi}
          inExpander
        />
      </div>
      <div className="hidden grid-cols-[22px,90px,1fr,115px,1fr,1fr,1fr] items-center gap-1 border-b-[2px] border-wombatPurple3 bg-[#E4D5EA] px-3 py-3 text-black md:grid lg:grid-cols-[22px,1fr,1fr,115px,,1fr,1fr,1fr]">
        <DesktopInfo
          chainId={chainId}
          tokenInfo={tokenInfo}
          asset={token}
          depositedOrStaked={depositedOrStaked}
          DISCONNECT={DISCONNECT}
          hasStaked={hasStaked}
          medianTotalAprUi={medianTotalAprUi}
          myTotalAprUi={getTotalAprUi()}
          womRewardUi={womRewardUi}
          inExpander
        />
      </div>
    </>
  )
}

import { SupportedChainId } from '../web3/supportedChainId'
import { Contract } from './Contract'
import { VOTER_ABI } from './abis/voter'
import { IContractConstructor } from '../../interfaces/contract'

export class Voter extends Contract<typeof VOTER_ABI> {
  constructor({ address, chainId }: IContractConstructor) {
    super({ address, chainId, abi: VOTER_ABI })
  }
}

export const VOTERS: { [id in SupportedChainId]?: Voter | null } = {
  [SupportedChainId.BSC_TESTNET]: new Voter({
    address: '0x23c8d0437b74ce912850b89a42f28e4037ca7849',
    chainId: SupportedChainId.BSC_TESTNET,
  }),
  [SupportedChainId.BSC_MAINNET]: new Voter({
    address: '0x04D4e1C1F3D6539071b6D3849fDaED04d48D563d',
    chainId: SupportedChainId.BSC_MAINNET,
  }),
  [SupportedChainId.ARBITRUM_ONE]: new Voter({
    address: '0x3f90a5a47364c0467031fB00246192d40E3D2D9D',
    chainId: SupportedChainId.ARBITRUM_ONE,
  }),
  [SupportedChainId.ETHEREUM_MAINNET]: new Voter({
    address: '0x32A936CbA2629619b46684cDf923CB556f09442c',
    chainId: SupportedChainId.ETHEREUM_MAINNET,
  }),
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { MigrationDetectionState, PendingTokenSCType, UserInfoSCType } from '../types'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { AssetProperty } from '../../../utils/asset'
import { BigNumber, constants, utils } from 'ethers'
import { CallbacksType, executeCallBacks, IContractCalls } from '../../../utils/executeCallBacks'
import { Asset } from '../../../constants/contract/asset/Asset'
import { MASTER_WOMBATS, MasterWombatId } from '../../../constants/contract/masterWombat'
import { assetListMap, ASSETS } from '../../../constants/contract/asset'
import { pidsForMigration } from '../../../constants/migration'
import { nativeToWAD, wmul } from '@hailstonelabs/big-number-utils'
import { multicall } from '@wagmi/core'
import { TokenSymbol, TokenSymbols } from '../../../constants/contract/token/TokenSymbols'
import { tokenAddressTokenMap } from '../../../constants/contract/token'

const masterWombatMigrations: { [id in SupportedChainId]?: MasterWombatId[] } = {
  [SupportedChainId.BSC_TESTNET]: [MasterWombatId.MW2],
  [SupportedChainId.BSC_MAINNET]: [MasterWombatId.MW2],
}

const updateAllMigrationData = createAsyncThunk<
  Pick<MigrationDetectionState, 'masterWombatMigrationData'>,
  {
    chainId: SupportedChainId
    account: `0x${string}` | null
    lpTokenToTokenRatesWad: AssetProperty<BigNumber>
  }
>(
  'MigrationDetection/updateAllMigrationData',
  async ({ chainId, account, lpTokenToTokenRatesWad }) => {
    let contractCalls: IContractCalls = []
    let callbacks: CallbacksType = []
    const initMasterWombatMigrationData: MigrationDetectionState['masterWombatMigrationData'] = {}
    const stakedAssets: { [id in MasterWombatId]?: Asset[] } = {}
    // Check userInfo first then call pendingTokens if userInfo.amount > 0
    const fetchEachMasterWombatMigrationUserInfoData = async (masterWombatId: MasterWombatId) => {
      /** @TODO don't assume all masterWombatId/asset to do migration...  */
      const assets = assetListMap[chainId]
      if (!account || !assets) return
      const masterWombat = MASTER_WOMBATS?.[chainId]?.[masterWombatId]
      if (!masterWombat) return
      for (const asset of assets) {
        const poolLabel = asset.poolLabel
        const assetSymbol = asset.symbol
        const assetDecimal = ASSETS[chainId][poolLabel][assetSymbol]?.decimals
        const pid =
          pidsForMigration[chainId][masterWombat.address.toLowerCase()]?.[
            asset.address.toLowerCase()
          ]
        // We cannot use (pid && account) since pid starts from 0 which is a falsy value.
        // The first tokenSymbol will be disappeared in this situation.
        // Asset paused will make the migrate shown error
        if (!account || pid === undefined || !assetDecimal || asset.paused) continue
        const pidBn = BigNumber.from(pid)
        contractCalls.push(masterWombat.multicall('userInfo', [pidBn, account]))
        callbacks.push((value) => {
          // check user did stake lp in masterWombat
          if (!(value as UserInfoSCType).amount.gt(constants.Zero)) return
          // Assign to stakedAssets for fetch pendingTokens
          stakedAssets[masterWombatId] = [...(stakedAssets[masterWombatId] || []), asset]
          const lpTokenToTokenRateWad = lpTokenToTokenRatesWad[poolLabel][assetSymbol]

          if (!lpTokenToTokenRateWad) return
          const stakedLpInTermsOfToken = utils.formatEther(
            wmul(nativeToWAD((value as UserInfoSCType).amount, assetDecimal), lpTokenToTokenRateWad)
          )
          initMasterWombatMigrationData[masterWombatId] = {
            ...initMasterWombatMigrationData[masterWombatId],
            stakedPidsBN: [
              ...(initMasterWombatMigrationData[masterWombatId]?.stakedPidsBN || []),
              pidBn,
            ],
            isMigrationNeeded: true,
            stakedLpInTermsOfToken: {
              ...(initMasterWombatMigrationData[masterWombatId]?.stakedLpInTermsOfToken || {}),
              [poolLabel]: {
                ...(initMasterWombatMigrationData[masterWombatId]?.stakedLpInTermsOfToken?.[
                  poolLabel
                ] || {}),
                [assetSymbol]: stakedLpInTermsOfToken,
              },
            },
          }
        })
      }
    }
    for (const masterWombatId of masterWombatMigrations[chainId] || []) {
      await fetchEachMasterWombatMigrationUserInfoData(masterWombatId)
    }
    try {
      const values = await multicall({ contracts: contractCalls, chainId, allowFailure: true })
      executeCallBacks(values, callbacks)
    } catch (error) {
      console.debug(error)
    }
    // If user migrate and not stake to oldMasterWombat, masterWombatContract.pendingTokens may error so need to find staked assets...
    // reset contractCalls and callbacks after fetch user info
    contractCalls = []
    callbacks = []
    const fetchEachMasterWombatMigrationPendingTokensData = (masterWombatId: MasterWombatId) => {
      const masterWombatContract = MASTER_WOMBATS[chainId]?.[masterWombatId]
      if (!masterWombatContract || !account) return
      const assets = stakedAssets[masterWombatId]
      if (!assets) return
      for (const asset of assets) {
        const pid =
          pidsForMigration[chainId][masterWombatContract.address.toLowerCase()]?.[
            asset.address.toLowerCase()
          ]
        const assetSymbol = asset.symbol
        const poolLabel = asset.poolLabel
        // Asset paused will make the migrate shown error
        if (pid === undefined) continue
        const pidBn = BigNumber.from(pid)
        contractCalls.push(masterWombatContract.multicall('pendingTokens', [pidBn, account]))
        callbacks.push((value: PendingTokenSCType) => {
          const pendingTokensInTheAsset: { [id in TokenSymbol]?: string } = {}
          const pendingWomRewardWad = value.pendingRewards
          const bonusTokenAddresses = value.bonusTokenAddress
          const pendingBonusRewardsBN = value.pendingBonusRewards
          pendingTokensInTheAsset[TokenSymbols.WOM] = utils.formatEther(pendingWomRewardWad)
          for (let i = 0; i < bonusTokenAddresses.length; i++) {
            const bonusToken = tokenAddressTokenMap[chainId]?.[bonusTokenAddresses[i].toLowerCase()]
            if (!bonusToken) continue
            const pendingBonusRewardBN = pendingBonusRewardsBN[i]
            const bonusTokenSymbol = bonusToken.symbol
            const bonusTokenDecimals = bonusToken.decimals
            pendingTokensInTheAsset[bonusTokenSymbol] = utils.formatUnits(
              pendingBonusRewardBN,
              bonusTokenDecimals
            )
            initMasterWombatMigrationData[masterWombatId] = {
              ...initMasterWombatMigrationData[masterWombatId],
              pendingTokens: {
                ...initMasterWombatMigrationData[masterWombatId]?.pendingTokens,
                [poolLabel]: {
                  ...initMasterWombatMigrationData[masterWombatId]?.pendingTokens?.[poolLabel],
                  [assetSymbol]: pendingTokensInTheAsset,
                },
              },
            }
          }
        })
      }
    }
    for (const masterWombatId of masterWombatMigrations[chainId] || []) {
      fetchEachMasterWombatMigrationPendingTokensData(masterWombatId)
    }
    try {
      const values = await multicall({ contracts: contractCalls, chainId, allowFailure: true })
      executeCallBacks(values, callbacks)
      return {
        masterWombatMigrationData: initMasterWombatMigrationData,
      }
    } catch (error) {
      return {
        masterWombatMigrationData: {},
      }
    }
  }
)

export default updateAllMigrationData

import React from 'react'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import TokenImage from '../TokenImage'
import { commafyPercentage } from '../../utils/commafy'

type Props = {
  displaySymbol: string
  rewardSymbol: TokenSymbol
  apr: string
} & React.HTMLAttributes<HTMLDivElement>

function AprTooltipItem({ displaySymbol, rewardSymbol, apr, ...otherProps }: Props) {
  return +apr > 0 ? (
    <div className="flex justify-between" {...otherProps}>
      <div className="flex">
        <TokenImage tokenSymbol={rewardSymbol} />
        <div className="ml-1">{displaySymbol}</div>
      </div>
      <span>{commafyPercentage(+apr)}</span>
    </div>
  ) : (
    <></>
  )
}

export default AprTooltipItem

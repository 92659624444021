import clsx from 'clsx'
import React from 'react'
type Props = {
  children: React.ReactNode
}

function Timeline({ children }: Props) {
  return <div className="bg-inherit font-Work">{children}</div>
}

export default Timeline

/** Compound components */

type ContentProps = {
  children: React.ReactNode
  label: React.ReactNode
  disableSeparator?: boolean
  disabled?: boolean
}
function Content({ children, label, disableSeparator, disabled }: ContentProps) {
  return (
    <div className="group relative flex flex-row bg-inherit">
      {/* Label */}
      <p className="flex-[0.3] text-right text-sm font-medium text-wombatBrown1">{label}</p>
      <div className="flex-0 relative ml-[8px] mr-[16px] flex flex-col items-center">
        {/* dot */}
        <div
          className={clsx(
            'my-[6px] h-[8px] w-[8px] rounded bg-wombatBrown1',
            disabled && 'border-1 border-wombatBrown3 bg-[#fff]'
          )}
        />
        {/* Separator */}
        {!disableSeparator && (
          <div
            className={clsx(
              'min-h-[64px] w-0 border-1 border-wombatBrown1 group-last-of-type:hidden'
            )}
          />
        )}
      </div>
      {/* Content */}
      <div className="relative flex flex-1 flex-col">{children}</div>
      {/* Overlay to block the content if it is disabled */}
      <div
        className={clsx(
          'absolute top-0 h-full w-full bg-inherit opacity-[0.6]',
          disabled ? 'block' : 'hidden'
        )}
      ></div>
    </div>
  )
}

Timeline.Content = Content

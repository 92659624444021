import { useEffect, useState } from 'react'

type Props = {
  targetTimestampInMilliseconds: number
}

export type CountdownData = {
  readonly label: string
  readonly maxValue: number
  value: number
}[]

const initialCountdownData = [
  {
    label: 'days',
    maxValue: 30,
    value: 0,
  },
  {
    label: 'hrs',
    maxValue: 24,
    value: 0,
  },
  {
    label: 'mins',
    maxValue: 60,
    value: 0,
  },
  {
    label: 'secs',
    maxValue: 60,
    value: 0,
  },
]

function useCountdown({ targetTimestampInMilliseconds }: Props) {
  const [countdownData, setCountdownData] = useState<CountdownData>(initialCountdownData)
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime()
      // convert UTC time to local time
      const nextEpochStartTimeInLocalTime = new Date(targetTimestampInMilliseconds).getTime()
      const diffInMilliseconds = nextEpochStartTimeInLocalTime - currentTime
      const seconds = Math.floor(diffInMilliseconds / 1000)
      if (seconds < 0) {
        clearInterval(interval)
        return
      }

      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      setCountdownData((prev) => {
        const newCountdown = [...prev]
        newCountdown[0].value = days % newCountdown[0].maxValue
        newCountdown[1].value = hours % newCountdown[1].maxValue
        newCountdown[2].value = minutes % newCountdown[2].maxValue
        newCountdown[3].value = seconds % newCountdown[3].maxValue
        return newCountdown
      })
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [targetTimestampInMilliseconds])

  return countdownData
}

export default useCountdown

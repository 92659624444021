import Image from 'next/image'
import { useEffect } from 'react'
import { ASSETS } from '../../../constants/contract/asset'
import { POOLS } from '../../../constants/contract/pool'
import { useWeb3 } from '../../../context/Web3Context'

import { TOKENS } from '../../../constants/contract'
import useNavbarSize from '../../../hooks/useNavbarSize'
import closeButtonIcon from '../../../public/assets/icons/button-close.svg'
import ScrollableBox from '../../ScrollableBox'
import TokenImage from '../../TokenImage'
import { useUserVoteAllocationTable } from '../UserVoteAllocationTable/UserVoteAllocationTableContext'

interface Props {
  onClose: () => void
}
const BANNER_HEIGHT = '47px'
function GaugeSelectionModal({ onClose }: Props) {
  const { chainId } = useWeb3()
  const {
    votedAndAddedGaugeList,
    actions: { addNewGauge },
  } = useUserVoteAllocationTable()
  const {
    mobile: { height: mobileNavbarHeight },
  } = useNavbarSize()
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'initial'
    }
    // only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div
      className="fixed top-0 left-0 z-20 h-full w-full p-[10px] font-Work"
      style={{
        // 47px is the height of the banner
        top: BANNER_HEIGHT,
        height: `calc(100vh - ${mobileNavbarHeight}px - ${BANNER_HEIGHT})`,
        background:
          'linear-gradient(0deg, rgba(255, 252, 238, 0.3), rgba(255, 252, 238, 0.3)), linear-gradient(117.54deg, rgba(255, 255, 255, 0.4) -19.85%, rgba(235, 235, 235, 0.293883) 4.2%, rgba(224, 224, 224, 0.230004) 13.88%, rgba(212, 212, 212, 0.169048) 27.98%, rgba(207, 207, 207, 0.140467) 37.8%, rgba(202, 202, 202, 0.114745) 44.38%, rgba(200, 200, 200, 0.10104) 50.54%, rgba(196, 196, 196, 0.08) 60.21%)',
        backdropFilter: 'blur(5px)',
      }}
    >
      <div
        className="absolute left-[50%] top-[50%] flex h-[60%] w-[80%] translate-y-[-50%] translate-x-[-50%] flex-col rounded-[16px] border-2 border-wombatBrown1 bg-white"
        style={{
          padding: '20px 20px 10px 20px',
          overflow: 'hidden',
        }}
      >
        <div className="mb-2 flex flex-row justify-between pr-1">
          <span className="font-semibold text-wombatBrown1">Add Gauge</span>
          <div className="relative cursor-pointer text-right" onClick={onClose}>
            <Image src={closeButtonIcon} width={12} height={12} />
          </div>
        </div>
        <ScrollableBox scrollDirection="vertical" className="h-full w-full border-wombatBrown1">
          {Object.values(POOLS[chainId]).map((pool) => {
            // remove added gauges from the options
            const filteredDropdownInputOptions = pool.supportedAssetTokenSymbols.filter(
              (assetTokenSymbol) => {
                return (
                  ASSETS[chainId][pool.label]?.[assetTokenSymbol]?.enableVoting &&
                  !votedAndAddedGaugeList.some(
                    (gauge) =>
                      gauge.poolSymbol === pool.label && gauge.assetTokenSymbol === assetTokenSymbol
                  )
                )
              }
            )
            return (
              // hide pool name when there are no options left
              filteredDropdownInputOptions.length > 0 && (
                <div className="my-3 text-sm">
                  <p
                    className="mb-1 bg-linearGradientApprove bg-clip-text font-semibold"
                    style={{ WebkitTextFillColor: 'transparent' }}
                  >
                    {pool.name}
                  </p>
                  {filteredDropdownInputOptions.map((assetTokenSymbol) => {
                    const token = TOKENS[chainId][assetTokenSymbol]
                    return (
                      <p
                        className="flex cursor-pointer flex-row items-center gap-2 py-1 pl-2 text-sm hover:bg-wombatYellow2"
                        key={pool.label + assetTokenSymbol}
                        onClick={(value) => {
                          if (value) {
                            addNewGauge({ poolSymbol: pool.label, assetTokenSymbol })
                            onClose()
                          }
                        }}
                      >
                        <TokenImage tokenSymbol={assetTokenSymbol} width="18" height="18" />
                        {token?.displaySymbol}
                      </p>
                    )
                  })}
                </div>
              )
            )
          })}
        </ScrollableBox>
      </div>
    </div>
  )
}

export default GaugeSelectionModal

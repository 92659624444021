import { useCallback, useState } from 'react'
import { useWeb3 } from '../../context/Web3Context'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import { useTxnReceipt } from '../../context/TxnReceiptProvider'
import { useSwapCreditContext } from '../../context/SwapCreditContext'
import {
  swapCreditForTokensSameChain,
  swapCreditForTokensCrossChain,
} from '../../utils/crossChainSwap'
import { HexString } from '../../interfaces/contract'
import { useCrossChainSwapTx } from '../crossChainSwap/useCrossChainSwapTx'
import { CROSS_CHAIN_SWAP_GAS_LIMIT } from '../../constants/common'
import { useSwapContext } from '../../context/SwapContext'
import { useModalContext } from '../../context/ModalContext'
import { addErrorToast, addSuccessToast } from '../../store/Toast/actions'
import { useAppDispatch } from '../../store/hooks'

export const useSwapCreditForTokens = () => {
  const { account, chainId } = useWeb3()
  const dispatch = useAppDispatch()
  const { appendCrossChainSwapTx } = useCrossChainSwapTx()
  const {
    selectedToChainId,
    selectedToTokenSymbol,
    creditInput,
    minimumReceive,
    recipientAddress,
    quotedToTokenAmount,
    resetAllInputAmount,
    setCreditBalance,
    estDeliveryFee,
  } = useSwapCreditContext()
  const { receiverValue } = useSwapContext()
  const { refreshTxnReceipt } = useTxnReceipt()
  const [isLoading, setIsLoading] = useState(false)
  const [transactionHash, setTransactionHash] = useState('')
  const {
    actions: { closeModal },
  } = useModalContext()

  const handleSameChainSwapCreditForToken = useCallback(async () => {
    if (!account || !selectedToTokenSymbol) return
    try {
      setIsLoading(true)
      const txn = await swapCreditForTokensSameChain({
        chainId: selectedToChainId,
        toTokenSymbol: selectedToTokenSymbol,
        creditAmount: creditInput,
        minimumToAmount: minimumReceive,
        recipient: recipientAddress as HexString,
      })
      setTransactionHash(txn.hash)
      const receiptTxn = await txn.wait()
      if (receiptTxn) {
        dispatch(
          addSuccessToast({
            message: `${formatNumberUSLocale(creditInput)} Credit`,
            title: 'Swap Credit Completed',
            txHash: receiptTxn.transactionHash,
          })
        )
        refreshTxnReceipt()
        resetAllInputAmount()
        setCreditBalance((prev) => (+prev - +creditInput).toString())
        closeModal()
      }
    } catch (error) {
      dispatch(
        addErrorToast({
          message: `${formatNumberUSLocale(creditInput)} Credit`,
          title: 'Swap Credit Failed',
          txHash: transactionHash,
        })
      )
      const errInfo =
        `@swapCreditForTokens\n` +
        `chainId: ${selectedToChainId.toString()}\n` +
        `toTokenSymbol: ${selectedToTokenSymbol.toString()}\n` +
        `creditAmount: ${creditInput.toString()}\n` +
        `minimumToAmount: ${minimumReceive.toString()}\n` +
        `recipient: ${recipientAddress.toString()}\n`
      console.error(errInfo, error)
    } finally {
      setIsLoading(false)
    }
  }, [
    account,
    selectedToTokenSymbol,
    selectedToChainId,
    creditInput,
    minimumReceive,
    recipientAddress,
    dispatch,
    refreshTxnReceipt,
    resetAllInputAmount,
    setCreditBalance,
    closeModal,
    transactionHash,
  ])

  const handleCrossChainSwapCreditForToken = useCallback(async () => {
    if (!account || !selectedToTokenSymbol) return
    try {
      setIsLoading(true)
      // want to sync up the amount with the record
      const txn = await swapCreditForTokensCrossChain({
        fromChainId: chainId,
        toChainId: selectedToChainId,
        toTokenSymbol: selectedToTokenSymbol,
        creditAmount: creditInput,
        minimumToAmount: minimumReceive,
        recipient: recipientAddress as HexString,
        receiverValue,
        gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
        deliveryFee: estDeliveryFee,
      })
      setTransactionHash(txn.hash)
      const receiptTxn = await txn.wait()
      if (receiptTxn) {
        dispatch(
          addSuccessToast({
            message: `${formatNumberUSLocale(creditInput)} Credit`,
            title: 'Swap Credit Completed',
            txHash: receiptTxn.transactionHash,
          })
        )
        appendCrossChainSwapTx({
          isCreditToToken: true,
          fromChainId: chainId,
          fromTxhash: receiptTxn.transactionHash,
          receiverValue,
          toChainId: selectedToChainId,
          toTokenAmount: quotedToTokenAmount,
          toTokenSymbol: selectedToTokenSymbol,
          userAddress: account,
          fromCreditAmount: creditInput,
        })
        refreshTxnReceipt()
        resetAllInputAmount()
        setCreditBalance((prev) => (+prev - +creditInput).toString())
        closeModal()
      }
    } catch (error) {
      dispatch(
        addErrorToast({
          message: `${formatNumberUSLocale(creditInput)} Credit`,
          title: 'Swap Credit Failed',
          txHash: transactionHash,
        })
      )
      const errInfo =
        `@swapCreditForTokensCrossChain\n` +
        `fromChainId: ${chainId.toString()}\n` +
        `toChainId: ${selectedToChainId.toString()}\n` +
        `toTokenSymbol: ${selectedToTokenSymbol.toString()}\n` +
        `creditAmount: ${creditInput.toString()}\n` +
        `minimumToAmount: ${minimumReceive.toString()}\n` +
        `recipient: ${recipientAddress.toString()}\n` +
        `receiverValue: ${receiverValue.toString()}\n` +
        `gasLimit: ${CROSS_CHAIN_SWAP_GAS_LIMIT.toString()}\n`
      console.error(errInfo, error)
    } finally {
      setIsLoading(false)
    }
  }, [
    account,
    selectedToTokenSymbol,
    minimumReceive,
    chainId,
    selectedToChainId,
    creditInput,
    recipientAddress,
    receiverValue,
    estDeliveryFee,
    dispatch,
    appendCrossChainSwapTx,
    quotedToTokenAmount,
    refreshTxnReceipt,
    resetAllInputAmount,
    setCreditBalance,
    closeModal,
    transactionHash,
  ])

  return {
    handleSameChainSwapCreditForToken,
    handleCrossChainSwapCreditForToken,
    isLoading,
  }
}

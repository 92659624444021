import { HexString } from '../../../interfaces/contract'
import { SupportedChainId } from '../../web3/supportedChainId'
import { Contract } from '../Contract'
import { CROSS_CHAIN_POOL_ABI, POOL_ABI, VOLATILE_POOL_ABI_V2 } from '../abis/pool'
import { ASSETS } from '../asset'
import { MasterWombatId } from '../masterWombat'
import { TokenSymbol } from '../token/TokenSymbols'
import { PoolLabels } from './PoolLabels'
import { PoolFilterGroup } from './poolFilterGroup'

export type PoolVersion = 'normal' | 'crosschain' | 'volatile_v2'

export type PoolList = { [pool in PoolLabels]?: Pool }
export type PoolConstructorProps = {
  chainId: SupportedChainId
  label: PoolLabels
  address: HexString
  name?: string
  // where is user lp token staked? etc. bnb pool delisted so that user's lp token still at MW2
  masterWombatId?: MasterWombatId
  isHiddenInPoolPage?: boolean
  isShownInBoosterCalculator?: boolean
  filterGroup: PoolFilterGroup
  version?: PoolVersion
}

export type POOL_ABI_TYPE = typeof POOL_ABI
// | typeof CROSS_CHAIN_POOL_ABI
// | typeof VOLATILE_POOL_ABI_V2

export class Pool extends Contract<POOL_ABI_TYPE> {
  readonly label: PoolLabels
  readonly supportedAssetTokenSymbols: TokenSymbol[]
  readonly name: string
  readonly masterWombatId: MasterWombatId
  readonly isHiddenInPoolPage: boolean
  readonly isShownInBoosterCalculator: boolean
  readonly filterGroup: PoolFilterGroup
  readonly version: PoolVersion

  constructor({
    chainId,
    label,
    address,
    name,
    /** @todo May change when new MW update */
    masterWombatId = MasterWombatId.BOOSTED,
    isHiddenInPoolPage = false,
    isShownInBoosterCalculator = false,
    filterGroup,
    version,
  }: PoolConstructorProps) {
    let abi: POOL_ABI_TYPE = POOL_ABI
    switch (version) {
      case 'crosschain':
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        abi = CROSS_CHAIN_POOL_ABI
        break
      case 'volatile_v2':
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        abi = VOLATILE_POOL_ABI_V2
        break
      default:
        break
    }
    super({ address, chainId, abi: abi })
    this.label = label
    this.name = name || `${label} Pool`
    this.masterWombatId = masterWombatId
    this.isHiddenInPoolPage = isHiddenInPoolPage
    this.isShownInBoosterCalculator = isShownInBoosterCalculator
    this.supportedAssetTokenSymbols = Object.values(ASSETS[chainId][label]).map(
      (asset) => asset.symbol
    )
    this.filterGroup = filterGroup
    // TODO: need add at least all crosschain pools version
    this.version = version || 'normal'
  }
}

import { WormHoleState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import getWormholeStatus from './thunks/getWormholeStatus'

const initialState: WormHoleState = {
  health: false,
  ready: false,
}

const wormholeSlice = createSlice({
  name: 'wormhole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWormholeStatus.fulfilled, (state, action) => {
      state.health = action.payload.health
    })
  },
})

export default wormholeSlice.reducer

import Image from 'next/image'
import { useRef } from 'react'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import failedWombat from '../../public/assets/animations/failedwombat.svg'
import Button from '../Button'
import Modal from '../Modal'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export default function TransactionFailedModal({ onClose, isOpen }: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="" width={480} height={400} isCenterTitle>
      <div className="flex flex-col items-center justify-center px-5 pb-14">
        <div className="text-center font-Work font-semibold">Transaction Failed</div>
        <div className="p-10">
          <Image src={failedWombat} layout="fixed" width={140} height={140} />
        </div>
        <Button className="w-full bg-wombatPurple1" onClick={() => onClose()}>
          Dismiss
        </Button>
      </div>
    </Modal>
  )
}

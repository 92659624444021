import { AssetConfig } from '..'
import { PoolLabels } from '../../../pool/PoolLabels'
import { TokenSymbols } from '../../../token/TokenSymbols'
import { defaultAssetConfig } from '../defaultAssetConfig'

export const ETHEREUM_MAINNET_ASSET_CONFIG: AssetConfig = {
  ...defaultAssetConfig,

  [PoolLabels.CROSS_CHAIN]: {
    [TokenSymbols.USDC]: {
      name: 'USD Coin',
      color: '#000000',
      hasBribeV2: true,
    },
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#000000',
      hasBribeV2: true,
    },
  },
  [PoolLabels.MAIN]: {
    [TokenSymbols.USDT]: {
      name: 'Tether USD',
      color: '#000000',
    },
    [TokenSymbols.WETH]: {
      name: 'ETH',
      color: '#E5C5FF',
      displaySymbol: 'ETH',
    },
    [TokenSymbols.WBTC]: {
      name: 'Wrapped Bitcoin',
      color: '#F7931A',
    },
  },
  [PoolLabels.mWOM]: {
    [TokenSymbols.mWOM]: {
      name: 'mWOM',
      color: '#A8D1FF',
      hasBribeV2: true,
    },
  },
  [PoolLabels.wmxWOM]: {
    /** @todo the token name on mainnet is called wmxWom but not wmxWOM */
    [TokenSymbols.wmxWom]: {
      name: 'Wombex WOM',
      color: '#685D54',
      hasBribeV2: true,
    },
  },
  [PoolLabels.frxETH]: {
    [TokenSymbols.frxETH]: {
      name: 'Frax Ether',
      color: '#C2A2FF',
      hasBribeV2: true,
    },
    [TokenSymbols.WETH]: {
      name: 'ETH',
      color: '#E5C5FF',
      displaySymbol: 'ETH',
      hasBribeV2: true,
    },
    [TokenSymbols.sfrxETH]: {
      name: 'Frax Staked Ether',
      color: '#A88FBD',
      hasBribeV2: true,
    },
  },
  [PoolLabels.wstETH]: {
    [TokenSymbols.wstETH]: {
      name: 'wstETH',
      color: '#71DEFF',
      enableVoting: false,
    },
    [TokenSymbols.WETH]: {
      name: 'WETH',
      color: '#F0FFAA',
      displaySymbol: 'ETH',
      enableVoting: false,
    },
  },
  [PoolLabels.FRAX]: {
    [TokenSymbols.FRAX]: {
      name: 'Frax',
      color: '#C2A2FF',
      enableVoting: false,
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#E5C5FF',
      enableVoting: false,
    },
  },
  [PoolLabels.EURA]: {
    [TokenSymbols.EURA]: {
      name: 'EURA',
      color: '#C2A2FF',
      enableVoting: false,
    },
    [TokenSymbols.EURe]: {
      name: 'EURe',
      color: '#E5C5FF',
      enableVoting: false,
    },
  },
  [PoolLabels.ETHx]: {
    [TokenSymbols.ETHx]: {
      name: 'Stader ETHx',
      color: '#C2A2FF',
      interestBearingAprEndpoint: 'https://universe.staderlabs.com/eth/apy',
      hasBribeV2: true,
    },
    [TokenSymbols.WETH]: {
      name: 'WETH',
      color: '#E5C5FF',
      displaySymbol: 'ETH',
      hasBribeV2: true,
    },
  },
  [PoolLabels.USDV]: {
    [TokenSymbols.USDV]: {
      name: 'USDV',
      color: '#D4D4D4',
    },
    [TokenSymbols.USDT]: {
      name: 'USDT',
      color: '#767676',
    },
  },
}

import { MigrationDetectionState } from './types'
import { createSlice } from '@reduxjs/toolkit'
import updateAllDelistedData from './thunks/updateAllDelistedData'
import updateAllMigrationData from './thunks/updateAllMigrationData'
import updateAllMW2Data from './thunks/updateAllMW2Data'

const initialState: MigrationDetectionState = {
  isMigrationNeeded: false,
  masterWombatMigrationData: {},
  isDelistedWithdrawalNeeded: false,
  delistedData: {},
  mw2Data: {},
  isMigrationDataFetched: false,
  isDelistedDataFetched: false,
  isMW2DataFetched: false,
  masterWombatMigrationDataLoading: false,
  delistedDataLoading: false,
  mw2DataLoading: false,
}

const migrationDetectionSlice = createSlice({
  name: 'migrationDetection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAllDelistedData.pending, (state) => {
      state.delistedDataLoading = true
    })
    builder.addCase(updateAllDelistedData.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.delistedData = {
        ...action.payload.delistedData,
      }
      state.isDelistedDataFetched = true
      state.delistedDataLoading = false
    })
    builder.addCase(updateAllDelistedData.rejected, (state) => {
      state.delistedDataLoading = false
    })

    builder.addCase(updateAllMigrationData.pending, (state) => {
      state.masterWombatMigrationDataLoading = true
    })
    builder.addCase(updateAllMigrationData.fulfilled, (state, action) => {
      state.masterWombatMigrationData = {
        ...action.payload.masterWombatMigrationData,
      }
      state.isMigrationDataFetched = true
      state.masterWombatMigrationDataLoading = false
    })
    builder.addCase(updateAllMigrationData.rejected, (state) => {
      state.masterWombatMigrationDataLoading = false
    })

    builder.addCase(updateAllMW2Data.pending, (state) => {
      state.mw2DataLoading = true
    })
    builder.addCase(updateAllMW2Data.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.mw2Data = {
        ...action.payload.mw2Data,
      }
      state.isMW2DataFetched = true
      state.mw2DataLoading = false
    })
    builder.addCase(updateAllMW2Data.rejected, (state) => {
      state.mw2DataLoading = false
    })
  },
})

export default migrationDetectionSlice.reducer

import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../constants/contract/token/TokenSymbols'

export type AssetProperty<T> = { [label in PoolLabels]: { [id in TokenSymbol]?: T } }

export function getInitialAssetProperty<T>(): AssetProperty<T> {
  return Object.values(PoolLabels).reduce((previousValue, currentValue) => {
    return { ...previousValue, [currentValue]: [] }
  }, {} as AssetProperty<T>)
}

import { useMemo } from 'react'
import { Token } from '../../constants/contract/token/Token'
import { SwapGroupSymbol, TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { TokenSymbolGenericType } from '../../interfaces/common'
import classNames from '../../utils/classNames'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import ScrollableBox from '../ScrollableBox'
import TokenImage from '../TokenImage'

interface IListToken {
  id?: string
  tokens: Token[]
  disableTokens: TokenSymbol[] | null
  onTokenChange: (token: Token) => void
  selectedToken: Token | null
  closeModal: () => void
  balances?: TokenSymbolGenericType<string>
}

interface TokenGroup {
  swapGroupSymbol: string
  tokens: Token[]
}

const TokenRow = ({
  id,
  token,
  selectedToken,
  onTokenChange,
  closeModal,
  balance,
}: {
  id?: string
  token: Token
  selectedToken: Token | null
  onTokenChange: (token: Token) => void
  closeModal: () => void
  balance: string
}) => {
  return (
    <li
      id={`select-${id}-${token.symbol}`}
      className={classNames(
        'mb-2 flex cursor-pointer justify-between py-1 hover:bg-wombatDrawerLinkHoverBackground',
        token === selectedToken ? 'font-bold' : ''
      )}
      onClick={() => {
        onTokenChange(token)
        closeModal()
      }}
    >
      <div className="flex">
        <TokenImage tokenSymbol={token.symbol} width="16" height="16" className="w-4" />
        <p className="ml-2 font-Roboto text-sm text-wombatBrown1">{token.displaySymbol}</p>
      </div>
      <p className="font-Roboto text-xs text-wombatBrown1">{balance}</p>
    </li>
  )
}

export const ListToken = ({
  id,
  tokens,
  disableTokens,
  onTokenChange,
  selectedToken,
  closeModal,
  balances,
}: IListToken) => {
  const filteredTokens = useMemo(() => {
    return tokens.filter((token) => !disableTokens?.includes(token.symbol))
  }, [tokens, disableTokens])

  const myTokensGroup: TokenGroup = useMemo(() => {
    const group: TokenGroup = {
      swapGroupSymbol: 'Your Tokens',
      tokens: [],
    }

    filteredTokens.forEach((token) => {
      const balance = balances ? balances[token.symbol] : '0'
      if (balance && +balance > 0) {
        group.tokens.push(token)
      }
    })

    return group
  }, [balances, filteredTokens])

  const tokenGroups: TokenGroup[] = useMemo(() => {
    const groups: TokenGroup[] = []
    filteredTokens.forEach((token) => {
      const existingGroupIndex = groups.findIndex(
        (group) => group.swapGroupSymbol === token.swapGroupSymbol
      )
      if (!myTokensGroup.tokens.includes(token)) {
        if (existingGroupIndex === -1) {
          groups.push({
            swapGroupSymbol: token.swapGroupSymbol,
            tokens: [token],
          })
        } else {
          groups[existingGroupIndex].tokens.push(token)
        }
      }
    })
    return groups
  }, [filteredTokens, myTokensGroup.tokens])

  return (
    <ScrollableBox scrollDirection="vertical" className="max-h-[400px] px-8 md:px-0">
      {myTokensGroup.tokens.length > 0 && (
        <>
          <h3 className="mb-2 bg-wombatBrownAlpha p-2 font-Roboto text-sm font-bold text-wombatBrown1">
            {myTokensGroup.swapGroupSymbol}
          </h3>
          {myTokensGroup.tokens.map((item) => {
            const disabled = disableTokens && disableTokens.includes(item.symbol)
            // only show tokens that are avaiable.
            if (disabled) return <></>
            const balance = balances ? `${formatNumberUSLocale(balances[item.symbol] ?? '0')}` : '0'

            return (
              <TokenRow
                id={id}
                key={item.symbol}
                token={item}
                selectedToken={selectedToken}
                onTokenChange={onTokenChange}
                closeModal={closeModal}
                balance={balance}
              />
            )
          })}
        </>
      )}
      {tokenGroups.map((group) => (
        <div key={group.swapGroupSymbol}>
          {group.tokens.length > 0 && group.swapGroupSymbol !== SwapGroupSymbol.UNAVAILABLE && (
            <>
              <h3 className="mb-2 bg-wombatBrownAlpha p-2 font-Roboto text-sm font-bold text-wombatBrown1">
                {group.swapGroupSymbol}
              </h3>
              <ul>
                {group.tokens.map((item) => {
                  const disabled = disableTokens && disableTokens.includes(item.symbol)
                  // only show tokens that are avaiable.
                  if (disabled) return <></>
                  const balance = balances
                    ? `${formatNumberUSLocale(balances[item.symbol] ?? '0')}`
                    : '0'

                  return (
                    <TokenRow
                      id={id}
                      key={item.symbol}
                      token={item}
                      selectedToken={selectedToken}
                      onTokenChange={onTokenChange}
                      closeModal={closeModal}
                      balance={balance}
                    />
                  )
                })}
              </ul>
            </>
          )}
        </div>
      ))}
    </ScrollableBox>
  )
}

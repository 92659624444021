import Image from 'next/image'
import { useMemo, useState } from 'react'
import SettingModal from '../../components/SettingModal'
import TransactionFailedModal from '../../components/TransactionFailedModal'
import TransactionSubmittedModal from '../../components/TransactionSubmittedModal'
import { TOKENS } from '../../constants/contract'
import { DEFAULT_SWAP_TOKEN_IN_CHAIN } from '../../constants/contract/token'
import { Token } from '../../constants/contract/token/Token'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { CROSS_CHAIN_GAS_RECEIVING_AMOUNT } from '../../constants/web3/chains'
import { useModalContext } from '../../context/ModalContext'
import { useSwapContext } from '../../context/SwapContext'
import SwapCreditProvider from '../../context/SwapCreditContext'
import { useSwapInputContext } from '../../context/SwapInputContext'
import { useWeb3 } from '../../context/Web3Context'
import { useUserPreference } from '../../context/userPreferenceContext'
import { ModalId } from '../../interfaces/modal'
import flipToken from '../../public/assets/icons/flip-token.svg'
import setting from '../../public/assets/icons/setting.svg'
import WormholeBanner from '../../public/assets/icons/wormhole.svg'
import { getDisabledTokenList, getFilteredTokenMaps } from '../../utils/router'
import SwitchNetworkModal from '../SwitchNetwork'
import { SwapBottomButtons } from './SwapBottomButtons'
import SwapConfirmationModal from './SwapConfirmationModal'
import SwapInfo from './SwapInfo'
import SwapInput from './SwapInput'
import CrossChainSwapInfoContainer from './crossChainSwap/CrossChainSwapInfoContainer'

export default function SwapContainer() {
  const { chainId } = useWeb3()
  const {
    actions: { closeModal, openModal },
    modalState,
  } = useModalContext()

  const {
    fromTokenSymbol,
    toTokenSymbol,
    targetChainId,
    sourceChainId,
    isCrossChainSwap,
    receiverValue,
    handleSourceChainIdChange,
    handleTargetChainIdChange,
    updateToTokenSymbol,
    updateFromTokenSymbol,
  } = useSwapContext()
  const sourceTokenMaps: { [id in TokenSymbol]?: Token } = useMemo(
    () => getFilteredTokenMaps(chainId, true),
    [chainId]
  )
  const targetTokenMaps: { [id in TokenSymbol]?: Token } = useMemo(
    () => getFilteredTokenMaps(targetChainId, true),
    [targetChainId]
  )

  const {
    recipientAddress,
    displayedFromValue,
    displayedToValue,
    handleToInputChange,
    handleFromInputChange,
    handleSwitchButtonOnClick,
    setRecipientAddress,
    handleReceiverValueOnChange,
  } = useSwapInputContext()

  const fromToken =
    TOKENS[chainId][fromTokenSymbol] ??
    (TOKENS[chainId][DEFAULT_SWAP_TOKEN_IN_CHAIN[chainId].fromTokenSymbol] as Token)
  const toToken =
    TOKENS[targetChainId][toTokenSymbol] ??
    (TOKENS[targetChainId][DEFAULT_SWAP_TOKEN_IN_CHAIN[targetChainId].toTokenSymbol] as Token)

  const [openSetting, setOpenSetting] = useState(false)

  const { userPreference } = useUserPreference()
  const slippage = userPreference.slippage
  const slippageAlert = useMemo(() => {
    return Number(slippage) > 0.02
  }, [slippage])
  return (
    <div className="mx-auto flex w-full max-w-lg flex-col items-center space-y-8 p-8 pb-20 font-Work md:p-0">
      <div className="flex w-full max-w-3xl flex-col justify-center py-5 pb-1 md:py-10 md:pb-2">
        <div className="rounded-2xl text-black md:bg-white md:p-6 md:shadow-lg">
          <div className="mb-5 flex justify-end px-4 pb-2 md:pb-0">
            <button
              className={`swap-open-settings button-hover-opacity -m-2 flex items-center gap-2 p-2 text-sm
               ${slippageAlert ? 'bg-yellow-100 text-yellow-500' : 'bg-gray-100'} rounded-2xl`}
              onClick={() => setOpenSetting(true)}
            >
              <div>{Number(slippage) * 100}% slippage</div>
              <Image alt={''} layout="fixed" width={18} height={18} src={setting} />
            </button>
          </div>
          <div className="md:px-4">
            <SwapInput
              tokens={sourceTokenMaps}
              selectedToken={fromToken}
              amount={displayedFromValue}
              onSelectedTokenChanged={(selectedToken) =>
                updateFromTokenSymbol(selectedToken.symbol)
              }
              onAmountChanged={(amount) => handleFromInputChange(amount)}
              /** no hidden token for cross chain swap */
              hideToken={isCrossChainSwap ? null : toToken}
              showMaxBtn
              chainId={sourceChainId}
              onSelectedChainIdChanged={() =>
                openModal({
                  currentModalId: ModalId.SELECT_SOURCE_CHAIN,
                })
              }
            />
            <div className="-my-1 flex items-center justify-center px-5">
              <button
                className={`swap-flip-token h-10 w-10 overflow-hidden rounded-full ${
                  isCrossChainSwap ? 'cursor-not-allowed' : ''
                }`}
                onClick={() => {
                  /** disable switch button when doing cross chain swap */
                  if (isCrossChainSwap) return
                  handleSwitchButtonOnClick()
                }}
              >
                <Image alt={''} width="40" height="40" src={flipToken}></Image>
              </button>
            </div>
            <SwapInput
              tokens={targetTokenMaps}
              selectedToken={toToken}
              amount={displayedToValue}
              onSelectedTokenChanged={(token) => updateToTokenSymbol(token.symbol)}
              onAmountChanged={(amount) => handleToInputChange(amount)}
              hideToken={isCrossChainSwap ? null : fromToken}
              /** @TODO Refactor SwapInput https://www.notion.so/Refactor-SwapInput-1ccc0a6d57b6453f9790e0f8cfc3da2b */
              disableTokens={getDisabledTokenList(fromToken.symbol, chainId)}
              showMaxBtn={false}
              chainId={targetChainId}
              onSelectedChainIdChanged={() =>
                openModal({
                  currentModalId: ModalId.SELECT_TARGET_CHAIN,
                })
              }
              recipientAddress={recipientAddress}
              setRecipientAddress={setRecipientAddress}
              disabled={isCrossChainSwap}
              chainSelectorProps={{
                leadingLabel: isCrossChainSwap ? 'Beta' : '',
              }}
            />
            <div className="mt-2 flex flex-col text-right">
              <SwapInfo />
            </div>
            <SwapBottomButtons />
          </div>
          {/* Button */}
        </div>
        {/* Modals */}
        <div>
          {/* Swap Confirmation Modal */}
          {modalState.currentModalId === ModalId.SWAP_WAIT_FOR_CONFIRMATION ? (
            <SwapConfirmationModal
              isOpen
              onClose={closeModal}
              isDisplayGifFlyToTheMoon={
                modalState.payload?.isTxnPending && modalState.payload?.isTxnPending
              }
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {/* Transaction Submitted Modal */}
          {modalState.currentModalId === ModalId.SWAP_SUBMITTED ? (
            <TransactionSubmittedModal
              isOpen
              token={toToken}
              onClose={closeModal}
              chainId={chainId}
              hash={
                modalState.payload?.transactionHashes && modalState.payload.transactionHashes[0]
              }
            />
          ) : (
            <></>
          )}
          {/* Transaction Failed Modal */}
          {modalState.currentModalId === ModalId.SWAP_FAILED ? (
            <TransactionFailedModal isOpen onClose={closeModal} />
          ) : (
            <></>
          )}
          {/* Setting Modal */}
          <SettingModal
            isOpen={openSetting === true}
            onClose={() => {
              if (receiverValue === '') {
                handleReceiverValueOnChange(
                  CROSS_CHAIN_GAS_RECEIVING_AMOUNT[targetChainId].defaultAmount
                )
              }
              setOpenSetting(false)
            }}
            isCrossChainSettingShown={isCrossChainSwap}
          />
          {/* Select Source ChainId Modal */}
          <SwitchNetworkModal
            isOpen={modalState.currentModalId === ModalId.SELECT_SOURCE_CHAIN}
            onClose={closeModal}
            onChainIdSelect={handleSourceChainIdChange}
            selectedChainId={sourceChainId}
            isHideMobileNav
          />
          {/* Select Target ChainId Modal */}
          <SwitchNetworkModal
            isOpen={modalState.currentModalId === ModalId.SELECT_TARGET_CHAIN}
            onClose={closeModal}
            onChainIdSelect={handleTargetChainIdChange}
            selectedChainId={targetChainId}
            isHideMobileNav
          />
        </div>
      </div>
      <SwapCreditProvider>
        <CrossChainSwapInfoContainer />
      </SwapCreditProvider>
      {/** Wormhole logo */}
      <div className="flex items-center py-8">
        <span className="mr-3 text-[12px] font-medium text-black opacity-50">
          Crosschain Messaging Powered by
        </span>
        <Image alt={''} src={WormholeBanner}></Image>
      </div>
    </div>
  )
}

import React from 'react'
import { HexString } from '../../../interfaces/contract'
import { CrossChainCase } from '../../../constants/crossChain'
import CrossChainStatusRecord, { ChainData, SourceChainData } from './CrossChainStatusRecord'
import { useWeb3 } from '../../../context/Web3Context'
import { useCrossChainSwapTxStore } from '../../../hooks/crossChainSwap/useCrossChainSwapTx'

function CrossChainHistory() {
  const { account, chainId } = useWeb3()
  const { getHistoryTxnList: getHistoryTxList } = useCrossChainSwapTxStore()
  const historyTxnList = account ? getHistoryTxList(chainId, account) : []
  return (
    <div>
      {historyTxnList.length > 0 ? (
        historyTxnList.map(
          ({
            fromChainId,
            fromTxhash,
            toChainId,
            toTokenAmount,
            toTokenSymbol,
            toTxhash,
            sequence,
            receiverValue,
            ...restData
          }) => {
            const commonProps: {
              source: SourceChainData
              target: ChainData
              sequence: string
              receiverValue: string
              isCreditToToken: boolean
            } = {
              source: {
                chainId: fromChainId,
                tokenSymbol: restData.isCreditToToken ? null : restData.fromTokenSymbol,
                amount: restData.isCreditToToken
                  ? restData.fromCreditAmount
                  : restData.fromTokenAmount,
                txnHash: fromTxhash as HexString,
              },
              target: {
                chainId: toChainId,
                tokenSymbol: toTokenSymbol,
                amount: toTokenAmount,
                txnHash: toTxhash as HexString,
              },
              sequence,
              receiverValue,
              isCreditToToken: restData.isCreditToToken,
            }

            if (restData.case === CrossChainCase.RECEIVED_CREDIT) {
              // if the status is RECEIVED_CREDIT
              // the component below will force us to input creditReceived
              return (
                <CrossChainStatusRecord
                  key={fromChainId + sequence}
                  status={restData.case}
                  {...commonProps}
                  creditReceived={restData.creditAmount || ''}
                />
              )
            }
            return (
              <CrossChainStatusRecord
                key={fromChainId + sequence}
                status={restData.case}
                {...commonProps}
              />
            )
          }
        )
      ) : (
        <p className="py-4 text-center text-base text-wombatBrown1">No transaction history</p>
      )}
    </div>
  )
}

export default CrossChainHistory

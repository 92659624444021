import { useAppSelector } from '../hooks'
import { selectBlock24hAgo, selectMedianBoostedAprs, selectTradingVol24h } from './selector'

export const useBlock24hAgo = () => {
  return useAppSelector(selectBlock24hAgo)
}

export const useTradingVol24h = () => {
  return useAppSelector(selectTradingVol24h)
}

export const useMedianBoostedAprs = () => {
  return useAppSelector(selectMedianBoostedAprs)
}

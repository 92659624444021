import {
  getIsTransferCompletedEth,
  parseTokenTransferVaa,
  ChainId as WormholeChainId,
} from '@certusone/wormhole-sdk'
import {
  SupportedChainId,
  supportedChainIdToWormholeChainIdMap,
  wormholeChainIdToSupportedChainIdMap,
} from '../constants/web3/supportedChainId'
import { utils, ethers } from 'ethers'
import { BridgeProgress, TokenTransferVaaMetadata } from '../interfaces/bridge'
import { chainInfos } from '../constants/web3/chains'

export function getTokenTransferVaaMetadata(signedVaa: Uint8Array): TokenTransferVaaMetadata {
  const parsedTokenTransferVaa = parseTokenTransferVaa(signedVaa)
  // cannot retrieve fromAdress as it always 0
  const { emitterChain, toChain, amount, to } = parsedTokenTransferVaa
  const sourceChainId =
    // BSC_MAINNET and BSC_TESTNET have the same wormhole chain id
    emitterChain === supportedChainIdToWormholeChainIdMap[SupportedChainId.BSC_MAINNET]
      ? SupportedChainId.BSC_MAINNET
      : wormholeChainIdToSupportedChainIdMap[emitterChain as WormholeChainId]

  const targetChainId =
    // BSC_MAINNET and BSC_TESTNET have the same wormhole chain id
    toChain === supportedChainIdToWormholeChainIdMap[SupportedChainId.BSC_MAINNET]
      ? SupportedChainId.BSC_MAINNET
      : wormholeChainIdToSupportedChainIdMap[toChain as WormholeChainId]
  // the amount data has 8 decimal places
  const bridgedTokenAmount = utils.formatUnits(amount.toString(), 8)
  const toAddress = utils.defaultAbiCoder.decode(['address'], to)[0] as string
  return {
    sourceChainId,
    targetChainId,
    bridgedTokenAmount,
    toAddress,
  }
}

/**
  Tracks the progress of a target relative to the current progress.
  @param {BridgeProgress} targetProgress - The target progress to track.
  @param {BridgeProgress} currentProgress - The current progress to compare with the target progress.
  @param {'ON_OR_AFTER_TARGET' | 'BEFORE_TARGET' | 'AFTER_TARGET' | 'ON_OR_BEFORE_TARGET'} mode - The mode to determine the relationship between the target progress and current progress.
  @returns {boolean} - Returns true if the current progress meets the specified mode criteria, otherwise false.
 */
export function isTargetOnCurrentProgress(
  targetProgress: BridgeProgress,
  currentProgress: BridgeProgress,
  mode: 'ON_OR_AFTER_TARGET' | 'BEFORE_TARGET' | 'AFTER_TARGET' | 'ON_OR_BEFORE_TARGET'
): boolean {
  const totalProgress = Object.values(BridgeProgress)
  const targetIndex = totalProgress.indexOf(targetProgress)
  const currentIndex = totalProgress.indexOf(currentProgress)

  switch (mode) {
    case 'BEFORE_TARGET':
      return currentIndex < targetIndex
    case 'AFTER_TARGET':
      return currentIndex > targetIndex
    case 'ON_OR_AFTER_TARGET':
      return currentIndex >= targetIndex
    case 'ON_OR_BEFORE_TARGET':
      return currentIndex <= targetIndex
    default:
      return currentIndex !== targetIndex
  }
}

/**
 * Get new target chainId when user change selected source chainId
 * @param {SupportedChainId} selectedChainId - The selected chain ID.
 * @returns {SupportedChainId} - The new target chain ID.
 */
export const getNewTargetChainId = (selectedChainId: SupportedChainId) => {
  const supportedChainIdArr = Object.values(SupportedChainId).filter(
    (chainId) =>
      typeof chainId === 'number' &&
      chainId !== SupportedChainId.BSC_TESTNET &&
      chainId !== selectedChainId
  ) as SupportedChainId[]

  return supportedChainIdArr[0]
}

/**
 *
 * @param {SupportedChainId} targetChainId - The ID of the target chain.
 * @param {Uint8Array|null|undefined} [signedVaa] - The signed VAA (optional).
 * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the redeem has been completed.
 * @param signedVaa
 */
export const fetchIsRedeemCompletedFn = async (
  targetChainId: SupportedChainId,
  signedVaa?: Uint8Array | null
): Promise<boolean> => {
  if (!signedVaa) return false
  try {
    const chainInfo = chainInfos[targetChainId]
    const tokenBridgeAddress = chainInfo.wormholeConfig?.tokenBridgeAddress
    if (!tokenBridgeAddress) throw new Error(`wormhole config is not found in ${chainInfo.name}.`)
    const provider = new ethers.providers.JsonRpcProvider(chainInfo.rpcUrls.default.http[0])
    const response = await getIsTransferCompletedEth(tokenBridgeAddress, provider, signedVaa)
    return response
  } catch (err) {
    console.log(err)
    return false
  }
}

import { strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import Image from 'next/image'
import { TokenSymbols } from '../../configx/tokenSymbols'
import { TOKENS } from '../../constants/contract'
import { Asset } from '../../constants/contract/asset/Asset'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { PoolFilterGroup } from '../../constants/contract/pool/poolFilterGroup'
import { chainInfos } from '../../constants/web3/chains'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { usePoolPage } from '../../context/PoolPageContext'
import { useWeb3 } from '../../context/Web3Context'
import WarningIcon from '../../public/assets/icons/warning-icon.svg'
import commafy, { commafyWithDecimals } from '../../utils/commafy'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import { TokenInfo } from '../PoolPage'
import { TokenReward } from '../PoolPage/TokenReward'
import TokenImage from '../TokenImage'
import WButton, { Variant } from '../WButton'
import { MedianTotalAprTooltip } from './medianTotalAprTooltip'
import { MyTotalAprTooltip } from './myTotalAprTooltip'
import { SupportedChains } from './supportedChains'

const WarningMessage = () => {
  return (
    <div className="group relative mb-1 flex items-center justify-between text-left lg:mb-0">
      <Image alt={''} src={WarningIcon} width={16} height={16} />
      <div className="tooltip-brown top-[10px] w-[300px] py-2 px-3 text-center text-sm text-white">
        <p className="text-white">
          The rewarder contracts are just updated, please ACTIVATE for reward earning
        </p>
      </div>
    </div>
  )
}

interface DesktopInfoProps {
  chainId: SupportedChainId
  tokenInfo: TokenInfo
  asset: Asset
  depositedOrStaked: boolean | null
  DISCONNECT: boolean
  hasStaked: boolean | null
  medianTotalAprUi: string
  myTotalAprUi: string
  womRewardUi: string
  rewarderBalanceArr?: string[]
  inExpander?: boolean
}

function DesktopInfo({
  chainId,
  tokenInfo,
  asset: token,
  depositedOrStaked,
  DISCONNECT,
  hasStaked,
  medianTotalAprUi,
  myTotalAprUi,
  womRewardUi,
  rewarderBalanceArr,
  inExpander,
}: DesktopInfoProps) {
  const { chainId: currentChainId, switchNetwork } = useWeb3()
  const { selectedPoolFilterGroup } = usePoolPage()
  const chainInfo = chainInfos[chainId]

  function getDisplayTradingVol24hr() {
    const tradingVol24h = tokenInfo.tradingVol24h
    if (typeof tradingVol24h === 'number')
      return formatNumberUSLocale(tokenInfo.tradingVol24h?.toString() ?? '0', 2)
    return '-'
  }

  return (
    <>
      {/* token image & name */}
      {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && inExpander ? (
        <>
          <TokenImage tokenSymbol={tokenInfo.symbol} width="22" height="22" />
          <div className="flex flex-col md:ml-2">
            <h2 className="font-Work text-[14px] font-semibold leading-5">
              {tokenInfo.displaySymbol} ({chainInfo.label})
            </h2>
          </div>
        </>
      ) : (
        <>
          <TokenImage tokenSymbol={tokenInfo.symbol} width="32" height="32" />
          <div className="flex flex-col md:ml-2">
            <h2 className="font-Work font-semibold leading-5">{tokenInfo.displaySymbol}</h2>
          </div>
        </>
      )}

      {/* cov ratio */}
      <>
        {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN &&
        !inExpander &&
        selectedPoolFilterGroup === PoolFilterGroup.CROSS_CHAIN ? (
          <SupportedChains tokenInfo={tokenInfo} />
        ) : (
          <p className="text-center font-Work text-sm font-medium text-wombatBrown md:col-auto">
            {`${commafy(((tokenInfo.covRatio ?? 0) * 100).toFixed(1))}%`}
          </p>
        )}
      </>

      {/* tvl */}
      <div className="flex justify-center">
        <div className="group relative text-sm font-medium text-wombatBrown">
          {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && !inExpander ? (
            <>
              {tokenInfo.multichainTVL ? (
                <span className="hover-text">{`$${formatNumberUSLocale(
                  tokenInfo.multichainTVL.toString()
                )}`}</span>
              ) : (
                'loading\u2026'
              )}
            </>
          ) : (
            <>
              {tokenInfo.tvl ? (
                <span className="hover-text">{`$${formatNumberUSLocale(
                  tokenInfo.tvl.toString()
                )}`}</span>
              ) : (
                'loading\u2026'
              )}
            </>
          )}

          <div className="tooltip-brown left-1/2 w-fit py-2 px-3 text-center text-sm text-white">
            <>
              {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && !inExpander ? (
                <>
                  {commafyWithDecimals(tokenInfo.multichainLiability ?? 0)}&nbsp;
                  {token.displaySymbol}
                </>
              ) : (
                <>
                  {commafyWithDecimals(tokenInfo.liability ?? 0)}&nbsp;{token.displaySymbol}
                </>
              )}
            </>
          </div>
        </div>
        <div className="text-sm font-medium text-wombatBrown1">
          {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && !inExpander ? (
            <>
              &nbsp;
              {`($${formatNumberUSLocale(
                tokenInfo.multiChainTradingVol24h?.toString() ?? '0',
                2
              )})`}
            </>
          ) : (
            <>&nbsp;{`($${getDisplayTradingVol24hr()})`}</>
          )}
        </div>
      </div>
      {/* median APR */}

      <div className="relative flex w-fit flex-col justify-self-center">
        <p>
          <span className="hover-text text-sm font-bold text-wombatPurple1">
            {medianTotalAprUi}
          </span>
        </p>
        <MedianTotalAprTooltip
          DISCONNECT={DISCONNECT}
          tokenInfo={tokenInfo}
          depositedOrStaked={depositedOrStaked}
          hasStaked={hasStaked}
        />
      </div>

      {/* Switch chain button */}
      {currentChainId !== chainId ? (
        <WButton
          variant={Variant.GRADIENT}
          onClick={async () => {
            await switchNetwork(+chainId)
          }}
          width="w-full"
        >
          Switch to {chainInfo.label}
        </WButton>
      ) : (
        <>
          {/* my APR */}
          <div className="relative flex w-fit flex-col justify-self-center">
            <p>
              <span
                className={`${
                  hasStaked && !DISCONNECT ? 'hover-text' : ''
                } text-sm font-medium text-wombatBrown`}
              >
                {myTotalAprUi}
              </span>
            </p>
            <MyTotalAprTooltip
              DISCONNECT={DISCONNECT}
              tokenInfo={tokenInfo}
              depositedOrStaked={depositedOrStaked}
              hasStaked={hasStaked}
            />
          </div>

          {/* reward */}
          <div className="relative flex flex-col justify-center gap-1 pr-1 text-center text-sm font-medium text-wombatBrown">
            {hasStaked && !DISCONNECT ? (
              <>
                {strToWad(womRewardUi).gt('0') && (
                  <TokenReward
                    key={TokenSymbols.WOM}
                    symbol={TokenSymbols.WOM}
                    amount={womRewardUi}
                  />
                )}
                {tokenInfo.bonusTokens?.map((rewardInfo, index) => {
                  const rewardToken = TOKENS[chainId][rewardInfo.symbol]
                  if (rewardInfo.amount.eq('0')) {
                    return
                  }
                  const userInfo = rewardInfo.userInfo
                  const amount = utils.formatUnits(rewardInfo.amount, rewardToken?.decimals ?? 18)
                  const hasBonusTokenBalanceDrained =
                    rewarderBalanceArr && strToWad(rewarderBalanceArr[index]).isZero()
                  if (
                    +amount > 0 &&
                    userInfo?.rewardDebt.isZero() &&
                    userInfo?.unpaidRewards.isZero()
                  ) {
                    return <WarningMessage key={rewardInfo.symbol} />
                  }
                  return (
                    <TokenReward
                      key={rewardInfo.symbol}
                      symbol={rewardInfo.symbol}
                      amount={amount}
                      hasDrained={hasBonusTokenBalanceDrained}
                    />
                  )
                })}
                {tokenInfo.boostedRewarderRewardData.map((rewardInfo) => {
                  if (strToWad(rewardInfo.amount).eq('0')) {
                    return
                  }
                  const amount = rewardInfo.amount
                  const userRewardInfo = rewardInfo.userRewardInfo

                  if (
                    +amount > 0 &&
                    userRewardInfo?.rewardDebt.isZero() &&
                    userRewardInfo?.unpaidRewards.isZero()
                  ) {
                    return <WarningMessage key={rewardInfo.symbol} />
                  }

                  return (
                    <TokenReward
                      key={rewardInfo.symbol}
                      symbol={rewardInfo.symbol}
                      amount={amount}
                    />
                  )
                })}
              </>
            ) : (
              '-'
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DesktopInfo

import { strToWad } from '@hailstonelabs/big-number-utils'
import React from 'react'
import { Link } from 'react-router-dom'
import { useWeb3 } from '../../../context/Web3Context'

import useFetchBoostInfo from '../../../hooks/useFetchBoostInfo'

function UserVoteAllocationSectionOverlay() {
  const { account } = useWeb3()
  /** @todo should increase the speed of data fetching to prevent from the situation below */
  // Currently, the interval of usePoller from MulticallDataContext is 10s.
  // Sometimes, the totalLocked is 0 at first and then users need to wait for 10s.
  const { totalLocked } = useFetchBoostInfo()
  const hasWomLocked = strToWad(totalLocked).gt(0)

  const renderTitle = (title: string) => {
    return <p className="font-Jamjuree text-3xl font-bold">{title}</p>
  }
  return (
    <div
      className={`absolute top-0 left-0 z-10 ${
        !account || !hasWomLocked ? 'flex' : 'hidden'
      } h-full w-full flex-col items-center justify-center gap-1`}
      style={{
        borderRadius: 'inherit',
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'blur(4px)',
      }}
    >
      {!account && (
        <>
          {renderTitle('Connect Wallet')}
          <p className="px-2 text-center font-Work text-sm">
            Connect your wallet to view your veWOM balance and vote to earn bribes and boost pool
            emissions.
          </p>
        </>
      )}
      {account && !hasWomLocked && (
        <>
          {renderTitle('Lock WOM to get veWOM')}
          <Link to="/boost">
            <p className="font-Jamjuree text-base font-bold text-wombatPurple1 underline">
              Go to Boost page →
            </p>
          </Link>
        </>
      )}
    </div>
  )
}

export default UserVoteAllocationSectionOverlay

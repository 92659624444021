import React from 'react'
import { MasterWombatId } from '../../constants/contract/masterWombat'
// import { useMigrationDetectionContext } from '../../context/MigrationDetectionContext'
import { useModalContext } from '../../context/ModalContext'
import { ModalId } from '../../interfaces/modal'
import { WarningIcon } from '../images'
import WButton, { Variant } from '../WButton'
import { useMasterWombatMigrationData } from '../../store/MigrationDetection/hooks'
type Props = {
  masterWombatId: MasterWombatId
  title?: React.ReactNode
  message: React.ReactNode
  buttonLabel?: string
}

function MigrationAlert({ masterWombatId, title, message, buttonLabel }: Props) {
  const {
    actions: { openModal },
  } = useModalContext()
  // const { masterWombatMigrationData } = useMigrationDetectionContext()
  const masterWombatMigrationData = useMasterWombatMigrationData()
  if (!masterWombatMigrationData[masterWombatId]?.isMigrationNeeded) {
    return <></>
  }
  return (
    <div className="my-4 flex flex-col items-center justify-center gap-3 rounded-lg bg-wombatRed px-[16px] py-[20px] font-Work text-white first-of-type:mt-0">
      <p className="inline-flex gap-1 font-semibold">
        <WarningIcon fill="#fff" />
        <span>{title ? title : 'Action Required'}</span>
      </p>
      <p className="text-center text-sm font-normal">{message}</p>
      <WButton
        style={{ width: '160px' }}
        variant={Variant.WHITE}
        labelClassName="text-wombatRed"
        onClick={() =>
          openModal({
            currentModalId: ModalId.MIGRATION_WAIT_FOR_CONFIRMATION,
            payload: { masterWombatId },
          })
        }
      >
        {buttonLabel ? buttonLabel : 'CLAIM & MIGRATE'}
      </WButton>
    </div>
  )
}

export default MigrationAlert

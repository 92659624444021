import { BigNumber, ContractTransaction, utils } from 'ethers'
import Image from 'next/image'
import { useMemo, useState } from 'react'
import { TOKENS } from '../../constants/contract'
import { Asset } from '../../constants/contract/asset/Asset'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { useWeb3 } from '../../context/Web3Context'
import useHandleUnStake, { HandleUnStakeState } from '../../hooks/useHandleUnstake'
import WarningIcon from '../../public/assets/icons/warning-icon.svg'
import { useTokenData } from '../../store/MulticallData/hooks'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import WaitingModal from '../Modal/WaitingModal'
import PoolInput from '../PoolInput'
import { POOL_STATE, TokenInfo } from '../PoolPage'
import TransactionFailedModal from '../TransactionFailedModal'
import SubmittedWithdraw from '../TransactionSubmittedModal'
import WButton, { Variant } from '../WButton'

interface Props {
  currentPoolLabel: PoolLabels
  asset: Asset
  tokenInfo: TokenInfo
  stakedAmount: string | undefined
  onTxnSubmited: (txn: ContractTransaction) => void
  redirect: (value: POOL_STATE | null) => void
}

export default function Unstake({ asset, stakedAmount, onTxnSubmited, redirect }: Props) {
  const [lpInputAmountBn, setLpInputAmountBn] = useState<BigNumber | null>(null)
  const [isDisplayTxnModal, setIsDisplayTxnModal] = useState<boolean>(false)
  const { chainId, account } = useWeb3()
  const { withAccount } = useTokenData()

  const balances = withAccount?.balances
  const [tokenAmountForDisplay, setTokenAmountForDisplay] = useState<string>('')
  const token = TOKENS[chainId][asset.symbol]
  const { tryHandleUnStake, unStakeState, txnHash, isDisplayGifFlyToTheMoon } = useHandleUnStake(
    asset,
    lpInputAmountBn,
    onTxnSubmited,
    formatNumberUSLocale(utils.formatEther(lpInputAmountBn ?? 0)) + ' ' + asset.symbol,
    redirect
  )

  const remainStakeBalance = useMemo(() => {
    return (Number(stakedAmount) - Number(utils.formatEther(lpInputAmountBn ?? 0))).toFixed(2)
  }, [stakedAmount, lpInputAmountBn])

  function isAmountGreaterMappingAmount() {
    if (!lpInputAmountBn) return false
    return stakedAmount && lpInputAmountBn
      ? lpInputAmountBn.gt(utils.parseEther(stakedAmount))
      : true
  }
  const button = () => {
    if (!lpInputAmountBn || lpInputAmountBn?.eq(0) || isAmountGreaterMappingAmount()) {
      return (
        <>
          <WButton variant={Variant.LIGHT_PURPLE} width="w-full" disabled>
            UNSTAKE
          </WButton>
        </>
      )
    } else {
      return (
        <WButton
          onClick={() => {
            setIsDisplayTxnModal(true)
            tryHandleUnStake(() => {
              setTokenAmountForDisplay('')
              setLpInputAmountBn(null)
            })
          }}
          variant={Variant.GRADIENT}
          width="w-full"
          className={`unstake-${asset.symbol}-unstake`}
        >
          UNSTAKE
        </WButton>
      )
    }
  }

  const transactionModal = () => {
    switch (unStakeState) {
      case HandleUnStakeState.FAILED:
        return <TransactionFailedModal isOpen onClose={() => setIsDisplayTxnModal(false)} />
      case HandleUnStakeState.SUBMITED:
        return (
          token && (
            <SubmittedWithdraw
              // isViewMyVeWOM
              hash={txnHash}
              isAddTokenToWallet={false}
              token={token}
              isOpen
              onClose={() => setIsDisplayTxnModal(false)}
              chainId={chainId}
            />
          )
        )
      case HandleUnStakeState.LOADING:
        return (
          <WaitingModal
            textAboveBalance="Unstake Token"
            isDisplayGifFlyToTheMoon={isDisplayGifFlyToTheMoon}
            data={
              (lpInputAmountBn ? formatNumberUSLocale(utils.formatEther(lpInputAmountBn)) : '0') +
              ' ' +
              asset.displaySymbol
            }
            isOpen
            onClose={() => setIsDisplayTxnModal(false)}
          />
        )
    }
  }

  return (
    <>
      <div className="flex flex-col items-center justify-between pb-1">
        <div className="flex flex-shrink-0 items-center">
          <Image alt={''} src={WarningIcon} width={24} height={24} />
        </div>
        <div className="max-w-xs text-center text-xs text-wombatRed">
          Unstaking all LP tokens will end your ability to generate yield.
        </div>
      </div>
      {token && (
        <PoolInput
          balance={balances?.[asset.symbol]}
          tokenAmountForDisplay={tokenAmountForDisplay}
          setTokenAmountForDisplay={setTokenAmountForDisplay}
          poolState={POOL_STATE.UNSTAKE}
          token={token}
          selectedAsset={asset}
          setTokenAmount={setLpInputAmountBn}
          handleChangeAmount={(value) => {
            setLpInputAmountBn(value)
          }}
          amountTokenMapping={stakedAmount ?? '0'}
          decimals={asset.decimals}
        />
      )}
      <div className="py-4 font-Work text-sm text-wombatBrown">
        <div className="flex items-center justify-between ">
          <div className="flex items-center">
            <span className="mr-2">Remaining LP Staked</span>
          </div>
          <span>
            {remainStakeBalance
              ? `${formatNumberUSLocale(remainStakeBalance)} LP-${asset.displaySymbol}`
              : '-'}
          </span>
        </div>
      </div>

      {button()}
      {account && isAmountGreaterMappingAmount() && (
        <div className="mt-2 -mb-2 text-center text-red-600">Insufficient balance.</div>
      )}
      {isDisplayTxnModal && transactionModal()}
    </>
  )
}

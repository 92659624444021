import useElementSize from './useElementSize'

function useNavbarSize() {
  const [navbarWidth, navbarHeight] = useElementSize('#navbar')
  const [mobileNavbarWidth, mobileNavbarHeight] = useElementSize('#mobile-navbar')
  return {
    desktop: {
      width: navbarWidth,
      height: navbarHeight,
    },
    mobile: {
      width: mobileNavbarWidth,
      height: mobileNavbarHeight,
    },
  }
}

export default useNavbarSize

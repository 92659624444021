import clsx from 'clsx'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import DropdownInput, { DropdownInputProps, OptionProps } from '../inputs/DropdownInput'
import ScrollableBox from '../ScrollableBox'

const ALL_OPTION_LABEL = 'all'

type FilterDropdownContextType = {
  selectedOptionMap: Map<string, boolean>
  setSelectedOptionMap: React.Dispatch<React.SetStateAction<Map<string, boolean>>>
}
const FilterDropdownContext = createContext({} as FilterDropdownContextType)

const useFilterDropdownContext = () => {
  return useContext(FilterDropdownContext)
}
type Props = {
  children: React.ReactNode
  onOptionsChange?: (selectedOptions: string[]) => void
} & DropdownInputProps

function FilterDropdown({ children, onOptionsChange, ...otherProps }: Props) {
  const [selectedOptionMap, setSelectedOptionMap] = useState(new Map<string, boolean>())
  const isSelectedAllOptions = useMemo(() => {
    return [...selectedOptionMap.values()].every((isSelected) => isSelected)
  }, [selectedOptionMap])
  useEffect(() => {
    if (onOptionsChange) {
      const selectedKeys: string[] = []
      for (const [key, isSelected] of selectedOptionMap.entries()) {
        if (isSelected && key !== ALL_OPTION_LABEL) {
          selectedKeys.push(key)
        }
      }
      onOptionsChange(selectedKeys)
    }
  }, [onOptionsChange, selectedOptionMap])
  return (
    <FilterDropdownContext.Provider value={{ selectedOptionMap, setSelectedOptionMap }}>
      <DropdownInput {...otherProps} enableMultiSelect height="32px" variant="secondary">
        <div className="my-2 flex w-full flex-row items-center justify-between text-xs font-normal text-wombatPurple1">
          <span
            className="cursor-pointer"
            onClick={() => {
              setSelectedOptionMap((prev) => {
                const newMap = new Map(prev)
                prev.forEach((_, key) => {
                  newMap.set(key, false)
                })

                return newMap
              })
            }}
          >
            Clear
          </span>
          <span
            className={clsx(
              'text- cursor-pointer',
              isSelectedAllOptions && 'cursor-not-allowed text-wombatBrown1'
            )}
            onClick={() => {
              setSelectedOptionMap((prev) => {
                const newMap = new Map(prev)
                prev.forEach((_, key) => {
                  newMap.set(key, true)
                })

                return newMap
              })
            }}
          >
            Select all
          </span>
        </div>
        <ScrollableBox scrollDirection="vertical" style={{ maxHeight: '150px' }}>
          {children}
        </ScrollableBox>
      </DropdownInput>
    </FilterDropdownContext.Provider>
  )
}

export default FilterDropdown

type FilterDropdownOptionProps<T> = {
  children: React.ReactNode
  isChecked?: boolean
  isAllOption?: boolean
  value?: string
} & OptionProps<T>
function Option<T>({
  children,
  isAllOption,
  onClick,
  value,
  ...otherProps
}: FilterDropdownOptionProps<T>) {
  const { selectedOptionMap, setSelectedOptionMap } = useFilterDropdownContext()
  const targetValue = isAllOption ? ALL_OPTION_LABEL : value
  const isChecked = !!(targetValue && selectedOptionMap.get(targetValue))
  useEffect(() => {
    setSelectedOptionMap((prev) => {
      const newMap = new Map(prev)
      if (targetValue) {
        newMap.set(targetValue, true)
      }
      return newMap
    })

    return () => {
      setSelectedOptionMap((prev) => {
        const newMap = new Map(prev)
        if (targetValue) {
          newMap.delete(targetValue)
        }
        return newMap
      })
    }
  }, [setSelectedOptionMap, targetValue])
  return (
    <DropdownInput.Option
      onClick={() => {
        if (targetValue) {
          setSelectedOptionMap((prev) => {
            const isAlreadySelected = prev.get(targetValue)
            const newMap = new Map(prev)
            if (isAllOption) {
              prev.forEach((_, key) => {
                newMap.set(key, !isAlreadySelected)
              })
            } else {
              // unselecting any option needs to unselect "all option" as well
              if (isAlreadySelected && prev.get(ALL_OPTION_LABEL)) {
                newMap.set(ALL_OPTION_LABEL, false)
              }
              newMap.set(targetValue, !isAlreadySelected)
            }
            return newMap
          })
        }
        onClick && onClick(value)
      }}
      {...otherProps}
    >
      <div className="flex w-full flex-row items-center justify-between font-Jamjuree font-semibold">
        <div className="text-[13px]">{children}</div>
        <div className="relative mr-1 flex items-center justify-center">
          <input
            className="h-[15px] w-[15px] cursor-pointer border-[1px] border-wombatPurple1 focus:ring-opacity-25"
            type="radio"
            style={{ background: 'unset' }}
          />
          {(isChecked || (isAllOption && isChecked)) && (
            <div className="pointer-events-none absolute left-[50%] top-[50%] h-[48%] w-[48%] translate-x-[-50%] translate-y-[-50%] bg-wombatPurple1" />
          )}
        </div>
      </div>
    </DropdownInput.Option>
  )
}

FilterDropdown.Option = React.memo(Option)

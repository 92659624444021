import React, { createContext, ReactElement, useContext, useState, useMemo } from 'react'
import { TokenInfo } from '../components/PoolPage'
import { PoolLabels } from '../constants/contract/pool/PoolLabels'
import { utils, BigNumber } from 'ethers'
import { useMasterWombat } from './masterWombatContext'
import { PoolFilterGroup } from '../constants/contract/pool/poolFilterGroup'
import { useCashesData } from '../store/Asset/hooks'

interface ContextType {
  tokenInfo: TokenInfo | null
  poolLabel: PoolLabels | null
  handlePoolCardInput: (poolLabel: PoolLabels, tokenInfo: TokenInfo) => void
  resetPoolCardInput: () => void
  depositedAmount: string
  stakedAmount: string
  hasStaked: boolean | null
  hasDeposited: boolean | null
  hasDepositedOrStaked: boolean | null
  selectedPoolFilterGroup: PoolFilterGroup | null
}

const initialState: ContextType = {
  tokenInfo: null,
  poolLabel: null,
  handlePoolCardInput: () => {
    //
  },
  resetPoolCardInput: () => {
    //
  },
  depositedAmount: '0',
  stakedAmount: '0',
  hasStaked: null,
  hasDeposited: null,
  hasDepositedOrStaked: null,
  selectedPoolFilterGroup: null,
}

const MyContext = createContext<ContextType>(initialState)

interface Props {
  children: React.ReactNode
  value: {
    selectedPoolFilterGroup: PoolFilterGroup
  }
}

export const usePoolCard = (): ContextType => {
  return useContext(MyContext)
}

function PoolCardProvider({ children, value }: Props): ReactElement {
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | null>(null)
  const [poolLabel, setPoolLabel] = useState<PoolLabels | null>(null)

  const handlePoolCardInput = (poolLabel: PoolLabels, tokenInfo: TokenInfo) => {
    setPoolLabel(poolLabel)
    setTokenInfo(tokenInfo)
  }

  const resetPoolCardInput = () => {
    setPoolLabel(null)
    setTokenInfo(null)
  }

  const { deposits } = useCashesData()

  const { userInfos } = useMasterWombat()

  const depositedAmount = useMemo(() => {
    if (!poolLabel || !tokenInfo) return '0'
    return utils.formatEther(deposits[poolLabel][tokenInfo.symbol] ?? BigNumber.from(0))
  }, [deposits, poolLabel, tokenInfo])

  const stakedAmount = useMemo(() => {
    if (!poolLabel || !tokenInfo) return '0'
    return utils.formatEther(userInfos[poolLabel][tokenInfo.symbol]?.amount ?? BigNumber.from(0))
  }, [poolLabel, tokenInfo, userInfos])

  const hasStaked = Number(stakedAmount) > 0
  const hasDeposited = Number(depositedAmount) > 0
  const hasDepositedOrStaked = hasStaked || hasDeposited

  return (
    <MyContext.Provider
      value={{
        tokenInfo,
        poolLabel,
        handlePoolCardInput,
        resetPoolCardInput,
        depositedAmount,
        stakedAmount,
        hasStaked,
        hasDeposited,
        hasDepositedOrStaked,
        ...value,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

export default PoolCardProvider

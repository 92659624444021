import { useEffect, useRef } from 'react'

export const usePoller = (
  // fn must be useCallback
  fn: () => void,
  /* @TODO: fix e2e test for polling data */
  delay = 10000
): void => {
  const savedCallback = useRef<() => void>()
  // Remember the latest fn.
  useEffect((): void => {
    savedCallback.current = () => {
      // add fn required online
      if (navigator.onLine) {
        fn()
      }
    }
  }, [fn])

  // Set up the interval.
  useEffect((): void | (() => void) => {
    function tick() {
      if (savedCallback.current) savedCallback.current()
    }
    if (delay !== undefined) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])

  // run at start too
  useEffect(() => {
    // add fn required online
    if (navigator.onLine) {
      fn()
    }
  }, [fn])
}

import { BigNumber, utils } from 'ethers'
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { TOKENS } from '../../constants/contract'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { Token } from '../../constants/contract/token/Token'
import { POOLS } from '../../constants/contract/pool'
import { useWeb3 } from '../../context/Web3Context'
import { formatNumberUSLocale, replaceString, sliceDecimal } from '../../utils/numberFormat'
import { decimalAndInteger } from '../../utils/regex'
import InputSelect from '../InputSelect'
import Slider from '../Slider/Slider'
import TokenImage from '../TokenImage'
import { SwapGroupSymbol } from '../../constants/contract/token/TokenSymbols'
import { TokenSymbolGenericType } from '../../interfaces/common'
import { AssetProperty } from '../../store/Asset/types'

interface IWithdrawInput {
  token: Token
  setLpAmount: Dispatch<SetStateAction<BigNumber | null>>
  withdrawToken: Token | null
  setWithdrawToken: Dispatch<SetStateAction<Token | null>>
  withdrawTokenPoolLabel: PoolLabels
  balances: AssetProperty<BigNumber | null>
  tokenAmountForDisplay: string
  setTokenAmountForDisplay: Dispatch<SetStateAction<string>>
  withdrawalQuote: BigNumber
  quotes: TokenSymbolGenericType<string>
  disableWithdrawInOtherAsset: boolean
}

const WithdrawInput: FC<IWithdrawInput> = (props) => {
  const {
    token,
    setLpAmount,
    withdrawToken,
    setWithdrawToken,
    withdrawTokenPoolLabel,
    balances,
    withdrawalQuote,
    tokenAmountForDisplay,
    quotes,
    setTokenAmountForDisplay,
    disableWithdrawInOtherAsset,
  } = props
  const [sliderValue, setSliderValue] = useState<string>('0')
  const { chainId } = useWeb3()
  const withdrawTokenPool = POOLS[chainId][withdrawTokenPoolLabel]
  const withdrawableTokens = []
  if (withdrawTokenPool) {
    for (const tokenSymbol of withdrawTokenPool.supportedAssetTokenSymbols) {
      const token = TOKENS[chainId][tokenSymbol]
      if (token && token.swapGroupSymbol !== SwapGroupSymbol.UNAVAILABLE) {
        withdrawableTokens.push(token)
      }
    }
  }
  const handleChangeAmount = (value: string) => {
    if (!value) {
      setSliderValue('0')
      setLpAmount(BigNumber.from(0))
      setTokenAmountForDisplay('')
    } else {
      const replacedAmount = replaceString(value, ',', '.')
      if (decimalAndInteger.test(replacedAmount)) {
        setLpAmount(utils.parseEther(replacedAmount))
        setTokenAmountForDisplay(replacedAmount)
        if (token && balances) {
          const balanceTokenSelected = Number(
            utils.formatEther(balances[withdrawTokenPoolLabel][token.symbol] ?? 0)
          )
          const preSliderValue = balanceTokenSelected
            ? sliceDecimal((Number(replacedAmount) / balanceTokenSelected) * 100, 2)
            : '0'
          if (Number(preSliderValue) === 100) {
            setSliderValue('100')
          } else {
            setSliderValue(preSliderValue)
          }
        }
      }
    }
  }

  const handleMax = () => {
    if (balances) {
      setSliderValue('100')
      setTokenAmountForDisplay(
        utils.formatEther(balances[withdrawTokenPoolLabel][token.symbol] ?? 0)
      )
      setLpAmount(balances[withdrawTokenPoolLabel][token.symbol] ?? null)
    }
  }

  useEffect(() => {
    if (!tokenAmountForDisplay) setSliderValue('0')
  }, [tokenAmountForDisplay])

  const isBetaLogoShown = withdrawTokenPoolLabel === PoolLabels.CROSS_CHAIN

  return (
    <div className="w-full rounded-lg bg-wombatCream p-3 font-Work">
      <div className="flex items-center gap-2 pb-2">
        <div className="h-[34px]">
          <TokenImage tokenSymbol={token.symbol} width="34" height="34" />
        </div>
        <div className="flex w-full items-center justify-between">
          <div className="flex select-none items-center pl-1">
            <div className="flex-row">
              <div className="font-semibold leading-5">{token.displaySymbol}</div>
              <div className="font-Work text-xs leading-3 text-wombatBrown1">{token.name}</div>
            </div>
            {isBetaLogoShown && (
              <div className="mr-2 ml-2 flex items-center bg-linearGradientApprove px-[5px] font-Quantico font-bold text-white">
                Beta
              </div>
            )}
          </div>
          <div className="abosolute top-6 right-4 font-Work text-xs font-normal text-wombatPurple">
            {`Deposit: ${formatNumberUSLocale(
              utils.formatEther(balances[withdrawTokenPoolLabel][token.symbol] ?? '0'),
              2
            )}`}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between  rounded-md border-2 border-wombatPurple1 bg-white px-2">
        <input
          placeholder="0.00"
          type="text"
          inputMode="numeric"
          className="h-8 w-full rounded-lg bg-white font-Work font-semibold text-wombatBrown1 focus:outline-none"
          value={tokenAmountForDisplay}
          onChange={(e) => handleChangeAmount(e.target.value)}
        />
        <button
          className="button-hover-opacity bg-white px-1 font-Work font-semibold uppercase text-wombatPurple"
          onClick={handleMax}
        >
          MAX
        </button>
      </div>
      <Slider
        hasHeadValue={true}
        value={sliderValue}
        callBack={(val) => {
          setSliderValue(val)
          try {
            const balance = balances[withdrawTokenPoolLabel][token.symbol]
            if (balance && token) {
              if (Number(val) === 100) {
                setTokenAmountForDisplay(utils.formatEther(balance))
                setLpAmount(balance)
              } else {
                setTokenAmountForDisplay(utils.formatEther(balance.mul(val).div(100)))
                setLpAmount(balance.mul(val).div(100))
              }
            }
          } catch (error) {
            console.log(error)
          }
        }}
      />
      <div className="flex items-center justify-between whitespace-nowrap text-xs font-normal">
        {withdrawTokenPool && !disableWithdrawInOtherAsset ? (
          <>
            <span className="mr-2">Withdraw in</span>
            <InputSelect
              balances={quotes}
              onSelectedChange={(token) => {
                setWithdrawToken(token)
              }}
              selectedToken={withdrawToken}
              amount={withdrawalQuote}
              onAmountChanged={null}
              tokens={withdrawableTokens}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
export default WithdrawInput

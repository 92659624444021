import { BigNumber } from 'ethers'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { CallbacksType, IContractCalls } from '../../../utils/executeCallBacks'
import { getInitialAssetProperty } from '../../../utils/asset'
import { ASSETS } from '../../../constants/contract/asset'
import { PoolLabels } from '../../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../../constants/contract/token/TokenSymbols'
import { AssetProperty } from '../../../store/Asset/types'
export interface AssetDataWithoutAccountType {
  cashesBn: AssetProperty<BigNumber>
  liabilitiesBn: AssetProperty<BigNumber>
  totalSuppliesBn: AssetProperty<BigNumber>
  maxSuppliesBn: AssetProperty<BigNumber>
}

export interface AssetDataWithAccountType {
  myLpBalancesInTermsOfLpBn: AssetProperty<BigNumber>
}

export const fetchAssetData = (
  chainId: SupportedChainId,
  account: string | null | undefined,
  onlyXChain?: boolean
): {
  contractCalls: IContractCalls
  callbacks: CallbacksType
  states: { withAccount: AssetDataWithAccountType; withoutAccount: AssetDataWithoutAccountType }
} => {
  /**
   *  Create empty contractCalls and callbacks array
   */
  const contractCalls: IContractCalls = []
  const callbacks: CallbacksType = []
  const states: {
    withoutAccount: AssetDataWithoutAccountType
    withAccount: AssetDataWithAccountType
  } = {
    withoutAccount: {
      cashesBn: getInitialAssetProperty<BigNumber>(),
      liabilitiesBn: getInitialAssetProperty<BigNumber>(),
      totalSuppliesBn: getInitialAssetProperty<BigNumber>(),
      maxSuppliesBn: getInitialAssetProperty<BigNumber>(),
    },
    withAccount: {
      myLpBalancesInTermsOfLpBn: getInitialAssetProperty<BigNumber>(),
    },
  }

  const handleAssetData = () => {
    // withoutAccount data
    Object.entries(ASSETS[chainId]).forEach(([poolLabelStr, assetsInPool]) => {
      if (onlyXChain && poolLabelStr !== PoolLabels.CROSS_CHAIN) return
      Object.entries(assetsInPool).forEach(([assetSymbolStr, asset]) => {
        const poolLabel = poolLabelStr as PoolLabels
        const assetSymbol = assetSymbolStr as TokenSymbol
        // withAccount data
        if (account && !asset.delisted && !asset.paused) {
          contractCalls.push(asset.multicall('balanceOf', [account]))
          callbacks.push((value) => {
            states.withAccount.myLpBalancesInTermsOfLpBn[poolLabel][assetSymbol] = value
          })
        }

        if (asset.delisted || asset.paused) return
        contractCalls.push(
          asset.multicall('cash'),
          asset.multicall('liability'),
          asset.multicall('totalSupply'),
          asset.multicall('maxSupply')
        )
        callbacks.push(
          (value) => {
            states.withoutAccount.cashesBn[poolLabel][assetSymbol] = value
          },
          (value) => {
            states.withoutAccount.liabilitiesBn[poolLabel][assetSymbol] = value
          },
          (value) => {
            states.withoutAccount.totalSuppliesBn[poolLabel][assetSymbol] = value
          },
          (value) => {
            states.withoutAccount.maxSuppliesBn[poolLabel][assetSymbol] = value
          }
        )
      })
    })
  }

  handleAssetData()

  return {
    contractCalls,
    callbacks,
    states,
  }
}

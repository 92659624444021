/* eslint-disable @typescript-eslint/ban-ts-comment */
// Reference: https://github.com/Uniswap/interface/blob/main/src/utils/index.ts
import {
  isParsableString,
  strToWad,
  getDpFormat,
  wmul,
  safeWdiv,
} from '@hailstonelabs/big-number-utils'
import { BigNumber, utils, constants } from 'ethers'
import { SupportedChainId, TESTNET_CHAIN_IDS } from '../constants/web3/supportedChainId'

// returns the checksummed address if the address is valid, otherwise returns false
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getChecksummedAddress(value: any): string | false {
  try {
    return utils.getAddress(value)
  } catch {
    return false
  }
}

export function isEmptyAddress(address: string | undefined): boolean {
  const isAddressValid = !!getChecksummedAddress(address)
  return isAddressValid && address === constants.AddressZero
}

export function isNonEmptyAddress(address: string | undefined): boolean {
  const isAddressValid = !!getChecksummedAddress(address)
  return isAddressValid && address !== constants.AddressZero
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = getChecksummedAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return shortenHashString(parsed, chars)
}

export function shortenHashString(hash: string, chars = 4, hashLen = 42): string {
  return `${hash.substring(0, chars + 2)}...${hash.substring(hashLen - chars)}`
}

export function commify(str: string): string {
  const parts = str.split('.')
  if (parts.length > 2) throw new Error('commify string cannot have > 1 period')
  const [partA, partB] = parts
  if (partA.length === 0) return partB === undefined ? '' : `.${partB}`
  const mod = partA.length % 3
  const div = Math.floor(partA.length / 3)
  // define a fixed length array given the expected # of commas added
  const commaAArr = new Array(partA.length + (mod === 0 ? div - 1 : div))
  // init pointers for original string and for commified array
  let commaAIdx = commaAArr.length - 1
  // iterate original string backwards from the decimals since that's how commas are added
  for (let i = partA.length - 1; i >= 0; i--) {
    // revIdx is the distance from the decimal place eg "3210."
    const revIdx = partA.length - 1 - i
    // add the character to the array
    commaAArr[commaAIdx--] = partA[i]
    // add a comma if we are a multiple of 3 from the decimal
    if ((revIdx + 1) % 3 === 0) {
      commaAArr[commaAIdx--] = ','
    }
  }
  const commifiedA = commaAArr.join('')
  return partB === undefined ? commifiedA : `${commifiedA}.${partB}`
}

export function parseStringToBigNumber(
  valueRaw: string,
  precision: number,
  fallback?: BigNumber
): { value: BigNumber; isFallback: boolean } {
  let valueSafe: BigNumber
  let isFallback: boolean
  try {
    // attempt to parse string. Use fallback value if library error is thrown
    valueSafe = utils.parseUnits(valueRaw, precision)
    isFallback = false
  } catch {
    valueSafe = fallback ?? BigNumber.from('0')
    isFallback = true
  }
  return { value: valueSafe, isFallback }
}

// add 20%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(2000))).div(BigNumber.from(10000))
}

export function formattedPercentage(x: number, dp: number): string {
  return (x * 100).toFixed(dp) + '%'
}

export const timeConverter = (
  UNIX_timestamp: number,
  showTime = false,
  utc = false,
  showTimezone = false
) => {
  const a = new Date(UNIX_timestamp * 1000)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const year = utc ? a.getUTCFullYear() : a.getFullYear()
  const month = utc ? months[a.getUTCMonth()] : months[a.getMonth()]
  const date = utc ? a.getUTCDate() : a.getDate()

  if (date && month && year) {
    if (showTime) {
      const hours = (utc ? a.getUTCHours() : a.getHours()).toString().padStart(2, '0')
      const minutes = (utc ? a.getUTCMinutes() : a.getMinutes()).toString().padStart(2, '0')
      // getTimezoneOffset() returns a positive value if the local time is behind UTC
      // and a negative value if the local time is ahead of UTC
      const timezoneOffsetInHours = (a.getTimezoneOffset() / 60) * -1
      const timezone = showTimezone
        ? ` GMT${timezoneOffsetInHours >= 0 ? '+' : ''}${timezoneOffsetInHours}`
        : ''
      const time = date + ' ' + month + ' ' + year + ' ' + hours + ':' + minutes + timezone
      return time
    } else {
      const time = date + ' ' + month + ' ' + year
      return time
    }
  }
  return '-'
}

export const timeSince = (timeStamp: number) => {
  const date = new Date(timeStamp * 1000)
  const now = new Date()
  const seconds = Math.floor((date.getTime() - now.getTime()) / 1000)
  const minute = Math.floor((seconds / 60) % 60)
  const hours = Math.floor((seconds / 60 / 60) % 24)
  const days = Math.floor(seconds / 60 / 60 / 24)
  return {
    countDown: days >= 1 ? `${days} days` : hours >= 1 ? `${hours}h` : `${minute}min`,
    isLock: seconds >= 0,
  }
}

export const getPeriodDuration = (endDayTimestamp: number, dayLockupPeriod: number) => {
  const startDayTimestamp = endDayTimestamp - dayLockupPeriod * 24 * 60 * 60
  const endDay = timeConverter(endDayTimestamp, true)
  const startDay = timeConverter(startDayTimestamp, true)
  const now = new Date()
  const progressPercent =
    ((now.getTime() / 1000 - startDayTimestamp) / (endDayTimestamp - startDayTimestamp)) * 100
  const periodDuration = `${startDay} - ${endDay}`
  return {
    periodDuration,
    progressPercent,
  }
}

export const getClaimDate = (dayLockupPeriod: number, originLockTime?: number) => {
  const now = new Date()
  const dayLockupPeriodInMilliseconds = dayLockupPeriod * 24 * 60 * 60 * 1000
  let endDayTimestamp
  if (originLockTime) {
    // For Extend Lock
    endDayTimestamp = originLockTime * 1000 + dayLockupPeriodInMilliseconds
  } else {
    // For First Lock
    endDayTimestamp = now.getTime() + dayLockupPeriodInMilliseconds
  }
  const endDay = timeConverter(endDayTimestamp / 1000)
  return endDay
}

export const calculateApr = (
  multiplicationFactor: BigNumber,
  annualReward: BigNumber,
  rewardPrice: BigNumber,
  totalAmount: BigNumber,
  depositUnitPrice: BigNumber
) => {
  const annualRewardInUsdWad = wmul(annualReward, rewardPrice)
  const totalStakedAmountInUsdWad = wmul(totalAmount, depositUnitPrice)

  return totalAmount > BigNumber.from(0) && depositUnitPrice > BigNumber.from(0)
    ? safeWdiv(wmul(multiplicationFactor, annualRewardInUsdWad), totalStakedAmountInUsdWad)
    : BigNumber.from(0)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here")
}
/**
 * Multiplying a total value by a percentage to get a desired value.
 * @param {string} totalValue
 * @param {string} percentage
 * @returns {string}
 */
export function getValueFromPercentage(totalValue: string, percentage: string): string {
  if (!isParsableString(totalValue, 18, true) || !isParsableString(percentage, 18, true)) return ''
  return utils.formatEther(strToWad(totalValue).mul(strToWad(percentage)).div(strToWad('100')))
}

export const getLockPositionMultiplier = (veWomBalance: string, womBalance: string) => {
  const multiplier = (Number(veWomBalance) / Number(womBalance)).toFixed(3)
  return getDpFormat(multiplier, 3)
}

/**
 * Get the formatted UTC date after a specified number of days.
 *
 * @param {number} days - The number of days to add to the current date.
 * @param {boolean} [showTime=false] - Optional parameter to indicate if the time should be included in the result.
 * @returns {string} - The formatted date in UTC timezone. If `showTime` is `true`, the time will be included.
 */
export const getFormattedUtcDateAfterNdays = (days: number, showTime = false) => {
  const endDate = new Date()
  endDate.setDate(endDate.getDate() + +days)

  // getTime() returns local timestamp
  const endDateInUnixTime = endDate.getTime() / 1000
  const formattedEndDate = timeConverter(endDateInUnixTime, showTime, true)
  return formattedEndDate
}

export const isTestnet = (chainId: SupportedChainId) => {
  return TESTNET_CHAIN_IDS.includes(chainId)
}

import { useCallback, useEffect, useState } from 'react'
import { useWindowDimension } from './useWindowDimensions'
import defaultTheme from 'tailwindcss/defaultTheme'
const { md, sm, lg, xl } = defaultTheme.screens
interface ReturnType {
  isXl: boolean
  isLg: boolean
  isMd: boolean
  isSm: boolean
}

const initialBreakpoints: ReturnType = {
  isXl: false,
  isLg: false,
  isMd: false,
  isSm: false,
}

function useBreakpoints(): ReturnType {
  const { width } = useWindowDimension()
  const [breakpoints, setBreakpoints] = useState<ReturnType>(initialBreakpoints)
  const isLargerThanWindowWidth = useCallback(
    (breakpoint: string) => {
      return +breakpoint.replace('px', '') > width
    },
    [width]
  )
  useEffect(() => {
    setBreakpoints({
      isSm: isLargerThanWindowWidth(sm),
      isMd: isLargerThanWindowWidth(md),
      isLg: isLargerThanWindowWidth(lg),
      isXl: isLargerThanWindowWidth(xl),
    })
  }, [isLargerThanWindowWidth, width])
  return breakpoints
}

export default useBreakpoints

import { strToWad } from '@hailstonelabs/big-number-utils'
import clsx from 'clsx'
import { BigNumber, utils } from 'ethers'
import Image from 'next/image'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { TokenSymbols } from '../../configx/tokenSymbols'
import { TOKENS } from '../../constants/contract'
import { ASSETS } from '../../constants/contract/asset'
import { Asset } from '../../constants/contract/asset/Asset'
import { POOLS } from '../../constants/contract/pool'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { Token } from '../../constants/contract/token/Token'
import { useWeb3 } from '../../context/Web3Context'
import { useMasterWombat } from '../../context/masterWombatContext'
import useBoostedPoolRewarderAprs from '../../hooks/pool/useBoostedPoolRewarderAprs'
import CalculatorBrownIcon from '../../public/assets/icons/calculator-brown.svg'
import ResetIcon from '../../public/assets/icons/reset-icon.svg'
import ArrowDownIcon from '../../public/assets/icons/toggle-down.svg'
import ArrowUpIcon from '../../public/assets/icons/toggle-up.svg'
import TooltipIcon from '../../public/assets/icons/tooltip.svg'
import WarningIcon from '../../public/assets/icons/warning-icon.svg'
import WOMicon from '../../public/assets/tokens/WOM.svg'
import { useCashesData } from '../../store/Asset/hooks'
import { useMasterWombatData, useTokenData, useVewomData } from '../../store/MulticallData/hooks'
import { useTokenPrices } from '../../store/Prices/hooks'
import { calLPPrice } from '../../utils/math'
import { formatNumberUSLocale, sliceDecimal } from '../../utils/numberFormat'
import { getExpectedVeWom } from '../../utils/veWom'
import Modal from '../Modal'
import ScrollableBox from '../ScrollableBox'
import Slider from '../Slider/Slider'
import TokenImage from '../TokenImage'
import Tooltip from '../Tooltip'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const maxDays = 1461
const dayPerPercent = maxDays / 100

const BoostCalculatorModal = ({ isOpen, onClose }: Props) => {
  const [isSelectTokenLabelExpand, setIsSelectTokenLabelExpand] = useState(false)
  const [lockPeriodValue, setLockPeriodValue] = useState<number | string | null>(null)
  const [sliderValue, setSliderValue] = useState(0)
  const [selectedPool, setSelectedPool] = useState<PoolLabels | null>(null)
  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null)
  const [selectedToken, setSelectedToken] = useState<Token | null>(null)
  const [baseAPR, setBaseAPR] = useState<number>(0)
  const [boostedAPR, setBoostedAPR] = useState<number>(0)
  const { chainId } = useWeb3()
  const [stakeAmount, setStakeAmount] = useState<BigNumber | null>(null)
  const [lockAmount, setLockAmount] = useState<BigNumber | null>(null)
  const [poolLiquidity, setPoolLiquidity] = useState<string | undefined>(undefined)
  const [poolLiquidityUSD, setPoolLiquidityUSD] = useState<string | undefined>(undefined)
  const [poolShare, setPoolShare] = useState<string | undefined>(undefined)
  const [isOpenTooltipMobile, setIsOpenTooltipMobile] = useState(false)
  const { withAccount } = useTokenData()
  const tokenPrices = useTokenPrices()
  const masterWombatData = useMasterWombatData()
  const womBalance = withAccount?.balances[TokenSymbols.WOM]
  const totalLpStakedBNs = useMemo(() => {
    return masterWombatData.withoutAccount?.totalLpStakedBNs
  }, [masterWombatData.withoutAccount?.totalLpStakedBNs])
  const {
    baseAprWads: baseAprs,
    boostedAprs,
    userInfos,
    actions: { estimateBoostedApr },
  } = useMasterWombat()
  const { boostedPoolRewarderAprs } = useBoostedPoolRewarderAprs()

  const { lpTokenToTokenRates } = useCashesData()
  const totalStakedAmountBN =
    selectedPool && selectedAsset ? totalLpStakedBNs?.[selectedPool][selectedAsset.symbol] : null
  const userStakedAmount =
    selectedPool && selectedAsset
      ? userInfos[selectedPool][selectedAsset.symbol]?.amount ?? null
      : null

  useEffect(() => {
    if (totalStakedAmountBN) {
      {
        /* Pool Liquidity = staked liquidity (other users) + user stake liquidity */
      }
      const _poolLiquidity = totalStakedAmountBN.sub(userStakedAmount ?? 0).add(stakeAmount ?? 0)
      /** @TODO [lp token to token amount] _poolLiquidity is number of lp token */
      const _poolLiquidityUSD =
        selectedAsset && selectedPool
          ? calLPPrice(
              _poolLiquidity,
              lpTokenToTokenRates[selectedPool][selectedAsset.symbol] ?? null,
              strToWad(tokenPrices[selectedAsset.symbol])
            )
          : null
      if (_poolLiquidity) {
        setPoolLiquidity(utils.formatEther(_poolLiquidity))
      }
      if (_poolLiquidityUSD) {
        setPoolLiquidityUSD(utils.formatEther(_poolLiquidityUSD))
      }

      {
        /* Pool Share = stake amount / Pool Liquidity */
      }
      const _poolShare =
        Number(utils.formatEther(stakeAmount ?? 0)) / Number(utils.formatEther(_poolLiquidity))
      setPoolShare((_poolShare * 100).toString())
    }
  }, [
    lpTokenToTokenRates,
    selectedPool,
    selectedAsset,
    stakeAmount,
    tokenPrices,
    totalStakedAmountBN,
    userStakedAmount,
  ])

  const availablePoolsFromConfig = useMemo(() => {
    return Object.values(POOLS[chainId]).filter((pool) => {
      return pool.isShownInBoosterCalculator === true
    })
  }, [chainId])

  const availablePoolsFromChain = useMemo(() => {
    return Object.values(POOLS[chainId]).filter((pool) => {
      const baseAprGreaterThanZero = pool.supportedAssetTokenSymbols.some((tokenSymbol) => {
        const baseAprBN = baseAprs[pool.label][tokenSymbol]
        const asset = ASSETS[chainId][pool.label][tokenSymbol]

        let totalBaseAprFromBoostedRewarder = 0

        if (asset) {
          if (asset.boostedPoolRewarder && asset.boostedPoolRewarder.rewardTokenSymbols) {
            asset.boostedPoolRewarder.rewardTokenSymbols.map((rewardSymbol, index) => {
              const aprs = boostedPoolRewarderAprs[pool.label][tokenSymbol]
              const baseApr = aprs?.base[index] || '0'
              totalBaseAprFromBoostedRewarder += +baseApr
            })
          }
        }

        return (baseAprBN && baseAprBN.gt('0')) || totalBaseAprFromBoostedRewarder > 0
      })
      return baseAprGreaterThanZero
    })
  }, [baseAprs, boostedPoolRewarderAprs, chainId])

  const availablePools = useMemo(() => {
    if (availablePoolsFromChain.length) {
      return availablePoolsFromChain
    } else if (availablePoolsFromConfig.length) {
      return availablePoolsFromConfig
    } else {
      return availablePoolsFromChain.filter((pool) => {
        return availablePoolsFromConfig.some(
          (poolFromConfig) => poolFromConfig.label === pool.label
        )
      })
    }
  }, [availablePoolsFromChain, availablePoolsFromConfig])

  const renderTokenDropdown = () => (
    <div
      className={`absolute -inset-x-px top-full z-10 rounded rounded-t-none border-1 border-t-0 border-wombatPurple1 bg-white p-2 pt-0 ${
        isSelectTokenLabelExpand ? '' : 'hidden'
      }`}
    >
      <ScrollableBox scrollDirection="vertical" style={{ maxHeight: '400px' }}>
        {availablePools.map((pool) => (
          <React.Fragment key={pool.label}>
            <div className="flex items-center gap-2">
              <hr className="h-0.5 grow bg-linearGradientApprove" />
              <p
                className="bg-linearGradientApprove bg-clip-text"
                style={{ WebkitTextFillColor: 'transparent' }}
              >
                {pool.name.replace(' Pool', '')}
              </p>
              <hr className="h-0.5 grow bg-linearGradientApprove" />
            </div>
            {pool.supportedAssetTokenSymbols.map((assetTokenSymbol) => {
              const asset = ASSETS[chainId][pool.label][assetTokenSymbol]
              const token = TOKENS[chainId][assetTokenSymbol]
              return asset && !asset.paused && token ? (
                <button
                  key={pool.label + '-' + assetTokenSymbol}
                  className="flex w-full items-center gap-2 py-1 pl-2 hover:bg-wombatYellow2"
                  onClick={() => {
                    setSelectedPool(pool.label)
                    setSelectedAsset(asset)
                    setSelectedToken(token)
                    handleClickSelectTokenExpand()

                    let totalBaseAprFromBoostedRewarder = 0
                    let totalAvgBoostedAprFromBoostedRewarder = 0

                    asset?.boostedPoolRewarder?.rewardTokenSymbols?.map((rewardSymbol, index) => {
                      const aprs = boostedPoolRewarderAprs[pool.label][assetTokenSymbol]
                      const baseApr = aprs?.base[index] || '0'
                      const averageApr = aprs?.average[index] || '0'
                      totalBaseAprFromBoostedRewarder += +baseApr
                      totalAvgBoostedAprFromBoostedRewarder += +averageApr
                    })

                    const totalBaseApr =
                      (Number(utils.formatEther(baseAprs[pool.label][assetTokenSymbol] ?? 0)) +
                        totalBaseAprFromBoostedRewarder) *
                      100
                    const totalBoostedApr =
                      (Number(utils.formatEther(boostedAprs[pool.label][assetTokenSymbol] ?? 0)) +
                        totalAvgBoostedAprFromBoostedRewarder) *
                      100

                    setBaseAPR(totalBaseApr)
                    setBoostedAPR(totalBoostedApr)
                  }}
                >
                  <TokenImage tokenSymbol={token.symbol} width="18" height="18" />
                  <div className="font-Helvetica text-sm">{token.displaySymbol}</div>
                </button>
              ) : (
                <></>
              )
            })}
          </React.Fragment>
        ))}
      </ScrollableBox>
    </div>
  )

  const handleClickSelectTokenExpand = () => {
    setIsSelectTokenLabelExpand(!isSelectTokenLabelExpand)
  }

  const amountOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value) {
        setStakeAmount(utils.parseEther(e.target.value))
      } else {
        setStakeAmount(null)
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  const amountLockOnchange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.value) {
        setLockAmount(utils.parseEther(e.target.value))
      } else {
        setLockAmount(null)
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  const refreshDepositAmount = useCallback(() => {
    setStakeAmount(userStakedAmount)
  }, [userStakedAmount])

  useEffect(() => {
    if (selectedAsset) refreshDepositAmount()
  }, [refreshDepositAmount, selectedAsset])

  const handleMax = () => {
    if (womBalance) setLockAmount(utils.parseUnits(womBalance, 18))
  }

  const handleChangeLockPeriod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (parseFloat(value)) {
      setLockPeriodValue(parseFloat(value))
      setSliderValue(Math.round(parseFloat(value) / dayPerPercent))
    } else {
      setLockPeriodValue(null)
    }
  }

  const handleCallBackSlider = (val: string) => {
    const value = Number(val)
    if (value === 0) {
      setLockPeriodValue(7)
    } else {
      setLockPeriodValue(Math.round(Number(value) * dayPerPercent))
    }
    setSliderValue(value)
  }
  const vewomData = useVewomData()
  const { vewomTotalSupplyWad, vewomBalanceWad } = useMemo(() => {
    return {
      vewomTotalSupplyWad: vewomData.withoutAccount?.vewomTotalSupplyWad || null,
      vewomBalanceWad: vewomData.withAccount?.vewomBalanceWad || null,
    }
  }, [vewomData.withAccount?.vewomBalanceWad, vewomData.withoutAccount?.vewomTotalSupplyWad])
  const [estimatedBoostedAPR, setEstimatedBoostedAPR] = useState<BigNumber | null>(null)

  const veWomEarnings = useMemo(
    () => getExpectedVeWom(lockAmount || BigNumber.from(0), Number(lockPeriodValue)),
    [lockAmount, lockPeriodValue]
  )

  const expectedVeWomBalance =
    veWomEarnings + Number(utils.formatEther(vewomBalanceWad ?? BigNumber.from(0)))

  const veWomShare = useMemo(
    () =>
      // (expectedVeWomBalance / (veWomEarnings + totalSupply)) * 100
      (expectedVeWomBalance /
        (veWomEarnings + Number(utils.formatEther(vewomTotalSupplyWad ?? BigNumber.from(0))))) *
      100,
    [expectedVeWomBalance, veWomEarnings, vewomTotalSupplyWad]
  )

  useEffect(() => {
    if (!selectedPool || !selectedAsset) {
      setEstimatedBoostedAPR(null)
    } else {
      try {
        const apr = estimateBoostedApr(
          selectedPool,
          selectedAsset.symbol,
          userStakedAmount ?? BigNumber.from(0),
          vewomBalanceWad ?? BigNumber.from(0),
          stakeAmount ?? BigNumber.from(0),
          utils
            .parseEther(veWomEarnings.toFixed(18) ?? '0')
            .add(vewomBalanceWad ?? BigNumber.from(0))
        )
        setEstimatedBoostedAPR(apr)
      } catch (e) {
        console.error('@setEstimatedBoostedAPR')
      }
    }
  }, [
    estimateBoostedApr,
    selectedPool,
    selectedAsset,
    stakeAmount,
    userStakedAmount,
    vewomBalanceWad,
    veWomEarnings,
  ])
  const baseAPR_UI = `${formatNumberUSLocale(baseAPR.toString())}%`
  const boostedAPR_UI = `${formatNumberUSLocale(boostedAPR.toString())}%`

  const estimatedBoostedAPR_UI = `${formatNumberUSLocale(
    stakeAmount ? (Number(utils.formatEther(estimatedBoostedAPR || 0)) * 100).toString() : '0.00'
  )}%`

  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} width={400}>
      <div className="relative text-wombatBrown1">
        <div
          style={{
            transform: 'translate(20%,20%)',
          }}
          className="absolute top-full left-full  hidden h-3 w-3 md:block"
        >
          <Tooltip>
            This calculator is for reference only. Rewards are calculated weekly and may change from
            time to time. The rates above are not guaranteed for the duration of the above term.
          </Tooltip>
        </div>
        <div className="mb-4 flex items-center justify-center md:hidden">
          <div
            className="top-full left-full flex h-3 w-3 items-center"
            onClick={() => {
              setIsOpenTooltipMobile(true)
            }}
          >
            <Image alt={''} src={TooltipIcon} height={12} width={12} />
          </div>
          <div className="ml-1">Booster Calculator</div>
          <div className="ml-1 flex items-center">
            <Image alt={''} src={CalculatorBrownIcon} width={16} height={16} />
          </div>
        </div>
        <div className="mb-6 rounded-lg border-2 border-wombatPurple1 px-6 py-8 text-wombatBrown1 md:rounded-none md:border-none md:bg-transparent md:p-0">
          <div
            className={clsx(
              'relative mb-2 select-none  rounded border-1 border-wombatPurple bg-white',
              isSelectTokenLabelExpand ? 'rounded-b-none' : ''
            )}
          >
            {availablePools.length > 0 && (
              <>
                <button
                  className="button-hover-opacity-light flex w-full items-center justify-between px-2 py-3"
                  onClick={handleClickSelectTokenExpand}
                >
                  <div className="flex items-center text-xs">
                    {selectedToken ? (
                      <>
                        <TokenImage tokenSymbol={selectedToken.symbol} width="18" height="18" />
                        <div className="ml-1 font-Helvetica text-sm">
                          {selectedToken.displaySymbol}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>Select a token</div>
                      </>
                    )}
                  </div>
                  <div className="flex items-center">
                    <Image
                      alt={''}
                      src={!isSelectTokenLabelExpand ? ArrowDownIcon : ArrowUpIcon}
                      width={10}
                      height={5}
                    />
                  </div>
                </button>

                {renderTokenDropdown()}
              </>
            )}
          </div>
          <div>
            <div className="font-Work text-xs font-medium text-wombatPurple">Staked deposit</div>
            <div className="mt-1 flex items-center justify-between rounded border-1 border-wombatPurple p-2">
              <div className="w-full font-Helvetica font-medium text-wombatBrown1">
                <input
                  inputMode="numeric"
                  type="number"
                  placeholder="0.00"
                  className="w-full rounded-md bg-transparent font-Helvetica text-base font-medium text-wombatBrown1 focus:outline-none"
                  value={
                    stakeAmount ? parseFloat(utils.formatUnits(stakeAmount.toString(), 18)) : ''
                  }
                  onChange={amountOnchange}
                />
              </div>
              <button
                onClick={refreshDepositAmount}
                className="button-hover-opacity flex items-center pl-1"
              >
                <Image alt={''} src={ResetIcon} width={16} height={16} />
              </button>
            </div>
          </div>
          <div className="mt-2 flex justify-between">
            <div className="font-Work text-xs">Pool Liquidity</div>
            <div className="flex items-center font-Work text-xs">
              <div className="mr-1">
                <div className="text-right">
                  {selectedToken
                    ? `${sliceDecimal(Number(poolLiquidity), 2)} ${selectedToken.displaySymbol}`
                    : '-'}
                </div>
                <div className="text-wombatBrown2">
                  {selectedAsset ? `$${sliceDecimal(Number(poolLiquidityUSD), 6)}` : '-'}
                </div>
              </div>
              <button onClick={refreshDepositAmount} className="button-hover-opacity h-full">
                <Image alt={''} src={ResetIcon} width={15} height={15} />
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between text-xs">
            <div className="">Share of pool</div>
            <div className="mr-5">
              {formatNumberUSLocale(selectedAsset && poolShare ? poolShare : '0.00')}%
            </div>
          </div>
          <div className="mt-6">
            <div className="text-xs font-medium text-wombatPurple">WOM Lock Amount</div>
          </div>
          <div className="mt-1 flex items-center justify-between rounded border-1 border-wombatPurple p-2">
            <div className="flex items-center">
              <Image alt={''} src={WOMicon} width={20} height={20} />
            </div>
            <div className="ml-2 w-full font-Helvetica font-medium text-wombatBrown1">
              <input
                type="number"
                inputMode="numeric"
                placeholder="0.00"
                className="w-full bg-transparent font-Helvetica text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none"
                value={lockAmount ? parseFloat(utils.formatUnits(lockAmount.toString(), 18)) : ''}
                onChange={amountLockOnchange}
              />
            </div>

            <button
              onClick={handleMax}
              className="button-hover-opacity px-1 text-sm font-semibold text-wombatPurple"
            >
              MAX
            </button>
          </div>
          <div className="mt-2">
            <div className="text-xs font-medium text-wombatPurple">Lock period</div>
          </div>
          <div className="mt-1 flex items-center justify-between rounded border-1 border-wombatPurple p-2">
            <div className="grow font-Helvetica font-medium text-wombatBrown1">
              <input
                className="w-full bg-transparent font-Helvetica text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none"
                inputMode="numeric"
                placeholder="7"
                onChange={handleChangeLockPeriod}
                value={lockPeriodValue ?? ''}
              />
            </div>

            <div className="select-none text-sm font-medium text-wombatBrown1">days</div>
          </div>
          <div>
            <Slider
              value={sliderValue.toString()}
              // hasHeadValue
              callBack={handleCallBackSlider}
            />
          </div>
          <div className="rounded bg-wombatYellow3 p-2 text-xs">
            <div className="flex items-center justify-between">
              <div>veWOM Earnings</div>
              <div>{formatNumberUSLocale(veWomEarnings ? veWomEarnings.toString() : '0.00')}</div>
            </div>
            <div className="mt-1 flex items-center justify-between">
              <div>total veWOM</div>
              <div>{formatNumberUSLocale(expectedVeWomBalance.toString())}</div>
            </div>
            <div className="mt-1 flex items-center justify-between">
              <div>veWOM Share</div>
              <div>{formatNumberUSLocale(veWomShare ? veWomShare.toString() : '0.00')}%</div>
            </div>
            <div className="mt-1 flex items-center justify-between">
              <div>Base APR</div>
              <div>{baseAPR_UI}</div>
            </div>
            <div className="mt-1 flex items-center justify-between">
              <div>Current Boosted APR</div>
              <div>{boostedAPR_UI}</div>
            </div>
          </div>
          <div className="flex w-full items-center justify-center p-3 text-xs font-medium">
            Estimated Boosted APR
          </div>
          <div className="flex flex-col items-center justify-center rounded bg-wombatBrown2 p-2">
            <div className="font-Jamjuree text-2xl font-bold text-wombatCream">
              {estimatedBoostedAPR_UI}
            </div>
          </div>
        </div>
      </div>
      <Modal
        onClose={() => {
          setIsOpenTooltipMobile(false)
        }}
        isOpen={isOpenTooltipMobile}
        isShowBackButton={false}
      >
        <div className="flex items-center justify-center">
          <Image alt={''} src={WarningIcon} width={32} height={32} />
        </div>
        <div className="mt-4 flex flex-col items-center justify-center px-5 text-xs text-wombatPurple">
          <div className="text-center">
            This calculator is for information and reference purposes ONLY.
          </div>

          <div className="mt-3 text-center">
            Rewards are calculated weekly and may change from time to time, and the rates presented
            above are not guaranteed for the duration of the above term.
          </div>
        </div>
      </Modal>
    </Modal>
  )
}

export default BoostCalculatorModal

import { SupportedChainId } from '../web3/supportedChainId'
import { Contract } from './Contract'
import { ABNBC_ABI, BNBX_STAKE_MANAGER_ABI, PSTAKED_POOL_ABI } from './abis/stakedBNB'
import { IContractConstructor } from '../../interfaces/contract'
// This contract for getting exchangeRate for price fetching
export class PStakePool extends Contract<typeof PSTAKED_POOL_ABI> {
  constructor({ address, chainId }: IContractConstructor) {
    super({ address, chainId, abi: PSTAKED_POOL_ABI })
  }
}

export class BnbXStakeManager extends Contract<typeof BNBX_STAKE_MANAGER_ABI> {
  constructor({ address, chainId }: IContractConstructor) {
    super({ address, chainId, abi: BNBX_STAKE_MANAGER_ABI })
  }
}

export class aBNBcToken extends Contract<typeof ABNBC_ABI> {
  constructor({ address, chainId }: IContractConstructor) {
    super({ address, chainId, abi: ABNBC_ABI })
  }
}

export const PSTAKE_POOLS: { [id in SupportedChainId]?: PStakePool } = {
  [SupportedChainId.BSC_MAINNET]: new PStakePool({
    address: '0xC228CefDF841dEfDbD5B3a18dFD414cC0dbfa0D8',
    chainId: SupportedChainId.BSC_MAINNET,
  }),
  [SupportedChainId.BSC_TESTNET]: new PStakePool({
    address: '0x7CdFba1Ee6A8D1e688B4B34A56b62287ce400802',
    chainId: SupportedChainId.BSC_TESTNET,
  }),
}
export const BNBX_STAKE_MANAGERS: { [id in SupportedChainId]?: BnbXStakeManager } = {
  [SupportedChainId.BSC_MAINNET]: new BnbXStakeManager({
    address: '0x7276241a669489E4BBB76f63d2A43Bfe63080F2F',
    chainId: SupportedChainId.BSC_MAINNET,
  }),
  [SupportedChainId.BSC_TESTNET]: new BnbXStakeManager({
    address: '0xDAdcae6bF110c0e70E5624bCdcCBe206f92A2Df9',
    chainId: SupportedChainId.BSC_TESTNET,
  }),
}

export const ABNBCS: { [id in SupportedChainId]?: aBNBcToken } = {
  [SupportedChainId.BSC_MAINNET]: new aBNBcToken({
    address: '0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A',
    chainId: SupportedChainId.BSC_MAINNET,
  }),
  [SupportedChainId.BSC_TESTNET]: new aBNBcToken({
    address: '0x46dE2FBAf41499f298457cD2d9288df4Eb1452Ab',
    chainId: SupportedChainId.BSC_TESTNET,
  }),
}

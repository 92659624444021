import { useCallback, useState } from 'react'
import { crossChainSwap } from '../../utils/crossChainSwap'
import { useSwapContext } from '../../context/SwapContext'
import { useSwapInputContext } from '../../context/SwapInputContext'
import { HexString } from '../../interfaces/contract'
import { strToWad } from '@hailstonelabs/big-number-utils'
import { CROSS_CHAIN_SWAP_GAS_LIMIT, DELAY_TIME_ANIMATION_GIF } from '../../constants/common'
import { ModalId } from '../../interfaces/modal'
import { useModalContext } from '../../context/ModalContext'
import { InputTxData, useCrossChainSwapTx } from '../crossChainSwap/useCrossChainSwapTx'
import { useWeb3 } from '../../context/Web3Context'
import {
  CROSS_CHAIN_SWAP_ERROR_CODE,
  ErrorMessages,
  PROVIDER_ERROR_CODES,
  PROVIDER_ERROR_REASON,
} from '../../context/errorMessage'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import { useTxnReceipt } from '../../context/TxnReceiptProvider'
import { useAppDispatch } from '../../store/hooks'
import { addSuccessToast, addErrorToast } from '../../store/Toast/actions'

export const useCrossChainSwap = () => {
  const {
    fromTokenAmount,
    toTokenAmount,
    fromTokenSymbol,
    toTokenSymbol,
    targetChainId,
    sourceChainId,
    estimatedDeliveryFee,
    minimumCreditReceive,
    minimumReceive,
    receiverValue,
  } = useSwapContext()

  const { recipientAddress, resetAllInputAmount } = useSwapInputContext()
  const { appendCrossChainSwapTx } = useCrossChainSwapTx()
  const { account } = useWeb3()
  const [swapTransactionHash, setSwapTransactionHash] = useState<string>('')
  const { refreshTxnReceipt } = useTxnReceipt()

  const {
    actions: { openModal, closeModal },
  } = useModalContext()
  const dispatch = useAppDispatch()

  const handleCrossChainSwap = useCallback(async () => {
    try {
      openModal({ currentModalId: ModalId.SWAP_WAIT_FOR_CONFIRMATION })
      if (!estimatedDeliveryFee) throw new Error('Cannot estimate delivery fee for crosschain swap')
      if (!account) throw new Error('No wallet connected')
      const txn = await crossChainSwap({
        fromTokenSymbol,
        toTokenSymbol,
        toChainId: targetChainId,
        fromChainId: sourceChainId,
        fromAmount: fromTokenAmount,
        minimumCreditAmount: minimumCreditReceive || '0',
        minimumToAmount: minimumReceive || '0',
        recipient: recipientAddress as HexString,
        receiverValue,
        deliveryFeeWad: strToWad(estimatedDeliveryFee),
        gasLimit: CROSS_CHAIN_SWAP_GAS_LIMIT,
      })
      if (txn) {
        openModal({
          currentModalId: ModalId.SWAP_WAIT_FOR_CONFIRMATION,
          payload: { isTxnPending: true },
        })
        setSwapTransactionHash(txn.hash)
        setTimeout(() => {
          openModal({
            currentModalId: ModalId.SWAP_WAIT_FOR_CONFIRMATION,
            payload: { isTxnPending: false },
          })
          openModal({
            currentModalId: ModalId.SWAP_SUBMITTED,
            payload: { transactionHashes: [txn.hash] },
          })
        }, DELAY_TIME_ANIMATION_GIF)
      }
      const receiptTxn = await txn.wait()
      if (receiptTxn) {
        dispatch(
          addSuccessToast({
            message: `+${formatNumberUSLocale(toTokenAmount)} ${toTokenSymbol}`,
            title: 'Swap Completed',
            txHash: txn.hash,
          })
        )
        refreshTxnReceipt()
        resetAllInputAmount()
        appendCrossChainSwapTx({
          fromChainId: sourceChainId,
          toChainId: targetChainId,
          fromTokenSymbol,
          toTokenSymbol,
          fromTokenAmount,
          toTokenAmount,
          fromTxhash: receiptTxn.transactionHash,
          userAddress: account,
          receiverValue,
          isCreditToToken: false,
        } as InputTxData)
      }
    } catch (error) {
      const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
      /**@todo decode other errors for cross chain swap */

      if (JSON.stringify(error).includes(code)) {
        closeModal()
        const errorMessage = ErrorMessages[code]
        dispatch(
          addErrorToast({
            message: errorMessage.message,
            title: errorMessage.title,
          })
        )
      } else {
        if (JSON.stringify(error).includes(PROVIDER_ERROR_REASON.INSUFFICIENT_FUND_FOR_GAS)) {
          dispatch(
            addErrorToast({
              message: '',
              title: 'Insufficient Gas',
              txHash: swapTransactionHash,
            })
          )
        } else if (
          JSON.stringify(error).includes(CROSS_CHAIN_SWAP_ERROR_CODE.WOMBAT_AMOUNT_TOO_LOW)
        ) {
          dispatch(
            addErrorToast({
              message: '',
              title: 'Slippage too high. Please retry or adjust the slippage settings.',
              txHash: swapTransactionHash,
            })
          )
        } else {
          dispatch(
            addErrorToast({
              message: `${formatNumberUSLocale(toTokenAmount)} ${toTokenSymbol}`,
              title: 'Swap Failed',
              txHash: swapTransactionHash,
            })
          )
        }
        const errInfo =
          `@crossChainSwap\n` +
          `fromTokenSymbol: ${fromTokenSymbol.toString()}\n` +
          `toChainId: ${targetChainId.toString()}\n` +
          `fromChainId: ${sourceChainId.toString()}\n` +
          `minimumCreditAmount: ${minimumCreditReceive || '0'.toString()}\n` +
          `minimumToAmount: ${minimumReceive || '0'.toString()}\n` +
          `minimumToAmount: ${minimumReceive || '0'.toString()}\n` +
          `recipient: ${recipientAddress.toString()}\n` +
          `estimatedDeliveryFee: ${estimatedDeliveryFee || '0'.toString()}\n` +
          `gasLimit: ${CROSS_CHAIN_SWAP_GAS_LIMIT.toString()}\n`
        console.error(errInfo, error)
        openModal({ currentModalId: ModalId.SWAP_FAILED })
      }
    }
  }, [
    account,
    appendCrossChainSwapTx,
    closeModal,
    dispatch,
    estimatedDeliveryFee,
    fromTokenAmount,
    fromTokenSymbol,
    minimumCreditReceive,
    minimumReceive,
    openModal,
    receiverValue,
    recipientAddress,
    refreshTxnReceipt,
    resetAllInputAmount,
    sourceChainId,
    swapTransactionHash,
    targetChainId,
    toTokenAmount,
    toTokenSymbol,
  ])

  return {
    handleCrossChainSwap,
  }
}

import { createSelector } from 'reselect'
import { RootState } from '../store'

export const selectTokenData = (state: RootState) => state.multicallData.tokenData

export const selectVotingData = (state: RootState) => state.multicallData.votingData

export const selectPoolData = (state: RootState) => state.multicallData.poolData

export const selectMasterWombatData = (state: RootState) => state.multicallData.masterWombatData

export const selectVewomData = (state: RootState) => state.multicallData.vewomData

export const selectAssetData = (state: RootState) => state.multicallData.assetData

export const selectBribeConfigData = (state: RootState) => state.multicallData.bribeConfigData

export const selectUniswapV3PoolData = (state: RootState) => state.multicallData.uniswapV3PoolData

export const selectBribeInfoData = (state: RootState) => state.multicallData.bribeInfoData

export const selectPoolRewarderData = (state: RootState) => state.multicallData.poolRewarderData

export const selectBoostedPoolRewarderData = (state: RootState) =>
  state.multicallData.boostedPoolRewarderData

export const selectMulticallData = (state: RootState) => state.multicallData

export const selectMasterWombatWithAccountRewards = createSelector(
  [selectMasterWombatData],
  (masterWombatData) => {
    return masterWombatData?.withAccount?.poolRewards
  }
)

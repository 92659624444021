export default function commafy(num: number | string) {
  const str = num.toString().split('.')
  if (str[0].length >= 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, '$1')
  }
  return str.join('.')
}

/**
 * Truncate decimals and commafy.
 * However, if the number is less than 100, we show 2 decimals
 */
export function commafyWithDecimals(num: number): string {
  if (num >= 100 || num <= -100) {
    return commafy(Math.floor(num))
  } else {
    return commafy(num.toFixed(2))
  }
}

/**
 * Truncate decimals, commafy and return a percentage string
 * However, if the number is less than 100%, we show 2 decimals
 */
export function commafyPercentage(num: number): string {
  if (num >= 1 || num <= -1) {
    return commafy(Math.floor(num * 100)) + '%'
  } else {
    return commafy((num * 100).toFixed(2)) + '%'
  }
}

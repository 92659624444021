import Image from 'next/image'
import { useRef } from 'react'
import useDidClickOutside from '../../hooks/useDidClickOutside'
import CloseIcon from '../../public/assets/icons/button-close.svg'

type Props = {
  isOpen: boolean
  onClose: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}

export default function TooltipMobileFullScreen({ isOpen, onClose, children }: Props) {
  const wrapperRef = useRef(null)
  useDidClickOutside([wrapperRef], () => {
    onClose()
  })

  return isOpen === true ? (
    <>
      <div className="fixed top-0 left-0 bottom-0 z-20 block w-full bg-white px-8 py-8 md:hidden">
        <div className="absolute top-0 left-0 bottom-0 z-10 h-full w-full space-y-4 bg-wombatBackgroundMobile bg-cover bg-center bg-no-repeat px-8 py-8 ">
          <div className="mb-6 flex items-center justify-end" onClick={onClose}>
            <Image src={CloseIcon} width={12} height={12} />
          </div>

          <div>{children}</div>
        </div>
      </div>
    </>
  ) : (
    <></>
  )
}

import { utils } from 'ethers'
import Image from 'next/image'
import { useCallback, useMemo, useState } from 'react'
import { useWeb3 } from '../../context/Web3Context'
import { HandleUnLockState } from '../../hooks/useHandleUnlock'
import { useScrollToTop } from '../../hooks/useScroll'
import { BreedingStructOutput } from '../../interfaces/contract'
import arrowDownIcon from '../../public/assets/icons/arrow-down.svg'
import arrowLeftIcon from '../../public/assets/icons/arrow-left-thin.svg'
import arrowRightIcon from '../../public/assets/icons/arrow-right-thin.svg'
import arrowUpIcon from '../../public/assets/icons/arrow-up-fill.svg'
import calendarBrownIcon from '../../public/assets/icons/calendar-brown.svg'
import sortIcon from '../../public/assets/icons/sort-icon.svg'
import veWOMicon from '../../public/assets/icons/veWOM-boost.svg'
import WOMicon from '../../public/assets/tokens/WOM.svg'
import { getPeriodDuration, timeConverter, timeSince } from '../../utils'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import { getLockDay } from '../../utils/veWom'
import BoostConfirmationModal from '../BoostConfirmationModal'
import BoostUnlockModal from '../BoostUnlockModal'
import ExtendLockModal from '../ExtendLockModal'
import TransactionFailedModal from '../TransactionFailedModal'
import TransactionSubmittedModal from '../TransactionSubmittedModal'
import WButton, { Variant } from '../WButton'
import SelectOption from './components/SelectOption'

type Props = {
  breedings: BreedingStructOutput | null
  tryHandleUnLock: (slot: number | null, unLockBalance: string) => Promise<void>
  unLockState: HandleUnLockState
  totalLocked: string | undefined
  totalEarning: string | undefined
  txnHashUnlock: string
  isNewTxnUnlock: boolean
}

type Breeding =
  | {
      ID: number
      WOMBalance: string
      veWOMBalance: string
      LockPeriod: string
      countDown: string
      isLock: boolean
      periodDuration: string
      progressPercent: number
      unlockTime: number
    }[]
  | undefined

const PositionTable = ({
  breedings,
  tryHandleUnLock,
  unLockState,
  totalLocked,
  totalEarning,
  isNewTxnUnlock,
  txnHashUnlock,
}: Props) => {
  const { chainId, account } = useWeb3()

  //@todo refactor: put the below state to context
  const [isExpandSelectPages, setIsExpandSelectPages] = useState(false)
  const [isExpandSelectRows, setIsExpandSelectRows] = useState(false)
  const [isExpandSelectSort, setIsExpandSelectSort] = useState(false)
  const [isOpenUnlockModal, setIsOpenUnlockModal] = useState(false)
  const [isOpenExtendLockModal, setIsOpenExtendLockModal] = useState(false)
  const [isShowActionModal, setIsShowActionModal] = useState(false)
  const [isExpandPagesFirstTime, setIsExpandPagesFirstTime] = useState(false)
  const [isExpandRowsFirstTime, setIsExpandRowsFirstTime] = useState(false)
  const [isExpandSortByFirstTime, setIsExpandSortByFirstTime] = useState(false)
  const [selectPoolId, setSelectPoolId] = useState<number | null>(null)
  const [unlockBalance, setUnlockBalance] = useState('')
  const [unlockTime, setUnlockTime] = useState<number>(0)
  const [veVomBalance, setVeVomBalance] = useState('')
  const [lockPeriod, setLockPeriod] = useState('')
  const [lockDay, setLockDay] = useState<number>(0)

  const listPage = []
  const listRow = ['5', '10', '100', 'All']

  const listTitle = useMemo(
    () => ['ID', 'Locked', 'Balance', 'Unlock on', 'Unlock in', 'Extend'],
    []
  )
  const [isSortByASC, setIsSetSortByASC] = useState(true)
  const [sortBy, setSortBy] = useState(listTitle[0])
  const [rowPerPage, setRowPerPage] = useState(listRow[0])
  const [currentPage, setCurrentPage] = useState('1')
  const { scrollToTop } = useScrollToTop()

  const renderModal = () => {
    switch (unLockState) {
      case HandleUnLockState.LOADING:
        return (
          <BoostConfirmationModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
            txHash={isNewTxnUnlock}
            data={unlockBalance}
            textAboveBalance="UNLOCK WOM"
          />
        )

      case HandleUnLockState.SUBMITED:
        return (
          <TransactionSubmittedModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
            hash={txnHashUnlock}
            isAddTokenToWallet={false}
            chainId={chainId}
          />
        )

      case HandleUnLockState.FAILED:
        return (
          <TransactionFailedModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
          />
        )
      default:
        return null
    }
  }

  const data = breedings?.map((breeding, i) => {
    const WOMBalance = Number(utils.formatUnits(breeding.womAmount, 18))
    const veWOMBalance = Number(utils.formatUnits(breeding.veWomAmount, 18))
    const LockPeriod = timeConverter(breeding.unlockTime)
    const countDown = timeSince(breeding.unlockTime).countDown
    const isLock = timeSince(breeding.unlockTime).isLock
    const lockupDay = getLockDay(veWOMBalance, WOMBalance)
    const { periodDuration, progressPercent } = getPeriodDuration(breeding.unlockTime, lockupDay)

    return {
      ID: i,
      WOMBalance: WOMBalance.toFixed(2),
      veWOMBalance: veWOMBalance.toFixed(2),
      LockPeriod,
      countDown,
      isLock,
      periodDuration,
      progressPercent,
      unlockTime: breeding.unlockTime,
      lockupDay,
    }
  })

  let totalPages = 1
  if (rowPerPage !== listRow[listRow.length - 1] && data) {
    totalPages = Math.ceil(data?.length / Number(rowPerPage))
  }
  if (totalPages) {
    for (let i = 1; i <= totalPages; i++) {
      listPage.push(i.toString())
    }
  }

  const sortedList = useCallback(
    (sortBy: string, array: Breeding) => {
      switch (sortBy) {
        case listTitle[0]:
          return array?.sort((item1, item2) =>
            isSortByASC ? Number(item1?.ID) - item2?.ID : Number(item2?.ID) - item1?.ID
          )
        case listTitle[1]:
          return array?.sort((item1, item2) =>
            isSortByASC
              ? Number(item1?.WOMBalance) - Number(item2?.WOMBalance)
              : Number(item2?.WOMBalance) - Number(item1?.WOMBalance)
          )
        case listTitle[2]:
          return array?.sort((item1, item2) =>
            isSortByASC
              ? Number(item1?.veWOMBalance) - Number(item2?.veWOMBalance)
              : Number(item2?.veWOMBalance) - Number(item1?.veWOMBalance)
          )
        case listTitle[3]:
        case listTitle[4]:
          return array?.sort((item1, item2) =>
            isSortByASC
              ? Number(item1?.unlockTime) - item2?.unlockTime
              : Number(item2?.unlockTime) - item1?.unlockTime
          )
        default:
          return null
      }
    },
    [isSortByASC, listTitle]
  )

  let startItemIndex = 1
  let endItemIndex = 1
  if (data) {
    if (rowPerPage === listRow[listRow.length - 1]) {
      startItemIndex = Number(100000) * (Number(currentPage) - 1)
      endItemIndex =
        Number(100000) * Number(currentPage) > data?.length
          ? data?.length
          : Number(100000) * Number(currentPage) - 1
    } else {
      startItemIndex = Number(rowPerPage) * (Number(currentPage) - 1)
      endItemIndex =
        Number(rowPerPage) * Number(currentPage) > data?.length
          ? data?.length
          : Number(rowPerPage) * Number(currentPage) - 1
    }
  }

  const renderPositionTable = useMemo(() => {
    if (Number(currentPage) > totalPages) {
      setCurrentPage(totalPages.toString())
    }
    const dataPerPage = data?.filter((item) => item.ID >= startItemIndex && item.ID <= endItemIndex)
    sortedList(sortBy, dataPerPage)
    return (
      data &&
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dataPerPage?.map((item, i) => {
        const renderProgress = () => {
          if (item.isLock) {
            return (
              <div
                style={{
                  width: `${item.progressPercent}%`,
                }}
                className="absolute bottom-0 left-0 h-2 rounded bg-wombatPurple1"
              />
            )
          }
        }
        return (
          <div
            className={`${
              !item.isLock ? 'bg-wombatBrown2 bg-opacity-50' : 'bg-wombatYellow3 text-wombatBrown'
            }  relative mt-2 grid grid-cols-12 rounded-lg p-2 md:h-12 md:grid-cols-6 md:rounded md:p-0`}
            key={item.ID}
          >
            <div className="col-span-2 flex items-center md:col-span-1 md:ml-5">#{item.ID + 1}</div>
            <div className="hidden items-center justify-center md:flex">
              <Image src={WOMicon} width={18} height={18} />
              <div className="ml-1">{formatNumberUSLocale(item.WOMBalance)} WOM</div>
            </div>
            <div className="hidden items-center justify-center md:flex">
              <Image src={veWOMicon} width={18} height={18} />
              <div className="ml-1">{formatNumberUSLocale(item.veWOMBalance)} veWOM</div>
            </div>
            <div className="group hidden cursor-default items-center justify-center md:flex">
              <p className="hover-text">{item.LockPeriod}</p>
              <div className="absolute bottom-0 right-0 z-10 hidden bg-wombatPurple3 px-1 py-[2px] text-wombatBrown group-hover:block">
                {item.periodDuration}
              </div>
            </div>
            <div className="col-span-7 -ml-4 flex flex-col text-xs md:-ml-0 md:hidden">
              <div className="flex w-full items-center py-1">
                <div className="flex items-center">
                  <Image src={WOMicon} width={16} height={16} />
                </div>
                <div className="ml-1">{item.WOMBalance} WOM</div>
              </div>
              <div className="ml-[1px] flex w-full items-center py-1">
                <div className="flex items-center">
                  <Image src={veWOMicon} width={13} height={13} />
                </div>
                <div className="ml-1">{item.veWOMBalance} veWOM</div>
              </div>
              <div className="flex w-full items-center py-1">
                <div className="flex items-center">
                  <Image src={calendarBrownIcon} width={15} height={15} />
                </div>
                <div className="ml-1">{item.periodDuration}</div>
              </div>
            </div>

            <div className="col-span-3 flex items-center justify-center md:col-span-1">
              {!item.isLock ? (
                <div className=" mr-4 flex h-full items-center">
                  <WButton
                    variant={Variant.GRADIENT}
                    width="w-24"
                    height="h-5"
                    onClick={() => {
                      setIsOpenUnlockModal(true)
                      setSelectPoolId(item.ID)
                      setUnlockBalance(item.WOMBalance)

                      scrollToTop()
                    }}
                  >
                    <div className="flex items-center">
                      <div className="mr-1 font-Jamjuree text-sm font-bold">UNLOCK</div>
                      <div>
                        <Image src={WOMicon} width={15} height={15} />
                      </div>
                    </div>
                  </WButton>
                </div>
              ) : (
                <div className="flex items-center">{item.countDown}</div>
              )}
            </div>
            <div className=" mr-4 flex h-full items-center">
              <WButton
                variant={Variant.GRADIENT}
                width="w-24"
                height="h-5"
                onClick={() => {
                  setIsOpenExtendLockModal(true)
                  setSelectPoolId(item.ID)
                  setUnlockTime(item.unlockTime)
                  setUnlockBalance(item.WOMBalance)
                  setLockPeriod(item.LockPeriod)
                  setVeVomBalance(item.veWOMBalance)
                  setLockDay(item.lockupDay)
                  scrollToTop()
                }}
              >
                <div className="flex items-center">
                  <div className="mr-1 font-Jamjuree text-sm font-bold">EXTEND</div>
                </div>
              </WButton>
            </div>
            {renderProgress()}
          </div>
        )
      })
    )
  }, [currentPage, data, endItemIndex, scrollToTop, sortBy, sortedList, startItemIndex, totalPages])

  return (
    <div className="mb-20 w-full px-4 text-xs text-wombatBrown md:mb-0 md:px-0">
      <div className="flex items-center justify-between md:justify-center">
        <div className="flex items-center">
          <div className="mr-2">
            <Image src={WOMicon} width={27} height={27} />
          </div>
          <div className="font-Work">
            <div className="text-xs font-normal">Total Locked</div>
            <div className="text-base font-semibold">
              {account && totalLocked ? `${formatNumberUSLocale(totalLocked)} WOM` : '-'}
            </div>
          </div>
        </div>
        <div className="flex items-center md:ml-20">
          <div className="mr-2">
            <Image src={veWOMicon} width={27} height={27} />
          </div>
          <div>
            <div className="text-xs font-normal">Total Balance</div>
            <div className="text-base font-semibold">
              {account && totalEarning ? `${formatNumberUSLocale(totalEarning)} veWOM` : '-'}
            </div>
          </div>
        </div>
      </div>
      {/* table */}
      <div>
        {/* td */}
        <div className="mt-6 hidden select-none md:block">
          <div className="grid h-6 grid-cols-6 rounded bg-wombatBrown1 text-wombatCream">
            {listTitle.map((title, i) => (
              <div
                className={`flex items-center ${
                  i === 0 ? 'ml-4' : 'justify-center'
                } text-wombatCream`}
                key={title}
              >
                <button
                  className="flex"
                  onClick={() => {
                    setIsSetSortByASC(!isSortByASC)
                    setSortBy(title)
                  }}
                >
                  <div className="flex items-center">
                    <Image src={sortIcon} width={13} height={13} />
                  </div>
                  <div>{title}</div>
                </button>
              </div>
            ))}
          </div>
        </div>
        {/* tr */}
        <div className="mt-6 mb-4 flex items-center justify-between text-xs md:hidden">
          <div
            className={`rounded border-2 p-1 ${
              isExpandSelectPages ? 'rounded-b-none border-b-0 pb-[6px]' : ''
            } relative flex  items-center justify-between border-wombatPurple2 bg-wombatCream p-1`}
            onClick={() => {
              setIsExpandSelectPages(!isExpandSelectPages)
              setIsExpandSelectRows(false)
              setIsExpandSelectSort(false)
            }}
          >
            <div>{isExpandPagesFirstTime ? currentPage : 'Page'}</div>
            <div className="ml-2">
              <Image src={arrowDownIcon} width={9} height={5} />
            </div>
            {isExpandSelectPages && (
              <SelectOption
                onSelect={(page) => {
                  setCurrentPage(page)
                  setIsExpandPagesFirstTime(true)
                }}
                listItem={listPage}
              />
            )}
          </div>
          <div className="flex">
            <div
              className={`rounded border-2 p-1 ${
                isExpandSelectRows ? 'rounded-b-none border-b-0 pb-[6px]' : ''
              } relative flex  items-center justify-between border-wombatPurple2 bg-wombatCream p-1`}
              onClick={() => {
                setIsExpandSelectRows(!isExpandSelectRows)
                setIsExpandSelectPages(false)
                setIsExpandSelectSort(false)
              }}
            >
              <div>{isExpandRowsFirstTime ? rowPerPage : 'Rows'}</div>
              <div className="ml-2">
                <Image src={arrowDownIcon} width={9} height={5} />
              </div>
              {isExpandSelectRows && (
                <SelectOption
                  onSelect={(row) => {
                    setRowPerPage(row)
                    setIsExpandRowsFirstTime(true)
                  }}
                  listItem={listRow}
                />
              )}
            </div>
            <div
              className={`rounded border-2 p-1 ${
                isExpandSelectSort ? 'rounded-b-none border-b-0 pb-[6px]' : ''
              } relative ml-1  flex min-w-[108px] items-center justify-between border-wombatPurple2 bg-wombatCream p-1`}
              onClick={() => {
                setIsExpandSelectSort(!isExpandSelectSort)
                setIsExpandSelectRows(false)
                setIsExpandSelectPages(false)
              }}
            >
              <div>{isExpandSortByFirstTime ? sortBy : 'Sort By'}</div>
              <div className="ml-1">
                <Image src={arrowDownIcon} width={9} height={5} />
              </div>
              {isExpandSelectSort && (
                <SelectOption
                  onSelect={(sortBy) => {
                    setSortBy(sortBy)
                    setIsSetSortByASC(true)
                    setIsExpandSortByFirstTime(true)
                  }}
                  listItem={listTitle}
                />
              )}
            </div>
          </div>
        </div>

        {renderPositionTable}

        <div className="hidden select-none items-center justify-between pt-11 text-xs text-wombatBrown md:flex">
          <div className="flex w-[170px] items-center justify-between">
            <div>Rows per page:</div>
            <div
              className={`rounded border-2 p-1 ${
                isExpandSelectRows ? 'rounded-t-none border-t-0 pt-[6px]' : ''
              } relative ml-1  flex cursor-pointer items-center justify-between border-wombatPurple2 bg-wombatCream p-1 px-2`}
              onClick={() => {
                setIsExpandSelectRows(!isExpandSelectRows)
              }}
            >
              <div className="flex items-center justify-center pr-3">{rowPerPage}</div>
              <div className="flex items-center">
                <Image src={arrowUpIcon} width={9} height={5} />
              </div>

              {isExpandSelectRows && (
                <SelectOption
                  onSelect={(row: string) => {
                    setRowPerPage(row)
                  }}
                  listItem={listRow}
                />
              )}
            </div>
          </div>
          <div className="place-items-stretch">
            {startItemIndex + 1} -{' '}
            {data && endItemIndex + 1 > data?.length ? data?.length : endItemIndex + 1} of{' '}
            {data?.length}
          </div>
          <div className="flex w-[160px] items-center justify-end">
            <div className="flex items-center">
              <div
                className={`${
                  currentPage == '1' ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'
                } flex items-center`}
                onClick={() => {
                  setCurrentPage((Number(currentPage) - 1).toString())
                }}
              >
                <Image src={arrowLeftIcon} height={12} width={7} />
              </div>
              <div
                className={`rounded border-2 p-1 ${
                  isExpandSelectPages ? 'rounded-t-none border-t-0 pt-[6px]' : ''
                } relative mx-3  flex cursor-pointer items-center justify-between border-wombatPurple2 bg-wombatCream p-1 px-2 ${
                  rowPerPage === listRow[listRow.length - 1]
                    ? 'pointer-events-none cursor-not-allowed'
                    : ''
                }`}
                onClick={() => {
                  setIsExpandSelectPages(!isExpandSelectPages)
                }}
              >
                <div className="flex items-center justify-center pr-3">{currentPage}</div>
                <div className="flex items-center">
                  <Image src={arrowUpIcon} width={9} height={5} />
                </div>

                {isExpandSelectPages && (
                  <SelectOption
                    onSelect={(page: string) => {
                      setCurrentPage(page)
                    }}
                    listItem={listPage}
                  />
                )}
              </div>
              <div
                className={`${
                  Number(currentPage) === totalPages
                    ? 'pointer-events-none cursor-not-allowed'
                    : 'cursor-pointer'
                } flex items-center`}
              >
                <Image
                  src={arrowRightIcon}
                  height={12}
                  width={7}
                  onClick={() => {
                    setCurrentPage((Number(currentPage) + 1).toString())
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BoostUnlockModal
        open={isOpenUnlockModal}
        onClose={() => {
          setIsOpenUnlockModal(false)
        }}
        tryHandleUnLock={() => {
          tryHandleUnLock(selectPoolId, unlockBalance)
          setIsShowActionModal(true)
        }}
      />
      <ExtendLockModal
        open={isOpenExtendLockModal}
        setIsOpenExtendLockModal={setIsOpenExtendLockModal}
        originalUnlockTime={unlockTime}
        originalLockedWom={unlockBalance}
        originalVeWomBalance={veVomBalance}
        originalLockPeriod={lockPeriod}
        originalLockDay={lockDay}
        slot={selectPoolId}
      />
      {isShowActionModal && renderModal()}
    </div>
  )
}

export default PositionTable

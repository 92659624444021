/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useState } from 'react'
import { routes, RouteType } from '../constants/routes'

interface IActiveTabContext {
  activeTab: RouteType
  updateActiveTab: (tab: RouteType) => void
}

type Props = {
  children: ReactNode
}

export const ActiveTabContext = createContext<IActiveTabContext>({
  activeTab: routes.SWAP,
  updateActiveTab: () => {},
})

const ActiveTab = ({ children }: Props) => {
  const [activeTab, setActiveTab] = useState<RouteType>(routes.SWAP)

  const updateActiveTab = (tab: RouteType) => {
    setActiveTab(tab)
  }

  return (
    <ActiveTabContext.Provider value={{ activeTab, updateActiveTab }}>
      {children}
    </ActiveTabContext.Provider>
  )
}

export default ActiveTab

import {
  getEmitterAddressEth,
  getSignedVAAWithRetry,
  parseSequenceFromLogEth,
} from '@certusone/wormhole-sdk'
import { ethers, utils } from 'ethers'
import { useCallback, useState } from 'react'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { chainInfos } from '../../constants/web3/chains'
import { useAppDispatch } from '../../store/hooks'
import { addInfoToast, addSuccessToast, addErrorToast } from '../../store/Toast/actions'

function useSignedVaa() {
  const [signedVaa, setSignedVaa] = useState<Uint8Array | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const updateSignedVaa = useCallback(
    async (sourceChainId: SupportedChainId, receipt: ethers.providers.TransactionReceipt) => {
      try {
        setIsLoading(true)
        dispatch(addInfoToast({ message: 'Fetching signed VAA...' }))
        const chainInfo = chainInfos[sourceChainId]
        const coreBridgeAddress = chainInfo.wormholeConfig?.coreBridgeAddress
        const tokenBridgeAddress = chainInfo.wormholeConfig?.tokenBridgeAddress
        const wormholeChainId = chainInfo.wormholeConfig?.chainId
        if (!(coreBridgeAddress && tokenBridgeAddress && wormholeChainId))
          throw new Error(`Wormhole chain id is not found in ${chainInfo.name}.`)
        const coreBridgeCheckSumAddress = utils.getAddress(coreBridgeAddress)
        const tokenBridgeCheckSumAddress = utils.getAddress(tokenBridgeAddress)

        // Get the sequence number and emitter address required to fetch the signedVaa of our message
        const sequence = parseSequenceFromLogEth(receipt, coreBridgeCheckSumAddress)
        const emitterAddress = getEmitterAddressEth(tokenBridgeCheckSumAddress)
        const { vaaBytes } = await getSignedVAAWithRetry(
          [
            'https://wormhole-v2-mainnet-api.certus.one',
            'https://wormhole.inotel.ro',
            'https://wormhole-v2-mainnet-api.mcf.rocks',
            'https://wormhole-v2-mainnet-api.chainlayer.network',
            'https://wormhole-v2-mainnet-api.staking.fund',
            'https://wormhole-v2-mainnet.01node.com',
          ],
          wormholeChainId,
          emitterAddress,
          sequence
        )
        dispatch(
          addSuccessToast({
            message: 'Fetched signed VAA.',
          })
        )
        setSignedVaa(vaaBytes)
        return vaaBytes
      } catch (err) {
        /** @todo update messages later */
        console.log(err)
        const errorMessage = (err as Error).message
        dispatch(
          addErrorToast({
            message: 'Your transaction is still in progress. Please try later.',
            title: errorMessage || 'Requested VAA not found in store',
          })
        )
        setSignedVaa(null)
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch]
  )
  return { signedVaa, updateSignedVaa, isLoading }
}

export default useSignedVaa

import { isParsableString, strToWad } from '@hailstonelabs/big-number-utils'
import { useEffect, useMemo, useState, useCallback, Dispatch, SetStateAction } from 'react'
import { getClaimDate, getLockPositionMultiplier, timeConverter } from '../../utils'
import Modal from '../Modal'
import ModalInfoBox from '../ModalInfoBox'
import Slider from '../Slider/Slider'
import WButton, { Variant } from '../WButton'
import { getExpectedVeWom } from '../../utils/veWom'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import useHandleExtendLock, { HandleExtendLockState } from '../../hooks/useHandleExtendLock'
import clsx from 'clsx'
import BoostConfirmationModal from '../BoostConfirmationModal'
import FailedModal from '../TransactionFailedModal'
import SubmittedModal from '../TransactionSubmittedModal'
import { useWeb3 } from '../../context/Web3Context'

interface Props {
  open: boolean
  originalUnlockTime: number
  originalLockedWom: string
  originalVeWomBalance: string
  originalLockPeriod: string
  originalLockDay: number
  slot: number | null
  setIsOpenExtendLockModal: Dispatch<SetStateAction<boolean>>
}

const maxDays = 1461
const minDays = 7
const dayPerPercent = maxDays / 100
const listDay = [
  {
    '1 year': Math.round(maxDays / 4) as number,
  },
  {
    '2 years': Math.round(maxDays / 2) as number,
  },
  {
    '3 years': Math.round((maxDays / 4) * 3) as number,
  },
  {
    '4 years': maxDays as number,
  },
]

const ExtendLockModal = ({
  open,
  originalUnlockTime,
  originalLockedWom,
  originalVeWomBalance,
  originalLockDay,
  slot,
  setIsOpenExtendLockModal,
}: Props) => {
  const { chainId } = useWeb3()
  const currentTime = new Date().getTime() / 1000

  const [lockDayInputAmount, setLockDayInputAmount] = useState<string>('')
  const [sliderValue, setSliderValue] = useState(0)

  const [isShowActionModal, setIsShowActionModal] = useState(false)

  const { tryHandleExtendLock, extendLockState, isNewTxn, txnHash } = useHandleExtendLock(
    slot,
    lockDayInputAmount
  )

  const newLockDayLtMinDay = Number(lockDayInputAmount) < minDays

  const newLockDayGtMaxDay = Number(lockDayInputAmount) > maxDays

  const newLockDayGtOriginLockDay = Number(lockDayInputAmount) >= originalLockDay

  const newVeVomBalance = useMemo(() => {
    return getExpectedVeWom(strToWad(originalLockedWom), Number(lockDayInputAmount)).toString()
  }, [lockDayInputAmount, originalLockedWom])

  const newVeVomBalanceGtOriginalVeWomBalance =
    Number(newVeVomBalance) > Number(originalVeWomBalance)

  const isExtendDisabled =
    newLockDayLtMinDay ||
    newLockDayGtMaxDay ||
    !newLockDayGtOriginLockDay ||
    !newVeVomBalanceGtOriginalVeWomBalance

  const hasLockDayInput = lockDayInputAmount !== ''

  const handleChangeLockPeriod = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value === '') {
      setLockDayInputAmount('')
    }

    // only accept integer for the input
    if (value !== '' && !isParsableString(value, 0, true)) return

    // limit user max input
    if (parseFloat(e.target.value) >= Math.pow(maxDays, 2)) return
    setLockDayInputAmount(value)
    setSliderValue(Number(value) / dayPerPercent)
  }

  const handleCallBackSlider = (val: string) => {
    const value = Number(val)
    setSliderValue(value)
    setLockDayInputAmount(Math.round(Number(value) * dayPerPercent).toString())
  }

  const resetExtendLockInputAmount = useCallback(() => {
    setLockDayInputAmount(originalLockDay.toString())
    setSliderValue(originalLockDay / dayPerPercent)
  }, [originalLockDay])

  const renderDays = () =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listDay?.map((item: any, i) => {
      const listDayItem = item[Object.keys(item)[0]]
      return (
        <button
          key={i}
          disabled={originalLockDay > listDayItem}
          className={`
        ${
          //disabled button when originalLockDay > listDayItem
          originalLockDay > listDayItem
            ? 'opacity-30'
            : 'hover:border-wombatBrown1 hover:bg-wombatPurple1 hover:text-wombatCream'
        }
        ${
          //highlight button when lockDayInputAmount = listDayItem
          listDayItem.toString() == lockDayInputAmount && !(originalLockDay > listDayItem)
            ? 'border-wombatBrown1 bg-wombatPurple1 text-wombatCream'
            : 'border-wombatBrown2'
        } 
        mb-3 flex-1 rounded-lg border-2 py-1 text-center  [&:not(:last-child)]:mr-2`}
          onClick={() => {
            if (originalLockDay > listDayItem) return
            setLockDayInputAmount(Math.round(listDayItem).toString())
            setSliderValue(Math.round(listDayItem / dayPerPercent))
          }}
        >
          {Object.keys(item)[0]}
        </button>
      )
    })

  const renderInfoItem = (originalValue: string, newValue: string) => {
    return (
      <div className="flex">
        <div className="text-[#B7A38E]">{originalValue}</div>
        {hasLockDayInput && (
          <div className="flex">
            <span>&nbsp;{'>'}&nbsp;</span>
            <div className="font-bold text-wombatPurple1">{newValue}</div>
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    resetExtendLockInputAmount()
  }, [resetExtendLockInputAmount])

  const renderWarningMessage = (message: string) => {
    return (
      <div className="flex items-center justify-center">
        <div className="ml-1 text-xs text-wombatRed">{message}</div>
      </div>
    )
  }

  const handleExtendModalOnClose = () => {
    setIsOpenExtendLockModal(false)
    setLockDayInputAmount('')
    setSliderValue(0)
  }

  const renderModal = () => {
    switch (extendLockState) {
      case HandleExtendLockState.LOADING:
        return (
          <BoostConfirmationModal
            isOpen
            onClose={handleExtendModalOnClose}
            txHash={isNewTxn}
            textAboveBalance="Extend Lock"
            newLockDay={lockDayInputAmount}
          />
        )

      case HandleExtendLockState.SUBMITED:
        return (
          <SubmittedModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
            hash={txnHash}
            isAddTokenToWallet={false}
            chainId={chainId}
          />
        )

      case HandleExtendLockState.FAILED:
        return (
          <FailedModal
            isOpen
            onClose={() => {
              setIsShowActionModal(false)
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Modal
        title={<div className="text-lg text-wombatPurple">Extend Lock</div>}
        isOpen={open}
        onClose={handleExtendModalOnClose}
        isCenterTitle
        isShowBackButton={false}
        width={500}
      >
        <div
          className="flex flex-col items-center justify-center"
          style={{ marginTop: 0, marginBottom: '10px' }}
        >
          <span className="mb-4 text-center font-Work text-xs">
            Extend lock period to acquire more veWOM
          </span>
          <ModalInfoBox>
            <div className="flex items-center">
              <div className="flex-1 text-[16px] font-bold">Extend Lock Period</div>
              <div className="flex flex-1 grow items-center justify-between rounded-md border-2  border-wombatPurple1 bg-wombatYellow3/50 p-2 focus-within:border-wombatPurple hover:border-wombatPurple">
                <input
                  className="w-full bg-transparent font-Helvetica text-base font-medium text-wombatBrown1 placeholder-wombatPurple3 focus:outline-none"
                  inputMode="numeric"
                  placeholder={originalLockDay.toString()}
                  onChange={handleChangeLockPeriod}
                  value={lockDayInputAmount}
                />
                <div className="select-none font-medium text-wombatPurple1">days</div>
              </div>
            </div>
            <Slider value={sliderValue.toString()} callBack={handleCallBackSlider} />
            <div className="mb-[10px] flex justify-between">{renderDays()}</div>
            <ModalInfoBox.InfoItem
              leadingLabel="Earned veVOM"
              trailingLabel={renderInfoItem(
                formatNumberUSLocale(originalVeWomBalance),
                formatNumberUSLocale(newVeVomBalance)
              )}
            />
            <ModalInfoBox.InfoItem
              leadingLabel="Duration"
              trailingLabel={renderInfoItem(
                `${originalLockDay} days`,
                `${lockDayInputAmount} days`
              )}
              className="my-2"
            />
            <ModalInfoBox.InfoItem
              leadingLabel="Multipler"
              trailingLabel={renderInfoItem(
                `${getLockPositionMultiplier(originalVeWomBalance, originalLockedWom)}x`,
                `${getLockPositionMultiplier(newVeVomBalance, originalLockedWom)}x`
              )}
              className="my-2"
            />
            <ModalInfoBox.InfoItem
              leadingLabel="Unlock Date"
              trailingLabel={
                <div
                  className={clsx(
                    lockDayInputAmount ? 'font-bold text-wombatPurple1' : 'text-wombatBrown1'
                  )}
                >
                  {lockDayInputAmount
                    ? getClaimDate(Number(lockDayInputAmount), currentTime)
                    : timeConverter(originalUnlockTime)}
                </div>
              }
              className="my-2"
            />
            {/**@todo refactor: create warning message component */}
            {hasLockDayInput &&
              (newLockDayLtMinDay || newLockDayGtMaxDay) &&
              renderWarningMessage('Input a lock period of 7 days to 4 years')}
            {hasLockDayInput &&
              !newLockDayGtOriginLockDay &&
              renderWarningMessage('Extend your lock period beyond the original time frame')}
            {hasLockDayInput &&
              !newVeVomBalanceGtOriginalVeWomBalance &&
              renderWarningMessage(
                'The new veWom amount must be greater than existing veWom amount'
              )}
            <div
              style={{
                marginTop: '10px',
              }}
              className="flex items-center justify-center"
            >
              <WButton
                variant={isExtendDisabled ? Variant.LIGHT_PURPLE : Variant.GRADIENT}
                width="w-full"
                height="h-10"
                onClick={() => {
                  tryHandleExtendLock()
                  setIsShowActionModal(true)
                }}
                disabled={isExtendDisabled}
                isLoading={extendLockState === HandleExtendLockState.LOADING}
              >
                <div className="flex items-center">
                  <div className="mr-1 font-Jamjuree text-lg font-bold">EXTEND</div>
                </div>
              </WButton>
            </div>
          </ModalInfoBox>
        </div>
      </Modal>
      {isShowActionModal && renderModal()}
    </>
  )
}

export default ExtendLockModal

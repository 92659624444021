import { BigNumber, ContractTransaction } from 'ethers'
import { useState } from 'react'
import { useContract } from 'wagmi'
import { VEWOMS } from '../constants/contract/veWom'
import { ErrorMessages, PROVIDER_ERROR_CODES, PROVIDER_ERROR_REASON } from '../context/errorMessage'
import { useWeb3 } from '../context/Web3Context'
import { formatNumberUSLocale } from '../utils/numberFormat'
import { useTxnReceipt } from './../context/TxnReceiptProvider'
import { useAppDispatch } from '../store/hooks'
import { addErrorToast, addSuccessToast } from '../store/Toast/actions'

export enum HandleUnLockState {
  IDLE,
  LOADING,
  SUBMITED,
  FAILED,
  SUCCESS,
}

export default function useHandleUnLock(onTxnSubmited: (txn: ContractTransaction) => void) {
  const { chainId, signer } = useWeb3()
  const vewomContract = useContract({ ...VEWOMS[chainId]?.get(), signerOrProvider: signer })
  const [unLockState, setUnLockState] = useState(HandleUnLockState.IDLE)
  const [txnHashUnlock, setTxnHash] = useState('')
  const [isNewTxnUnlock, setIsNewTxn] = useState(false)
  const dispatch = useAppDispatch()
  const { refreshTxnReceipt } = useTxnReceipt()

  async function tryHandleUnLock(slot: number | null, amount: string) {
    if (vewomContract != null && slot != null) {
      setUnLockState(HandleUnLockState.LOADING)
      vewomContract.estimateGas
        .burn(BigNumber.from(slot))
        .then((estimateGas) => vewomContract.burn(BigNumber.from(slot), { gasLimit: estimateGas }))
        .then((txn) => {
          setTxnHash(txn.hash)
          setIsNewTxn(true)
          setTimeout(() => {
            setUnLockState(HandleUnLockState.SUBMITED)
            setIsNewTxn(false)
          }, 3000)
          txn.wait().then(() => {
            setUnLockState(HandleUnLockState.SUCCESS)
            refreshTxnReceipt()
            dispatch(
              addSuccessToast({
                message: `${formatNumberUSLocale(amount)} WOM`,
                title: 'Unlock Completed',
                txHash: txn.hash,
              })
            )
            onTxnSubmited(txn)
          })
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
          if (JSON.stringify(error).includes(code)) {
            setUnLockState(HandleUnLockState.IDLE)
            const errorMessage = ErrorMessages[code]
            dispatch(
              addErrorToast({
                message: errorMessage.message,
                title: errorMessage.title,
              })
            )
          } else {
            if (JSON.stringify(error).includes(PROVIDER_ERROR_REASON.VEWOM_NOT_ENOUGH_VOTE)) {
              dispatch(
                addErrorToast({
                  message:
                    'Your veWOMs are being used for voting. Please unvote first before unlocking.',
                  title: 'Unlock Failed',
                })
              )
            } else {
              dispatch(
                addErrorToast({
                  message: `${formatNumberUSLocale(amount)} WOM`,
                  title: 'Unlock Failed',
                })
              )
            }
            setUnLockState(HandleUnLockState.FAILED)
            const errInfo = '@VeWom.burn\n' + `slot: ${slot}\n` + `txHash: ${txnHashUnlock}\n`
            console.error(errInfo, error)
          }
        })
    }
  }

  return { unLockState, tryHandleUnLock, txnHashUnlock, isNewTxnUnlock }
}

import { strToWad } from '@hailstonelabs/big-number-utils'
import { utils } from 'ethers'
import React from 'react'
import { TokenSymbols } from '../../configx/tokenSymbols'
import { TOKENS } from '../../constants/contract'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { POOL_PAGE_COL_INFO_OBJ } from '../../constants/infoData'
import commafy, { commafyWithDecimals } from '../../utils/commafy'
import { formatNumberUSLocale } from '../../utils/numberFormat'
import { TokenReward } from '../PoolPage/TokenReward'
import AppTooltip from '../Tooltip/AppTooltip'
import { MyTotalAprTooltip } from './myTotalAprTooltip'
import { SupportedChains } from './supportedChains'
import { Asset } from '../../constants/contract/asset/Asset'
import { SupportedChainId } from '../../constants/web3/supportedChainId'
import { TokenInfo } from '../PoolPage'
import TokenImage from '../TokenImage'
import { chainInfos } from '../../constants/web3/chains'
import clsx from 'clsx'
import { useWeb3 } from '../../context/Web3Context'
import WButton, { Variant } from '../WButton'
import { usePoolPage } from '../../context/PoolPageContext'
import { PoolFilterGroup } from '../../constants/contract/pool/poolFilterGroup'

interface MobileInfoProps {
  chainId: SupportedChainId
  tokenInfo: TokenInfo
  asset: Asset
  depositedOrStaked: boolean | null
  DISCONNECT: boolean
  hasStaked: boolean | null
  myTotalAprUi: string
  womRewardUi: string
  rewarderBalanceArr?: string[]
  inExpander?: boolean
}

function MobileInfo({
  chainId,
  tokenInfo,
  asset: token,
  depositedOrStaked,
  DISCONNECT,
  hasStaked,
  myTotalAprUi,
  womRewardUi,
  rewarderBalanceArr,
  inExpander,
}: MobileInfoProps): JSX.Element {
  const { chainId: currentChainId, switchNetwork } = useWeb3()
  const { selectedPoolFilterGroup } = usePoolPage()
  const chainInfo = chainInfos[chainId]

  function getDisplayTradingVol24hr() {
    const tradingVol24h = tokenInfo.tradingVol24h
    if (typeof tradingVol24h === 'number')
      return formatNumberUSLocale(tokenInfo.tradingVol24h?.toString() ?? '0', 2)
    return '-'
  }

  return (
    <div className={clsx('flex min-h-[70px] flex-col pt-3.5 text-sm', inExpander && 'pb-3')}>
      {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && inExpander && (
        <div className="mb-2 flex items-center gap-2 px-4 md:ml-2">
          <TokenImage tokenSymbol={tokenInfo.symbol} width="22" height="22" />
          <h2 className="font-Work text-[14px] font-semibold leading-5">
            {token.displaySymbol} ({chainInfo.label})
          </h2>
        </div>
      )}
      {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN &&
      !inExpander &&
      selectedPoolFilterGroup === PoolFilterGroup.CROSS_CHAIN ? (
        <SupportedChains isMobile tokenInfo={tokenInfo} />
      ) : (
        <div className="my-[1px] flex w-full justify-between px-4 text-wombatBrown1">
          <div className="item-center flex">
            <span>{POOL_PAGE_COL_INFO_OBJ['covRatio'].name}</span>
            <div onClick={(e) => e.stopPropagation()}>
              <AppTooltip message={POOL_PAGE_COL_INFO_OBJ['covRatio'].detail}></AppTooltip>
            </div>
          </div>
          <div className="flex items-center">
            <div>{`${commafy(((tokenInfo.covRatio ?? 0) * 100).toFixed(1))}%`}</div>
          </div>
        </div>
      )}

      <div className="my-[1px] flex w-full justify-between px-4 text-wombatBrown1">
        <div>{POOL_PAGE_COL_INFO_OBJ['tvl'].name}</div>
        <div className="flex">
          <span className="group font-medium text-wombatBrown" onClick={(e) => e.stopPropagation()}>
            {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && !inExpander ? (
              <>
                {tokenInfo.multichainTVL ? (
                  <span className="hover-text">{`$${formatNumberUSLocale(
                    tokenInfo.multichainTVL.toString()
                  )}`}</span>
                ) : (
                  'loading\u2026'
                )}
              </>
            ) : (
              <>
                {tokenInfo.tvl ? (
                  <span className="hover-text">{`$${formatNumberUSLocale(
                    tokenInfo.tvl.toString()
                  )}`}</span>
                ) : (
                  'loading\u2026'
                )}
              </>
            )}
            <div className="tooltip-brown w-fit py-2 px-3 text-center text-sm text-white">
              <>
                {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && !inExpander ? (
                  <>
                    {commafyWithDecimals(tokenInfo.multichainLiability ?? 0)}&nbsp;
                    {token.displaySymbol}
                  </>
                ) : (
                  <>
                    {commafyWithDecimals(tokenInfo.liability ?? 0)}&nbsp;{token.displaySymbol}
                  </>
                )}
              </>
            </div>
          </span>
          <span className="font-Work font-medium text-wombatBrown1">
            {tokenInfo.poolLabel === PoolLabels.CROSS_CHAIN && !inExpander ? (
              <>{` ($${formatNumberUSLocale(
                tokenInfo.multiChainTradingVol24h?.toString() ?? '0',
                2
              )})`}</>
            ) : (
              <>{` ($${getDisplayTradingVol24hr()})`}</>
            )}
          </span>
        </div>
      </div>
      {/* Switch chain button */}
      {currentChainId !== chainId ? (
        <div className="mt-2 px-4">
          <WButton
            variant={Variant.GRADIENT}
            onClick={async () => {
              await switchNetwork(+chainId)
            }}
            width="w-full"
          >
            Switch to {chainInfo.label}
          </WButton>
        </div>
      ) : (
        <>
          <div className="my-[1px] flex w-full justify-between px-4 text-wombatBrown1">
            <div>{POOL_PAGE_COL_INFO_OBJ['myTotalApr'].name}</div>
            <div className="relative flex w-fit">
              <span className={`${hasStaked && !DISCONNECT ? 'hover-text' : ''} text-wombatBrown`}>
                {myTotalAprUi}
              </span>
              <MyTotalAprTooltip
                DISCONNECT={DISCONNECT}
                tokenInfo={tokenInfo}
                depositedOrStaked={depositedOrStaked}
                hasStaked={hasStaked}
                isMobile
              />
            </div>
          </div>
          <div className="my-[1px] flex w-full justify-between px-4 text-wombatBrown1">
            <div>{POOL_PAGE_COL_INFO_OBJ['reward'].name}</div>
            <div className="relative flex flex-col justify-center gap-1">
              {hasStaked && !DISCONNECT ? (
                <>
                  {strToWad(womRewardUi).gt('0') && (
                    <TokenReward symbol={TokenSymbols.WOM} amount={womRewardUi} hideTokenName />
                  )}
                  {tokenInfo.bonusTokens?.map((rewardInfo, index) => {
                    const rewardToken = TOKENS[chainId][rewardInfo.symbol]
                    if (rewardInfo.amount.eq('0')) {
                      return
                    }
                    const amount = utils.formatUnits(rewardInfo.amount, rewardToken?.decimals ?? 18)

                    const hasBonusTokenBalanceDrained =
                      rewarderBalanceArr && strToWad(rewarderBalanceArr[index]).isZero()

                    return (
                      <TokenReward
                        key={rewardInfo.symbol}
                        symbol={rewardInfo.symbol}
                        amount={amount}
                        hideTokenName
                        hasDrained={hasBonusTokenBalanceDrained}
                      />
                    )
                  })}
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MobileInfo

import { createSlice } from '@reduxjs/toolkit'
import {
  AssetDataWithAccountType,
  AssetDataWithoutAccountType,
  BoostedPoolRewarderDataWithAccountType,
  BoostedPoolRewarderDataWithoutAccountType,
  BribeConfigDataWithAccountType,
  BribeConfigDataWithoutAccountType,
  BribeInfoDataWithoutAccountType,
  PoolDataWithoutAccountType,
  PoolRewarderDataWithAccountType,
  TokenDataWithAccountType,
  TokenDataWithoutAccountType,
  VewomDataWithAccountType,
  VewomDataWithoutAccountType,
  VotingDataWithAccountType,
  VotingDataWithoutAccountType,
} from '../../context/MulticallDataContext/helpers'
import {
  MasterWombatDataWithAccountType,
  MasterWombatDataWithoutAccountType,
} from '../../context/MulticallDataContext/helpers/fetchMasterWombatHelper'
import { PoolDataWithAccountType } from '../../context/MulticallDataContext/helpers/fetchPoolDataHelper'
import { UniswapV3PoolData } from '../../context/MulticallDataContext/helpers/fetchUniswapV3PoolDataHelper'
import fetchMulticalData from './thunks/fetchMulticalData'

export interface MulticallDataState {
  isMulticallDataFetched: boolean
  isMulticallDataFetchedWithAccount: boolean
  tokenData: {
    withoutAccount?: TokenDataWithoutAccountType
    withAccount?: TokenDataWithAccountType
  }
  poolData: {
    withoutAccount?: PoolDataWithoutAccountType
    withAccount?: PoolDataWithAccountType
  }
  votingData: {
    withoutAccount?: VotingDataWithoutAccountType
    withAccount?: VotingDataWithAccountType
  }
  masterWombatData: {
    withoutAccount?: MasterWombatDataWithoutAccountType
    withAccount?: MasterWombatDataWithAccountType
  }
  vewomData: {
    withoutAccount?: VewomDataWithoutAccountType
    withAccount?: VewomDataWithAccountType
  }
  assetData: {
    withoutAccount?: AssetDataWithoutAccountType
    withAccount?: AssetDataWithAccountType
  }
  uniswapV3PoolData?: UniswapV3PoolData
  bribeConfigData: {
    withoutAccount?: BribeConfigDataWithoutAccountType
    withAccount?: BribeConfigDataWithAccountType
  }
  bribeInfoData: {
    withoutAccount?: BribeInfoDataWithoutAccountType
  }
  poolRewarderData: {
    withAccount?: PoolRewarderDataWithAccountType
  }
  boostedPoolRewarderData: {
    withAccount?: BoostedPoolRewarderDataWithAccountType
    withoutAccount?: BoostedPoolRewarderDataWithoutAccountType
  }
  dataLoading: boolean
}

export const initialState: MulticallDataState = {
  dataLoading: false,
  isMulticallDataFetched: false,
  isMulticallDataFetchedWithAccount: false,
  tokenData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  votingData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  poolData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  masterWombatData: {},
  vewomData: {},
  assetData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  bribeConfigData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
  uniswapV3PoolData: undefined,
  bribeInfoData: {
    withoutAccount: undefined,
  },
  poolRewarderData: {
    withAccount: undefined,
  },
  boostedPoolRewarderData: {
    withoutAccount: undefined,
    withAccount: undefined,
  },
}

const multicallDataSlice = createSlice({
  name: 'MulticallData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMulticalData.pending, (state) => {
      state.dataLoading = true
    })
    builder.addCase(fetchMulticalData.rejected, (state, action) => {
      console.error('@MulticallData', action.error)
      state.dataLoading = false
    })
    builder.addCase(fetchMulticalData.fulfilled, (state, action) => {
      state.tokenData = {
        ...state.tokenData,
        ...action.payload.tokenData,
      }
      state.votingData = {
        ...state.votingData,
        ...action.payload.votingData,
      }
      state.poolData = {
        ...state.poolData,
        ...action.payload.poolData,
      }
      state.masterWombatData = {
        ...state.masterWombatData,
        ...action.payload.masterWombatData,
      }
      state.vewomData = {
        ...state.vewomData,
        ...action.payload.vewomData,
      }
      state.assetData = {
        ...state.assetData,
        ...action.payload.assetData,
      }
      state.bribeConfigData = {
        ...state.bribeConfigData,
        ...action.payload.bribeConfigData,
      }
      state.bribeInfoData = {
        ...state.bribeInfoData,
        ...action.payload.bribeInfoData,
      }
      state.poolRewarderData = {
        ...state.poolRewarderData,
        ...action.payload.poolRewarderData,
      }
      state.boostedPoolRewarderData = {
        ...state.boostedPoolRewarderData,
        ...action.payload.boostedPoolRewarderData,
      }
      state.uniswapV3PoolData = {
        ...state.uniswapV3PoolData,
        ...action.payload.uniswapV3PoolData,
      }
      state.isMulticallDataFetched = true
    })
  },
})

export default multicallDataSlice.reducer

/* eslint-disable react/prop-types */
import clsx from 'clsx'
import Image from 'next/image'
import * as React from 'react'
import { overrideTailwindClasses } from 'tailwind-override'
import loading from '../../public/assets/icons/loading.svg'

export type ButtonProps = {
  isLoading?: boolean
  variant?: 'primary' | 'outlined'
} & React.ComponentPropsWithoutRef<'button'>

export default function Button({
  children,
  className,
  disabled: buttonDisabled,
  isLoading,
  variant = 'primary',
  ...rest
}: ButtonProps) {
  const disabled = isLoading || buttonDisabled
  const buttonStyles = (() => {
    switch (variant) {
      case 'outlined':
        return 'bg-none text-wombatPurple1 border-1 border-wombatPurple1'
      default:
        return 'bg-wombatPurple1 text-wombatCream'
    }
  })()
  return (
    <button
      {...rest}
      disabled={disabled}
      className={overrideTailwindClasses(
        clsx(
          'button-hover-opacity-light ml-0.5 w-buttonWidth bg-contain bg-center bg-no-repeat px-5 py-2 text-lg md:text-2xl',
          'disabled:cursor-not-allowed',
          !disabled && 'animated-underline',
          buttonStyles,
          disabled && 'border-0 bg-wombatPurple3 text-wombatCream',
          isLoading && 'flex !cursor-wait items-center justify-center transition-none',
          className
        )
      )}
    >
      {isLoading && (
        <div className={`mr-1 h-7 w-7 animate-spin`}>
          <Image alt={''} src={loading} />
        </div>
      )}
      <span className="font-Jamjuree font-bold">
        <div className="flex items-center justify-center">{children}</div>
      </span>
    </button>
  )
}

import { useCallback, useState } from 'react'
interface Props<T> {
  key: string
  initialValue: T
}

// reference: https://usehooks.com/useLocalStorage/
/**
 * Cavert : Try not to put object to initialValue to prevent uncessary re-render
 * @param param0
 * @returns
 */
function useLocalStorage<T>({
  key,
  initialValue,
}: Props<T>): [T, (value: T | ((value: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }

    try {
      const item = window.localStorage.getItem(key)
      // use default value if localStorage is empty
      if (!item) return initialValue
      if (typeof initialValue !== 'object') {
        // parse to primitive value
        return JSON.parse(item)
      } else {
        // destructure object, and replace items not found in storage by default values
        return { ...initialValue, ...JSON.parse(item || '{}') }
      }
    } catch (error) {
      const errInfo = '@window.localStorage.getItem\n' + `key: ${key}\n`
      console.error(errInfo, error)
      return initialValue
    }
  })

  const setValueToLocalStorage = useCallback(
    (value: T | ((value: T) => T)) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
        setStoredValue(valueToStore)
      } catch (error) {
        const errInfo = '@window.localStorage.setItem\n' + `key: ${key}\n`
        console.error(errInfo, error)
      }
    },
    [key, storedValue]
  )

  return [storedValue, setValueToLocalStorage]
}

export default useLocalStorage

import React, { useState } from 'react'
import Tabs from '../../Tabs'
import CrossChainPendingSwap from './CrossChainPendingSwap'
import CrossChainUserCreditInfo from './CrossChainUserCreditInfo'
import CrossChainHistory from './CrossChainHistory'

enum TabId {
  PENDING,
  HISTORY,
  CREDIT,
}
function CrossChainTabs() {
  const [currentActiveTabId, setCurrentActiveTabId] = useState(TabId.PENDING)

  const renderMainUI = () => {
    switch (currentActiveTabId) {
      case TabId.PENDING:
        return <CrossChainPendingSwap />
      case TabId.HISTORY:
        return <CrossChainHistory />
      case TabId.CREDIT:
        return <CrossChainUserCreditInfo />
    }
  }
  return (
    <>
      <Tabs
        currentActiveTabId={currentActiveTabId}
        onTabClick={(tabId) => {
          setCurrentActiveTabId(tabId)
        }}
      >
        <Tabs.Tab tabId={TabId.PENDING}>Pending</Tabs.Tab>
        <Tabs.Tab tabId={TabId.HISTORY}>History</Tabs.Tab>
        <Tabs.Tab tabId={TabId.CREDIT}>Credit</Tabs.Tab>
      </Tabs>
      {renderMainUI()}
    </>
  )
}

export default CrossChainTabs

import type { AppProps } from 'next/app'
import Script from 'next/script'
import React, { useEffect, useState } from 'react'
import ActiveTab from '../context/ActivetabContext'
import MasterWombatProvider from '../context/masterWombatContext'
import { SpaceIdProvider } from '../context/spaceIdContext'
import { TxnReceiptProvider } from '../context/TxnReceiptProvider'
import UserPreferenceProvider from '../context/userPreferenceContext'
import { WalletSelectedProvider } from '../context/WalletSelectedContext'
import '../styles/globals.scss'
import '../styles/tailwind.css'
import { VotingDataProvider } from '../context/VotingDataContext'
import ModalProvider from '../context/ModalContext'
import { WagmiConfig } from 'wagmi'
import { wagmiClient } from '../constants/wagmi'
import Web3Provider from '../context/Web3Context'
import { Provider } from 'react-redux'
import { store } from '../store/store'
import ToastContainer from '../components/Toast/ToastContainer'

function MyApp({ Component, pageProps }: AppProps) {
  const [pageLoaded, setPageLoaded] = useState(false)

  useEffect(() => {
    setPageLoaded(true)
  }, [])

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
      />
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
        `}
      </Script>

      <WagmiConfig client={wagmiClient}>
        <Provider store={store}>
          <Web3Provider>
            <ToastContainer />
            <ModalProvider>
              <UserPreferenceProvider>
                <WalletSelectedProvider>
                  <TxnReceiptProvider>
                    <SpaceIdProvider>
                      <MasterWombatProvider>
                        <VotingDataProvider>
                          <ActiveTab>
                            <div suppressHydrationWarning>
                              {typeof window === 'undefined' || !pageLoaded ? null : (
                                <Component {...pageProps} />
                              )}
                            </div>
                          </ActiveTab>
                        </VotingDataProvider>
                      </MasterWombatProvider>
                    </SpaceIdProvider>
                  </TxnReceiptProvider>
                </WalletSelectedProvider>
              </UserPreferenceProvider>
            </ModalProvider>
          </Web3Provider>
        </Provider>
      </WagmiConfig>
    </>
  )
}

export default MyApp

import { useMemo } from 'react'
import { IconProps } from '../Icons/IconChevDown'

export type WppMultiple = '1' | '1.2' | '1.5' | '1.8' | '2' | '2.5' | '3'

const WppBadge = ({ multiple = '1', ...props }: IconProps & { multiple?: WppMultiple }) => {
  const numberPath = useMemo(() => {
    switch (multiple) {
      case '1':
        return (
          <>
            <path
              className="h"
              d="M431.91,115.85h-33.71c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h8.54v-25.54h-7.02c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h22.12c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v37.54h7.32c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v9.25c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65Z"
            />
            <path
              className="h"
              d="M462.03,109.56c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.52c0-1.87.16-3.27.47-4.21.31-.93.9-2.03,1.75-3.29l4.74-6.64-4.74-6.64c-.9-1.26-1.49-2.36-1.78-3.29-.29-.93-.44-2.34-.44-4.21v-4.69c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h9c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.21c0,.41.02.83.06,1.28.04.45.19.87.47,1.28l2.57,3.84c.19.33.4.58.61.76.21.18.48.27.79.27h.7c.31,0,.56-.09.76-.27.19-.18.41-.44.64-.76l2.63-3.84c.27-.41.43-.83.47-1.28.04-.45.06-.87.06-1.28v-4.21c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h8.89c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.69c0,1.87-.16,3.27-.47,4.21-.31.94-.9,2.03-1.75,3.29l-4.74,6.7,4.74,6.58c.9,1.26,1.49,2.36,1.78,3.29.29.93.44,2.34.44,4.21v6.52c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.03c0-.41-.02-.83-.06-1.28-.04-.45-.2-.87-.47-1.28l-2.57-3.84c-.23-.37-.45-.63-.64-.79-.2-.16-.45-.24-.76-.24h-.82c-.31,0-.57.08-.76.24-.2.16-.41.43-.64.79l-2.63,3.84c-.27.41-.43.83-.47,1.28-.04.45-.06.88-.06,1.28v6.03Z"
            />
          </>
        )
      case '1.2':
        return (
          <>
            <g xmlns="http://www.w3.org/2000/svg">
              <path
                className="h"
                d="M400.83,115.85h-33.71c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h8.54v-25.54h-7.02c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h22.12c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v37.54h7.32c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v9.25c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65Z"
              />
              <path
                className="h"
                d="M417.36,115.85h-7.46c-.47,0-.81-.1-1.02-.31-.21-.21-.31-.55-.31-1.02v-7.51c0-.47.1-.81.31-1.02.21-.21.55-.31,1.02-.31h7.46c.47,0,.81.11,1.02.31.21.21.31.55.31,1.02v7.51c0,.47-.1.81-.31,1.02-.21.21-.55.31-1.02.31Z"
              />
              <path
                className="h"
                d="M462.09,115.85h-36.31c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-13.15c0-2.96.29-5.39.88-7.3.58-1.91,1.41-3.48,2.48-4.7,1.07-1.22,2.34-2.19,3.81-2.91,1.47-.71,3.08-1.32,4.81-1.83l10.22-3.21c.81-.2,1.36-.51,1.64-.92.28-.41.42-1.02.42-1.83,0-.76-.15-1.29-.46-1.57-.31-.28-.84-.42-1.6-.42h-16.55c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-8.95c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h22.5c4.32,0,7.64,1.08,9.95,3.25,2.31,2.17,3.47,5.31,3.47,9.44v7.19c0,3.82-1.07,6.75-3.2,8.79-2.14,2.04-5.03,3.62-8.7,4.74l-10.37,3.29c-.76.2-1.28.43-1.56.69-.28.26-.42.79-.42,1.61v1.61h21.74c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v8.95c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65Z"
              />
            </g>
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M490.52,109.56c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.52c0-1.87.16-3.27.47-4.21.31-.93.9-2.03,1.75-3.29l4.74-6.64-4.74-6.64c-.9-1.26-1.49-2.36-1.78-3.29-.29-.93-.44-2.34-.44-4.21v-4.69c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h9c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.21c0,.41.02.83.06,1.28.04.45.19.87.47,1.28l2.57,3.84c.19.33.4.58.61.76.21.18.48.27.79.27h.7c.31,0,.56-.09.76-.27.19-.18.41-.44.64-.76l2.63-3.84c.27-.41.43-.83.47-1.28.04-.45.06-.87.06-1.28v-4.21c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h8.89c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.69c0,1.87-.16,3.27-.47,4.21-.31.94-.9,2.03-1.75,3.29l-4.74,6.7,4.74,6.58c.9,1.26,1.49,2.36,1.78,3.29.29.93.44,2.34.44,4.21v6.52c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.03c0-.41-.02-.83-.06-1.28-.04-.45-.2-.87-.47-1.28l-2.57-3.84c-.23-.37-.45-.63-.64-.79-.2-.16-.45-.24-.76-.24h-.82c-.31,0-.57.08-.76.24-.2.16-.41.43-.64.79l-2.63,3.84c-.27.41-.43.83-.47,1.28-.04.45-.06.88-.06,1.28v6.03Z"
            />
          </>
        )
      case '1.5':
        return (
          <>
            <g xmlns="http://www.w3.org/2000/svg">
              <path
                className="h"
                d="M400.52,115.85h-33.71c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h8.54v-25.54h-7.02c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h22.12c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v37.54h7.32c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v9.25c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65Z"
              />
              <path
                className="h"
                d="M417.06,115.85h-7.46c-.47,0-.81-.1-1.02-.31-.21-.21-.31-.55-.31-1.02v-7.51c0-.47.1-.81.31-1.02.21-.21.55-.31,1.02-.31h7.46c.47,0,.81.11,1.02.31.21.21.31.55.31,1.02v7.51c0,.47-.1.81-.31,1.02-.21.21-.55.31-1.02.31Z"
              />
              <path
                className="h"
                d="M450.87,115.85h-24.26c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-8.95c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h19.68c.61,0,1.11-.14,1.49-.42.38-.28.57-.73.57-1.34v-2.98c0-.61-.19-1.06-.57-1.34-.38-.28-.88-.42-1.49-.42h-18.92c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-28.59c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h32.88c.97,0,1.66.22,2.1.65.43.43.65,1.13.65,2.1v9.1c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65h-18.31v4.97h10.45c4.12,0,7.37,1.02,9.76,3.06,2.39,2.04,3.58,5.07,3.58,9.1v10.63c0,3.88-1.33,6.96-4,9.25-2.67,2.29-6.29,3.44-10.87,3.44Z"
              />
            </g>
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M490.52,109.56c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.52c0-1.87.16-3.27.47-4.21.31-.93.9-2.03,1.75-3.29l4.74-6.64-4.74-6.64c-.9-1.26-1.49-2.36-1.78-3.29-.29-.93-.44-2.34-.44-4.21v-4.69c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h9c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.21c0,.41.02.83.06,1.28.04.45.19.87.47,1.28l2.57,3.84c.19.33.4.58.61.76.21.18.48.27.79.27h.7c.31,0,.56-.09.76-.27.19-.18.41-.44.64-.76l2.63-3.84c.27-.41.43-.83.47-1.28.04-.45.06-.87.06-1.28v-4.21c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h8.89c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.69c0,1.87-.16,3.27-.47,4.21-.31.94-.9,2.03-1.75,3.29l-4.74,6.7,4.74,6.58c.9,1.26,1.49,2.36,1.78,3.29.29.93.44,2.34.44,4.21v6.52c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.03c0-.41-.02-.83-.06-1.28-.04-.45-.2-.87-.47-1.28l-2.57-3.84c-.23-.37-.45-.63-.64-.79-.2-.16-.45-.24-.76-.24h-.82c-.31,0-.57.08-.76.24-.2.16-.41.43-.64.79l-2.63,3.84c-.27.41-.43.83-.47,1.28-.04.45-.06.88-.06,1.28v6.03Z"
            />
          </>
        )
      case '1.8':
        return (
          <>
            <g xmlns="http://www.w3.org/2000/svg">
              <path
                className="h"
                d="M398.08,115.85h-33.71c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h8.54v-25.54h-7.02c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.25c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h22.12c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v37.54h7.32c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v9.25c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65Z"
              />
              <path
                className="h"
                d="M414.62,115.85h-7.46c-.47,0-.81-.1-1.02-.31-.21-.21-.31-.55-.31-1.02v-7.51c0-.47.1-.81.31-1.02.21-.21.55-.31,1.02-.31h7.46c.47,0,.81.11,1.02.31.21.21.31.55.31,1.02v7.51c0,.47-.1.81-.31,1.02-.21.21-.55.31-1.02.31Z"
              />
              <path
                className="h"
                d="M467.88,96.28v7.42c0,1.99-.37,3.8-1.11,5.43-.74,1.63-2,3.03-3.78,4.21-1.78,1.17-4.18,2.08-7.21,2.71-3.03.64-6.83.96-11.4.96s-8.39-.32-11.44-.96c-3.05-.64-5.47-1.54-7.25-2.71-1.78-1.17-3.04-2.57-3.78-4.21-.74-1.63-1.11-3.44-1.11-5.43v-7.42c0-3.57.77-6.32,2.33-8.26,1.55-1.94,3.47-3.13,5.76-3.59-1.68-.71-2.85-1.78-3.51-3.21-.66-1.43-.99-3.11-.99-5.05v-4.43c0-1.88.33-3.57.99-5.05.66-1.48,1.75-2.74,3.28-3.78,1.53-1.04,3.58-1.85,6.18-2.41,2.59-.56,5.8-.84,9.61-.84s7,.28,9.57.84c2.57.56,4.6,1.36,6.1,2.41,1.5,1.04,2.57,2.31,3.2,3.78.64,1.48.95,3.16.95,5.05v4.43c0,1.94-.33,3.62-.99,5.05-.66,1.43-1.83,2.5-3.51,3.21,2.29.46,4.21,1.66,5.76,3.59,1.55,1.94,2.33,4.69,2.33,8.26ZM437.68,100.79c0,.82.43,1.49,1.3,2.03.86.54,2.67.8,5.42.8s4.54-.27,5.38-.8c.84-.54,1.26-1.21,1.26-2.03v-4.05c0-.76-.42-1.42-1.26-1.95-.84-.54-2.63-.8-5.38-.8s-4.55.27-5.42.8c-.87.54-1.3,1.19-1.3,1.95v4.05ZM439.59,79.15c0,.82.27,1.49.8,2.03.53.54,1.87.8,4,.8s3.52-.27,4-.8c.48-.54.72-1.21.72-2.03v-3.36c0-.76-.24-1.42-.72-1.95-.48-.54-1.82-.8-4-.8s-3.47.27-4,.8c-.53.54-.8,1.19-.8,1.95v3.36Z"
              />
            </g>
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M490.52,109.56c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.52c0-1.87.16-3.27.47-4.21.31-.93.9-2.03,1.75-3.29l4.74-6.64-4.74-6.64c-.9-1.26-1.49-2.36-1.78-3.29-.29-.93-.44-2.34-.44-4.21v-4.69c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h9c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.21c0,.41.02.83.06,1.28.04.45.19.87.47,1.28l2.57,3.84c.19.33.4.58.61.76.21.18.48.27.79.27h.7c.31,0,.56-.09.76-.27.19-.18.41-.44.64-.76l2.63-3.84c.27-.41.43-.83.47-1.28.04-.45.06-.87.06-1.28v-4.21c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h8.89c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.69c0,1.87-.16,3.27-.47,4.21-.31.94-.9,2.03-1.75,3.29l-4.74,6.7,4.74,6.58c.9,1.26,1.49,2.36,1.78,3.29.29.93.44,2.34.44,4.21v6.52c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.03c0-.41-.02-.83-.06-1.28-.04-.45-.2-.87-.47-1.28l-2.57-3.84c-.23-.37-.45-.63-.64-.79-.2-.16-.45-.24-.76-.24h-.82c-.31,0-.57.08-.76.24-.2.16-.41.43-.64.79l-2.63,3.84c-.27.41-.43.83-.47,1.28-.04.45-.06.88-.06,1.28v6.03Z"
            />
          </>
        )
      case '2':
        return (
          <>
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M432.45,115.85h-36.31c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-13.15c0-2.96.29-5.39.88-7.3.58-1.91,1.41-3.48,2.48-4.7,1.07-1.22,2.34-2.19,3.81-2.91,1.47-.71,3.08-1.32,4.81-1.83l10.22-3.21c.81-.2,1.36-.51,1.64-.92.28-.41.42-1.02.42-1.83,0-.76-.15-1.29-.46-1.57-.31-.28-.84-.42-1.6-.42h-16.55c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-8.95c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h22.5c4.32,0,7.64,1.08,9.95,3.25,2.31,2.17,3.47,5.31,3.47,9.44v7.19c0,3.82-1.07,6.75-3.2,8.79-2.14,2.04-5.03,3.62-8.7,4.74l-10.37,3.29c-.76.2-1.28.43-1.56.69-.28.26-.42.79-.42,1.61v1.61h21.74c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v8.95c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65Z"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M462.03,109.56c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.52c0-1.87.16-3.27.47-4.21.31-.93.9-2.03,1.75-3.29l4.74-6.64-4.74-6.64c-.9-1.26-1.49-2.36-1.78-3.29-.29-.93-.44-2.34-.44-4.21v-4.69c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h9c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.21c0,.41.02.83.06,1.28.04.45.19.87.47,1.28l2.57,3.84c.19.33.4.58.61.76.21.18.48.27.79.27h.7c.31,0,.56-.09.76-.27.19-.18.41-.44.64-.76l2.63-3.84c.27-.41.43-.83.47-1.28.04-.45.06-.87.06-1.28v-4.21c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h8.89c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.69c0,1.87-.16,3.27-.47,4.21-.31.94-.9,2.03-1.75,3.29l-4.74,6.7,4.74,6.58c.9,1.26,1.49,2.36,1.78,3.29.29.93.44,2.34.44,4.21v6.52c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.03c0-.41-.02-.83-.06-1.28-.04-.45-.2-.87-.47-1.28l-2.57-3.84c-.23-.37-.45-.63-.64-.79-.2-.16-.45-.24-.76-.24h-.82c-.31,0-.57.08-.76.24-.2.16-.41.43-.64.79l-2.63,3.84c-.27.41-.43.83-.47,1.28-.04.45-.06.88-.06,1.28v6.03Z"
            />
          </>
        )
      case '2.5':
        return (
          <>
            <g xmlns="http://www.w3.org/2000/svg">
              <path
                className="h"
                d="M402.58,115.85h-36.31c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-13.15c0-2.96.29-5.39.88-7.3.58-1.91,1.41-3.48,2.48-4.7,1.07-1.22,2.34-2.19,3.81-2.91,1.47-.71,3.08-1.32,4.81-1.83l10.22-3.21c.81-.2,1.36-.51,1.64-.92.28-.41.42-1.02.42-1.83,0-.76-.15-1.29-.46-1.57-.31-.28-.84-.42-1.6-.42h-16.55c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-8.95c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h22.5c4.32,0,7.64,1.08,9.95,3.25,2.31,2.17,3.47,5.31,3.47,9.44v7.19c0,3.82-1.07,6.75-3.2,8.79-2.14,2.04-5.03,3.62-8.7,4.74l-10.37,3.29c-.76.2-1.28.43-1.56.69-.28.26-.42.79-.42,1.61v1.61h21.74c.97,0,1.67.22,2.1.65.43.43.65,1.13.65,2.1v8.95c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65Z"
              />
              <path
                className="h"
                d="M416.98,115.85h-7.46c-.47,0-.81-.1-1.02-.31-.21-.21-.31-.55-.31-1.02v-7.51c0-.47.1-.81.31-1.02.21-.21.55-.31,1.02-.31h7.46c.47,0,.81.11,1.02.31.21.21.31.55.31,1.02v7.51c0,.47-.1.81-.31,1.02-.21.21-.55.31-1.02.31Z"
              />
              <path
                className="h"
                d="M450.8,115.85h-24.26c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-8.95c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h19.68c.61,0,1.11-.14,1.49-.42.38-.28.57-.73.57-1.34v-2.98c0-.61-.19-1.06-.57-1.34-.38-.28-.88-.42-1.49-.42h-18.92c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-28.59c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h32.88c.97,0,1.66.22,2.1.65.43.43.65,1.13.65,2.1v9.1c0,.97-.22,1.67-.65,2.1-.43.43-1.13.65-2.1.65h-18.31v4.97h10.45c4.12,0,7.37,1.02,9.76,3.06,2.39,2.04,3.58,5.07,3.58,9.1v10.63c0,3.88-1.33,6.96-4,9.25-2.67,2.29-6.29,3.44-10.87,3.44Z"
              />
            </g>
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M490.52,109.56c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.52c0-1.87.16-3.27.47-4.21.31-.93.9-2.03,1.75-3.29l4.74-6.64-4.74-6.64c-.9-1.26-1.49-2.36-1.78-3.29-.29-.93-.44-2.34-.44-4.21v-4.69c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h9c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.21c0,.41.02.83.06,1.28.04.45.19.87.47,1.28l2.57,3.84c.19.33.4.58.61.76.21.18.48.27.79.27h.7c.31,0,.56-.09.76-.27.19-.18.41-.44.64-.76l2.63-3.84c.27-.41.43-.83.47-1.28.04-.45.06-.87.06-1.28v-4.21c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h8.89c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.69c0,1.87-.16,3.27-.47,4.21-.31.94-.9,2.03-1.75,3.29l-4.74,6.7,4.74,6.58c.9,1.26,1.49,2.36,1.78,3.29.29.93.44,2.34.44,4.21v6.52c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.03c0-.41-.02-.83-.06-1.28-.04-.45-.2-.87-.47-1.28l-2.57-3.84c-.23-.37-.45-.63-.64-.79-.2-.16-.45-.24-.76-.24h-.82c-.31,0-.57.08-.76.24-.2.16-.41.43-.64.79l-2.63,3.84c-.27.41-.43.83-.47,1.28-.04.45-.06.88-.06,1.28v6.03Z"
            />
          </>
        )
      case '3':
        return (
          <>
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M396.9,101.4h19.15c1.22,0,2.01-.23,2.36-.69.35-.46.53-.99.53-1.61v-2.06c0-.61-.18-1.13-.53-1.57-.36-.43-1.14-.65-2.36-.65h-12.97c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-8.18c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h8.01c1.22,0,2.01-.23,2.36-.69.35-.46.53-.99.53-1.61v-1.15c0-.61-.18-1.15-.53-1.61-.36-.46-1.14-.69-2.36-.69h-14.19c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-9.1c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65h21.28c4.22,0,7.36.98,9.42,2.94,2.06,1.96,3.09,4.62,3.09,7.99v4.43c0,1.94-.33,3.62-.99,5.05-.66,1.43-1.83,2.5-3.51,3.21,3.61.41,6.11,1.52,7.51,3.33,1.4,1.81,2.1,4.4,2.1,7.76v7.65c0,3.93-1.25,7.02-3.74,9.29-2.49,2.27-6.18,3.4-11.06,3.4h-24.1c-.97,0-1.67-.22-2.1-.65-.43-.43-.65-1.13-.65-2.1v-8.95c0-.97.22-1.67.65-2.1.43-.43,1.13-.65,2.1-.65Z"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              className="h"
              d="M462.03,109.56c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.52c0-1.87.16-3.27.47-4.21.31-.93.9-2.03,1.75-3.29l4.74-6.64-4.74-6.64c-.9-1.26-1.49-2.36-1.78-3.29-.29-.93-.44-2.34-.44-4.21v-4.69c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h9c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.21c0,.41.02.83.06,1.28.04.45.19.87.47,1.28l2.57,3.84c.19.33.4.58.61.76.21.18.48.27.79.27h.7c.31,0,.56-.09.76-.27.19-.18.41-.44.64-.76l2.63-3.84c.27-.41.43-.83.47-1.28.04-.45.06-.87.06-1.28v-4.21c0-.77.17-1.33.5-1.68.33-.34.87-.52,1.61-.52h8.89c.74,0,1.28.17,1.61.52.33.35.5.91.5,1.68v4.69c0,1.87-.16,3.27-.47,4.21-.31.94-.9,2.03-1.75,3.29l-4.74,6.7,4.74,6.58c.9,1.26,1.49,2.36,1.78,3.29.29.93.44,2.34.44,4.21v6.52c0,.77-.17,1.33-.5,1.68-.33.35-.87.52-1.61.52h-8.89c-.74,0-1.28-.17-1.61-.52-.33-.34-.5-.9-.5-1.68v-6.03c0-.41-.02-.83-.06-1.28-.04-.45-.2-.87-.47-1.28l-2.57-3.84c-.23-.37-.45-.63-.64-.79-.2-.16-.45-.24-.76-.24h-.82c-.31,0-.57.08-.76.24-.2.16-.41.43-.64.79l-2.63,3.84c-.27.41-.43.83-.47,1.28-.04.45-.06.88-.06,1.28v6.03Z"
            />
          </>
        )
    }
  }, [multiple])
  return (
    <svg
      id="c"
      data-name="Text"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 534.03 185.89"
      className={'w-14 md:w-20'}
      {...props}
    >
      <defs>
        <style>
          {
            '\n      .d {\n        fill: #312a1e;\n      }\n\n      .e {\n        fill: #cea638;\n      }\n\n      .f {\n        fill: #fff0b3;\n      }\n\n      .g {\n        fill: #ffdd52;\n      }\n\n      .h {\n        fill: #342a1a;\n      }\n    '
          }
        </style>
      </defs>
      <g>
        <g>
          <g>
            <polygon
              className="e"
              points="311.09 117.64 311.09 129.4 322.85 129.4 322.85 117.64 322.85 105.88 311.09 105.88 311.09 117.64"
            />
            <polygon
              className="e"
              points="311.09 82.35 311.09 94.11 322.85 94.11 322.85 82.35 322.85 70.59 311.09 70.59 311.09 82.35"
            />
            <rect className="e" x={311.09} y={47.06} width={11.76} height={11.76} />
          </g>
          <path
            className="f"
            d="M511,11.26h-165.12v11.76h-11.76v11.77h-34.29v-11.77h-11.77v-11.76H11.27v153.93h276.79v-11.76h11.77v-11.77h34.29v11.77h11.76v11.76h176.89V11.26h-11.77ZM275.3,152.43H24.03v-10.77h251.27v10.77ZM275.3,34.79H24.03v-10.77h251.27v10.77ZM298.83,93.61v35.29h-10.77V47.56h10.77v46.06ZM322.35,128.9h-10.76v-22.53h10.76v22.53ZM322.35,93.61h-10.76v-22.53h10.76v22.53ZM322.35,58.32h-10.76v-10.76h10.76v10.76ZM345.88,93.61v35.29h-10.76V47.56h10.76v46.06ZM357.65,140.67h-10.76v-10.76h10.76v10.76ZM357.65,46.56h-10.76v-10.76h10.76v10.76ZM510,152.43h-151.36v-10.77h151.36v10.77Z"
          />
          <g>
            <rect className="d" x={522.27} y={152.93} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={141.17} width={11.76} height={11.77} />
            <rect className="d" x={522.27} y={129.4} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={117.64} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={105.88} width={11.76} height={11.77} />
            <rect className="d" x={522.27} y={94.11} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={82.35} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={70.59} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={58.82} width={11.76} height={11.77} />
            <rect className="d" x={522.27} y={47.06} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={35.29} width={11.76} height={11.76} />
            <rect className="d" x={522.27} y={23.53} width={11.76} height={11.77} />
            <rect className="d" x={522.27} y={11.76} width={11.76} height={11.76} />
            <rect className="d" x={510.5} y={164.7} width={11.77} height={11.76} />
            <rect className="d" x={510.5} y={0} width={11.77} height={11.76} />
            <rect className="d" x={423.44} y={164.7} width={87.07} height={11.76} />
            <rect className="d" x={423.44} y={0} width={87.07} height={11.76} />
            <rect className="d" x={381.67} y={164.7} width={41.76} height={11.76} />
            <rect className="d" x={381.67} y={0} width={41.76} height={11.76} />
            <rect className="d" x={369.91} y={164.7} width={11.77} height={11.76} />
            <rect className="d" x={369.91} y={0} width={11.77} height={11.76} />
            <rect className="d" x={358.15} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={358.15} y={0} width={11.76} height={11.76} />
            <rect className="d" x={346.38} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={346.38} y={0} width={11.76} height={11.76} />
            <rect className="d" x={334.62} y={152.93} width={11.76} height={11.76} />
            <rect className="d" x={334.62} y={11.76} width={11.76} height={11.76} />
            <rect className="d" x={322.85} y={141.17} width={11.77} height={11.77} />
            <rect className="d" x={322.85} y={23.53} width={11.77} height={11.77} />
            <rect className="d" x={311.09} y={141.17} width={11.76} height={11.77} />
            <rect className="d" x={311.09} y={23.53} width={11.76} height={11.77} />
            <rect className="d" x={299.33} y={141.17} width={11.76} height={11.77} />
            <rect className="d" x={299.33} y={23.53} width={11.76} height={11.77} />
            <rect className="d" x={287.56} y={152.93} width={11.77} height={11.76} />
            <rect className="d" x={287.56} y={11.76} width={11.77} height={11.76} />
            <rect className="d" x={275.8} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={275.8} y={0} width={11.76} height={11.76} />
            <rect className="d" x={264.03} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={264.03} y={0} width={11.76} height={11.76} />
            <rect className="d" x={252.27} y={164.7} width={11.77} height={11.76} />
            <rect className="d" x={252.27} y={0} width={11.77} height={11.76} />
            <rect className="d" x={240.5} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={240.5} y={0} width={11.76} height={11.76} />
            <rect className="d" x={228.74} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={228.74} y={0} width={11.76} height={11.76} />
            <rect className="d" x={216.98} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={216.98} y={0} width={11.76} height={11.76} />
            <rect className="d" x={205.21} y={164.7} width={11.77} height={11.76} />
            <rect className="d" x={205.21} y={0} width={11.77} height={11.76} />
            <rect className="d" x={193.45} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={193.45} y={0} width={11.76} height={11.76} />
            <rect className="d" x={181.68} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={181.68} y={0} width={11.76} height={11.76} />
            <rect className="d" x={169.92} y={164.7} width={11.77} height={11.76} />
            <rect className="d" x={169.92} y={0} width={11.77} height={11.76} />
            <rect className="d" x={158.15} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={158.15} y={0} width={11.76} height={11.76} />
            <rect className="d" x={146.39} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={146.39} y={0} width={11.76} height={11.76} />
            <rect className="d" x={87.06} y={164.7} width={59.34} height={11.76} />
            <rect className="d" x={87.06} y={0} width={59.34} height={11.76} />
            <rect className="d" x={35.29} y={164.7} width={51.76} height={11.76} />
            <rect className="d" x={35.29} y={0} width={51.76} height={11.76} />
            <rect className="d" x={23.53} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={23.53} y={0} width={11.76} height={11.76} />
            <rect className="d" x={11.77} y={164.7} width={11.76} height={11.76} />
            <rect className="d" x={11.77} y={0} width={11.76} height={11.76} />
            <rect className="d" y={152.93} width={11.77} height={11.76} />
            <rect className="d" y={141.17} width={11.77} height={11.77} />
            <rect className="d" y={129.4} width={11.77} height={11.76} />
            <rect className="d" y={117.64} width={11.77} height={11.76} />
            <rect className="d" y={105.88} width={11.77} height={11.77} />
            <rect className="d" y={94.11} width={11.77} height={11.76} />
            <rect className="d" y={82.35} width={11.77} height={11.76} />
            <rect className="d" y={70.59} width={11.77} height={11.76} />
            <rect className="d" y={58.82} width={11.77} height={11.77} />
            <rect className="d" y={47.06} width={11.77} height={11.76} />
            <rect className="d" y={35.29} width={11.77} height={11.76} />
            <rect className="d" y={23.53} width={11.77} height={11.77} />
            <rect className="d" y={11.76} width={11.77} height={11.76} />
          </g>
          <g>
            <polygon
              className="g"
              points="506.64 141.17 423.44 141.17 381.67 141.17 369.91 141.17 358.15 141.17 358.15 152.93 369.91 152.93 381.67 152.93 423.44 152.93 506.64 152.93 510.5 152.93 510.5 141.17 506.64 141.17"
            />
            <polygon
              className="g"
              points="381.67 35.29 506.64 35.29 506.64 23.53 381.67 23.53 369.91 23.53 358.15 23.53 358.15 35.29 369.91 35.29 381.67 35.29"
            />
            <rect className="g" x={346.38} y={129.4} width={11.76} height={11.76} />
            <rect className="g" x={346.38} y={35.29} width={11.76} height={11.76} />
            <polygon
              className="g"
              points="334.62 105.88 334.62 117.64 334.62 129.4 346.38 129.4 346.38 117.64 346.38 105.88 346.38 94.11 334.62 94.11 334.62 105.88"
            />
            <polygon
              className="g"
              points="334.62 58.82 334.62 70.59 334.62 82.35 334.62 94.11 346.38 94.11 346.38 82.35 346.38 70.59 346.38 58.82 346.38 47.06 334.62 47.06 334.62 58.82"
            />
            <polygon
              className="g"
              points="287.56 105.88 287.56 117.64 287.56 129.4 299.33 129.4 299.33 117.64 299.33 105.88 299.33 94.11 287.56 94.11 287.56 105.88"
            />
            <polygon
              className="g"
              points="287.56 58.82 287.56 70.59 287.56 82.35 287.56 94.11 299.33 94.11 299.33 82.35 299.33 70.59 299.33 58.82 299.33 47.06 287.56 47.06 287.56 58.82"
            />
            <rect className="g" x={275.8} y={129.4} width={11.76} height={11.76} />
            <rect className="g" x={275.8} y={35.29} width={11.76} height={11.76} />
            <polygon
              className="g"
              points="264.03 23.53 252.27 23.53 240.5 23.53 240.5 35.29 252.27 35.29 264.03 35.29 275.8 35.29 275.8 23.53 264.03 23.53"
            />
            <polygon
              className="g"
              points="252.27 141.17 240.5 141.17 240.5 152.93 252.27 152.93 264.03 152.93 275.8 152.93 275.8 141.17 264.03 141.17 252.27 141.17"
            />
            <polygon
              className="g"
              points="216.98 141.17 205.21 141.17 193.45 141.17 193.45 152.93 205.21 152.93 216.98 152.93 228.74 152.93 240.5 152.93 240.5 141.17 228.74 141.17 216.98 141.17"
            />
            <polygon
              className="g"
              points="216.98 23.53 205.21 23.53 193.45 23.53 193.45 35.29 205.21 35.29 216.98 35.29 228.74 35.29 240.5 35.29 240.5 23.53 228.74 23.53 216.98 23.53"
            />
            <polygon
              className="g"
              points="181.68 141.17 169.92 141.17 169.92 152.93 181.68 152.93 181.68 152.93 193.45 152.93 193.45 141.17 181.68 141.17 181.68 141.17"
            />
            <polygon
              className="g"
              points="181.68 23.53 169.92 23.53 169.92 35.29 181.68 35.29 181.68 35.29 193.45 35.29 193.45 23.53 181.68 23.53 181.68 23.53"
            />
            <polygon
              className="g"
              points="146.39 141.17 87.06 141.17 35.29 141.17 23.53 141.17 23.53 152.93 35.29 152.93 87.06 152.93 146.39 152.93 158.16 152.93 169.92 152.93 169.92 141.17 158.16 141.17 146.39 141.17"
            />
            <polygon
              className="g"
              points="146.39 23.53 87.06 23.53 35.29 23.53 23.53 23.53 23.53 35.29 35.29 35.29 87.06 35.29 146.39 35.29 158.16 35.29 169.92 35.29 169.92 23.53 158.16 23.53 146.39 23.53"
            />
          </g>
        </g>
        <g>
          <path
            className="h"
            d="M261.61,105.79c0,.91-.2,1.56-.61,1.97-.41.41-1.06.61-1.97.61h-8.86c-.91,0-1.56-.2-1.97-.61-.41-.4-.61-1.06-.61-1.97v-12.01h-11.65c-.91,0-1.56-.2-1.97-.61-.41-.4-.61-1.06-.61-1.97v-8.15c0-.9.2-1.56.61-1.97.4-.4,1.06-.61,1.97-.61h11.65v-12.01c0-.9.2-1.56.61-1.97.4-.4,1.06-.61,1.97-.61h8.86c.9,0,1.56.2,1.97.61.4.41.61,1.06.61,1.97v12.01h11.65c.91,0,1.56.2,1.97.61.4.41.61,1.06.61,1.97v8.15c0,.91-.2,1.56-.61,1.97s-1.06.61-1.97.61h-11.65v12.01Z"
          />
          <g>
            <path
              className="h"
              d="M81.61,55.03h13.29c1.13,0,1.95.25,2.45.76.5.51.76,1.32.76,2.45v34.6c0,1.9-.08,3.67-.22,5.31-.15,1.64-.61,3.55-1.38,5.75l-4.46,12.13c-.3.89-.73,1.65-1.29,2.27-.57.62-1.32.94-2.27.94h-11.86c-.95,0-1.81-.21-2.59-.62-.77-.42-1.28-1.1-1.52-2.05l-4.1-14.54c-.18-.59-.4-1-.67-1.2-.27-.21-.58-.31-.94-.31h-.8c-.36,0-.67.1-.94.31-.27.21-.49.61-.67,1.2l-4.19,14.54c-.24.89-.68,1.56-1.34,2.01-.66.45-1.46.67-2.41.67h-11.77c-.95,0-1.77-.3-2.45-.89-.68-.59-1.17-1.37-1.47-2.32l-4.46-12.31c-.77-2.2-1.23-4.12-1.38-5.75-.15-1.63-.22-3.4-.22-5.31v-34.42c0-1.13.25-1.95.76-2.45s1.32-.76,2.45-.76h13.73c1.13,0,1.95.25,2.45.76.5.51.76,1.32.76,2.45v32.82l5.17-11.24c.48-1.01,1.1-1.72,1.87-2.14.77-.42,1.72-.62,2.85-.62h3.75c1.13,0,2.08.21,2.85.62.77.42,1.4,1.13,1.87,2.14l5.17,11.24v-32.82c0-1.13.25-1.95.76-2.45s1.32-.76,2.45-.76Z"
            />
            <path
              className="h"
              d="M123.08,119.24h-13.82c-1.13,0-1.95-.25-2.45-.76-.51-.5-.76-1.32-.76-2.45v-57.79c0-1.13.25-1.95.76-2.45s1.32-.76,2.45-.76h27.29c8.08,0,13.61,1.61,16.59,4.82,2.97,3.21,4.46,7.4,4.46,12.57v11.42c0,5.17-1.49,9.36-4.46,12.58-2.97,3.21-8.5,4.82-16.59,4.82h-10.26v14.8c0,1.13-.25,1.95-.76,2.45-.51.51-1.32.76-2.45.76ZM126.12,71.71v13.47h8.03c1.72,0,2.85-.45,3.39-1.34.53-.89.8-1.9.8-3.03v-4.73c0-1.13-.27-2.14-.8-3.03-.54-.89-1.67-1.34-3.39-1.34h-8.03Z"
            />
            <path
              className="h"
              d="M180.34,119.24h-13.82c-1.13,0-1.95-.25-2.45-.76-.51-.5-.76-1.32-.76-2.45v-57.79c0-1.13.25-1.95.76-2.45s1.32-.76,2.45-.76h27.29c8.08,0,13.61,1.61,16.59,4.82,2.97,3.21,4.46,7.4,4.46,12.57v11.42c0,5.17-1.49,9.36-4.46,12.58-2.97,3.21-8.5,4.82-16.59,4.82h-10.26v14.8c0,1.13-.25,1.95-.76,2.45-.51.51-1.32.76-2.45.76ZM183.37,71.71v13.47h8.03c1.72,0,2.85-.45,3.39-1.34.53-.89.8-1.9.8-3.03v-4.73c0-1.13-.27-2.14-.8-3.03-.54-.89-1.67-1.34-3.39-1.34h-8.03Z"
            />
          </g>
        </g>
      </g>
      {numberPath}
    </svg>
  )
}

export default WppBadge

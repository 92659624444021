import { createAsyncThunk } from '@reduxjs/toolkit'
import { SubgraphState } from '../types'
import { SupportedChainId } from '../../../constants/web3/supportedChainId'
import { ChainInfo } from '../../../constants/web3/chains'
import { fetchSubgraphData } from '../helper'

const getSubgraphData = createAsyncThunk<
  Pick<SubgraphState, 'tradingVol24h' | 'medianBoostedAprs'> | null,
  {
    chainId: SupportedChainId
    block24hAgo: { chainId: number; value: number } | null
    chainInfo: ChainInfo
  }
>('subgraph/getSubgraphData', async ({ chainId, block24hAgo, chainInfo }) => {
  const [tradingVol24h, medianBoostedAprs] = await fetchSubgraphData({
    block24hAgo,
    chainId,
    chainInfo,
  })
  if (tradingVol24h) {
    return {
      tradingVol24h: tradingVol24h,
      medianBoostedAprs: medianBoostedAprs || null,
    }
  }
  return null
})

export default getSubgraphData
